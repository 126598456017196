
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { acceptApprovalVisit } from "../../slices/Invitations/InvitationsSlice";
import { getPurposeType } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";
import CommonUtil from "../../Util/CommonUtils";
import CommonPhoneInput from "../../components/CustomInputs/CommonPhoneInput";
import { getVisitorAccessLevels } from "../../slices/VisitorAccessLevels/VisitorAccessLevelSlice";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ApprovalPage(props) {
  const { open, handleClose, selectedObject } = props;
  const { t } = useTranslation();
  const [selectedAccessLevel, setSelectdAccessLevel] = useState([]);
  const dispatch = useDispatch();
  const [purposeData, setPurposeData] = useState([]);

  const zlink = localStorage.getItem("redirectFrom");

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );

  const [payload, setPayload] = React.useState({
    hostId: "",
    approvedBy: "",
    accessLevel: [],
    approvalReason: "",
    purposeTypeId: selectedObject?.purposeType,
    siteId: "",
    visitMode: "",
  });

  const [error, setError] = React.useState({
    approvalReason: false,
    purposeTypeId: false,
  });

  useEffect(() => {
    loadPurposeType();
    // loadAcceLevelList();
  }, []);

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI0000") {
          setPurposeData(data?.data?.purposeTypes);
        } else {
          setPurposeData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const loadAcceLevelList = () => {
  //   const dto = {
  //     page: 0,
  //     pageSize: 1000,
  //     isPageable: false,
  //   };
  //   dispatch(getVisitorAccessLevels(dto))
  //     .unwrap()
  //     .then((data) => {
  //       if (data?.code === "DMSI0000") {
  //         setSelectdAccessLevel(data?.data?.accessGroups);
  //       } else {
  //         setSelectdAccessLevel([]);
  //       }
  //     });
  // };

  const onError = (data) => { };

  const handleSumbit = () => {
    if (CommonUtil.isEmpty(payload.purposeTypeId)) {
      Toast(("Please enter purpose type"), 'error')
      setError({ purposeTypeId: true });
      return false;
    }

    const dto = {
      approvalReason: payload.approvalReason,
      purposeTypeId: payload.purposeTypeId.id,
      accessLevel: payload.accessLevel,
      visitorId: selectedObject.id,
    };

    dispatch(acceptApprovalVisit(dto))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI1028" || data?.code === 'CVAI0000') {
          Toast("Visit approved successfully", "success");
          props.approve();
          handleClose();
        } else {
          onError(data);
        }
      });

  };
  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };
  const handlePhoneChange = (event) => {
    setPayload({
      ...payload,
      phoneNumber: event,
    });
    setError({ phoneNumber: false });
  };

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    });
  };

  const onselectAccessLevel = (event) => {
    setPayload({
      ...payload,
      accessLevel: event,
    });
  };

  return (
    <>
      <DialogBox
        Header="Approve"
        acceptText="Submit"
        cancelText="Cancel"
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onSubmit={handleSumbit}
        onClose={() => handleClose(false)}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ marginTop: "2px", padding: "20px" }}
          spacing={2}
        >
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              error={error.firstName}
              name="firstName"
              label="First Name"
              value={selectedObject.visitor.firstName}
              handleChange={(e) => handleChange(e)}
              helperText={error.firstName}
              validation="alpha-numeric"
              required={true}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label="Last Name"
              value={selectedObject.visitor.lastName}
              error={error.lastName}
              name="lastName"
              handleChange={(e) => handleChange(e)}
              helperText={error.lastName}
              validation="alpha-numeric"
            />
          </Grid>
          <Grid item lg={6} xs={12} mt={-1.5}>
            {/* <CustomPhone
              value={selectedObject.visitor.phone}
              specialLabel={false}
              handleChange={(e) => handlePhoneChange(e)}
            /> */}

            <CommonPhoneInput
              sx={{ margin: 'none !important' }}
              countryCode={selectedObject.visitor.phone.split("-")[0]}
              placeholder={t('COMPPROFSCREEN037')}
              phoneNumber={selectedObject.visitor.phone.split("-")[1]}
              name={'phone'}
              disabled={'disabled'}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label="Email *"
              value={selectedObject.visitor.email}
              name="emailId"
              handleChange={(e) => handleChange(e)}
              helperText={error.lastName}
              validation="alpha-numeric"
            />
          </Grid>

          {!zlink ? (
            <>
              <Grid item lg={6} sm={12} style={{ margin: "16px 0px" }}>
                <Autocomplete
                  style={{ width: '100%' }}
                  noOptionsText={'No Options found'}
                  name='purposeTypeId'
                  value={payload.purposeTypeId}
                  options={CommonUtil.isEmptyArray(purposeData) ? [] : purposeData}
                  getOptionLabel={(option) => option.name ? option.name : ''}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                      onselectPurposeType(newValue);
                    } else {
                      onselectPurposeType('');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name='purposeTypeId' value={payload.purposeTypeId} error={error.purposeTypeId} variant='outlined' fullWidth label={'Purpose Type *'} />
                  )}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item lg={6} sm={12} style={{ margin: "16px 0px" }}>
                <Autocomplete
                  style={{ width: '100%' }}
                  noOptionsText={'No Options found'}
                  name='purposeTypeId'
                  value={payload.purposeTypeId}
                  options={CommonUtil.isEmptyArray(purposeData) ? [] : purposeData}
                  getOptionLabel={(option) => option.name ? option.name : ''}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                      onselectPurposeType(newValue);
                    } else {
                      onselectPurposeType('');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name='purposeTypeId' value={payload.purposeTypeId} error={error.purposeTypeId} variant='outlined' fullWidth label={'Visitor Purpose *'} />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label="Company Name"
              error={error.visitorCompanyName}
              name="visitorCompanyName"
              value={selectedObject.visitor.visitorCompanyName}
              onChange={handleChange}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>

          {!zlink ? (
            <>
              <Grid item lg={6} sm={12} style={{ margin: "16px 0px" }}>
              <Autocomplete
                  multiple
                  limitTags={2}
                  error={error.accessLevel}
                  options={selectedAccessLevel}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      var ids = newValue.map((newValue) => { return newValue.id });
                      onselectAccessLevel(ids);
                    } else {
                      onselectAccessLevel("");
                    }
                    setError({ accessLevel: false });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Access Levels" value={payload.accessLevel} />
                  )}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}

          {!zlink ? (
            <>
              <Grid item lg={6} sm={12}>
                <CustomTextfield
                  error={error.approvalReason}
                  name="approvalReason"
                  label="Remarks"
                  handleChange={(e) => handleChange(e)}
                  helperText={error.approvalReason}
                  value={payload.approvalReason}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item lg={12} sm={12}>
                <CustomTextfield
                  error={error.approvalReason}
                  name="approvalReason"
                  label="Remarks"
                  handleChange={(e) => handleChange(e)}
                  helperText={error.approvalReason}
                  value={payload.approvalReason}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogBox>
    </>
  );
}
