import {
    Tooltip,
    Grid,
    DialogActions,
    DialogContent,
    DialogTitle,
    Dialog,
    IconButton,
  } from "@mui/material";
  import { Divider } from "@mui/material";
  import React, { useState } from "react";
  import { Typography } from "@mui/material";
  import { useTranslation } from "react-i18next";
  import { ReactComponent as ExportIcon } from '../../assets/images/logs/expoer-bg.svg'
  import CloseIcon from "@mui/icons-material/Close";
  import CommonUtil from "../../Util/CommonUtils";
  import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
  import CancelButtons from "../../components/DialogBoxComponent/CancelButtons";
  import Toast from "../../components/ToastContainer/CustomToast";
  import CustomDateTimePicker from "../../components/CustomInputs/CustomDateTimePicker";
  
  export default function FilterExportDialog(props) {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
  
    const formatDayAndMonth = (numValue) => {
      if (numValue <= 0) {
        return "-" + (numValue + 12);
      }
      if (numValue < 10) {
        return "-0" + numValue;
      } else {
        return "-" + numValue;
      }
    };
    var today = new Date();
    var endDate =
      today.getFullYear() +
      formatDayAndMonth(today.getMonth() + 1) +
      formatDayAndMonth(today.getDate()) +
      "T23:59:59";
    var startDate =
      today.getFullYear() +
      formatDayAndMonth(today.getMonth() + 1) +
      formatDayAndMonth(today.getDate()) +
      "T00:00";
  
    const [payload, setPayload] = useState({
      scheduledStartDate: CommonUtil.formatToUtc(startDate),
      scheduledEndDate: CommonUtil.formatToUtc(endDate),
    });
  
    const handleClose = () => {
      setPayload({
        scheduledStartDate: CommonUtil.formatToUtc(startDate),
        scheduledEndDate: CommonUtil.formatToUtc(endDate),
      });
      setOpen(false);
    };
  
    const reset = () => {
      if (
        payload.scheduledStartDate === startDate &&
        payload.scheduledEndDate === endDate
      ) {
        Toast(t("COMMON027"), "error")
        return;
      }
  
      setPayload({
        scheduledStartDate: CommonUtil.formatToUtc(startDate),
        scheduledEndDate: CommonUtil.formatToUtc(endDate),
      });
    };

    const [error, setError] = useState({
        scheduledStartDate: false,
        scheduledEndDate: false,
      });
  
    const handleSubmit = () => {
      if (
        payload.scheduledStartDate === null &&
        payload.scheduledEndDate === null
      ) {
        Toast(t("COMMON015"), "error")
        return;
      }
  
      if (
        payload.scheduledStartDate === null ||
        payload.scheduledStartDate === undefined ||
        payload.scheduledStartDate === ""
      ) {
        setError({ scheduledStartDate: true });
        Toast(t("INVITESTARTDATEERROR"), "error")
        return;
      }
  
      if (
        payload.scheduledEndDate === null ||
        payload.scheduledEndDate === undefined ||
        payload.scheduledEndDate === ""
      ) {
        setError({ scheduledEndDate: true });
        Toast(t("INVITEENDDATEERROR"), "error")
        return;
      }
  
      if (
        new Date(payload.scheduledStartDate).toString() === "Invalid Date" ||
        new Date(payload.scheduledEndDate).toString() === "Invalid Date"
      ) {
        Toast(t("USERPROFSCREEN013"), "error")
        return;
      }
  
      if (
        new Date(payload.scheduledEndDate) < new Date(payload.scheduledStartDate)
      ) {
        Toast(t("INVITATIONFORM010"), "error")
        return;
      }
      handleClose();
      props.applyFilter(payload);
      setPayload({
        scheduledStartDate: CommonUtil.formatToUtc(startDate),
        scheduledEndDate: CommonUtil.formatToUtc(endDate),
      });
    };

    return (
      <>
        <Tooltip title={<Typography>Export</Typography>}>
          <ExportIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          </ExportIcon>
        </Tooltip>
        <Grid item xs={6}>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle variant="h4" onClick={handleClose}>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 15,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              Export
            </DialogTitle>
            <Divider variant="middle" />
            <DialogContent>
              <Grid
                container
                justifyContent="center"
                alignItems={"center"}
              >
                
              <Grid item xs={12} sm={9} style={{ marginTop: "30px", height: "100%" }}>
                <CustomDateTimePicker
                  onChange={(scheduledStartDate) =>
                    setPayload({
                      ...payload,
                      scheduledStartDate: scheduledStartDate,
                    })
                  }
                  value={payload.scheduledStartDate}
                  label={"Invite Start Date *"}
                  />
                </Grid>
  
                <Grid item xs={12}
                  sm={9}
                  style={{ marginTop: "30px", height: "100%" }}
                >
                  <CustomDateTimePicker
                    onChange={(scheduledEndDate) =>
                      setPayload({
                        ...payload,
                        scheduledEndDate: scheduledEndDate,
                      })
                    }
                    value={payload.scheduledEndDate}
                    minDateTime={payload.scheduledStartDate}
                    label="Invite End Date *"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{
              justifyContent: "center",
            }}>
              <SubmitButtons variant='contained' onClick={handleSubmit}>
                <Typography variant="body1" style={{ textTransform: "none" }}>
                  Apply
                </Typography>
              </SubmitButtons>
              <CancelButtons variant='contained' color="secondary" onClick={reset}>
                <Typography variant="body1" style={{ textTransform: "none" }}>
                  Reset
                </Typography>
              </CancelButtons>
            </DialogActions>
          </Dialog>
        </Grid>
      </>
    );
  }
  