import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import UserRoleService from '../../services/Role/RoleService';

const initialState = [];

export const listRole = createAsyncThunk('role/list', async (payload) => {
    const res = await UserRoleService.RoleSlice(payload);
    return res.data;
});

export const deactivateAccount = createAsyncThunk('role/deActivate', async (email) => {
    const res = await UserRoleService.deactivateAccount(email);
    return res.data;
});

export const activateAccount = createAsyncThunk('role/activate', async (payload) => {
    const res = await UserRoleService.activateAccount(payload);
    return res.data;
});

 const RoleSlice = createSlice({
    name: 'userRole',
     initialState,
     extraReducers: {
        [listRole.fulfilled]: (state, action) => {
             if (action.payload.code === '') {
                 return action.payload.data;
            } else {
                 return [];
             }
         },

     }
 })

const { reducer } = RoleSlice;
export default reducer;