import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import addressService from "../../services/Site/AddressTypeService";

const initialState = {
    addressTypes: []
};

export const getAddressTypes = createAsyncThunk('addressType/get', async (filter) => {
    const res = await addressService.getAddressTypes(filter);
    return res.data;
});

const AddressTypeSlice = createSlice({
    name: 'addressType',
    initialState,
    extraReducers: {
        [getAddressTypes.fulfilled]: (state, action) => {
            state.addressTypes = action.payload;
        }
    },
});

const { reducer } = AddressTypeSlice;
export default reducer;
