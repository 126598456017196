import React from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from "@mui/icons-material/Close";

const RoleDilogBox = (props) => {

    const styleDialog = {
        '& .MuiDialog-paper': {
            position: 'absolute',
            boxShadow: 1,
            width:props.width?props.width: '550px',
            borderRadius: '12px',
            padding: '1rem',
            top:'none',
        },
    };

  return (
    <>
    
    <Dialog sx={styleDialog} open={props.open} onClose={() => props.onClose()}>
    <Box>
        <Box display='flex' justifyContent={'space-between'}>
         <Typography component='div' gutterBottom
            style={{color: '#3D4977',  fontSize: '17px', fontWeight:'600'}}>
           {props.title}
        </Typography>
        <IconButton disableRipple onClick={props.onClose}>
          <CloseIcon />  </IconButton>
         </Box>
        {props.children}
       </Box>
        </Dialog>

    </>
  )
}

export default RoleDilogBox
