import {
  Tooltip,
  Grid,
  Select,
  InputLabel,
  Stack,
  TextField,
  ThemeProvider,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "./DialogBoxComponent/DialogBox";
import { ReactComponent as FilterIcon } from "../../src/assets/FilterIcon.svg";
import { FormControl, MenuItem, Typography } from "@mui/material";
import { ReactComponent as DropDownIcon } from "../../src/assets/DropDown.svg";
import { ReactComponent as DropUpIcon } from "../../src/assets/DropUp.svg";
import SubmitButtons from "../components/DialogBoxComponent/SubmitButtons";
import CancelButtons from "../components/DialogBoxComponent/CancelButtons";
import CalenderIcon from "../assets/Calender";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDateTimePicker from "@mui/lab/DesktopDateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Breadcrumbs } from "@mui/material";
import { Link } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getSitesList } from "../slices/Site/SiteSlice";
import { listVisitorType } from "../slices/VisitTypeSlice";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { createTheme } from "@material-ui/core";
import CustomDropdown from "./CustomInputs/CustomDropdown";
import Toast from "./ToastContainer/CustomToast";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import AutocompleteComponent from "./CustomInputs/AutocompleteComponent";
import { listVisitorTypeZlink } from "../slices/PerpouseType/PerpouseTypeSlice";
import CommonUtil from "../Util/CommonUtils";
import CustomTextfield from "./CustomInputs/CustomTextfield";
import CustomDateTimePicker from "./CustomInputs/CustomDateTimePicker";


// const MuiTheme = createTheme({
//   palette: {
//     type: "light",
//     primary: {
//       main: process.env.REACT_APP_BG_ICON,
//     },
//     secondary: {
//       main: "#f50057",
//     },
//   },
// });

// const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
//   color: process.env.REACT_APP_BG_ICON,
//   border: process.env.REACT_APP_BG_BORDER,
//   borderRadius: "50%",
//   "&:hover": {
//     backgroundColor: process.env.REACT_APP_BG_ICON,
//     color: "#FFFF",
//   },
// }));

// const CustomIconRight = styled(ChevronRightIcon)(() => ({
//   color: process.env.REACT_APP_BG_ICON,
//   border: process.env.REACT_APP_BG_BORDER,
//   borderRadius: "50%",
//   "&:hover": {
//     backgroundColor: process.env.REACT_APP_BG_ICON,
//     color: "#FFFF",
//   },
// }));

// const useStyles = makeStyles((theme) => ({
//   cssLabel: {
//     color: '#0000008a'
//   },

//   cssOutlinedInput: {
//     '&$cssFocused $notchedOutline': {
//       borderColor: '#1976d2 !important',
//     }
//   },
//   cssFocused: {
//     color: '#1976d2 !important'
//   },

//   notchedOutline: {
//     borderWidth: '1px',
//     borderColor: '#C4C4C4 !important'
//   },

// }));

export default function InvitationFilterForm(props) {
  const [open, setOpen] = React.useState(false);
  const [purposeIcon, setPurposeIcon] = React.useState(false);
  const [siteIcon, setSiteIcon] = React.useState(false);
  const [data, setData] = useState();
  const [siteData, setSiteData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const classes = useStyles();

  const [date, setDate] = useState(new Date());
  const [dateOpen, setDateOpen] = useState(false);
  const [scheduledStartDate, setScheduledStartDate] = React.useState(
    new Date()
  );

  // const [open, setOpen] = React.useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose1 = () => {
  //   setOpen(false);
  // };

  // const handleDate = (e) => {
  //   setScheduledStartDate(e);
  //   // props.SelectedDate(e);
  // };


  const ITEM_HEIGHT = 44;
  const ITEM_PADDING_TOP = 2;

  const MenuProps = {
    PaperProps: {
      style: {
        opacity: "1",
        color: "#242424",
        fontSize: "17px",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        marginLeft: "9.5px",
        width: 440,
      },
    },
  };

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };
  var today = new Date();
  var endDate =

    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";

  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [payload, setPayload] = useState({

    purposeTypeId: "",
    siteId: "",
    status: props.status,
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: props.selectedObject,
  });

  const [selectedPurpose, setSelectedPurpose] = useState(null)
  const [selectSite, setSelectSite] = useState(null)

  const handleClose = () => {
    setPayload({
      purposeTypeId: "",
      siteId: "",
      status: props.status,
      scheduledStartDate: CommonUtil.formatToUtc(startDate),
      scheduledEndDate: CommonUtil.formatToUtc(endDate),
      visitMode: props.selectedObject,
    });
    setSelectSite(null)
    setSelectedPurpose(null)
    setOpen(false);
  };

  const [error, setError] = useState({
    purposeTypeId: false,
    siteId: false,
  });

  const reset = () => {

    if (
      selectedPurpose === null &&
      selectSite === null
      // payload.scheduledStartDate === startDate &&
      // payload.scheduledEndDate === endDate
    ) {
      Toast(t("COMMON027"), "error")
      return;
    }

    if (selectedPurpose !== null) {
      setSelectedPurpose(null)
    }

    if (selectSite !== null) {
      setSelectSite(null)
    }

    setPayload({
      purposeTypeId: "",
      siteId: "",
      status: props.status,
      scheduledStartDate: CommonUtil.formatToUtc(startDate),
      scheduledEndDate: CommonUtil.formatToUtc(endDate),
      visitMode: props.selectedObject,
    });
  };



  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (
      payload.purposeTypeId === "" &&
      payload.siteId === "" &&
      payload.scheduledStartDate === null &&
      payload.scheduledEndDate === null
    ) {
      Toast(t("COMMON015"), "error")
      return;
    }

    if (
      payload.scheduledStartDate === null ||
      payload.scheduledStartDate === undefined ||
      payload.scheduledStartDate === ""
    ) {
      setError({ scheduledStartDate: true });
      Toast(t("INVITESTARTDATEERROR"), "error")
      return;
    }

    if (
      payload.scheduledEndDate === null ||
      payload.scheduledEndDate === undefined ||
      payload.scheduledEndDate === ""
    ) {
      setError({ scheduledEndDate: true });
      Toast(t("INVITEENDDATEERROR"), "error")
      return;
    }

    if (
      new Date(payload.scheduledStartDate).toString() === "Invalid Date" ||
      new Date(payload.scheduledEndDate).toString() === "Invalid Date"
    ) {
      Toast(t("USERPROFSCREEN013"), "error")
      return;
    }

    if (
      new Date(payload.scheduledEndDate) < new Date(payload.scheduledStartDate)
    ) {
      Toast(t("INVITATIONFORM010"), "error")
      return;
    }

    props.applyFilter(payload);
    setPayload({
      purposeTypeId: "",
      siteId: "",
      scheduledStartDate: payload.scheduledStartDate,
      scheduledEndDate: payload.scheduledEndDate,
      visitMode: props.selectedObject,
      status: props.status,
    });
    // setSelectedPurpose(null)
    // setSelectSite(null)
    handleClose();
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadVisitType();
    loadSites();
  };

  const loadVisitType = () => {
    const query = {
      id: "",
      name: "",
      pageNumber: 1,
      pageSize: 0,
      isPageable: true,
    };

    dispatch(listVisitorTypeZlink(query))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI0000") {
          setData(data?.data?.purposeTypes);
        } else {
          setData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadSites = () => {
    dispatch(getSitesList(data))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVSI0000") {
          setSiteData(data?.data?.site);
        } else {
          setSiteData([]);
        }
      });
  };

  const selectedPurposeType = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        purposeTypeId: newValue.id
      })
    }
    else {
      setPayload({
        ...payload,
        purposeTypeId: ''
      })
    }
  }

  const onSelectedSite = (newValue) => {
    if (newValue !== null) {
      setPayload({
        ...payload,
        siteId: newValue.id
      })
    }
    else {
      setPayload({
        ...payload,
        siteId: ''
      })
    }
  }


  return (
    <>
      <Tooltip title={<Typography>Filter</Typography>}>
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <InvitationFilterForm />
        </FilterIcon>
      </Tooltip>
      <Grid item xs={6}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle variant="h4" onClick={handleClose}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 15,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            Filter
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
            >
              <Grid item xs={12} sm={9} style={{ marginTop: "10px" }}>
                {/* <AutocompleteComponent
                  label="Purpose"
                  error={error.purposeTypeId}
                  name="purposeTypeId"
                  value={payload.purposeTypeId}
                  onSelect={(newValue) => setPayload({
                    ...payload,
                    purposeTypeId: newValue.id,
                  })}
                  onReset={() => reset()}
                  options={data}
                /> */}

                <Autocomplete
                  fullWidth
                  noOptionsText={'No Options found'}
                  options={data}
                  getOptionLabel={(option) =>
                    option.name ? option.name : ""
                  }
                  value={selectedPurpose}
                  onChange={(event, newValue) => {
                    setSelectedPurpose(newValue)
                    selectedPurposeType(newValue)
                    setError({ purposeTypeId: false })
                  }}
                  renderInput={(params) => (
                    <CustomTextfield
                      {...params}
                      label="PurposeType"
                      required={false}
                      palceholder="PurposeType"
                      name="id"
                      error={error.purposeTypeId}
                    />
                  )}
                />


              </Grid>

              <Grid item xs={12} sm={9} style={{ marginTop: "30px" }}>
                {/* <AutocompleteComponent
                  label="Site"
                  error={error.siteId}
                  name="siteId"
                  value={payload.siteId}
                  onSelect={(newValue) => setPayload({
                    ...payload,
                    siteId: newValue.id,
                  })}
                  options={siteData} /> */}

                <Autocomplete
                  fullWidth
                  noOptionsText={'No Options found'}
                  options={siteData}
                  getOptionLabel={(option) =>
                    option.name ? option.name : ""
                  }
                  value={selectSite}
                  onChange={(event, newValue) => {
                    setSelectSite(newValue)
                    onSelectedSite(newValue)
                    setError({ siteId: false })
                  }}
                  renderInput={(params) => (
                    <CustomTextfield
                      {...params}
                      label="Site"
                      required={false}
                      palceholder="Site"
                      name="id"
                      error={error.siteId}
                    />
                  )}
                />

              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                style={{ marginTop: "30px", height: "100%" }}
              >
                {/* <ThemeProvider theme={MuiTheme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDateTimePicker
                        // disablePast
                        components={{
                          OpenPickerIcon: CalenderIcon,
                          LeftArrowIcon: CustomIconLeft,
                          RightArrowIcon: CustomIconRight,
                        }}
                        dateRangeIcon={<CalenderIcon />}
                        label={"Invite Start Date *"}
                        inputFormat="dd-MM-yyyy HH:mm"
                        value={payload.scheduledStartDate}
                        // onChange={handleChange}

                        onChange={(scheduledStartDate) => {
                          // payload.purposeTypeId !== " " && payload.siteId !== "" &&
                          setPayload({
                            ...payload,
                            scheduledStartDate: scheduledStartDate,
                          })
                        }
                        }
                        InputProps={{
                          classes:
                          {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                            {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </ThemeProvider> */}

                <CustomDateTimePicker
                  onChange={(scheduledStartDate) =>
                    setPayload({
                      ...payload,
                      scheduledStartDate: scheduledStartDate,
                    })
                  }
                  value={payload.scheduledStartDate}
                  label={"Invite Start Date *"}
                />


              </Grid>

              <Grid
                item
                xs={12}
                sm={9}
                style={{ marginTop: "30px", height: "100%" }}
              >
                {/* <ThemeProvider theme={MuiTheme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDateTimePicker
                        // disablePast
                        components={{
                          OpenPickerIcon: CalenderIcon,
                          LeftArrowIcon: CustomIconLeft,
                          RightArrowIcon: CustomIconRight,
                        }}
                        dateRangeIcon={<CalenderIcon />}
                        label="Invite End Date *"
                        inputFormat="dd-MM-yyyy HH:mm"
                        type="datetime-local"
                        value={payload.scheduledEndDate}
                        //    onChange={handleChange}

                        onChange={(scheduledEndDate) => {
                         
                          //  { payload.purposeTypeId !== " " && payload.siteId !== " " &&  

                          setPayload({
                            ...payload,
                            scheduledEndDate: scheduledEndDate,
                          })

                          // }


                          setError({ scheduledEndDate: false })

                        }}
                        minDateTime={payload.scheduledStartDate}
                        InputProps={{
                          classes:
                          {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                            {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </ThemeProvider> */}

                <CustomDateTimePicker
                  onChange={(scheduledEndDate) =>
                    setPayload({
                      ...payload,
                      scheduledEndDate: scheduledEndDate,
                    })
                  }
                  value={payload.scheduledEndDate}
                  label="Invite End Date *"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{
            justifyContent: "center",
          }}>
            <SubmitButtons variant='contained' onClick={handleSubmit}>
              <Typography variant="body1" style={{ textTransform: "none" }}>
                Apply
              </Typography>
            </SubmitButtons>
            <CancelButtons variant='contained' color="secondary" onClick={reset}>
              <Typography variant="body1" style={{ textTransform: "none" }}>
                Reset
              </Typography>
            </CancelButtons>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
