import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import blockVisitorsGlobalService from '../../services/BlockVisitorsGlobal/BlockVisitorService';

const initialState = [];

export const listAllBlacklistVisitors = createAsyncThunk('listAllBlacklistVisitors/blacklist', async (payload) => {
    const res = await blockVisitorsGlobalService.listAllBlacklistVisitors(payload);
    return res.data;
}); 


export const  blacklistVisitor = createAsyncThunk('blacklistVisitor/blacklist', async (payload) => {
    const res = await blockVisitorsGlobalService. blacklistVisitor(payload);
    return res.data;
}); 

export const unblacklistVisitor = createAsyncThunk('unblacklistVisitor/unblacklist', async (payload) => {
    const res = await blockVisitorsGlobalService.unblacklistVisitor(payload);
    return res.data;
}); 

export const rejectBlacklistVisitors = createAsyncThunk('rejectBlacklistVisitors/rejectBlacklistVisitors', async (payload) => {
    const res = await blockVisitorsGlobalService.rejectBlacklistVisitors(payload);
    return res.data;
}); 



const blacklistVisitorSlice = createSlice({
    name: 'blacklistVisitor',
    initialState,
    extraReducers: {
        [listAllBlacklistVisitors.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = blacklistVisitorSlice;
export default reducer;