import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { timeSlotListPro } from "../../slices/TimeSlot/TimeSlotSlice";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { createVisitorAccessLevels, updateVisitorAccessLevels } from "../../slices/VisitorAccessLevels/VisitorAccessLevelSlice";
import Toast from "../../components/ToastContainer/CustomToast";
import CommonUtil from "../../Util/CommonUtils";
import { useTranslation } from "react-i18next";

function AddAccessLevelDialog(props) {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const [timeSlots, setTimeSlots] = useState([]);
  const { t } = useTranslation();

  const [error, setError] = useState({
    name: '',
    schedulerId: ''
  });

  const [payload, setPayload] = useState({
    id: '',
    name: '',
    schedulerId: ''
  });

  useEffect(() => {
    if (props.selectedObject.id !== undefined) {
      setPayload({
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        schedulerId: props.selectedObject.schedulerId
      })
    }
  }, [])

  const handleSubmit = () => {
    if (payload.name === '') {
      setError({ ...error, name: true });
      Toast('Please Enter The Access Level Name', 'error')
    } else if (!CommonUtil.validateName(payload.name)) {
      Toast(t('Please enter the valid Access Level Name'), 'error')
      setError({ name: true });
      return;
    }
     else if (payload.schedulerId === '') {
      setError({ ...error, schedulerId: true });
      Toast('Please Select The Time Slot', 'error')
    } else {
      props.selectedObject.id === undefined ? handleCreate() : handleUpdate()
    }
  };

  const handleClose = () => {
    props.handleDialogClose()
    props.updateSelectedObject()
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  }

  useEffect(() => {
    loadTimeSlots({ pageNumber: 1, pageSize: 1000 });
  }, [])

  const loadTimeSlots = (paging) => {
    dispatch(timeSlotListPro(paging)).unwrap().then((data) => {
      if (data?.code === 'DMSI0000') {
        setTimeSlots(data?.data?.timeZone);
      }
    })
  }

  const handleCreate = () => {
    dispatch(createVisitorAccessLevels(payload)).unwrap().then((data) => {
      if (data?.code === 'DMSI0000') {
        Toast("Access Level Created Successfully", 'success')
        props.loadData();
        handleClose()
      } else {
        Toast(data.msg, 'error')
      }
    })
  }

  const handleUpdate = () => {
    dispatch(updateVisitorAccessLevels(payload)).unwrap().then((data) => {
      if (data?.code === 'DMSI0000') {
        Toast("Access Level Updated Successfully", 'success')
        props.loadData();
        props.updateSelectedObject();
        handleClose()
      } else {
        Toast(data.message, 'error')
      }
    })
  }

  const findTimeZoneById = (id) => {
    var time = {};
    timeSlots.map((timeSlot) => {
      if (timeSlot.id === id) {
        time = timeSlot;
      }
    });
    if (!CommonUtil.isEmpty(time))
      return time;
    else
      return payload.schedulerId;
  };
  const edit = true;
  const timeSlotField = () => {
    return (
      <>
     {props.selectedObject.id !== undefined && props.selectedObject.edit===true
     ? <Autocomplete
      name="schedulerId"
      required
      fullWidth
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      value={edit===true && findTimeZoneById(payload.schedulerId)}
      renderInput={(params)=><TextField
         {...params}  
         helperText={error.schedulerId}
         error={error.schedulerId}
         label={'Time slot *'}/>}
      getOptionLabel={(option)=>option.operationName}
      ListboxProps={{ style: { maxHeight: 180 } }}
      options={!CommonUtil.isEmpty(timeSlots)?timeSlots:[]}
      onChange={(event,newValue)=>{
        if (!CommonUtil.isEmpty(newValue))
        setPayload({
          ...payload,
          schedulerId:newValue.id
        })
      }}
      />:
      <Autocomplete
      name="schedulerId"
      required
      fullWidth
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params)=><TextField
         {...params}  
         helperText={error.schedulerId}
         error={error.schedulerId}
         label={'Time slot *'}/>}
      getOptionLabel={(option)=>option.operationName}
      options={!CommonUtil.isEmpty(timeSlots)?timeSlots:[]}
      ListboxProps={{ style: { maxHeight: 180 } }}
      onChange={(event,newValue)=>{
        if (!CommonUtil.isEmpty(newValue))
        setPayload({
          ...payload,
          schedulerId:newValue.id
        })
      }}
      />}
      </>
    )
  }

  return (
    <div>
      <DialogBox
        Header={props.selectedObject.id === undefined ? "Add Visiting Access Level" : "Edit Access Level"}
        acceptText="Submit"
        cancelText="Cancel"
        fullWidth={true}
        onClose={handleClose}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={12} sm={9} style={{ marginTop: "20px" }}>
            <CustomTextfield
              label="Name"
              name="name"
              error={error.name}
              required
              handleChange={(e) => handleChange(e)}
              value={payload.name}
            />
          </Grid>

          <Grid item xs={12} sm={9} style={{ marginTop: "20px" }}>
            {timeSlotField()}
          </Grid>
        </Grid>
      </DialogBox>
    </div>
  );
}


export default AddAccessLevelDialog