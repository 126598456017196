import { Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ContinueDilog = (props) => {
    
    const { t } = useTranslation();
    const handleSubmit = () => {
      props.handleSubmit()
    }

    return (
        <Grid>
            <Typography style={{ margin: "1rem 0rem", color: '#3D4977',  fontSize: '17px', fontWeight:'400'}}>{t("The Role has no assigned Permissions to it. ")}</Typography>
            <Grid
                style={{ display: "flex", justifyContent: "center", margin: "12px 0px" }}
            >
                <Button
                 variant="contained"
                    onClick={() => handleSubmit()}
                    style={{ width: "180px", height: "50px", marginRight: "24px"}}
                    >
                    {t("Continue")}
                </Button>
                <Button
                    onClick={() => props.setOpenDilog(!props.openDilog)}
                    variant="outlined"
                    style={{ width: "180px",height: "50px"}}
                >
                    {t("Cancel")}
                </Button>
            </Grid>
        </Grid>
    );
}
export default ContinueDilog