import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { logout, reinit } from '../slices/User';

export default function SecuredRoute({ component: Component, ...otherProps }) {
  const dispatch = useDispatch();
  const [logoutUser, setLogout] = useState(false);

  // useEffect(() => {
    
  //   const jwtToken = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN);

  //   if (jwtToken) {
  //     const decoded_jwtToken = jwt_decode(jwtToken);
  //     const currentTime = Date.now() / 1000;
  //     if (decoded_jwtToken.exp < currentTime) {
  //       dispatch(logout());
  //       window.location.href = '/vis/error';
  //       setLogout(true);
  //     } else {
  //       setLogout(false);
  //       dispatch(reinit(decoded_jwtToken));
  //     }
  //   } else {
  //     dispatch(logout());
  //     window.location.href = '/vis/error';
  //     setLogout(true);
  //   }
  // }, []);

  return <Route {...otherProps} render={(props) =>  <Component {...props} /> } />;
}
