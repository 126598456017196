import { Avatar } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DataTable from "../../components/DataTable/DataTable";
import BlockVisitorForm from "../../components/DialogBoxComponent/BlockVisitorForm";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import VisitorProfileCard from "../../components/VisitorActionsComponents/VisitorProfileCard";
import { forceCheckout, listInvitations } from "../../slices/Invitations/InvitationsSlice";
import CommonUtil from "../../Util/CommonUtils";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import BlockVisitorIcon from "../Invitations/BlockVisitorIcon";
import CheckinViewPage from "./CheckinViewPage";
import ForceCheckoutIcon from "./ForceCheckoutIcon";

export default function CheckInLogs(props) {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const dispatch = useDispatch();
  const [openBlockVisitor, setOpenBlockVisitor] = useState(false);
  const [blockId, setBlockId] = useState();
  const [registrationId, setRegistrationId] = useState();
  const { t } = useTranslation();

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };


  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [filter, setFilter] = React.useState({
    firstName: "",
    lastName: "",
    emailId: "",
    mobile: "",
    status: ["CHECKED_IN"],
    visitorType: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    fromDate: null,
    toDate: null,
    hostId: "",
    hostEmail: "",
    createdBy: "",
    visitMode: ["INVITATION", "WALKIN_REGISTARTION", "ONDEMAND_KIOSK", "SELF_REGISTARTION",],
    isPagable: true,
  });


  useEffect(() => {
    const newvariable = { ...props.filterValue, status: 'CHECKED_IN' };
    if (props.filterState) {
      loadData(newvariable, defaultPaging);
    }
    else { loadData(filter, paging) }
  }, [props.filterValue ? props.filterValue : filter, paging]);

  useEffect(() => {
    if (props.newState) {
      loadData(filter, paging);
    }
    globalsearch(props.svalue);
  }, [props.svalue]);

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  const handleBlockVisit = (params) => {
    setOpenBlockVisitor(!openBlockVisitor);
    setBlockId(params.row.visitorId.id);
    setRegistrationId(params.id);
  };


  const loadData = (page, rowsPerPage, filterValue) => {
    const payload = {
      scheduledEndDate: filter.scheduledEndDate,
      scheduledStartDate: filter.scheduledStartDate,
      status: filter.status,
      visitMode: filter.visitMode,
      page: 0,
      rowsPerPage: 1000,
    };

    dispatch(listInvitations(payload))
      .unwrap()
      .then((data) => {
        console.info(data);
        if ((data.code = "CVAI0000" && data.data)) {
          setRows(data.data.visits);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const globalsearch = (searchedVal) => {
    const payload = {
      scheduledEndDate: filter.scheduledEndDate,
      scheduledStartDate: filter.scheduledStartDate,
      status: filter.status,
      visitMode: filter.visitMode,
      page: 0,
      rowsPerPage: 1000,
    };

    dispatch(listInvitations(payload))
      .unwrap()
      .then((data) => {
        console.info(data);
        if ((data.code = "CVAI0000" && data.data)) {

          if (searchedVal === 'Check In') {
            searchedVal = 'CHECKED_IN';
          }

          const filteredRows = data?.data.visits.filter((test) => {
            return (
              test.visitorId.firstName
                .toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              test.visitorId.lastName
                .toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              test.purpose.toLowerCase().includes(searchedVal.toLowerCase()) ||
              test.hostName.toLowerCase().includes(searchedVal.toLowerCase()) ||
              test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
              `${test.visitorId.firstName} ${test.visitorId.lastName}`
                .toLowerCase()
                .includes(searchedVal.toLowerCase())
            );
          });
          setRows(filteredRows);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });


  };


  const handleForceCheckOut = (params) => {
    dispatch(forceCheckout(params.id))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0000")) {
          toast.success("Visitor  Check Out Successfully");
          loadData(0, rowsPerPage);
        } else {
        }
      })
      .catch((er) => { });
  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          alt={params.row.visitorId.firstName}
          src={`data:image/png;base64,${params.row.visitorId.profile}`}
        ></Avatar>
      }
      child={<Avatar
        style={{
          width: '300px', height: '300px', border: "12px solid #FFFF",
          fontSize: '100px',
          borderRadius: '1px'
        }}
        alt={params.row.visitorId.firstName}
        src={`data:image/png;base64,${params.row.visitorId.profile}`}
      ></Avatar>}
    />
  )

  const columns = React.useMemo(() => [
    {
      field: "visitorId.profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return (
          ZoomHandaler(params)
        );
      },
    },
    {
      field: "visitorId.firstName",
      headerName: "Name",
      flex: 1,
      minWidth: 10,
      valueGetter: (params) =>
        params.row.visitorId.firstName + " " + params.row.visitorId.lastName,
    },
    {
      field: "visitorTypeCode",
      headerName: "Purpose",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitorTypeCode,
    },

    {
      field: "scheduledStartDate",
      headerName: "Invite Date",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        moment(CommonUtil.getLocalDate(params.row.scheduledStartDate)).format("DD-MM-YYYY kk:mm")
    },
    {
      field: "checkIn",
      headerName: "Check In",
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        moment(CommonUtil.getLocalDate(params.row.checkIn)).format("DD-MM-YYYY kk:mm")
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />;
      },
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CheckinViewPage rowData={params.row} />}
          label="View"
        />,
        <GridActionsCellItem
          icon={<BlockVisitorIcon />}
          label="BlockVisitor"
          onClick={() => handleBlockVisit(params)}
        />,
        <GridActionsCellItem
          icon={<ForceCheckoutIcon />}
          label="Force Checkout"
          onClick={() => handleForceCheckOut(params)}
        />,
      ],
    },
  ]);
  return (
    <>
      <DataTable
        columns={columns}
        rows={rows}
        page={page}
        pageCount={totalPages}
        pageSize={rowsPerPage}
        handleChange={(newPage, size) => handleChange(newPage, size)}
      />

      {openBlockVisitor && (
        <BlockVisitorForm
          open={openBlockVisitor}
          handleClose={(data) => {
            setOpenBlockVisitor(data);
            loadData(filter, paging);
          }}
          BlockVisitorId={blockId}
          BlockRegistrationId={registrationId}
        />
      )}
    </>
  );
}
