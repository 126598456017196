import React from "react";
import { Tooltip, Grid } from "@mui/material";

const ChecklistIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <>
      <Tooltip title="Add Checklist Items">
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="20.978" height="21" viewBox="0 0 20.978 21">
            <path id="checklist-item-hoverstate" d="M8602.979-7277a5,5,0,0,1,5-5,5,5,0,0,1,5,5,5.006,5.006,0,0,1-5,5A5,5,0,0,1,8602.979-7277Zm1.874,0a.671.671,0,0,0,.67.672h1.781v1.781a.675.675,0,0,0,.675.672.674.674,0,0,0,.672-.672v-1.781h1.779a.673.673,0,0,0,.672-.672.673.673,0,0,0-.672-.67h-1.779v-1.781a.673.673,0,0,0-.672-.672.673.673,0,0,0-.675.672v1.781h-1.781A.671.671,0,0,0,8604.854-7277Zm-2.075,4.69h-9.831a.942.942,0,0,1-.67-.277.928.928,0,0,1-.276-.666v-18.8a.943.943,0,0,1,.942-.945h15.042a.931.931,0,0,1,.67.277.922.922,0,0,1,.272.668v8.119a7.224,7.224,0,0,0-.951-.065,7.02,7.02,0,0,0-.929.063v-7.181h-13.164v16.925h7.68a7.032,7.032,0,0,0,1.217,1.883Zm-2.309-11.4h-.1a.908.908,0,0,1-.893-.964.9.9,0,0,1,.93-.921h3.815a.9.9,0,0,1,.942.942.967.967,0,0,1-.236.646.906.906,0,0,1-.706.3Zm-4.706-.942a.941.941,0,0,1,.94-.94.941.941,0,0,1,.94.94.943.943,0,0,1-.94.942A.943.943,0,0,1,8595.764-7284.655Zm8.472-2.821h-3.865a.906.906,0,0,1-.869-.973.9.9,0,0,1,.932-.908h3.82a.9.9,0,0,1,.929.94.9.9,0,0,1-.936.94Zm-8.472-.938a.941.941,0,0,1,.94-.94.941.941,0,0,1,.94.94.941.941,0,0,1-.94.938A.941.941,0,0,1,8595.764-7288.414Z" transform="translate(-8592.001 7292.999)" fill="#3d4977"/>
          </svg>          
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="20.978" height="21" viewBox="0 0 20.978 21">
            <path id="checklist-item" d="M8602.979-7277a5,5,0,0,1,5-5,5,5,0,0,1,5,5,5.006,5.006,0,0,1-5,5A5,5,0,0,1,8602.979-7277Zm1.874,0a.671.671,0,0,0,.67.672h1.781v1.781a.675.675,0,0,0,.675.672.674.674,0,0,0,.672-.672v-1.781h1.779a.673.673,0,0,0,.672-.672.673.673,0,0,0-.672-.67h-1.779v-1.781a.673.673,0,0,0-.672-.672.673.673,0,0,0-.675.672v1.781h-1.781A.671.671,0,0,0,8604.854-7277Zm-2.075,4.69h-9.831a.942.942,0,0,1-.67-.277.928.928,0,0,1-.276-.666v-18.8a.943.943,0,0,1,.942-.945h15.042a.931.931,0,0,1,.67.277.922.922,0,0,1,.272.668v8.119a7.224,7.224,0,0,0-.951-.065,7.02,7.02,0,0,0-.929.063v-7.181h-13.164v16.925h7.68a7.032,7.032,0,0,0,1.217,1.883Zm-2.309-11.4h-.1a.908.908,0,0,1-.893-.964.9.9,0,0,1,.93-.921h3.815a.9.9,0,0,1,.942.942.967.967,0,0,1-.236.646.906.906,0,0,1-.706.3Zm-4.706-.942a.941.941,0,0,1,.94-.94.941.941,0,0,1,.94.94.943.943,0,0,1-.94.942A.943.943,0,0,1,8595.764-7284.655Zm8.472-2.821h-3.865a.906.906,0,0,1-.869-.973.9.9,0,0,1,.932-.908h3.82a.9.9,0,0,1,.929.94.9.9,0,0,1-.936.94Zm-8.472-.938a.941.941,0,0,1,.94-.94.941.941,0,0,1,.94.94.941.941,0,0,1-.94.938A.941.941,0,0,1,8595.764-7288.414Z" transform="translate(-8592.001 7292.999)" fill="#c0c7cc"/>
          </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default ChecklistIcon;
