import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import { DialogContent, Typography } from "@mui/material";

import { ReactComponent as DeleteVisitIcon } from "../../../src/assets/DeleteVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DialogBox from "../DialogBoxComponent/DialogBox";
import DeleteIcon from "../../Pages/Watchlist/DeleteIcon";
import DeleteDialogBox from "./DeleteDialogBox";

export default function DeleteDialog(props) {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleSubmit = () => {
        handleClose();
        props.handleDelete();
    };

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Grid onClick={handleOpen}>
                <DeleteIcon />
            </Grid>
            <DeleteDialogBox
                Header="Delete"
                acceptText="Confirm"
                cancelText="Cancel"
                fullWidth={true}
                onClose={handleClose}
                cancel={handleClose}
                onSubmit={handleSubmit}
                open={open}
            >
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Grid >
                        <DeleteVisitIcon />
                    </Grid>
                    <Grid p={2} style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                        <Typography gutterBottom>
                            {props.text + props.name}
                        </Typography> &nbsp;
                        <Typography style={{ fontWeight: "bold" }}>
                            {props.deviceName} ?
                        </Typography>
                    </Grid>
                </DialogContent>
            </DeleteDialogBox>
        </>
    );
}
