import React, { useEffect } from 'react';
import { useState } from 'react';
import { Box, Grid, FormControlLabel, Checkbox } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { TextField, Typography } from '@mui/material';
import { TimePicker } from 'antd';
import localeES from 'antd/es/date-picker/locale/es_ES';
import localeZH from 'antd/es/date-picker/locale/zh_CN';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeRU from 'antd/es/date-picker/locale/ru_RU';
import localeJA from 'antd/es/date-picker/locale/ja_JP';
import localeTH from 'antd/es/date-picker/locale/th_TH';
import localeID from 'antd/es/date-picker/locale/id_ID';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
// import CancelButton from '../../components/CancelButton';
// import SubmitButton from '../../components/SubmitButton';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';
import { getTimeSlotById, updateTimeSLots } from '../../slices/TimeSlot/TimeSlotSlice';
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons';
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons';
import { useDispatch } from 'react-redux';
import TitleBar from '../../components/v4/TitleBar';
import CustomTextfield from '../../components/CustomInputs/CustomTextfield';
import { REACT_TIME_SLOTS } from '../../actions/EndPoints';
import Toast from '../../components/ToastContainer/CustomToast';

export default function UpdateSchedules() {
  const { t } = useTranslation();
  const location = useLocation();
  const language = BrowserLanguage.getDefaultLanguage();
  const history = useHistory();
  const dispatch = useDispatch();
  //   const breadcrumb = [{ path: '', name: t('SCHEDULES006') }];

  const [copyMonday, setCopyMonday] = useState(false);

  const zlink = localStorage.getItem("redirectFrom");

  const [monday1, setMonday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [monday2, setMonday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [monday3, setMonday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [tuesday1, setTuesday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [tuesday2, setTuesday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [tuesday3, setTuesday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [wednesday1, setWednesday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [wednesday2, setWednesday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [wednesday3, setWednesday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [thursday1, setThursday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [thursday2, setThursday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [thursday3, setThursday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [friday1, setFriday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [friday2, setFriday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [friday3, setFriday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [saturday1, setSaturday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [saturday2, setSaturday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [saturday3, setSaturday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [sunday1, setSunday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [sunday2, setSunday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
  const [sunday3, setSunday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);

  const [error, setError] = useState({
    name: false,
    remark: false,
  });
  const localeMap = {
    en: localeEN,
    es: localeES,
    zh: localeZH,
    ru: localeRU,
    ja: localeJA,
    th: localeTH,
    idn: localeID,
  };
  const [payload, setPayload] = React.useState({
    id: '',
    companyId: '',
    name: '',
    remark: '',
    sundayStart1: '',
    sundayEnd1: '',
    sundayStart2: '',
    sundayEnd2: '',
    sundayStart3: '',
    sundayEnd3: '',
    mondayStart1: '',
    mondayEnd1: '',
    mondayStart2: '',
    mondayEnd2: '',
    mondayStart3: '',
    mondayEnd3: '',
    tuesdayStart1: '',
    tuesdayEnd1: '',
    tuesdayStart2: '',
    tuesdayEnd2: '',
    tuesdayStart3: '',
    tuesdayEnd3: '',
    wednesdayStart1: '',
    wednesdayEnd1: '',
    wednesdayStart2: '',
    wednesdayEnd2: '',
    wednesdayStart3: '',
    wednesdayEnd3: '',
    thursdayStart1: '',
    thursdayEnd1: '',
    thursdayStart2: '',
    thursdayEnd2: '',
    thursdayStart3: '',
    thursdayEnd3: '',
    fridayStart1: '',
    fridayEnd1: '',
    fridayStart2: '',
    fridayEnd2: '',
    fridayStart3: '',
    fridayEnd3: '',
    saturdayStart1: '',
    saturdayEnd1: '',
    saturdayStart2: '',
    saturdayEnd2: '',
    saturdayStart3: '',
    saturdayEnd3: '',
  });

  const [schedule, setSchedule] = React.useState({});
  const [timezones, setTimezones] = React.useState([]);

  useEffect(() => {
    let id = location?.state?.data;
    dispatch(getTimeSlotById(id)).unwrap().then((data) => {
      if (data?.code === 'DMSI0000') {
        if (!CommonUtil.isEmpty(data.data)) {
          setSchedule(data?.data);
          setTimezones(data?.data?.timezones);
          setPayload({
            ...payload,
            name: data.data.name,
            operationName: data.data.operationName,
          });

          data.data.timezones.map((timezone) => {
            switch (timezone.intervals) {
              case 1:
                {
                  switch (timezone.type) {
                    case 1: {
                      setMonday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 2: {
                      setTuesday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 3: {
                      setWednesday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 4: {
                      setThursday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 5: {
                      setFriday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 6: {
                      setSaturday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 0: {
                      setSunday1([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                  }
                }
                break;
              case 2:
                {
                  switch (timezone.type) {
                    case 1: {
                      setMonday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 2: {
                      setTuesday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 3: {
                      setWednesday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 4: {
                      setThursday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 5: {
                      setFriday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 6: {
                      setSaturday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 0: {
                      setSunday2([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                  }
                }
                break;
              case 3:
                {
                  switch (timezone.type) {
                    case 1: {
                      setMonday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 2: {
                      setTuesday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 3: {
                      setWednesday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 4: {
                      setThursday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 5: {
                      setFriday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 6: {
                      setSaturday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                    case 0: {
                      setSunday3([moment(formatTime(timezone.startTime), 'HH:mm'), moment(formatTime(timezone.endTime), 'HH:mm')]);
                      break;
                    }
                  }
                }
                break;
            }
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (copyMonday) handleCopyMonday(monday1, monday2, monday3);
  }, [monday1, monday2, monday3]);

  const timeFormat = 'HH:mm';

  const handleChange = (event) => {
    var val = event.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (event.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    setCopyMonday(event.target.checked);
    var defaultTime = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
    if (event.target.checked) handleCopyMonday(monday1, monday2, monday3);
    else handleCopyMonday(defaultTime, defaultTime, defaultTime);
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.name)) {
      setError({...error, name: true,});
      toast.error('Name is required');
      return;
    }

    else if (!CommonUtil.validateName(payload.name)) {
      Toast(('Please enter the valid Time slots name'), 'error')
      setError({...error, name: true});
      return;
    }

    const state = {
      name: payload.name,
      timezones: timezones,
      id: location?.state?.data
    };
    dispatch(updateTimeSLots(state)).unwrap().then((res) => {
      if (res?.code === 'DMSI0008') {
        Toast('Time slot update successfully', 'success');
        handleClose();
      } else if (res?.code === 'DMSE0056') {
        Toast(res.msg, 'success');
      }
      else if (res?.code === 'CVAE1004') {
        Toast('Name already exist', 'error');
      } else {
        Toast(res.msg, 'error');
      }
    });
  };

  const handleClose = () => {
    history.push({
      pathname: REACT_TIME_SLOTS,
    });
  };

  const handleCopyMonday = (monday1, monday2, monday3) => {
    setThursday1(monday1);
    setWednesday1(monday1);
    setTuesday1(monday1);
    setFriday1(monday1);
    setSunday1(monday1);
    setSaturday1(monday1);
    handleTimeIntervalChange(1, 2, monday1);
    handleTimeIntervalChange(1, 3, monday1);
    handleTimeIntervalChange(1, 4, monday1);
    handleTimeIntervalChange(1, 5, monday1);
    handleTimeIntervalChange(1, 6, monday1);
    handleTimeIntervalChange(1, 0, monday1);

    setSunday2(monday2);
    setTuesday2(monday2);
    setWednesday2(monday2);
    setThursday2(monday2);
    setFriday2(monday2);
    setSaturday2(monday2);
    handleTimeIntervalChange(2, 2, monday2);
    handleTimeIntervalChange(2, 3, monday2);
    handleTimeIntervalChange(2, 4, monday2);
    handleTimeIntervalChange(2, 5, monday2);
    handleTimeIntervalChange(2, 6, monday2);
    handleTimeIntervalChange(2, 0, monday2);

    setTuesday3(monday3);
    setWednesday3(monday3);
    setThursday3(monday3);
    setFriday3(monday3);
    setSaturday3(monday3);
    setSunday3(monday3);
    handleTimeIntervalChange(3, 2, monday3);
    handleTimeIntervalChange(3, 3, monday3);
    handleTimeIntervalChange(3, 4, monday3);
    handleTimeIntervalChange(3, 5, monday3);
    handleTimeIntervalChange(3, 6, monday3);
    handleTimeIntervalChange(3, 0, monday3);
  };

  const formatTime = (time) => {
    if (CommonUtil.isEmptyString(time)) return '00:00';
    else return time;
  };

  const handleTimeIntervalChange = (interval, type, time) => {
    var tempTimeZones = timezones;
    var index = tempTimeZones.findIndex((timezone) => timezone.intervals === interval && timezone.type === type);
    if (index === -1) {
      const newObj = {
        intervals: interval,
        type: type,
        startTime: time[0].format('HH:mm'),
        endTime: time[1].format('HH:mm'),
      };
      tempTimeZones.push(newObj);
    } else {
      tempTimeZones[index].startTime = time[0].format('HH:mm');
      tempTimeZones[index].endTime = time[1].format('HH:mm');
    }

    setTimezones(tempTimeZones);
  };

  return (
    <>
      {!zlink ? (<>
        <TitleBar title={t("Update Time Slots")} back_button={true} />
      </>) : (<>
        <TitleBar title={t("Edit Time Slot")} back_button={true} />
      </>)}

      <Grid style={{ paddingTop: '40px' }}>
        <div className='custom-container' style={{ minHeight: '6vh' }}>
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center' m={1} p={1}>
                <CustomTextfield
                  label="Name"
                  error={error.name}
                  name="name"
                  value={payload.name}
                  handleChange={(event) => {
                    setPayload({ ...payload, name: event.target.value });
                    setError({ ...error, name: false });
                  }}
                  helperText={error.name}
                  validation="alphabets"
                  required
                  inputProps={{ maxLength: 30 }}
                />
              </Box>
            </Grid>
          </Grid>
        </div>

        <div className='custom-container' style={{ fontSize: 'larger', marginTop: '25px', marginBottom: '10px' }}>
          <Grid container style={{ paddingBottom: '15px', paddingTop: '20px' }} >
            <Grid item xs={12} sm={2}>
              <Box display='flex' justifyContent='center'>
                <span style={{ verticalAlign: 'center' }}>{t('Monday')}</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} >
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={monday1}
                  onChange={(time, timeString) => {
                    setMonday1(time);
                    handleTimeIntervalChange(1, 1, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={monday2}
                  onChange={(time, timeString) => {
                    setMonday2(time);

                    handleTimeIntervalChange(2, 1, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={monday3}
                  onChange={(time, timeString) => {
                    setMonday3(time);

                    handleTimeIntervalChange(3, 1, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container style={{ paddingBottom: '15px' }}>
            <Grid item xs={12} sm={2}>
              <Box display='flex' justifyContent='center'>
                <span style={{ verticalAlign: 'center' }}>{t('Tuesday')}</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={tuesday1}
                  onChange={(time, timeString) => {
                    setTuesday1(time);

                    handleTimeIntervalChange(1, 2, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={tuesday2}
                  onChange={(time, timeString) => {
                    setTuesday2(time);

                    handleTimeIntervalChange(2, 2, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={tuesday3}
                  onChange={(time, timeString) => {
                    setTuesday3(time);

                    handleTimeIntervalChange(3, 2, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container style={{ paddingBottom: '15px' }}>
            <Grid item xs={12} sm={2}>
              <Box display='flex' justifyContent='center'>
                <span style={{ verticalAlign: 'center' }}>{t('Wednesday')}</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={wednesday1}
                  onChange={(time, timeString) => {
                    setWednesday1(time);
                    handleTimeIntervalChange(1, 3, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={wednesday2}
                  onChange={(time, timeString) => {
                    setWednesday2(time);
                    handleTimeIntervalChange(2, 3, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={wednesday3}
                  onChange={(time, timeString) => {
                    setWednesday3(time);
                    handleTimeIntervalChange(3, 3, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container style={{ paddingBottom: '15px' }}>
            <Grid item xs={12} sm={2}>
              <Box display='flex' justifyContent='center'>
                <span style={{ verticalAlign: 'center' }}>{t('Thursday')}</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={thursday1}
                  onChange={(time, timeString) => {
                    setThursday1(time);
                    handleTimeIntervalChange(1, 4, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={thursday2}
                  onChange={(time, timeString) => {
                    setThursday2(time);
                    handleTimeIntervalChange(2, 4, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={thursday3}
                  onChange={(time, timeString) => {
                    setThursday3(time);
                    handleTimeIntervalChange(3, 4, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container style={{ paddingBottom: '15px' }}>
            <Grid item xs={12} sm={2}>
              <Box display='flex' justifyContent='center'>
                <span style={{ verticalAlign: 'center' }}>{t('Friday')}</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={friday1}
                  onChange={(time, timeString) => {
                    setFriday1(time);
                    handleTimeIntervalChange(1, 5, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={friday2}
                  onChange={(time, timeString) => {
                    setFriday2(time);
                    handleTimeIntervalChange(2, 5, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={friday3}
                  onChange={(time, timeString) => {
                    setFriday3(time);
                    handleTimeIntervalChange(3, 5, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container style={{ paddingBottom: '15px' }}>
            <Grid item xs={12} sm={2}>
              <Box display='flex' justifyContent='center'>
                <span style={{ verticalAlign: 'center' }}>{t('Saturday')}</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={saturday1}
                  onChange={(time, timeString) => {
                    setSaturday1(time);
                    handleTimeIntervalChange(1, 6, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={saturday2}
                  onChange={(time, timeString) => {
                    setSaturday2(time);
                    handleTimeIntervalChange(2, 6, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={saturday3}
                  onChange={(time, timeString) => {
                    setSaturday3(time);
                    handleTimeIntervalChange(3, 6, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container style={{ paddingBottom: '15px' }}>
            <Grid item xs={12} sm={2}>
              <Box display='flex' justifyContent='center'>
                <span style={{ verticalAlign: 'center' }}>{t('Sunday')}</span>
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={sunday1}
                  onChange={(time, timeString) => {
                    setSunday1(time);
                    handleTimeIntervalChange(1, 0, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={sunday2}
                  onChange={(time, timeString) => {
                    setSunday2(time);
                    handleTimeIntervalChange(2, 0, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3} style={{ paddingBottom: '20px' }}>
              <Box display='flex' justifyContent='center'>
                <TimePicker.RangePicker
                  allowClear={false}
                  locale={localeMap[language]}
                  format={timeFormat}
                  value={sunday3}
                  onChange={(time, timeString) => {
                    setSunday3(time);
                    handleTimeIntervalChange(3, 0, time);
                  }}
                  order={false}
                  placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                />
              </Box>
            </Grid>
          </Grid>
        </div>
        <Grid display='flex' p={1}>
          <Box p={1} flexGrow={1} >
            <FormControlLabel
              control={<Checkbox
                style={{ color: process.env.REACT_APP_BG_ICON }}
                name='copyMonday'
                checked={copyMonday}
                onChange={handleChange}
              />}
              label={<Typography style={{ fontSize: 'large' }}>{t("Copy Monday's setting to other weekdays")}
              </Typography>}
            />
          </Box>
          <Box display='flex' style={{ flexDirection: 'row-reverse' }}>
            <Box p={1}>
              <CancelButtons onClick={handleClose} >
                {t('COMMON008')}
              </CancelButtons>
            </Box>
            <Box p={1}>
              <SubmitButtons onClick={handleSubmit} >
                {t('COMMON007')}
              </SubmitButtons>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
