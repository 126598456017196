import { Grid } from "@material-ui/core";
import React from "react";
import { Typography } from "@mui/material";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as DeleteVisitIcon } from "../../../src/assets/DeleteVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Toast from "../../components/ToastContainer/CustomToast";
import { deleteWatchlistTemplate } from "../../slices/WatchlistGlobal/WatchlistGlobalSlice";

export default function DeleteWatchlist(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [payload, setPayload] = React.useState({
    watchListId: props.DeleteId,
  });

  const handleSubmit = () => {
    dispatch(deleteWatchlistTemplate(payload.watchListId))
      .unwrap()
      .then((data) => {
        handleClose(false);
        if (data?.code === "CVAI2003") {
          Toast(t("WATCHLISTDELETEMSG"), "success");
          handleClose();
        } else {
          Toast(data.message,"error");
        }
      })
      .catch((er) => {});
  };

  return (
    <>
      <DialogBox
        Header={t("COMMON004")}
        acceptText={t("CONFIRM")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
          style={{ padding: "20px" }}
        >
          <Grid>
            <DeleteVisitIcon />
          </Grid>
          <Grid>
            <Grid style={{wordWrap: "break-word", opacity: 1, textAlign: "center", display: "flex",
                        flexDirection: "row", fontSize: "17px", marginTop: "15px",}}>
               <Typography style={{ color: "#242424", marginRight: "4px" }}>
                {t("COMMON018")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
