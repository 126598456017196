import { Tooltip, Grid } from "@mui/material";
import React, {  useState } from "react";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as FilterIcon } from "../../assets/FilterIcon.svg";
import { Typography } from "@mui/material";
import { makeStyles} from "@material-ui/core/styles";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import Toast from "../../components/ToastContainer/CustomToast";
import { useTranslation } from "react-i18next";


const useStyles = makeStyles((theme) => ({
    textarea: {
        resize: "both",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        background: "#3d4977",
        width: "100%",
        padding: "5px",
        textAlign: "center",
        borderRadius: "5px",
        color: "#FFFFFF",
    },
}));

export default function FilterDevice(props) {

    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation();
    const defaultFilter = {
        deviceAlias: '',
        deviceSn: '',
        deviceType: ''
    }
    const [filterQuery, setFilterQuery] = useState({...defaultFilter});

    const handleChange = (event) => {
        const name = event.target.name;
        setFilterQuery({
            ...filterQuery,
            [name]: event.target.value,
        });
    };

    const handleClose = () => {
        setFilterQuery(defaultFilter);
        setOpen(false);
    }

    const handleSubmit = () => {

    if (filterQuery.deviceAlias.trim() === "" && filterQuery.deviceSn.trim() === "" && filterQuery.deviceType.trim() === "") {
            Toast(t("COMMON015"), "error");
            return;
          }
        props.onSubmit(filterQuery);
        handleClose();
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const reset = () => {
        if (filterQuery.deviceAlias.trim() === "" && filterQuery.deviceSn.trim() === "" && filterQuery.deviceType.trim() === "") {
            Toast(t("COMMON027"), "error");
            return;
          }
        setFilterQuery(defaultFilter);
    }

    return (
        <>
            <Tooltip title={ <Typography fontSize={12} fontWeight={500}>Filter</Typography> } >
                <FilterIcon onClick={() => setOpen(true)} style={{cursor:'pointer'}}>
                    <FilterIcon />
                </FilterIcon>
            </Tooltip>

            <DialogBox
                Header="Filter"
                acceptText="Apply"
                cancelText="Reset"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                cancel={reset}
            >
                <Grid container justifyContent="center"alignItems={"center"}>
                    <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                        <CustomTextfield
                            inputProps={{ className: classes.textarea }}
                            label='Device Name'
                            name="deviceAlias"
                            value={filterQuery.deviceAlias}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                        <CustomTextfield
                            inputProps={{ className: classes.textarea }}
                            label='Serial Number'
                            name="deviceSn"
                            value={filterQuery.deviceSn}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid>
{/* 
                    <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                        <CustomTextfield
                            inputProps={{ className: classes.textarea }}
                            label='Device Type'
                            name="deviceType"
                            value={filterQuery.deviceType}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid> */}

                </Grid>
            </DialogBox>
        </>
    );
}
