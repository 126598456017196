import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import LoginLayoutV2 from './LoginLayoutV2';
import { useTranslation } from 'react-i18next';
import email_sent from '../../assets/USA_Images/login_images/linkexpirelft.svg';
import vis_email_sent from '../../assets/images/onboard/email_img.svg';
import link_expired from '../../assets/USA_Images/login_images/linkexpire.svg';
import vis_link_expired from '../../assets/images/onboard/link_expired.svg';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '70%',
        marginTop: theme.spacing(3),
    },
    submit: {
        backgroundColor: '#36c96d',
        borderRadius: '0.2rem',
        padding: '10px',
        '&:hover': {
            background: '#119743',
        },
    },
}));

function LinkExpired(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    return (
        <>
            <LoginLayoutV2 success={true} lang={true} src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  email_sent : vis_email_sent} >
                <Grid className='center_div'>
                    <Grid container alignItems='center' >
                        <Grid item className='logo-center'>
                            <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  link_expired : vis_link_expired} alt='logo' style={{ width: '280px' }} />
                        </Grid>
                    </Grid>
                    <form className={classes.form} noValidate autoComplete='off'>
                        <Grid item xs={12} style={{ marginBottom: '25px', marginTop: '10px' }}>
                            <Typography variant='h2' align='center' style={{ color: '#464646', fontWeight: '500' }}>
                                This Link is Expired
                            </Typography>
                        </Grid>
                    </form>
                </Grid>
            </LoginLayoutV2>
        </>
    );
}
export default withRouter(LinkExpired);
