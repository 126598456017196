import React from "react";
import { Tooltip, Grid } from "@mui/material";
import { useTranslation } from 'react-i18next';

const ViewRoleAndPermissionIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Tooltip title={t("View Permissions")}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="Group_5930"
              data-name="Group 5930"
              width="22.3"
              height="22.3"
              viewBox="0 0 22.3 22.3"
            >
              <path
                id="Path_6184"
                data-name="Path 6184"
                d="M0,0H22.3V22.3H0Z"
                fill="none"
              />
              <path
                id="Path_6185"
                data-name="Path 6185"
                d="M12.221,9A10.989,10.989,0,0,0,2,15.969a10.98,10.98,0,0,0,20.441,0A10.984,10.984,0,0,0,12.221,9Zm0,11.614a4.646,4.646,0,1,1,4.646-4.646A4.647,4.647,0,0,1,12.221,20.614Zm0-7.433a2.787,2.787,0,1,0,2.787,2.787A2.79,2.79,0,0,0,12.221,13.181Z"
                transform="translate(-1.071 -4.819)"
                fill="#3d4977"
              />
            </svg>
          ) : (
            <svg
              id="Group_5930"
              data-name="Group 5930"
              xmlns="http://www.w3.org/2000/svg"
              width="22.3"
              height="22.3"
              viewBox="0 0 22.3 22.3"
            >
              <path
                id="Path_6184"
                data-name="Path 6184"
                d="M0,0H22.3V22.3H0Z"
                fill="none"
              />
              <path
                className="test-hover-css"
                id="Path_6185"
                data-name="Path 6185"
                d="M12.221,9A10.989,10.989,0,0,0,2,15.969a10.98,10.98,0,0,0,20.441,0A10.984,10.984,0,0,0,12.221,9Zm0,11.614a4.646,4.646,0,1,1,4.646-4.646A4.647,4.647,0,0,1,12.221,20.614Zm0-7.433a2.787,2.787,0,1,0,2.787,2.787A2.79,2.79,0,0,0,12.221,13.181Z"
                transform="translate(-1.071 -4.819)"
                fill="rgba(99,115,129,0.4)"
              />
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default ViewRoleAndPermissionIcon;
