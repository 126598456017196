import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import PerpouseTypeService from '../../services/PerpouseType/PerpouseTypeService';

const initialState ={
};
export const listVisitorTypeZlink = createAsyncThunk('visitType/', async (payload) => {
    const res = await PerpouseTypeService.getAll(payload);
    return res.data;
});

export const addPurposeTypeZlink = createAsyncThunk('visitType/add', async (payload) => {
    const res = await PerpouseTypeService.addPurposeTypeZlink(payload);
    return res.data;
});

export const updateVisitorType = createAsyncThunk('visitType/update', async (payload) => {
    const res = await PerpouseTypeService.updateVisitorType(payload);
    return res.data;
});

const PerpouseTypeSlice = createSlice({
    name: 'visitType',
    initialState,
    reducers: {},
    extraReducers: {
        [listVisitorTypeZlink.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})
const { reducer } = PerpouseTypeSlice;
export default reducer;