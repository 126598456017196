import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  FormGroup,
} from "@material-ui/core";
import { Divider, IconButton } from "@mui/material";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import { getAddressTypes } from '../../../slices/Site/AddressTypeSlice'
import { getSiteTypeList } from "../../../slices/Site/SiteTypeSlice";
import {
  cityData,
  countryData,
  stateData,
  timeZoneData,
} from "./AddressFormData";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CommonUtil from "../../../Util/CommonUtils";
import { createSite, getAllSites, getSitesWithoutPaging, updateSite } from "../../../slices/Site/SiteSlice";
import SubmitButtons from "../../../components/DialogBoxComponent/SubmitButtons";
import CancelButtons from "../../../components/DialogBoxComponent/CancelButtons";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextfield from "../../../components/CustomInputs/CustomTextfield";
import CustomDropdown from "../../../components/CustomInputs/CustomDropdown";
import Toast from "../../../components/ToastContainer/CustomToast";
import AutocompleteComponent from "../../../components/CustomInputs/AutocompleteComponent";


const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: "both",
  },
  heading: {
    fontSize: '16px',
    fontWeight: 500,
    width: "100%",
    textAlign: "left",
    color: process.env.REACT_APP_COLOR_ACCORDION
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor:process.env.REACT_APP_BG_SWITCH,
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: process.env.REACT_APP_BG_SWITCH,
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default function AddSite(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const [open, setOpen] = React.useState(props.open);
  const [siteTypes, setSiteTypes] = React.useState([]);
  const [parentSites, setParentSites] = React.useState([]);
  const [addressTypes, setAddressTypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [selectStates, setSelectStates] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [timeZones, setTimeZones] = React.useState([]);
  const [selectArea, setSelectArea] = React.useState(true);
  const [expanded, setExpanded] = React.useState(false);
  const [expandedSite, setExpandedSite] = React.useState(true);

  const handleValidation = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleValidationSite = (panel) => (event, isExpanded) => {
    setExpandedSite(isExpanded ? panel : false);
  };

  const [state, setState] = React.useState({
    id: "",
    name: "",
    description: "",
    parentId: "",
    siteTypeId: "",
    address: {
      id: "",
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      country: "",
      state: "",
      city: "",
      areaCode: "",
      latitude: "",
      longitude: "",
      radius: "",
      timeZone: "",
      dayLightSaving: true,
      isDefault: true,
      addressTypeId: "",
      employeeId: "",
      userId: "",
      siteId: "",
    },
  });

  const [error, setError] = React.useState({
    name: false,
    description: false,
    parentId: false,
    siteTypeId: false,
    address: {
      addressLine1: false,
      addressLine2: false,
      addressLine3: false,
      country: false,
      state: false,
      city: false,
      areaCode: false,
      latitude: false,
      longitude: false,
      radius: false,
      timeZone: false,
      dayLightSaving: false,
      isDefault: false,
      addressTypeId: false,
      employeeId: false,
      userId: false,
      siteId: false,
    },
  });

  useEffect(() => {
    setOpen(props.open);
    if (
      !CommonUtil.isEmpty(props.selectedObject) &&
      !CommonUtil.isEmpty(props.selectedObject.address)
    ) {
      setState({
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        description: props.selectedObject.description,
        parentId: props.selectedObject.parentId,
        siteTypeId: props.selectedObject.siteTypeId,
        address: {
          id: props.selectedObject.address.id,
          addressLine1: props.selectedObject.address.addressLine1,
          addressLine2: props.selectedObject.address.addressLine2,
          addressLine3: props.selectedObject.address.addressLine3,
          country: props.selectedObject.address.country,
          state: props.selectedObject.address.state,
          city: props.selectedObject.address.city,
          areaCode: props.selectedObject.address.areaCode,
          latitude: props.selectedObject.address.latitude,
          longitude: props.selectedObject.address.longitude,
          radius: props.selectedObject.address.radius,
          timeZone: props.selectedObject.address.timeZone,
          dayLightSaving: props.selectedObject.address.dayLightSaving,
          isDefault: props.selectedObject.address.isDefault,
          addressTypeId: props.selectedObject.address.addressTypeId,
          employeeId: props.selectedObject.address.employeeId,
          userId: props.selectedObject.address.userId,
          siteId: props.selectedObject.address.siteId,
        },
      });
    }
  }, [props.open, props.selectedObject]);

  useEffect(() => {
    setOpen(props.open);
    if (
      !CommonUtil.isEmpty(props.selectedObject) &&
      CommonUtil.isEmpty(props.selectedObject.address)
    ) {
      setState({
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        description: props.selectedObject.description,
        parentId: props.selectedObject.parentId,
        siteTypeId: props.selectedObject.siteTypeId,
        address: {
          id: "",
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          country: "",
          state: "",
          city: "",
          areaCode: "",
          latitude: "",
          longitude: "",
          radius: "",
          timeZone: "",
          dayLightSaving: true,
          isDefault: true,
          addressTypeId: "",
          employeeId: "",
          userId: "",
          siteId: "",
        },
      });
    }
  }, [props.open, props.selectedObject]);

  const payload = {
    page: 1,
    rowsPerPage: 10000,
    code: '',
    name: ''
  };

  const payloadSiteType = {
    page: 1,
    rowsPerPage: 10000,
    filter: {
      code: '',
      name: ''
    }
  };

  useEffect(() => {

    dispatch(getAllSites(payload)).unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000')
          setParentSites(data.data.site);
      });

    dispatch(getSiteTypeList(payloadSiteType)).unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000')
          setSiteTypes(data.data.siteType);
      });

    dispatch(getAddressTypes(payload)).unwrap()
      .then((data) => {
        if (data.code === 'LMSI0000')
          setAddressTypes(data.data.addressType);
      });

    setCountries(countryData);
    setSelectStates(stateData);
    setCities(cityData);
    setTimeZones(timeZoneData);
  }, []);

  const handleClose = () => {
    props.onClose(false);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleAddressChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      address: {
        ...state.address,
        [name]: event.target.value,
      },
    });

    setError({
      [name]: false,
    });
  };

  const handleAddressChange1 = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      address: {
        ...state.address,
        [name]: event.target.checked,
      },
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Site', JSON.stringify(state));

    if (CommonUtil.isEmptyString(state.name)) {
      Toast(t("Please enter Name"), 'error');
      setError({ name: true });
      return;
    }

    if (CommonUtil.isEmptyString(state.siteTypeId)) {
      Toast(t("Please enter Site Type"), 'error');
      setError({ siteTypeId: true });
      return false;
    }

    setExpanded("panel1");
    if (CommonUtil.isEmptyString(state.address.addressLine1)) {
      Toast(t("Please enter Address Line 1"), 'error');
      setError({ addressLine1: true });
      return false;
    }

    if (CommonUtil.isEmptyString(state.address.country)) {
      Toast(t("Please enter country"), 'error');
      setError({ country: true });
      return false;
    }

    if (CommonUtil.isEmptyString(state.address.city)) {
      Toast(t("Please enter city"), 'error');
      setError({ city: true });
      return false;
    }


    if (process.env.REACT_APP_ENVIRONMENT === 'USA'? '':CommonUtil.isEmptyString(state.address.latitude)) 
    {
      Toast(t("Please enter Latitude"), 'error');
      setError({ latitude: true });
      return false;
    }
    
    if (process.env.REACT_APP_ENVIRONMENT === 'USA'? '':CommonUtil.isEmptyString(state.address.longitude)) 
    {
      Toast(t("Please enter longitude"), 'error');
      setError({ longitude: true });
      return false;
    }



    if (CommonUtil.isEmptyString(state.address.addressTypeId)) {
      Toast(t("Please enter Address Type"), 'error');
      setError({ addressTypeId: true });
      return false;
    }

    if (state.id === '')
      dispatch(createSite([state])).unwrap().then((data) => {
        if (data.code === 'LMSI0013') {
          handleClose()
          Toast((data.message), 'success')
        }
        else
          Toast(data.data.error[0].message, 'error')
      });
    else
      dispatch(updateSite(state)).unwrap().then((data) => {
        if (data.code === 'LMSI0014') {
          handleClose()
          Toast((data.message), 'success')
        }
        else
          Toast(data.data.error[0].message, 'error')
      });
  };


  return (
    <>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="custom-modal"
      >
        <DialogTitle id="form-dialog-title">
          <Box display="flex" m={1}>
            <Box flexGrow={1}>
              <Typography variant="h4">
                {CommonUtil.isEmptyString(state.id)
                  ? t("SITE002")
                  : t("SITE008")}
              </Typography>
            </Box>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 15,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            > <CloseIcon /></IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <>
            <Grid container justifyContent="center" alignItems="center">
              <Accordion
                expanded={expandedSite}
                onChange={handleValidationSite(expandedSite ? false : true)}
                sx={{
                  width: '100%',
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: process.env.REACT_APP_COLOR_ACCORDION,}} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: process.env.REACT_APP_BG_ACCORDION,
                    borderRadius: "7px",
                    marginTop:"20px"
                  }}
                >
                  <Typography className={classes.heading}>
                    {t("SITE001")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form noValidate autoComplete="off">
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center" >
                          <CustomTextfield
                            required
                            error={error.name}
                            name="name"
                            label={t("COMMON044")}
                            value={state.name}
                            handleChange={(e) => handleChange(e)}
                            helperText=''
                            inputProps={{ maxLength: 50 }}
                          />
                        </Box>
                      </Grid>


              <Grid item xs={12} sm={6} style={{ marginTop: "10px" }}>
                <AutocompleteComponent
                 label={t('STYPE001') + ' * '}
                 name="siteTypeId"
                 error={error.siteTypeId}
                 value={state.siteTypeId}
                onSelect={(e) => setState({
                  ...state,
                  siteTypeId: e.id,
              })}
                 options={siteTypes}
              />
              </Grid>   

         <Grid item xs={12} sm={6} style={{ marginTop: "6px" }}>
                <AutocompleteComponent
                 label={t('COMMON050')}
                 name="parentId"
                 error={error.parentId}
                 value={state.parentId}
                 onSelect={(e) => setState({
                  ...state,
                  parentId: e.id,
              })}
                 options={parentSites}
              />
              </Grid>   


                      <Grid item xs={12} sm={6}>
                        <CustomTextfield
                          inputProps={{ className: classes.textarea, maxLength: 120 }}
                          label={t("SITE009")}
                          value={state.description}
                          helperText=''
                          name="description"
                          handleChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </form>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleValidation("panel1")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: process.env.REACT_APP_COLOR_ACCORDION,}} />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    backgroundColor: process.env.REACT_APP_BG_ACCORDION,
                    borderRadius: "7px",
                  }}
                >
                  <Typography className={classes.heading}>
                    {t("ADD012")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <form noValidate autoComplete="off">
                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            required
                            error={error.addressLine1}
                            name="addressLine1"
                            label={t("ADD001")}
                            helperText=''
                            value={state.address.addressLine1}
                            handleChange={handleAddressChange}
                            inputProps={{ maxLength: 50 }}
                            validation='alphabets'
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.addressLine2}
                            name="addressLine2"
                            label={t("ADD002")}
                            helperText=''
                            value={state.address.addressLine2}
                            handleChange={handleAddressChange}
                            inputProps={{ maxLength: 50 }}
                            validation='alphabets'
                          />
                        </Box>
                      </Grid>

                      {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                       '': 
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.addressLine3}
                            name="addressLine3"
                            label={t("ADD017")}
                            helperText=''
                            value={state.address.addressLine3}
                            handleChange={handleAddressChange}
                            inputProps={{ maxLength: 50 }}
                            validation='alphabets'
                          />
                        </Box>
                      </Grid>}

                <Grid item xs={12} sm={6} style={{ marginTop: "6px" }}>
                   <AutocompleteComponent
                     label={t('ADD003') + ' * '}
                     name="country"
                     error={error.country}
                     value={state.address.country}
                     onSelect={(e) => setState({
                     ...state,
                     address: {
                      ...state.address,
                      country: e.name,
                     }
                     })}
                     options={countries}
                  />
              </Grid> 

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.state}
                            name="state"
                            label={t("ADD004")}
                            helperText=''
                            value={state.address.state}
                            handleChange={handleAddressChange}
                            inputProps={{ maxLength: 50 }}
                            validation='alphabets'
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.city}
                            name="city"
                            label={t("ADD005")}
                            helperText=''
                            value={state.address.city}
                            handleChange={handleAddressChange}
                            inputProps={{ maxLength: 50 }}
                            validation='alphabets'
                            required
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.areaCode}
                            name="areaCode"
                            label={t("ADD006")}
                            helperText=''
                            value={state.address.areaCode}
                            handleChange={handleAddressChange}
                            inputProps={{ maxLength: 10 }}
                            validation='numeric'
                          />
                        </Box>
                      </Grid>

                      {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                        '': 
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            required
                            error={error.latitude}
                            name="latitude"
                            label={t("ADD007")}
                            helperText=''
                            value={state.address.latitude}
                            handleChange={handleAddressChange}
                            inputProps={{ maxLength: 20 }}
                            validation='numeric'
                          />
                        </Box>
                      </Grid>}

                      {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                         '': 
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.longitude}
                            name="longitude"
                            label={t("ADD008")}
                            helperText=''
                            required
                            value={state.address.longitude}
                            handleChange={handleAddressChange}
                            inputProps={{ maxLength: 20 }}
                            validation='numeric'
                          />
                        </Box>
                      </Grid>}

                      {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                        '': 
                      <Grid item xs={12} sm={6}>
                        <Box display="flex" justifyContent="center">
                          <CustomTextfield
                            error={error.radius}
                            name="radius"
                            label={t("ADD009")}
                            helperText=''
                            value={state.address.radius}
                            handleChange={handleAddressChange}
                            inputProps={{ maxLength: 20 }}
                            validation='numeric'
                          />
                        </Box>
                      </Grid>}

                <Grid item xs={12} sm={6} style={{ marginTop: "6px" }}>
                   <AutocompleteComponent
                     label={t('ADD010')}
                     name="timeZone"
                     error={error.timeZone}
                     value={state.address.timeZone}
                     onSelect={(e) => setState({
                     ...state,
                     address: {
                      ...state.address,
                      timeZone: e.name,
                     }
                     })}
                     options={timeZones}
                  />
              </Grid> 

                   <Grid item xs={12} sm={6} style={{ marginTop: "6px" }}>
                      <AutocompleteComponent
                         label={t('ATYPE001') + ' * '}
                         name="addressTypeId"
                         error={error.addressTypeId}
                         value={state.address.addressTypeId}
                         onSelect={(e) => setState({
                         ...state,
                         address: {
                         ...state.address,
                         addressTypeId: e.id,
                         }
                        })}
                       options={addressTypes}
                     />
                  </Grid> 

                      <Grid item xs={12}>
                        <Box display="flex" className="ml-3 mt-2">
                          <FormGroup>
                            <Typography component="div">
                              <Grid
                                component="label"
                                container
                                alignItems="center"
                                spacing={1}
                              >
                                <Grid item>{t("ADD011")}</Grid>
                                <Grid item>{t("DAYLIGHTOFF")}</Grid>
                                <Grid item >
                                  <IOSSwitch
                                    checked={state.address.dayLightSaving}
                                    onChange={handleAddressChange1}
                                    name="dayLightSaving"
                                  />
                                </Grid>
                                <Grid item>{t("DAYLIGHTON")}</Grid>
                              </Grid>
                            </Typography>
                          </FormGroup>
                        </Box>
                      </Grid>
                    </Grid>
                  </form>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <SubmitButtons
            onClick={handleSubmit}
            variant="contained"
          >
            {t("COMMON035")}
          </SubmitButtons>
          <CancelButtons
            onClick={handleClose}
            color="secondary"
            variant="contained"
          >
            {t("COMMON008")}
          </CancelButtons>
        </DialogActions>
      </Dialog>
    </>
  );
}
