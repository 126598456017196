import { Tooltip, Grid, IconButton, Button, Autocomplete } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as FilterIcon } from "../../../assets/FilterIcon.svg";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { createTheme } from "@material-ui/core";
import InvitationFilterForm from "../../../components/InvitationFilterForm";
import CustomTextfield from "../../../components/CustomInputs/CustomTextfield";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { getAllSites } from "../../../slices/Site/SiteSlice";
import CustomAutocomplete from "../../../components/CustomInputs/CustomAutocomplete";
import CustomDropdown from "../../../components/CustomInputs/CustomDropdown";
import AutocompleteComponent from "../../../components/CustomInputs/AutocompleteComponent";
import { useTranslation } from "react-i18next";
import Toast from "../../../components/ToastContainer/CustomToast";

const MuiTheme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#36c96d",
        },
        secondary: {
            main: "#f50057",
        },
    },
});

const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
    border: "1px solid green",
    borderRadius: "50%",
    "&:hover": {
        backgroundColor: "green",
        color: "#FFFF",
    },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
    border: "1px solid green",
    borderRadius: "50%",
    "&:hover": {
        backgroundColor: "green",
        color: "#FFFF",
    },
}));

const useStyles = makeStyles((theme) => ({
    textarea: {
        resize: "both",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        background: "#3d4977",
        width: "100%",
        padding: "5px",
        textAlign: "center",
        borderRadius: "5px",
        color: "#FFFFFF",
    },
}));

export default function SiteFilter(props) {

    const zlink = localStorage.getItem("redirectFrom");

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [sites, setSites] = useState();
    const { t } = useTranslation();
    const [filterQuery, setFilterQuery] = useState({
        name: '',
        parentId: ''
    });

    const [selectedSite, setSelectedSite] = useState(null)

    const payload = {
        page: 1,
        rowsPerPage: 10000,
        code: '',
        name: ''
    };

    const defaultFilter = {
        name: '',
        parentId: ''
    }

    useEffect(() => {
        dispatch(getAllSites(payload)).unwrap()
            .then((data) => {
                if (data?.code === 'LMSI0000')
                    setSites(data?.data?.site);

            });


    }, [])

    const handleChange = (event) => {
        const name = event.target.name;
        setFilterQuery({
            ...filterQuery,
            [name]: event.target.value,
        });
    };

    const onSelectChange = (event) => {
        setFilterQuery({
            ...filterQuery,
            parentId: event.id,
        });
    };

    const handleClose = () => {
        setSelectedSite(null)
        setFilterQuery(defaultFilter);
        setOpen(false);
    }

    const handleSubmit = () => {
        if (filterQuery.name === "" && selectedSite === null) {
            Toast(t("COMMON015"), "error");
            return;
        }
        props.onSubmit(filterQuery);
        handleClose();
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const reset = () => {
        if (filterQuery.name === "" && selectedSite === null) {
            Toast(t("COMMON027"), "error");
            return;
        }
        if (selectedSite !== null) {
            setSelectedSite(null)
        }
        setSelectedSite(null)
        setFilterQuery(defaultFilter);
    }

    const onSelectedSite = (newValue) => {

        if (newValue !== null) {
            setFilterQuery({ parentId: newValue.id })
        }
        else {
            setFilterQuery({ ...filterQuery, parentId: '' })
        }

    }
    return (
        <>
            <Tooltip title={<Typography fontSize={12} fontWeight={500}>{t("COMMON006")}</Typography>}>
                <FilterIcon onClick={handleOpen} style={{ cursor: 'pointer' }}>
                    <FilterIcon />
                </FilterIcon>
            </Tooltip>

            <DialogBox
                Header={t("COMMON006")}
                acceptText={t("COMMON024")}
                cancelText={t("COMMON025")}
                fullWidth={true}
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                cancel={reset}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems={"center"}
                >
                    <Grid item xs={12} sm={9} style={{ marginTop: "30px" }}>
                        <CustomTextfield
                            inputProps={{ className: classes.textarea }}
                            label={t("COMMON044")}
                            placeholder={t("COMMON044")}
                            name="name"
                            value={filterQuery.name}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid>

                    {!zlink ? (<>
                        <Grid item xs={12} sm={9} style={{ marginTop: "30px" }}>
                            {/* <AutocompleteComponent
                            label={t("COMMON050")}
                            name="parentId"
                            value={payload.parentId}
                            onSelect={(e) => setFilterQuery({
                                ...filterQuery,
                                parentId: e.id,
                            })}
                            options={sites}
                        /> */}

                            <Autocomplete
                                fullWidth
                                noOptionsText={'No Options found'}
                                options={sites}
                                getOptionLabel={(option) =>
                                    option.name ? option.name : ""
                                }
                                value={selectedSite}
                                onChange={(event, newValue) => {
                                    setSelectedSite(newValue)
                                    onSelectedSite(newValue)
                                }}

                                renderInput={(params) => (
                                    <CustomTextfield
                                        {...params}
                                        label={t('COMMON050')}
                                        placeholder={t('COMMON050')}
                                        name="id"

                                    />
                                )}

                            />
                        </Grid>
                    </>
                    ) : (<></>)}

                </Grid>
            </DialogBox>
        </>
    );
}
