import { Avatar, Typography } from "@material-ui/core";
import { Box, Link, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  listInvitations,
} from "../../slices/Invitations/InvitationsSlice";
import CommonUtil from "../../Util/CommonUtils";
import DataTable from "../../components/DataTable/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import BlockVisitorIcon from "./BlockVisitorIcon";
import InvitationFilterForm from "../../components/InvitationFilterForm";
import "../../App.css";
import VisitorProfileCard from "../../components/VisitorActionsComponents/VisitorProfileCard";
import Search from "../../components/SearchTab/Search";
import BlockVisitorForm from "../../components/DialogBoxComponent/BlockVisitorForm";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import { useTranslation } from "react-i18next";
import HeaderToolbar from "../../components/HeaderToolbar";
import moment from "moment";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import DatePickerComponent from "./DatePickerComponent";


export default function ReportWalkinRegistartion() {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [clear, setClear] = React.useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [openBlockVisitor, setOpenBlockVisitor] = useState(false);
  const [blockId, setBlockId] = useState();
  const [registrationId, setRegistrationId] = useState();
  const { t } = useTranslation();

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };


  const [filter, setFilter] = React.useState({
    firstName: "",
    lastName: "",
    emailId: "",
    mobile: "",
    status: [
      "None",
      "WAIT_FOR_VISITOR",
      "CONFIRMED",
      "APPROVED",
      "CHECKED_IN",
      "CANCELLED",
      "INITIATED_FOR_BLOCKED",
      "BLOCKED",
      "REJECTED",
    ],
    visitorType: "",
    siteId: "",
    scheduledStartDate:"",
    scheduledEndDate:"",
 
    fromDate: null,
    toDate: null,
    hostId: "",
    hostEmail: "",
    createdBy: "",
    registrationMode: ["ONDEMAND"],
    isPagable: true,
  });

  const defaultFilter = {
    firstName: "",
    lastName: "",
    emailId: "",
    mobile: "",
    status: [
      "WAIT_FOR_VISITOR",
      "CONFIRMED",
      "APPROVED",
      "CHECKED_IN",
      "CANCELLED",
      "INITIATED_FOR_BLOCKED",
      "BLOCKED",
      "REJECTED",
    ],
    visitorType: "",
    siteId: "",
    scheduledStartDate:"",
    scheduledEndDate:"",
    fromDate: null,
    toDate: null,
    hostId: "",
    hostEmail: "",
    createdBy: "",
    registrationMode: ["ONDEMAND"],
    isPagable: true,
  };

  const applyDatePickerFilter = (sdate, edate) => {
    setPaging(defaultPaging);
    setFilter({
      firstName: "",
      lastName: "",
      phoneNumber: "",
      emailId: "",
      visitorTypeCode: "",
      siteId: "",
      status: "",
      hostId: "",
      scheduledStartDate: sdate,
      scheduledEndDate: edate,
      registrationMode: ["ONDEMAND"],
    });
  }

  const applyFilter = (filterData) => {
    setPaging(defaultPaging);
    setClear(true);
    setFilter({
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      phoneNumber: filterData.phoneNumber,
      emailId: filterData.emailId,
      visitorTypeCode: filterData.visitorTypeCode,
      siteId: filterData.siteId,
      status: filterData.status,
      hostId: filterData.hostId,
      scheduledStartDate: CommonUtil.formatToUtc(filterData.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(filterData.scheduledEndDate),
      registrationMode: [filterData.registrationMode],
    });
  };

  const resetFilter = () => {
    setClear(false);
    setFilter({
      ...defaultFilter,
    });
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter,paging);
  }, [filter,paging]);

  const loadData = (filter,paging) => {
    const payload = {
      filter: filter,
      page: paging.page,
      rowsPerPage: paging.rowsPerPage,
    };
    dispatch(listInvitations(payload))
      .unwrap()
      .then((data) => {
        console.info(data)
        if ((data.code = "CVAI0000" && data.data)) {
          setRows(data.data.visitors);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };


  const handleBlockVisit = (params) => {
    setOpenBlockVisitor(!openBlockVisitor);
    setBlockId(params.row.visitorId.id);
    setRegistrationId(params.id);
  };
  const globalsearch = (searchedVal) => {
    if (searchedVal === 'Scheduled') {
      searchedVal = 'WAIT_FOR_VISITOR';
    }

    if (searchedVal === 'Check In') {
      searchedVal = 'CHECKED_IN';
    }

    const filteredRows = rows.filter((test) => {
      return test.visitorId.firstName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.visitorId.lastName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.visitorId.emailId.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.hostName.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.visitorId.phoneNumber.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.scheduledStartDate.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.visitorId.firstName} ${test.visitorId.lastName}`
          .toLowerCase()
          .includes(searchedVal.toLowerCase());

    });
    setRows(filteredRows);
  };


  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          alt={params.row.visitorId.firstName}
          src={`data:image/png;base64,${params.row.visitorId.profile}`}
        ></Avatar>
      }
      child={<Avatar
        style={{
          width: '300px', height: '300px', border: "12px solid #FFFF",
          fontSize: '100px',
          borderRadius: '1px'
        }}
        alt={params.row.visitorId.firstName}
        src={`data:image/png;base64,${params.row.visitorId.profile}`}
      ></Avatar>}
    />
  )

  const totalduration = (params) => {
    let start = moment(params.row.checkIn);
    let end = moment(params.row.checkOut);
    let duration = moment.duration(end.diff(start));
    return Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()).format(":mm:ss")

  };
  const columns = React.useMemo(() => [
    {
      field: "visitorId.profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return (
          ZoomHandaler(params)
        );
      },
    },
    {
      field: "visitorId.firstName",
      headerName: t("COMMON014"),
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.visitorId.firstName + " " + params.row.visitorId.lastName,
    },


    {
      field: "visitorId.purpose",
      headerName: t("PURPOSELABEL"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.purpose,

    },

    {
      field: "scheduledStartDate",
      headerName: t("INVITEDATELBL"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        moment(CommonUtil.getLocalDate(params.row.scheduledStartDate)).format("DD-MM-YYYY kk:mm")
    },

    {
      field: "checkIn",
      headerName: t("commonsCheckIn"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.checkIn ?
          moment(CommonUtil.getLocalDate(params.row.checkIn)).format("DD-MM-YYYY kk:mm") : ""
    },

    {
      field: "checkOut",
      headerName: t("commonsCheckIn"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        params.row.checkOut ?
          moment(CommonUtil.getLocalDate(params.row.checkOut)).format("DD-MM-YYYY kk:mm") : ""
    },
    {
      field: "duration",
      headerName: "Total Duration",
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.checkOut ? totalduration(params) : ""
    },

    {
      field: "status",
      headerName: t("COMMON001"),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />;
      }
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisitorProfileCard rowData={params.row} />}
          label="View"
        />,


        <GridActionsCellItem
          icon={<BlockVisitorIcon />}
          label="BlockVisitor"
          onClick={() => handleBlockVisit(params)}
        />,

      ],
    },
  ]);
  const status = [
    "WAIT_FOR_VISITOR",
    "CONFIRMED",
    "APPROVED",
    "CHECKED_IN",
    "CANCELLED",
    "INITIATED_FOR_BLOCKED",
    "BLOCKED",
    "REJECTED",
  ];
  return (
    <>
      <HeaderToolbar title={t("WALKINREPORTLBL")} />
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item >
          <InvitationFilterForm
            rowsPerPage={rowsPerPage}
            selectedObject={filter.registrationMode}
            status={status}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>
        <Box >
          
          <DatePickerComponent
            datePickerFilter={(sdate,edate) =>
              applyDatePickerFilter(sdate,edate)
            }
          />
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter,paging)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openBlockVisitor && (
        <BlockVisitorForm
          open={openBlockVisitor}
          handleClose={(data) => {
            setOpenBlockVisitor(data);
            loadData(filter,paging);
          }}
          BlockVisitorId={blockId}
          BlockRegistrationId={registrationId}
        />
      )}
    </>
  );
}
