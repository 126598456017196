import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CommonUtil from "../../Util/CommonUtils";
import DataTable from "../../components/DataTable/DataTable";
import "../../App.css";
import { useTranslation } from "react-i18next";
import HeaderToolbar from "../../components/HeaderToolbar";
import { getAllDeviceEvents } from "../../slices/DeviceEventReportList/DeviceEventReportListSlice";
import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { Link } from "react-router-dom";
import Search from "../../components/SearchTab/Search";
import DeviceEventsFilter from "./DeviceEventsFilter";
import api from "../../config/http-common";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";

function DeviceEventReportList() {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(0);
  const [del, setDel] = useState(false);
  const zlink = localStorage.getItem("redirectFrom");

  const [roles, setRoles] = useState([]);
  const [listDeviceEvents, setListDeviceEvents] = useState(true);

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    startDateTime: '',
    endDateTime: '',
    personnelId: '',
    deviceAlias: '',
    cardNo: '',
    eventPointName: '',
    isPagable: true,
  });

  const defaultFilter = {
    startDateTime: '',
    endDateTime: '',
    personnelId: '',
    deviceAlias: '',
    cardNo: '',
    eventPointName: '',
    isPagable: true,
  };

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setListDeviceEvents(l.includes("EVENTS_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("EVENTS_LIST")) {
          dispatch(getAllDeviceEvents(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
              if ((data?.code === "DMSI0000" && data?.data)) {
                setPage(data?.data?.pageNumber);
                setPageSize(data?.data?.pageSize);
                setTotalRecords(data?.data?.totalCount);
                setTotalPages(data?.data?.totalPage);
                setRows(data?.data?.list);
              } else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("EVENTS_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);

  const loadData = (filter, paging) => {
    dispatch(getAllDeviceEvents(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "DMSI0000" && data?.data)) {
          setPage(data?.data?.pageNumber);
          setPageSize(data?.data?.pageSize);
          setTotalRecords(data?.data?.totalCount);
          setTotalPages(data?.data?.totalPage);
          setRows(data?.data?.list);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      deviceAlias: data.deviceAlias,
      cardNo: data.cardNo,
      personnelId: data.personnelId,
      // startDateTime: CommonUtil.formatToUtc(data.startDateTime),
      // endDateTime: CommonUtil.formatToUtc(data.endDateTime),
      startDateTime: data.startDateTime,
      endDateTime: data.endDateTime,
      eventPointName: data.eventPointName,
    });
  };

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(filter, paging);
      return;
    }

    dispatch(getAllDeviceEvents(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "DMSI0000" && data?.data)) {
          const filteredRows = data?.data?.list.filter((test) => {
            return (
              (test.sn ? test.sn.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.cardNo ? test.cardNo.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.personnel ? test.personnel.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.personnelName ? test.personnelName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.deviceAlias ? test.deviceAlias.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.eventTime ? test.eventTime.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.eventAddress ? test.eventAddress.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.eventName ? test.eventName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.epName ? test.epName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.mode ? test.mode.toLowerCase().includes(searchedVal.toLowerCase()) : "")
            );
          });
          setRows(filteredRows);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
    
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...';
    } else {
      return text;
    }
  }

  const columns = [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },

    {
      field: "personnel",
      headerName: t("Person Info"),
      flex: 1,
      width: 10,
      render: (rowData) => {
        const pid = CommonUtil.isEmpty(rowData.personnel) ? '' : rowData.personnel;
        const cardNo = CommonUtil.isEmpty(rowData.cardNo) ? '' : rowData.cardNo;
        return CommonUtil.isEmpty(cardNo) ? pid : pid + '(' + cardNo + ')';
      },
    },

    {
      field: "deviceAlias",
      headerName: t("Device Alias"),
      flex: 1,
      width: 10,
    },

    {
      field: "sn",
      headerName: t("SN"),
      flex: 1,
      width: 10,
    },

    {
      field: "eventTime",
      headerName: t("Event Time"),
      flex: 1,
      width: 10,
    },

    {
      field: "eventAddress",
      headerName: t("Event Address"),
      flex: 1,
      width: 10,
    },

    {
      field: "eventName",
      headerName: t("Event Name"),
      flex: 1,
      width: 10,
    },

    {
      field: "epName",
      headerName: t("EP Name"),
      flex: 1,
      width: 10,
    },

    {
      field: "mode",
      headerName: t("Verification Mode"),
      flex: 1,
      width: 10,
    },

  ];

  return (
    <>
      <HeaderToolbar
        title="Events"
      />

  {listDeviceEvents &&
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >

        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "1",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item p={1} >
          <DeviceEventsFilter
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        <Box item>
          <Tooltip title={"Refresh"}>
            <IconButton onClick={() => loadData(filter, paging)}>
              <Refresh />
            </IconButton>
          </Tooltip>
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
        
      </Box> }

    {listDeviceEvents ?(
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row ? row : ""}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
       ):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
           <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
              {t('CVROLE014')}
           </Typography>
         </div>)
    }

    </>
  );
}

export default DeviceEventReportList;
