import { Avatar, Grid, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import FileBase64 from 'react-file-base64';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import cameraplus2 from '../../assets/AddPhotoIcon.svg'
import Toast from '../../components/ToastContainer/CustomToast';

export default function ProfileUplod(props) {
  const [profile, setProfile] = React.useState(cameraplus2);
  const [file, setFile] = React.useState({});

  const [state, setState] = React.useState({
    profilePhoto: '',
  });
  const handleProfile = (file) => {
    if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/bmp') {
      let fileSize = file.size.split(' ')[0];
      let size = parseInt(fileSize);
      if (size <= 2050) {
        setFile(file);
        setState({
          ...state,
          profilePhoto: file.base64.split(',')[1],
        });
        props.onsetProfile(file);
        setProfile(file.base64);
      } else {
        setFile('');
        setProfile('');
        //setProfile(state.profilePhoto);
        //props.onsetProfile(state.profilePhoto);
        Toast('Image size should be less than 2MB','error');
      }
    } else {
      setFile('');
      //setProfile(state.profilePhoto);
      Toast('Invalid file format', 'error');
    }
  };

  return (
    <>
      <Grid conatiner display={'flex'} direction='column' justifyContent='center' alignItems='center'>
        <div style={{ borderStyle: 'dashed ', borderRadius: '50%', padding: '5px', color: '#D2D2D2' }}>
          <Tooltip title={props.profilePhoto ? 'click to upload picture' : ''}>
            <IconButton
              variant='contained'
              component='label'
              style={{
                position: 'relative',
                width: '210px',
                height: '210px',
                font: 'normal normal normal 18px/24px Roboto',
                letterSpacing: '0px',
              }}
              disabled={props.disabled}
            >
              {props.profilePhoto ? (
                <Avatar className='ImageContent'
                  alt='' src={props.profilePhoto}
                  style={{
                    height: '200px',
                    width: '200px',
                    zIndex: '99',
                    borderRadius: '50%'
                  }} />
              ) : (
                <>
                  <Avatar
                    className='ImageContent'
                    alt=''
                    src={profile}
                    style={{
                      height: '100%',
                      width: '100%',
                      borderRadius: '50%',
                      backgroundColor: '#F4F6F8'
                    }}
                    sx={[
                      () => ({
                        '& .MuiAvatar-img': {
                          width: '49px',
                          height: '42px',
                          textAlign: 'center',
                          objectFit: 'cover',
                          color: 'transparent',
                          textIndent: '10000px',
                        },
                      }),
                    ]}
                  />
                  <div style={{ fontSize:'16px',position: 'absolute', top: '68%', zIndex: '1', color: '#C3C5C7', justifyContent: 'center' }}>Click for Photo </div>
                  <Grid style={{ display: 'none' }}>
                    <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                  </Grid>
                </>
              )}
              <Grid style={{ display: 'none' }}>
                <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
              </Grid>
            </IconButton>
          </Tooltip>
        </div>
        <Grid direction='row' display='flex' alignItems='flex-end' style={{ color: '#C3C5C7', opacity: 1, fontSize: '12px', paddingTop: '34px', justifyContent: 'center' }}>
          Allowed *.jpeg, *.jpg, *.png max size of 2 MB
        </Grid>
      </Grid>
    </>
  );
}

ProfileUplod.defaultProps = {
  disabled: false,
};
