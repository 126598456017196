import React, { useEffect, useState } from "react";
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';
import SelfRegistrationApprovals from './SelfRegistrationApprovals';
import './Approvals.css';
import InvitationWalkInRegistrationApprovals from './InvitationWalkInRegistrationApprovals';
import ApprovalBlockRequests from './ApprovalBlockRequests';
import { useDispatch } from 'react-redux';
import api from "../../config/http-common";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import { Typography } from "@mui/material";


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return <div {...other}>{value === index && <Box pt={2}>{children}</Box>}</div>;
}

export default function ApprovalTabs() {
    const [value, setValue] = React.useState(0);
    const { t } = useTranslation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const dispatch = useDispatch();
    const [roles, setRoles] = useState([]);
    const [invitationApprovals, setInvitationApprovals] = useState(true);
    const [selfApprovals, setSelfApprovals] = useState(true);
    const [blockApprovals, setBlockApprovals] = useState(true);

    useEffect(() => {
        dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
        .unwrap()
        .then((res) => {
            var l = [];
            res.data.rolePermissions.map((item, index) => {
              l.push(item.code);
          });
            setRoles(l);
            setSelfApprovals(l.includes("REGISTRATION_APPROVALS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setBlockApprovals(l.includes("BLOCKED_REQUEST_APPROVALS_CV") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setInvitationApprovals(l.includes("INVITATION_APPROVALS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
            
            if (l.includes("INVITATION_APPROVALS")) {
               }
             });
      }, []);

      useEffect(() => {
        if (roles.includes("INVITATION_APPROVALS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
          }
      }, []);
    

    return (
        <div>
            <Grid>
                <AppBar position='static' color='transparent' >
                    <Tabs
                        TabIndicatorProps={{ style: {background: process.env.REACT_APP_BG_ICON}}}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        style={{ position: 'absolute', top: '160px', display: 'flex',marginLeft:'-20px' }}
                    >
                        <Tab label={t('APPROVALITEMS001')} style={{borderRight: 'solid #00000029',textTransform: "none"}}/>
                        <Tab label={t('APPROVALITEMS002')} style={{borderRight: 'solid #00000029',textTransform: "none"}}/>
                        {process.env.REACT_APP_ENVIRONMENT === 'USA' ? <Tab label={t('APPROVALITEMS004')} style={{textTransform: "none" }}/> : <Tab label={t('APPROVALITEMS003')} style={{textTransform: "none" }}/>}
                    </Tabs>
                </AppBar>

                
             
                {value === 0 && (
                     (<>
                    {invitationApprovals ? (
                    <TabPanel value={value} index={value} >
                        <InvitationWalkInRegistrationApprovals />
                    </TabPanel>) :
                       (<div style={{marginTop: '185px', paddingLeft: '300px'}}>
                       <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                          {t('CVROLE025')}
                       </Typography>
                    </div>)}
                     </>)  
                )}
              
          
                {value === 1 && (
                     (<>
                   {selfApprovals ? (
                    <TabPanel value={value} index={value}>
                        <SelfRegistrationApprovals />
                    </TabPanel>) : 
                     (<div style={{marginTop: '185px', paddingLeft: '300px'}}>
                        <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                           {t('CVROLE026')}
                        </Typography>
                     </div>)}
                     </>)  
                )}

                {value === 2 && (
                    (<>
                    {blockApprovals ? (
                    <TabPanel value={value} index={value}>
                        {/* <BlockRequestsApprovals /> */}
                        <ApprovalBlockRequests />
                    </TabPanel>) :
                      (<div style={{marginTop: '185px', paddingLeft: '300px'}}>
                         <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                            {t('CVROLE027')}
                         </Typography>
                      </div>)}
                    </>)
                )}

            </Grid>
        </div >
    );
}