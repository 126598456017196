import React, { useEffect, useState } from "react";
import { Grid, Typography, Paper } from "@material-ui/core";
import DashboardCards from "../DashBoard/DashboardCards";
import DataTable from "../../components/DataTable/DataTable";
import { Avatar, Box, IconButton, Link, Tooltip } from "@mui/material";
import CommonUtil from "../../Util/CommonUtils";
import { useDispatch } from "react-redux";
import Search from "../../components/SearchTab/Search";
import FilterForm from "../../components/DialogBoxComponent/FilterForm";
import { getDashBoardStatics } from "../../slices/DashBoard/DashBoardSlice";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import { useTranslation } from "react-i18next";
import { getAllVisitors } from "../../slices/Approvals/selfRegistarationApprovalSlice";
import CustomDateFilter from "../DailyReports/CustomDateFilter";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";
import moment from "moment";


export default function Dashboard(props) {
  
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [todaysStatics, setTodaysStatics] = useState({});
  const [clear, setClear] = useState(false);
  const { t } = useTranslation();
  const zlink = localStorage.getItem("redirectFrom");

  const [roles, setRoles] = useState([]);
  const [listDashboard, setListDashboard] = useState(true);
  const [viewCounterEvents, setViewCounterEvents] = useState(true);

  const [currentFilter, setCurrentFilter] = useState({
    hostId: "",
    purposeTypeId: "",
    status: [
      "SCHEDULED",
      "CONFIRMED",
      "APPROVED",
      "CHECKED_IN",
      "CHECKED_OUT",
      "CANCELLED",
      "INITIATED_FOR_BLOCKED",
      "BLOCKED",
      "REJECTED",
      "MISSED",
    ],
    visitMode: ["INVITATION", "WALKIN_REGISTARTION", "SELF_REGISTARTION", "WALKIN_REGISTARTION_KIOSK"]
  });

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [selectedDates, setSelectedDates] = useState({
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
  })

  useEffect(() => {
    setSelectedDates({
      scheduledStartDate: CommonUtil.formatToUtc(startDate),
      scheduledEndDate: CommonUtil.formatToUtc(endDate),
    })
  }, [])

  const defaultFilter = {
    hostId: "",
    purposeTypeId: "",
    status: [
      "SCHEDULED",
      "CONFIRMED",
      "APPROVED",
      "CHECKED_IN",
      "CHECKED_OUT",
      "CANCELLED",
      "INITIATED_FOR_BLOCKED",
      "BLOCKED",
      "REJECTED",
      "MISSED",
    ],
    hostEmail: "",
    createdBy: "",
    visitMode: ["INVITATION", "WALKIN_REGISTARTION", "SELF_REGISTARTION", "WALKIN_REGISTARTION_KIOSK"]
  };

  const [invFilter, setInvFilter] = React.useState({
    hostId: "",
    purposeTypeId: "",
    status: [
      "SCHEDULED",
      "CONFIRMED",
      "APPROVED",
      "CHECKED_IN",
      "CHECKED_OUT",
      "CANCELLED",
      "INITIATED_FOR_BLOCKED",
      "BLOCKED",
      "REJECTED",
      "MISSED",
    ],
    visitMode: ["INVITATION"],
  });

  const [regFilter, setRegFilter] = React.useState({
    hostId: "",
    purposeTypeId: "",
    status: [
      "SCHEDULED",
      "CONFIRMED",
      "APPROVED",
      "CHECKED_IN",
      "CHECKED_OUT",
      "CANCELLED",
      "INITIATED_FOR_BLOCKED",
      "BLOCKED",
      "REJECTED",
      "MISSED",
    ],
    visitMode: ["WALKIN_REGISTARTION", "WALKIN_REGISTARTION_KIOSK"]
  });


  const [selfRegFilter, setSelfRegFilter] = React.useState({
    status: [
      "CONFIRMED",
      "APPROVED",
      "CHECKED_IN",
      "CHECKED_OUT",
      "CANCELLED",
      "INITIATED_FOR_BLOCKED",
      "BLOCKED",
      "REJECTED",
      "MISSED",
    ],
    purposeTypeId: "",
    hostId: "",
    visitMode: ["SELF_REGISTARTION"]
  });

  const [checkinFilter, setCheckinFilter] = React.useState({
    hostId: "",
    purposeTypeId: "",
    status: ["CHECKED_IN"],
    visitMode: ["INVITATION", "WALKIN_REGISTARTION", "SELF_REGISTARTION", "WALKIN_REGISTARTION_KIOSK"]
  });

  const [checkOutFilter, setCheckOutFilter] = React.useState({
    hostId: "",
    purposeTypeId: "",
    status: ["CHECKED_OUT"],
    visitMode: ["INVITATION", "WALKIN_REGISTARTION", "SELF_REGISTARTION", "WALKIN_REGISTARTION_KIOSK"]
  });
  const [confirmedFilter, setConfirmedFilter] = React.useState({
    hostId: "",
    purposeTypeId: "",
    status: ["CONFIRMED"],
    isPagable: true,
    // createdAt: CommonUtil.formatToUtc(startDate),
    visitMode: ["INVITATION", "WALKIN_REGISTARTION", "SELF_REGISTARTION", "WALKIN_REGISTARTION_KIOSK"]
  });

  const applyFilter = (filterData) => {
    setClear(true);
    const query = {
      purposeTypeId: filterData.purposeTypeId,
      status: filterData.status,
      hostId: filterData.hostId,
      visitMode: [filterData.visitMode],
    };
    setCurrentFilter({
      ...currentFilter,
      ...query
    })
  };

  const resetFilter = () => {
    setClear(false);
    setCurrentFilter({
      ...defaultFilter
    });
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    loadData(newPage, size);
    setRowsPerPage(size);
  };

  useEffect(() => {
    loadData(1, rowsPerPage);
    loadDashBoard();
  }, [currentFilter, selectedDates]);


  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setListDashboard(l.includes("VIEW_CV_DASHBOARDS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setViewCounterEvents(l.includes("VIEW_CV_DASHBOARDS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

    if (l.includes("VIEW_CV_DASHBOARDS")) {
         const payload = {
             filter: { ...currentFilter, ...selectedDates },
             page: page,
             rowsPerPage: rowsPerPage,
          };
        dispatch(getAllVisitors(Object.assign(payload)))
            .unwrap()
            .then((data) => {
              if ((data && data?.code === "CVAI0000" && data?.data)) {
                setTotalRecords(data?.data?.totalCount);
                setRows(data?.data?.visits);
                setPage(data?.data?.curPage);
                setTotalPages(data?.data?.totalPages);
              } else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
    }, []);

  useEffect(() => {
     if (roles.includes("VIEW_CV_DASHBOARDS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
    }
   }, []);

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: { ...currentFilter, ...selectedDates },
      page: page,
      rowsPerPage: rowsPerPage,
    };

    dispatch(getAllVisitors(Object.assign(payload)))
      .unwrap()
      .then((data) => {
        if ((data && data?.code === "CVAI0000" && data?.data)) {
          setTotalRecords(data?.data?.totalCount);
          setRows(data?.data?.visits);
          setPage(data?.data?.curPage);
          setTotalPages(data?.data?.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });

  };

  const loadDashBoard = () => {
    dispatch(getDashBoardStatics(selectedDates))
      .unwrap()
      .then((data) => {
        if ((data && data?.code === "CVAI0000" && data?.data)) {
          setTodaysStatics(data?.data);
        } else {
          setTodaysStatics({});
        }
      })
      .catch((er) => { });
  }

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(page, rowsPerPage);
      return;
    }

    if (searchedVal === 'Check In') {
      searchedVal = 'CHECKED_IN';
    }
    if (searchedVal === 'Check Out') {
      searchedVal = 'CHECKED_OUT';
    }
    if (process.env.REACT_APP_ENVIRONMENT === 'USA' ? searchedVal === 'Blacklist Initiated' : searchedVal === 'Block Initiated') {
      searchedVal = 'INITIATED_FOR_BLOCKED';
    }
    if (searchedVal === "Blacklist") {
      searchedVal = "BLOCKED";
    }

    const filteredRows = rows.filter((test) => {
      return (
        (test.visitor.firstName ? test.visitor.firstName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.visitor.lastName ? test.visitor.lastName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.visitor.phone ? test.visitor.phone.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.visitor.email ? test.visitor.email.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        (test.hostName ? test.hostName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
        test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
        test.purposeType.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.visitor.firstName} ${test.visitor.lastName}`.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const handalCardChange = (params) => {
    if (params === "Checkin") {
      setCurrentFilter({ ...currentFilter, ...checkinFilter });
    }
    if (params === "checkOut") {
      setCurrentFilter({ ...currentFilter, ...checkOutFilter });
    }
    else if (params === "registration") {
      setCurrentFilter({ ...currentFilter, ...regFilter });
    }
    if (params === "Invitation") {
      setCurrentFilter({ ...currentFilter, ...invFilter });
    }
    if (params === "confirmed") {
      setCurrentFilter({ ...currentFilter, ...confirmedFilter });
    }
    if (params === "selfRegistration") {
      setCurrentFilter({ ...currentFilter, ...selfRegFilter });
    }
  }

  const handleDatePickerFilter = (sdate, edate) => {
    setSelectedDates({
      scheduledStartDate: CommonUtil.formatToUtc(sdate),
      scheduledEndDate: CommonUtil.formatToUtc(edate),
    });
  }

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...';
    } else {
      return text;
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        >{params.row.visitor.firstName == null ? "" : params.row.visitor.firstName.charAt(0)}</Avatar>
      }
      child={
        <Avatar
          style={{
            width: "300px",
            height: "300px",
            border: "12px solid #FFFF",
            fontSize: "100px",
            borderRadius: "1px",
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        >{params.row.visitor.firstName == null ? "" : params.row.visitor.firstName.charAt(0)}</Avatar>
      }
    />
  );

  const columns = React.useMemo(() => [
    {
      field: "visitor.profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params);
      },
    },
    {
      field: "visitor.firstName",
      headerName: t("COMMON014"),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName),
      renderCell: (params) => (
        <Tooltip title={` ${params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName)}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName))}</span>
        </Tooltip>
      ),
    },

    {
      field: "visitor.phone",
      headerName: t("COMMON063"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.phone,
    },
    {
      field: "visitor.email",
      headerName: t("PERSON005"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.visitor.email}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.visitor.email)}</span>
        </Tooltip>
      ),
    },
    {
      field: "hostName",
      headerName: t("commonsTextFeildHost"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.hostName,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.hostName}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.hostName)}</span>
        </Tooltip>
      ),
    },
    {
      field: "scheduledStartDate",
      headerName: t("INVITEDATELBL"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        moment(CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)).format(
          "DD-MM-YYYY HH:mm"),
       renderCell: (params) => (
          <Tooltip title={` ${ moment(CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)).format(
            "DD-MM-YYYY HH:mm" )}`} placement="bottom-start">
          <span>{handleTextOverflow( moment(CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)).format(
          "DD-MM-YYYY HH:mm"))}</span>
          </Tooltip>
        ),  
    },
    {
      field: "purposeType",
      headerName: t("Purpose"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.purposeType !== null ? params.row.purposeType.name : '',
      renderCell: (params) => (
        <Tooltip title={` ${params.row.visitor.purposeType !== null ? params.row.purposeType.name : ''}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.visitor.purposeType !== null ? params.row.purposeType.name : '')}</span>
        </Tooltip>
      ),
    },

    {
      field: "status",
      headerName: t("COMMON001"),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} visitMode={params.row.visitMode} icon={true} />;
      }
    },
  ]);


  const status = [
    "SCHEDULED",
    "CONFIRMED",
    "APPROVED",
    "CHECKED_IN",
    "CANCELLED",
    "CHECKED_OUT",
    "MISSED",
    "INITIATED_FOR_BLOCKED",
    "BLOCKED",
    'REJECTED',
  ];

  return (
    <Grid container spacing={3}>
      <Box sx={{ m: 2 }}><Typography variant="h3">
        {t("labelNavItemsDashboard")}
      </Typography></Box>

  {listDashboard &&
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
        style={{ paddingRight: '20px', marginTop: '-60px' }}
      >
        <CustomDateFilter
          datePickerFilter={(sdate, edate) =>
            handleDatePickerFilter(sdate, edate)
          }
        />
      </Box>
  }

  {viewCounterEvents &&
      <Grid item xs={12} sm={12}>
        <DashboardCards
          getRowData={(params) => handalCardChange(params)}
          data={todaysStatics} />
      </Grid>
  }

  {listDashboard &&
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >

        <Box item p={1}>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item p={1}>
          <FilterForm
            rowsPerPage={rowsPerPage}
            selectedObject={currentFilter.visitMode}
            status={status}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>

       <Box item>
        <Tooltip title={"Refresh"}>
              <IconButton onClick={() => loadData(page, rowsPerPage)}>
                <Refresh />
              </IconButton>
            </Tooltip>
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(page, rowsPerPage)}
          />
        </Box>

      </Box>}

    {listDashboard ? (
      <Grid item xs={12}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </Grid>
          ):(<div style={{marginTop: '230px', paddingLeft: '200px'}}>
          <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'430'}}>
              {t('CVROLE001')}
          </Typography>
        </div>)}

    </Grid>
  );
}

