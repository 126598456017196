import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RoleandPermissionService from "../../services/RoleandPermission/RoleandPermissionService";

const initialState = [];

export const getAllUserRoles = createAsyncThunk("getAllUserRoles/", async (payload) => {
    const res = await RoleandPermissionService.getAllUserRoles(payload);
    return res.data;
  }
);

export const createRolesandPermission = createAsyncThunk('createUserRoles/create', async (payload) => {
    const res = await RoleandPermissionService.createRolesandPermission(payload);
    return res.data;
  }
);

export const getPermissionsList = createAsyncThunk('getPermissionsList/', async (payload) => {
    const res = await RoleandPermissionService.getPermissionsList(payload);
    return res.data;
  }
);

export const updateRolesandPermission = createAsyncThunk('updateUserRoles/update', async (payload) => {
    const res = await RoleandPermissionService.updateRolesandPermission(payload);
    return res.data;
  }
);

export const deleteRolesandPermission = createAsyncThunk('deleteUserRoles/delete', async (roleId) => {
    const res = await RoleandPermissionService.deleteRolesandPermission(roleId);
    return res.data;
  }
);

export const getPermissionsListByroleId = createAsyncThunk('getPermissionsList/by_role_Id', async (payload) => {
    const res = await RoleandPermissionService.getPermissionsListByroleId(payload);
    return res.data;
});

export const updatePermission = createAsyncThunk('updatePermission/permissionId', async (permissionId) => {
  const res = await RoleandPermissionService.updatePermission(permissionId);
  return res.data;
});

const RoleandPermissionSlice = createSlice({
  name: "getAllUserRoles",
  initialState,
  extraReducers: {
    [getAllUserRoles.fulfilled]: (state, action) => {
      if (action.payload.code === "") {
        return action.payload.data;
      } else {
        return [];
      }
    },
  },
});

const { reducer } = RoleandPermissionSlice;
export default reducer;
