import { Tooltip, Grid, IconButton, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as FilterIcon } from "../../../assets/FilterIcon.svg";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { createTheme } from "@material-ui/core";
import InvitationFilterForm from "../../../components/InvitationFilterForm";
import CustomTextfield from "../../../components/CustomInputs/CustomTextfield";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import Toast from "../../../components/ToastContainer/CustomToast";

const MuiTheme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#36c96d",
        },
        secondary: {
            main: "#f50057",
        },
    },
});

const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
    border: "1px solid green",
    borderRadius: "50%",
    "&:hover": {
        backgroundColor: "green",
        color: "#FFFF",
    },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
    border: "1px solid green",
    borderRadius: "50%",
    "&:hover": {
        backgroundColor: "green",
        color: "#FFFF",
    },
}));

const useStyles = makeStyles((theme) => ({
    textarea: {
        resize: "both",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        background: "#3d4977",
        width: "100%",
        padding: "5px",
        textAlign: "center",
        borderRadius: "5px",
        color: "#FFFFFF",
    },
}));

export default function CheckInAndOutDeviceFilter(props) {

    const { t } = useTranslation()
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [filterQuery, setFilterQuery] = useState({
        deviceName: '',
        serialNumber: '',
        ipAddress: ''
    });

    const defaultFilter = {
        deviceName: '',
        serialNumber: '',
        ipAddress: ''
    }

    const handleChange = (event) => {
        const name = event.target.name;
        setFilterQuery({
            ...filterQuery,
            [name]: event.target.value,
        });
    };

    const handleClose = () => {
        setFilterQuery(defaultFilter);
        setOpen(false);
    }

    const handleSubmit = () => {

        if (filterQuery.deviceName.trim() === "" && filterQuery.serialNumber.trim() === "" && filterQuery.ipAddress.trim() === "") {
            Toast(t("COMMON015"), "error")
            return
        }
        props.onSubmit(filterQuery);
        handleClose();
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const reset = () => {
        if ((filterQuery.deviceName.trim() === "" && filterQuery.serialNumber.trim() === "" && filterQuery.ipAddress.trim() === "")) {
            Toast(t('COMMON027'), "error")
            return
        }
        setFilterQuery(defaultFilter);
    }

    return (
        <>
            <Tooltip title={<Typography fontSize={12} fontWeight={500}>Filter</Typography>} >
                <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
                    <FilterIcon />
                </FilterIcon>
            </Tooltip>

            <DialogBox
                Header="Filter"
                acceptText="Apply"
                cancelText="Reset"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                cancel={reset}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems={"center"}
                >
                    <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                        <CustomTextfield
                            inputProps={{ className: classes.textarea }}
                            label='Device Name'
                            name="deviceName"
                            value={filterQuery.deviceName}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                        <CustomTextfield
                            inputProps={{ className: classes.textarea }}
                            label='Serial Number'
                            name="serialNumber"
                            value={filterQuery.serialNumber}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                        <CustomTextfield
                            inputProps={{ className: classes.textarea }}
                            label='IP Address'
                            name="ipAddress"
                            value={filterQuery.ipAddress}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid>

                </Grid>
            </DialogBox>
        </>
    );
}
