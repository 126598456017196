import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable/DataTable";
import HeaderToolbar from "../../components/HeaderToolbar";
import Search from "../../components/SearchTab/Search";
import { getAllZone } from "../../slices/Zone/ZoneSlice";
import FilterChecklistTemplate from "../Checklist/ChecklistFilterForm";
import ZoneView from "./ZoneView";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { useTranslation } from "react-i18next";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";

function ZoneList() {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [clear, setClear] = useState(false);
  const [searchedRows, setSerchedRows] = useState([]);
  const [controlRendering, setControlRendering] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeleteZone, setOpenDeletZone] = useState(false);
  const [zoneId, setZoneId] = useState();
  const [del,setDel] = useState(false);
  const [pageSize,setPageSize] = useState(0);
  const { t } = useTranslation();

  const [roles, setRoles] = useState([]);
  const [viewZone, setViewZone] = useState(true);
  const [listZone, setListZone] = useState(true);

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });
  const [filter, setFilter] = useState({
    name: "",
  });

  const [selectedObject, setSelectedObject] = useState({
    zoneId: "",
    name: "",
    siteId: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    name: "",
  };

  const handleChange = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(filter, paging);
      return;
    }

    dispatch(getAllZone(Object.assign(filter, paging)))
    .unwrap()
    .then((data) => {
      if ((data?.code === "LMSI0000" && data.data)) {
        const filteredRows = data?.data?.zone.filter((test) => {
          return (
            (test.zoneName
              ? test.zoneName.toLowerCase().includes(searchedVal.toLowerCase())
              : "") ||
            (test.siteName
              ? test.siteName.toLowerCase().includes(searchedVal.toLowerCase())
              : "") ||
            (test.addressLine1
              ? test.addressLine1.toLowerCase().includes(searchedVal.toLowerCase())
              : "")
          );
        });
        setRows(filteredRows);
      } else {
        setRows([]);
      }
    })
    .catch((er) => { });

    
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  const handleOpen = () => {
    setOpenEditDialog(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setViewZone(l.includes("CV_VIEW_ZONE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setListZone(l.includes("CV_VIEW_ZONE_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("CV_VIEW_ZONE_LIST")) {
          dispatch(getAllZone(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
              if ((data?.code === "LMSI0000" && data.data)) {
                setRows(data?.data?.zone);
                setPage(data?.data?.currentPage);
                setTotalPages(data?.data?.totalPages);
                setPageSize(data?.data?.pageSize);
              }else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("CV_VIEW_ZONE_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);

  const loadData = (filter, paging) => {
    dispatch(getAllZone(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "LMSI0000" && data.data)) {
          setRows(data?.data?.zone);
          setPage(data?.data?.currentPage);
          setTotalPages(data?.data?.totalPages);
          setPageSize(data?.data?.pageSize)
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  function handleTextOverflow(text) {
    if (text && text.length > 20) {
      return text.substring(0, 20) + '...';
    } else {
      return text;
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData({ page: page,rowsPerPage: rowsPerPage}, filter);
      setDel(false);
    } else {
      if (del === true) {
        setPaging({ page: page-1,rowsPerPage: rowsPerPage});
        setDel(false);
      }
    }
  }, [del]);

  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog);
    setSelectedObject(params);
  };

  const handleDeleteZone = (params) => {
    setOpenDeletZone(!openDeleteZone);
    setZoneId(params.id);
  };


  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      name: data.name,
    });
  };

  

  const columns = [

    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },

    {
      field: "zoneName",
      headerName: "Zone name",
      flex: 1,
      width: 180,
      valueGetter: (params) => params.row.zoneName,
      renderCell: (params) => (
        <Tooltip title={` ${ params.row.zoneName}`}>
           <span>{handleTextOverflow(params.row.zoneName)}</span>
           </Tooltip>
            ),
    },

    {
      field: "siteName",
      headerName: "Site name",
      flex: 1,
      width: 180,
      valueGetter: (params) => params.row.siteName,
      renderCell: (params) => (
        <Tooltip title={` ${ params.row.siteName}`}>
           <span>{handleTextOverflow(params.row.siteName)}</span>
           </Tooltip>
            ),
    },

    {
      field: "addressLine1",
      headerName: "Address",
      flex: 0,
      width: 180,
      valueGetter: (params) => params.row.addressLine1,
      renderCell: (params) => (
        <Tooltip title={` ${ params.row.addressLine1}`}>
           <span>{handleTextOverflow(params.row.addressLine1)}</span>
           </Tooltip>
            ),
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      width: 180,
      headerAlign: "center",

      getActions: (params) => [
       
      (<>
      {viewZone &&
        <GridActionsCellItem
          icon={<ZoneView
            title="Zone Details"
            rowData={params.row} />}
          label="View"
        />}
        </>),
      ],
    },
  ]

  return (
    <>
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          <HeaderToolbar  title="Zone" />
        </Grid>
      </Box>

  {listZone &&
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >

      <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "1",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
      </Box>

      <Box item>
          <FilterChecklistTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
      </Box>

      <Box item>
        <Tooltip title={"Refresh"}>
              <IconButton onClick={() => loadData(filter, paging)}>
                <Refresh />
              </IconButton>
            </Tooltip>
      </Box>

      <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
      </Box>

      </Box>}

    {listZone ? (
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
        ): (<div style={{marginTop: '185px', paddingLeft: '300px'}}>
              <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                {t('CVROLE005')}
              </Typography>
            </div>)
    }

    </>
  );
}

export default ZoneList;
