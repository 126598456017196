import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withRouter } from 'react-router-dom';

function UserAgreement() {
    return (
        <Grid>
                <Grid container>
                    <Grid item xs>
                        <Typography variant='body2' gutterBottom  >
                            This Agreement applies only to the ZKBio Cloud Visitor product or service (hereinafter referred to as: ZKBio Cloud Visitor). Last updated: March 2022. If you have any questions,
                            comments, or suggestions, please contact your distributor or branch office in your country or contact us at the following contact information. Email: service@zkteco.com
                            If you are a minor, please consult your guardian, and ask the guardian to accompany you to read and understand the Agreement.
                            <br /><br />
                            I. Special Notice
                            <br /><br />
                            1.1 This User Agreement (here in after referred to “the Agreement”) is an agreement between you (hereinafter referred to “you” or “User”) and ZKTeco Co., Ltd. and its affiliates
                            (hereinafter referred to “ZKTeco”, or “Company” or “We”) regarding your registration, use of ZKBio Cloud Visitor related products and services (including but not limited to through the
                            ZKBio Cloud Visitor web server, ZKBio Cloud Visitor mobile app) and your access to related information, advice and/or services through this.
                            <br /><br />
                            1.2 Before you use the services provided by the Software, please read this Agreement carefully and fully understand the content of each clause of this Agreement. The privacy policy,
                            usage rules and other provisions issued by the Software are supplementary to this Agreement and are inseparable from and have the same legal effect as this Agreement. When you
                            follow the instructions on the registration page to fill in the information, submit the documents, read and agree to this Agreement and complete the registration process, it means that you have fully read, understood and accepted the entire content of this Agreement. If you do not agree to accept this Agreement or any of its terms and conditions, you should immediately stop the registration process.
                            <br /><br />
                            1.3 You expressly agree that the Software has the right to update or amend the foregoing agreement and terms and conditions from time to time in accordance with changes in laws and regulations and/or operational needs, and to make advance public notice and solicit comments from you, and that such updates or amendments will be made public through platform announcements, SMS, emails, etc. The Software is not required to make separate announcements or notifications to you personally. You should take the initiative to check the public announcement of the Software from time to time, and if you do not agree with the update or amendment, you may stop using the Platform services. If you continue to use the Software, you will be deemed to have accepted our updates and modifications to the foregoing agreement and terms.
                            <br /><br />
                            1.4 You should carefully read and fully understand the terms and conditions in this agreement, especially the disclaimer that relates to the exclusion or limitation of our liability, the terms and conditions that limit your rights, the terms and conditions that agree on dispute resolution, jurisdiction, and application of law.
                            <br /><br />
                            1.5 Please read and fully understand the content of this Agreement and all relevant agreements, rules and other documents published by the Software. If you do not have full civil
                            capacity, please read it in the company of a legal guardian. By registering as a user of the Software and using the services provided by the Software, you are deemed to have accepted
                            the foregoing documents and any updates, modifications, deletions and additions to the foregoing documents made by us in accordance with the provisions of this Agreement.
                            <br /><br />
                            II. Service content
                            <br /><br />
                            2.1 The software provides you with related services, including but not limited to Device management: device addition and control services Access control: access control time
                            management and remote door opening and closing Site management: place combined with equipment management services Personnel and organization management: personnel
                            addition and position authority distribution management services Reports: generation of various reports.
                            <br /><br />
                            2.2 You agree and acknowledge that the Company shall only provide the services explicitly agreed in this Agreement, except for the equipment related to the relevant software services (such as personal computers, cell phones, other devices related to access to the Internet or mobile networks) and the related fees charged by third parties (such as telephone and Internet access fees paid for access to the Internet and cell phone fees paid for the use of mobile networks), which shall be borne by you.
                            <br /><br />
                            III. Declaration of Intellectual Property Rights
                            <br /><br />
                            3.1 The Company is the owner of the intellectual property rights of the Software. All copyrights, trademarks, patents, trade secrets and other intellectual property rights of the Software, as well as all information content related to the Software (including but not limited to text, pictures, audio, video, graphics, interface design, layout frames, relevant data or electronic documents, etc.) are protected by the laws and regulations of the People's Republic of China and the corresponding international treaties. The Company shall enjoy the above-mentioned intellectual property rights, except for the rights that the relevant right holders shall enjoy in accordance with the provisions of the law.
                            <br /><br />
                            3.2 Without the written consent of the Company or the relevant right holder, the User shall not implement, utilize or transfer the above intellectual property rights for any commercial or
                            non-commercial purposes by itself or license any third party.
                            <br /><br />
                            IV. Change, interruption, or termination of services
                            <br /><br />
                            4.1 The user understands that the Company needs to overhaul or maintain the Software or related equipment on a regular or irregular basis, and that the ability to connect to the Internet is limited by the stability of the global network, the state of technology, the location of the user and the network used, power supply, government control, computer viruses, hacker attacks and other existing uncertainties, and that the Company shall not be liable for any interruption of service caused by such circumstances, and shall provide prior notice except in special circumstances.
                            <br /><br />
                            4.2 The Company has the right to interrupt or terminate the provision of all or part of the software and services under this Agreement to you at any time without any liability to you or any third party if any of the following circumstances occurs.
                            <br /><br />
                            1) The personal information provided by you is untrue.
                            <br /><br />2) you violate the rules of use set forth in this Agreement.
                            <br /><br />
                            3) you do not pay the corresponding service fee to the Company as required when using the software fee service.
                            <br /><br />
                            4)Your behavior violates laws and regulations or government regulations.
                            <br /><br />
                            4.3 The user's registered account or use of the Software and services shall not engage in behavior or manner that violates laws, regulations or national policy requirements, or infringes on the legitimate rights and interests of any third party, and if any of the above circumstances exist, the user shall bear the corresponding legal responsibility, and the Company shall have the right to prohibit the user from continuing to use the account or stop the user from continuing to use the Software, or provide the relevant information to the government, court or prosecuting authority in accordance with the relevant laws or government orders, and the user shall bear the liability for any loss caused to the Company as a result.
                            <br /><br />
                            4.4, If you decide to stop using for the payment application, you may voluntarily submit a request to our partners & us to terminate the service, but we will not refund a portion of the fees for the service that you have enjoyed from the time you started the service until the moment which you submit the termination.
                            <br /><br />
                            4.5, If, as a person in the enterprise/organization, you no longer use the paid services provided in our software products, please make your own request to the owner/administrator of the enterprise/organization.
                            <br /><br />
                            V. Use rules
                            <br /><br />
                            5.1 After the user's registration is completed, a user account is automatically generated, which is under the control of the software user and gains the right to use the account. Once the account is used, any form of transfer, including but not limited to gift, loan, rent, transfer or sale, is prohibited. The user assumes responsibility for the safekeeping of the account and password and is solely responsible for all activities under his account, sub-accounts created by his account and password (whether or not done by or with the authorization of the user), and the user is responsible for the safekeeping of the registered account information and the actions under the password, and the user is legally responsible for the registered account and the actions under the password. Users agree not to use other members' accounts and passwords under any circumstances.
                            <br /><br />
                            5.2 Users shall not transfer or lend their accounts and passwords to others for use. If the user finds that his or her account has been used illegally by others, he or she should immediately notify our company. The user shall be responsible for all losses that occur due to hacking or the user's negligence in keeping the account and password illegally used by others, and the Company shall not be responsible for any losses. If this causes damage to the Company, the user agrees to compensate the Company for the damage.
                            <br /><br />
                            5.3 The user has the right to change and delete personal data, registration information and transmission content in the Software, but it should be noted that deletion of the relevant information will also delete any text and images you have stored in the system, and the user shall bear the risk.
                            <br /><br />
                            5.4 Users shall comply with the terms and conditions of this Agreement and use the Local Services correctly and appropriately. If the User violates any of the terms and conditions of this Agreement, the Software shall have the right to terminate the provision of services to the account of the defaulting User in accordance with the Agreement. At the same time, the Software reserves the right to withdraw the account and user name of the Software at any time.
                            <br /><br />
                            5.5 Users shall provide true, accurate, legal, valid and complete information as guided by the registration page if necessary when registering for an account. For individual users, including and not limited to the use of their real name, cell phone number, cell phone SMS, ID card or other supportable documents and other personal information for real name verification; for legal person users, including and not limited to the enterprise unified social credit code / business registration number, the legal representative of the enterprise ID card number, enterprise CA certification and other information for real name verification.
                            <br /><br />5.6 Users shall not register with false information or other illegal and undesirable information. The ownership of the user's account belongs to the Software. After the user completes the application registration procedure, he/she only obtains the right to use the account, and the right to use belongs to the applicant registrant only. At the same time, the applicant registrant shall not gift, transfer or otherwise license the account to others.
                            <br /><br />
                            5.7 Users are obliged to ensure the security of their accounts and passwords. If the user discovers that the account has been used without authorization or any other security problems, such as being informed by others of their registration data, or the account is used illegally due to hacking or the user's negligence in storage, the user shall bear the loss, including but not limited to the loss of the service data of the Software, the information data carried in the account, etc. The Software and the owner of the Software shall not bear any the software and the software owner shall not be held responsible. If the user loses or forgets the password and resets the password by cell phone number, password fingerprint,
                            password face, etc., the Software is only responsible for verifying whether the verification information is consistent with the information related to the account, and is not responsible for the impersonation verification and the loss caused by it..
                            <br /><br />
                            5.8 Users may incur data traffic costs in the process of using the Software, and users are required to find out the relevant tariff information from the operators and bear the related costs..
                            <br /><br />
                            5.9 Users fully understand and agree that the Software is only a platform for users to share, transmit and obtain information, and users must be responsible for all actions under their registered accounts, including any content transmitted by you and any results arising therefrom. Users shall exercise their own judgment regarding the content in the Software and bear all risks arising from the use of the content, including risks arising from the correctness, completeness, usefulness or reliance on the content. The Software shall not be liable for any loss or damage arising from the User's actions.
                            <br /><br />
                            5.10 Any content transmitted by the user in or through the Software does not reflect the views and policies of the Software and the Software assumes no responsibility for them.
                            <br /><br />
                            5.11 The user fully understands and agrees that the Software is a product based on the user's identity service, and the user must assume full responsibility for the authenticity, legality, and validity of the information registered in the Software. Users shall not impersonate others, shall not use the name of others to disseminate any information, shall not maliciously register and use the registered account to cause other users to misidentify, otherwise the Software has the right to immediately stop providing services, and the user alone shall bear all legal responsibilities arising there from.
                            <br /><br />
                            5.12 The user must be solely responsible for the authenticity, legality, harmlessness and validity of the information transmitted in the Software, and any legal responsibility related to the information disseminated by the user shall be borne by the user and has nothing to do with the Software.
                            <br /><br />
                            5.13 The services provided by the Software may include advertisements, and the User agrees to display advertisements provided by the Software and third-party suppliers and partners in the course of use.
                            <br /><br />
                            5.14 Users must follow the following principles in the process of using the Software.
                            <br /><br />
                            1) comply with all agreements, regulations and procedures relating to the Software and the Services.
                            <br /><br />
                            2) not to use the software for any illegal purpose.
                            <br /><br />
                            3) not to use the Software in any form to infringe upon the commercial interests of the Company, including and not limited to publishing commercial advertisements that are not permitted by the Company.
                            <br /><br />
                            4)shall not use the Software for any act that may adversely affect the normal operation of the Internet or mobile network.
                            <br /><br />
                            5) shall not use the Software to upload, display or transmit any false, harassing, libelous, abusive, threatening, vulgar and obscene or any other illegal information data.
                            <br /><br />
                            6) shall not infringe on the patent rights, copyright trademark rights, reputation rights or any other legitimate rights and interests of any other third party.
                            <br /><br />
                            7) shall not use the Software and the Services for any conduct detrimental to the Company.
                            <br /><br />
                            5.15 Any content produced, copied, published or disseminated by users in the course of using the Software and Services, including but not limited to account avatars, names, user descriptions and other registration information, or text, voice, images, etc. sent, replied to, accompanying graphics and related link pages, as well as other content generated by the use of the account or the Software and Services, shall not infringe the legitimate rights and interests of other users or third parties, including but not limited to
                            <br /><br />
                            1) Spreading rumors, disturbing social order and destabilizing society
                            <br /><br />
                            2) Spreading obscenity, pornography, gambling, violence, terrorism or abetting crime
                            <br /><br />
                            3) Insulting or slandering others and infringing on their legitimate rights and interests
                            <br /><br />
                            4) inciting illegal assemblies, associations, marches, demonstrations, and gatherings to disrupt social order
                            <br /><br />
                            5) activities in the name of illegal civil organizations
                            <br /><br />
                            6) Containing other contents prohibited by laws and administrative regulations
                            <br /><br />
                            7) publishing, transmitting, disseminating, storing content that infringes on the legal rights of others such as reputation, portrait rights, intellectual property rights, trade secrets, etc.
                            <br /><br />
                            8) involving the privacy, personal information or information of others
                            <br /><br />
                            9) publishing, transmitting, disseminating harassment, advertising information, excessive marketing information and spam or containing any sexual or sexually explicit information
                            <br /><br />
                            10) Other information that violates laws, regulations, policies and public order and morality, social morality or interferes with the normal operation of the Software and violates the legitimate rights and interests of other users or third parties.
                            <br /><br />
                            5.16 Except as permitted by law or with the written permission of the Software, users shall not engage in the following acts in the course of using the Software.
                            <br /><br />
                            1) Deleting the information on the Software and its copies regarding copyright.
                            <br /><br />
                            2) Reverse engineer, reverse assemble, reverse compile, or otherwise attempt to discover the source code of the Software.
                            <br /><br />
                            3) Illegal use, rental, lending, copying, modification, linking, reproduction, compilation, publication, publication, establishment of mirror sites, etc. of the content of which the owner of the Software owns the intellectual property rights.
                            <br /><br />
                            4) Copying, modifying, adding, deleting, linking, running or creating any derivative works of the Software or the data released into the memory of any terminal during the operation of the Software, the interaction data between the client and the server during the operation of the Software, and the system data necessary for the operation of the Software, including but not limited to using plug-ins, plug-ins or third-party tools/services not authorized by the Software to access the Software and related systems.
                            <br /><br />
                            5) Adding, deleting, changing the functions or operating effects of the Software by modifying or falsifying the instructions or data in the operation of the Software, or operating or disseminating the Software or methods used for such purposes to the public, whether or not such acts are for commercial purposes.
                            <br /><br />
                            6) Interfering with the Software, its components, modules and data by itself or authorizing others or third party software.
                            <br /><br />
                            5.17 Any statements, notices, warnings, etc. made by the Company by various means (including but not limited to web announcements emails, SMS alerts, etc.) for the use of certain specific software/services are considered part of this Agreement, and the user is deemed to be aware of and agree to the contents of such statements, notices, warnings if he/she uses the software/services.
                            <br /><br />
                            5.18 The Company shall have the right to review and supervise the use of the Software Services (including but not limited to free or paid services) by the User in accordance with the terms of the Privacy Policy of the Software (including but not limited to the review of the content stored by the User in the Company), and if the User violates any of the above provisions when using the Software Services, the Company shall have the right to require the User to correct or directly take all necessary measures (including but not The Company reserves the right to require the user to correct or directly take all necessary measures (including but not limited to changing or deleting the user's posted content, suspending or terminating the user's right to use the software and services) to mitigate the impact of the user's misconduct. Due to the user's own behavior to be responsible to third parties, the user shall bear their own responsibility, and the Company has nothing to do.
                            <br /><br />
                            5.19 Software Updates.
                            <br /><br />
                            Although the company is not obligated to provide you with update services, it may still provide you with software updates. Replacement or supplemental software updates provided by the Company are governed by this Agreement, unless such updates are accompanied by a separate user service agreement, in which case they will be governed by the latter. If you decide not to download the updates provided by us, you understand and acknowledge that you may be placing the Software at serious security risk and may render the Software unavailable or unstable. Some Software features may be version limited and therefore updating to the latest version may enhance your user experience.
                            <br /><br />
                            5.20 If a user uses a service function that involves a service provided by a third party of the Software, in addition to complying with this Agreement, the user shall also comply with the user agreement and rules of the third party. The correctness, accuracy, security, effectiveness and any possible risk of uncertainty of the services provided by third parties are not related to the Software and all parties to the Software. The Software and the Owners of the Software shall not be liable for any disputes or damages arising from the services provided by third parties. Any dispute arising from third party software or technology used by the Software shall be resolved by the third party and the Software and the Software Owners shall not be liable for any dispute.
                            <br /><br />
                            VI. License and Permissions
                            <br /><br />
                            6.1 Subject to your compliance with this Agreement, we grant you a limited, non-exclusive, non-transferable license to download and install one copy of the Application onto a single mobile device owned or controlled by you and to run such copy of the Application solely for your own personal use.
                            <br /><br />
                            6.2 You may not.
                            <br /><br />
                            1. license, sublicense, sell, resell, transfer, assign, distribute, distribute, or otherwise commercially exploit or make available the Services or Application to any third party in any manner.
                            <br /><br />
                            2. modify the Services or Applications or create derivative works therefrom.
                            <br /><br />
                            3. create Internet 'links to the Services, or design or mirror any Application on any other server or wireless or Internet- based device.
                            <br /><br />
                            4. reverse engineering or accessing an application to design or build a competitive product or service, designing or building a product using ideas or graphics similar to those of a service or application, or copying any idea, feature, function or graphic of a service or application.
                            <br /><br />
                            5. Launch automated programs or scripts that send multiple server requests per second or programs that overburden or impede the work and/or performance of the service or application.
                            <br /><br />
                            6.3 In addition, you may not.
                            <br /><br />
                            1. send spam or other forms of repetitive or unwanted mail that violate applicable law.
                            <br /><br />
                            2. send or store infringing, obscene, threatening, defamatory or otherwise illegal or infringing material, including material that endangers children or offends the privacy rights of third parties.
                            <br /><br />
                            3. sending or storing information containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or programs.
                            <br /><br />
                            4. obstruct or disrupt the integrity or performance of the website, applications, services or data contained therein..
                            <br /><br />
                            5. Attempting to gain unauthorized access to the website, application, service or its associated systems or networks.
                            <br /><br />
                            6.4 To the maximum extent permitted by law, we will have the right to investigate and prosecute any such violations. We may participate in and assist law enforcement authorities in prosecuting users who violate this Agreement. We reserve the right to remove or disable access to any Content at any time without notice if we believe that such Content violates this Agreement or otherwise jeopardizes the Site, the Software and/or the Services or Application Stalk order therein.
                            <br /><br />
                            VII. Privacy Protection
                            <br /><br />
                            7.1 Protection of user privacy is a basic policy of the Company, and the Company guarantees that it will not disclose or provide to third parties not affiliated with the Company the registration data of individual users and the non-public content stored in the Software by users when using the Software and the Services, except for the following cases.
                            <br /><br />
                            1. with the prior express authorization of the user.
                            <br /><br />
                            2.In accordance with the requirements of relevant laws and regulations.
                            <br /><br />
                            3.In accordance with the requirements of the relevant governmental authorities.
                            <br /><br />
                            4. in order to safeguard the interests of the public
                            <br /><br />
                            5.For the purpose of maintaining the legitimate rights and interests of the Company.
                            <br /><br />
                            7.2 The privacy policy published by the Software is a valid part of this Agreement, and you agree that the Company may update the privacy policy from time to time and accept the updated privacy policy.
                            VIII. Disclaimer
                            <br /><br />
                            8.1 The Company shall not be liable for any problems arising from the use of the Software due to abuse, misuse or unauthorized modification. The user expressly agrees that the risk of using the Company's software and services will be borne entirely by the user; all consequences arising from the user's use of the Company's software and services will also be borne by the user, and the Company shall not be liable to the user in any way.
                            <br /><br />
                            Active
                            <br /><br />
                            InActive
                            <br /><br />
                            8.4 The User understands that the Company cannot guarantee that the products or services it provides will be free of defects, but the Company is committed to continuously improving the quality and level of service. Therefore, the user agrees that even if there are defects in the services provided by the Company, such defects are unavoidable at the time of the technical level of the industry and will not be considered as a breach of contract by the Company. The user agrees to cooperate with the Company to solve the above-mentioned defects.
                            <br /><br />
                            8.5 Our software is designed for non-profit organizations, private business organizations and individuals. We do not provide services to any illegal organizations or terrorists.
                            <br /><br />
                            IX. Compensation
                            <br /><br />
                            9.1 By accepting this Agreement and using each application and/or service in the Software, you agree that you shall indemnify and hold harmless the Company from and against any and all claims, costs, damages, losses, liabilities and expenses (including legal fees and court costs) arising out of or related to the following matters
                            <br /><br />
                            1. your violation or breach of any provision of this Agreement or any applicable law or regulation (whether or not referred to herein).
                            <br /><br />
                            2. You have violated any rights of any third party.
                            <br /><br />
                            9.2 To the extent permitted by applicable law, we shall not be liable under any circumstances (regardless of cause) for: <br /><br />1. any indirect, intentional, punitive, incidental, exemplary, special or consequential damages;<br /><br /> 2. loss of business or
                            <br /><br />
                            opportunity; <br /><br />3. loss of revenue;<br /><br /> 4. loss of profits;<br /><br /> 5. loss of goodwill; <br /><br />6. loss of content; or <br /><br />7. loss of data.
                            <br /><br />X. Modification of the Agreement
                            <br /><br />
                            The Company has the right to modify any of the terms of this Agreement at any time. Once the content of this Agreement changes, the Company will publish the modified content of the Agreement on the ZKBio Cloud Visitor webpage and mobile app, and request users to re-view and agree to the modified content.
                            <br /><br /><br />
                            XI. Force Majeure
                            <br /><br />
                            The user agrees that the lessor is obligated by law to provide basic security, but cannot be held responsible for damages caused to the user due to failure of information network infrastructure, information network equipment maintenance connection, failure of computer, communication or other systems, power failure, strike, riot, fire, natural disaster, explosion, war, governmental action, order of judicial and administrative authorities, conflicts between employees and administrators in the management of the enterprise or due to third parties.
                            <br /><br />
                            The user agrees that the lessor is obligated by law to provide basic security, but cannot be held responsible for damages caused to the user due to failure of information network infrastructure, information network equipment maintenance connection, failure of computer, communication or other systems, power failure, strike, riot, fire, natural disaster, explosion, war, governmental action, order of judicial and administrative authorities, conflicts between employees and administrators in the management of the enterprise or due to third parties.
                            <br /><br />
                            XII. Service of notice
                            <br /><br />
                            12.1 All notices from the Company to the User under this Agreement may be made by means of web announcements, e-mail, cell phone text messages or conventional mail transmission; such notices shall be deemed to have been delivered to the recipient on the date of delivery.
                            <br /><br />
                            12.2 Notices from users to the Company shall be delivered through the Company's officially published mailing address, fax number, e-mail address and other contact information. Such notices shall be served on the date of actual receipt by the Company.
                            <br /><br />
                            1. The conclusion, execution and interpretation of this Agreement and the settlement of disputes shall be governed by the laws of China and subject to the jurisdiction of the Chinese courts.
                            <br /><br />
                            2. If any dispute arises between the parties regarding the content of this Agreement or its implementation, the parties shall try to resolve it through friendly consultation; if consultation fails, either party may file a lawsuit with the people's court with jurisdiction in the place where the Agreement is signed. The place where the agreement is signed: Tangxia Town, Dongguan City, Guangdong Province.
                            <br />
                        </Typography>
                    </Grid>
                </Grid>
        </Grid>
    );
}
export default withRouter(UserAgreement);
