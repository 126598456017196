import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "./phoneInput.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled } from "@mui/material/styles";
import { createTheme } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, createFilterOptions, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { listHost, allHostList } from "../../slices/HostSlice";
import { getSitesList } from "../../slices/Site/SiteSlice";
import CommonUtil from "../../Util/CommonUtils";
import { createInvitation } from "../../slices/Invitations/InvitationsSlice";
import Toast from "../../components/ToastContainer/CustomToast";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
import CancelButtons from "../../components/DialogBoxComponent/CancelButtons";
import CustomDateTimePicker from "../../components/CustomInputs/CustomDateTimePicker";
import { getPurposeType } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";
import axios from "axios";
import ProfileUplod from "../Invitations/UploadImg";
import CommonPhoneInput from "../../components/CustomInputs/CommonPhoneInput";
import { useTranslation } from "react-i18next";


const MuiTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: process.env.REACT_APP_BG_ICON,
    },
    secondary: {
      main: "#f50057",
    },
  },
});


const useStyles = makeStyles((theme) => ({
  cssLabel: {
    color: '#0000008a'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#1976d2 !important',
    }
  },
  cssFocused: {
    color: '#1976d2 !important'
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important'
  },
  dialogbox: {
    "& .css-hlj6pa-MuiDialogActions-root": {
      marginLeft: '290px !important',
    },
    "& .css-ypiqx9-MuiDialogContent-root": {
      padding: '0px !important',
    },
  },

}));

const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  color: '#36C96D',
  border: "1px solid  #36C96D",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "#36C96D",
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  color: '#36C96D',
  border: "1px solid #36C96D",
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "#36C96D",
    color: "#FFFF",
  },
}));

export default function CreateNewRegistration(props) {
  const { t } = useTranslation()
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const [profilePhoto, setProfilePhoto] = useState("");
  const [siteData, setSiteData] = useState([]);
  const [data, setData] = useState();
  const [hostList, setHostList] = useState([]);
  const [selectedHost, setSelectHost] = useState({});
  const classes = useStyles();
  const zlink = localStorage.getItem("redirectFrom");
  const [InavalidprofilePhoto, setInavalidprofilePhoto] = useState(false)
  const [purposeData, setPurposeData] = useState([]);
  const [phoneInput, setPhoneInput] = useState("");
  const [profile, setProfile] = React.useState();
  const [profileImage, setProfileImage] = React.useState();
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );
  var today = new Date();

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";

  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T" +
    (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
    ":" +
    (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());

  var changeDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T" +
    (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
    ":" +
    (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());

  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    phoneCountryCode: "",
    purposeTypeId: "",
    hostId: "",
    visitorCompanyName: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    scheduledchangeDate: CommonUtil.formatToUtc(changeDate),
    earlyCheckInTime: "2022-04-11T21:00:00",
    visitMode: "WALKIN_REGISTARTION",
    earlyCheckin: false,
    profilePhoto: "",
  });

  const [countryCode, setCountryCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    emailId: false,
    phoneNumber: false,
    purposeTypeId: false,
    hostId: false,
    visitorCompanyName: false,
    siteId: false,
    profilePhoto: false,
    scheduledStartDate: false,
    scheduledEndDate: false,
  });

  const uploadProfilePhoto = (data) => {

    var bodyFormData = new FormData();
    bodyFormData.append('url', data.data.profilePhotoURL);
    bodyFormData.append('file', profileImage);
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
      })
      .catch(function (response) {

      });
  };

  const onError = (data) => {
    if (data)
      switch (data.code) {
        case "CVAE1015":
          setError({ emailId: true });
          break;
        case "CVAE1016":
          setError({ emailId: true });
          break;
        case "CVAE1012":
          setError({ firstName: true });
          break;
        case "CVAE1013":
          setError({ firstName: true });
          break;
        case "CVAE1017":
          setError({ phoneNumber: true });
          break;
        case "CVAE1041":
          setError({ purposeTypeId: true });
          break;
        case "CVAE1019":
          setError({ hostId: true });
          break;
        case "CVAE1021":
          setError({ scheduledStartDate: true });
          break;
        case "CVAE1022":
          setError({ scheduledEndDate: true });
          break;
        case "CVAE1023":
          setError({ scheduledEndDate: true });
          break;
        case "CVAE0300":
          setError({ siteId: true });
          break;
        case "OMSE0311":
          setError({ visitorCompanyName: true })
          break;
        case "CVAE1034": {
          setError({ emailId: true })
          break;
        }
        default:
          setError({ ...error });
      }
    Toast(data.message, "error");
  };

  const handleSumbit = () => {

    if (!InavalidprofilePhoto) {
      if (CommonUtil.isEmptyString(payload.firstName)) {
        Toast("Please Enter First Name", "error");
        setError({ firstName: true });
        return;
      }

      if (!CommonUtil.isValidName(payload.firstName)) {
        Toast("Please Enter Valid First Name", "error");
        setError({ firstName: true });
        return;
      }

      if (CommonUtil.isEmptyString(payload.emailId) && CommonUtil.isEmptyString(payload.phoneNumber)) {
        Toast("Email or mobile number is mandatory", "error");
        setError({ emailId: true });
        return;
      }

      if (!CommonUtil.isValidEmails(payload.emailId)) {
        setError({ ...error, emailId: true });
        Toast(t("CORRECTEMAIL"), "error");
        return;
      }

      if (decoded_jwtToken.email === payload.emailId.trim() || payload.emailId.trim() === selectedHost.email) {
        setError({ emailId: true });
        Toast("Visitor email can not be same as Host or logged user email", "error");
        return;
      }

      if (payload.phoneNumber !== null && payload.phoneNumber !== undefined && payload.phoneNumber !== "") {
        if (decoded_jwtToken.phone === payload.phoneNumber) {
          setError({ phoneNumber: false });
          Toast(t("INVITATIONFORM009"), "error");
          return;
        }
      }

      if (CommonUtil.isEmptyString(payload.purposeTypeId)) {
        Toast("Please Select the Visitor Purpose", "error");
        setError({ purposeTypeId: true });
        return;
      }

      if (CommonUtil.isEmpty(selectedHost)) {
        Toast("Host name is mandatory", "error");
        setError({ selectedHost: true });
        return;
      }

      if (CommonUtil.isEmptyString(payload.visitorCompanyName)) {
        zlink ? (Toast("Please Enter the Visitor Company Name", "error"))
          :
          (Toast("Please Enter the Visitor Company Name", "error"))
        setError({ visitorCompanyName: true });
        return;
      }

      if (CommonUtil.isEmptyString(payload.siteId)) {
        Toast("Please Select the Site", "error");
        setError({ siteId: true });
        return;
      }

      if (payload.scheduledStartDate === null || payload.scheduledStartDate === undefined || payload.scheduledStartDate === "") {
        setError({ scheduledStartDate: true });
        Toast("Scheduled start date is mandatory", "error");
        return;
      }

      if (payload.scheduledEndDate === null || payload.scheduledEndDate === undefined || payload.scheduledEndDate === "") {
        setError({ scheduledEndDate: true });
        Toast("Scheduled end date is mandatory", "error");
        return;
      }

      if (new Date(payload.scheduledStartDate) < new Date(payload.scheduledchangeDate)) {
        setError({ scheduledStartDate: true });
        Toast("You can't schedule for a past time.", "error");
        return;
      }

      // if (payload.scheduledStartDate > new Date() && payload.scheduledEndDate > new Date()) {
      //   Toast("Invite start date and end date only accept the current date and time", "error");
      //   return
      // }

      if (CommonUtil.isEmptyString(countryCode) && !CommonUtil.isEmptyString(payload.phoneNumber)) {
        Toast(t("COMMON133"), "error");
        return
      }
  
      if (!CommonUtil.isEmptyString(countryCode) && CommonUtil.isEmptyString(payload.phoneNumber)) {
        Toast(t("COMMON134"), "error");
        return
      }

      if (new Date(payload.scheduledStartDate) < new Date() && new Date(payload.scheduledStartDate) < new Date(payload.scheduledEndDate)) {
        var dt = new Date();
        var addMinutes = new Date().setMinutes(dt.getMinutes());
        var currentDate = CommonUtil.formatToUtc(addMinutes);

        const dto = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          purposeTypeId: payload.purposeTypeId.id,
          visitorTypeId: payload.visitorTypeId,
          hostId: payload.hostId,
          phoneCountryCode: !CommonUtil.isEmpty(payload.phoneNumber) ? "+" + countryCode : '',
          // phoneCountryCode: payload.phoneCountryCode,
          phoneNumber: payload.phoneNumber,
          scheduledStartDate: currentDate,
          scheduledEndDate: CommonUtil.formatToUtc(payload.scheduledEndDate),
          emailId: payload.emailId,
          visitMode: payload.visitMode,
          visitorCompanyName: payload.visitorCompanyName,
          siteId: payload.siteId.id,
          earlyCheckin: payload.earlyCheckin,
          profilePhoto: payload.firstName,
        };
        dispatch(createInvitation(dto))
          .unwrap()
          .then((data) => {
            if (data?.code === "CVAI0067") {
              if (!CommonUtil.isEmptyString(data?.data?.profilePhotoURL)) {
                uploadProfilePhoto(data);
              }
              handleClose(false);
              Toast("Registration Sent Successfully", "success");
            }

            else {
              onError(data);
            }
          });
      }

      else if (new Date(payload.scheduledStartDate) > new Date() && new Date(payload.scheduledStartDate) < new Date(payload.scheduledEndDate)
      ) {
        const dto = {
          firstName: payload.firstName,
          lastName: payload.lastName,
          purposeTypeId: payload.purposeTypeId.id,
          emailId: payload.emailId,
          phoneNumber: payload.phoneNumber,
          phoneCountryCode: !CommonUtil.isEmpty(payload.phoneNumber) ? "+" + countryCode : "",
          profilePhoto: payload.firstName,
          visitTypeId: payload.visitTypeId,
          visitMode: payload.visitMode,
          hostId: payload.hostId,
          visitorCompanyName: payload.visitorCompanyName,
          siteId: payload.siteId.id,
          scheduledStartDate: CommonUtil.formatToUtc(payload.scheduledStartDate),
          scheduledEndDate: CommonUtil.formatToUtc(payload.scheduledEndDate),
          registrationMode: "ONDEMAND",
        };
        dispatch(createInvitation(dto))
          .unwrap()
          .then((data) => {
            if (data?.code === "CVAI0067") {
              if (!CommonUtil.isEmptyString(data.data.profilePhotoURL)) {
                uploadProfilePhoto(data);
              }
              handleClose(false);
              Toast("Registration Sent Successfully", "success");
            } else {
              onError(data);
            }
          });
      } else {
        Toast("End time should be greater than Start time", "error");
      }
    }
  };

  const newFunction = () => {
    setInavalidprofilePhoto(true)
    setProfilePhoto("")
  }

  const handleProfilePicture = (data) => {
    if (data === "Error") {
      setInavalidprofilePhoto(true)
      setProfilePhoto('')
      Toast('Image size should be less than 7MB', 'error');
      return;
    }
    setInavalidprofilePhoto(false)
    setProfilePhoto(data);
    setPayload({
      ...payload,
      profilePicture: data.split(",")[1],
    });
  };

  const titleStyle = {
    color: "#242424",
    opacity: 1,
  };

  useEffect(() => {
    setInavalidprofilePhoto(false)
    loadData();
  }, []);

  const loadData = () => {
    loadPurposeType();
    loadHosts();
    loadSites();
  };

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI0000") {
          setPurposeData(data?.data?.purposeTypes);
        } else {
          setPurposeData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadHosts = () => {
    const payload = {
      pageNumber: 1,
      pageSize: 100,
      // email: decoded_jwtToken.email,
    };
    // dispatch(listHost(payload))
    dispatch(allHostList(payload))
      .unwrap()
      .then((data) => {
        if (data?.code === "OMSI0000") {
          // getSelectedItem(data?.data?.employees[0]);
          setHostList(data?.data?.employees);
        } else {
          getSelectedItem();
          setHostList([]);
        }
      });
  };

  const loadSites = () => {
    dispatch(getSitesList(data))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVSI0000") {
          setSiteData(data?.data?.site);
        } else {
          setSiteData([]);
        }
      });
  };

  const handleText = (event) => {
    if (CommonUtil.isValidEmails(event.target.value)) {
      console.log(event.target.value);
      const payload = {
        pageNumber: 1,
        pageSize: 0,
        email: event.target.value,
      };
      dispatch(allHostList(payload))
        .unwrap()
        .then((data) => {
          if (data?.code === "OMSI0000") {
            if (!CommonUtil.isEmptyArray(data.data)) {
              // getSelectedItem(data.data.employees[0]);
              setHostList(data?.data?.employees);
            } else {
              setHostList([]);
              getSelectedItem(null);
            }
          }
        });
    }
  };

  const filterOptions = createFilterOptions({
    stringify: ({ firstName, email, lastName }) => `${firstName} ${email} ${lastName}`,
    limit: 1
  });

  const getSelectedItem = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        hostId: newValue.id,
      });
      setSelectHost(newValue);
    } else {
      setPayload({
        ...payload,
        hostId: "",
      });
      setSelectHost({});
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const onSelectChange = (event) => {
    setPayload({
      ...payload,
      visitTypeId: event.id,
      siteId: event.id,
    });
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value);
    setPayload({
      ...payload,
      phoneCountryCode: "+" + data.dialCode,
      phoneNumber: value.toString().replace(data.dialCode, ""),
    });
    setError({ phoneNumber: false });
  };

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    });
  };

  const handleProfile = (file) => {
    setPayload({
      ...payload,
      profilePhoto: payload.firstName,
    });
    setProfile(file.base64);
    setProfileImage(file.file);
  };


  const handlePhoneNumberChange = (value) => {

    var code = "+" + countryCode
    var mobile = value
    setError({
      ...error,
      phoneNumber: ''
    })
    if (mobile.length > 25) {
      return false
    }
    else {
      setPayload({
        ...payload,
        //phoneCountryCode: code,
        phoneNumber: mobile
      })
      setPhoneNumber(value)
    }


  }
  return (
    <Dialog className={classes.dialogbox} open={open} onClose={handleClose} fullWidth={true} maxWidth={"md"}>
      <DialogTitle variant="h4" onClick={handleClose}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        Visitor Registration
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Grid container justifyContent="center" style={{ marginBottom: "15px" }}>
          <Grid
            md={4}
            container
            justifyContent="center"
            style={{ padding: "16px 32px", width: "100%" }}
            spacing={2}
            direction="column"
            alignItems="center"
          >
            <ProfileUplod
              onsetProfile={(profile) => handleProfile(profile)}
              profilePhoto={profile}
            />
          </Grid>
          <Grid
            md={8}
            container
            justifyContent="center"
            style={{ padding: "16px 32px", width: "100%" }}
            spacing={2}
          >
            {!zlink ? (
              <>
                <Grid item lg={6} sm={12}>
                  <CustomTextfield
                    label="First Name"
                    error={error.firstName}
                    name="firstName"
                    value={payload.firstName}
                    handleChange={(e) => handleChange(e)}
                    helperText={error.firstName}
                    validation="alpha-numeric"
                    required={true}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item lg={6} sm={12}>
                  <CustomTextfield
                    label="Visitor First Name"
                    error={error.firstName}
                    name="firstName"
                    value={payload.firstName}
                    handleChange={(e) => handleChange(e)}
                    helperText={error.firstName}
                    validation="alpha-numeric"
                    required={true}
                  />
                </Grid>
              </>
            )}

            {!zlink ? (
              <>
                <Grid item lg={6} sm={12}>
                  <CustomTextfield
                    label="Last Name"
                    error={error.lastName}
                    name="lastName"
                    value={payload.lastName}
                    handleChange={(e) => handleChange(e)}
                    helperText={error.lastName}
                    validation="alpha-numeric"
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item lg={6} sm={12}>
                  <CustomTextfield
                    label="Visitor Last Name"
                    error={error.lastName}
                    name="lastName"
                    value={payload.lastName}
                    handleChange={(e) => handleChange(e)}
                    helperText={error.lastName}
                    validation="alpha-numeric"
                  />
                </Grid>
              </>
            )}

            {!zlink ? (
              <>
                <Grid item lg={6} sm={12}>
                  <CustomTextfield
                    label="Email"
                    error={error.emailId}
                    name="emailId"
                    value={payload.emailId}
                    handleChange={(e) => handleChange(e)}
                    helperText={error.emailId}
                    validation="email"
                    required={true}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item lg={6} sm={12}>
                  <CustomTextfield
                    label="Visitor Email"
                    error={error.emailId}
                    name="emailId"
                    value={payload.emailId}
                    handleChange={(e) => handleChange(e)}
                    helperText={error.emailId}
                    validation="email"
                    required={true}
                  />
                </Grid>
              </>
            )}

            <Grid item lg={6} sm={12} md={12} marginTop={-1.5} >
              {/* <CustomPhone
                value={phoneInput}
                specialLabel={false}
                handleChange={(value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue)}
              /> */}

              <CommonPhoneInput
                sx={{ margin: 'none!important' }}
                error={error.phoneNumber}
                handleCountryCode={(data) => {
                  setCountryCode(data.dialCode)
                  setError({ ...error, phoneNumber: '' })
                }}
                countryCode={countryCode}
                placeholder={t("COMPPROFSCREEN037")}
                phoneNumber={phoneNumber}
                name={'phone'}
                handleChange={(e) => handlePhoneNumberChange(e)}

              />
            </Grid>
            {!zlink ? (
              <>
                <Grid item lg={6} sm={12}>
                  {/* <AutocompleteComponent
                    label="Purpose *"
                    name="purposeTypeId"
                    error={error.purposeTypeId}
                    value={payload.purposeTypeId}
                    onSelect={(e) => onselectPurposeType(e)}
                    options={purposeData}
                    onBlur={() => setError({ ...error, purposeTypeId: "" })}
                  /> */}
                  <Autocomplete
                    style={{ width: "100%" }}
                    noOptionsText={"No Options found"}
                    name="purposeTypeId"
                    value={payload.purposeTypeId}
                    options={CommonUtil.isEmptyArray(purposeData) ? [] : purposeData}
                    getOptionLabel={(option) => (option.name ? option.name : "")}
                    onChange={(event, newValue) => {
                      if (!CommonUtil.isEmpty(newValue)) {
                        // eslint-disable-next-line
                        onselectPurposeType(newValue);
                      } else {
                        onselectPurposeType("");
                      }
                      setError({ purposeTypeId: false });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="purposeTypeId"
                        value={payload.purposeTypeId}
                        error={error.purposeTypeId}
                        variant="outlined"
                        fullWidth
                        label={"Purpose Type *"}
                      />
                    )}
                  />
                </Grid>
              </>
            ) : (
              <>
                <Grid item lg={6} sm={12}>
                  <Autocomplete
                    style={{ width: "100%" }}
                    noOptionsText={"No Options found"}
                    name="purposeTypeId"
                    value={payload.purposeTypeId}
                    options={CommonUtil.isEmptyArray(purposeData) ? [] : purposeData}
                    getOptionLabel={(option) => (option.name ? option.name : "")}
                    onChange={(event, newValue) => {
                      if (!CommonUtil.isEmpty(newValue)) {
                        // eslint-disable-next-line
                        onselectPurposeType(newValue);
                      } else {
                        onselectPurposeType("");
                      }
                      setError({ purposeTypeId: false });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="purposeTypeId"
                        value={payload.purposeTypeId}
                        error={error.purposeTypeId}
                        variant="outlined"
                        fullWidth
                        label={"Visitor Purpose *"}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            <Grid item lg={6} sm={12}>
              <Autocomplete
                style={{
                  margin: "-15px",
                  marginLeft: "1px",
                  color: "#242424",
                }}
                id="free-solo-demo"
                freeSolo
                noOptionsText={'No Options found'}
                fullWidth
                //filterOptions={filterOptions}
                options={CommonUtil.isEmptyArray(hostList) ? [] : hostList}
                getOptionLabel={(option) =>
                  option.lastName ? option.firstName + " " + option.lastName : option.firstName ? option.firstName : ""}
                value={selectedHost}
                onChange={(event, newValue) => {
                  getSelectedItem(newValue);
                  setError({ selectedHost: false });
                }}
                renderInput={(params) => (
                  <CustomTextfield
                    {...params}
                    label="Host"
                    required={true}
                    placeholder="Host *"
                    name="hostId"
                    key="Confirmation Code"
                    id="hostId"
                    handleChange={(e) => handleText(e)}
                    error={error.selectedHost}
                  />
                )}
              />
            </Grid>

            {!zlink ? (
              <>
                <Grid item lg={6} xs={12}>
                  <CustomTextfield
                    label="Company Name"
                    error={error.visitorCompanyName}
                    name="visitorCompanyName"
                    value={payload.visitorCompanyName}
                    handleChange={(e) => handleChange(e)}
                    helperText={error.visitorCompanyName}
                    validation="alphabets"
                  />
                </Grid>
              </>
            ) : (
              <>

                <Grid item lg={6} xs={12}>
                  <CustomTextfield
                    required
                    label="Visitor Company Name"
                    error={error.visitorCompanyName}
                    name="visitorCompanyName"
                    value={payload.visitorCompanyName}
                    handleChange={(e) => handleChange(e)}
                    helperText={error.visitorCompanyName}
                    validation="alphabets"
                  />
                </Grid>
              </>
            )}

            <Grid item lg={6} xs={12} marginTop={2}>
              <Autocomplete
                style={{ width: "100%" }}
                noOptionsText={"No Options found"}
                name="siteId"
                value={payload.siteId}
                options={CommonUtil.isEmptyArray(siteData) ? [] : siteData}
                getOptionLabel={(option) => (option.name ? option.name : "")}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                    // eslint-disable-next-line
                    setPayload({ ...payload, siteId: newValue });
                  } else {
                    setPayload({ ...payload, siteId: "" });
                  }
                  setError({ siteId: false });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="siteId"
                    value={payload.siteId}
                    error={error.siteId}
                    variant="outlined"
                    fullWidth
                    label={"Site *"}
                  />
                )}
              />
            </Grid>

            <Grid item lg={6} sm={12}>
              <CustomDateTimePicker
                onChange={(scheduledStartDate) =>
                  setPayload({
                    ...payload,
                    scheduledStartDate: scheduledStartDate,
                  })
                }
                error={error.scheduledStartDate}
                disableFuture
                disablePast
                value={payload.scheduledStartDate}
                label={"Invite Start Date *"}
              />
            </Grid>
            <Grid item lg={6} sm={12}>
              <CustomDateTimePicker
                onChange={(scheduledEndDate) =>
                  setPayload({
                    ...payload,
                    scheduledEndDate: scheduledEndDate,
                  })
                }
                disablePast
                disableFuture
                value={payload.scheduledEndDate}
                error={error.scheduledEndDate}
                //minDateTime={payload.scheduledStartDate}
                label="Invite End Date *"
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{
        justifyContent: "center",
      }}>
        <SubmitButtons variant='contained' onClick={handleSumbit}>
          <Typography variant="body1" style={{ textTransform: "none" }}>
            Submit
          </Typography>
        </SubmitButtons>
        <CancelButtons variant='contained' color="secondary" onClick={handleClose}>
          <Typography variant="body1" style={{ textTransform: "none" }}>
            Cancel
          </Typography>
        </CancelButtons>
      </DialogActions>
    </Dialog>
  );
}
