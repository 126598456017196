import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CompanyService from '../../services/Company/CompanyService'

export const getCompanyInfo = createAsyncThunk('commpany/info', async () => {
    const res = await CompanyService.get();
    return res.data;
});

export const updateCompanyInfo = createAsyncThunk('commpany/update', async (payload) => {
    const res = await CompanyService.updateCompany(payload);
    return res.data;
});

export const createCompany = createAsyncThunk('commpany/create', async (payload) => {
    const res = await CompanyService.createCompany(payload);
    return res.data;
});

export const getUserByCompanyIdOrCode = createAsyncThunk("company/getUser", async (payload) => {
    const res = await CompanyService.getUserByCompanyIdOrCode(payload)
    return res.data

})