import { Box, Link } from '@mui/material'
import Typography from "@material-ui/core/Typography"
import React, { useEffect, useState } from 'react'
import DataTable from '../../../components/DataTable/DataTable'
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid-pro"
import { getAddressTypes } from '../../../slices/Site/AddressTypeSlice'
import VisitorProfileCard from '../../../components/VisitorActionsComponents/VisitorProfileCard';
import HeaderToolbar from '../../../components/HeaderToolbar';
import ChecklistTemplateDetails from '../../Checklist/ChecklistTemplateDetails';
import AddressTypeFilter from './AddressTypeFilter';
import Search from '../../../components/SearchTab/Search';
import Toast from '../../../components/ToastContainer/CustomToast';
import { useTranslation } from 'react-i18next';

function AddressType() {
    const { t } = useTranslation();
    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [searchedRows, setSerchedRows] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [clear, setClear] = useState(false);
    const [paging, setPaging] = useState({
        page: 1,
        rowsPerPage: 10,
    });
    const [query, setQuery] = useState({
        name: '',
        code: ''
    });

    const defaultQuery = {
        name: '',
        code: ''
    }

    const handleChange = (newPage, size) => {
        setPage(newPage);
        setPaging({
            page: newPage,
            rowsPerPage: size,
        })
    };

    useEffect(() => {
        loadData(query, paging);
    }, [query, paging, clear]);

    const resetFilter = () => {
        setClear(false);
        setPaging({ ...paging, page: 1 })
        setQuery(defaultQuery);
    };

    const loadData = (query, paging) => {
        dispatch(getAddressTypes(Object.assign(query, paging)))
            .unwrap()
            .then((data) => {
                if ((data.code = "LMSI0000" && data.data)) {
                    setRows(data.data.addressType);
                    setTotalRecords(data.data.totalCount);
                    setPage(data.data.currentPage);
                    setTotalPages(data.data.totalPages);
                } else {
                    Toast(data.message, 'error');
                }
            })
            .catch((er) => { });
    };

    const globalsearch = (searchedVal) => {
        const filteredRows = rows.filter((test) => {
          return (
            (test.name? test.name.toLowerCase().includes(searchedVal.toLowerCase()): "") || 
            test.code.toLowerCase().includes(searchedVal.toLowerCase()) ||
            (test.description ? test.description.toLowerCase().includes(searchedVal.toLowerCase()): "")
          );
        });
        setRows(filteredRows);
      };


    const handleFilter = (data) => {
        setClear(true);
        setPaging({ ...paging, page: 1 });
        setQuery({
            name: data.name,
            code: data.code
        });
    }

    const columns = React.useMemo(() => [
        {
            headerName: "",
            sortable: false,
            width: 5,
            renderCell: (params) => {
              return <div></div>;
            },
          },
        {
            field: "name",
            headerName: t("COMMON044"),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.name,
        },
        {
            field: "code",
            headerName: t("COMMON045"),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.code,
        },
        {
            field: "description",
            headerName: t("ATYPE009"),
            flex: 1,
            width: 10,
            valueGetter: (params) => params.row.description,
        },
        {
            field: "Actions",
            headerName: t("ACTION"),
            type: "actions",
            flex: 1,
            minWidth: 190,
            width: 150,
            headerAlign: "center",
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<ChecklistTemplateDetails rowData={params.row} title={t("ATYPE014")}/>}
                    label="View"
                />,
            ],
        },
    ]);

    return (
        <>
            <HeaderToolbar src={''} onClick={''} title={t("ATYPE001")} tooltipTitle="" />
            <Box
                display={"flex"}
                width={"100%"}
                flexDirection={"row-reverse"}
                alignItems={"center"}
            >
                <Box item>
                    {clear && (
                        <Link
                            href="#"
                            underline="hover"
                            className="line"
                            style={{
                                opacity: "1",
                                color: "#E3393C",
                                fontSize: "14px",
                                underline: "hover",
                                display: "inline",
                            }}
                            onClick={resetFilter}
                        >
                            {t("CLEARFILTER")}
                        </Link>
                    )}
                </Box>
                <Box item>
                    <AddressTypeFilter
                        onSubmit={(data) => { handleFilter(data) }}
                    />
                </Box>
                <Box item p={1}>
                    <Search
                        onSearch={(e) => globalsearch(e)}
                        clearSearch={() => loadData(query, paging)}
                    />
                </Box>
            </Box>
            <div style={{ height: 300, width: "100%" }}>
                <DataTable
                    columns={columns}
                    rows={searchedRows.length === 0 ? rows : searchedRows}
                    page={page}
                    count={totalRecords}
                    rowId={(row) => row.id}
                    pageCount={totalPages}
                    pageSize={rowsPerPage}
                    handleChange={(newPage, size) => handleChange(newPage, size)}
                />
            </div>
        </>
    )
}

export default AddressType