export const defaultPermissionCode = {
    'CV_DASHBOARD': false,
    'VIEW_CV_DASHBOARDS': false,
    'CV_ORGANIZATION': false,
    'CV_VIEW_PERSONLISTS': false,
    'CV_VIEW_PERSONPROFILE': false,
    'CV_VIEW_PERMISSION_LIST': false,
    'CV_VIEW_PERMISSIONS': false,
    'CV_SITE_MANAGEMENT': false,
    'CV_VIEW_SITELIST': false,
    'CV_VIEW_SITE': false,
    'CV_VIEW_ZONE_LIST': false,
    'CV_VIEW_ZONE': false,
    'CV_DEVICE_MANAGEMENT': false,
    'DEVICE_LISTS': false,
    'VIEW_CV_DEVICES': false,
    'CREATE_REBOOT_DELETE_ENBLE_DEVICES': false,
    'CV_VISITOR': false,
    'VISITING_FLOWCHART': false,
    'INVITATION_LIST': false,
    'CREATE_INVITATION': false,
    'VIEW_INVITATION': false,
    'CANCEL_INVITATION': false,
    'BLOCK_INVITATION': false,
    'RESCHEDULE_INVITATION': false,
    'RESEND_INVITATION': false,
    'REGISTRATION_LIST': false,
    'CREATE_REGISTRATION': false,
    'VIEW_REGISTRATION': false,
    'CANCEL_REGISTRATION': false,
    'BLOCK_REGISTRATION': false,
    'RESCHEDULE_REGISTRATION': false,
    'RESEND_REGISTRATION': false,
    'CREATE_SELF_REGISTRATION': false,
    'INVITATION_APPROVALS': false,
    'REGISTRATION_APPROVALS': false,
    'BLOCKED_REQUEST_APPROVALS_CV': false,
    'BLOCKED_VISITORS_LIST': false,
    'VIEW_BLOCKED_VISITORS': false,
    'UNBLOCK_VISITORS': false,
    'VISITOR_LOG_LIST': false,
    'VIEW_VISITOR_LOG': false,
    'BLOCK_VISITOR_LOG': false,
    'CV_REPORTS': false,
    'EVENTS_LIST': false,
    'CV_SETTINGS': false,
    'TIMES_LOTS_LIST': false,
    'CREATE_UPDATE_DELETE_TIMES_LOTS': false,
    'VISITING_PURPOSE_LIST': false,
    'VIEW_CV_VISITING_PURPOSE': false,
    'CREATE_EDIT_VISITING_PURPOSE': false,
    'PURPOSE_ACCESS_LEVEL_VISITING_PURPOSE': false,
    'VISITING_ACCESS_LEVEL_LIST': false,
    'MANAGE_CV_VISITING_ACCESS_LEVEL': false,
    'CREATE_EDIT_DELETE_VISITING_ACCESS_LEVEL': false,
    'RESTRICT_TYPE_LIST': false,
    'VIEW_CV_RESTRICT_TYPE': false,
    'CREATE_EDIT_RESTRICT_TYPE': false,
    'CHECKLIST_LIST': false,
    'VIEW_CV_CHECKLIST': false,
    'CREATE_EDIT_CHECKLIST': false,
    'CHECKITEMS_CV_CHECKLIST': false,
    'WATCHLIST_LIST': false,
    'VIEW_CV_WATCHLIST': false,
    'CREATE_EDIT_DELETE_WATCHLIST': false,
    'VIEW_URGENT_MESSAGES': false,
    'CREATE_URGENT_MESSAGES': false, 
}