import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter, page, rowsPerPage) => {
  let query =
    "?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=false&operator=AND&pageNumber=" +
    page +
    "&pageSize=" +
    rowsPerPage;

  if (!CommonUtil.isEmptyString(filter.code)) {
    query = query + "&code=" + filter.code;
  }
  if (!CommonUtil.isEmptyString(filter.name)) {
    query = query + "&name=" + filter.name;
  }
  return query;
};

const getSiteType = (payload) => {
  return api
    .securedAxios()
    .get(
      "/api/v2.0/siteType" +
        buildQuery(payload.filter, payload.page, payload.rowsPerPage)
    );
};

const createSiteType = (payload) => {
  return api.securedAxios().post("/api/v2.0/siteType", payload);
};

const editSiteType = (payload) => {
   return api.securedAxios().put("/api/v2.0/siteType/" + payload.id, payload);
};

const removeSiteType = (idsOrCodes) => {

  return api.securedAxios().delete("/api/v2.0/siteType?idsOrCodes=" + idsOrCodes);
  
}

const SiteTypeService = {
  getSiteType,
  createSiteType,
  editSiteType,
  removeSiteType,
};

export default SiteTypeService;
