import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import "./profileCard.css";
import { Divider, Grid } from "@mui/material";
import ViewIcon from "../../Pages/Invitations/ViewIcon";
import { Tooltip } from '@material-ui/core';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    overflowY: "clip",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    overflowY: "visible",
    borderRadius: "20px",
    minWidth: "1000px",
    height: "480px",
    opacity: 1,
  },
  '& .MuiDialogContent-root ' : {
    padding: '16px 24px',
     position:' relative',
     bottom: '80px',
    }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function BlockedVisitorProfile(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 20) {
        return text.substring(0, 25) + '...';
    } else {
    return text;
}
}

  return (
    <div>
      <div onClick={handleClickOpen}>
        <ViewIcon />
      </div>
      <Grid>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >

          <BootstrapDialogTitle onClose={handleClose}>
            <Avatar
              alt={props.rowData.visitor.firstName}
              src={props.rowData.visitor.profilePhotoURL !== undefined ? props.rowData.visitor.profilePhotoURL : null}
              sx={{
                width: 150,
                height: 150,
                bottom: "80px",
                margin: 'auto',
                border: "solid #FFFF",
                borderWidth: "7px",
              }}
            />
            <div className="CssTextProfileViewPageName">
            {props.rowData.visitor.lastName === null ? props.rowData.visitor.firstName: props.rowData.visitor.firstName + ' ' + props.rowData.visitor.lastName}
            </div>
            <div className="CssTextProfileViewPagePhone">
              {props.rowData.visitor.phone}
            </div>
          </BootstrapDialogTitle>


          <Divider variant="inset" className="MuiDividerCss" />
          <DialogContent style={{ marginLeft: "70px", marginRight: "70px" }}>
            <Grid
              container
              spacing={12.25}
              className="CssTextProfileViewPageContent"
              marginTop={0.5}
            >
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>Email</Grid>
                <Grid item xs={3}>:</Grid>
                <Tooltip title={props.rowData.visitor.email}>
                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'> 
                   {handleTextOverflow(props.rowData.visitor.email)}
                </Grid>
                </Tooltip>
              </Grid>

              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>Host</Grid>
                <Grid item xs={3}>:</Grid>
                <Tooltip title={props.rowData.hostName}>
                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                  {handleTextOverflow(props.rowData.hostName)}
                </Grid>
                </Tooltip>
              </Grid>

              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>Purpose Type</Grid>
                <Grid item xs={3}>:</Grid>
                <Tooltip title={props.rowData.purposeType?.name}>
                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                  {handleTextOverflow(props.rowData.purposeType?.name)}
                </Grid>
                </Tooltip>
              </Grid>

              {/* <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  Reason
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.blackListId.restrictReason}
                </Grid>
              </Grid> */}

              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  Status
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  <span style={{ color: '#D53033', backgroundColor: '#D5303326', opacity: 1 }}>
                    {props.rowData.status === "BLOCKED" ? "Blocked": ""}
                  </span>
                </Grid>
              </Grid>

              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>Restrict Reason</Grid>
                <Grid item xs={3}>:</Grid>
                <Tooltip title={props.rowData.blackListId?.restrictReason}>
                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                  {handleTextOverflow(props.rowData.blackListId?.restrictReason)}
                </Grid>
                </Tooltip>
              </Grid>

            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
    </div>
  );
}

export default BlockedVisitorProfile;
