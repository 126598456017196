import { Box, IconButton, Tooltip, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/DataTable/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import "../../App.css";
import Search from "../../components/SearchTab/Search";
import Toast from "../../components/ToastContainer/CustomToast";
import HeaderToolbar from "../../components/HeaderToolbar";
import DeleteIcon from "../Person/DeleteIcon";
import { deleteAccessLevel } from "../../slices/PerpouseType/PerpouseTypeAccessSlice";
import { getVisitorAccessLevels, getVisitorAccessLevelsByPurposeId, setAccessLevelIds } from "../../slices/VisitorAccessLevels/VisitorAccessLevelSlice";
import AddAccessLevelIcon from "../../../src/assets/access_level.svg";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import PurposeTypeAccessLevelDelete from "./PurposeTypeAccessLevelDelete";
import VisitorAddAccessLevelDialog from "./VisitorAddAccessLevelDialog";
import { REACT_VISITOR_ACCESS_LEVEL_LIST } from "../../actions/EndPoints";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import CommonUtil from "../../Util/CommonUtils";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";

export default function VisitorAccessLevelsPage(props) {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const [idToDelet, setIdToDelet] = useState();
  const [openAccessLevelDelete, setOpenAccessLevelDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const store=useSelector((state)=>state.accessLevel)
  const [del,setDel] = useState(false);
  const [pageSize,setPageSize] = useState(0);
  const [reload,setReload]=useState(false);
  const zlink = localStorage.getItem("redirectFrom");

  const[purposeId,setPurposeId] = useState(!CommonUtil.isEmpty(location?.state?.data) ?location?.state?.data?.id : []) ;

  const [accessIds, setAccessIds] = useState(
    !CommonUtil.isEmpty(location?.state?.data)
      ? location?.state?.data?.accessLevelIds
      : []
  );

  // useEffect(() => {
  //   if (!CommonUtil.isEmptyArray(store.accessLevelIds)) {
  //     setReload(true)
  //     loadAccessLevels(page, rowsPerPage);
  //   }
  // }, []);

  // useEffect(()=>{
  //   if(!CommonUtil.isEmptyArray(store.accessLevelIds)&&reload===true){
  //     setReload(false);
  //     loadAccessLevels(page, rowsPerPage);
  //   }
  // },[store])
  

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });
  const [filter, setFilter] = useState({
    name: "",
    id: "",
  });

  useEffect(() => {
    loadAccessLevels(paging);
  }, [filter, paging, purposeId]);

  useEffect(() => {
    
    if (del === true && parseInt(pageSize) !== 1) {
      loadAccessLevels(filter,{ page: page,rowsPerPage: rowsPerPage});
      setDel(false);
    } else {
      if (del === true) {
        setPaging({ page: page-1,rowsPerPage: rowsPerPage})
        setDel(false);
      }
    }
  }, [del]);

  const loadAccessLevels = () => {
    const payload = {
      page: paging.page,
      rowsPerPage: paging.rowsPerPage,
      id: purposeId,
    };

    if (!CommonUtil.isEmptyArray(payload.id)) {
      dispatch(getVisitorAccessLevelsByPurposeId(Object.assign(payload)))
        .unwrap()
        .then((data) => {
          if ((data?.code === "CVAI0000" && data?.data)) {
            if (location?.state?.data?.id) {
              setTotalRecords(data?.data?.totalCount);
              setPage(data?.data?.currentPage);
              setRows(data?.data?.accessGroups);
              setPageSize(data?.data?.pageSize);
              setTotalPages(data?.data?.totalPages);
            } else {
              toast.error("no data");
            }
          } else {
            setRows([]);
          }
        })
        .catch((er) => {});
    } else {
      setRows([]);
    }
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  const handleDelete = (data) => {
    const delpayload = {
      id: location?.state?.data?.id,
      ids: [data[0].id]
    };
    dispatch(deleteAccessLevel(delpayload))
      .unwrap()
      .then((data) => {
        if ((data?.code === "CVAI0047")) {
          if(!CommonUtil.isEmpty(data.data.accessLevelIds)){
            setDel(false);
            setReload(true);
            dispatch(setAccessLevelIds(data?.data?.accessLevelIds)); 
            Toast("AccessLevels deleted successfully.", 'success')
            loadAccessLevels(paging);
          }
          else{
            Toast("AccessLevels deleted successfully.", 'success')
            loadAccessLevels(paging);
            dispatch(setAccessLevelIds([])); 
            setDel(true);
           
          }
         
        } else {
          Toast(data.msg, 'error')
        }
      })
      .catch((er) => { });
  }

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadAccessLevels(paging);
      return;
    }

    const filteredRows = rows.filter((test) => {
      return test.name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };

  const handleOpen = () => {
    history.push({ pathname: REACT_VISITOR_ACCESS_LEVEL_LIST, state: { state: location?.state?.data, accessIds: rows } })
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: "",
        sortable: false,
        width: 5,
        renderCell: (params) => {
          return <div></div>;
        },
      },
      {
        field: "name",
        headerName: "Access Level Name",
        flex: 1,
        width: 110,
        valueGetter: (params) => params.row.name,
        //renderCell: (rowdata) => <span> {rowdata.row.data.timeSlotName ? getAccessName(rowdata.row.data.accessLevelids) : '-'}</span>,
      },

      {
        field: "Actions",
        headerName: "Action",
        type: "actions",
        flex: 0,
        minWidth: 1,
        width: 150,
        headerAlign: "center",
        getActions: (params) => [

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDelete([params.row])
            }
            delete={()=>setDel(true)}
          />
        ],
      },
    ]);

  return (
    <>
   
      <HeaderToolbar
        src={AddAccessLevelIcon}
        title="Visitor Access Levels"
        tooltipTitle="Add Access"
        onClick={handleOpen}
        back_button={true}
      />

      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >

    {zlink ? (
        <>
        <Box item>
        <Tooltip title={"Refresh"}>
              <IconButton onClick={() => loadAccessLevels(paging)}>
                <Refresh />
              </IconButton>
            </Tooltip>
        </Box>
        </>):(<></>)}

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadAccessLevels(page, rowsPerPage)}
          />
        </Box>

      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openAccessLevelDelete && (
        <PurposeTypeAccessLevelDelete
          open={openAccessLevelDelete}
          handleClose={(data) => {
            setOpenAccessLevelDelete(data);
            handleClose(loadAccessLevels(page, rowsPerPage));
          }}
          DeleteId={idToDelet}
        />
      )},

      {open && (
        <VisitorAddAccessLevelDialog
          open={open}
          handleClose={(data) => {
            handleClose(loadAccessLevels(page, rowsPerPage));
          }}
          id={location?.state?.data}
        />
      )}
    </>
  );
}
