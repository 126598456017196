import React, { useState } from 'react';
import { Link, Grid, Box, Typography, IconButton } from '@material-ui/core';
import Button from '@mui/material/Button';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoginLayoutV2 from './LoginLayoutV2';
import { useTranslation } from 'react-i18next';
import visLogo from '../../assets/images/onboard/vis-logo.png';
import CustomTextfield from '../../components/CustomInputs/CustomTextfield';
import { createUser } from '../../slices/onboard/LoginSlice';
import signup from '../../assets/USA_Images/login_images/signup.svg';
import vis_signup from '../../assets/images/onboard/sign-up.svg';
import info from '../../assets/images/onboard/info.svg';
import CommonUtil from "../../Util/CommonUtils";
import CustomPhoneInput from '../../components/CustomInputs/CustomPhoneInput';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Checkbox from '@material-ui/core/Checkbox';
import DialogContent from '@mui/material/DialogContent';
import PrivacyPolicyScreen from './PrivacyPolicyScreen';
import UserAgreement from './UserAgreement';
import Toast from '../../components/ToastContainer/CustomToast';
import { Dialog, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as CompanyIcons } from "../../assets/CompanyIcons.svg";
import { ReactComponent as EmailIcons } from "../../assets/EmailIcons.svg";
import { ReactComponent as PassIcon } from "../../assets/passIcon.svg";
import CommonPhoneInput from "../../components/CustomInputs/CommonPhoneInput";
import { Margin } from '@mui/icons-material';
import { useEffect } from 'react';


const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle variant='h3' {...other}>
            {children}
            {onClose ? (
                <IconButton style={{ float: 'right' }}
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 5,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },

}));

const useStyles = makeStyles((theme) => ({
    form: {
        width: '70%',
        marginTop: theme.spacing(3),
    },
    content: {
        bottom: '0px !important'
    },
    dialogWidth: {
        "& .MuiDialog-paperWidthSm": {
            maxWidth: '1200px'
        },
    },
    //   '& .MuiButtonBase-root .MuiIconButton-root .PrivateSwitchBase-root-6 .MuiCheckbox-root': {
    //     color: 'red !important',
    // }
}));

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 470,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #007CE8",
        marginRight: '160px'
    }
}));

function SignUpScreenV2(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        password: '',
        showPassword: false,
        confirmPassword: '',
        confirmShowPassword: false,
        checkedA: false,
        checkedB: false,
    });
    const [countryCode, setCountryCode] = React.useState(CommonUtil.getLocationDetails('country_calling_code'));

    const [phoneNumber, setPhoneNumber] = React.useState('')
    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        country: false,
        password: false,
        showPassword: false,
        confirmPassword: false
    });

    const handleChange = (event) => {
        const name = event.target.name;
        if (name === 'phone') {
            if (!CommonUtil.isEmptyString(event.target.value) && event.target.value.match(/^[0-9 ]+$/)) {
                setValues({
                    ...values,
                    [name]: event.target.value,
                });
            } else if (CommonUtil.isEmptyString(event.target.value)) {
                setValues({
                    ...values,
                    [name]: event.target.value,
                });
            }
        } else {
            setValues({
                ...values,
                [name]: event.target.value,
            });
        }
        setError({
            ...error,
            [name]: false,
        });
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleClickConfirmShowPassword = () => {
        setValues({ ...values, confirmShowPassword: !values.confirmShowPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const user = {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        country: "+" + countryCode,
        password: values.password,
        confirmPassword: values.confirmPassword,
        verified: true

    };
    const handleSubmit = (event) => {
        event.preventDefault();

        if (CommonUtil.isEmptyString(values.firstName)) {
            setError({ ...error, firstName: true });
            Toast(t('PERSON016'), "error");
            return;
        }

        if (CommonUtil.isEmptyString(values.email)) {
            setError({ ...error, email: true });
            Toast(t('REGISTERUSER0001'), "error");
            return;
        }
        if (!CommonUtil.isValidEmail(values.email)) {
            setError({ ...error, email: true });
            Toast(t('COMP0007'), "error");
            return;
        }
        if (!CommonUtil.isEmptyString(values.phone)) {
            if (values.phone.length < 8 || values.phone.length > 15) {
                setError({ ...error, phone: true });
                Toast(t('COMPPROFSCREEN003'), "error");
                return;
            }
        }

        if (CommonUtil.isEmptyString(values.password)) {
            setError({ ...error, password: true });
            Toast(t('REGISTERUSER0002'), "error");
            return;
        }

        if (!values.password.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/)) {
            setError({ ...error, password: true });
            Toast(t('PASSWORDERROR'), "error");
            return;
        }

        if (CommonUtil.isEmptyString(values.confirmPassword)) {
            setError({ ...error, confirmPassword: true });
            Toast(t('REGISTERUSER0004'), "error");
            return;
        }
        if (!values.confirmPassword.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/)) {
            setError({ ...error, confirmPassword: true });
            Toast(t('PASSWORDERROR'), "error");
            return;
        }

        if (values.password !== values.confirmPassword) {
            setError({ ...error, confirmPassword: true });
            Toast(t('REGISTERUSER0007'), "error");
            return false;
        }

        if (!(userAgreement)) {
            Toast(t('You must agree to User Agreement before registering'), "error");
            return;
        }

        if (!(termsCondition)) {
            Toast(t('You must agree to Privacy Policy before registering'), "error");
            return;
        }

        dispatch(createUser(user))
            .unwrap()
            .then((data) => {
                if (data.code === 'UASI0001') {
                    localStorage.setItem('applicationId', 'Bearer ' + data.data.access_token);
                    history.push('/vis/user-created');
                } else {
                    // Toast((data.message), 'error');
                    onFail(data);
                }
            })
            .catch((er) => { })
    };

    const onFail = (data) => {
        switch (data.code) {
            case "UASE0020":
                Toast(("User already exist."), 'error');
                break;
            case "UASI0001":
                history.push('/vis/user-created');
                break;
            default:
                break;
        }
    };

    const [openDilog, setopenDilog] = React.useState(false);
    const [HeaderContent, setHeaderContent] = React.useState('');
    const [userAgreement, setUserAgreement] = React.useState(false);
    const [termsCondition, setTermsCondition] = React.useState(false);
    const [init, setInit] = useState({ userAgreement: true, termsCondition: true });

    const handleAgree = () => {
        if (HeaderContent === 'User Agreement') {
            setInit({ ...init, userAgreement: false });
            setUserAgreement(true);
        } else {
            setInit({ ...init, termsCondition: false });
            setTermsCondition(true);
        }
        setopenDilog(false);
    };

    const handleDisAgree = () => {
        if (HeaderContent === 'User Agreement') {
            setInit({ ...init, userAgreement: false });
            setUserAgreement(false);
        } else {
            setInit({ ...init, termsCondition: false });
            setTermsCondition(false);
        }
        setopenDilog(false);
    };

    const handlePhoneNumberChange = (value) => {
        var mobile = value
        setError({
            ...error,
            phone: ''
        })
        if (mobile.length > 25) {
            return false
        }
        else {
            setValues({
                ...values,
                phone: mobile
            })
            setPhoneNumber(value)
        }
    }

    return (
        <>
            <LoginLayoutV2 lang={true} src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? signup : vis_signup}>
                <Grid className='center_div'>
                    <Grid container alignItems='center'>
                        <Grid item className='logo-center'>
                            <img src={visLogo} alt='logo' style={{ width: '180px' }} />
                        </Grid>
                    </Grid>
                    <form className={classes.form} noValidate onSubmit={handleSubmit} autoComplete='off'>
                        <Grid item xs={12} >
                            <Typography variant='h2' align='left' style={{ color: '#464646', fontWeight: '500', marginTop: '20px' }}>
                                {t("LOGINFORM0005")}
                            </Typography>
                            <Typography variant='subtitle2' align='left' style={{ color: '#7a7b97', marginBottom: '20px', marginTop: '6px', fontSize: '12px' }}>
                                {t("LOGINPAGE001")}
                            </Typography>
                        </Grid>
                        <Grid container alignItems='center'>
                            <Grid item xs style={{ display: 'flex' }}>
                                <Grid className="company_icon">
                                    <CompanyIcons className='visibleIcons' />
                                </Grid>
                                <CustomTextfield
                                    type={'text'}
                                    error={error.firstName}
                                    name='firstName'
                                    label={t("COMMON020") + " *"}
                                    value={values.firstName}
                                    handleChange={(e) => handleChange(e)}
                                    helperText={error.firstName}
                                    validation='alphabets'
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center'>
                            <Grid item xs style={{ display: 'flex' }}>
                                <Grid className="company_icon">
                                    <CompanyIcons className='visibleIcons' />
                                </Grid>
                                <CustomTextfield
                                    type={'text'}
                                    error={error.lastName}
                                    name='lastName'
                                    label={t("COMMON021")}
                                    value={values.lastName}
                                    handleChange={(e) => handleChange(e)}
                                    helperText={error.lastName}
                                    validation='alphabets'
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center'>
                            <Grid item xs style={{ display: 'flex' }}>
                                <Grid className="company_icon">
                                    <EmailIcons className='visibleIcons' />
                                </Grid>
                                <CustomTextfield
                                    type={'text'}
                                    error={error.email}
                                    name='email'
                                    label={t("COMMON042") + " *"}
                                    value={values.email}
                                    handleChange={(e) => handleChange(e)}
                                    helperText={error.email}
                                    validation='email'
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center' style={{ position: 'relative', top: '-3%' }}  >
                            {/* <Grid item xs style={{ display: 'flex' }}> */}
                            {/* <CustomPhoneInput specialLabel={false}
                                    //  handleChange={(e) => handleChange(e)}
                                    value={values.phone}
                                    handleChange={(phone) => setValues({ ...values, phone: phone })}
                                /> */}
                            <CommonPhoneInput
                                sx={{ margin: 'none !important' }}
                                error={error.phone}
                                handleCountryCode={(data) => {
                                    setCountryCode(data.dialCode)
                                    setError({ ...error, phone: '' })
                                }}
                                countryCode={countryCode}
                                placeholder={t('COMPPROFSCREEN037')}
                                phoneNumber={phoneNumber}
                                name={'phone'}
                                handleChange={(e) => handlePhoneNumberChange(e)}

                            />
                            {/* </Grid> */}
                        </Grid>

                        <Grid container alignItems='center' style={{ display: 'flex', position: 'relative', top: '-6%' }}>
                            <Grid item xs style={{ display: 'flex' }}>
                                <Grid className="company_icon">
                                    <PassIcon className='visibleIcons' />
                                </Grid>
                                <CustomTextfield
                                    type={values.showPassword ? 'text' : 'password'}
                                    error={error.password}
                                    name='password'
                                    label={t("COMMON047") + " *"}
                                    value={values.password}
                                    handleChange={(e) => handleChange(e)}
                                    helperText={error.password}
                                    //  validation='password'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton className='visibleIcons' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                                                    {values.showPassword ? <Visibility /> : <VisibilityOff className='visibilityIcons' />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid style={{ position: 'absolute', right: '-25px' }}>
                                <HtmlTooltip title={<React.Fragment>
                                    {t("SIGNUP006")}</React.Fragment>}>
                                    <img src={info} alt='logo' className='left_img' />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center' style={{ display: 'flex', position: 'relative', top: '-6%' }}>
                            <Grid item xs style={{ display: 'flex' }}>
                                <Grid className="company_icon">
                                    <PassIcon className='visibleIcons' />
                                </Grid>
                                <CustomTextfield
                                    type={values.confirmShowPassword ? 'text' : 'password'}
                                    error={error.confirmPassword}
                                    name='confirmPassword'
                                    label={t("USER0004") + " *"}
                                    value={values.confirmPassword}
                                    handleChange={(e) => handleChange(e)}
                                    helperText={error.confirmPassword}
                                    //  validation='password'
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton className='visibleIcons' onClick={handleClickConfirmShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                                                    {values.confirmShowPassword ? <Visibility className='visibleIcons' /> : <VisibilityOff className='visibilityIcons' />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid style={{ position: 'absolute', right: '-25px' }}>
                                <HtmlTooltip title={<React.Fragment>
                                    {t("SIGNUP006")}</React.Fragment>}>
                                    <img src={info} alt='logo' className='left_img' />
                                </HtmlTooltip>
                            </Grid>
                        </Grid>
                        <Grid container style={{ position: 'relative', top: '-6%' }}>
                            <Typography>
                                <Checkbox
                                    style={{ color: process.env.REACT_APP_BG_ICON }}
                                    value={userAgreement}
                                    checked={userAgreement}
                                    onChange={(e) => {
                                        setUserAgreement(e.target.checked);
                                        setInit({ ...init, userAgreement: false });
                                    }}
                                />{' '}
                                {t("SIGNUP005") + " "}
                                <Link style={{ color: '#007CE8', textDecoration: 'none', cursor: 'pointer' }}
                                    onClick={() => setopenDilog(!openDilog) & setHeaderContent('User Agreement')}>
                                    {t("SIGNUP003")}
                                </Link>
                            </Typography>
                            <Typography>
                                <Checkbox
                                    style={{ color: process.env.REACT_APP_BG_ICON }}
                                    value={termsCondition}
                                    checked={termsCondition}
                                    onChange={(e) => {
                                        setTermsCondition(e.target.checked);
                                        setInit({ ...init, termsCondition: false });
                                    }}
                                />{' '}
                                {t("SIGNUP005") + " "}
                                <Link style={{ color: '#007CE8', textDecoration: 'none', cursor: 'pointer' }}
                                    onClick={() => setopenDilog(!openDilog) & setHeaderContent('Privacy Policy')}>
                                    {t("SIGNUP004")}
                                </Link>
                            </Typography>

                        </Grid>
                        <Grid container spacing={5} alignItems='center' style={{ position: 'relative', top: '-6%' }}>
                            <Grid item xs>
                                <Button fullWidth variant='contained' type='submit'>
                                    {t('LOGINFORM0005')}
                                </Button>
                            </Grid>
                        </Grid>

                        <BootstrapDialog
                            maxWidth={'lg'}
                            onClose={() => setopenDilog(!openDilog)}
                            open={openDilog}
                        >
                            <BootstrapDialogTitle
                                onClose={() => setopenDilog(!openDilog)}>
                                {HeaderContent}
                            </BootstrapDialogTitle>

                            {HeaderContent === 'User Agreement' ? (
                                <DialogContent dividers>
                                    <UserAgreement />
                                    <Grid
                                        style={{ flexDirection: 'row-reverse', display: 'flex', marginTop: '12px' }}
                                    >
                                        <Button sx={{ m: 2 }}
                                            variant='contained' color='secondary'
                                            onClick={() => handleDisAgree()}
                                        >
                                            {t("SIGNUP007")}
                                        </Button>
                                        <Button sx={{ m: 2 }} variant='contained'
                                            onClick={() => handleAgree()}>
                                            {t("SIGNUP008")}
                                        </Button>
                                    </Grid>
                                </DialogContent>
                            ) : (
                                <DialogContent dividers>
                                    <PrivacyPolicyScreen />
                                    <Grid style={{ flexDirection: 'row-reverse', display: 'flex', justifyContent: 'end', marginTop: '12px' }}>
                                        <Button
                                            sx={{ m: 2 }}
                                            variant='contained' color='secondary'
                                            onClick={() => handleDisAgree()}
                                        >
                                            {t("SIGNUP007")}
                                        </Button>
                                        <Button sx={{ m: 2 }}
                                            variant='contained' onClick={() => handleAgree()}>
                                            {t("SIGNUP008")}
                                        </Button>
                                    </Grid>
                                </DialogContent>
                            )}
                        </BootstrapDialog>
                    </form>
                    <Grid container alignItems='center' style={{ position: 'relative', top: '-5%' }} >
                        <Grid item xs >
                            <Box mt={2} mb={3} align='center'>
                                <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px' }}>
                                    {t('LOGINFORM0006')} <Link href='/vis/login' className='link_green'> {t('LOGINFORM0002')}</Link>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </LoginLayoutV2>
        </>
    );
}
export default withRouter(SignUpScreenV2);
