import { Tooltip, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-input-2";
import CommonUtil from "../../Util/CommonUtils";
import Toast from "../../components/ToastContainer/CustomToast";
import AutocompleteComponent from "../../components/CustomInputs/AutocompleteComponent";
import { getPurposeType } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";
import CommonPhoneInput from "../../components/CustomInputs/CommonPhoneInput";

export default function WatchlistFilterForm(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const [purposeData, setPurposeData] = useState([]);

  const [payload, setPayload] = useState({
    email: "",
    phoneNumber: "",
    phoneCountryCode: "",
    firstName: "",
    lastName: "",
    visitorCompanyName: "",
    contactEmail: "",
    contactPhoneNumber: "",
    contactPhoneNumberCountryCode: "",
    purposeTypeId: "",

  });

  const handleClose = () => {
    setPayload({
      email: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      visitorCompanyName: "",
      contactEmail: "",
      contactPhoneNumber: "",
      purposeTypeId: "",
    });
    setOpen(false);
  };

  const [phoneNumber, setPhoneNumber] = useState('')
  const [countryCode, setCountryCode] = useState('')

  const [contactPhoneNumber, setContactPhoneNumber] = useState('')
  const [contactCountryCode, setContactCountryCode] = useState('')

  const [error, setError] = useState({
    email: false,
    phoneNumber: false,
    firstName: false,
    lastName: false,
    visitorCompanyName: false,
    purposeTypeId: false,
    contactEmail: false,
    contactPhoneNumber: false,
  });

  const reset = () => {
    if (
      payload.firstName.trim() === "" &&
      payload.lastName.trim() === "" &&
      payload.email.trim() === "" &&
      payload.phoneNumber.trim() === "" &&
      payload.purposeTypeId.trim() === "" &&
      payload.contactEmail.trim() === "" &&
      payload.contactPhoneNumber.trim() === ""
    ) {
      Toast(t("COMMON027"), "error");
      return;
    }

    setPhoneNumber("")
    setCountryCode("")
    setContactPhoneNumber("")
    setContactCountryCode("")
    setPayload({
      email: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      visitorCompanyName: "",
      purposeTypeId: "",
      contactEmail: "",
      contactPhoneNumber: "",
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (
      payload.firstName.trim() === "" &&
      payload.lastName.trim() === "" &&
      payload.email.trim() === "" &&
      payload.phoneNumber.trim() === "" &&
      payload.contactEmail.trim() === "" &&
      payload.purposeTypeId.trim() === "" &&
      payload.contactPhoneNumber.trim() === ""
    ) {
      Toast(t("COMMON015"), "error");
      return;
    }

    // if (CommonUtil.isEmptyString(payload.contactEmail)) {
    //   Toast(t("EMAILVALIDATE"), "error");
    //   setError({ name: true });
    //   return false;
    // }

    // if (!CommonUtil.isValidEmails(payload.contactEmail)) {
    //   Toast(t("CORRECTEMAIL"), "error");
    //   setError({ name: true });
    //   return;
    // }

    props.applyFilter(payload);
    setPayload({
      email: "",
      phoneNumber: "",
      firstName: "",
      lastName: "",
      visitorCompanyName: "",
      contactEmail: "",
      contactPhoneNumber: "",
      purposeTypeId: "",
    });
    setPhoneNumber("")
    setContactPhoneNumber("")
    handleClose();
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadPurposeType();
  };

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI0000") {
          setPurposeData(data?.data?.purposeTypes);
        } else {
          setPurposeData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event.id,
    });
  };

  const handlePhoneNumberChange = (value) => {

    var code = "+" + countryCode
    var mobile = value
    setError({
      ...error,
      phoneNumber: ''
    })
    if (mobile.length > 25) {
      return false
    }
    else {
      setPayload({
        ...payload,
        phoneCountryCode: code,
        phoneNumber: mobile
      })
      setPhoneNumber(value)
    }

  }

  const handleConactPhoneNumberChange = (value) => {

    var code = "+" + countryCode
    var mobile = value
    setError({
      ...error,
      phoneNumber: ''
    })
    if (mobile.length > 25) {
      return false
    }
    else {
      setPayload({
        ...payload,
        contactPhoneNumberCountryCode: code,
        contactPhoneNumber: mobile
      })
      setContactPhoneNumber(value)
    }

  }

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            {t("COMMON006")}
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
          <WatchlistFilterForm />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        Header={t("COMMON006")}
        acceptText={t("COMMON024")}
        cancelText={t("COMMON025")}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={reset}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={12} sm={10.5} style={{ marginTop: "15px" }}>
            <TextField
              variant="outlined"
              label={t("COMMON020")}
              error={error.firstName}
              name="firstName"
              value={payload.firstName}
              onChange={handleChange}
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              style={{ margin: "1px" }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={10.5}>
            {/* <PhoneInput
              fullWidth
              specialLabel={t("COMMON063")}
              searchPlaceholder=""
              countryCodeEditable={true}
              enableSearch="true"
              inputStyle={{ height: "54px", width: "100%" }}
              //   isValid={error.phoneNumber}
              country={process.env.REACT_APP_ENVIRONMENT === "USA" ? "us" : ""}
              value={payload.phoneNumber}
              onChange={(phoneNumber) => {
                setError({ phoneNumber: true });
                setPayload({ ...payload, phoneNumber: phoneNumber });
              }}
            /> */}

            <CommonPhoneInput
              sx={{ margin: 'none!important' }}
              error={error.phoneNumber}
              handleCountryCode={(data) => {
                setCountryCode(data.dialCode)
                setError({ ...error, phoneNumber: '' })
              }}
              countryCode={countryCode}
              placeholder={t('COMPPROFSCREEN037')}
              phoneNumber={phoneNumber}
              name={'phone'}
              handleChange={(e) => handlePhoneNumberChange(e)}


            />


          </Grid>
          <Grid item xs={12} sm={10.5}>
            <TextField
              label={t("COMMON042")}
              name="email"
              value={payload.email}
              onChange={handleChange}
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={10.5} style={{ marginTop: "15px" }}>
            <AutocompleteComponent
              label="Purpose Type"
              name="purposeTypeId"
              error={error.purposeTypeId}
              value={payload.purposeTypeId}
              isOptionEqualToValue={(option, value) => option.name === value}
              onSelect={(e) => onselectPurposeType(e)}
              options={purposeData}
            />
          </Grid>

          <Grid item xs={12} sm={10.5}>
            <Typography
              color="#242424"
              fontSize={"18px"}
              opacity={1}
              fontWeight={550}
            >
              {t("PERSONINFO")} {""}{" "}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={10.5}>
            <TextField
              label={t("WATCHLISTTEMP002")}
              name="contactEmail"
              value={payload.contactEmail}
              onChange={handleChange}
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={10.5}>
            {/* <PhoneInput
              fullWidth
              specialLabel={t("WATCHLISTTEMP009")}
              searchPlaceholder=""
              countryCodeEditable={true}
              enableSearch="true"
              inputStyle={{ height: "54px", width: "100%" }}
              //   isValid={error.phoneNumber}
              country={process.env.REACT_APP_ENVIRONMENT === "USA" ? "us" : ""}
              value={payload.contactPhoneNumber}
              onChange={(phoneNumber) => {
                setError({ contactPhoneNumber: true });
                setPayload({ ...payload, contactPhoneNumber: phoneNumber });
              }}
            /> */}

            <CommonPhoneInput
              sx={{ margin: 'none !important' }}
              error={error.phoneNumber}
              handleCountryCode={(data) => {
                setContactCountryCode(data.dialCode)
                setError({ ...error, phoneNumber: '' })
              }}
              countryCode={contactCountryCode}
              placeholder={t('COMPPROFSCREEN037')}
              phoneNumber={contactPhoneNumber}
              name={'phone'}
              handleChange={(e) => handleConactPhoneNumberChange(e)}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}

