import api from '../config/http-common';

const listcloudAccess_Levels = (payload) => {
    return api.securedAxios().get('/api/v2.0/visitor_type/access_level' +
        '?isPageable=' + payload.isPageable + '&page=' + payload.page + '&size=' + payload.pageSize);
};

const getAccessLevel = (payload) => {
    return api.securedAxios().get('/api/v2.0/visitor-access-level'
        + '/' + payload.id + '?page=' + payload.page + '&size=' + payload.pageSize);
};

const deleteAccessLevel = (payload) => {
    return api.securedAxios().delete('/api/v2.0/visitor-access-level/' + payload);

};

const addAccessLevel = (payload) => {
    return api.securedAxios().post('/api/v2.0/visitor-access-level', payload);
};

const AccessLevelService = {
    listcloudAccess_Levels,
    getAccessLevel,
    deleteAccessLevel,
    addAccessLevel
};

export default AccessLevelService;
