import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable/DataTable";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HeaderToolbar from "../../components/HeaderToolbar";
import checklistIcon from "../../assets/checklist.svg";
import Search from "../../components/SearchTab/Search";
import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { REACT_URL_HEALTHQUESTIONARIES } from "../../actions/EndPoints";
import ChecklistIcon from "../Checklist/ChecklistIcon";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditChecklistIcon from "../Checklist/EditChecklistIcon";
import ChecklistTemplateForm from "./ChecklistTemplateForm";
import ChecklistTemplateDetails from "./ChecklistTemplateDetails";
import { listChecklistTemplates } from "../../slices/CheckListSlice/CheckListSlice";
import ChecklistFilterForm from "./ChecklistFilterForm";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";

export default function ChecklistTemplate(props) {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const zlink = localStorage.getItem("redirectFrom");

  const [roles, setRoles] = useState([]);
  const [createChecklistTemplate, setCreateChecklistTemplate] = useState(true);
  const [listChecklistTemplate, setListChecklistTemplate] = useState(true);
  const [viewChecklistTemplate, setViewChecklistTemplate] = useState(true);
  const [editChecklistTemplate, setEditChecklistTemplate] = useState(true);
  const [checklistItems, setChecklistItems] = useState(true);

  const [selectedObject, setSelectedObject] = useState({
    id: "",
    name: "",
    companyId: "",
    description: "",
  });

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    id: "",
    name: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    id: "",
    name: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);
 
  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setCreateChecklistTemplate(l.includes("CREATE_EDIT_CHECKLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setEditChecklistTemplate(l.includes("CREATE_EDIT_CHECKLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setViewChecklistTemplate(l.includes("VIEW_CV_CHECKLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setChecklistItems(l.includes("CHECKITEMS_CV_CHECKLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setListChecklistTemplate(l.includes("CHECKLIST_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("CHECKLIST_LIST")) {
          dispatch(listChecklistTemplates(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
              if ((data?.code === "CVAI0000" && data?.data)) {
                setTotalRecords(data?.data?.totalCount);
                setPage(data?.data?.curPage);
                setTotalPages(data.data.totalPages);
                setRows(data?.data?.visitors);
              } else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("CHECKLIST_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);

  const loadData = (filter, paging) => {
    dispatch(listChecklistTemplates(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "CVAI0000" && data?.data)) {
          setTotalRecords(data?.data?.totalCount);
          setPage(data?.data?.curPage);
          setTotalPages(data.data.totalPages);
          setRows(data?.data?.visitors);
        } else {
          setRows([]);
        }
      })
      .catch((er) => {});
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      name: data.name,
    });
  };

  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog);
    setSelectedObject(params);
  };

  const handleChecklistItem = (params) => {
        history.push({
        pathname: REACT_URL_HEALTHQUESTIONARIES,
        state: { data: params.row },
      });
  };


  const globalsearch = (searchedVal) => {
    if (!searchedVal) {
      loadData(filter, paging);
      return;
    }
    dispatch(listChecklistTemplates(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "CVAI0000" && data?.data)) {
          
          const filteredRows = data?.data?.visitors.filter((test) => {
            return (test.name ? test.name.toLowerCase().includes(searchedVal.toLowerCase()) : "")
                  || test.description.toLowerCase().includes(searchedVal.toLowerCase())
      
          });
          setRows(filteredRows);
        } else {
          setRows([]);
        }
      })
      .catch((er) => {});
  };

  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.name,
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
    },

    {
        field: "Actions",
        headerName: "Actions",
        type: "actions",
        flex: 1,
        minWidth: 190,
        width: 150,
        headerAlign: "center",
        getActions: (params) => [

          (<>
          {viewChecklistTemplate &&
          <GridActionsCellItem
              icon={<ChecklistTemplateDetails
              title="Checklist Template Details"
              rowData={params.row} />}
              label="View"
          />}
          </>),
  
          (<>
          {editChecklistTemplate &&
          <GridActionsCellItem
            icon={<EditChecklistIcon />}
            label="Edit"
            onClick={() => handleEditVisit(params.row)}
          />}
          </>),
  
          (<>
          {checklistItems &&
          <GridActionsCellItem
            icon={<ChecklistIcon />}
            label="Delete"
            onClick={() => handleChecklistItem(params)}
          />}
          </>),

        ],
      },
    ]);

  return (
    <>

 {!zlink ? (
  <>
  <HeaderToolbar 
     src={checklistIcon} 
     onClick={handleOpen} 
     title="Checklist Template" 
     tooltipTitle="Add Checklist Template" />
   </>):(<>

 {createChecklistTemplate ?(
  <HeaderToolbar 
     src={checklistIcon} 
     onClick={handleOpen} 
     title="Checklist" 
     tooltipTitle="Add Checklist Template" 
  />):

  (<HeaderToolbar 
     title="Checklist" 
     tooltipTitle="Add Checklist Template" 
  />)
  }
  </>)}

  {listChecklistTemplate &&
    <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <ChecklistFilterForm
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        {zlink ? (
        <>
        <Box item>
        <Tooltip title={"Refresh"}>
              <IconButton onClick={() => loadData(filter, paging)}>
                <Refresh />
              </IconButton>
            </Tooltip>
        </Box>
        </>):(<></>)}

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>

      </Box>}

    {listChecklistTemplate ? (
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
          ):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
          <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
              {t('CVROLE021')}
          </Typography>
        </div>)
    }     

      {open && (
        <ChecklistTemplateForm
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            handleClose(loadData(filter, paging));
          }}
        />
      )}

     {openEditDialog && (
        <ChecklistTemplateForm
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false);
            loadData(filter, paging);
            setSelectedObject({
              id: "",
              name: "",
              description: "",
            });
          }}
        />
      )}
     </>
  )
}




