import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CommonUtil from "../../../Util/CommonUtils";
import DialogBox from "../../../components/DialogBoxComponent/DialogBox";
import {
  addSiteType,
  updateSiteType,
} from "../../../slices/Site/SiteTypeSlice";
import Toast from "../../../components/ToastContainer/CustomToast";
import { useDispatch } from "react-redux";
import CustomTextfield from "../../../components/CustomInputs/CustomTextfield";

export default function AddSiteType(props) {
  const { open, handleClose } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    siteTypeIdOrCode: "",
    name: "",
    code: "",
    description: "",
  });

  const [error, setError] = React.useState({
    name: false,
    code: false,
    description: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const onError = (data) => {
    if (data)
      switch (data.code) {
        case "LMSE0009":
          setError({ code: true });
          Toast(data.message, "error");
          break;
        case "LMSE0010":
          setError({ name: true });
          Toast(data.message, "error");
          break;
        default:
          setError({ ...error });
      }
    Toast(data.data.error[0].message, "error");
  };

  const handleSubmit = () => {
    if (!CommonUtil.isValidName(payload.name)) {
      Toast(t("NAMEVALIDATE"), "error");
      setError({ name: true });
      return;
    }
    if (CommonUtil.isEmptyString(payload.name)) {
      Toast(t("NAME"), "error");
      setError({ name: true });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.code)) {
      Toast(t("CODE"), "error");
      setError({ code: true });
      return false;
    }

    const newTemplate = {
      id: props.selectedObject.id,
      name: payload.name,
      code: payload.code,
      description: payload.description,
    };

    if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      dispatch(addSiteType([newTemplate]))
        .unwrap()
        .then((data) => {
          if (data.code === "LMSI0005") {
            Toast(t(data.message), "success");
            handleClose();
          } else {
            onError(data);
          }
        });
    } else {
      dispatch(updateSiteType(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "LMSI0006") {
            Toast(t(data.message), "success");
            handleClose();
          } else {
            onError(data);
          }
        });
    }
  };

  useEffect(() => { }, []);

  useEffect(() => {
    if (props.selectedObject.id) {
      setPayload({
        ...payload,
        siteTypeIdOrCode: props.selectedObject.id,
        name: props.selectedObject.name,
        code: props.selectedObject.code,
        description: props.selectedObject.description,
      });
    }
  }, []);

  return (
    <>
      <DialogBox
        Header={payload.siteTypeIdOrCode ? t("STYPE014") : t("STYPE002")}
        acceptText={t('COMMON007')}
        cancelText={t('COMMON008')}
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        fullWidth={true}
        open={open}
        onClose={handleClose}
        cancel={handleClose}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={12} sm={10.5}>
            <CustomTextfield
              label={t("COMMON014")}
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
              inputProps={{ maxLength: 50 }}
              required={true}
            />
          </Grid>

          <Grid item xs={12} sm={10.5}>
            <CustomTextfield
              label={t("COMMON045")}
              error={error.code}
              name="code"
              value={payload.code}
              handleChange={(e) => handleChange(e)}
              helperText={error.code}
              inputProps={{ maxLength: 50 }}
              required={true}
            />
          </Grid>

          <Grid item xs={12} sm={10.5}>
            <CustomTextfield
              label={t("STYPE009")}
              error={error.description}
              name="description"
              value={payload.description}
              handleChange={(e) => handleChange(e)}
              helperText={error.description}
              inputProps={{ maxLength: 120 }}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
