import {
    Box,
    Grid,
    DialogActions,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useDispatch } from "react-redux";
  import CommonUtil from "../../Util/CommonUtils";
  import Toast from "../../components/ToastContainer/CustomToast";
  import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
  import { useTranslation } from "react-i18next";
  import { defaultPermissionCode } from "./DefaultPermissionCode";
  import { useHistory} from "react-router";
  import { useLocation } from "react-router-dom";
  import { getPermissionsList, getPermissionsListByroleId, updateRolesandPermission } from "../../slices/RoleandPermission/RoleandPermissionSlice";
  import RightViewLayout from "./RightViewLayout";
  import CustomizedAccordions from "./CustomizedAccordion";
  import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
  import CancelButtons from "../../components/DialogBoxComponent/CancelButtons";
import ContinueDilog from "./ContinueDilog";
import RoleDilogBox from "./RoleDilogBox";
  
  export default function UpdateRoleAndPermission(props) {

    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const [openDilog, setOpenDilog] = useState(false);
    const [error, setError] = useState({ name: "", code: "" });
    const [permissionList, setPermissionList] = useState({});
    const [clearAll, setClearAll] = useState(false);
    const [data, setData] = useState({});
    const [permissions, setPermissions] = useState(defaultPermissionCode);
    const defaultPermissions = useState(defaultPermissionCode);
    const { open, handleClose } = props;
    
    const [state, setState] = React.useState({
      id: location?.state?.state.id,
      name: location?.state?.state.name,
      code: location?.state?.state.code,
      description: location?.state?.state.description,
      scope: "CUSTOM",
      permissionId: [],
    });
   
    const handleChange = (event) => {
      const name = event.target.name;
      setState({
        ...state,
        [name]: event.target.value,
      });
      setError({
        ...error,
      [name]: "",
      });
    };

    useEffect(() => {
      dispatch(getPermissionsListByroleId(location?.state?.state?.id)).unwrap()
      .then((data) => {
        if (data?.code === "CVRP0001") {
          setData(data?.data?.rolePermissions);
        }
      });
    }, []);

    useEffect(() => {
      if (data instanceof Array && data.length > 0) {
        var newPermissions = { ...permissions };
        data.map((data) => {
          newPermissions[data.code] = true;
        });
        setPermissions({
          ...permissions,
          ...newPermissions,
        });
      } else {
        setPermissions({
          ...defaultPermissions,
        });
      }
    }, [data]);

    useEffect(() => {
      dispatch(getPermissionsList()).unwrap().then((data) => {
        if (data?.code === "CVRP0001") {
          // setPermissionList(data.data.permissions);
          setPermissionList(data.data);
        }
      });
    }, []);
  
    const handleSubmit = () => {

      if (CommonUtil.isEmpty(state.name)) {
        Toast(("Please enter User Role Name"), 'error')
        setError({ name: true });
        return;
      }
  
      if (CommonUtil.isEmpty(state.code)) {
        Toast(("Please User Role Code"), 'error')
        setError({ code: true });
        return;
      }

      var newPermissions = [];
      permissionList.map((p) => {
      if (permissions[p.code]) {
        newPermissions.push(p.id);
        }
      });

      const payload = {
        ...state,
        permissionId: newPermissions,
      };

      if (!CommonUtil.isEmpty(payload)) {
        if (newPermissions.length<=0) {
          setOpenDilog(true);
        } else {
          dispatch(updateRolesandPermission(payload)).unwrap()
            .then((data) => {
              if (data?.code === "UASI0046") {
                Toast("Role and its Permission Updated Successfully", 'success');
                history.push({ pathname: "/vis/RoleAndPermission", state  });
              } else if (data?.code === "UASW0004") {
                Toast("Role permissions Updated succesfully, but one more not updated.", 'success');
                history.push({ pathname: "/vis/RoleAndPermission", state });
              } else {
                if (data?.code === "UASE0130")
                  setError({ code: data.message });
                if (data?.code === "UASE0030")
                Toast("You dont have access to update the role.", 'error');
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      } else {
        setOpenDilog(!openDilog);
      }
    };

    const handleDailogSubmit = () => {
      dispatch(updateRolesandPermission(state)).unwrap()
        .then((data) => {
          if (data?.code === "UASI0046") {
            Toast("Role and its Permission Updated Successfully", 'success');
            history.push({ pathname: "/vis/RoleAndPermission", state: { state: data.data } });
          } else if (data?.code === "UASW0004") {
            Toast("Role permissions Updated succesfully, but one more not updated.", 'success');
            history.push({ pathname: "/vis/RoleAndPermission", state });
          } else {
            if (data?.code === "UASE0130")
              setError({ code: data.message });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    };

  const handleClearAll = () => {
      setPermissions(!clearAll);
      setState({
        id: location.state.state.id,
        name: location.state.state.name,
        code: location.state.state.code,
        description: location.state.state.description,
        scope: "CUSTOM",
        permissionId: [],
    });
      dispatch(getPermissionsListByroleId(location.state.state.id)).unwrap().then((data) => {
        if (data?.code === "CVRP0001") {
          setData(data?.data?.rolePermissions);
        }
      });
    };

    const setOpenDilogBox = (val) => {
      setOpenDilog(val);
    };

    const handleSetPermission = (v) => {
      setPermissions({
        ...permissions,
        ...v,
      });
    };
  
    return (
      <>
        <RightViewLayout title={"Edit User Role"} overflowY={"hidden"} back_button={true}>
          <Box>
            <Typography variant="h4" style={{color: '#3D4977', fontSize: '20px', fontWeight:'505'}}>
               User Role Information
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={3} style={{marginTop: "10px"}}>
               <CustomTextfield
                 type={'text'}
                 label="User Role Name"
                 error={error.name}
                 name="name"
                 value={state.name}
                 handleChange={(e) => handleChange(e)}
                 helperText={error.name}
                 required={true}
                 inputProps={{ maxLength: 50 }}
                 disabled
               />
            </Grid>

            <Grid item xs={3} style={{marginTop: "10px"}}>
               <CustomTextfield
                 type={'text'}
                 label="User Role Code"
                 error={error.code}
                 name="code"
                 value={state.code}
                 handleChange={(e) => handleChange(e)}
                 helperText={error.code}
                 inputProps={{ maxLength: 50 }}
                 required={true}
                 disabled
               />
             </Grid>
            </Grid>

            <Box item paddingBottom={2} paddingTop={2}>
              <Typography variant="h4" style={{color: '#3D4977', fontSize: '20px', fontWeight:'500'}}>
                 Permission for this Role
              </Typography>
              <Typography style={{color: '#3D4977', marginTop: "10px", fontSize: '15px', fontWeight:'400'}}><b>Note:</b> New Roles can be created in Zlink - Organization</Typography>
  
              <CustomizedAccordions
                 permissions={permissions}
                 setPermissions={(v) => handleSetPermission(v)}
                 disabled={false}
              />
            </Box>

            <DialogActions style={{justifyContent:"flex-end"}}>
            <SubmitButtons variant='contained' onClick={() => handleSubmit()}>
               <Typography variant="body1" style={{ textTransform: "none" }}>
                  Submit
               </Typography>
            </SubmitButtons>
            <CancelButtons variant='contained' color="secondary" onClick={() => handleClearAll()}>
               <Typography variant="body1" style={{ textTransform: "none" }}>
                  Clear
                </Typography>
            </CancelButtons>
            </DialogActions>

          <RoleDilogBox
                customWidth={"0px"}
                defalutWidth
                open={openDilog}
                onClose={() => setOpenDilog(!openDilog)}
                title={"Do you want to create the Role ?"}
                children={
            <ContinueDilog
                openDilog={openDilog}
                setOpenDilog={setOpenDilogBox}
                handleSubmit={() => handleDailogSubmit()}
            />
          }
        />
    
    </RightViewLayout>
      </> 
    );
  }
  