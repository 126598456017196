import { Grid, Checkbox, TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState, useEffect } from "react";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import CommonUtil from "../../Util/CommonUtils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import Toast from "../../components/ToastContainer/CustomToast";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CustomDateTimePicker from "../../components/CustomInputs/CustomDateTimePicker";
import { getPurposeType } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";
import { acceptApprovalVisit } from "../../slices/Invitations/InvitationsSlice";
import moment from "moment";
import { getVisitorAccessLevels } from "../../slices/VisitorAccessLevels/VisitorAccessLevelSlice";


export default function ApprovalPageDailogSelfRegistration(props) {
  const { open, handleClose, selectedObject } = props;
  const { t } = useTranslation();
  const [selectdAccessLevel, setSelectdAccessLevel] = useState([]);
  const dispatch = useDispatch();
  const [purposeData, setPurposeData] = useState([]);
  var minutes = props.selfIntervalTime === '' ? 30 : Number(props.selfIntervalTime);
  const [selfIntervalTime, setSelfIntervalTime] = useState(minutes);

  const zlink = localStorage.getItem("redirectFrom");

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();

  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T" +
    (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
    ":" +
    (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());

  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";

  const [payload, setPayload] = React.useState({
    hostId: "",
    purposeTypeId: selectedObject?.purposeType,
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    accessLevel: [],
    approvalReason: "",
    siteId: "",
  });

  const [error, setError] = React.useState({
    approvalReason: false,
    purposeTypeId: false,
    scheduledStartDate: false,
    approvalReason: false,
    siteId: false,
  });

  useEffect(() => {
    loadPurposeType();
    loadAcceLevelList();
  }, []);

  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI0000") {
          setPurposeData(data?.data?.purposeTypes);
        } else {
          setPurposeData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadAcceLevelList = () => {
    const dto = {
      page: 0,
      rowsPerPage: 1000,
      isPageable: false,
    };
    dispatch(getVisitorAccessLevels(dto))
      .unwrap()
      .then((data) => {
        if (data?.code === "DMSI0000") {
          setSelectdAccessLevel(data?.data?.accessGroups);
        } else {
          setSelectdAccessLevel([]);
        }
      });
  };

  const handleSumbit = () => {

    if (CommonUtil.isEmpty(payload.purposeTypeId)) {
      Toast(("Please enter purpose type"), 'error')
      setError({ purposeTypeId: true });
      return false;
    }

    if (
      new Date(payload.scheduledStartDate) < new Date() &&
      new Date(payload.scheduledStartDate) < new Date(payload.scheduledEndDate)
    ) {
      var dt = new Date();
      var addMinutes = new Date().setMinutes(dt.getMinutes());
      var currentDate = CommonUtil.formatToUtc(addMinutes);
      const dto = {
        approvalReason: payload.approvalReason,
        purposeTypeId: payload.purposeTypeId.id,
        hostId: payload.hostId,
        siteId: payload.siteId.id,
        scheduledStartDate: currentDate,
        scheduledEndDate: CommonUtil.formatToUtc(payload.scheduledEndDate),
        accessLevel: payload.accessLevel,
        visitorId: selectedObject.id,
      };
      dispatch(acceptApprovalVisit(dto))
        .unwrap()
        .then((data) => {
          if (data?.code === "CVAI1028") {
            Toast("Visit approved successfully", "success");
            props.approve();
            handleClose();
          } else {
            Toast(data.message, "error");
          }
        });
    }
  };
  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    });
  };

  const onselectAccessLevel = (event) => {
    setPayload({
      ...payload,
      accessLevel: event,
    });
  };


  return (
    <>
      <DialogBox
        Header="Approve"
        acceptText="Submit"
        cancelText="Cancel"
        PaperProps={{
          style: {
            borderRadius: "20px !important",
            minWidth: "50vw",
          },
        }}
        open={open}
        onSubmit={handleSumbit}
        onClose={() => handleClose(false)}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "20px" }}
          spacing={2}
        >
          <Grid item lg={6} sm={12}>
            <CustomTextfield
              error={error.firstName}
              name="firstName"
              label="First Name"
              value={selectedObject.visitor.firstName}
              handleChange={(e) => handleChange(e)}
              helperText={error.firstName}
              validation="alpha-numeric"
              required={true}
              disabled
            />
          </Grid>

          <Grid item lg={6} sm={12}>
            <CustomTextfield
              label="Last Name"
              value={selectedObject.visitor.lastName}
              error={error.lastName}
              name="lastName"
              handleChange={(e) => handleChange(e)}
              helperText={error.lastName}
              validation="alpha-numeric"
              disabled
            />
          </Grid>

          {!zlink ? (
            <>
              <Grid item lg={6} sm={12} style={{ marginTop: "5px"}}>
                <Autocomplete
                  limitTags={2}
                  multiple
                  error={error.accessLevel}
                  options={selectdAccessLevel}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      var ids = newValue.map((newValue) => { return newValue.id });
                      onselectAccessLevel(ids);
                    } else {
                      onselectAccessLevel("");
                    }
                    setError({ accessLevel: false });
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label="Access Levels" value={payload.accessLevel}/>
                  )}
                />
              </Grid>
            </>
          ):(
            <></>
          )}  


          <Grid item lg={6} xs={12} style={{ margin: "6px 0px" }}>
            <CustomDateTimePicker
              onChange={(scheduledStartDate) =>
                setPayload({
                  ...payload,
                  scheduledStartDate: scheduledStartDate,
                  // scheduledEndDate: CommonUtil.formatToUtc(moment(scheduledStartDate).add(selfIntervalTime, 'minutes'))
                })
              }
              // value={payload.scheduledStartDate}
              value={moment(CommonUtil.getLocalDateTimeInYearFormat(selectedObject?.scheduledStartDate))}
              label={"Invite Start Date *"}
              disabled
            />
          </Grid>

        <Grid item lg={6} xs={12} style={{ margin: "5px 0px" }}>
            <CustomDateTimePicker
              onChange={(scheduledEndDate) =>
                setPayload({
                  ...payload,
                  scheduledEndDate: scheduledEndDate,
                })
              }
              // value={payload.scheduledEndDate}
              value={moment(CommonUtil.getLocalDateTimeInYearFormat(selectedObject?.scheduledEndDate))}
              // minDateTime={payload.scheduledStartDate}
              label="Invite End Date *"
              disabled
            />
          </Grid>

          {zlink ? (
            <>
          <Grid item lg={6} sm={12} style={{marginTop: "5px"}}>
                <Autocomplete
                  style={{ width: '100%' }}
                  noOptionsText={'No Options found'}
                  name='purposeTypeId'
                  value={payload.purposeTypeId}
                  options={CommonUtil.isEmptyArray(purposeData) ? [] : purposeData}
                  getOptionLabel={(option) => option.name ? option.name : ''}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                      onselectPurposeType(newValue);
                    } else {
                      onselectPurposeType('');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name='purposeTypeId' value={payload.purposeTypeId} error={error.purposeTypeId} variant='outlined' fullWidth label={'Purpose Type *'} />
                  )}
                />
              </Grid>
              </>
                ):(
                  <>
            <Grid item lg={6} sm={12} style={{marginTop: "8px"}}>
                <Autocomplete
                  style={{ width: '100%' }}
                  noOptionsText={'No Options found'}
                  name='purposeTypeId'
                  value={payload.purposeTypeId}
                  options={CommonUtil.isEmptyArray(purposeData) ? [] : purposeData}
                  getOptionLabel={(option) => option.name ? option.name : ''}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                      onselectPurposeType(newValue);
                    } else {
                      onselectPurposeType('');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} name='purposeTypeId' value={payload.purposeTypeId} error={error.purposeTypeId} variant='outlined' fullWidth label={'Purpose Type *'} />
                  )}
                />
              </Grid>
                  
                  </>
                )}  

          
        {zlink ? (
            <>
          <Grid item lg={6} sm={12} style={{marginTop: "-10px"}}>
            <CustomTextfield
              error={error.remarks}
              name="remarks"
              label="Remarks"
              value={selectedObject.remarks}
              handleChange={(e) => handleChange(e)}
              helperText={error.remarks}
              required={true}
              disabled
            />
          </Grid>
          </>
                ):(
                  <>

          <Grid item lg={12} sm={12} style={{marginTop: "-10px"}}>
            <CustomTextfield
              error={error.remarks}
              name="remarks"
              label="Remarks"
              value={selectedObject.remarks}
              handleChange={(e) => handleChange(e)}
              helperText={error.remarks}
              required={true}
              disabled
            />
          </Grid>
          </>
          )}  

        </Grid>
      </DialogBox>
    </>
  );
}
