import React, { useEffect, useState } from "react";
import CommonUtil from "../../Util/CommonUtils";
import Search from "../../components/SearchTab/Search";
import { Box, Link, Tooltip, IconButton } from "@mui/material";
import DataTable from "../../components/DataTable/DataTable";
import { Avatar, Grid } from "@material-ui/core";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useDispatch } from "react-redux";
import ApproveIcon from "./ApproveIcon";
import RejectIcon from "./RejectIcon";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import { useTranslation } from "react-i18next";
import ApprovalPageDailogSelfRegistration from "../Approval/ApprovalPageDailogSelfRegistration";
import SelfRegistrationApprovalView from "./SelfRegistrationApprovalView";
import moment from "moment";
import SimpleDialogDemo from "./ImageDailogBox";
import RejectRegistartionInvitationDialog from "./RejectRegistartionInvitationDialog";
import { getAllVisitors } from "../../slices/Approvals/selfRegistarationApprovalSlice";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import InvitationFilterForm from "../../components/InvitationFilterForm";

function SelfRegistrationApprovals(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [clear, setClear] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [selectedObject, setSelectedObject] = useState([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [rejectVisitId, setRejectVisitId] = useState();
  const [openRejectInvitation, setOpenRejectInvitation] = useState(false);
  const [del, setDel] = useState(false);
  const [pageSize, setPageSize] = useState(0);
  const [selfIntervalTime, setSelfIntervalTime] = useState('');
  const zlink = localStorage.getItem("redirectFrom");

  const status = ["CONFIRMED"];

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [filter, setFilter] = React.useState({
    status: ["CONFIRMED"],
    visitorTypeId: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: ["SELF_REGISTARTION"],
    isPagable: true,
  });

  const defaultFilter = {
    status: ["CONFIRMED"],
    visitorTypeId: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: ["SELF_REGISTARTION"],
    isPagable: true,
  };

  const applyFilter = (filterData) => {
    setClear(true);
    const query = {
      siteId: filterData.siteId,
      visitorTypeId: filterData.visitorTypeId,
      visitMode: filterData.visitMode,
      scheduledStartDate: CommonUtil.formatToUtc(filterData.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(filterData.scheduledEndDate),
      status: filterData.status,
      purposeTypeId: filterData.purposeTypeId
    };
    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setClear(false);
    setFilter({
      ...defaultFilter,
    });
  };
  const handleChange = (newPage, size) => {
    setRowsPerPage(size);
    setPage(newPage);
    loadData(newPage, size);
  };

  useEffect(() => {
    loadData(1, rowsPerPage);
  }, [filter]);

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: filter,
      page: page,
      rowsPerPage: rowsPerPage,
    };
    dispatch(getAllVisitors(payload))
      .unwrap()
      .then((data) => {
        console.info(data);
        if ((data?.code === "CVAI0000" && data?.data)) {
          setTotalRecords(data?.data?.totalCount);
          setRows(data?.data?.visits);
          setPage(data?.data?.curPage);
          setPageSize(data?.data?.pageSize);
          setTotalPages(data?.data?.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };


  // useEffect(() => {
  //   dispatch(getListSettings(''))
  //     .unwrap()
  //     .then((data) => {
  //       if ((data.code === "CVAI0000")) {
  //         setSelfIntervalTime(data.data.selfIntervalTime);
  //       }
  //     })
  // }, []);

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(page, rowsPerPage);
      setDel(false);
    } else {
      if (del === true) {
        loadData(page - 1, rowsPerPage)
        setDel(false);
      }
    }
  }, [del]);

  const handleRejectVisit = (params) => {
    setOpenRejectInvitation(!openRejectInvitation);
    setRejectVisitId(params.id);
  };

  const handleApprove = (params) => {
    setSelectedObject(params.row)
    setOpenApproveDialog(!openApproveDialog);
  };

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(page, rowsPerPage);
      return;
    }


    const payload = {
      filter: filter,
      page: page,
      rowsPerPage: rowsPerPage,
    };
    dispatch(getAllVisitors(payload))
      .unwrap()
      .then((data) => {
        console.info(data);
        if ((data?.code === "CVAI0000" && data?.data)) {
        
          const filteredRows = data?.data?.visits.filter((test) => {
            return (
              (test.visitor.firstName ? test.visitor.firstName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.visitor.lastName ? test.visitor.lastName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.visitor.phone ? test.visitor.phone.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.visitor.email ? test.visitor.email.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.visitor.scheduledStartDate ? test.visitor.scheduledStartDate.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
              `${test.visitor.firstName} ${test.visitor.lastName}`.toLowerCase().includes(searchedVal.toLowerCase())
            );
          });
          setRows(filteredRows);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
   
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...';
    } else {
      return text;
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        >{params.row.visitor.firstName == null ? "" : params.row.visitor.firstName.charAt(0)}</Avatar>
      }
      child={
        <Avatar
          style={{
            width: "300px",
            height: "300px",
            border: "12px solid #FFFF",
            fontSize: "100px",
            borderRadius: "1px",
            cursor: 'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        >{params.row.visitor.firstName == null ? "" : params.row.visitor.firstName.charAt(0)}</Avatar>
      }
    />
  );

  const columns = React.useMemo(() => [
    {
      field: "visitor.profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params);
      },
    },
    {
      field: "visitor.firstName",
      headerName: t("COMMON014"),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName),
      renderCell: (params) => (
        <Tooltip title={` ${params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName)}`}>
          <span>{handleTextOverflow(params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName))}</span>
        </Tooltip>
      ),
    },
    {
      field: "visitor.phone",
      headerName: t("COMMON063"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.phone,
      renderCell: (params) => (
        <Tooltip title={` ${ params.row.visitor.phone}`}>
           <span>{handleTextOverflow(params.row.visitor.phone)}</span>
           </Tooltip>
            ),
    },
    {
      field: "visitor.email",
      headerName: t("PERSON005"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.visitor.email}`}>
          <span>{handleTextOverflow(params.row.visitor.email)}</span>
        </Tooltip>
      ),
    },

    {
      field: "scheduledStartDate",
      headerName: t("INVITEDATELBL"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        moment(CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)).format(
          "DD-MM-YY HH:mm"
        ),
    },

    {
      field: "status",
      headerName: t("COMMON001"),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />;
      },
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<SelfRegistrationApprovalView rowData={params.row} />}
          label="View"
        />,

        <GridActionsCellItem
          icon={<ApproveIcon />}
          label="Approve"
          onClick={() => handleApprove(params)}
        />,

        <GridActionsCellItem
          icon={<RejectIcon />}
          label="Reject"
          onClick={() => handleRejectVisit(params)}
        />,
      ],
    },
  ]);

  return (
    <>
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <InvitationFilterForm
            rowsPerPage={rowsPerPage}
            selectedObject={filter.visitMode}
            status={status}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>

        {zlink ? (
          <>
            <Box item>
              <Tooltip title={"Refresh"}>
                <IconButton onClick={() => loadData(1, rowsPerPage)}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Box>
          </>) : (<></>)}

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(page, rowsPerPage)}
          />
        </Box>
      </Box>

      <Grid item xs={12}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </Grid>
      {openApproveDialog && (
        <ApprovalPageDailogSelfRegistration
          selfIntervalTime={selfIntervalTime}
          selectedObject={selectedObject}
          open={openApproveDialog}
          handleClose={(data) => {
            setOpenApproveDialog(data);
            setSelectedObject([]);
          }}
          approve={() => setDel(true)}
          onclose={(data) => {
          }}
        />
      )}

      {openRejectInvitation && (
        <RejectRegistartionInvitationDialog
          open={openRejectInvitation}
          handleClose={(data) => {
            setOpenRejectInvitation(data);
          }}
          disable={() => setDel(true)}
          RejectRegisterId={rejectVisitId}
        />
      )}


    </>
  );
}

export default SelfRegistrationApprovals;
