import React from "react";
import { Tooltip, Box, Typography, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';

const HeaderToolbar = (props) => {
    let history = useHistory();
    return (
        <>
            <Box
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Grid style={{display:'flex', cursor: 'pointer'}}>
                    {props.back_button ?
                        (
                        <Grid item onClick={history.goBack}>
                            <Grid style={{ cursor: 'pointer', marginTop:'20px' }} >
                              <Tooltip title={'Back'}>
                                 <ArrowBackIosRoundedIcon sx={{ color: '#9AA1B9'}} fontSize="small"/>
                               </Tooltip>
                            </Grid>
                        </Grid>)
                        : 
                        (
                        <></>
                        )
                    }
                     <Box sx={{m:2}}><Typography variant="h3">{props.title}</Typography></Box>
                </Grid>
                {props.src ?
                    <Box
                        style={{
                            textAlign: "-webkit-right",
                            padding: '10px',
                            backgroundColor: process.env.REACT_APP_BG_ICON,
                            borderRadius: "50%",
                            cursor:'pointer'
                        }}
                    >
                        <Tooltip title={props.tooltipTitle}>
                            <img
                                alt="..."
                                src={props.src}
                                onClick={props.onClick}
                                style={{ width: '22px'}}
                            />
                        </Tooltip>
                    </Box>
                    : <></>}
            </Box>
        </>
    );
};
export default HeaderToolbar;
