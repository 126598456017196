const timeZoneData = [
    {
      value: '-12%3A00',
      name: '(GMT-12:00) International Date Line West',
    },
    {
      value: '-11%3A00',
      name: '(UTC-11)Coordinated Universal Time-11',
    },
    {
      value: '-10%3A00',
      name: '(UTC-10)Hawaii',
    },
    {
      value: '-09%3A00',
      name: '(UTC-9)Alaska',
    },
    {
      value: '-08%3A00',
      name: '(UTC-8)Pacific time (American and Canada) Baja California',
    },
    {
      value: '-07%3A00',
      name: '(UTC-7)La Paz, The mountain time (American and Canada), Arizona',
    },
    {
      value: '-06%3A00',
      name: '(UTC-6)Saskatchewan, Central time, Central America',
    },
    {
      value: '-05%3A00',
      name: '(UTC-5)Bogota, Lima, Quito, Rio Branco, Eastern time, Indiana(East)',
    },
    {
      value: '-04%3A30',
      name: '(UTC-4:30)Caracas',
    },
    {
      value: '-04%3A00',
      name: '(UTC-4)Atlantic time, Cuiaba, Georgetown, La Paz, Santiago',
    },
    {
      value: '-03%3A30',
      name: '(UTC-3:30)Newfoundland',
    },
    {
      value: '-03%3A00',
      name: '(UTC-3)Brasilia, Buenos Aires, Greenland, Cayenne',
    },
    {
      value: '-02%3A00',
      name: '(UTC-2)The International Date Line West-02',
    },
    {
      value: '-01%3A00',
      name: '(UTC-1)Cape Verde Islands, Azores',
    },
    {
      value: '-00%3A00',
      name: '(UTC)Dublin, Edinburgh, Lisbon, London, The International Date Line West',
    },
    {
      value: '%2001%3A00',
      name: '(UTC+1)Amsterdam, Brussels, Sarajevo',
    },
    {
      value: '%2002%3A00',
      name: '(UTC+2)Beirut, Damascus, Eastern Europe, Cairo,Athens, Jerusalem',
    },
    {
      value: '%2003%3A00',
      name: '(UTC+3)Baghdad, Kuwait, Moscow, St Petersburg,Nairobi',
    },
    {
      value: '%2003%3A30',
      name: '(UTC+3:30)Teheran or Tehran',
    },
    {
      value: '%2004%3A00',
      name: '(UTC+4)Abu Dhabi, Yerevan, Baku, Port Louis, Samarra',
    },
    {
      value: '%2004%3A30',
      name: '(UTC+4:30)Kabul',
    },
    {
      value: '%2004%3A30',
      name: '(UTC+5)Ashgabat, Islamabad, Karachi',
    },
    {
      value: '%2005%3A30',
      name: '(UTC+5:30)Chennai, Calcutta Mumbai, New Delhi',
    },
    {
      value: '%2005%3A45',
      name: '(UTC+5:45)Kathmandu',
    },
    {
      value: '%2006%3A00',
      name: '(UTC+6)Astana, Dhaka, Novosibirsk',
    },
    {
      value: '%2006%3A30',
      name: '(UTC+6:30)Yangon',
    },
    {
      value: '%2007%3A00',
      name: '(UTC+7)Bangkok, Hanoi, Jakarta',
    },
    {
      value: '%2008%3A00',
      name: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi,Kuala Lumpur, Singapore',
    },
    {
      value: '%2009%3A00',
      name: '(UTC+9)Osaka, Tokyo, Seoul, Yakutsk',
    },
    {
      value: '%2009%3A30',
      name: '(UTC+9:30)Adelaide, Darwin',
    },
    {
      value: '%2010%3A00',
      name: '(UTC+10)Brisbane, Vladivostok, Guam, Canberra',
    },
    {
      value: '%2011%3A00',
      name: '(UTC+11)Solomon Islands, New Caledonia',
    },
    {
      value: '%2012%3A00',
      name: '(UTC+12)Anadyr, Oakland, Wellington, Fiji',
    },
    {
      value: '%2013%3A00',
      name: "(UTC+13)Nuku'alofa, The Samoa Islands",
    },
    {
      value: '%2014%3A00',
      name: '(UTC+14)Christmas Island',
    },
  ];
  
  export const timeZoneList = timeZoneData;
  