import React from "react";
import { Tooltip, Grid } from "@mui/material";

const CancelIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <>
      <Tooltip title="Cancel">
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg
              id="Group_5910"
              data-name="Group 5910"
              xmlns="http://www.w3.org/2000/svg"
              width="16.91"
              height="20"
              viewBox="0 0 16.91 20"
            >
              <path
                id="Path_6180"
                data-name="Path 6180"
                d="M12.976,40.848H6.11A1.111,1.111,0,0,1,5,39.738V35.478A5.483,5.483,0,0,1,10.478,30h2.444a5.379,5.379,0,0,1,.941.083,6.379,6.379,0,0,0-.887,10.765Z"
                transform="translate(-5 -20.848)"
                fill="#3d4977"
              />
              <rect
                id="Rectangle_6294"
                data-name="Rectangle 6294"
                width="6.23"
                height="7.12"
                rx="3.115"
                transform="translate(3.56)"
                fill="#3d4977"
              />
              <circle
                id="Ellipse_2949"
                data-name="Ellipse 2949"
                cx="5.25"
                cy="5.25"
                r="5.25"
                transform="translate(6.41 9.5)"
                fill="#e3393c"
              />
              <path
                id="Path_6181"
                data-name="Path 6181"
                d="M6.892,6.892a.459.459,0,0,0,0,.649L8.515,9.165,6.892,10.788a.459.459,0,1,0,.649.649L9.165,9.814l1.623,1.623a.459.459,0,0,0,.649-.649L9.814,9.165l1.623-1.623a.459.459,0,0,0-.649-.649L9.165,8.515,7.541,6.892A.459.459,0,0,0,6.892,6.892Z"
                transform="translate(2.496 5.585)"
                fill="#fff"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16.91"
              height="20"
              viewBox="0 0 16.91 20"
            >
              <g
                id="Group_5910"
                data-name="Group 5910"
                transform="translate(-5 -0.472)"
              >
                <path
                  id="Path_6180"
                  data-name="Path 6180"
                  d="M12.976,40.848H6.11A1.111,1.111,0,0,1,5,39.738V35.478A5.483,5.483,0,0,1,10.478,30h2.444a5.379,5.379,0,0,1,.941.083,6.379,6.379,0,0,0-.887,10.765Z"
                  transform="translate(0 -20.376)"
                  fill="rgba(99,115,129,0.4)"
                />
                <rect
                  id="Rectangle_6294"
                  data-name="Rectangle 6294"
                  width="6.23"
                  height="7.12"
                  rx="3.115"
                  transform="translate(8.56 0.472)"
                  fill="rgba(99,115,129,0.4)"
                />
                <circle
                  id="Ellipse_2949"
                  data-name="Ellipse 2949"
                  cx="5.25"
                  cy="5.25"
                  r="5.25"
                  transform="translate(11.41 9.971)"
                  fill="rgba(227,57,60,0.4)"
                />
                <path
                  id="Path_6181"
                  data-name="Path 6181"
                  d="M6.892,6.892a.459.459,0,0,0,0,.649L8.515,9.165,6.892,10.788a.459.459,0,1,0,.649.649L9.165,9.814l1.623,1.623a.459.459,0,0,0,.649-.649L9.814,9.165l1.623-1.623a.459.459,0,0,0-.649-.649L9.165,8.515,7.541,6.892A.459.459,0,0,0,6.892,6.892Z"
                  transform="translate(7.496 6.057)"
                  fill="#fff"
                />
              </g>
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default CancelIcon;
