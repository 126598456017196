import React, { useEffect, useState } from 'react';
import { Box, InputLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import CommonUtil from '../../components/Util/CommonUtils';
import CommonUtil from '../../Util/CommonUtils';

export default function CustomNewInput(props) {
  const [helperText, setHelperText] = useState(props.helperText);
  const [error, setError] = useState(props.error);
  const [value, setValue] = useState(props.value);
  const { t } = useTranslation();

  useEffect(() => {
    setError(props.error);
    setHelperText(props.helperText);
  }, [props.error]);

  const handleChange = (e) => {
    var val = e.target.value;
    if (val.trim() === '') {
      val = val.trim();
    }
    if (e.target.value.length > 0 && val.length == 0) {
      return;
    }
    if (val.length > 2) {
      if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
        return;
      }
      if (val[0] === ' ') {
        return;
      }
    }
    if (props.regex === 'none' && props.validation) {
      switch (props.validation) {
        case 'alpha-numeric':
          handleAlphaNumeric(e);
          break;
        case 'numeric':
          handleNumeric(e);
          break;
        case 'email':
          handleEmail(e);
          break;
        case 'password':
          handlePassword(e);
          break;
        case 'code':
          handleCode(e);
          break;
        case 'mobile':
          handleMobile(e);
          break;
        case 'postcode':
          handlePostCode(e);
          break;
        case 'alpha-numeric-underscore':
          handleCodeUnderscore(e);
          break;
        case 'alpha-numeric-space':
          handleAlphaNumericSpace(e);
          break;
        default:
          props.handleChange(e);
      }
    }
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleAlphaNumeric = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('COMPPROFSCREEN029'));
    }
  };

  const handleCodeUnderscore = (e) => {
    if (CommonUtil.isAlphaNumericUnderscore(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('COMPPROFSCREEN030'));
    }
  };

  const handleAlphaNumericSpace = (e) => {
    if (CommonUtil.isAlphaNumericSpace(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('COMPPROFSCREEN031'));
    }
  };

  const handleCode = (e) => {
    if (CommonUtil.isAlphaNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('COMPPROFSCREEN032'));
    }
  };

  const handlePostCode = (e) => {
    if (CommonUtil.isValidCode(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('COMPPROFSCREEN033'));
    }
  };

  const handleNumeric = (e) => {
    if (CommonUtil.isValidNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('COMPPROFSCREEN034'));
    }
  };

  const handleEmail = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidEmail(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('COMPPROFSCREEN035'));
    }
  };
  const handleMobile = (e) => {
    if (CommonUtil.isValidPasitiveNumeric(e.target.value)) {
      props.handleChange(e);
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('COMPPROFSCREEN028'));
    }
  };

  const handlePassword = (e) => {
    props.handleChange(e);
    if (CommonUtil.isValidPassword(e.target.value)) {
      setHelperText(props.helperText);
      setError(false);
    } else {
      setError(true);
      setHelperText(t('COMPPROFSCREEN036'));
    }
  };
  const preventHandleBlur = ['email', 'password', 'confirmPassword', 'oldPassword', 'newPassword']
  const handleBlur = () => {
    if (!preventHandleBlur.includes(props.name)) {
      setHelperText('');
      setError(false);
    }
  }

  return (
    <Box variant='standard' style={{ width: '100%' }} pb={1}>
      <InputLabel shrink htmlFor='bootstrap-input' style={{ marginLeft: props.labelMarginLeft, fontSize: '19px' }}>
        {props.label} {props.required && <span style={{ color: 'red', marginLeft: '-4px' }}>*</span>}
      </InputLabel>
      <TextField
       disabled={props.disabled}
        inputProps={{ style: { background: 'white !important' } }}
        autoComplete='off'
        {...props}
        onBlur={() => handleBlur()}
        label={''}
        placeholder={props.placeholder}
        value={props.value}
        helperText={helperText}
        error={error}
        onChange={(e) => handleChange(e)}
        style={{ background: 'transparent', marginLeft: '0px' }}
      />
    </Box>
  );
}

CustomNewInput.defaultProps = {
  validation: 'none',
  regex: 'none',
};

CustomNewInput.propType = {
  validation: PropTypes.oneOf(['alpha-numeric', 'alpha-numeric-space', 'alpha-numeric-underscore', 'numeric', 'email', 'password', 'code']),
};
