import React, { useEffect, useState } from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import iot from '../../assets/images/onboard/Miot-Logo.svg';
import iot from '../../assets/MIOTLogo/miotlogo.png'
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import CommonUtil from '../../components/Util/CommonUtils';
import LoginLayoutV2 from './LoginLayoutV2';
import jwt_decode from 'jwt-decode';
import { useTranslation } from 'react-i18next';
import visLogo from '../../assets/images/onboard/vis-logo.png';
import CustomTextfield from '../../components/CustomInputs/CustomTextfield';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { authorizeUser } from '../../slices/onboard/LoginSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Toast from '../../components/ToastContainer/CustomToast';
import { ReactComponent as UserIcon } from "../../assets/UserIcon.svg";
import { ReactComponent as PassIcon } from "../../assets/passIcon.svg";
import { logout } from '../../slices/User';
import Button from '@mui/material/Button';
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints';


const useStyles = makeStyles((theme) => ({
    form: {
        width: '70%',
        marginTop: theme.spacing(3),
    },
}));

function LoginFormV2(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [locationKeys, setLocationKeys] = useState([]);
    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false,
    });

    const [error, setError] = useState({
        email: false,
        password: false,
    });
    const store = useSelector((state) => state);

    const query = new URLSearchParams(props.location.search);

    useEffect(() => {
        return () => {
          if (history.action === "POP") {
            history.replace('/vis/login');
          }
        };   
      }, [history]);


    const handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setValues({
            ...values,
            [name]: value,
        });
        setError({
            [name]: false,
        });
    };

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const user = {
            userName: values.email,
            password: values.password,
        };

        dispatch(authorizeUser(user))
            .unwrap()
            .then((data) => {
                if (data.code === 'CVAI0000') {
                    localStorage.setItem(process.env.REACT_APP_TEMP_TOKEN, 'Bearer ' + data.data.access_token);
                    const decoded = jwt_decode(data.data.access_token);
                    localStorage.setItem('applicationId', decoded.client_id);
                    history.push('/vis/verify-company');
                }
                else{
                //    Toast((data.message), 'error');
                   onFail(data);
                }
            })

            .catch((er) => { })
    };

    const onFail = (data) => {
        switch (data.code) {
            case "UASE0054":
                Toast(("Incorrect Password"), 'error');
                break;
            case "UASE0003":
                Toast(("User does not exist"), "error");    
            default:
                break;
        }
    };






    return !CommonUtil.isEmptyString(query.get('code')) ? (
        <></>
    ) : (
        <>
            <LoginLayoutV2 lang={true}>
                <Grid className='center_div'>
                    <Grid container alignItems='center'>
                        <Grid item className='logo-center'>
                            <img src={visLogo} alt='logo' style={{ width: '180px' }} />
                        </Grid>
                    </Grid>
                    <form className={classes.form} noValidate onSubmit={handleSubmit} autoComplete='off'>
                        <Grid item xs={12} >
                            <Typography variant='h2' align='left' style={{ color: '#464646', fontWeight: '500', marginTop: '20px' }}>
                                {t("LOGINFORM0002")}
                            </Typography>
                            <Typography variant='subtitle2' align='left' style={{ color: '#7a7b97', marginBottom: '20px', marginTop: '6px', fontSize: '12px' }}>
                               {t("LOGINPAGE001")}
                            </Typography>
                        </Grid>
                        <Grid container alignItems='center'>
                            <Grid item xs style={{ display: 'flex' }}>
                                <Grid className="company_icon">
                                    <UserIcon className='visibleIcons' />
                                </Grid>
                                <CustomTextfield
                                    type={'text'}
                                    error={error.email}
                                    name='email'
                                    label={t("LOGINPAGE002")+ " *"}
                                    value={values.email}
                                    handleChange={(e) => handleChange(e)}
                                    helperText={error.email}
                                    validation='email'
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center'>
                            <Grid item xs style={{ display: 'flex' }}>
                                <Grid className="company_icon">
                                    <PassIcon className='visibleIcons' />
                                </Grid>
                                <CustomTextfield
                                    type={values.showPassword ? 'text' : 'password'}
                                    error={error.password}
                                    name='password'
                                    label={t("COMMON047")+" *"}
                                    value={values.password}
                                    handleChange={(e) => handleChange(e)}
                                    helperText={error.password}
                                    validation='password'
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge='end'>
                                                    {values.showPassword ? <Visibility className='visibleIcons' /> : <VisibilityOff className='visibleIcons' />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center'>
                            <Grid item xs>
                                <Box align='right' className='link_box'>
                                    <Link href='/vis/forgot-password' className='forgot_password'>
                                        {t('COMMON054')}
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} alignItems='center'>
                            <Grid item xs>
                                <Button type='submit' fullWidth variant='contained'>
                                        {t('COMMON038')}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                        <Grid container alignItems='center'>
                            <Grid item xs>
                                <Box mt={3} className='signIn'>
                                    <hr className='hrline_Left' />
                                    <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px' }}>
                                        {t('LOGINFORM0003')}
                                    </Typography>
                                    <hr className='hrline_Right' />
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center'>
                            <Grid item xs>
                                <Box mt={1} align='center'>
                                    <IconButton className='icon_button'
                                    // onClick={() => dispatch(redirectLogin())}
                                    >
                                        <img src={iot} alt='iotlogo' className='social_icons' />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center'>
                            <Grid item xs>
                                <Box align='center'>
                                    <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px', paddingBottom: '15px' }}>
                                        {t('LOGINFORM0004')} <Link href='/vis/signup' className='forgot_password'> {t('LOGINFORM0005')}</Link>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
            </LoginLayoutV2>
        </>
    );
}
export default withRouter(LoginFormV2);
