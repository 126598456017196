import { useTranslation } from 'react-i18next';

const Applyi18nTOStatus = (props) => {
    const { t } = useTranslation();
    function SwitchCase(props) {
        switch (props) {
            case 'BLOCKED':
                return (
                    process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                    t('Blacklist'): t('Blocked')
                );
            case 'SCHEDULED':
                return (
                    t('Scheduled')
                );
            case 'APPROVED':
                return (
                    t('Approved')
                );
            case 'CONFIRMED':
                return (
                    t('Confirmed')
                );
            case 'CHECKED_OUT':
                return (
                    t('Checked Out')
                );
            case 'CANCELLED':
                return (
                    t('Cancelled')
                );
            case 'Rescheduled':
                return (
                    t('Rescheduled')
                );
            case 'CHECKED_IN':
                return (
                    t('Checked In')
                );
              
            case 'INITIATED_FOR_BLOCKED':
                return (
                    process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                    t('Blacklist Initiated'):t('Block Initiated')
                );
               
            case 'REJECTED':
                return (
                    t('Rejected')
                );
            case 'MISSED':
                return (
                    t('Missed')
                );
            default:
                return (
                    props
                );
        }
    }

    return SwitchCase(props);
};

export default Applyi18nTOStatus;
