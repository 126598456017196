import { Avatar } from "@material-ui/core";
import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddPersonIcon from "../../assets/addPerson.svg";
import DataTable from "../../components/DataTable/DataTable";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import "../../App.css";
import Search from "../../components/SearchTab/Search";
import { useTranslation } from "react-i18next";
import AddPerson from "./AddPerson";
import PersonFilter from "./PersonFilter";
import DeleteIcon from "./DeleteIcon";
import ActivateIcon from "./ActivateIcon";
import DeletePerson from "./DeletePerson";
import Toast from "../../components/ToastContainer/CustomToast";
import EditIcon from "./EditIcon";
import HeaderToolbar from "../../components/HeaderToolbar";
import {
  activateAccount,
} from "../../slices/Person/PersonSlice";
import PersonViewPage from "./PersonViewPage";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import { useSelector } from 'react-redux';
import PersonRole from "./PersonRole";
import { listAllEmployees } from "../../slices/Employee/EmployeeSlice"
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import api from "../../config/http-common";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";

export default function Person() {

  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [openEditPerson, setOpenEditPerson] = useState(false);
  const [clear, setClear] = React.useState(false);
  const [openDeletePerson, setOpenDeletePerson] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [personFirstName, setPersonFirstName] = useState();
  const [personUserId, setPersonUserId] = useState();
  const [searchedRows, setSerchedRows] = useState([]);
  const [selectedData, onSelectionChange] = useState([]);
  const store = useSelector((state) => state);
  const zlink = localStorage.getItem("redirectFrom");
  const [pageSize, setPageSize] = useState(0);
  const [employeeId, setEmployeeId] = useState();
  const [del, setDel] = useState(false);
  const [typedRows, setTypedRows] = useState([]);

  const [roles, setRoles] = useState([]);
  const [viewPerson, setViewPerson] = useState(true);
  const [listPerson, setListPerson] = useState(true);
  const [load, setLoad] = React.useState(true);
  const [reload, setReload] = React.useState(false);

  const [selectedObject, setSelectedObject] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    profilePhoto: "",
    departmentId: "",
    designationId: "",
    employeeCode: "",
    departmentIdOrCode: null,
    designationIdOrCode: null,
    roleIdentifier: "",
    userId: "",
    status: "",
    siteId: "",
    address: {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      country: "",
      state: "",
      city: "",
      areaCode: "",
      lattitude: "",
      longitude: "",
      radius: "",
      timeZone: "",
      isDefault: true,
      addressTypeId: "",
      employeeId: "",
      userId: "",
      siteId: "",
    },
  });

  const [filter, setFilter] = useState({
    firstName: "",
    email: "",
    // phoneCountryCode: "",
    phone: "",
    code: "",
  });


  const defaultFilter = {
    firstName: "",
    email: "",
    phoneCountryCode: "",
    phone: "",
    code: "",
  };

  const applyFilter = (filterData) => {
    setClear(true);
    const query = {
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      email: filterData.email,
      phone: filterData.phone,
      gender: filterData.gender,
      profilePhoto: filterData.profilePhoto,
      departmentId: filterData.departmentId,
      designationId: filterData.designationId,
      employeeCode: filterData.employeeCode,
      departmentIdOrCode: null,
      designationIdOrCode: null,
      userId: filterData.userId,
      status: filterData.status,
      siteId: filterData.siteId,
      address: {
        addressLine1: filterData.addressLine1,
        addressLine2: filterData.addressLine2,
        addressLine3: filterData.addressLine3,
        country: filterData.country,
        state: filterData.state,
        city: filterData.city,
        areaCode: filterData.areaCode,
        lattitude: filterData.lattitude,
        longitude: filterData.longitude,
        radius: filterData.radius,
        timeZone: filterData.timeZone,
        isDefault: true,
        addressTypeId: filterData.addressTypeId,
        employeeId: filterData.employeeId,
        userId: filterData.userId,
        siteId: filterData.siteId,
      },
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setRowsPerPage(size)
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId))
      .unwrap()
      .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
        });
        setRoles(l);
        setViewPerson(l.includes("CV_VIEW_PERSONPROFILE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setListPerson(l.includes("CV_VIEW_PERSONLISTS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("CV_VIEW_PERSONLISTS")) {
          dispatch(listAllEmployees(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
              if ((data && data?.code === "OMSI0000" && data?.data)) {
                setRows(data?.data?.employees);
                setPage(data?.data?.currentPage);
                setTotalPages(data?.data?.totalPages);
                setPageSize(data?.data?.pageSize);
                setLoad(false);
              } else {
                setRows([]);
              }
            })
            .catch((er) => { });
        }
      });
  }, []);

  useEffect(() => {
    if (roles.includes("CV_VIEW_PERSONLISTS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
    }
  }, []);

  const loadData = (filter, paging) => {
    dispatch(listAllEmployees(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data && data?.code === "OMSI0000" && data?.data)) {
          setRows(data?.data?.employees);
          setPage(data?.data?.currentPage);
          setTotalPages(data?.data?.totalPages);
          setPageSize(data?.data?.pageSize);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const handleOpenEditPerson = (params) => {
    setOpenEditPerson(!openEditPerson);
    setSelectedObject(params);
  };

  const handleCloseEditPerson = () => {
    setOpenEditPerson(false);
  };

  const handleDeletePerson = (params) => {
    setOpenDeletePerson(true);
    setEmployeeId(params.id);
    setPersonFirstName(params.row.firstName);
    setPersonUserId(params.row.userId)
  };

  const handleActivateAccount = (data) => {
    if (data.length < 1) {
      Toast(t("COMMON019"), "warning")
      return;
    }

    var personsToActivate = [];
    data.map((record, index) => {
      let person = {
        id: record.id,
        firstName: record.firstName,
        lastName: record.lastName,
        email: record.email,
        phone: record.phone,
        userId: record.userId,
        employeeCode: record.employeeCode,
        companyCode: localStorage.getItem("companyCode"),
      };
      personsToActivate[index] = person;
    });

    dispatch(activateAccount(personsToActivate))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI3003") {
          Toast(t("PERSONACTSUCC001"), "success");
        } else if (data?.code === "CVAW0001") {
          Toast(t("User already active"), "warning");
        } else {
          Toast((data.message), 'error');
        }
        onSelectionChange([]);
        loadData(filter, paging);
      })
      .catch((er) => { });
  };

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage });
      setDel(false);
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false);
      }
    }
  }, [del]);

  const handleFilter = (data) => {

    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      firstName: data.firstName,
      email: data.email,
      // phoneCountryCode: data.phoneCountryCode,
      phone: data.phone,
      code: data.code,
    });

  };

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(filter, paging);
      return;
    }

    dispatch(listAllEmployees(Object.assign(filter, paging)))
    .unwrap()
    .then((data) => {
      if ((data && data?.code === "OMSI0000" && data?.data)) {
        setPage(data?.data?.currentPage);
        setTotalPages(data?.data?.totalPages);
        setPageSize(data?.data?.pageSize);
        
        const filteredRows = data?.data?.employees.filter((test) => {
          return (
            (test.firstName
              ? test.firstName.toLowerCase().includes(searchedVal.toLowerCase())
              : "") ||
            (test.lastName
              ? test.lastName.toLowerCase().includes(searchedVal.toLowerCase())
              : "") ||
            (test.code ? test.code.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
            (test.email ? test.email.includes(searchedVal.toLowerCase()) : "") ||
            (test.phone
              ? test.phone.toLowerCase().includes(searchedVal.toLowerCase())
              : "") ||
            `${test.firstName} ${test.lastName}`
              .toLowerCase()
              .includes(searchedVal.toLowerCase())
    
          );
        });
        setRows(filteredRows);

      } else {
        setRows([]);
      }
    })
    .catch((er) => { });
   
    
  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{ cursor: "pointer" }}
          alt={params.row.firstName}
          src={params.row.profilePhotoURL !== undefined ? params.row.profilePhotoURL : null}
        >{params.row.firstName == null ? "" : params.row.firstName.charAt(0)}</Avatar>
      }
      child={
        <Avatar
          style={{
            width: "300px",
            height: "300px",
            border: "12px solid #FFFF",
            fontSize: "100px",
            borderRadius: "1px",
            cursor: "pointer",
          }}
          alt={params.row.firstName}
          src={params.row.profilePhotoURL}
        >{params.row.firstName == null ? "" : params.row.firstName.charAt(0)}</Avatar>
      }
    />
  );

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...';
    } else {
      return text;
    }
  }

  const columns = React.useMemo(
    () => [
      {
        field: "employees.profile",
        headerName: "",
        sortable: false,
        width: 10,
        renderCell: (params) => {
          return (
            ZoomHandaler(params)
          );
        },
      },
      {
        field: "employees.firstName",
        headerName: "Name",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.firstName + " " + (params.row.lastName === undefined ? "" : params.row.lastName),
        renderCell: (params) => (
          <Tooltip title={` ${params.row.lastName !== null ? (params.row.firstName + " " + params.row.lastName) : (params.row.firstName)}`}>
            <span>{handleTextOverflow(params.row.lastName !== null ? (params.row.firstName + " " + params.row.lastName) : (params.row.firstName))}</span>
          </Tooltip>
        ),
      },
      {
        field: "employees.code",
        headerName: "Person ID",
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.code,
        renderCell: (params) => (
          <Tooltip title={` ${params.row.code}`}>
            <span>{handleTextOverflow(params.row.code)}</span>
          </Tooltip>
        ),
      },
      {
        field: "employees.phone",
        headerName: "Mobile",
        type: "singleSelect",
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.phone,
        renderCell: (params) => (
          <Tooltip title={` ${params.row.phone}`}>
            <span>{handleTextOverflow(params.row.phone)}</span>
          </Tooltip>
        ),
      },
      {
        field: "employees.email",
        headerName: "Email",
        flex: 1,
        minWidth: 150,
        valueGetter: (params) => params.row.email,
        renderCell: (params) => (
          <Tooltip title={` ${params.row.email}`}>
            <span>{handleTextOverflow(params.row.email)}</span>
          </Tooltip>
        ),
      },
      {
        field: "Actions",
        headerName: t("Actions"),
        type: "actions",
        flex: 1,
        minWidth: 190,
        width: 150,
        headerAlign: "center",
        getActions: (params) => [
          !zlink ? (
            <>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                onClick={() => handleOpenEditPerson(params.row)}
              />
            </>
          ) : (<>
            {viewPerson ? (
              <GridActionsCellItem
                icon={viewPerson ? (<PersonViewPage rowData={params.row} />) : ("")}
                label="View"
              />
            ) : ("")
            }
          </>
          ),

          !zlink ? (
            <>
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => handleDeletePerson(params)}
              />
            </>
          ) : (
            <>
              {/* <GridActionsCellItem
              icon={<PersonRole rowData={params.row} />}
              label="View"
            /> */}
            </>
          ),

          !zlink ? (
            <>
              <GridActionsCellItem
                icon={<ActivateIcon />}
                label="Activate Account"
                onClick={() => handleActivateAccount([params.row])}
              />
            </>
          ) : (
            <></>
          ),
        ],
      },
    ],

  );
  return (
    <>
      {!zlink ? (
        <>
          <HeaderToolbar
            src={AddPersonIcon}
            onClick={handleOpen}
            title="Person"
            tooltipTitle="Add Person"
          />
        </>
      ) : (
        <>
          <HeaderToolbar title="Person" />
        </>
      )}

      {listPerson &&
        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"row-reverse"}
          alignItems={"center"}
        >

          <Box item>
            {clear && (
              <Link
                href="#"
                underline="hover"
                className="line"
                style={{
                  opacity: "0.8",
                  color: "#E3393C",
                  fontSize: "14px",
                  underline: "hover",
                  display: "inline",
                }}
                onClick={resetFilter}
              >
                {"Clear Filter"}
              </Link>
            )}
          </Box>

          <Box item>
            <PersonFilter
              rowsPerPage={rowsPerPage}
              applyFilter={(data) => handleFilter(data)}
            />
          </Box>

          {zlink ? (
            <>
              <Box item>
                <Tooltip title={"Refresh"}>
                  <IconButton onClick={() => loadData(filter, paging)}>
                    <Refresh />
                  </IconButton>
                </Tooltip>
              </Box>
            </>) : (<></>)}

          <Box item p={1}>
            <Search
              onSearch={(e) => globalsearch(e)}
              clearSearch={() => loadData(filter, paging)}
            />
          </Box>

        </Box>}

      {listPerson ? (
        <div style={{ height: 300, width: "100%" }}>
          <DataTable
            columns={columns}
            rows={searchedRows.length === 0 ? rows : searchedRows}
            page={page}
            pageCount={totalPages}
            pageSize={rowsPerPage}
            handleChange={(newPage, size) => handleChange(newPage, size)}
          />
        </div>
      ) : (<div style={{ marginTop: '185px', paddingLeft: '300px' }}>
        <Typography variant="h5" style={{ color: '#3D4977', fontSize: '18px', fontWeight: '450' }}>
          {t('CVROLE002')}
        </Typography>
      </div>)
      }

      {open && (
        <AddPerson
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            handleClose(loadData(filter, paging));
          }}
        />
      )}

      {openEditPerson && (
        <AddPerson
          selectedObject={selectedObject}
          open={openEditPerson}
          handleClose={() => {
            setOpenEditPerson(false);
            loadData(filter, paging);
            handleClose(loadData(page, rowsPerPage));
            setSelectedObject({
              id: "",
              firstName: "",
              lastName: "",
              email: "",
              phone: "",
              gender: "",
              employeeCode: "",
              address: {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                country: "",
                state: "",
                city: "",
                areaCode: "",
                lattitude: "",
                longitude: "",
                radius: "",
                timeZone: "",
                addressTypeId: "",
                employeeId: "",
                userId: "",
                siteId: "",
              },
            });
          }}
        />
      )}

      {openDeletePerson && (
        <DeletePerson
          open={openDeletePerson}
          handleClose={(data) => {
            setOpenDeletePerson(data);
            loadData(filter, paging);
          }}
          DeleteId={employeeId}
          DeleteFirstName={personFirstName}
          DeleteUserId={personUserId}
          delete={() => setDel(true)}
        />
      )}
    </>
  );
}
