import { createTheme } from '@mui/material/styles';
import typography from './typography';

const MuiTheme = createTheme({
  components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: '10px',
                        padding: '13px',
                        fontSize: '16px',
                        '&:hover': {
                            background: process.env.REACT_APP_BG_BUTTON,
                        },
                    },
                },
                variants: [
                    {
                      props: { variant: 'contained' },
                      style: {
                        textTransform: 'none', 
                        backgroundColor: process.env.REACT_APP_BG_BUTTON,
                      },
                    },
                    {
                      props: { variant: 'text' },
                      style: {
                        textTransform: 'none',
                        color: process.env.REACT_APP_BG_BUTTON,
                      },
                    },
                    {
                        props: { variant: 'outlined' },
                        style: {
                          border: `1px solid #D1D1D1`,
                          color:'#00000099',
                          fontSize:'14px',
                          background: "transparent",
                          padding:'5px',
                          '&:hover': {
                            border: process.env.REACT_APP_BG_BORDER,
                            color: process.env.REACT_APP_BG_BUTTON,
                            background: "transparent",
                         },
                        },
                      },
                      {
                        props: { variant: 'contained', color:'secondary' },
                        style: {
                          textTransform: 'none',
                          backgroundColor: process.env.REACT_APP_BG_COLOUR,
                          '&:hover': {
                            backgroundColor: process.env.REACT_APP_BG_COLOUR,
                         },
                        },
                      },
                  ],
            },
            MuiBox: {
              styleOverrides: {
                root:{
                  margin:'30px',
                  backgroundColor:'#cdcdcd'
                }
              }
            },
            parentFieldtext: {
                " .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T > .sidenav---navtext---1AE_f ": {
                  color:'#0F5A9C',
              },
              "  .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y > .sidenav---navitem---9uL5T > .sidenav---navtext---1AE_f": {
                  color: '#F2824C',
              }
            },
        },
  typography
});

export default MuiTheme;
