import React, { useEffect } from 'react';
import { Typography, Grid, TextField, Autocomplete } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Device from '../../assets/DeviceIcon/pic.png';
import { Close, } from '@mui/icons-material';
import { toast } from 'react-toastify';
import CommonUtil from '../../Util/CommonUtils';
import TitleBar from '../../components/v4/TitleBar';
import { useDispatch } from 'react-redux';
import { bindDevice, findDeviceBySn, updateDevice } from '../../slices/ZlinkDeviceManagement/DeviceSlice';
import api from '../../config/http-common';
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons';
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons';
import Toast from '../../components/ToastContainer/CustomToast';


export default function AddDevicePage(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [siteList, setSiteList] = React.useState([]);
  const [siteId, setSiteId] = React.useState('');
  const [zoneList, setZoneList] = React.useState([]);
  const [zones, setZones] = React.useState([]);
  const [state, setState] = React.useState({
    deviceId: '',
    sn: '',
    siteId: '',
    zoneId: '',
    deviceName: 'b012',
  });
  const [error, setError] = React.useState({
    deviceId: '',
    sn: '',
    siteId: '',
    zoneId: '',
    deviceName: ''
  });

  useEffect(() => {
    if (open === true) {
      loadSites();
      loadZones();
      // loadDevice();
    }
  }, [open]);
  const styleDialog = {
    '& .MuiDialog-paper': {
      padding: '30px',
      width: '550px',
    },
  };

  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };

  const loadSites = () => {
    api.securedAxios().get(`/api/v2.0/zlink/sites`).then((response) => {
      if (response.data.code === 'LMSI0000') setSiteList(response.data.data.site);
    });
  };
  const loadZones = () => {
    api.securedAxios().get(`/api/v2.0/zlink/zones`).then((response) => {
      if (response.data.code === 'LMSI0000') setZoneList(response.data.data.zone);
    });
  };

  // const loadDevice = (payload) => {
  //   api.securedAxios().get(`/web/device/${payload.id}`).then((response) => {
  //     if (response.data.code === 'DMSI0000')
  //     alert("find devicename" + JSON.stringify(response.data))
  //   });
  // };

  useEffect(() => {
    if (!CommonUtil.isEmptyArray(siteList) && !CommonUtil.isEmptyArray(zoneList)) {
      const zoneFilter = zoneList.filter((zone) => { return zone.siteId === siteId });
      setZones(zoneFilter)
    }

  }, [siteId, zoneList])

  const handleChange = (event, newValue) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });

    setError({
      [name]: '',
    });
  };

  const findDevice = () => {
    if (CommonUtil.isEmptyString(state.sn)) {
      Toast(t("Please Enter Device SN"), "error");
      setError({ sn: true });
      return;
    }


    dispatch(findDeviceBySn(state.sn))
      .unwrap()
      .then((data) => {
        if (data.code === 'DMSI0000') {
          setState({
            ...state,
            deviceId: data.data.deviceId
          });
          setOpen(true);
        } else {
          if (data.code === 'DMSE0003') toast.error('Device does not exist.');
        }
      })
  };


  const handleSubmit = (props) => {
    if (CommonUtil.isEmptyString(state.siteId)) {
      setError({
        siteId: 'This field is required',
      });
      return;
    }
    if (CommonUtil.isEmptyString(state.zoneId)) {
      setError({
        zoneId: 'This field is required',
      });
      return;
    }



    dispatch(bindDevice(state))
      .unwrap()
      .then((data) => {
        if (data.code === 'DMSI0000') {
          toast.success("Device Bind successfully");
          history.push('/vis/device-list');
        } else {
          if (data.code === 'DMSE0028') toast.error('Device already bounded');
          if (data.code === 'DMSE0080') toast.error('Unable to authorize device ');
        }
      });



  };
  const zoneField = () => (
    <Autocomplete
      required
      error={error.zoneId}
      fullWidth
      disablePortal
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="zone bind list"
      getOptionLabel={(option) => option.name}
      options={zones}
      renderInput={(params) => <TextField {...params} helperText={state.zoneId !== "" ? "" : error.zoneId} error={state.zoneId !== "" ? "" : error.zoneId} required label="Bind zone" />}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
     
          setState({
            ...state,
            zoneId: newValue.id
          });
        }
        if (CommonUtil.isEmpty(newValue)) {
  
          setState({
            ...state,
            zoneId: ""
          });
        }
      }}
      ListboxProps={{ style: { maxHeight: '90px' } }}
    />
  )

  const siteField = (key) => (
    <Autocomplete
      fullWidth
      error={error.siteId}
      disablePortal
      isOptionEqualToValue={(option, value) => option.id === value.id}
      id="site bind list"
      getOptionLabel={(option) => option.name}
      options={siteList}
      sx={{ margin: '20px 0px' }}
      renderInput={(params) => <TextField {...params} required helperText={state.siteId !== "" ? "" : error.siteId} error={state.siteId !== "" ? "" : error.siteId} label="Bind site" />}
      onChange={(event, newValue) => {
        if (!CommonUtil.isEmpty(newValue)) {
     
          setState({
            ...state,
            siteId: newValue.id
          });
          setSiteId(newValue.id)
        }

        if (CommonUtil.isEmpty(newValue)) {
      
          setState({
            ...state,
            siteId: ""
          });
          setSiteId("")
        }
      }}
      ListboxProps={{ style: { maxHeight: '150px' } }}
    />
  )

  return (
    <>
      <TitleBar title={'Add device'} back_button={true} /><br />
      <Typography variant='h5' gutterBottom component='div' style={{ marginBottom: '1rem', fontWeight: 'bold', color: '#242424' }}>
        Manual register device with browser
      </Typography>
      <Grid
        style={{
          backgroundColor: '#FFFF',
          padding: '1rem',
          borderRadius: '8px',
        }}
      >
        <Typography variant='h4' gutterBottom component='div' style={{ fontWeight: 'bold', color: '#242424', marginTop: '20px' }}>
          Power up and set device network
        </Typography><br />
        <Typography className='device-instruction' style={{ color: '#707070', paddingBottom: '10px', fontSize: '16px' }}>1. Plug in the network cable if device support ethernet function.</Typography>
        <Typography className='device-instruction' style={{ color: '#707070', paddingBottom: '10px', fontSize: '16px' }}>
          2. Enter your device Ethernet setting/WiFi setting menu to enter communication setting page. Network setup is successful, device will display a QR code in standby page.
        </Typography>
        <Typography className='device-instruction' style={{ color: '#707070', paddingBottom: '10px', fontSize: '16px' }}>3. On the side of device box or on the back of device, can find the device serial number.</Typography>
        <Typography className='device-instruction' style={{ color: '#707070', paddingBottom: '10px', fontSize: '16px' }}>4. Fill in device serial number on system.</Typography>
      </Grid>
      <Typography variant='h5' gutterBottom component='div' style={{ margin: '1rem 0rem', fontWeight: 'bold', color: '#242424' }}>
        Enter device serial number
      </Typography>
      <Grid
        container
        style={{
          backgroundColor: '#FFFF',
          padding: '2rem',
          borderRadius: '8px',
        }}
      >
        <Grid item sm={4} paddingRight={1}>
          <TextField
            style={{ color: '#000' }}
            // error={!CommonUtil.isEmptyString(error.sn)}
            required
            fullWidth
            name='sn'
            label={t('Device SN')}
            helperText={error.sn}
            variant='outlined'
            value={state.sn}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
          marginTop: '1rem',
        }}
      >
        <SubmitButtons onClick={() => findDevice()}>
          {t('Add')}
        </SubmitButtons>
      </Grid>
      <Dialog sx={styleDialog} onClose={() => setOpen(!open)} open={open}>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant='h4' gutterBottom component='div'>
            Bind devices to your company
          </Typography>
          <Close onClick={() => setOpen(false)} />
        </Grid>
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'center',
            alignItems: 'center',
            border: '0.5px solid #FFFF',
            backgroundColor: '#e9e9e9',
            width: '100%',
            justifyContent: 'flex-start',
            padding: '0.5rem',
            borderRadius: '8px',
            marginTop: '0.5rem',
          }}
        >
          <img alt='complex' src={Device} style={{ height: '60px', width: '60px' }} />
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '0rem 0.5rem',
            }}
          >
            <Typography>{state.deviceName}</Typography>
            {/* <Typography variant='fieldLabel'>{props.updateData.deviceName}</Typography> */}
            <Typography variant='fieldLabel'>{state.sn}</Typography>
          </Grid>
        </Grid>
        <Typography variant='title' gutterBottom component='div' style={{ fontSize: '14px', margin: '0.5rem 0rem', color: '#304669' }}>
          Please specify the device to a site and zone.
        </Typography>
        <Typography
          style={{
            color: '#2281AB',
            fontSize: '14px',
            cursor: 'pointer',
            margin: '0.5rem 0rem',
            opacity: 1,
          }}
        >
          This device will sync the same time zone of the site.
        </Typography>
        <Grid>

          {siteField()}
          <br />
          {zoneField()}
        </Grid>
        <Grid
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '2rem',
            gap: '1rem',
          }}
        >
          <SubmitButtons onClick={() => handleSubmit()}>
            {t('Save')}
          </SubmitButtons>
          <CancelButtons onClick={() => setOpen(false)}>
            {t('Cancel')}
          </CancelButtons>
        </Grid>
      </Dialog>
    </>
  );
}
