import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoginLayoutV2 from './LoginLayoutV2';
import { useTranslation } from 'react-i18next';
import company from '../../assets/USA_Images/login_images/create-company.svg';
import vis_company from '../../assets/images/onboard/create-company.svg';
import CustomAutocomplete from '../../components/CustomInputs/CustomAutocomplete';
import CommonUtil from '../../Util/CommonUtils';
import CustomTextfield from '../../components/CustomInputs/CustomTextfield';
import { InputAdornment } from '@mui/material';
import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CustomPhoneInput from '../../components/CustomInputs/CustomPhoneInput';
import { countryList } from '../../components/Util/CountryList';
import { createCompany } from '../../slices/Company/CompanySlice';
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import jwt_decode from 'jwt-decode';
import Toast from '../../components/ToastContainer/CustomToast';
import { ReactComponent as CompanyIcons } from "../../assets/CompanyIcons.svg";
import { ReactComponent as EmailIcons } from "../../assets/EmailIcons.svg";
import CommonPhoneInput from '../../components/CustomInputs/CommonPhoneInput';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '70%',
        marginTop: theme.spacing(3),
    },
    submit: {
        backgroundColor: '#36c96d',
        borderRadius: '0.2rem',
        padding: '10px',
        '&:hover': {
            background: '#119743',
        },
    },
}));


function CreateCompany(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();

    const [values, setValues] = useState({
        name: '',
        code: '',
        email: '',
        phone: '',
        country: '',
        registratioNumber: '',
    });

    const [country, setCountry] = useState('');




    const [error, setError] = useState({
        name: false,
        code: false,
        registratioNumber: false,
        country: false,
        email: false,
        phone: false
    });

    const countries = countryList;

    const handleChange = (event) => {
        const value = event.target.value;
        const name = event.target.name;

        setValues({
            ...values,
            [name]: value,
        });

        setError({
            [name]: false,
        });
    };

    const handlePhoneChange = (event) => {

        setValues({
            ...values,
            phone: event,
        });
    }

    const handleSubmit = (event) => {

        event.preventDefault();
    }

    const handleCompany = (event) => {
        if (!CommonUtil.isValidEmail(values.email)) {
            Toast(t("Invalid Email"), "error");
            setError({ email: true });
            return false;
        }
        dispatch(createCompany(values))
            .unwrap()
            .then((data) => {
                if (data.code === 'UASI0011') {
                    localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
                    localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, 'Bearer ' + data.data.access_token);
                    localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, data.data.refresh_token);
                    const decoded = jwt_decode(data.data.access_token);
                    localStorage.setItem('companyId', decoded.companyId);
                    localStorage.setItem('companyName', decoded.companyName);
                    localStorage.setItem('companyCode', values.code);
                    history.push(REACT_URL_DASHBOARD);
                }
                else
                    Toast((data.message), 'error');
            })
            .catch((er) => { })
    }


    return (
        <>
            <LoginLayoutV2 lang={true} src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? company : vis_company} backbutton={true}>
                <Grid className='center_div'>
                    <form className={classes.form} noValidate autoComplete='off' onSubmit={handleSubmit}>
                        <Grid item xs={12} style={{ marginBottom: '13px', marginTop: '30px' }}>
                            <Typography variant='h2' align='left' style={{ color: '#464646', fontWeight: '500' }}>
                                Create Company
                            </Typography>
                        </Grid>
                        <Grid>
                            <CustomAutocomplete
                                label={t('ADD003')}
                                placeholder={t('ADD003')}
                                onSelect={(e) => setValues({
                                    ...values,
                                    country: e.name,
                                })}
                                options={countries}
                                required={false}
                            />
                        </Grid>
                        <Grid container alignItems='center'>
                            <Grid item xs style={{ display: 'flex' }}>
                                <Grid className="company_icon">
                                    <CompanyIcons className='visibleIcons' />
                                </Grid>
                                <CustomTextfield
                                    type={'text'}
                                    error={error.name}
                                    name='name'
                                    label='Name *'
                                    value={values.name}
                                    handleChange={(e) => handleChange(e)}
                                    helperText={error.name}
                                />
                            </Grid>
                        </Grid>

                        {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                            '' :
                            <Grid container alignItems='center'>
                                <Grid item xs style={{ display: 'flex' }}>
                                    <Grid className="company_icon">
                                        <CompanyIcons className='visibleIcons' />
                                    </Grid>
                                    <CustomTextfield
                                        type={'text'}
                                        error={error.code}
                                        name='code'
                                        label='Code'
                                        value={values.code}
                                        handleChange={(e) => handleChange(e)}
                                        helperText={error.code}
                                        validation='code'
                                    />
                                </Grid>
                            </Grid>}


                        {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                            '' :
                            <Grid container alignItems='center'>
                                <Grid item xs style={{ display: 'flex' }}>
                                    <Grid className="company_icon">
                                        <CompanyIcons className='visibleIcons' />
                                    </Grid>
                                    <CustomTextfield
                                        type={'text'}
                                        error={error.registratioNumber}
                                        name='registratioNumber'
                                        label='Registration Number'
                                        value={values.registratioNumber}
                                        handleChange={(e) => handleChange(e)}
                                        helperText={error.registratioNumber}
                                        validation='alpha-numeric'
                                    />
                                </Grid>
                            </Grid>}


                        <Grid container alignItems='center'>
                            <Grid item xs style={{ display: 'flex' }}>
                                <Grid className="company_icon">
                                    <EmailIcons className='visibleIcons' />
                                </Grid>
                                <CustomTextfield
                                    type={'text'}
                                    error={error.email}
                                    name='email'
                                    label='Email'
                                    value={values.email}
                                    handleChange={(e) => handleChange(e)}
                                    helperText={error.email}
                                    validation='email'
                                />
                            </Grid>
                        </Grid>
                        <Grid container alignItems='center'>
                            <Grid item xs style={{ margin: '-24px 0px' }}>
                                {/* <CustomPhoneInput specialLabel="Mobile" handleChange={handlePhoneChange} /> */}

                                <CommonPhoneInput
                                    sx={{ margin: 'none !important' }}
                                    error={error.phone}
                                    handleCountryCode={(data) => {
                                        setCountry(data.dialCode)
                                        setError({ ...error, phone: '' })
                                    }}
                                    countryCode={country}
                                    placeholder={t('COMPPROFSCREEN037')}
                                    phoneNumber={values.phone}
                                    name={'phone'}
                                    handleChange={(e) => handlePhoneChange(e)}


                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} alignItems='center'>
                            <Grid item xs >
                                <Box mt={3} mb={6}>
                                    <Button
                                        fullWidth variant='contained'
                                        color='primary'
                                        className={classes.submit}
                                        onClick={() => {
                                            handleCompany();
                                        }}
                                    >
                                        {t('COMMON007')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </LoginLayoutV2>
        </>
    );
}
export default withRouter(CreateCompany);
