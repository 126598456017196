import api from "../../config/http-common";

const DoorList = (query) => {
    return api.securedAxios().post('/web/door?pageNumber=' + query.page + '&pageSize=' + query.size);
};

const addDoorToAccessLevel = (payload) => {
    const body = { doors : payload.doors}
    return api.securedAxios().post('/web/door/' + payload.accessGroupId + '/doors', body);
};

const DoorService = {
    DoorList,
    addDoorToAccessLevel
};

export default DoorService;