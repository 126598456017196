import { Tooltip, Grid } from "@mui/material";
import React from "react";

const RescheduleIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <>
      <Tooltip title="Reschedule">
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.865"
              height="20.742"
              viewBox="0 0 25.865 20.742"
            >
              <g
                id="Group_5916"
                data-name="Group 5916"
                transform="translate(-46.418 -492)"
              >
                <g id="Events" transform="translate(52.064 492)">
                  <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M68.387,496.513h-.975a.505.505,0,0,1,0-1.01H68.4a.505.505,0,0,1,0,1.01Z"
                    transform="translate(-57.813 -492.975)"
                    fill="#3d4977"
                  />
                  <path
                    id="Path_11_-_Outline"
                    data-name="Path 11 - Outline"
                    d="M68.369,495.37h-.981a.6.6,0,0,0-.577.585.592.592,0,0,0,.577.613h1.025a.592.592,0,0,0,.577-.613A.6.6,0,0,0,68.369,495.37Zm0,1.01h-.981a.412.412,0,0,1,0-.823H68.4a.418.418,0,0,1,.4.433A.412.412,0,0,1,68.369,496.38Z"
                    transform="translate(-57.786 -492.938)"
                    fill="#3d4977"
                  />
                  <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M74.512,501.565H54.306v-3.536a2.526,2.526,0,0,1,2.526-2.526.505.505,0,0,1,0,1.01,1.516,1.516,0,0,0-1.515,1.515h0v2.526H73.5v-2.526a1.516,1.516,0,0,0-1.515-1.515.505.505,0,0,1,0-1.01h0a2.526,2.526,0,0,1,2.526,2.526Z"
                    transform="translate(-54.306 -492.975)"
                    fill="#3d4977"
                  />
                  <rect
                    id="Rectangle_6070"
                    data-name="Rectangle 6070"
                    width="3.392"
                    height="3.014"
                    transform="translate(14.13 10.105)"
                    fill="#3d4977"
                  />
                  <rect
                    id="Rectangle_6067"
                    data-name="Rectangle 6067"
                    width="3.014"
                    height="4.899"
                    transform="translate(4.709 0.684)"
                    fill="#3d4977"
                  />
                  <rect
                    id="Rectangle_6068"
                    data-name="Rectangle 6068"
                    width="3.014"
                    height="4.899"
                    transform="translate(12.623 0.684)"
                    fill="#3d4977"
                  />
                  <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M65.423,511.7H56.836a1.48,1.48,0,0,1-.772-.216H54.671a2.513,2.513,0,0,0,2.165,1.227h8.588a.505.505,0,0,0,0-1.01Zm-10.1-6.711H54.31V508.6h1.01Zm-1.01-5.412V502.1h1.01v-1.515H73.506v4.041a.505.505,0,1,0,1.01,0v-5.052ZM60.877,492h-1.01a1.515,1.515,0,0,0-1.515,1.515v3.031a1.515,1.515,0,0,0,1.515,1.515h1.01a1.515,1.515,0,0,0,1.1-.476.412.412,0,0,0,.051-.058,1.505,1.505,0,0,0,.361-.981v-3.031A1.515,1.515,0,0,0,60.877,492Zm.505,1.515v3.031a.507.507,0,0,1-.505.505h-1.01a.5.5,0,0,1-.505-.505v-3.031a.507.507,0,0,1,.505-.505h1.01A.511.511,0,0,1,61.382,493.515ZM68.96,492h-1.01a1.515,1.515,0,0,0-1.515,1.515v3.031a1.515,1.515,0,0,0,1.515,1.515h1.01a1.515,1.515,0,0,0,1.515-1.515v-3.031A1.515,1.515,0,0,0,68.96,492Zm.505,4.546a.507.507,0,0,1-.505.505h-1.01a.5.5,0,0,1-.505-.505v-3.031a.507.507,0,0,1,.505-.505h1.01a.511.511,0,0,1,.505.505Z"
                    transform="translate(-54.307 -492)"
                    fill="#3d4977"
                  />
                  <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M68.541,505.3H66.52a1.009,1.009,0,0,0-1.01,1.01v2.021a1,1,0,0,0,1.01,1.01h2.021a1.009,1.009,0,0,0,1.01-1.01V506.31A1.013,1.013,0,0,0,68.541,505.3Zm0,3.031H66.52V506.31h2.021Zm0,2.021H66.52a1.009,1.009,0,0,0-1.01,1.01v2.021a1,1,0,0,0,1.01,1.01h2.021a1.009,1.009,0,0,0,1.01-1.01v-2.021A1.013,1.013,0,0,0,68.541,510.352Zm0,3.031H66.52v-2.021h2.021ZM74.1,505.3H72.077a1.009,1.009,0,0,0-1.01,1.01v2.021a1,1,0,0,0,1.01,1.01H74.1a1.009,1.009,0,0,0,1.01-1.01V506.31A1.013,1.013,0,0,0,74.1,505.3Zm0,3.031H72.077V506.31H74.1Z"
                    transform="translate(-57.425 -495.702)"
                    fill="#3d4977"
                  />
                  <path
                    id="Rectangle_259"
                    data-name="Rectangle 259"
                    d="M75.319,512.179h0a2.117,2.117,0,0,1,2.117,2.117h0a2.117,2.117,0,0,1-2.117,2.117h0A2.117,2.117,0,0,1,73.2,514.3h0A2.118,2.118,0,0,1,75.319,512.179Z"
                    transform="translate(-59.566 -497.617)"
                    fill="#3d4977"
                  />
                  <path
                    id="Rectangle_259_-_Outline"
                    data-name="Rectangle 259 - Outline"
                    d="M76.3,512.18h-1.97a1.13,1.13,0,0,0-1.133,1.133v1.97a1.13,1.13,0,0,0,1.133,1.133H76.3a1.135,1.135,0,0,0,1.133-1.133v-1.97A1.13,1.13,0,0,0,76.3,512.18Zm-1.97,3.291a.191.191,0,0,1-.188-.188v-1.97a.193.193,0,0,1,.188-.195H76.3a.188.188,0,0,1,.188.195v1.97a.186.186,0,0,1-.188.188Z"
                    transform="translate(-59.565 -497.617)"
                    fill="#3d4977"
                  />
                  <path
                    id="Path_3801"
                    data-name="Path 3801"
                    d="M81.407,517a.518.518,0,0,1-.518-.518v-8.609a.518.518,0,1,1,1.036,0v8.609a.518.518,0,0,1-.518.518Z"
                    transform="translate(-61.705 -496.274)"
                    fill="#3d4977"
                  />
                  <path
                    id="Path_3802"
                    data-name="Path 3802"
                    d="M77.666,520.32H68.284a.518.518,0,1,1,0-1.036h9.382a.518.518,0,1,1,0,1.036Z"
                    transform="translate(-58.053 -499.594)"
                    fill="#3d4977"
                  />
                </g>
                <g
                  id="Group_5914"
                  data-name="Group 5914"
                  transform="translate(47.623 500.813)"
                >
                  <path
                    id="Line_1"
                    data-name="Line 1"
                    d="M10.871.5H1.877A.627.627,0,0,1,1.25-.123.627.627,0,0,1,1.877-.75h8.993a.627.627,0,0,1,.627.627A.627.627,0,0,1,10.871.5Z"
                    transform="translate(-1.25 3.192)"
                    fill="#3d4977"
                  />
                  <g id="layer1" transform="translate(7.172)">
                    <path
                      id="path5978"
                      d="M58.094,504.738a.511.511,0,0,1,.865-.382l2.716,2.55a.512.512,0,0,1,.02.725l-.02.02-2.716,2.55a.511.511,0,1,1-.715-.731l.014-.014,2.319-2.177L58.259,505.1A.506.506,0,0,1,58.094,504.738Z"
                      transform="translate(-58.09 -504.213)"
                      fill="#3d4977"
                      fill-rule="evenodd"
                    />
                  </g>
                </g>
                <g
                  id="Group_5915"
                  data-name="Group 5915"
                  transform="translate(46.418 506.587)"
                >
                  <path
                    id="Line_1-2"
                    data-name="Line 1-2"
                    d="M8.871.5H-.123A.627.627,0,0,1-.75-.123.627.627,0,0,1-.123-.75H8.871A.627.627,0,0,1,9.5-.123.627.627,0,0,1,8.871.5Z"
                    transform="translate(1.42 3.192)"
                    fill="#3d4977"
                  />
                  <g id="layer1-2">
                    <path
                      id="path5978-2"
                      d="M50.225,512.738a.511.511,0,0,0-.865-.383l-2.716,2.55a.513.513,0,0,0-.019.725l.019.02,2.716,2.55a.512.512,0,0,0,.756-.691.481.481,0,0,0-.056-.052l-2.319-2.179L50.06,513.1A.5.5,0,0,0,50.225,512.738Z"
                      transform="translate(-46.483 -512.213)"
                      fill="#3d4977"
                      fill-rule="evenodd"
                    />
                  </g>
                </g>
              </g>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.865"
              height="20.742"
              viewBox="0 0 25.865 20.742"
            >
              <g
                id="Group_5916"
                data-name="Group 5916"
                transform="translate(-46.418 -492)"
              >
                <g id="Events" transform="translate(52.064 492)">
                  <path
                    id="Path_11"
                    data-name="Path 11"
                    d="M68.387,496.513h-.975a.505.505,0,0,1,0-1.01H68.4a.505.505,0,0,1,0,1.01Z"
                    transform="translate(-57.813 -492.975)"
                    fill="#bac2c8"
                  />
                  <path
                    id="Path_11_-_Outline"
                    data-name="Path 11 - Outline"
                    d="M68.369,495.37h-.981a.6.6,0,0,0-.577.585.592.592,0,0,0,.577.613h1.025a.592.592,0,0,0,.577-.613A.6.6,0,0,0,68.369,495.37Zm0,1.01h-.981a.412.412,0,0,1,0-.823H68.4a.418.418,0,0,1,.4.433A.412.412,0,0,1,68.369,496.38Z"
                    transform="translate(-57.786 -492.938)"
                    fill="#bac2c8"
                  />
                  <path
                    id="Path_12"
                    data-name="Path 12"
                    d="M74.512,501.565H54.306v-3.536a2.526,2.526,0,0,1,2.526-2.526.505.505,0,0,1,0,1.01,1.516,1.516,0,0,0-1.515,1.515h0v2.526H73.5v-2.526a1.516,1.516,0,0,0-1.515-1.515.505.505,0,0,1,0-1.01h0a2.526,2.526,0,0,1,2.526,2.526Z"
                    transform="translate(-54.306 -492.975)"
                    fill="#bac2c8"
                  />
                  <rect
                    id="Rectangle_6070"
                    data-name="Rectangle 6070"
                    width="3.392"
                    height="3.014"
                    transform="translate(14.13 10.105)"
                    fill="#bac2c8"
                  />
                  <rect
                    id="Rectangle_6067"
                    data-name="Rectangle 6067"
                    width="3.014"
                    height="4.899"
                    transform="translate(4.709 0.684)"
                    fill="#bac2c8"
                  />
                  <rect
                    id="Rectangle_6068"
                    data-name="Rectangle 6068"
                    width="3.014"
                    height="4.899"
                    transform="translate(12.623 0.684)"
                    fill="#bac2c8"
                  />
                  <path
                    id="Path_13"
                    data-name="Path 13"
                    d="M65.423,511.7H56.836a1.48,1.48,0,0,1-.772-.216H54.671a2.513,2.513,0,0,0,2.165,1.227h8.588a.505.505,0,0,0,0-1.01Zm-10.1-6.711H54.31V508.6h1.01Zm-1.01-5.412V502.1h1.01v-1.515H73.506v4.041a.505.505,0,1,0,1.01,0v-5.052ZM60.877,492h-1.01a1.515,1.515,0,0,0-1.515,1.515v3.031a1.515,1.515,0,0,0,1.515,1.515h1.01a1.515,1.515,0,0,0,1.1-.476.412.412,0,0,0,.051-.058,1.505,1.505,0,0,0,.361-.981v-3.031A1.515,1.515,0,0,0,60.877,492Zm.505,1.515v3.031a.507.507,0,0,1-.505.505h-1.01a.5.5,0,0,1-.505-.505v-3.031a.507.507,0,0,1,.505-.505h1.01A.511.511,0,0,1,61.382,493.515ZM68.96,492h-1.01a1.515,1.515,0,0,0-1.515,1.515v3.031a1.515,1.515,0,0,0,1.515,1.515h1.01a1.515,1.515,0,0,0,1.515-1.515v-3.031A1.515,1.515,0,0,0,68.96,492Zm.505,4.546a.507.507,0,0,1-.505.505h-1.01a.5.5,0,0,1-.505-.505v-3.031a.507.507,0,0,1,.505-.505h1.01a.511.511,0,0,1,.505.505Z"
                    transform="translate(-54.307 -492)"
                    fill="#bac2c8"
                  />
                  <path
                    id="Path_14"
                    data-name="Path 14"
                    d="M68.541,505.3H66.52a1.009,1.009,0,0,0-1.01,1.01v2.021a1,1,0,0,0,1.01,1.01h2.021a1.009,1.009,0,0,0,1.01-1.01V506.31A1.013,1.013,0,0,0,68.541,505.3Zm0,3.031H66.52V506.31h2.021Zm0,2.021H66.52a1.009,1.009,0,0,0-1.01,1.01v2.021a1,1,0,0,0,1.01,1.01h2.021a1.009,1.009,0,0,0,1.01-1.01v-2.021A1.013,1.013,0,0,0,68.541,510.352Zm0,3.031H66.52v-2.021h2.021ZM74.1,505.3H72.077a1.009,1.009,0,0,0-1.01,1.01v2.021a1,1,0,0,0,1.01,1.01H74.1a1.009,1.009,0,0,0,1.01-1.01V506.31A1.013,1.013,0,0,0,74.1,505.3Zm0,3.031H72.077V506.31H74.1Z"
                    transform="translate(-57.425 -495.702)"
                    fill="#bac2c8"
                  />
                  <path
                    id="Rectangle_259"
                    data-name="Rectangle 259"
                    d="M75.319,512.179h0a2.117,2.117,0,0,1,2.117,2.117h0a2.117,2.117,0,0,1-2.117,2.117h0A2.117,2.117,0,0,1,73.2,514.3h0A2.118,2.118,0,0,1,75.319,512.179Z"
                    transform="translate(-59.566 -497.617)"
                    fill="#bac2c8"
                  />
                  <path
                    id="Rectangle_259_-_Outline"
                    data-name="Rectangle 259 - Outline"
                    d="M76.3,512.18h-1.97a1.13,1.13,0,0,0-1.133,1.133v1.97a1.13,1.13,0,0,0,1.133,1.133H76.3a1.135,1.135,0,0,0,1.133-1.133v-1.97A1.13,1.13,0,0,0,76.3,512.18Zm-1.97,3.291a.191.191,0,0,1-.188-.188v-1.97a.193.193,0,0,1,.188-.195H76.3a.188.188,0,0,1,.188.195v1.97a.186.186,0,0,1-.188.188Z"
                    transform="translate(-59.565 -497.617)"
                    fill="#bac2c8"
                  />
                  <path
                    id="Path_3801"
                    data-name="Path 3801"
                    d="M81.407,517a.518.518,0,0,1-.518-.518v-8.609a.518.518,0,1,1,1.036,0v8.609a.518.518,0,0,1-.518.518Z"
                    transform="translate(-61.705 -496.274)"
                    fill="#bac2c8"
                  />
                  <path
                    id="Path_3802"
                    data-name="Path 3802"
                    d="M77.666,520.32H68.284a.518.518,0,1,1,0-1.036h9.382a.518.518,0,1,1,0,1.036Z"
                    transform="translate(-58.053 -499.594)"
                    fill="#bac2c8"
                  />
                </g>
                <g
                  id="Group_5914"
                  data-name="Group 5914"
                  transform="translate(47.623 500.813)"
                >
                  <path
                    id="Line_1"
                    data-name="Line 1"
                    d="M10.871.5H1.877A.627.627,0,0,1,1.25-.123.627.627,0,0,1,1.877-.75h8.993a.627.627,0,0,1,.627.627A.627.627,0,0,1,10.871.5Z"
                    transform="translate(-1.25 3.192)"
                    fill="#bac2c8"
                  />
                  <g id="layer1" transform="translate(7.172)">
                    <path
                      id="path5978"
                      d="M58.094,504.738a.511.511,0,0,1,.865-.382l2.716,2.55a.512.512,0,0,1,.02.725l-.02.02-2.716,2.55a.511.511,0,1,1-.715-.731l.014-.014,2.319-2.177L58.259,505.1A.506.506,0,0,1,58.094,504.738Z"
                      transform="translate(-58.09 -504.213)"
                      fill="#bac2c8"
                      fill-rule="evenodd"
                    />
                  </g>
                </g>
                <g
                  id="Group_5915"
                  data-name="Group 5915"
                  transform="translate(46.418 506.587)"
                >
                  <path
                    id="Line_1-2"
                    data-name="Line 1-2"
                    d="M8.871.5H-.123A.627.627,0,0,1-.75-.123.627.627,0,0,1-.123-.75H8.871A.627.627,0,0,1,9.5-.123.627.627,0,0,1,8.871.5Z"
                    transform="translate(1.42 3.192)"
                    fill="#bac2c8"
                  />
                  <g id="layer1-2">
                    <path
                      id="path5978-2"
                      d="M50.225,512.738a.511.511,0,0,0-.865-.383l-2.716,2.55a.513.513,0,0,0-.019.725l.019.02,2.716,2.55a.512.512,0,0,0,.756-.691.481.481,0,0,0-.056-.052l-2.319-2.179L50.06,513.1A.5.5,0,0,0,50.225,512.738Z"
                      transform="translate(-46.483 -512.213)"
                      fill="#bac2c8"
                      fill-rule="evenodd"
                    />
                  </g>
                </g>
              </g>
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default RescheduleIcon;
