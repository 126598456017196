import React, { Fragment } from 'react';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import { useTranslation } from 'react-i18next';
import { IconFlagUS, IconFlagES, IconFlagCN } from 'material-ui-flags';
import { Menu, MenuItem, ListItem, Popover } from '@material-ui/core';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import LanguageIcon from '@material-ui/icons/Language';
import Language from '../../assets/images/onboard/Language.svg';


export default function HeaderI18n() {
  const { t, i18n } = useTranslation();
  // eslint-disable-next-line
  const [search, setSearch] = React.useState(null);
  // eslint-disable-next-line
  const [anchorLanguage, setAnchorLanguage] = React.useState(null);

  const handleCloseLangMenu = (event) => {
    if (event[0] === undefined) {
      BrowserLanguage.getDefaultLanguage();
      setSearch(null);
    } else {
      BrowserLanguage.setLanguage(event);
      i18n.changeLanguage(event);
      setSearch(event);
    }
    setAnchorLanguage(null);
    window.location.reload(false);
  };

  // eslint-disable-next-line
  const handleClose = () => {
    setAnchorLanguage(null);
  };

  // eslint-disable-next-line
  const handleOnclickLanguages = (event) => {
    setAnchorLanguage(event.currentTarget);
  };

  return (
    <Fragment>
      <PopupState variant='popover' popupId='demo-popup-menu'>
        {(popupState) => (
          <React.Fragment>
            <ListItem variant='contained' {...bindTrigger(popupState)} style={{ cursor: 'pointer' }}>
              {/* <LanguageIcon fontSize='medium' /> */}
              <img src={Language} alt='logo' className='lang' />
               {/* &nbsp;  <span style={{ textTransform: 'uppercase' }}>{' ' + localStorage.getItem('i18nextLng')}</span> */}
            </ListItem>
            <Popover  {...bindMenu(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem
                value={'en'}
                key={'en'}
                onClick={() => {
                  handleCloseLangMenu('en');
                }}
              >
                {' '}
                <IconFlagUS className='lang-flag' />
                &nbsp; {t('i18nLabelEnglishFlag')}
              </MenuItem>
              <MenuItem
                value={'es'}
                key={'es'}
                onClick={() => {
                  handleCloseLangMenu('es');
                }}
              >
                {' '}
                <IconFlagES className='lang-flag' />
                &nbsp; {t('i18nLabelSpanishFlag')}
              </MenuItem>
              <MenuItem
                value={'es'}
                key={'es'}
                onClick={() => {
                  handleCloseLangMenu('zh');
                }}
              >
                {' '}
                <IconFlagCN className='lang-flag' />
                &nbsp;  {t('chinese')}
              </MenuItem>
            </Popover >
          </React.Fragment>
        )}
      </PopupState>
    </Fragment>
  );
}
