import React from "react";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CommonUtil from '../../Util/CommonUtils';
import { TextField } from "@mui/material";

export default function AutocompleteComponent({ options, onSelect, error, InputProps, label, defaultValue, onBlur, value, multiple,disabled,renderOption, disableCloseOnSelect,...props }) {
    const filterOptions = createFilterOptions({ stringify: ({ name, code }) => `${name} ${code}`, });

    return (
        <>
            <Autocomplete
                multiple={multiple}
                disableCloseOnSelect={disableCloseOnSelect}
                disabled={disabled}
                autoSelect={true}
                noOptionsText={'No Options found'}
                size='small'
                filterOptions={filterOptions}
                getOptionLabel={(option) => (option.name ? option.name : null)}
                options={CommonUtil.isEmpty(options) ? [] : options}
                renderOption={renderOption}
                onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                        onSelect(newValue);
                    }
                }}
                onBlur={onBlur}
                autoHighlight={true}
                renderInput={(params) =>
                    <TextField
                        sx={[
                            () => ({
                                "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall ": {
                                    paddingTop: '14px !important',
                                    paddingBottom: '14px !important',
                                },

                            })]}
                        {...params}
                        className='invite_company'
                        required={props.required}
                        variant='outlined'
                        fullWidth
                        helperText={error}
                        label={label}
                        value={value}
                        error={error}
                    />
                }
            />
        </>
    );
}
