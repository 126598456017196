import React, { useEffect, useState } from "react";
import { Typography, Grid, Divider, Checkbox, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import TitleBar from "../../components/v4/TitleBar";
import { ReactComponent as Step1 } from "../../assets/v4/Union228.svg";
import { ReactComponent as Step2 } from "../../assets/v4/Union230.svg";
import { ReactComponent as Step3 } from "../../assets/v4/Union229.svg";
import { useHistory } from "react-router-dom";
import { REACT_ADD_DEVICE } from "../../actions/EndPoints";
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";

export default function ZlinkAddDeviceInfo(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [isRead, setRead] = useState(false);

  const handleSubmit = () => {
    history.push(REACT_ADD_DEVICE);
  };
  
  return (
    <>
      <TitleBar title={"Add Device"} back_button={true} />
      <Grid
        container
        style={{
          backgroundColor: "#FFFF",
          padding: "2rem",
          borderRadius: "8px",
        }}
      >
        <Grid style={{ width: "30%" }}>
          <Typography variant="title" gutterBottom component="div"  style={{color:'#000', fontWeight:'500', fontSize:'16px'}}>
            Step 1: Power up and turn on the device
          </Typography><br/>
          <Typography variant="fieldLabel" style={{color:'#707070', opacity:'0.8'}}>
            If the device has a network, it will automatically connect to the
            network and start working.
          </Typography>
          <br />
          <Step1
            style={{ alignSelf: "center", width: "100%", padding: "1rem 0rem" }}
          />
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: "0px 1rem" }}
        />

        <Grid style={{ width: "30%" }}>
          <Typography variant="title" gutterBottom component="div"  style={{color:'#000', fontWeight:'500', fontSize:'16px'}}>
            Step 2: Configure the network
          </Typography><br/>
          <Typography variant="fieldLabel" style={{color:'#707070', opacity:'0.8'}}>
            You may use bluetooth to set up the network. Or some device has
            touch screen that has network setting in firmware.
          </Typography>
          <Step2
            style={{ alignSelf: "center", width: "100%", padding: "1rem 0rem" }}
          />
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          style={{ margin: "0px 1rem" }}
        />

        <Grid style={{ width: "30%" }}>
          <Typography variant="title" gutterBottom component="div" style={{color:'#000', fontWeight:'500', fontSize:'16px'}}>
          Step 3: Register the device to your organization
          </Typography> <br/>
          <Typography variant="fieldLabel" style={{color:'#707070', opacity:'0.8'}}>
          Device needs to been bound to your organization, only your company can use the date of the device. Later, check those devices in your "Device Center".
          </Typography>
          <Step3
            style={{ alignSelf: "center", width: "100%", padding: "1rem 0rem" }}
          />
        </Grid>
      </Grid>
      <Grid
        style={{
          fontFamily: "Roboto",
          color: "#304669",
          cursor: "default",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "2rem 0.4rem",
        }}
      >
        <Grid container style={{ alignSelf: "center" }}>
          <Checkbox
             onChange={(e) => setRead(e.target.checked)}
             style={{color: process.env.REACT_APP_BG_ICON,  padding: "0px", fontSize: "16px" }}
           />&nbsp;
          <Typography style={{ fontWeight: "bold" }}>
            {t("I have read these instructions")}
          </Typography>
        </Grid>
        <Grid>
          <SubmitButtons disabled={!isRead} onClick={() => handleSubmit()}>
            {t("Continue")}
          </SubmitButtons>
        </Grid>
      </Grid>
    </>
  );
}
