import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
    let query = "?isPagable=true&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    if (!CommonUtil.isEmptyString(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    return query;
};

const getPurposeType = (filter) => {
    return api.securedAxios().get('/web/getPurposeType' + buildQuery(filter));
}

const createPurposeType = (payload) => {
    return api.securedAxios().post('/web/createPurposeType', payload);
}

const updatePurposeType = (payload) => {
    return api.securedAxios().put('/web/updatePurposeType/' + payload.id, payload);
}

const getPurposeTypeById = (payload) => {
    return api.securedAxios().get('/web/getPurposeTypeById/' + payload.id);
}


const purposeTypesService = {
    getPurposeType,
    createPurposeType,
    updatePurposeType,
    getPurposeTypeById,
}

export default purposeTypesService;