import React from "react";
import { AccordionSummary, Checkbox, Grid, InputLabel, ownerDocument } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ReactComponent as LeftArrowIcon } from "../../assets/arrow.svg";
import { margin } from "@mui/system";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: "8px",
  display: "contents",
  margin: "0px 24px",
  "& .MuiPaper-root-MuiAccordion-root.Mui-expanded(:first-of-type)": {
    marginTop: "12px !importent",
  },
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  backgroundColor: "#FFFF",
  padding: "0px !importent",
}));

export default function CustomizedAccordions(props) {
  const [expand, setExpanded] = React.useState("");
  const isChecked = (codes) => {
    var checked = [];
    codes.map((code) => {
      if (props.permissions[code]) checked.push(code);
    });
    return checked.length === codes.length - [];
  };

  const handleChange = (codes, e) => {
    var newPermissions = { ...props.permissions };
    codes.map((code) => {
      newPermissions[code] = e.target.checked;
    });

    props.setPermissions({
      ...props.permissions,
      ...newPermissions,
    });
  };
  const permissionTree = [
    {
      parent: {
        title: "Dashboard",
        codes: [
          "CV_DASHBOARD",
          "VIEW_CV_DASHBOARDS",
        ],
      },
      child: [
        {
          title: 'View Dashboard',
          code: 'VIEW_CV_DASHBOARDS',
        }
      ],
    },
    {
      parent: {
        title: "Organization",
        codes: [
          "CV_ORGANIZATION",
          "CV_VIEW_PERSONLISTS",
          "CV_VIEW_PERSONPROFILE",
          'CV_VIEW_PERMISSION_LIST',
          'CV_VIEW_PERMISSIONS',
        ],
      },
      child: [
        {
          title: 'View Person List',
          code:  'CV_VIEW_PERSONLISTS',
        },
        {
          title: 'View Person Details',
          code: 'CV_VIEW_PERSONPROFILE',
        },
        {
          title: 'Role and Permission List',
          code: 'CV_VIEW_PERMISSION_LIST',
        },
        {
          title: 'View Role and Permission Details',
          code: 'CV_VIEW_PERMISSIONS',
        },
      ],
    },
    {
      parent: {
        title: "Site Management",
        codes: [
          "CV_SITE_MANAGEMENT",
          "CV_VIEW_SITELIST",
          "CV_VIEW_SITE",
          "CV_VIEW_ZONE_LIST",
          "CV_VIEW_ZONE",
        ],
      },
      child: [
        {
          title: 'View Site List',
          code: 'CV_VIEW_SITELIST',
        },
        {
          title: 'View Site Details',
          code: 'CV_VIEW_SITE',
        },
        {
          title: 'View Zone List',
          code: 'CV_VIEW_ZONE_LIST',
        },
        {
          title: 'View Zone Details',
          code: 'CV_VIEW_ZONE',
        },
      ],
    },
    {
      parent: {
        title: "Device Management",
        codes: [
          "CV_DEVICE_MANAGEMENT",
          "DEVICE_LISTS",
          "VIEW_CV_DEVICES",
          "CREATE_REBOOT_DELETE_ENBLE_DEVICES",
        ],
      },
      child: [
        {
          title: 'Device List',
          code: 'DEVICE_LISTS',
        },
        {
          title: 'View Device Details',
          code: 'VIEW_CV_DEVICES',
        }, 
        {
          title: 'Add, Reboot, Delete and Enable/Disable Device',
          code: 'CREATE_REBOOT_DELETE_ENBLE_DEVICES',
        }, 
      ],
    },

    {
      parent: {
        title: "Visitor",
        codes: [
          "CV_VISITOR",
          "VISITING_FLOWCHART",
          "INVITATION_LIST",
          "CREATE_INVITATION",
          "VIEW_INVITATION",
          "CANCEL_INVITATION",
          "BLOCK_INVITATION",
          "RESCHEDULE_INVITATION",
          "RESEND_INVITATION",
          "REGISTRATION_LIST",
          "CREATE_REGISTRATION",
          "VIEW_REGISTRATION",
          "CANCEL_REGISTRATION",
          "BLOCK_REGISTRATION",
          "RESCHEDULE_REGISTRATION",
          "RESEND_REGISTRATION",
          "CREATE_SELF_REGISTRATION",
          "INVITATION_APPROVALS",
          "REGISTRATION_APPROVALS",
          "BLOCKED_REQUEST_APPROVALS_CV",
          "BLOCKED_VISITORS_LIST",
          "VIEW_BLOCKED_VISITORS",
          "UNBLOCK_VISITORS",
          "VISITOR_LOG_LIST",
          "VIEW_VISITOR_LOG",
          "BLOCK_VISITOR_LOG",
        ],
      },
      child: [
        {
          title: 'Visiting Flowchart',
          code: 'VISITING_FLOWCHART',
        },
        {
          title: 'Invitation List',
          code: 'INVITATION_LIST',
        },
        {
          title: 'Create Invitation',
          code: 'CREATE_INVITATION',
        },
        {
          title: 'View Invitation',
          code: 'VIEW_INVITATION',
        },
        {
          title: 'Cancel Invitation',
          code: 'CANCEL_INVITATION',
        },
        {
          title: 'Reschedule Invitation',
          code: 'RESCHEDULE_INVITATION',
        },
        {
          title: 'Block Invitation',
          code: 'BLOCK_INVITATION',
        },
        {
          title: 'Resend Invitation',
          code: 'RESEND_INVITATION',
        },
        {
          title: 'Registration List',
          code: 'REGISTRATION_LIST',
        },
        {
          title: 'Create Registration',
          code: 'CREATE_REGISTRATION',
        },
        {
          title: 'View Registration',
          code: 'VIEW_REGISTRATION',
        },
        {
          title: 'Cancel Registration',
          code: 'CANCEL_REGISTRATION',
        },
        {
          title: 'Reschedule Registration',
          code: 'RESCHEDULE_REGISTRATION',
        },
        {
          title: 'Block Registration',
          code: 'BLOCK_REGISTRATION',
        },
        {
          title: 'Resend Registration',
          code: 'RESEND_REGISTRATION',
        },
        {
          title: 'Create Self Registration',
          code: 'CREATE_SELF_REGISTRATION',
        },
        {
          title: 'Approve/Reject Invitations(Registrations)',
          code: 'INVITATION_APPROVALS',
        },
        {
          title: 'Approve/Reject Self Registrations',
          code: 'REGISTRATION_APPROVALS',
        },
        {
          title: 'Approve/Reject Blocked Requests',
          code: 'BLOCKED_REQUEST_APPROVALS_CV',
        },
        {
          title: 'Blocked Visitors List',
          code: 'BLOCKED_VISITORS_LIST',
        },
        {
          title: 'View Blocked Visitor Details',
          code: 'VIEW_BLOCKED_VISITORS',
        },
        {
          title: 'Unblock Visitors',
          code: 'UNBLOCK_VISITORS',
        },
        {
          title: 'Visitor Log List',
          code: 'VISITOR_LOG_LIST',
        },
        {
          title: 'View Visitor Log',
          code: 'VIEW_VISITOR_LOG',
        },
        {
          title: 'Block Visitor Log',
          code: 'BLOCK_VISITOR_LOG',
        }
      ],
    },

    {
      parent: {
        title: "Reports",
        codes: [
          "CV_REPORTS",
          "EVENTS_LIST",
        ],
      },
      child: [
        {
          title: 'View Events List',
          code:  'EVENTS_LIST',
        },
      ],
    },

    {
      parent: {
        title: "Settings",
        codes: [
        "CV_SETTINGS",
        "TIMES_LOTS_LIST",
        "CREATE_UPDATE_DELETE_TIMES_LOTS",
        "VISITING_PURPOSE_LIST",
        "VIEW_CV_VISITING_PURPOSE",
        "CREATE_EDIT_VISITING_PURPOSE",
        "PURPOSE_ACCESS_LEVEL_VISITING_PURPOSE",
        "VISITING_ACCESS_LEVEL_LIST",
        "MANAGE_CV_VISITING_ACCESS_LEVEL",
        "CREATE_EDIT_DELETE_VISITING_ACCESS_LEVEL",
        "RESTRICT_TYPE_LIST",
        "CREATE_EDIT_RESTRICT_TYPE",
        "VIEW_CV_RESTRICT_TYPE",
        "CHECKLIST_LIST",
        "CREATE_EDIT_CHECKLIST",
        "CHECKITEMS_CV_CHECKLIST",
        "VIEW_CV_CHECKLIST",
        "WATCHLIST_LIST",
        "VIEW_CV_WATCHLIST",
        "CREATE_EDIT_DELETE_WATCHLIST",
        "VIEW_URGENT_MESSAGES",
        "CREATE_URGENT_MESSAGES",
        ],
      },
      child: [
        {
          title: 'Time Slots List',
          code: 'TIMES_LOTS_LIST',
        },
        {
          title: 'Add, Edit and Delete Time Slots',
          code: 'CREATE_UPDATE_DELETE_TIMES_LOTS',
        },
        {
          title: 'Visiting Purpose List',
          code: 'VISITING_PURPOSE_LIST',
        },
        {
          title: 'View Visiting Purpose Details',
          code: 'VIEW_CV_VISITING_PURPOSE',
        },
        {
          title: 'Add and Edit Visiting Purpose',
          code: 'CREATE_EDIT_VISITING_PURPOSE',
        },
        {
          title: 'Purpose Type Access Levels',
          code: 'PURPOSE_ACCESS_LEVEL_VISITING_PURPOSE',
        },
        {
          title: 'Visiting Access Level List',
          code: 'VISITING_ACCESS_LEVEL_LIST',
        },
        {
          title: 'Manage Visiting Access Level',
          code: 'MANAGE_CV_VISITING_ACCESS_LEVEL',
        },
        {
          title: 'Add, Edit and Delete Visiting Access Level',
          code: 'CREATE_EDIT_DELETE_VISITING_ACCESS_LEVEL',
        },
        {
          title: 'Restrict Type List',
          code: 'RESTRICT_TYPE_LIST',
        },
        {
          title: 'View Restrict Type Details',
          code: 'VIEW_CV_RESTRICT_TYPE',
        },
        {
          title: 'Add and Edit Restrict Type',
          code: 'CREATE_EDIT_RESTRICT_TYPE',
        },
        {
          title: 'Checklist List',
          code: 'CHECKLIST_LIST',
        },
        {
          title: 'View Checklist Details',
          code: 'VIEW_CV_CHECKLIST',
        },
        {
          title: 'Add and Edit Checklist',
          code: 'CREATE_EDIT_CHECKLIST', 
        },
        {
          title: 'Checklist Items',
          code: 'CHECKITEMS_CV_CHECKLIST',
        },
        {
          title: 'Watchlist List',
          code: 'WATCHLIST_LIST',
        },
        {
          title: 'View Watchlist Details',
          code: 'VIEW_CV_WATCHLIST',
        }, 
        {
          title: 'Add, Edit and Delete Watchlist',
          code: 'CREATE_EDIT_DELETE_WATCHLIST',
        },
        {
          title: 'Urgent Messages List',
          code: 'VIEW_URGENT_MESSAGES',
        },
        {
          title: 'Add Urgent Messages',
          code: 'CREATE_URGENT_MESSAGES',
        }
      ],
    },

  ];
  const isExpaded = (title) => {
    return expand === title ? true : false;
  };

  const selectPermission = (idOrCode, e) => {

// ---------------------------------- DASHBOARD PERMISSIONS -------------------------------------------------
  
  if(idOrCode === "VIEW_CV_DASHBOARDS") {
    if(props.permissions[idOrCode]===true){
          props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          // VIEW_CV_DASHBOARDS: true,
          CV_DASHBOARD: e.target.checked,
        });
      }
    else{
          props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          VIEW_CV_DASHBOARDS: e.target.checked,
          CV_DASHBOARD: e.target.checked,
        });
      }
  }
else if( idOrCode === "VIEW_CV_DASHBOARDS" && props.permissions['VIEW_CV_DASHBOARDS']){
          props.setPermissions({
          ...props.permissions,
          VIEW_CV_DASHBOARDS: false,
          CV_DASHBOARD: false,
      });
  }

// ---------------------------------- PERSON AND ROLE PERMISSIONS -------------------------------------------

  else if ((idOrCode === "CV_VIEW_PERSONLISTS" || idOrCode === "CV_VIEW_PERSONPROFILE" || idOrCode === "CV_ORGANIZATION") &&
            idOrCode !== "CV_ORGANIZATION") {
    if (idOrCode === "CV_VIEW_PERSONLISTS" ||
      idOrCode === "CV_VIEW_PERSONPROFILE") {
       
      if(idOrCode === "CV_VIEW_PERSONPROFILE"){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          CV_VIEW_PERSONLISTS: true,
          CV_ORGANIZATION: e.target.checked,
        });
      }else if(idOrCode === "CV_VIEW_PERSONLISTS"&& props.permissions['CV_VIEW_PERSONLISTS']){
        props.setPermissions({
          ...props.permissions,
          CV_VIEW_PERSONPROFILE: false,
          CV_VIEW_PERSONLISTS: false,
          CV_ORGANIZATION: false,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          CV_VIEW_PERSONLISTS: e.target.checked,
          CV_ORGANIZATION: e.target.checked,
        });
      }
    } 
  }

// ---------------------------------- PERSON AND ROLE PERMISSIONS ------------------------------------------- 
      
  else if ((idOrCode === "CV_VIEW_PERMISSION_LIST" || idOrCode === "CV_VIEW_PERMISSIONS" || idOrCode === "CV_ORGANIZATION") &&
           idOrCode !== "CV_ORGANIZATION") {
    if (idOrCode === "CV_VIEW_PERMISSION_LIST" ||
      idOrCode === "CV_VIEW_PERMISSIONS") {

    if(idOrCode === "CV_VIEW_PERMISSIONS"){
      props.setPermissions({
        ...props.permissions,
        [idOrCode]: e.target.checked,
        CV_VIEW_PERMISSION_LIST: true,
        CV_ORGANIZATION: e.target.checked,
      });
    }else if(idOrCode === "CV_VIEW_PERMISSION_LIST"&& props.permissions['CV_VIEW_PERMISSION_LIST']){
      props.setPermissions({
        ...props.permissions,
        CV_VIEW_PERMISSIONS: false,
        CV_VIEW_PERMISSION_LIST: false,
        CV_ORGANIZATION: false,
      });
    }
    else{
      props.setPermissions({
        ...props.permissions,
        [idOrCode]: e.target.checked,
        CV_VIEW_PERMISSION_LIST: e.target.checked,
        CV_ORGANIZATION: e.target.checked,
      });
    }
  }
}

// ---------------------------------- SITE MANAGEMENT PERMISSIONS -------------------------------------------

  else if ((idOrCode === "CV_SITE_MANAGEMENT" || idOrCode === "CV_VIEW_SITELIST" || idOrCode === "CV_VIEW_SITE") &&
            idOrCode !== "CV_SITE_MANAGEMENT") {
    if ((idOrCode === "CV_VIEW_SITELIST" ||
      idOrCode === "CV_VIEW_SITE")) {

      if(idOrCode === "CV_VIEW_SITE"){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          CV_VIEW_SITELIST: true,
          CV_SITE_MANAGEMENT: e.target.checked,
        });
      }else if(idOrCode === "CV_VIEW_SITELIST"&& props.permissions['CV_VIEW_SITELIST']){
        props.setPermissions({
          ...props.permissions,
          CV_VIEW_SITE: false,
          CV_VIEW_SITELIST: false,
          CV_SITE_MANAGEMENT: false,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          CV_VIEW_SITELIST: e.target.checked,
          CV_SITE_MANAGEMENT: e.target.checked,
        });
      }
    } 
  }

// ---------------------------------- ZONE MANAGEMENT PERMISSIONS -----------------------------------------

  else if ((idOrCode === "CV_SITE_MANAGEMENT" || idOrCode === "CV_VIEW_ZONE_LIST" || idOrCode === "CV_VIEW_ZONE") &&
            idOrCode !== "CV_SITE_MANAGEMENT") {

    if ((idOrCode === "CV_VIEW_ZONE_LIST" ||
      idOrCode === "CV_VIEW_ZONE")) {

      if(idOrCode === "CV_VIEW_ZONE"){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          CV_VIEW_ZONE_LIST: true,
          CV_SITE_MANAGEMENT: e.target.checked,
        });
      }else if(idOrCode === "CV_VIEW_ZONE_LIST"&& props.permissions['CV_VIEW_ZONE_LIST']){
        props.setPermissions({
          ...props.permissions,
          CV_VIEW_ZONE: false,
          CV_VIEW_ZONE_LIST: false,
          CV_SITE_MANAGEMENT: false,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          CV_VIEW_ZONE_LIST: e.target.checked,
          CV_SITE_MANAGEMENT: e.target.checked,
        });
      }
    } 
  }

// ---------------------------------- DEVICE MANAGEMENT PERMISSIONS -----------------------------------------

  else if ((idOrCode === "CV_DEVICE_MANAGEMENT" ||  idOrCode === "DEVICE_LISTS" ||
      idOrCode === "VIEW_CV_DEVICES" || idOrCode === "CREATE_REBOOT_DELETE_ENBLE_DEVICES") &&
      idOrCode !== "CV_DEVICE_MANAGEMENT") {
    if ((idOrCode === "DEVICE_LISTS" || idOrCode === "VIEW_CV_DEVICES" ||
         idOrCode === "CREATE_REBOOT_DELETE_ENBLE_DEVICES")) {

      if(idOrCode === "VIEW_CV_DEVICES" || idOrCode === "CREATE_REBOOT_DELETE_ENBLE_DEVICES"){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          DEVICE_LISTS: true,
          CV_DEVICE_MANAGEMENT: e.target.checked,
        });
      }else if(idOrCode === "DEVICE_LISTS"&& props.permissions['DEVICE_LISTS']){
        props.setPermissions({
          ...props.permissions,
          DEVICE_LISTS: false,
          VIEW_CV_DEVICES: false,
          CREATE_REBOOT_DELETE_ENBLE_DEVICES:  false,
          CV_DEVICE_MANAGEMENT: false,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          DEVICE_LISTS: e.target.checked,
          CV_DEVICE_MANAGEMENT: e.target.checked,
        });
      }
    } 
  } 
               
// ---------------------------------- VISITOR MANAGEMENT PERMISSIONS ----------------------------------------  
  
  // ---------------------------------- VISITOR FLOWCHART PERMISSIONS -----------------------------------------

  else if (idOrCode === "VISITING_FLOWCHART") {
      if(props.permissions[idOrCode]===true){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          // VISITING_FLOWCHART: true,
          CV_VISITOR: e.target.checked,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          VISITING_FLOWCHART: e.target.checked,
          CV_VISITOR: e.target.checked,
        });
      }
    }
  else if( idOrCode === "VISITING_FLOWCHART" && props.permissions['VISITING_FLOWCHART']){
        props.setPermissions({
          ...props.permissions,
          VISITING_FLOWCHART: false,
          CV_VISITOR: false,
        });
    }

  // ---------------------------------- VISITOR INVITATION PERMISSIONS ----------------------------------------

  else if (
    ( idOrCode === "INVITATION_LIST" || idOrCode === "CREATE_INVITATION" ||
      idOrCode === "CANCEL_INVITATION" || idOrCode === "VIEW_INVITATION" ||
      idOrCode === "BLOCK_INVITATION" || idOrCode === "RESCHEDULE_INVITATION" || idOrCode === "RESEND_INVITATION"
      )
  ) {
      if(idOrCode === "CREATE_INVITATION" || idOrCode === "CANCEL_INVITATION" ||
         idOrCode === "VIEW_INVITATION" || idOrCode === "BLOCK_INVITATION" ||
         idOrCode === "RESCHEDULE_INVITATION" || idOrCode === "RESEND_INVITATION"){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          INVITATION_LIST: true,
          CV_VISITOR: e.target.checked,
        });
      }else if(idOrCode === "INVITATION_LIST"&& props.permissions['INVITATION_LIST']){
        props.setPermissions({
          ...props.permissions,
          INVITATION_LIST: false,
          CREATE_INVITATION: false,
          CANCEL_INVITATION: false,
          VIEW_INVITATION: false,
          BLOCK_INVITATION: false,
          RESCHEDULE_INVITATION: false,
          RESEND_INVITATION: false,
          CV_VISITOR: false,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          INVITATION_LIST: e.target.checked,
          CV_VISITOR: e.target.checked,
        });
      }
  }

  // ---------------------------------- VISITOR REGISTRATION PERMISSIONS --------------------------------------

  else if (
    ( idOrCode === "REGISTRATION_LIST" || idOrCode === "CREATE_REGISTRATION" ||
      idOrCode === "VIEW_REGISTRATION" || idOrCode === "CANCEL_REGISTRATION" ||
      idOrCode === "BLOCK_REGISTRATION" || idOrCode === "RESCHEDULE_REGISTRATION" || idOrCode === "RESEND_REGISTRATION"
      )
  ) {
      if(idOrCode === "CREATE_REGISTRATION" || idOrCode === "CANCEL_REGISTRATION" ||
         idOrCode === "VIEW_REGISTRATION" || idOrCode === "BLOCK_REGISTRATION" ||
         idOrCode === "RESCHEDULE_REGISTRATION" || idOrCode === "RESEND_REGISTRATION"){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          REGISTRATION_LIST: true,
          CV_VISITOR: e.target.checked,
        });
      }else if(idOrCode === "REGISTRATION_LIST"&& props.permissions['REGISTRATION_LIST']){
        props.setPermissions({
          ...props.permissions,
          REGISTRATION_LIST: false,
          CREATE_REGISTRATION: false,
          CANCEL_REGISTRATION: false,
          VIEW_REGISTRATION: false,
          BLOCK_REGISTRATION: false,
          RESCHEDULE_REGISTRATION: false,
          RESEND_REGISTRATION: false,
          CV_VISITOR: false,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          REGISTRATION_LIST: e.target.checked,
          CV_VISITOR: e.target.checked,
        });
      }
  }

  // ---------------------------------- VISITOR SELFREGISTRATION PERMISSIONS ----------------------------------

  else if (idOrCode === "CREATE_SELF_REGISTRATION") {
      if(props.permissions[idOrCode]===true){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          // CREATE_SELF_REGISTRATION: true,
          CV_VISITOR: e.target.checked,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          CREATE_SELF_REGISTRATION: e.target.checked,
          CV_VISITOR: e.target.checked,
        });
      }
    }
  else if( idOrCode === "CREATE_SELF_REGISTRATION" && props.permissions['CREATE_SELF_REGISTRATION']){
        props.setPermissions({
          ...props.permissions,
          CREATE_SELF_REGISTRATION: false,
          CV_VISITOR: false,
        });
    }

  // ---------------------------------- VISITOR APPROVALS PERMISSIONS -----------------------------------------

  else if (
    ( idOrCode === "INVITATION_APPROVALS" || idOrCode === "REGISTRATION_APPROVALS" ||
      idOrCode === "BLOCKED_REQUEST_APPROVALS_CV")) {

      if(idOrCode === "REGISTRATION_APPROVALS" || idOrCode === "BLOCKED_REQUEST_APPROVALS_CV"){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          INVITATION_APPROVALS: true,
          CV_VISITOR: e.target.checked,
        });
      }else if(idOrCode === "INVITATION_APPROVALS"&& props.permissions['INVITATION_APPROVALS']){
        props.setPermissions({
          ...props.permissions,
          INVITATION_APPROVALS: false,
          REGISTRATION_APPROVALS: false,
          BLOCKED_REQUEST_APPROVALS_CV: false,
          CV_VISITOR: false,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          INVITATION_APPROVALS: e.target.checked,
          CV_VISITOR: e.target.checked,
        });
      }
  }

  // ---------------------------------- BLOCKED VISITORS PERMISSIONS ------------------------------------------

  else if (
    ( idOrCode === "BLOCKED_VISITORS_LIST" || idOrCode === "VIEW_BLOCKED_VISITORS" ||
      idOrCode === "UNBLOCK_VISITORS"
    )
  ) {
      if(idOrCode === "VIEW_BLOCKED_VISITORS" || idOrCode === "UNBLOCK_VISITORS"){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          BLOCKED_VISITORS_LIST: true,
          CV_VISITOR: e.target.checked,
        });
      }else if(idOrCode === "BLOCKED_VISITORS_LIST"&& props.permissions['BLOCKED_VISITORS_LIST']){
        props.setPermissions({
          ...props.permissions,
          BLOCKED_VISITORS_LIST: false,
          VIEW_BLOCKED_VISITORS: false,
          UNBLOCK_VISITORS: false,
          CV_VISITOR: false,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          BLOCKED_VISITORS_LIST: e.target.checked,
          CV_VISITOR: e.target.checked,
        });
      }
  }

  // ---------------------------------- VISITOR LOGS PERMISSIONS ----------------------------------------------

  else if ((idOrCode === "VISITOR_LOG_LIST" || idOrCode === "VIEW_VISITOR_LOG" || idOrCode === "BLOCK_VISITOR_LOG")) {
    if(idOrCode === "VIEW_VISITOR_LOG" || idOrCode === "BLOCK_VISITOR_LOG"){
      props.setPermissions({
        ...props.permissions,
        [idOrCode]: e.target.checked,
        VISITOR_LOG_LIST: true,
        CV_VISITOR: e.target.checked,
      });
    }else if(idOrCode === "VISITOR_LOG_LIST"&& props.permissions['VISITOR_LOG_LIST']){
      props.setPermissions({
        ...props.permissions,
        VISITOR_LOG_LIST: false,
        VIEW_VISITOR_LOG: false,
        BLOCK_VISITOR_LOG: false,
        CV_VISITOR: false,
      });
    }
    else{
      props.setPermissions({
        ...props.permissions,
        [idOrCode]: e.target.checked,
        VISITOR_LOG_LIST: e.target.checked,
        CV_VISITOR: e.target.checked,
      });
    }
}

// ---------------------------------- DEVICE REPORTS(EVENTS) PERMISSIONS ------------------------------------  

  else if(idOrCode === "EVENTS_LIST") {
        if(props.permissions[idOrCode]===true){
              props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              // EVENTS_LIST: true,
              CV_REPORTS: e.target.checked,
            });
          }
        else{
              props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              EVENTS_LIST: e.target.checked,
              CV_REPORTS: e.target.checked,
            });
          }
      }
    else if( idOrCode === "EVENTS_LIST" && props.permissions['EVENTS_LIST']){
              props.setPermissions({
              ...props.permissions,
              EVENTS_LIST: false,
              CV_REPORTS: false,
          });
      }

// ---------------------------------- SETTINGS PERMISSIONS --------------------------------------------------    

  // ---------------------------------- TIMESLOTS(SETTINGS) PERMISSIONS ---------------------------------------

 if ((idOrCode === "CV_SETTINGS" || idOrCode === "TIMES_LOTS_LIST" || idOrCode === "CREATE_UPDATE_DELETE_TIMES_LOTS") &&
            idOrCode !== "CV_SETTINGS") {

    if ((idOrCode === "TIMES_LOTS_LIST" ||
      idOrCode === "CREATE_UPDATE_DELETE_TIMES_LOTS")) {

      if(idOrCode === "CREATE_UPDATE_DELETE_TIMES_LOTS"){
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          TIMES_LOTS_LIST: true,
          CV_SETTINGS: e.target.checked,
        });
      }else if(idOrCode === "TIMES_LOTS_LIST"&& props.permissions['TIMES_LOTS_LIST']){
        props.setPermissions({
          ...props.permissions,
          CREATE_UPDATE_DELETE_TIMES_LOTS: false,
          TIMES_LOTS_LIST: false,
          CV_SETTINGS: false,
        });
      }
      else{
        props.setPermissions({
          ...props.permissions,
          [idOrCode]: e.target.checked,
          TIMES_LOTS_LIST: e.target.checked,
          CV_SETTINGS: e.target.checked,
        });
      }
    } 
  }

  // ---------------------------------- VISITING_PURPOSE(SETTINGS) PERMISSIONS --------------------------------

  else if ((idOrCode === "VISITING_PURPOSE_LIST" || idOrCode === "VIEW_CV_VISITING_PURPOSE" ||
                idOrCode === "CREATE_EDIT_VISITING_PURPOSE" || idOrCode === "PURPOSE_ACCESS_LEVEL_VISITING_PURPOSE"))
       {
          if(idOrCode === "VIEW_CV_VISITING_PURPOSE" || idOrCode === "CREATE_EDIT_VISITING_PURPOSE" ||
             idOrCode === "PURPOSE_ACCESS_LEVEL_VISITING_PURPOSE"){
            props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              VISITING_PURPOSE_LIST: true,
              CV_SETTINGS: e.target.checked,
            });
          }else if(idOrCode === "VISITING_PURPOSE_LIST"&& props.permissions['VISITING_PURPOSE_LIST']){
            props.setPermissions({
              ...props.permissions,
              VISITING_PURPOSE_LIST: false,
              VIEW_CV_VISITING_PURPOSE: false,
              CREATE_EDIT_VISITING_PURPOSE: false,
              PURPOSE_ACCESS_LEVEL_VISITING_PURPOSE: false,
              CV_SETTINGS: false,
            });
          }
          else{
            props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              VISITING_PURPOSE_LIST: e.target.checked,
              CV_SETTINGS: e.target.checked,
            });
          }
      }

  // ---------------------------------- VISITING_ACCESS_LEVEL(SETTINGS) PERMISSIONS ---------------------------

       else if ((idOrCode === "VISITING_ACCESS_LEVEL_LIST" || idOrCode === "MANAGE_CV_VISITING_ACCESS_LEVEL" ||
                idOrCode === "CREATE_EDIT_DELETE_VISITING_ACCESS_LEVEL"))
       {
          if(idOrCode === "MANAGE_CV_VISITING_ACCESS_LEVEL" || idOrCode === "CREATE_EDIT_DELETE_VISITING_ACCESS_LEVEL"){
              props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              VISITING_ACCESS_LEVEL_LIST: true,
              CV_SETTINGS: e.target.checked,
            });
          }else if(idOrCode === "VISITING_ACCESS_LEVEL_LIST"&& props.permissions['VISITING_ACCESS_LEVEL_LIST']){
            props.setPermissions({
              ...props.permissions,
              VISITING_ACCESS_LEVEL_LIST: false,
              MANAGE_CV_VISITING_ACCESS_LEVEL: false,
              CREATE_EDIT_DELETE_VISITING_ACCESS_LEVEL: false,
              CV_SETTINGS: false,
            });
          }
          else{
            props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              VISITING_ACCESS_LEVEL_LIST: e.target.checked,
              CV_SETTINGS: e.target.checked,
            });
          }
      }

  // ---------------------------------- RESTRICT_TYPE(SETTINGS) PERMISSIONS -----------------------------------
 
   else if ((idOrCode === "RESTRICT_TYPE_LIST" || idOrCode === "VIEW_CV_RESTRICT_TYPE" ||
                idOrCode === "CREATE_EDIT_RESTRICT_TYPE"))
        {
          if(idOrCode === "VIEW_CV_RESTRICT_TYPE" || idOrCode === "CREATE_EDIT_RESTRICT_TYPE"){
              props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              RESTRICT_TYPE_LIST: true,
              CV_SETTINGS: e.target.checked,
            });
          }else if(idOrCode === "RESTRICT_TYPE_LIST"&& props.permissions['RESTRICT_TYPE_LIST']){
            props.setPermissions({
              ...props.permissions,
              RESTRICT_TYPE_LIST: false,
              VIEW_CV_RESTRICT_TYPE: false,
              CREATE_EDIT_RESTRICT_TYPE: false,
              CV_SETTINGS: false,
            });
          }
          else{
            props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              RESTRICT_TYPE_LIST: e.target.checked,
              CV_SETTINGS: e.target.checked,
            });
          }
      }

  // ---------------------------------- CHECKLIST(SETTINGS) PERMISSIONS ---------------------------------------

   else if ((idOrCode === "CHECKLIST_LIST" || idOrCode === "VIEW_CV_CHECKLIST" ||
                idOrCode === "CREATE_EDIT_CHECKLIST" || idOrCode === "CHECKITEMS_CV_CHECKLIST"))
       {
          if(idOrCode === "VIEW_CV_CHECKLIST" || idOrCode === "CREATE_EDIT_CHECKLIST"){
              props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              CHECKLIST_LIST: true,
              CV_SETTINGS: e.target.checked,
            });
          }else if(idOrCode === "CHECKLIST_LIST"&& props.permissions['CHECKLIST_LIST']){
            props.setPermissions({
              ...props.permissions,
              CHECKLIST_LIST: false,
              VIEW_CV_CHECKLIST: false,
              CREATE_EDIT_CHECKLIST: false,
              CHECKITEMS_CV_CHECKLIST: false,
              CV_SETTINGS: false,
            });
          }
          else{
            props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              CHECKLIST_LIST: e.target.checked,
              CV_SETTINGS: e.target.checked,
            });
          }
      }

  // ---------------------------------- WATCHLIST(SETTINGS) PERMISSIONS ---------------------------------------

    else if ((idOrCode === "WATCHLIST_LIST" || idOrCode === "VIEW_CV_WATCHLIST" ||
                idOrCode === "CREATE_EDIT_DELETE_WATCHLIST"))
       {
          if(idOrCode === "VIEW_CV_WATCHLIST" || idOrCode === "CREATE_EDIT_DELETE_WATCHLIST"){
              props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              WATCHLIST_LIST: true,
              CV_SETTINGS: e.target.checked,
            });
          }else if(idOrCode === "WATCHLIST_LIST"&& props.permissions['WATCHLIST_LIST']){
            props.setPermissions({
              ...props.permissions,
              WATCHLIST_LIST: false,
              VIEW_CV_WATCHLIST: false,
              CREATE_EDIT_DELETE_WATCHLIST: false,
              CV_SETTINGS: false,
            });
          }
          else{
            props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              WATCHLIST_LIST: e.target.checked,
              CV_SETTINGS: e.target.checked,
            });
          }
      }

  // ---------------------------------- URGENT_MESSAGES(SETTINGS) PERMISSIONS ---------------------------------

    else if ((idOrCode === "VIEW_URGENT_MESSAGES" || idOrCode === "CREATE_URGENT_MESSAGES"))
       {
          if(idOrCode === "CREATE_URGENT_MESSAGES"){
              props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              VIEW_URGENT_MESSAGES: true,
              CV_SETTINGS: e.target.checked,
            });
          }else if(idOrCode === "VIEW_URGENT_MESSAGES"&& props.permissions['VIEW_URGENT_MESSAGES']){
            props.setPermissions({
              ...props.permissions,
              VIEW_URGENT_MESSAGES: false,
              CREATE_URGENT_MESSAGES: false,
              CV_SETTINGS: false,
            });
          }
          else{
            props.setPermissions({
              ...props.permissions,
              [idOrCode]: e.target.checked,
              VIEW_URGENT_MESSAGES: e.target.checked,
              CV_SETTINGS: e.target.checked,
            });
          }
      }
};


  const checkPermissionByCodeorId = (idOrCode) => {
    // console.warn(idOrCode + " : " + props.permissions[idOrCode]);
  
  if (props.role === "ORGANIZATION OWNER"||props.role === "SUPERADMIN") return true;

// ---------------------------------- DASHBOARD PERMISSIONS -------------------------------------------------

    if (idOrCode === "VIEW_CV_DASHBOARDS") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["VIEW_CV_DASHBOARDS"] 
      ) {
        return true;
      } else {
        return false;
      }
    }

// ---------------------------------- PERSON AND ROLE PERMISSIONS -------------------------------------------
    
    if (idOrCode === "CV_VIEW_PERSONLISTS" || idOrCode === "CV_VIEW_PERMISSION_LIST") {
      if (props.permissions[idOrCode] || props.permissions["CV_VIEW_PERSONPROFILE"] && idOrCode !== "CV_VIEW_PERMISSION_LIST") {
        return true;
      }
      else if (props.permissions[idOrCode] || props.permissions["CV_VIEW_PERMISSIONS"] && idOrCode !== "CV_VIEW_PERSONLISTS") {
        return true;
      }
      else {
        return false;
      }
    }

// ---------------------------------- SITE MANAGEMENT PERMISSIONS -------------------------------------------

    if (idOrCode === "CV_VIEW_SITELIST" || idOrCode === "CV_VIEW_ZONE_LIST") {
      if (props.permissions[idOrCode] || props.permissions["CV_VIEW_SITE"] && idOrCode !== "CV_VIEW_ZONE_LIST") {
        return true;
      }
      else if (props.permissions[idOrCode] || props.permissions["CV_VIEW_ZONE"] && idOrCode !== "CV_VIEW_SITELIST") {
        return true;
      }
      else {
        return false;
      }
    }

// ---------------------------------- DEVICE MANAGEMENT PERMISSIONS -----------------------------------------

    if (idOrCode === "DEVICE_LISTS") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["VIEW_CV_DEVICES"] ||
        props.permissions["CREATE_REBOOT_DELETE_ENBLE_DEVICES"]
      ) {
        return true;
      } else {
        return false;
      }
    }

// ---------------------------------- VISITOR MANAGEMENT PERMISSIONS ----------------------------------------

  // ---------------------------------- VISITOR FLOWCHART PERMISSIONS -------------------------------------

    if (idOrCode === "VISITING_FLOWCHART") {
      if (
          props.permissions[idOrCode] ||
          props.permissions["VISITING_FLOWCHART"] 
  
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- VISITOR INVITATION PERMISSIONS ------------------------------------

    if (idOrCode === "INVITATION_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["CREATE_INVITATION"] ||
        props.permissions["VIEW_INVITATION"] ||
        props.permissions["CANCEL_INVITATION"] ||
        props.permissions["BLOCK_INVITATION"] ||
        props.permissions["RESCHEDULE_INVITATION"] ||
        props.permissions["RESEND_INVITATION"]
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- VISITOR REGISTRATION PERMISSIONS ----------------------------------

    if (idOrCode === "REGISTRATION_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["CREATE_REGISTRATION"] ||
        props.permissions["VIEW_REGISTRATION"] ||
        props.permissions["CANCEL_REGISTRATION"] ||
        props.permissions["BLOCK_REGISTRATION"] ||
        props.permissions["RESCHEDULE_REGISTRATION"] ||
        props.permissions["RESEND_REGISTRATION"]
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- VISITOR SELFREGISTRATION PERMISSIONS ------------------------------

    if (idOrCode === "CREATE_SELF_REGISTRATION") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["CREATE_SELF_REGISTRATION"] 
      ) {
        return true;
      } else {
        return false;
      }
    }

  // ---------------------------------- VISITOR APPROVALS PERMISSIONS -------------------------------------
    
    if (idOrCode === "INVITATION_APPROVALS") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["REGISTRATION_APPROVALS"] ||
        props.permissions["BLOCKED_REQUEST_APPROVALS_CV"]
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- BLOCKED VISITORS PERMISSIONS --------------------------------------

    if (idOrCode === "BLOCKED_VISITORS_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["VIEW_BLOCKED_VISITORS"] ||
        props.permissions["UNBLOCK_VISITORS"]
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- VISITOR LOGS PERMISSIONS ------------------------------------------

    if (idOrCode === "VISITOR_LOG_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["VIEW_VISITOR_LOG"] ||
        props.permissions["BLOCK_VISITOR_LOG"]
      ) {
        return true;
      } else {
        return false;
      }
    }

// ---------------------------------- DEVICE REPORTS(EVENTS) PERMISSIONS ------------------------------------

    if (idOrCode === "EVENTS_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["EVENTS_LIST"]
      ) {
        return true;
      } else {
        return false;
      }
    }

// ---------------------------------- SETTINGS PERMISSIONS --------------------------------------------------

  // ---------------------------------- TIMESLOTS(SETTINGS) PERMISSIONS -----------------------------------

    if (idOrCode === "TIMES_LOTS_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["CREATE_UPDATE_DELETE_TIMES_LOTS"]
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- VISITING_PURPOSE(SETTINGS) PERMISSIONS ----------------------------

    if (idOrCode === "VISITING_PURPOSE_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["VIEW_CV_VISITING_PURPOSE"] || 
        props.permissions["CREATE_EDIT_VISITING_PURPOSE"] ||
        props.permissions["PURPOSE_ACCESS_LEVEL_VISITING_PURPOSE"]
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- VISITING_ACCESS_LEVEL(SETTINGS) PERMISSIONS -----------------------

    if (idOrCode === "VISITING_ACCESS_LEVEL_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["MANAGE_CV_VISITING_ACCESS_LEVEL"] ||
        props.permissions["CREATE_EDIT_DELETE_VISITING_ACCESS_LEVEL"]
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- MANAGE_VISITING_ACCESS_LEVEL(SETTINGS->DOORS) PERMISSIONS ---------

    // if (idOrCode === "MANAGE_VISITING_ACCESS_LEVEL") {
    //   if (
    //      props.permissions[idOrCode] ||
    //      props.permissions["ADD_DOORS_FOR_CLOUD_VISITORS"] ||
    //      props.permissions["DELETE_DOORS_FOR_CLOUD_VISITORS"] 
    //     ) {
    //      return true;
    //     } else {
    //     return false;
    //   }
    // } 

  // ---------------------------------- RESTRICT_TYPE(SETTINGS) PERMISSIONS -------------------------------

    if (idOrCode === "RESTRICT_TYPE_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["VIEW_CV_RESTRICT_TYPE"] ||
        props.permissions["CREATE_EDIT_RESTRICT_TYPE"]
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- CHECKLIST(SETTINGS) PERMISSIONS -----------------------------------

    if (idOrCode === "CHECKLIST_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["VIEW_CV_CHECKLIST"] ||
        props.permissions["CREATE_EDIT_CHECKLIST"] ||
        props.permissions["CHECKITEMS_CV_CHECKLIST"]
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- CHECKLISTITEMS(SETTINGS->CHECKLIST) PERMISSIONS -------------------

    // if (idOrCode === "CHECKLISTITEMS_LIST") {
    //   if (
    //     props.permissions[idOrCode] ||
    //     props.permissions["ADD_CHECKLISTITEMS"] ||
    //     props.permissions["EDIT_CHECKLISTITEMS"] ||
    //     props.permissions["DELETE_CHECKLISTITEMS"] 
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // } 

  // ---------------------------------- WATCHLIST(SETTINGS) PERMISSIONS -----------------------------------

    if (idOrCode === "WATCHLIST_LIST") {
      if (
        props.permissions[idOrCode] ||
        props.permissions["VIEW_CV_WATCHLIST"] ||
        props.permissions["CREATE_EDIT_DELETE_WATCHLIST"]
      ) {
        return true;
      } else {
        return false;
      }
    } 

  // ---------------------------------- URGENT_MESSAGES(SETTINGS) PERMISSIONS -----------------------------

    if (idOrCode === "VIEW_URGENT_MESSAGES") {
      if (
        props.permissions[idOrCode] || 
        props.permissions["VIEW_URGENT_MESSAGES"] ||
        props.permissions["CREATE_URGENT_MESSAGES"] 
      ) {
        return true;
      } else {
        return false;
      }
    }

    return props.permissions[idOrCode];
  };

  const accordionTree = () =>
    permissionTree.map((e) => {
      return (
        <Grid
          item
          xs={12}
          style={{
            border: "1px solid #EAEBEB",
            width: "100%",
            borderRadius: "8px",
            margin: "10px 0px",
          }}
        >
          <Accordion
            expanded={isExpaded(e.parent.title)}
            onChange={() => {
              setExpanded(e.parent.title);
            }}
          >
            <AccordionSummary
              expandIcon={
                <LeftArrowIcon
                  style={{ transform: "rotate(270deg)", margin: "0px 8px" }}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (expand === e.parent.title) {
                      setExpanded(!expand);
                    } else {
                      setExpanded(e.parent.title);
                    }
                  }}
                />
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: props.disabled ?  "#B8B8B8" : "rgb(54, 201, 109)" } }
                    indeterminate={
                      // props.permissions[e.parent.ids[0]] !==isChecked(e.parent.ids) && props.role !== 'ORGANIZATION OWNER'
                      !isChecked(e.parent.codes) === true &&
                      !defaultRoles.includes(props.role)
                    }
                    disabled={props.disabled}
                    checked={
                      props.role === "ORGANIZATION OWNER"|| props.role === "SUPERADMIN"
                        ? true
                        : props.permissions[e.parent.codes[0]] ||
                        isChecked(e.parent.codes)
                    }
                    onChange={(event) => {
                      handleChange(e.parent.codes, event);
                    }}
                  />
                }
              />
              <InputLabel style={{ paddingTop: "10px", marginRight: "1px" }}>
                {e.parent.title}
              </InputLabel>
            </AccordionSummary>
            <AccordionDetails>
              <Grid ml={4}>
                {e.child.map((c) => {
                  return (
                    <FormControlLabel
                      label={c.title}
                      indeterminate={!isChecked(e.parent.codes) === true}
                      control={
                        <Checkbox
                          style={{ color: props.disabled ? "#B8B8B8" : "rgb(54, 201, 109)" }}
                          disabled={props.disabled}
                          value={props.permissions[c.code]}
                          checked={checkPermissionByCodeorId(c.code)}
                        />
                      }
                      onChange={(e) => {
                        selectPermission(c.code, e);
                        // props.setPermissions({
                        //   ...props.permissions,
                        //   [c.code]: e.target.checked,
                        // });
                      }}
                    />
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });

    const defaultRoles=["SUPERADMIN","ORGANIZATION OWNER"]
  return <Grid style={{ width: "100%" }}>{accordionTree()}</Grid>;
}
