import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import purposeTypesService from '../../services/PurposeTypeGlobalUSA/PurposeTypeGlobalService';


const initialState = [];

export const getPurposeType = createAsyncThunk('getPurposeType/', async (payload) => {
    const res = await purposeTypesService.getPurposeType(payload);
    return res.data;
});

export const createPurposeType = createAsyncThunk('createPurposeType/create', async (payload) => {
    const res = await purposeTypesService.createPurposeType(payload);
    return res.data;
});

export const updatePurposeType = createAsyncThunk('updatePurposeType/update', async (payload) => {
    const res = await purposeTypesService.updatePurposeType(payload);
    return res.data;
});

export const getPurposeTypeById = createAsyncThunk('getPurposeTypeById/', async (payload) => {
    const res = await purposeTypesService.getPurposeTypeById(payload);
    return res.data;
});

const purposeTypesSlice = createSlice({
    name: 'getPurposeType',
    initialState,
    extraReducers: {
        [getPurposeType.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = purposeTypesSlice;
export default reducer;