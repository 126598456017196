
import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable/DataTable";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HeaderToolbar from "../../components/HeaderToolbar";
import Search from "../../components/SearchTab/Search";
import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import EditChecklistIcon from "../Checklist/EditChecklistIcon";
import FilterChecklistTemplate from "../ChecklistTemplateUSA/FilterChecklistTemplate";
import AddVisitorPurposeTypeIcon from "../../assets/union.svg";
import { getPurposeType } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";
import PurposeTypeForm from "./PurposeTypeForm";
import PurposeTypeView from "./PurposeTypeView";
import AccessLevelIconGlobal from "../../assets/USA_Images/AccessLevelIconGlobal";
import { REACT_URL_VISITORACCESSLEVEL } from "../../actions/EndPoints";
import CommonUtil from "../../Util/CommonUtils";
import Toast from "../../components/ToastContainer/CustomToast";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";

export default function PurposeTypeUSA(props) {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [openAccessLevelDialog, setOpenAccessLevelDialog] = useState(false);
  const zlink = localStorage.getItem("redirectFrom");

  const [roles, setRoles] = useState([]);
  const [createVisitingPurpose, setCreateVisitingPurpose] = useState(true);
  const [listVisitingPurpose, setListVisitingPurpose] = useState(true);
  const [viewVisitingPurpose, setViewVisitingPurpose] = useState(true);
  const [editVisitingPurpose, setEditVisitingPurpose] = useState(true);
  const [accessLevelsVisitingPurpose, setAccessLevelsVisitingPurpose] = useState(true);

  const [selectedObject, setSelectedObject] = useState({
    id: "",
    name: "",
    companyId: "",
    description: "",
    templateId: "",
  });

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    name: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    name: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setCreateVisitingPurpose(l.includes("CREATE_EDIT_VISITING_PURPOSE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setEditVisitingPurpose(l.includes("CREATE_EDIT_VISITING_PURPOSE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setViewVisitingPurpose(l.includes("VIEW_CV_VISITING_PURPOSE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setAccessLevelsVisitingPurpose(l.includes("PURPOSE_ACCESS_LEVEL_VISITING_PURPOSE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setListVisitingPurpose(l.includes("VISITING_PURPOSE_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        
        if (l.includes("VISITING_PURPOSE_LIST")) {
          dispatch(getPurposeType(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
              if ((data?.code === "CVAI0000" && data?.data)) {
                setTotalRecords(data?.data?.totalCount);
                setPage(data?.data?.curPage);
                setTotalPages(data?.data?.totalPages);
                setRows(data?.data?.purposeTypes);
              }else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("VISITING_PURPOSE_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);

  const loadData = (filter, paging) => {
    dispatch(getPurposeType(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "CVAI0000" && data?.data)) {
          setTotalRecords(data?.data?.totalCount);
          setPage(data?.data?.curPage);
          setTotalPages(data?.data?.totalPages);
          setRows(data?.data?.purposeTypes);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      name: data.name,
    });
  };

  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog);
    setSelectedObject(params);
  };

  const globalsearch = (searchedVal) => {
    if (!searchedVal) {
      loadData(filter, paging);
      return;
    }
    dispatch(getPurposeType(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "CVAI0000" && data?.data)) {
          const filteredRows = data?.data?.purposeTypes.filter((test) => {
            return (test.name ? test.name.toLowerCase().includes(searchedVal.toLowerCase()) : "")
              || test.description.toLowerCase().includes(searchedVal.toLowerCase()) ||
              (test.templateId !== null ? test.templateId.name : "").toLowerCase().includes(searchedVal.toLowerCase())
          });
          setRows(filteredRows);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
    
  };

  const handleAccessLevel = (params) => {
    setOpenAccessLevelDialog(!openAccessLevelDialog);
    setSelectedObject(params);
    if (!CommonUtil.isEmptyArray(params.accessLevelIds)) {
      history.push({
        pathname: REACT_URL_VISITORACCESSLEVEL,
        state: { data: params },
      });
    } else {
      Toast("No access level Data for " + params.name + " visitor purpose.", "warning");
      history.push({
        pathname: REACT_URL_VISITORACCESSLEVEL,
        state: { data: params },
      });
    }

  };

  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "name",
      headerName: "Purpose",
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.name,
    },

    {
      field: "templateId",
      headerName: "Checklist Template",
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.templateId !== null ? params.row.templateId.name : '',
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.description,
    },

    {
      field: "Actions",
      headerName: "Action",
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [

        (<>
        {viewVisitingPurpose &&
        <GridActionsCellItem
          icon={<PurposeTypeView
          title="Purpose Type Details"
          rowData={params.row} />}
          label="View"
        />}
        </>),

        (<>
        {editVisitingPurpose &&
        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label="Edit"
          onClick={() => handleEditVisit(params.row)}
        />}
        </>),

        (<>
        {accessLevelsVisitingPurpose &&
        <GridActionsCellItem
          icon={<AccessLevelIconGlobal />}
          label="Edit"
          onClick={() => handleAccessLevel(params.row)}
        />}
        </>),

      ],
    },

  ]);

  return (
    <>

    {createVisitingPurpose ?(
      <HeaderToolbar
        src={AddVisitorPurposeTypeIcon}
        onClick={handleOpen}
        title="Visiting Purpose"
        tooltipTitle="Add Purpose Type" 
      />):

      (<HeaderToolbar
        title="Visiting Purpose"
        tooltipTitle="Add Purpose Type" 
       />)
      }

  {listVisitingPurpose &&
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
       
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <FilterChecklistTemplate
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        {zlink ? (
          <>
            <Box item>
              <Tooltip title={"Refresh"}>
                <IconButton onClick={() => loadData(filter, paging)}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Box>
          </>) : (<></>)}

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>

      </Box>}

    {listVisitingPurpose ? (
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
         ):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
             <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                 {t('CVROLE028')}
             </Typography>
           </div>)
    }

      {open && (
        <PurposeTypeForm
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            handleClose(loadData(filter, paging));
          }}
        />
      )}

      {openEditDialog && (
        <PurposeTypeForm
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false);
            loadData(filter, paging);
            setSelectedObject({
              id: "",
              name: "",
              description: "",
              templateId: "",
            });
          }}
        />
      )},




    </>
  )
}




