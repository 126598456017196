import { Tooltip, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Toast from "../../components/ToastContainer/CustomToast";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import CustomPhone from "../../components/CustomInputs/CustomPhone";
import CommonPhoneInput from "../../components/CustomInputs/CommonPhoneInput";
import CommonUtil from "../../Util/CommonUtils";


export default function PersonFilter(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const [payload, setPayload] = useState({
    firstName: "",
    email: "",
    phone: "",
    code: "",
  });

  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');

  const handleClose = () => {
    setPayload({
      firstName: "",
      email: "",
      phone: "",
      code: "",
    });
    setOpen(false);
    setCountryCode("")
    setPhoneNumber("")
  };

  const [error, setError] = useState({
    firstName: false,
    email: false,
    phone: false,
    code: false,
  });

  const reset = () => {
    if (
      payload.firstName.trim() === "" &&
      payload.code.trim() === "" &&
      payload.email.trim() === "" &&
      payload.phone.trim() === "" &&
      countryCode.trim() === ""
    ) {
      Toast(t("Nothing to Reset"), "error");
      return;
    }

    if (countryCode !== "") {
      setCountryCode("")
    }

    setPayload({
      firstName: "",
      email: "",
      phone: "",
      code: "",
    });
    setCountryCode("")
    setPhoneNumber("")
  };

  const handleChange = (event) => {

    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPayload({
      ...payload,
      phone: "+" + data.dialCode + "-" + value.toString().replace(data.dialCode, ""),
    });
  };

  const handleSubmit = () => {
    const updatedPayload = { ...payload, phone: countryCode !== "" ? "+" + countryCode + "-" + payload.phone: payload.phone };
    if (CommonUtil.isEmptyString(countryCode) && !CommonUtil.isEmptyString(phoneNumber)) {
      Toast("Please select Country Code", "error");
      return
    }

    if (
      payload.firstName === "" &&
      payload.code === "" &&
      payload.email === "" &&
      countryCode === "" &&
      payload.phone === "" &&
      phoneNumber === ""
    ) {
      Toast("Please enter filter condition!", "error");
      return;
    }
    
    else {
      props.applyFilter(updatedPayload);
      setPayload({
        code: "",
        email: "",
        firstName: "",
        phone: "",

      });
      setCountryCode("")
      setPhoneNumber("")

    }

    handleClose();
  };


  useEffect(() => { }, []);

  const handlePhoneNumberChange = (value) => {
    var mobile = value;
    setError({
      ...error,
      phone: '',
    });
    if (mobile.length > 25) {
      return false;
    } else {
      setPayload({
        ...payload,
        phone: mobile,
      });
      setPhoneNumber(value);
    }
  };

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            Filter
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
          <FilterIcon />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        Header="Filter"
        acceptText="Apply"
        cancelText="Reset"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={reset}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={11}>
            <CustomTextfield
              label="First Name"
              error={error.firstName}
              name="firstName"
              value={payload.firstName}
              handleChange={(e) => handleChange(e)}
              helperText={error.firstName}
            />
          </Grid>

          <Grid item xs={11}>
            <CustomTextfield
              label="Person ID"
              error={error.code}
              name="code"
              value={payload.code}
              handleChange={(e) => handleChange(e)}
              helperText={error.code}
            />
          </Grid>

          <Grid item xs={11}>
            <CustomTextfield
              label="Email"
              error={error.email}
              name="email"
              value={payload.email}
              handleChange={(e) => handleChange(e)}
              helperText={error.email}
            />
          </Grid>

          <Grid item xs={11}>
            {/* <CustomPhone
                placeholder="Mobile *"
                specialLabel={false}
                handleChange={(value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue)}
              /> */}

            <CommonPhoneInput
              error={error.phone}
              handleCountryCode={(data) => {
                setCountryCode(data.dialCode);
                setError({ ...error, phone: '' })

              }}

              countryCode={countryCode}
              placeholder={t('COMPPROFSCREEN037')}
              phoneNumber={phoneNumber}
              name={'phone'}
              handleChange={(e) => handlePhoneNumberChange(e)}

            />
          </Grid>

        </Grid>
      </DialogBox>
    </>
  );
}
