
import { Cookie } from "@mui/icons-material";
import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter, page, rowsPerPage) => {

  let query = "?isPagable=true&pageNumber=" + page + "&pageSize=" + rowsPerPage;

  if (!CommonUtil.isEmptyString(filter.firstName)) {
    query = query + "&firstName=" + filter.firstName;
  }
  if (!CommonUtil.isEmptyString(filter.lastName)) {
    query = query + "&lastName=" + filter.lastName;
  }
  if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
    query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
  }
  if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
    query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
  }
  if (!CommonUtil.isEmptyString(filter.createdAt)) {
    query = query + "&createdAt=" + filter.createdAt;
  }
  if (!CommonUtil.isEmptyString(filter.email)) {
    query = query + "&email=" + filter.email;
  }
  if (!CommonUtil.isEmptyString(filter.phone)) {
    query = query + "&phone=" + filter.phone;
  }
  if (!CommonUtil.isEmptyString(filter.visitorTypeId)) {
    query = query + "&visitorTypeId=" + filter.visitorTypeId;
  }
  if (!CommonUtil.isEmptyString(filter.purposeTypeId)) {
    query = query + "&purposeTypeId=" + filter.purposeTypeId;
  }
  if (!CommonUtil.isEmptyString(filter.siteId)) {
    query = query + "&siteId=" + filter.siteId;
  }
  if (!CommonUtil.isEmptyString(filter.hostId)) {
    query = query + "&hostId=" + filter.hostId;
  }
  if (!CommonUtil.isEmptyString(filter.blockStatus)) {
    query = query + "&blockStatus=" + filter.blockStatus;
  }
  if (!CommonUtil.isEmptyString(filter.visitMode)) {
    query = query + "&visitMode=" + filter.visitMode;
  }
  if (!CommonUtil.isEmpty(filter.status)) {
    query = query + "&status=" + filter.status;
  }
  if (!CommonUtil.isEmptyString(filter.isRestricted)) {
    query = query + "&isRestricted=" + filter.isRestricted;
  }
  return query;
};

const getAllVisitors = (payload) => {
  return api.securedAxios().get("/web/visit/list" + buildQuery(payload.filter, payload.page, payload.rowsPerPage));
};


const update = (payload) => {
  return api.securedAxios().put("/visitor_selfRegistration/approve", payload);
};

const createSelf = (payload, data, companyId) => {
  const headers = {
    companyId: companyId,
  };

  return api
    .unsecuredAxios()
    .post("/visitor_selfRegistration/create", payload, data, { headers });
};


const rejectVisitorInvitations = (payload) => {
  return api.securedAxios().put('/api/v2.0/visit_registration/reject', payload);
}


const SelfRegistartionService = {
  update,
  createSelf,
  rejectVisitorInvitations,
  getAllVisitors,
};

export default SelfRegistartionService;
