import React, { useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

function Loader() {

    const [loading, setLoading] = React.useState(false);
    const store = useSelector((state) => state);

    useEffect(() => {
        setLoading(store.loader.loader)
    }, [store.loader.loader])

    return (
        <>
            {!loading ? null :
                (
                    <div className='loader-container'>
                        <div className='loader'>
                            <Box sx={{ display: 'flex' }}>
                                <CircularProgress color='success' />
                            </Box>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Loader