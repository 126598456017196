import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as CancelVisitIcon } from "../../../src/assets/CancelVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { cancelVisitorInvite } from "../../slices/Invitations/InvitationsSlice";
import Toast from "../../components/ToastContainer/CustomToast";

export default function CancelRegistration(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [payload, setPayload] = React.useState({
    id: props.CancelId,
    firstName: props.CancelFirstName,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    dispatch(cancelVisitorInvite(payload))
      .unwrap()
      .then((data) => {
        handleClose(false);
        if (data?.code == "CVAI0068") {
          Toast(t("Registration Cancelled Successfully"), 'success');
          handleClose();
        } else {
          Toast(data?.message,"error");
        }
      })
      .catch((er) => {});
  };

  return (
    <>
      <DialogBox
        Header="Cancel Visitor"
        acceptText="Confirm"
        cancelText="Cancel"
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
          style={{ padding: "20px" }}
        >
          <Grid>
            <CancelVisitIcon />
          </Grid>
          <Grid>
            <Grid
              style={{
                wordWrap: "break-word",
                opacity: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                fontSize: "17px",
                marginTop: "15px",
              }}
            >
              <Typography style={{ color: "#242424", marginRight: "4px" }}>
                Are you sure you want to cancel this registration for
              </Typography>
              <Typography style={{ fontWeight: "bold", marginRight: "4px" }}>
                {payload.firstName}
              </Typography>
              <Typography style={{ color: "#242424", marginRight: "4px" }}>?</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
