import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import vis_EmptyLogo from "../../assets/empty_item.svg";
import EmptyLogo from "../../assets/USA_Images/empty-item.svg";
import { Paper, MenuItem, Select } from "@material-ui/core";
import './Datatable.css';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
  datagrid: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: process.env.REACT_APP_BG_TABLE_HEAD,
      color: process.env.REACT_APP_COLOR_TABLE_HEAD,
    },
    "& .MuiCheckbox-root": {
      color: process.env.REACT_APP_BG_ICON,
    },
    "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked": {
      color: process.env.REACT_APP_BG_ICON,
    },
    '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
      color: process.env.REACT_APP_BG_TABLESORT
    },
  },
}));

function DataTable(props) {
  const classes = useStyles();
  const { handleChange } = props;
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRowChange = () => {
    var e = document.getElementById("pagesize");
    handleChange(1, e.value);
    setRowsPerPage(e.value);
  };

  const handlePageChange = (event, value) => {
    handleChange(value, rowsPerPage);
    setPage(value);
  };

  return (
    <>
      <Paper>
      <div
        style={{
          height: 370,
          width: "100%",
          textAlign: "center",
          boxShadow: "0px 3px 8px #00000029",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          borderRadius: "10px",
          opacity: 1,
        }}
      >
        {props?.rows?.length > 0 ? null : (
          <img alt="no record found"
            src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  EmptyLogo : vis_EmptyLogo }
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          />
        )}

        {props?.rows?.length > 0 ? null : (
          <span
            style={{
              color: "#3D4977",
              opacity: "0.5",
              position: "absolute",
              paddingTop: 160,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
          >
            No Records Found
          </span>
        )}

        <DataGrid className={classes?.datagrid}
          rows={props?.rows}
          columns={props?.columns}
          pageSize={rowsPerPage}
          checkboxSelection={props?.checkboxSelection}
          onSelectionModelChange={(ids) => { props?.onSelectionModelChange(ids) }}
          disableColumnMenu
          disableSelectionOnClick
          hideFooterPagination
          hideFooter={props?.rows?.length > 0 ? false : true}
          getRowId={props?.rowId}
          defaultPage={1}
          sx={[
            () => ({
              "& .MuiDataGrid-iconSeparator": {
                display: "none",
              },
            }),
            () => ({
              "& .MuiDataGrid-overlay": {
                display: "none",
              },
            }),
            () => ({
              "& .MuiDataGrid-main": {
                borderRadius: "7px",
              },
            }),
          ]}
        />

        <>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              position: "relative",
              bottom: 40,
              justifyContent: "flex-end",
            }}
          >
            {props?.rows?.length > 0 ? (
              <>
                <label
                  for="records"
                  style={{ paddingRight: 5, paddingTop: 3.5 }}
                >
                  Per page{" "}
                </label>
                <select
                  id="pagesize"
                  style={{
                    padding: 3,
                    paddingBottom: 5,
                    paddingTop: 5,
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    border: "1px solid #CCCCCC",
                    borderRadius: "3px",
                    opacity: 1,
                    backgroundColor: '#fff'
                  }}
                  onChange={handleRowChange}
                  value={rowsPerPage}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                </select>
                <p style={{ paddingTop: 3.5 }}> Records</p>
              </>
            ) : null}
            <Pagination
              sx={[
                () => ({
                  button: {
                    backgroundColor: "#F2F2F2",
                    color: '#637381',
                  },
                }),
                () => ({
                  ul: {
                    "& .Mui-selected": {
                      backgroundColor: process.env.REACT_APP_PAGINATION,
                      color: 'white',
                    },
                  },
                }),
                () => ({
                  ul: {
                    "&:hover": {
                      "& .Mui-selected": {
                        //   backgroundColor: "#cdcdcd",
                      },
                    },
                  },
                }),
              ]}
              hidden={props?.rows?.length > 0 ? false : true}
              page={props.page}
              count={props.pageCount}
              onChange={handlePageChange}
            />
          </Stack>
        </>
      </div>
      </Paper>
    </>
  );
}

export default DataTable;

DataTable.defaultProps = {
  columns: [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "firstName",
      headerName: "First name",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "fullName",
      headerName: "Full name",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last name",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
    {
      field: "age",
      headerName: "Age",
      flex: 1,
      minWidth: 150,
      editable: false,
    },
  ],

  rows: [],
  checkboxSelection: false,
  pageCount: 10,
};
