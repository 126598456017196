import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import HomeGreen from "../../assets/USA_Images/user_profile/Home-blue.svg";
import vis_HomeGreen from "../../assets/HomeGreen.svg";
import HomeWhite from "../../assets/USA_Images/user_profile/Home-orange.svg";
import vis_HomeWhite from "../../assets/Home-white.svg";
import Language from "../../assets/USA_Images/user_profile/Language-blue.svg";
import vis_Language from "../../assets/LanguageGreen.svg";
import LanguageWhite from "../../assets/USA_Images/user_profile/Language-orange.svg";
import vis_LanguageWhite from "../../assets/LanguageWhite.svg";
import CompanyGreen from "../../assets/USA_Images/user_profile/company profile-blue.svg";
import vis_CompanyGreen from "../../assets/CompanyGreen.svg";
import Person from "../../assets/USA_Images/dashboard_icons/profile.svg";
import vis_Person from "../../assets/images/onboard/person_vis.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Card, CardContent, Grid, Link, Typography } from "@mui/material";
import "../HeaderProfileMenu/HeaderProfileMenu.css";
import Button from '@mui/material/Button';
import Stack from "@mui/material/Stack";
import CompanyBackgroundImg from "../../assets/CompanyBackgroundImg.svg";
import PersonWhite from "../../assets/USA_Images/user_profile/Profile-orange.svg";
import vis_PersonWhite from "../../assets/Person.svg";
import ProfileGreen from "../../assets/USA_Images/user_profile/Profile-blue.svg";
import vis_ProfileGreen from "../../assets/ProfileGreen.svg";
import CompanyWhite from "../../assets/USA_Images/user_profile/company profile-orange.svg";
import vis_CompanyWhite from "../../assets/Company-white.svg";

import HoverImage from "./HoverImage";
import SelectLanguageMenu from "./SelectLanguageMenu";
import {
  REACT_URL_COMPANYRPROFILE,
  REACT_URL_DASHBOARD,
  REACT_URL_USERPROFILE,
} from "../../actions/EndPoints";
import { getUserInfo, getUserById } from "../../slices/User/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { revokeUser } from "../../slices/onboard/LoginSlice";
import { useHistory } from "react-router-dom";
import configureStore from '../../config/configureStore'
import api from "../../config/http-common";

function HeaderProfileMenu() {
  const dispatch = useDispatch();
  const history = useHistory();
  const store = useSelector((state) => state);

  const [anchorEl, setAnchorEl] = useState(null);
  const [picture, setPicture] = useState("");
  const [name, setName] = useState("");
  const zlink = localStorage.getItem("redirectFrom");
  const [lastName, setLastName] = useState("");
  const [userPicture, setUserPicture] = useState(null);


  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    loadData();
  }, [store.profile.profileUpdated]);

  // useEffect(() => {
  //   dispatch(getUserById(decoded_jwtToken.userId)).unwrap().then((res) => {
  //     if(res.code === "UASI0033"){
  //       setUserPicture(res.data)
  //     }
  //   })

  // }, [])

  // const loadData = () => {
  //   dispatch(getUserInfo(decoded_jwtToken.userId))
  //     .unwrap()
  //     .then((data) => {

  //       if ((data.code = "UASI0000" && data.data)) {
  //         setPicture(data.data.profilePhoto);
  //         setName(data.data.firstName);
  //         setLastName(data.data.lastName);

  //       }
  //     })
  //     .catch((er) => { });
  // }

  const [user, setUser] = useState(null);
  
  useEffect(() => {
   let userdetail=api.decoded();
    if (userdetail) {
      dispatch(getUserById(userdetail.userId)).then((res) => {
        if(res.payload.code === 'UASI0033'){
          setUser(res.payload.data);}
      });
    }
  }, []);


  const loadData = () => {
    dispatch(getUserInfo(api.decoded().userId))
      .unwrap()
      .then((data) => {
        if ((data.code = "UASI0000" && data.data)) {
          setPicture(data.data.profilePhoto);
          setName(data.data.firstName);
          setLastName(data.data.lastName);
        }
      })
      .catch((er) => { });
  }

  const handleLogout = () => {
    history.push("/");
    dispatch(revokeUser());
    localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
    localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN);
    localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
    localStorage.clear();
    history.replace("/vis/login");
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "revert", alignItems: "end", textAlign: "right" }}>
        <IconButton
          onMouseEnter={handleClick}
          size="small"
          sx={{ ml: 2, mr: 5 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar
            // src={picture !== "" ? "data:image/jpeg;base64," + picture : process.env.REACT_APP_ENVIRONMENT === 'USA' ? Person : vis_Person}
            // src={userPicture?.imagePreSignedURL}
            src={user?.imagePreSignedURL}
            sx={[
              picture === ""
                ? {
                  width: 40,
                  height: 40,
                  background: process.env.REACT_APP_BG_AVATAR,
                  padding: "10px",
                }
                : "",
              open && {
                background: process.env.REACT_APP_BG_AVATAR,
                opacity: 0.8,
              },
              (theme) => ({
                "&:hover": {
                  background: process.env.REACT_APP_BG_AVATAR,
                  opacity: 0.8
                },
              }),
            ]}
          ></Avatar>
          <div style={{ padding: "5px" }}>
            <Typography variant="h5">
              {name}  {lastName}
            </Typography>
          </div>
          {!zlink && (
          <KeyboardArrowDownIcon
            style={{ paddingTop: "2.5px", color: process.env.REACT_APP_ICON }}
          />)}
        </IconButton>
      </Box>
      {!zlink && (
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        style={{
          border: "0.5px solid lightgray",
          borderRadius: "5px",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            width: '300px !important',
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
            },

            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: process.env.REACT_APP_BG_HEADER_LOGO,
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div onMouseLeave={() => { setAnchorEl(null); }}>
          <Card
            sx={{
              bgcolor: process.env.REACT_APP_BG_HEADER_LOGO,
              backgroundImage: `url(${CompanyBackgroundImg})`,
              backgroundPosition: "bottom right",
              backgroundRepeat: "no-repeat",
              opacity: 0.8,
              position: "relative",
              bottom: 9,
            }}
          >
            <CardContent>
              <Grid container spacing={1.5} textAlign="left" p={1.25}>
                <Grid item xs={12}>
                  <div style={{ color: "#FFFFFF" }}>
                    {/* {decoded_jwtToken.firstName + " " + decoded_jwtToken.lastName} */}
                    {name} {lastName}
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ color: "#FFFFFF" }}>{decoded_jwtToken.email}</div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <>
            <Stack
              spacing={2}
              direction="column"
              PaperProps={{
                elevation: 10,
                sx: {
                  opacity: 3,
                  bgcolor: "#FFFFFF",
                  position: "relative",
                },
              }}
            >
              {!zlink && (
                <HoverImage
                  normalImage={process.env.REACT_APP_ENVIRONMENT === 'USA' ? HomeGreen : vis_HomeGreen}
                  hoverImage={process.env.REACT_APP_ENVIRONMENT === 'USA' ? HomeWhite : vis_HomeWhite}
                  alt="Home"
                  item="Home"
                  link={REACT_URL_DASHBOARD}
                />
              )}
              {!zlink && (
                <HoverImage
                  normalImage={process.env.REACT_APP_ENVIRONMENT === 'USA' ? ProfileGreen : vis_ProfileGreen}
                  hoverImage={process.env.REACT_APP_ENVIRONMENT === 'USA' ? PersonWhite : vis_PersonWhite}
                  alt="Profile"
                  item="Profile"
                  link={REACT_URL_USERPROFILE}
                />
              )}
              {!zlink && (
              <SelectLanguageMenu
                handleClose={() => setAnchorEl(null)}
                normalImage={process.env.REACT_APP_ENVIRONMENT === 'USA' ? Language : vis_Language}
                hoverImage={process.env.REACT_APP_ENVIRONMENT === 'USA' ? LanguageWhite : vis_LanguageWhite}
                alt="Language"
                item="Language Change"
              />
              )}
              {!zlink && (
                <HoverImage
                  normalImage={process.env.REACT_APP_ENVIRONMENT === 'USA' ? CompanyGreen : vis_CompanyGreen}
                  hoverImage={process.env.REACT_APP_ENVIRONMENT === 'USA' ? CompanyWhite : vis_CompanyWhite}
                  alt="Company"
                  item="Company Profile"
                  link={REACT_URL_COMPANYRPROFILE}
                />
              )}

              {!zlink && (
                <Button
                  variant="outlined"
                  onClick={handleLogout}
                  sx={[
                    {
                      maxInlineSize: "fit-content",
                      position: "relative",
                      left: 95,
                    },
                    // (theme) => ({
                    //   "&:hover": {
                    //     background: "transparent",
                    //   },
                    // }),
                  ]}
                >
                  Logout
                </Button>
              )}
            </Stack>
          </>
        </div>
      </Menu>
      )}
    </React.Fragment>
  );
}

export default HeaderProfileMenu;

