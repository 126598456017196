import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DeviceService from '../../services/Zlink Device Service/DeviceService';

const initialState = [];

export const findDeviceBySn = createAsyncThunk('device/preRegister', async (payload) => {
    const res = await DeviceService.findDeviceBySn(payload);
    return res.data;
});
export const bindDevice = createAsyncThunk('device/bind', async (payload) => {
    const res = await DeviceService.bindDevice(payload);
    return res.data;
});
export const listOfDevice = createAsyncThunk('device/list', async (payload) => {
    const res = await DeviceService.listOfDevice(payload);
    return res.data;
});
export const updateDevice = createAsyncThunk('device/update', async (payload) => {
    const res = await DeviceService.updateDevice(payload);
    return res.data;
});

export const rebootDevice = createAsyncThunk('device/reboot', async (payload) => {
    const res = await DeviceService.rebootDevice(payload);
    return res.data;
});

export const deleteDevice = createAsyncThunk('device/reboot', async (payload) => {
    const res = await DeviceService.deleteDevice(payload);
    return res.data;
});

export const enableDisableDevice = createAsyncThunk('device/enable', async (payload) => {
    const res = await DeviceService.enableDisableDevice(payload)
    return res.data
})



const DeviceSlice = createSlice({
    name: 'device',
    initialState,
    extraReducers: {

    }
})


const { reducer } = DeviceSlice;
export default reducer;