import { Box, IconButton, Link, Tooltip } from "@mui/material";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import DataTable from "../../../components/DataTable/DataTable";
import AddSiteIcon from "../../../assets/AddSiteIcon.svg";
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import HeaderToolbar from "../../../components/HeaderToolbar";
import {
  deleteSite,
  getAllSites,
  getSitesList,
} from "../../../slices/Site/SiteSlice";
import AddSite from "./AddSite";
import EditWatchlistIcon from "../../Watchlist/EditWatchlistIcon";
import DeleteIcon from "../../Watchlist/DeleteIcon";
import SiteViewDialog from "./SiteViewDialog";
import Toast from "../../../components/ToastContainer/CustomToast";
import SiteFilter from "./SiteFilter";
import Search from "../../../components/SearchTab/Search";
import DeleteDialog from "../../../components/DialogBoxComponent/DeleteDialog";
import { useTranslation } from "react-i18next";
import ZlinkSiteView from "./ZlinkSiteView";
import { ReactComponent as Refresh } from "../../../assets/DeviceIcon/Refresh.svg";
import { getPermissionsListByroleId } from "../../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../../config/http-common";

function Site() {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [updateObject, setUpdateObject] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [clear, setClear] = useState(false);
  const [searchedRows, setSerchedRows] = useState([]);
  const [controlRendering, setControlRendering] = useState(false);
  const { t } = useTranslation();
  const zlink = localStorage.getItem("redirectFrom");

  const [roles, setRoles] = useState([]);
  const [viewSite, setViewSite] = useState(true);
  const [listSite, setListSite] = useState(true);

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });
  const [filter, setFilter] = useState({
    name: "",
    parentId: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    name: "",
    parentId: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(filter, paging);
      return;
    }

    dispatch(getAllSites(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "LMSI0000" && data.data)) {
          
          const filteredRows = data?.data.site.filter((test) => {
            return (
              (test.name
                ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
                : "") ||
              (test.parentName
                ? test.parentName.toLowerCase().includes(searchedVal.toLowerCase())
                : "") ||
              (test.description
                ? test.description.includes(searchedVal.toLowerCase())
                : "")
            );
          });
          setRows(filteredRows);
        } else {
          setRows([]);
        }
      })
      .catch((er) => {});

    
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging]);

  const handleOpen = () => {
    setOpenEditDialog(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const deleteIds = (id) => {
    dispatch(deleteSite(id))
      .unwrap()
      .then((data) => {
        if (data?.code === "LMSI0015") {
          Toast(data.message, "success");
          loadData(filter, paging);
        } else Toast(data.data.error[0].message, "error");
      })
      .catch((er) => {});
  };

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setViewSite(l.includes("CV_VIEW_SITE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setListSite(l.includes("CV_VIEW_SITELIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("CV_VIEW_SITELIST")) {
          dispatch(getAllSites(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
              if ((data?.code === "LMSI0000" && data.data)) {
                setRows(data.data.site);
                setPage(data?.data?.currentPage);
                setTotalPages(data?.data?.totalPages);
              } else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("CV_VIEW_SITELIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);
       
  const loadData = (filter, paging) => {
    dispatch(getAllSites(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "LMSI0000" && data.data)) {
          setRows(data.data.site);
          setPage(data?.data?.currentPage);
          setTotalPages(data?.data?.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => {});
  };

  const handleEditSite = (rowData) => {
    setUpdateObject(rowData);
    setOpenEditDialog(true);
  };

  const handleControlRendering = () => {
    setControlRendering(true);
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging(defaultPaging);
    setFilter({
      name: data.name,
      parentId: data.parentId,
    });
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
        return text.substring(0, 17) + '...';
    } else {
    return text;
   }
 }

  const columns = React.useMemo(
    () => [
      {
        headerName: "",
        sortable: false,
        width: 5,
        renderCell: (params) => {
          return <div></div>;
        },
      },
      {
        field: "name",
        headerName: t("COMMON044"),
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.name,
        renderCell: (params) => (
          <Tooltip title={` ${ params.row.name}`}>
             <span>{handleTextOverflow(params.row.name)}</span>
             </Tooltip>
              ),
      },
      
      !zlink ? ( 
      {
        field: "parentName",
        headerName: t("COMMON050"),
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.parentName,
        renderCell: (params) => (
          <Tooltip title={` ${ params.row.parentName}`}>
             <span>{handleTextOverflow(params.row.parentName)}</span>
             </Tooltip>
              ),
      }
      ) :({}),

      {
        field: "description",
        headerName: t("SITE009"),
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.description,
        renderCell: (params) => (
          <Tooltip title={` ${ params.row.description}`}>
             <span>{handleTextOverflow(params.row.description)}</span>
             </Tooltip>
              ),
      },
      {
        field: "Actions",
        headerName: t("ACTION"),
        type: "actions",
        flex: 1,
        minWidth: 190,
        width: 150,
        headerAlign: "center",
        getActions: (params) => [

          !zlink ? (<>
          <GridActionsCellItem
            icon={<SiteViewDialog data={params.row} />}
            label="View"
          />
          </>):(<>
            {viewSite &&
            <GridActionsCellItem
            icon={<ZlinkSiteView data={params.row} />}
            label="View"
          />}
          </>),


          !zlink ? (
            <>
              <GridActionsCellItem
                icon={<EditWatchlistIcon />}
                label="Edit"
                onClick={() => handleEditSite(params.row)}
              />
              
            </>
          ) : (
            <></>
          ),

          !zlink ? (
            <>
              <GridActionsCellItem
                icon={
                  <DeleteDialog
                    handleDelete={() => {
                      deleteIds(params.row.id);
                    }}
                    // deviceName={params.row.name}
                    text={"Are sure want to delete the"}
                    name={" selected record"}
                  />
                }
                label="Delete"
              />
              
            </>
          ) : (
            <></>
          )
        ],
      },
    ],
    [handleEditSite, deleteIds]
  );

  return (
    <>
      {!zlink ? (
        <>
          <HeaderToolbar
            src={AddSiteIcon}
            onClick={handleOpen}
            title={t("SITE013")}
            tooltipTitle={t("SITE002")}
          />
        </>
      ) : (
        <>
          <HeaderToolbar title="Site" />
        </>
      )}

  {listSite &&
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >

      <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "1",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <SiteFilter
            onSubmit={(data) => {
              handleFilter(data);
            }}
          />
        </Box>

        {zlink ? (
        <>
        <Box item>
        <Tooltip title={"Refresh"}>
              <IconButton onClick={() => loadData(filter, paging)}>
                <Refresh />
              </IconButton>
            </Tooltip>
        </Box>
        </>):(<></>)}

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>

      </Box>}

    {listSite ? (
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
        ): (<div style={{marginTop: '185px', paddingLeft: '300px'}}>
           <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
              {t('CVROLE004')}
           </Typography>
        </div>)
    }

      {openEditDialog && (
        <AddSite
          selectedObject={updateObject}
          open={openEditDialog}
          onClose={() => {
            setOpenEditDialog(false);
            setUpdateObject({});
            loadData(filter, paging);
          }}
        />
      )}
    </>
  );
}

export default Site;
