
import React, { useState } from "react";
// import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import SubmitButtons from "../DialogBoxComponent/SubmitButtons";
import CancelButtons from "../DialogBoxComponent/CancelButtons";
import { Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { ReactComponent as CloseSvg } from "../../../src/assets/Close.svg";
import CloseIcon from "@mui/icons-material/Close";

import { DialogActions } from "@mui/material";
import FilterForm from "./FilterForm";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: "20px",
  },
}));

export default function DialogBox(props) {
  const classes = useStyles();

  const { onSubmit, children, onClose, ...other } = props;

  const handleClose = () => {
    props.onClose(false);
  };
  return (
    <>
      <Dialog classes={{ paper: classes.dialogPaper }} {...props}>
        <DialogTitle variant="h4"onClick={handleClose} >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 15,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {props.Header}
        </DialogTitle>
        <Divider variant="middle" />
        {props.children}
        <DialogActions
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px",
          }}
        >
          <SubmitButtons onClick={onSubmit} type='submit'>
            <Typography variant="body1" style={{ textTransform: "none" }}>
              {props.acceptText}
            </Typography>
          </SubmitButtons>

          <CancelButtons  onClick={() => props.cancel()}>
            <Typography variant="body1" style={{ textTransform: "none" }}>
              {props.cancelText}
            </Typography>
          </CancelButtons>
        </DialogActions>
      </Dialog>
    </>
  );
}
