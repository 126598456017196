import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import "./profileCard.css";
import { Divider, Grid } from "@mui/material";
import ViewIcon from "../../Pages/Invitations/ViewIcon";
import { useDispatch } from "react-redux";
import { listVisitorType } from "../../slices/VisitTypeSlice";
import { useTranslation } from "react-i18next";
import { getPurposeType, getPurposeTypeById } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    overflowY: "clip",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    overflowY: "visible",
    borderRadius: "20px",
    minWidth: "1000px",
    height: "440px",
    opacity: 1,
  },
  '& .MuiDialogContent-root ': {
    padding: '16px 24px',
    position: ' relative',
    bottom: '80px',
  }
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function WatchlistViewProfile(props) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [purposeName, setPurposeName] = useState();
  const rowDataMode = props.rowData.registrationMode;
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadPurposeTypeData();
  }, [props.rowData.purposeTypeId]);

  const loadPurposeTypeData = () => {
    const payload = {
      id: props.rowData.purposeTypeId,
    };
    dispatch(getPurposeTypeById(payload))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAE0095") {
          setPurposeName(data.data);
        }
      })
      .catch((er) => { });
  };


  let formattedNumber = ""
  const contactMobileDetails = () => {
    const phoneNumber = props.rowData.contactPhoneNumber
    const countryCode = props.rowData.contactPhoneCountryCode

    if (countryCode && phoneNumber) {
      formattedNumber = `${countryCode}-${phoneNumber}`
    }
    return formattedNumber
  }

  let visitorMobile = ""
  const visitorMobileDetails = () => {

  const phoneNumber = props.rowData.phoneNumber
    const countryCode = props.rowData.phoneCountryCode

    if (countryCode && phoneNumber) {
      visitorMobile = `${countryCode}-${phoneNumber}`
    }
    return visitorMobile
  }
 

  return (
    <div>
      <div onClick={handleClickOpen}>
        <ViewIcon />
      </div>
      <Grid>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <BootstrapDialogTitle onClose={handleClose}>
            <Avatar
              alt={props.rowData.firstName}
              src={props.rowData.profilePhotoURL !== undefined ? props.rowData.profilePhotoURL : null}
              sx={{
                width: 150,
                height: 150,
                bottom: "80px",
                left: "421px",
                border: "solid #FFFF",
                borderWidth: "7px",
              }}
            />
            <div className="CssTextProfileViewPageName">
              {props.rowData.firstName + " " + props.rowData.lastName}
            </div>
            <div className="CssTextProfileViewPagePhone">
              {visitorMobileDetails()}

              {/* {props.rowData.phoneCountryCode + "-" + props.rowData.phoneNumber} */}
            </div>
          </BootstrapDialogTitle>
          <Divider variant="inset" className="MuiDividerCss" />
          <DialogContent style={{ marginLeft: "70px", marginRight: "70px" }}>
            <Grid
              container
              spacing={12.25}
              className="CssTextProfileViewPageContent"
              marginTop={0.5}
            >
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  {t("COMMON042")}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {" "}
                  {props.rowData.email}
                </Grid>
              </Grid>
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  {t("PURPOSELABEL")}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{purposeName}</Grid>
              </Grid>
              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  {t("WATCHLISTTEMP002")}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {props.rowData.contactEmail}
                </Grid>
              </Grid>

              <Grid container spacing={2} p={1.25}>
                <Grid item xs={3}>
                  {t("WATCHLISTTEMP009")}
                </Grid>
                <Grid item xs={3}>
                  :
                </Grid>
                <Grid
                  item
                  marginLeft={10}
                  className="CssTextProfileViewPageContentValue"
                >
                  {contactMobileDetails()}
                  {/* {props.rowData.contactPhoneCountryCode + "-" + props.rowData.contactPhoneNumber} */}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
    </div>
  );
}

export default WatchlistViewProfile;
