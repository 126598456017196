import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import Toast from "../../components/ToastContainer/CustomToast";
import { unblacklistVisitor } from "../../slices/BlockVisitorsGlobal/BlockVisitorSlice";
import CommonUtil from "../../Util/CommonUtils";
import { useTranslation } from "react-i18next";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";

export default function UnblockVisitorForm(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const [payload, setPayload] = React.useState({
    visitId: props.rowData.id,
    unrestrictedBy: props.rowData.visitor.lastName === null ? props.rowData.visitor.firstName: props.rowData.visitor.firstName + ' ' + props.rowData.visitor.lastName,
    unrestrictReason: "",
  });

  const [error, setError] = React.useState({
    unrestrictedBy: false,
    unrestrictReason: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.unrestrictedBy)) {
      Toast(t("Please enter visitor name"), "error");
      setError({ unrestrictedBy: true });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.unrestrictReason)) {
      Toast(t("Please enter reason"), "error");
      setError({ unrestrictReason: true });
      return false;
    }

    dispatch(unblacklistVisitor(payload))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAE1047") {
          Toast(t(data?.message), "success");
          props.disable()
          handleClose();
        } else {
          Toast(data?.message, "error");
        }
      });
  };
  
  return (
    <>
      <DialogBox
        Header="Unblock Visitor"
        acceptText="Submit"
        cancelText="Cancel"
        fullWidth={true}
        open={open}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >

          <Grid item lg={8} xs={12}  style={{marginTop: "20px"}}>
            <TextField
              variant="outlined"
              label="Name *"
              error={error.unrestrictedBy}
              name="unrestrictedBy"
              value={payload.unrestrictedBy}
              onChange={handleChange}
              // handleChange={(e) => handleChange(e)}
              autoComplete="off"
              inputProps={{ maxLength: 120 }}
              style={{ margin: "1px" }}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item lg={8} xs={12}>
            <TextField
              variant="outlined"
              label="Reason *"
              error={error.unrestrictReason}
              name="unrestrictReason"
              value={payload.unrestrictReason}
              onChange={handleChange}
              autoComplete="off"
              inputProps={{ maxLength: 50 }}
              style={{ margin: "1px", marginTop: "30px" }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
