import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import store from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './Routes';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import CssBaseline from '@material-ui/core/CssBaseline';
import BrowserLanguage from './components/Util/BrowserLanguage';
import i18n from 'i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CommonUtil from './components/Util/CommonUtils';
import { SnackbarProvider } from 'notistack';
import Cookies from 'js-cookie';

toast.configure();

class App extends Component {
  componentDidMount() {
    let language = BrowserLanguage.getDefaultLanguage();
    language = language === null ? 'en' : language;
    i18n.changeLanguage(language);
    if (CommonUtil.isEmptyString(localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN))) {
      localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN);
      localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN);
    } 
    document.cookie = 'cloudvisitor=myValue; SameSite=Lax';
    Cookies.set('name', 'value', { httpOnly: true });
    document.cookie = 'mycookie=myvalue; HttpOnly=true';
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter basename='/'>
          <CssBaseline />
          <ScrollToTop>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              maxSnack={2}
              autoHideDuration={3000}
            >
              <Routes />
              <ToastContainer autoClose={2000} position='top-center' className='toast-container' toastClassName='dark-toast'/>
            </SnackbarProvider>
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }

}

export default App;
