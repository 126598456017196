import { Typography } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Grid, Paper, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as ZLinkIcons } from "../../assets/zlinklogo.svg";
import { default as CustomTextfeild, default as CustomTextfield } from "../../components/CustomInputs/CustomTextfield";
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
import Toast from "../../components/ToastContainer/CustomToast";
import CommonUtil from "../../Util/CommonUtils";

import {
  visitorUpdate, visitorUpdateByCode
} from "../../slices/Invitations/InvitationsSlice";
import ProfileUplod from "./UploadImg";
import CommonPhoneInput from "../../components/CustomInputs/CommonPhoneInput";
import CustomDateTimePicker from "../../components/CustomInputs/CustomDateTimePicker";

function ConfirmVisitV2() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const [paramid, setParamid] = useState("");
  const [code, setCode] = useState("");
  const [validatelist, setValidatelist] = useState([]);
  const [profileImage, setProfileImage] = React.useState();
  const [isLoading, setIsLoading] = useState(false);

  const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
    color: process.env.REACT_APP_BG_ICON,
    border: process.env.REACT_APP_BG_BORDER,
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: process.env.REACT_APP_BG_ICON,
      color: "#FFFF",
    },
  }));

  const CustomIconRight = styled(ChevronRightIcon)(() => ({
    color: process.env.REACT_APP_BG_ICON,
    border: process.env.REACT_APP_BG_BORDER,
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: process.env.REACT_APP_BG_ICON,
      color: "#FFFF",
    },
  }));

  const [payload, setPayload] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phoneCountryCode: "",
    visitorCompanyName: "",
    profilePhoto: "",
    hostName: "",
    registrationId: "",
    scheduledStartDate: "",
    scheduledEndDate: "",
  });

  const [countryCode, setCountryCode] = useState('')

  const [error, setError] = useState({
    id: false,
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    visitorCompanyName: false,
    profilePhoto: false,
    hostName: false,
    profile: false,
  });
  const [phoneInput, setPhoneInput] = useState("");
  const [profile, setProfile] = useState("");
  const [checkList, setCheckList] = useState([]);
  const [registartionId, setRegistartionId] = useState('');


  const handleProfile = (file) => {
    setPayload({
      ...payload,
      profilePhoto: payload.firstName,
    });
    setProfile(file.base64);
    setProfileImage(file.file);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value);
    setPayload({
      ...payload,
      phoneCountryCode: "+" + data.dialCode,
      phone: value.toString().replace(data.dialCode, ""),
    });
    setError({ phone: false });
  };

  const handlePhoneNumberChange = (value) => {
    var codeCountry = countryCode
    var mobile = value
    setError({
      ...error,
      phone: ''
    })
    if (mobile.length > 25) {
      return
    }
    else {
      setPayload({
        ...payload,
        phoneCountryCode: codeCountry,
        phoneNumber: mobile
      })
      setPhoneInput(value)
    }
  }

  useEffect(() => {
    setIsLoading(true);
    loadData();
  }, []);

  const loadData = () => {
    var urlValue = window.location.href;
    var url = new URL(urlValue);
    var code = url.searchParams.get("code");
    setCode(code);
    axios.get('/web/visit/validate/' + code).then((res) => {
      const data = res.data;
      if (data.code === "CVSI0000") {
        setValidatelist(data.data.checkListItem);
        setParamid(data.data.Visit.id);
        setPayload({
          id: data.data.visitor.id,
          firstName: data.data.visitor.firstName,
          lastName: data.data.visitor.lastName,
          email: data.data.visitor.email,
          // phone: data.data.visitor.phone,
          // phoneCountryCode: data.data.visitor.phoneCountryCode,
          phoneCountryCode: data.data.visitor.phone.split("-")[0],
          phone: data.data.visitor.phone.split("-")[1],
          visitorCompanyName: data.data.visitor.visitorCompanyName,
          hostName: data.data.Visit.hostName,
          profilePhoto: data.data.visitor.profilePhoto,
          registrationId: data.data.visitor.id,
          scheduledStartDate: CommonUtil.getLocalDateTimeInYearFormat(data.data.Visit.scheduledStartDate),
          scheduledEndDate: CommonUtil.getLocalDateTimeInYearFormat(data.data.Visit.scheduledEndDate),
          companyId: data.data.Visit.companyId
        });
        setProfile(data.data.visitor.profilePhotoURL ?
          data.data.visitor.profilePhotoURL : "data:image/jpeg;base64," + data.data.visitor.profilePhotoURL);
        setCountryCode(data.data.visitor.phone.split("-")[0])
        setPhoneInput(data.data.visitor.phone.split("-")[1]);
        setCheckList(data.data.checkListItem);
        setRegistartionId(data.data.Visit.id);
      } else if (data.code === "CVSE0061") {
        setPayload([]);
        history.push("/vis/result-page");
      } else if (data.code === "CVAE0074") {
        setPayload([]);
        history.push("/vis/result-page");
      }
    }).catch((e) => {
      console.log(e);
    });
  };


  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('url', data.data.profilePhotoURL);
    bodyFormData.append('file', profileImage);
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: {
        'Content-Type': 'multipart/form-data', 'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'accept-language': localStorage.getItem("i18nextLng")
      },
    })
      .then(function (response) {
        // Toast("Profile Photo Saved", "success");
      })
      .catch(function (response) {

      });
  };

  useEffect(() => {
    const scheduledEndDate = new Date(payload.scheduledEndDate);
    const systemLocalTime = new Date();
    const scheduledEndTime = new Date(scheduledEndDate);
    scheduledEndTime.setSeconds(scheduledEndTime.getSeconds() + 59);
    if (systemLocalTime > scheduledEndTime) {
      history.push("/vis/result-page");
    }
  }, [payload.scheduledEndDate]);

  useEffect(() => {
    const bc = new BroadcastChannel("rejected");
    bc.addEventListener("message", (e) => {
      const data = e.data.data; 
      if (data === "rejected") {
        history.push("/vis/result-page")
      }
      return
    });
    return () => {
      bc.removeEventListener("message", handleSubmit);
    };
  }, []);


  const handleSubmit = (event) => {

    if (CommonUtil.isEmptyString(payload.firstName)) {
      Toast("Please Enter First Name", "error");
      return;
    }

    // var firstName = payload.firstName.toString()
    // if (firstName.length > 20) {
    //   Toast("Please enter a first name with a maximum of 20 characters", "error");
    //   return
    // }
    
    if (!CommonUtil.isValidName(payload.firstName)) {
      Toast("Please Enter Valid First Name ", "error");
      return;
    }

    // var lastName = payload.lastName.toString();
    // if (lastName.length > 20) {
    //   Toast("Please enter a last name with a maximum of 20 characters", "error");
    //   return;
    // }

    // if (!CommonUtil.isValidName(payload.lastName)) {
    //   Toast("Please Enter Valid Last Name", "error")
    //   return
    // }

    const scheduledEndDate = new Date(payload.scheduledEndDate);
    const systemLocalTime = new Date();
    const scheduledEndTime = new Date(scheduledEndDate)
    scheduledEndTime.setSeconds(scheduledEndTime.getSeconds() + 59)

    if (systemLocalTime > scheduledEndTime) {
      return history.push("/vis/result-page");
    }

    if (payload.scheduledStartDate === null ||
      payload.scheduledStartDate === undefined ||
      payload.scheduledStartDate === "") {
      Toast(t("INVITATIONFORM011"), 'error');
      return;
    }

    if (payload.scheduledEndDate === null ||
      payload.scheduledEndDate === undefined ||
      payload.scheduledEndDate === "") {
      Toast(t("INVITATIONFORM012"), 'error')
      return;
    }

    setIsLoading(true);

    var urlValue = window.location.href;
    var url = new URL(urlValue);

    let sendPayload = {
      visitorId: payload.id,
      id: payload.id,
      companyId: payload.companyId,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phone: payload.phone,
      phoneCountryCode: payload.phoneCountryCode,
      visitorCompanyName: payload.visitorCompanyName,
      profilePhoto: payload.profilePhoto,
      hostName: payload.hostName,
      registrationId: payload.registrationId,
      scheduledStartDate: payload.scheduledStartDate,
      scheduledEndDate: payload.scheduledEndDate,
      code: url.searchParams.get("code")
    }

    dispatch(visitorUpdate(Object.assign(payload))).unwrap().then((res) => {
      //dispatch(visitorUpdateByCode(Object.assign(sendPayload))).unwrap().then((res) => {

      if (res.code === "CVSE0061") {
        history.push({ pathname: '/vis/result-page' })
        return
      }
      if (res.code === "OMSI0236") {
        if (!CommonUtil.isEmptyString(res.data.profilePhotoURL)) {
          uploadProfilePhoto(res);
        }
        if (checkList?.questionaries?.length > 0) {
          history.push({
            pathname: "/vis/check-list-form",
            state: {
              success: true,
              listValue: checkList.questionaries,
              id: paramid,
              code: code,
            }
          });
        } else {
          axios({
            url: '/web/visit/confirm/' + code,
            method: 'put',
            headers: { 'Content-Type': 'application/json', Application: 'cloud-visitor', 'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone, 'accept-language': localStorage.getItem("i18nextLng") }
          })
            .then((res) => {
              if (res.data.code === "CVAI0072") {
                history.push({
                  pathname: "/vis/visit-success",
                  state: { message: res.data.message, success: true },
                });
              } else {
                Toast(res.data.message, "error");
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    })
    setIsLoading(false)
  };


  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "#F4F6F8",
        }}
      >
        <Grid container alignItems="center">
          <Grid item className="logo-center">
            <ZLinkIcons />
          </Grid>
        </Grid>
      </Grid>
      <Paper>
        <Typography
          gutterBottom
          variant="h3"
          style={{ padding: "25px", paddingLeft: "220px" }}
        >
          Visitor Details
        </Typography>
        <Grid container p={5} spacing={3}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            md={4}
            sm={12}
          >
            <Grid xl={6} lg={10} md={8} sm={4}>
              <ProfileUplod
                onsetProfile={(profile) => handleProfile(profile)}
                profilePhoto={profile}
              />
            </Grid>
          </Grid>
          <Grid container md={8} sm={12} spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={"First Name"}
                name={"firstName"}
                value={payload.firstName}
                validation="alpha-numeric"
                required={true}
                handleChange={handleChange}
                error={error.firstName}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={"Last Name"}
                name={"lastName"}
                value={payload.lastName}
                validation="alpha-numeric"
                handleChange={handleChange}
                error={error.lastName}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                disabled
                label={"Email"}
                name={"email"}
                value={payload.email}
                validation="email"
                handleChange={handleChange}
                error={error.email}
              />
            </Grid>
            <Grid item xl={6} lg={6} sm={12} mt={-1.5}>
              {/* <CustomPhone
                disabled
                placeholder="Mobile"
                value={phoneInput}
                specialLabel={false}
                handleChange={(value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue)}
              /> */}

              <CommonPhoneInput
                disabled={true}
                sx={{ margin: 'none !important' }}
                error={error.phone}
                handleCountryCode={(data) => {
                  setCountryCode(data.dialCode)
                  setError({ ...error, phone: '' })
                }}
                countryCode={countryCode}
                placeholder={t('COMPPROFSCREEN037')}
                phoneNumber={phoneInput}
                name={'phone'}
                handleChange={(e) => handlePhoneNumberChange(e)}
              />
            </Grid>


            <Grid item xl={6} lg={6} sm={12} marginTop={"-18px"}>
              <CustomTextfeild
                disabled
                label={"Company Name"}
                name={"visitorCompanyName"}
                value={payload.visitorCompanyName}
                handleChange={handleChange}
                error={error.visitorCompanyName}
                validation="alpha-numeric"
              />
            </Grid>
            <Grid item xl={6} lg={6} sm={12}  marginTop={"-18px"}>
              <CustomTextfeild
                disabled
                label={"Host Name"}
                name={"hostName"}
                value={payload.hostName}
                validation="alpha-numeric"
                handleChange={handleChange}
                error={error.hostName}
              />
            </Grid>

            <Grid item xl={6} lg={6} sm={12} marginTop={"5px"}>
            <CustomDateTimePicker
              onChange={(scheduledStartDate) =>
               setPayload({
                 ...payload,
                 scheduledStartDate: scheduledStartDate,
               })
             }
              disablePast
              disabled
              value={payload.scheduledStartDate}
              label={"Invite Start Date *"}
            />
            </Grid>

            <Grid item xl={6} lg={6} sm={12} marginTop={"5px"}>
              <CustomDateTimePicker
               onChange={(scheduledEndDate) => (
                setPayload({
                  ...payload,
                  scheduledEndDate: scheduledEndDate,
                }),
                setError({ scheduledEndDate: false })
              )}
                disablePast
                disabled
                value={payload.scheduledEndDate}
                //minDateTime={payload.scheduledStartDate}
                label="Invite End Date *"
              />
            </Grid>

          </Grid>
        </Grid>
        <Grid
          container
          lg={12}
          md={12}
          p={5}
          pt={0}
          spacing={3}
          justifyContent="flex-end"
        >
          <Grid>
            <SubmitButtons type='submit'
              onClick={handleSubmit}
              variant="contained"
            >
              <Typography>
                {"Continue"}
              </Typography>
            </SubmitButtons>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default ConfirmVisitV2;

