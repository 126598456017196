import { Paper } from '@material-ui/core'
import { Box, Grid, IconButton, Tooltip } from '@mui/material'
import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getUserInfo, updateUserInfo } from '../../slices/User/UserSlice'
import jwt_decode from "jwt-decode"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { ReactComponent as ProfileEditIcon } from '../../assets/ProfileEditIcon.svg'
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import Toast from '../../components/ToastContainer/CustomToast'
import CustomDropdown from '../../components/CustomInputs/CustomDropdown'
import CustomPhone from '../../components/CustomInputs/CustomPhone'
import { countryData, gender } from "../Person/AddressFormData"
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import CommonUtil from '../../Util/CommonUtils'
import ProfileUplod from '../Person/UploadImg'
import AutocompleteComponent from '../../components/CustomInputs/AutocompleteComponent'
import CommonPhoneInput from '../../components/CustomInputs/CommonPhoneInput'

function UserProfile() {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  var maxDate = new Date();

  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: CommonUtil.getDateOfJoining(maxDate),
    dateOfJoining: CommonUtil.getDateOfJoining(maxDate),
    gender: '',
    profilePhoto: '',
    email: '',
    phone: '',
    verified: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    pincode: '',
    country: countryCode,
    id: '',
  });

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    dateOfBirth: false,
    dateOfJoining: false,
    gender: false,
    profilePhoto: false,
    email: false,
    phone: false,
    verified: false,
    addressLine1: false,
    addressLine2: false,
    city: false,
    state: false,
    pincode: false,
    country: false,
    id: false,
  });

  const [profilePhoto, setProfilePhoto] = useState('');
  const [editMode, setEditMode] = useState(true);
  const [countries, setCountries] = React.useState([]);
  const [Gender, setGender] = React.useState([]);
  const [InavalidprofilePhoto, setInavalidprofilePhoto] = useState(false)
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );

  const [countryCode, setCountryCode] = useState('')


  useEffect(() => {
    setCountries(countryData);
    setGender(gender);
    setInavalidprofilePhoto(false)
    loadData();
  }, []);

  const loadData = () => {
    dispatch(getUserInfo(decoded_jwtToken.userId))
      .unwrap()
      .then((data) => {
        if ((data.code = "UASI0000" && data.data)) {
          setUser({ ...user, ...data.data });

          setProfilePhoto(data.data.profilePhoto ? 'data:image/jpeg;base64,' + data.data.profilePhoto : '');
        } else {
          setUser([]);
        }
      })
      .catch((er) => { });
  };

  const updateUser = () => {
    let payload = {
      firstName: user.firstName,
      lastName: user.lastName,
      dateOfBirth: CommonUtil.getDateOfJoining(maxDate),
      dateOfJoining: CommonUtil.getDateOfJoining(maxDate),
      gender: user.gender,
      profilePhoto: '',
      email: user.email,
      phone: user.phone,
      verified: '',
      addressLine1: user.addressLine1,
      addressLine2: user.addressLine2,
      city: user.city,
      state: user.state,
      pincode: user.pincode,
      country: "+" + countryCode,
      id: user.id
    }
    dispatch(updateUserInfo(payload))
      .unwrap()
      .then((data) => {
        if ((data.code = "UASI0002")) {
          Toast((data.message), 'success');
        } else {
          Toast((data.message), 'error');
        }
      })
      .catch((er) => { });
  };

  const newFunction = () => {
    setInavalidprofilePhoto(true)
    setProfilePhoto("")
  }

  const handleProfilePicture = (data) => {
    if (data === 'Error') {
      setInavalidprofilePhoto(true)
      setProfilePhoto('')
      Toast("Image size should be less than 2MB", "error")
      return;
    }
    setInavalidprofilePhoto(false)
    setProfilePhoto(data);
    setUser({ ...user, profilePhoto: data.split(',')[1] });
  };

  const handleClickEdit = () => {
    setEditMode(editMode ? false : true);
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setUser({
      ...user,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const onSelectChange = (event) => {
    setUser({
      ...user,
      country: event.id,
    });
  };

  const onSubmit = () => {
    if (CommonUtil.isEmptyString(user.firstName)) {
      Toast(t("Please Enter First Name"), "error");
      setError({ ...error, firstName: true });
      return false;
    }

    if (!CommonUtil.isValidName(user.firstName)) {
      Toast(t("Please Enter Correct First Name"), "error");
      setError({ ...error, firstName: true });
      return;
    }

    if (CommonUtil.isEmptyString(user.email)) {
      setError({ ...error, email: true });
      Toast("Please Enter Email", "error");
      return;
    }

    if (!CommonUtil.isValidEmails(user.email)) {
      setError({ ...error, email: true });
      Toast("Please Enter Correct Email", "error");
      return;
    }


    if (!InavalidprofilePhoto) {


      updateUser();
      setEditMode(true);
    }
    else {
      Toast("Image size should be less than 2MB", "error")
    }
  };

  const onCancel = () => {
    setEditMode(true);
    loadData();
  };

  const handlePhoneChange = (event) => {
    setUser({
      ...user,
      country: "+" + countryCode,
      phone: event,
    });
    setError({ phone: false });
  }

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ m: 2 }}>
          <Typography variant="h3">
            User Profile
          </Typography>
        </Box>
        <Grid>
          <div style={{ backgroundColor: '#F2F2F6' }}>
            <Tooltip title={<Typography fontSize={12} fontWeight={500}>Edit</Typography>}>
              <IconButton onClick={handleClickEdit} >
                <ProfileEditIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <Paper>
        <Grid container p={5}>
          <Grid container justifyContent="center" alignItems='center' md={4} sm={12}>
            <Grid>
              <ProfileUplod
                handalInvalidImg={() => newFunction()}
                profilePhoto={profilePhoto}
                onsetProfile={(data) => handleProfilePicture(data)}
                disabled={editMode} />
            </Grid>
          </Grid>
          <Grid container md={8} sm={12} spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'First Name'}
                placeholder={t("First Name")}
                name={'firstName'}
                value={user.firstName}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                error={error.firstName}
                helperText={error.firstName}
                validation='alpha-numeric'
                inputProps={{ maxLength: 18 }}
                required
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Last Name'}
                placeholder={t('Last Name')}
                name={'lastName'}
                value={user.lastName}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                error={error.lastName}
                helperText={error.lastName}
                validation='alpha-numeric'
                inputProps={{ maxLength: 18 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'User ID'}
                placeholder={t('User ID')}
                name={'id'}
                value={user.id}
                disabled={true}
                handleChange={handleChange}
                type={'text'}
                error={error.id}
                helperText={error.id}
                validation='alphabets'
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
              <CustomDropdown
                disabled={editMode}
                data={Gender}
                label="Gender"
                name="gender"
                value={user.gender}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Email'}
                placeholder={t('Email')}
                name={'email'}
                value={user.email}
                disabled={true}
                handleChange={handleChange}
                type={'text'}
                error={error.email}
                helperText={error.email}
                validation='email'
                required
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '-10px' }}>
              {/* <CustomPhone
                disabled={editMode}
                value={user.phone}
                specialLabel={false}
                handleChange={(e) => handlePhoneChange(e)}
              /> */}
              <CommonPhoneInput
                sx={{ margin: 'none !importantF' }}
                handleCountryCode={(data) => {
                  setCountryCode(data.dialCode)
                  setError({ ...error, phone: '' })
                }}
                // countryCode={user.country ? user.country : countryCode}
                countryCode={user.country ? user.country : countryCode}
                placeholder={t('COMPPROFSCREEN037')}
                phoneNumber={user.phone}
                name={'phone'}
                handleChange={(e) => handlePhoneChange(e)}

              />

            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} mt={-2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <DesktopDatePicker
                    label={'Date Of Birth'}
                    name={'dateofbirth'}
                    inputFormat="dd-MM-yyyy"
                    disabled={editMode}
                    value={user.dateOfBirth}
                    onChange={(dateOfBirth) =>
                      setUser({
                        ...user,
                        dateOfBirth: CommonUtil.getDateOfJoining(dateOfBirth),
                      })
                    }
                    renderInput={(params) => <CustomTextfeild {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} mt={-2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <DesktopDatePicker
                    label={'Date Of Joining'}
                    name={'dateofjoining'}
                    inputFormat="dd-MM-yyyy"
                    disabled={editMode}
                    ignoreInvalidInputs={false}
                    value={user.dateOfJoining}
                    onChange={(dateOfJoining) =>
                      setUser({
                        ...user,
                        dateOfJoining: CommonUtil.getDateOfJoining(dateOfJoining)

                      })
                    }
                    renderInput={(params) => <CustomTextfeild {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
              <AutocompleteComponent
                disabled={editMode}
                options={countries}
                label="Country"
                name="country"
                value={user.country}
                onSelect={(e) => onSelectChange(e)}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'State'}
                placeholder={t('State')}
                name={'state'}
                value={user.state}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                error={error.state}
                helperText={error.state}
                validation='alphabets'
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'City'}
                name={'city'}
                value={user.city}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                error={error.city}
                helperText={error.city}
                validation='alphabets'
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Address Line 1'}
                name={'addressLine1'}
                value={user.addressLine1}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                error={error.addressLine1}
                helperText={error.addressLine1}
                validation='alphabets'
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Address Line 2'}
                name={'addressLine2'}
                value={user.addressLine2}
                disabled={editMode}
                handleChange={handleChange}
                error={error.addressLine2}
                helperText={error.addressLine2}
                validation='alphabets'
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Post Code'}
                name={'pincode'}
                value={user.pincode}
                disabled={editMode}
                handleChange={handleChange}
                type={'text'}
                error={error.pincode}
                helperText={error.pincode}
                validation='numeric'
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
          </Grid>

          <Grid container lg={4} ></Grid>
          {!editMode ?
            <Grid container lg={8} justifyContent='center' >
              <Grid pr={2} pt={4}>
                <SubmitButtons
                  disable={editMode}
                  variant="contained"
                  onClick={onSubmit}
                >
                  <Typography>
                    {'Submit'}
                  </Typography>
                </SubmitButtons>
              </Grid>
              <Grid pt={4}>
                <CancelButtons
                  disable={editMode}
                  variant="contained"
                  color="secondary"
                  onClick={onCancel}
                >
                  <Typography>
                    {'Cancel'}
                  </Typography>
                </CancelButtons>
              </Grid>
            </Grid> : <></>}
        </Grid>

      </Paper>
    </>
  )
}

export default UserProfile