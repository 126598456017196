import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';
import { LeftSidebar } from './layout-blueprints';
import {
  REACT_URL_DASHBOARD,
  REACT_URL_INVITATIONS,
  REACT_URL_REGISTRATIONS,
  REACT_URL_APPROVAL,
  REACT_URL_VISITORLOGS,
  REACT_URL_BLOCKED_VISITORS,
  REACT_URL_USERPROFILE,
  REACT_URL_COMPANYRPROFILE,
  REACT_URL_SITE,
  REACT_URL_SITETYPE,
  REACT_URL_ADDRESSTYPE,
  REACT_URL_CHECKINOUTDEVICES,
  REACT_URL_PERSON,
  REACT_URL_ROLE,
  REACT_URL_PURPOSETYPE,
  REACT_URL_WATCHLIST,
  REACT_URL_CHECKLIST,
  REACT_URL_CHECKLIST_ITEM,
  REACT_URL_RESTRICTTYPE,
  REACT_URL_VISITORACCESSLEVEL,
  REACT_URL_ADDDEVICES,
  REACT_URL_URGENTMESSAGES,
  REACT_URL_FLOWCHART,
  REACT_URL_ADDCHECKOUT,
  REACT_VISITOR_QR_PAGE,
  REACT_DEVICE_LIST,
  REACT_DEVICE_INFO,
  REACT_URL_DEVICELIST,
  REACT_DEVICE_ADD_INSTRUCTION,
  REACT_ADD_DEVICE,
  REACT_ACCESSLEVELS_LIST,
  REACT_TIME_SLOTS,
  REACT_VIEW_DEVICE,
  REACT_MANAGE_ACCESSLEVELS,
  REACT_ADD_TIMESLOTS,
  REACT_ADD_DEVICE_TO_ACCESSLEVEL,
  REACT_UPDATE_TIMESLOT,
  REACT_URL_ZONE,
  REACT_URL_WALKINREGISTRATION,
  REACT_URL_REPORTINVITATION,
  REACT_URL_REPORTREGISTRATION,
  REACT_VISITOR_ACCESS_LEVEL_LIST,
  REACT_URL_VISITINVITE,
  REACT_URL_WALKINVISIT,
  REACT_URL_WATCHLISTGLOBAL,
  REACT_URL_HEALTHQUESTIONARIES,
  REACT_URL_REPORTLOGS,
  REACT_URL_ROLEANDPERMISSION,
  REACT_URL_SELFREGISTARTIONS,
  REACT_URL_DEVICEREPORTEVENTS,
  REACT_URL_UPDATEROLEANDPERMISSION,
  REACT_URL_VIEWROLEANDPERMISSION
} from './actions/EndPoints';

import Dashboard from './Pages/DashBoard/dashboard.js';
import Invitations from './Pages/Invitations/Invitations';
import walkinRegistartion from './Pages/Invitations/ReportWalkinRegistartion';
import Registrations from './Pages/Registrations/Registrations';
import Approvals from './Pages/Approvals/Approvals';
import VisitorLogs from './Pages/Logs/VisitorLogs';
import LoginLayoutV2 from './Pages/OnBoardV2/LoginLayoutV2';
import LoginFormV2 from './Pages/OnBoardV2/LoginFormV2';
import CustomCarousel from './Pages/OnBoardV2/CustomCarousel';
import BlockedVisitors from './Pages/BlockedVisitors/BlockedVisitors';
import CompanyProfile from './Pages/ProfilePages/CompanyProfile';
import UserProfile from './Pages/ProfilePages/UserProfile';
import SignUpScreenV2 from './Pages/OnBoardV2/SignUpScreenV2';
// import SelectCompany from './Pages/OnBoardV2/SelectCompany';
import SelectCompany from './Pages/OnBoardV2/SelectCompany';
import EmailSentScreen from './Pages/OnBoardV2/EmailSentScreen';
import ForgotPasswordScreen from './Pages/OnBoardV2/ForgotPasswordScreen';
import SuccessScreen from './Pages/OnBoardV2/SuccessScreen';
import ChangePassword from './Pages/OnBoardV2/ChangePassword';
import ThankYouScreen from './Pages/OnBoardV2/ThankYouScreen';
import PasswordSuccess from './Pages/OnBoardV2/PasswordSuccess';
import CreateCompany from './Pages/OnBoardV2/CreateCompany';
import PrivacyPolicyScreen from './Pages/OnBoardV2/PrivacyPolicyScreen';
import LinkExpired from './Pages/OnBoardV2/LinkExpired';
import Person from './Pages/Person/Person';
import Site from './Pages/SiteManagement/Site/Site';
import SiteType from './Pages/SiteManagement/SiteType/SiteType';
import AddressType from './Pages/SiteManagement/AddressType/AddressType';
import CheckInCheckOutDevices from './Pages/SiteManagement/CheckInCheckOutDevices/CheckInCheckOutDevices';
import PurposeType from './Pages/PurposeType/PurposeType';
import WatchlistTemplate from './Pages/Watchlist/WatchlistTemplate';
import ChecklistTemplate from './Pages/Checklist/ChecklistTemplate';
import VisitorAccessLevelsPage from './Pages/PurposeType/VisitorAccessLevelsPage';
import ChecklistItems from './Pages/Checklist/ChecklistItems';
import Role from './Pages/Role/Role';
import RestrictType from './Pages/RestrictType/RestrictType';
import UrgentMessages from './Pages/UrgentMessages/UrgentMessages';
import AddCheckInOutDevices from './Pages/SiteManagement/CheckInCheckOutDevices/AddCheckInOutDevices';
import ConfirmVisit from './Pages/Invitations/ConfirmVisit';
import VisitSuccess from './Pages/Invitations/VisitSuccess';
import ChecklistForm from './Pages/Invitations/ChecklistForm';
import VisitReject from './Pages/Invitations/RejectVisit';
import SecuredRoute from './config/SecuredRoute';
import RejectVisit from './Pages/Invitations/RejectVisit';
import VisLinkExpired from './Pages/Invitations/ResultPage';
import ResultPage from './Pages/Invitations/ResultPage';
import Zlink from './Pages/OnBoardV2/Zlink';
import VisitingFlowchart from './Pages/VisitingFlowchart/VisitingFlowchart';
import AddCheckOutDevice from './Pages/SiteManagement/CheckInCheckOutDevices/AddCheckOutDevice';
import SelfRegistration from './Pages/SelfRegistration/SelfRegistration';
import SelfReject from './Pages/SelfRegistration/SelfReject';
import Loader from './components/Loader/Loader';
import QRcode from './Pages/QRCode/QRcode';
import AddDeviceInfo from './Pages/SiteManagement/CheckInCheckOutDevices/AddDeviceInfo';
import AddCheckOutDeviceInfo from './Pages/SiteManagement/CheckInCheckOutDevices/AddCheckOutDeviceInfo';
import ActivateAccount from './Pages/Person/ActivateAccount';
import { DeviceList } from './Pages/DeviceManagementV2/DeviceList';
import ZlinkAddDeviceInfo from './Pages/DeviceManagementV2/ZlinkAddDeviceInfo';
import AddDevicePage from './Pages/DeviceManagementV2/AddDevicePage';
import VisitingAccessLevels from './Pages/VisitingAccessLevels/VisitingAccessLevels';
import { TimeSlotList } from './Pages/TimeSlots/TimeSlotList';
import { ViewDevice } from './Pages/DeviceManagementV2/ViewDevice';
import ManageAccessLevels from './Pages/VisitingAccessLevels/ManageAccessLevel'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AddTimeSlots from './Pages/TimeSlots/AddTimeSlots';
import AccessAddDevice from './Pages/VisitingAccessLevels/AddDeviceToAccessLevel';
import updateTime from './Pages/TimeSlots/UpdateTimeSlot';
import ZoneList from './Pages/ZoneManagment/ZoneList';

import ReportInvitation from './Pages/Invitations/ReportInvitation';
import ReportSelfRegistration from './Pages/Invitations/ReportSelfRegistration';
import ReportWalkinRegistartion from './Pages/Invitations/ReportWalkinRegistartion';
import VisitorAddAccessLevelDialog from './Pages/PurposeType/VisitorAddAccessLevelDialog';
import ConfirmVisitV2 from './Pages/Invitations/ConfirmVisitV2';
import InvitationsList from './Pages/Invitations/InvitationsList';
import RegistrationList from './Pages/Registrations/RegistrationList';
import WatchlistList from './Pages/Watchlist/WatchlistList';
import HealthQuestionaries from './Pages/HealthQuestionaries/HealthQuestionaries';
import ReportLogs from './Pages/VisitorReport/ReportLogs';
import RoleAndPermissionsList from './Pages/UserRole/RoleAndPermissionsList';
import SelfRegistrationList from './Pages/SelfRegistration/SelfRegistrationList';
import SelfRegistrationSuccess from './Pages/SelfRegistration/SelfRegistrationSuccess';
import DeviceEventReportList from './Pages/DeviceEventReport/DeviceEventReportList';
import SessionOut from './config/SessionOut';
import RoleAndPermissionView from './Pages/UserRole/RoleAndPermissionView';
import UpdateRoleAndPermission from './Pages/UserRole/UpdateRoleAndPermission';

const Routes = () => {

  const zlink = localStorage.getItem("redirectFrom");
  return (
    <ThemeProvider theme={MuiTheme}>
      <Loader />
      <Switch>
        <Redirect exact from='/' to='/vis/login' />
        <Route path='/vis/login' component={LoginFormV2} />
        <Route path='/vis/verify-company' component={SelectCompany} />
        <Route path='/vis/carousel' component={CustomCarousel} />
        <Route path='/vis/signup' component={SignUpScreenV2} />
        <Route path='/vis/email-sent' component={EmailSentScreen} />
        <Route path='/vis/link-expired' component={LinkExpired} />
        <Route path='/vis/forgot-password' component={ForgotPasswordScreen} />
        <Route path='/vis/success-screen' component={SuccessScreen} />
        <Route path='/vis/reset-password' component={ChangePassword} />
        <Route path='/vis/user-created' component={ThankYouScreen} />
        <Route path='/vis/password-success' component={PasswordSuccess} />
        <Route path='/vis/create-company' component={CreateCompany} />
        <Route path='/vis/privacy-policy' component={PrivacyPolicyScreen} />
        <Route path='/vis/confirm-page' component={ConfirmVisitV2} />
        <Route path='/vis/selfRegistration-confirm-page' component={SelfRegistrationSuccess} />
        <Route path='/vis/check-list-form' component={ChecklistForm} />
        <Route path='/vis/visit-success' component={VisitSuccess} />
        <Route path='/vis/reject' component={RejectVisit} />
        <Route path='/vis/result-page' component={ResultPage} />
        <Route path='/vis/activate-account' component={ActivateAccount} />
        <Route path='/vis/zlink' component={Zlink} />
        <Route path='/vis/selfRegistartion' component={SelfRegistration} />
        <Route path='/vis/confirms-page/rejects' component={SelfReject} />
        <Route path={REACT_VISITOR_QR_PAGE} component={QRcode} />
        <Route path='/vis/error' component={SessionOut} />
        <LeftSidebar>
          <SecuredRoute path={REACT_URL_DASHBOARD} component={Dashboard} />
          <SecuredRoute path={REACT_URL_INVITATIONS} component={Invitations} />
          <SecuredRoute path={REACT_URL_VISITINVITE} component={InvitationsList} />
          <SecuredRoute path={REACT_URL_REGISTRATIONS} component={Registrations} />
          <SecuredRoute path={REACT_URL_WALKINVISIT} component={RegistrationList} />
          <SecuredRoute path={REACT_URL_APPROVAL} component={Approvals} />
          <SecuredRoute path={REACT_URL_BLOCKED_VISITORS} component={BlockedVisitors} />
          <SecuredRoute path={REACT_URL_VISITORLOGS} component={VisitorLogs} />
          <SecuredRoute path={REACT_URL_USERPROFILE} component={UserProfile} />
          <SecuredRoute path={REACT_URL_COMPANYRPROFILE} component={CompanyProfile} />
          <SecuredRoute path={REACT_URL_PERSON} component={Person} />
          <SecuredRoute path={REACT_URL_ROLE} component={Role} />
          <SecuredRoute path={REACT_URL_SITE} component={Site} />
          <SecuredRoute path={REACT_URL_SITETYPE} component={SiteType} />
          <SecuredRoute path={REACT_URL_ADDRESSTYPE} component={AddressType} />
          <SecuredRoute path={REACT_URL_CHECKINOUTDEVICES} component={CheckInCheckOutDevices} />
          <SecuredRoute path={REACT_URL_PURPOSETYPE} component={PurposeType} />
          <SecuredRoute path={REACT_URL_RESTRICTTYPE} component={RestrictType} />
          <SecuredRoute path={REACT_URL_WATCHLIST} component={WatchlistTemplate} />
          <SecuredRoute path={REACT_URL_WATCHLISTGLOBAL} component={WatchlistList} />
          <SecuredRoute path={REACT_URL_CHECKLIST} component={ChecklistTemplate} />
          <SecuredRoute path={REACT_URL_VISITORACCESSLEVEL} component={VisitorAccessLevelsPage} />
          <SecuredRoute path={REACT_URL_CHECKLIST_ITEM} component={ChecklistItems} />
          <SecuredRoute path={REACT_URL_DEVICELIST} component={DeviceList} />
          <SecuredRoute path={REACT_URL_ADDDEVICES} component={AddCheckInOutDevices} />
          <SecuredRoute path={REACT_URL_ADDCHECKOUT} component={AddCheckOutDevice} />
          <SecuredRoute path={REACT_URL_URGENTMESSAGES} component={UrgentMessages} />
          <SecuredRoute path={REACT_URL_FLOWCHART} component={VisitingFlowchart} />
          <SecuredRoute path={REACT_DEVICE_LIST} component={AddDeviceInfo} />
          <SecuredRoute path={REACT_DEVICE_INFO} component={AddCheckOutDeviceInfo} />
          <SecuredRoute path={REACT_DEVICE_ADD_INSTRUCTION} component={ZlinkAddDeviceInfo} />
          <SecuredRoute path={REACT_ADD_DEVICE} component={AddDevicePage} />
          <SecuredRoute path={REACT_ACCESSLEVELS_LIST} component={VisitingAccessLevels} />
          <SecuredRoute path={REACT_TIME_SLOTS} component={TimeSlotList} />
          <SecuredRoute path={REACT_VIEW_DEVICE} component={ViewDevice} />
          <SecuredRoute path={REACT_MANAGE_ACCESSLEVELS} component={ManageAccessLevels} />
          <SecuredRoute path={REACT_ADD_TIMESLOTS} component={AddTimeSlots} />
          <SecuredRoute path={REACT_ADD_DEVICE_TO_ACCESSLEVEL} component={AccessAddDevice} />
          <SecuredRoute path={REACT_UPDATE_TIMESLOT} component={updateTime} />
          <SecuredRoute path={REACT_URL_ZONE} component={ZoneList} />
          <SecuredRoute path={REACT_URL_WALKINREGISTRATION} component={ReportWalkinRegistartion} />
          <SecuredRoute path={REACT_URL_REPORTINVITATION} component={ReportInvitation} />
          <SecuredRoute path={REACT_URL_REPORTREGISTRATION} component={ReportSelfRegistration} />
          <SecuredRoute path={REACT_VISITOR_ACCESS_LEVEL_LIST} component={VisitorAddAccessLevelDialog} />
          <SecuredRoute path={REACT_URL_HEALTHQUESTIONARIES} component={HealthQuestionaries} />
          <SecuredRoute path={REACT_URL_REPORTLOGS} component={ReportLogs} />
          <SecuredRoute path={REACT_URL_ROLEANDPERMISSION} component={RoleAndPermissionsList} />
          <SecuredRoute path={REACT_URL_SELFREGISTARTIONS} component={SelfRegistrationList} />
          <SecuredRoute path={REACT_URL_DEVICEREPORTEVENTS} component={DeviceEventReportList} />
          <SecuredRoute path={REACT_URL_UPDATEROLEANDPERMISSION} component={UpdateRoleAndPermission} /> 
          <SecuredRoute path={REACT_URL_VIEWROLEANDPERMISSION} component={RoleAndPermissionView} />
        </LeftSidebar>
      </Switch>
    </ThemeProvider>
  );
};

export default Routes;
