import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Sidebar, Header } from '../../layout-components';
import RefreshToken from '../../Pages/RefreshToken/RefreshToken';
import { useEffect } from 'react';
import SidebarIndex from '../../layout-components/Sidebar/SidebarIndex';

const LeftSidebar = (props) => {
  const { children, sidebarToggle, sidebarFixed, contentBackground } = props;
  const [open, setOpen] = React.useState(false);

  const onChangeSidebar = (child) => {
    setOpen(child)
  }

  useEffect(() => {
    document.body.style.zoom = '92%';
  }, []);
  
  return (
    <Fragment>
      <div className={clsx('app-wrapper', contentBackground)} >
        <Header />
        <div className={clsx('app-main', { 'app-main-sidebar-static': !sidebarFixed })}>
        {/* <Sidebar onchange={onChangeSidebar} /> */}
          <SidebarIndex onchange={onChangeSidebar} />
          <div>
            <div className='app-content--inner'>
              <div className={!open ? 'app_container1' : 'app_container2'} style={{ width: '-webkit-fill-available',padding:'3rem 0rem'}}>{children}</div>
            </div>
          </div>
        </div>
      </div>
      <RefreshToken />
    </Fragment>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node,
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,
  contentBackground: state.ThemeOptions.contentBackground,
});

export default connect(mapStateToProps)(LeftSidebar);
