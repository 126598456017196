import { Box, Grid, TextField } from "@mui/material";
import React, { useEffect } from "react";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { useDispatch } from "react-redux";
import Toast from "../../components/ToastContainer/CustomToast";
import CommonUtil from "../../components/Util/CommonUtils";
import { useTranslation } from "react-i18next";
import { rejectApprovalVisit } from "../../slices/Invitations/InvitationsSlice";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";

export default function RejectRegistartionInvitationDialog(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [payload, setPayload] = React.useState({
    visitorId: props.RejectRegisterId,
    approvalReason: "",
  });

  const [error, setError] = React.useState({
    approvalReason: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
  };

  const handleSubmit = () => {
    
    dispatch(rejectApprovalVisit(payload))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI1029") {
          Toast(t(data?.message), "success");
          props.disable();
          handleClose();
        } else {
          Toast(data?.message,"error");
        }
      })
      .catch((er) => {});
  };

  return (
    <>
      <DialogBox
        Header="Reject"
        acceptText="Submit"
        cancelText="Cancel"
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
        PaperProps={{
          style: {
            borderRadius: "20px !important",
            minWidth: "35vw",
          },
        }}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px",  }}
          spacing={2}
        >
         
          <Grid item xs={11} sm={10}>
            <CustomTextfield
              type={"text"}
              label="Remarks"
              error={error.approvalReason}
              name="approvalReason"
              value={payload.approvalReason}
              handleChange={(e) => handleChange(e)}
              inputProps={{ maxLength: 120 }}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
