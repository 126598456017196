import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { getCompanyInfo, updateCompanyInfo } from '../../slices/Company/CompanySlice'
import jwt_decode from "jwt-decode"
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons'
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons'
import { Box, Grid, IconButton, Paper, Stack, Tooltip } from '@mui/material'
import { ReactComponent as ProfileEditIcon } from '../../assets/ProfileEditIcon.svg'
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import { toast } from 'react-toastify'
import { Typography } from '@material-ui/core'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import CustomDropdown from '../../components/CustomInputs/CustomDropdown'
import { countryData } from "../Person/AddressFormData"
import Toast from '../../components/ToastContainer/CustomToast'
import CommonUtil from '../../Util/CommonUtils'
import { useTranslation } from "react-i18next"
import ProfileUplod from '../Person/UploadImg'
import AutocompleteComponent from '../../components/CustomInputs/AutocompleteComponent'


function CompanyProfile() {

  const dispatch = useDispatch()
  const { t } = useTranslation();

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  )

  const [company, setCompany] = useState({
    id: '',
    name: '',
    registrationNumber: '',
    owner: '',
    code: '',
    industry: '',
    companySize: '',
    email: '',
    phone: '',
    address: '',
    addressLine2: '',
    createdAt: '',
    city: '',
    state: '',
    pincode: '',
    country: '',
    companyLogo: '',
  })

  const [error, setError] = useState({
    name: false,
    registrationNumber: false,
    owner: false,
    industry: false,
    companySize: false,
    email: false,
    phone: false,
    country: false,
    address: false,
    addressLine2: false,
    creationTime: false,
    city: false,
    state: false,
    pincode: false,
    createdAt: false

  })

  const [profilePhoto, setProfilePhoto] = useState('')
  const [InavalidprofilePhoto, setInavalidprofilePhoto] = useState(false)
  const [editMode, setEditMode] = useState(true)
  const [countries, setCountries] = React.useState([]);

  useEffect(() => {
    setCountries(countryData);
    setInavalidprofilePhoto(false)
    loadCompany()
  }, [])


  const loadCompany = () => {
    dispatch(getCompanyInfo())
      .unwrap()
      .then((data) => {
        if ((data.code = "UASI0000")) {
          setCompany({
            id: data.data.id,
            name: data.data.name,
            registrationNumber: data.data.registrationNumber,
            owner: data.data.owner,
            code: data.data.code,
            industry: data.data.industry,
            companySize: data.data.companySize,
            email: data.data.email,
            phone: data.data.phone,
            address: data.data.address,
            city: data.data.city,
            state: data.data.state,
            pincode: data.data.pincode,
            country: data.data.country,
            companyLogo: data.data.companyLogo,
            createdAt: data.data.createdAt,
          })
          setProfilePhoto(data.data.companyLogo ? 'data:image/jpeg;base64,' + data.data.companyLogo : '')
        }
      })
      .catch((er) => { })
  }

  const updateCompany = () => {
    dispatch(updateCompanyInfo(company))
      .unwrap()
      .then((data) => {
        if ((data.code = "UASI0007")) {
          toast.success(data.message)
        } else {
          toast.error(data.message)
        }
      })
      .catch((er) => { })
  }
  const newFunction = () => {
    setInavalidprofilePhoto(true)
    setProfilePhoto("")
  }
  const handleProfilePicture = (data) => {
    if (data === 'Error') {
      setInavalidprofilePhoto(true)
      setProfilePhoto('')
      return;
    }
    setInavalidprofilePhoto(false)
    setProfilePhoto(data)
    setCompany({ ...company, companyLogo: data.split(',')[1] })
  };

  const handleClickEdit = () => {
    setEditMode(editMode ? false : true)
  }

  const handleChange = (event) => {
    const name = event.target.name
    setCompany({
      ...company,
      [name]: event.target.value,
    });
  };
  const onSelectChange = (event) => {
    setCompany({
      ...company,
      country: event.id,
    });
  };

  const onSubmit = () => {

    if (CommonUtil.isEmptyString(company.name)) {
      Toast(t("Please Enter Company Name"), "error");
      setError({ ...error, name: true });
      return false;
    }

    if (!CommonUtil.isValidName(company.name)) {
      Toast(t("Please Enter Correct Company Name"), "error");
      setError({ ...error, name: true });
      return; 
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'USA'? '':CommonUtil.isEmptyString(company.code)) 
    {
      Toast(t("Please Enter Company Code"), "error");
      setError({ ...error, code: true });
      return false;
    }

    if (process.env.REACT_APP_ENVIRONMENT === 'USA'? '':CommonUtil.isEmptyString(company.registrationNumber)) {
      Toast(t("Please Enter Registration Number"), "error");
      setError({ ...error, registrationNumber: true });
      return false;
    }


    if(!InavalidprofilePhoto){
      updateCompany()
      setEditMode(true)
    }
    else{
      Toast('Image size should be less than 2MB','error');
    }
  };

  const onCancel = () => {
    setEditMode(true)
    loadCompany()
  };

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          // padding: "20px",
        }}
      >
        <Box sx={{ m:2}}>
          <Typography
            gutterBottom
            variant="h3"
          >
            Company Profile
          </Typography>
        </Box>
        <Grid>
          <div style={{ backgroundColor: '#F2F2F6' }}>
            <Tooltip title={<Typography fontSize={12} fontWeight={500}>Edit</Typography>}>
              <IconButton onClick={handleClickEdit} >
                <ProfileEditIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      <Paper>
        <Grid container p={5}>
          <Grid container justifyContent="center" alignItems='center' md={4} sm={12}>
            <Grid>
              <ProfileUplod
                handalInvalidImg={() => newFunction()}
                profilePhoto={profilePhoto}
                 onsetProfile={(data) => handleProfilePicture(data)} disabled={editMode} />
            </Grid>
          </Grid>
          <Grid container md={8} sm={12} spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Company Name'}
                name={'name'}
                value={company.name}
                disabled={editMode}
                required={true}
                handleChange={handleChange}
                inputProps={{ maxLength: 20 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Industry Type'}
                name={'industry'}
                value={company.industry}
                disabled={editMode}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Company Size'}
                name={'companySize'}
                value={company.companySize}
                validation='numeric'
                disabled={editMode}
                handleChange={handleChange}
              />
            </Grid>

            {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
             '': 
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Code'}
                name={'code'}
                value={company.code}
                validation='code'
                disabled={editMode}
                required={true}
                handleChange={handleChange}
              />
            </Grid>}
          

            {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
             '': 
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Registration Number'}
                name={'registrationNumber'}
                value={company.registrationNumber}
                validation='alpha-numeric'
                disabled={editMode}
                required={true}
                handleChange={handleChange}
              />
            </Grid>}
           

            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Email'}
                name={'email'}
                value={company.email}
                validation='email'
                disabled={editMode}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Mobile'}
                name={'phone'}
                value={company.phone}
                validation='numeric'
                disabled={editMode}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} sx={{ mt: '16px' }}>
              <AutocompleteComponent
                disabled={editMode}
                options={countries}
                label="Country"
                name="country"
                value={company.country}
                onSelect={(e) => onSelectChange(e)}
                 />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'State'}
                name={'state'}
                value={company.state}
                validation='alpha-numeric'
                disabled={editMode}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'City'}
                name={'city'}
                value={company.city}
                validation='alphabets'
                disabled={editMode}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Address Line 1'}
                name={'address'}
                value={company.address}
                validation='alphabets'
                disabled={editMode}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Address Line 2'}
                name={'addressLine2'}
                value={company.addressLine2}
                validation='alphabets'
                disabled={editMode}
                handleChange={handleChange}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <CustomTextfeild
                label={'Post Code'}
                name={'pincode'}
                value={company.pincode}
                validation='numeric'
                disabled={editMode}
                handleChange={handleChange}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack spacing={3}>
                  <DesktopDatePicker
                    label={'Creation Time'}
                    name={'creationTime'}
                    inputFormat="MM/dd/yyyy"
                    disabled={editMode}
                    value={company.createdAt}
                    onChange={(createdAt) =>
                      setCompany({
                        ...company,
                        createdAt: createdAt,
                      })
                    }
                    renderInput={(params) => <CustomTextfeild {...params} />}
                  />
                </Stack>
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container lg={4} ></Grid>
          {!editMode ?
            <Grid container lg={8} justifyContent='center' >
              <Grid pr={2} pt={4}>
                <SubmitButtons
                  variant="contained"
                  disable={editMode}
                  onClick={onSubmit}>
                  <Typography >
                    {'Submit'}
                  </Typography>
                </SubmitButtons>
              </Grid>
              <Grid pt={4}>
                <CancelButtons
                  disable={editMode}
                  variant="contained"
                  color="secondary"
                  onClick={onCancel}
                >
                  <Typography style={{ textTransform: "none" }}>
                    {'Cancel'}
                  </Typography>
                </CancelButtons>
              </Grid>
            </Grid> : <></>}
        </Grid>
      </Paper>
    </>
  )
}

export default CompanyProfile