import React from "react";
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Grid } from '@material-ui/core';
import CommonUtil from '../../Util/CommonUtils';
import { ReactComponent as CompanyIcons } from "../../assets/CompanyIcons.svg";
import { TextField } from "@mui/material";


export default function CustomAutocomplete({ options, onSelect, error, InputProps, label, ...props }) {
    const filterOptions = createFilterOptions({ stringify: ({ name, code }) => `${name} ${code}`, });

    return (
        <Grid xs={{ display: 'flex' }}>
            <Grid className="company_icon">
                <CompanyIcons className='visibleIcons' />
            </Grid>
            <Autocomplete
                size='small'
                filterOptions={filterOptions}
                getOptionLabel={(option) => option.name}
                options={options}
                onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                        onSelect(newValue);
                    }
                }}
                renderInput={(params) =>
                    <TextField
                        sx={[
                            () => ({
                                "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall ": {
                                    paddingTop: '14px !important',
                                    paddingBottom: '14px !important',
                                },
                                "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-required.Mui-focused.Mui-required": {
                                    marginLeft: '1px !important'
                                },
                                "& .MuiInputLabel-outlined.MuiInputLabel-shrink":{
                                    transform:'translate(18px, -5px) scale(0.75) !important'
                                },
                                "& .css-1iknc2p-MuiFormLabel-root-MuiInputLabel-root":{
                                    transform: 'translate(34px, 15px) scale(1) !important'
                                }
                            })]}
                        {...params}
                        required={props.required}
                        placeholder={props.placeholder}
                        variant='outlined'
                        fullWidth
                        autoFocus
                        helperText={error}
                        label={label}
                    />
                }
            />
        </Grid>
    );
}

CustomAutocomplete.defaultProps = {
    label: ''
};

CustomAutocomplete.propType = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
}
