import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, InputLabel, Pagination, Select } from '@mui/material';
import styled from '@emotion/styled';

const customDataGridStyle = {
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#F5F6F6!important',
    color: '#474B4F!important',
    fontSize: '14px !important',
  },
  '.MuiDataGrid-columnHeader:focus-within': { outline: 'none' },
  '.MuiDataGrid-cell': { borderBottom: '0px', color: '#304669' },
  '.MuiDataGrid-cell:focus-within': {
    outline: 'none!important',
    color: '#36c96d!important',
  },
  '.MuiDataGrid-columnSeparator': { display: 'none !important' },
  '.MuiSvgIcon-root': { backgroundColor: 'transparent' },
  '.MuiDataGrid-root': { padding: '8px', borderRadius: '4px' },
  '.MuiDataGrid-row': {
    borderBottom: '1px solid #E7EBF0',
  },
  '.MuiDataGrid-columnHeaderTitle': {
    fontSize: '13px!important',
  },
  '.MuiCheckbox-root': { color: '#D1D1D1' },
  '.MuiDataGrid-cellContent': {
    color: '#474B4F!important',
    fontFamily: 'Roboto',
    // letterSpacing: '0.4px',
    fontSize: '12px !important',
  },
  '.MuiTypography-body1': { fontSize: '12px !important', },
  '.MuiDataGrid-virtualScrollerRenderZone': { backgroundColor: '#FFFF' },
  '.MuiDataGrid-footerContainer': {
    display: 'block',
    width: '100%',
    border: '0px',
  },
  '.MuiTablePagination-spacer': { display: 'none' },
  '.MuiTablePagination-actions': {
    position: 'absolute',
    right: '0px',
    backgroundColor: '#FFF',
  },
  '.MuiTablePagination-displayedRows': { position: 'absolute', right: '90px' },
  '.MuiDataGrid-virtualScroller': { overflow: 'auto' },
  '.MuiTablePagination-select': {
    backgroundColor: '#F0F1F4',
    borderRadius: '4px!important',
  },
  '.MuiTablePagination-toolbar': { paddingLeft: '0px' },
  '.MuiIconButton-root.Mui-disabled': {
    backgroundColor: '#F0F1F4',
    borderRadius: '0px',
  },
  '.MuiIconButton-root': { borderRadius: '20px' },
  '.Mui-checked': { color: 'rgb(54, 201, 109)!important' },
};

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay(props) {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>{props.text}</Box>
    </StyledGridOverlay>
  );
}

export default function DataTables(props) {
  const getPageDetails = () => {
    var pageRange = '0 - 0 of 0';
    var page = props.page + 1;
    if (props.totalRecords > 0) {
      let lastRecordIndex = page * props.rowsPerPage;
      if(lastRecordIndex<=props.totalRecords){
      let firstRecordIndex = lastRecordIndex - props.rowsPerPage + 1;
      pageRange = firstRecordIndex + '-' + lastRecordIndex + ' of ' + props.totalRecords;
      }
      else{
        let firstRecordIndex = lastRecordIndex - props.rowsPerPage + 1;
        pageRange = firstRecordIndex + '-' + props.totalRecords + ' of ' + props.totalRecords;
      }
      
      
    }
    return pageRange;
  };

  return (
    <Box sx={{ height: 'auto', width: '100%', overflow: 'auto', marginBottom: '80px' }}>
      <DataGrid
        style={{
          boxShadow: 1,
          // height:'350px'
        }}
        sx={customDataGridStyle}
        {...props}
        // autoPageSize
        autoHeight
        rowHeight={40}
        hideFooter='true'
        paginationMode='server'
        page={props.page}
        rows={props.rows}
        columns={props.columns}
        pagination
        rowCount={props.totalRecords}
        checkboxSelection={props.checkboxSelection}
        disableColumnMenu
        hideFooterSelectedRowCount={true}
        pageSize={props.rowsPerPage}
        disableSelectionOnClick
        // onPageSizeChange={(newPageSize) => props.onPageSizeChange(newPageSize)}
        onSelectionModelChange={(params) => {
          props.onSelection(params);
        }}
        onPageChange={(params) => props.onPageChange(params)}
        components={{
          Toolbar: props.toolbar,
          NoRowsOverlay: () => (<CustomNoRowsOverlay text={props.noRecordText}/>),
        }}
        componentsProps={{
          pagination: {
            labelRowsPerPage: 'No of records per page',
          },
        }}
      />
      {props.hidePagination===undefined&&<Box display={'flex'} justifyItems='center' pt={2}>
        <Box item flexGrow={1} display='flex'>
          <Box pl={1} pt={1}>
            <InputLabel htmlFor='filled-age-native-simple' sx={{ fontSize: '13px' }}>No of records per page</InputLabel>
          </Box>
          <Box pl={1}>
            <Select
              native
              size='small'
              sx={{ fontSize: '13px' }}
              value={props.rowsPerPage}
              inputProps={{
                name: 'age',
                id: 'filled-age-native-simple',
              }}
              onChange={(e) => props.onPageSizeChange(e.target.value)}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </Select>
          </Box>
          <Box pl={1} pt={1.3}>
            <InputLabel htmlFor='filled-age-native-simple' sx={{ fontSize: '13px' }}>{getPageDetails()}</InputLabel>
          </Box>
        </Box>
        <Box item >
          <Pagination
            count={Math.ceil(props.totalRecords / props.rowsPerPage)} page={props.page + 1} sx={[() =>
              ({ button: { backgroundColor: "#F2F2F2", color: '#637381', }, }),
            () => ({ ul: { "& .Mui-selected": { backgroundColor: '#36C96D', color: 'white', }, }, }),
            () => ({ ul: { "&:hover": { "& .Mui-selected": { backgroundColor: "#36C96D", }, }, }, }),]}
            shape='rounded'
            onChange={(e, v) => props.onPageChange(v - 1)} />
        </Box>
      </Box>}
    </Box>
  );
}
DataTables.defaultProps = {
  rows: [
    {
      id: 1,
      key: 1,
      Zonename: 'RND',
      Sitename: 'RND Center',
      country: 'Spain',
      Province_state: 'Guangdong',
      City: 'Donguang',
      Zonecode: 'DSZ34',
      Sitecode: '00123',
      Address: 'Lorem Ipsum is simply dummy',
    },
    {
      id: 2,
      key: 2,
      Zonename: 'RND',
      Sitename: 'RND Center',
      country: 'Donguang',
      Province_state: 'Guangdong',
      City: 'Donguang',
      Zonecode: 'DSZ34',
      Sitecode: '00123',
      Address: 'Lorem Ipsum is simply dummy ',
    },
  ],
  columns: [],
};
