import api from '../../config/http-common';
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
  let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.size + '&caseSensitive=false&sort=createdAt%7Cdesc&operator=AND';

  if (!CommonUtil.isEmptyString(filter.firstName)) {
    query = query + "&firstName=" + filter.firstName;
  }
  if (!CommonUtil.isEmptyString(filter.lastName)) {
    query = query + "&lastName=" + filter.lastName;
  }
  if (!CommonUtil.isEmptyString(filter.email)) {
    query = query + "&email=" + filter.email;
  }
  if (!CommonUtil.isEmptyString(filter.phone)) {
    query = query + "&phone=" + filter.phone;
  }
  if (!CommonUtil.isEmptyString(filter.roleName)) {
    query = query + "&roleName=" + filter.roleName;
  }
  return query;
}

const RoleSlice = (payload) => {
  return api.securedAxios().get("/api/v2.0/employee/roles" + buildQuery(payload));
}

const deactivateAccount = (id) => {
  return api.securedAxios().delete("/api/v2.0/employee/membership/" + id);
}

const activateAccount = (payload) => {
  return api.securedAxios().post("/api/v2.0/user/activate", payload);
}

const UserRoleService = {
  RoleSlice,
  deactivateAccount,
};

export default UserRoleService;