import React, { useState } from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PropTypes from "prop-types";
import { Typography } from '@material-ui/core';
import { ReactComponent as DropUpIcon } from "../../../src/assets/DropUp.svg";

function CustomDropdown(props) {

    // const [data] = useState(props.data);
    const [selectedData, updateSelectedData] = useState(props.selectedData);

    function handleChange(event) {
        updateSelectedData(event.target.value);
        // if (props.onSelectChange) props.onSelectChange(selectedData);
    }

    const ITEM_HEIGHT = 40;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <FormControl fullWidth>
            <InputLabel>{props.label}</InputLabel>
            <Select
                label={props.label}
                value={props.value}
                error={props.error}
                name={props.name}
                MenuProps={MenuProps}
                onChange={props.handleChange}
                disabled={props.disabled}
            >
                 <MenuItem value=""><em style={{fontFamily:"inherit" , fontStyle:"inherit"}}>{props.defaultSelect}</em></MenuItem>
                {props.data ? (
                    props.data.map((d, index) => (
                        <MenuItem key={index} value={d.id}>
                            <Typography variant="inherit" noWrap>
                                {d.name}
                            </Typography>
                        </MenuItem>
                    ))
                ) : (
                    <></>
                )}
            </Select>
        </FormControl>
    );
}
export default CustomDropdown;