import api from "../../config/http-common";

const getUserInfo = (userId) => {
    return api
        .securedAxios()
        .get(
            "/api/v2.0/user/" + userId
        );
};

const updateUserInfo = (user) => {
    return api
        .securedAxios()
        .put(
            "/api/v2.0/user/" + user.id, user
        );
};

const getUserById = (idOrEmailOrPhone) =>{
    return api.securedAxios().get("/api/v3/users?" + "idOrEmailOrPhone="+ idOrEmailOrPhone)
}

const UserService = {
    getUserInfo,
    updateUserInfo,
    getUserById
}

export default UserService;