import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable/DataTable";
import { useDispatch } from "react-redux";
import TitleBar from "../../components/v4/TitleBar";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddDoorIcon } from "../../assets/images/VisitingAccessLevels/addDoor.svg";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { ReactComponent as DeleteIconHeader } from "../../assets/images/VisitingAccessLevels/Delete.svg";
import { ReactComponent as FilterIcon } from "../../assets/FilterIcon.svg";
import IconButton from "@mui/material/IconButton";
import { deleteDoorsFromAccessLevel, listDoorsByAccessLevelId } from '../../slices/VisitorAccessLevels/VisitorAccessLevelSlice';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { REACT_ACCESSLEVELS_LIST, REACT_ADD_DEVICE_TO_ACCESSLEVEL } from "../../actions/EndPoints";
import { toast } from "react-toastify";
import CommonUtil from "../../Util/CommonUtils";
import AccessLevelFilter from "./AccessLevelFilter";
import MoreOption from "../../components/v4/MoreOption";
import { DeleteDoor } from "./DeleteDoor";
import { SetCheckInCheckOutDoor } from "./SetCheckInCheckOutDoor";
import Toast from "../../components/ToastContainer/CustomToast";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";


function ManageAccessLevel(props) {

  const [rows, setRows] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const history = useHistory();
  const [selectedIds, setSelectedIds] = useState([]);
  const [doorId, setDoorId] = useState([]);
  const [clear, setClear] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [doorDialog, setDoorDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [reload, setReload] = useState(false);
  const [updateDoor, setUpdateDoor] = useState(null);
  const [del, setDel] = useState(false);
  const [pageSize, setPageSize] = useState(0);

  const previousState = props?.history?.location?.state?.data;

  const defaultMenuData = [
    { label: 'Delete door', index: 0 },
    { label: 'Set as check-In/check-Out ', index: 1 },
  ];

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });
  const [filter, setFilter] = useState({
    accessGroupid: props?.history?.location?.state?.data?.id,
    name: "",
    id: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    accessGroupid: props?.history?.location?.state?.data?.id,
    name: "",
    id: "",
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging]);

  useEffect(() => {
    if (reload === true) {
      loadData(filter, paging);
      setReload(false);
    }
  }, [reload]);

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setRowsPerPage(size);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  const handleDelete = () => {
    const payload = {
      accessGroupId: deleteData.accGroupId,
      doorId: [deleteData.doorId]
    }
    dispatch(deleteDoorsFromAccessLevel(payload)).unwrap().then((data) => {
      if (data?.code === "DMSI0011") {
        toast.success(data.msg);
        setDel(true);
      } else {
        toast.error(data.msg)
      }
    })
  }

  const handleDeleteSelectedDoor = (rowData) => {
    if (CommonUtil.isEmpty(doorId)) {
      Toast("Please select the  door to Delete", "error");
      return
    }
    const payload = {
      accessGroupId: rowData.accGroupId,
      doorId: doorId
    }
    dispatch(deleteDoorsFromAccessLevel(payload)).unwrap().then((data) => {
      if (data?.code === "DMSI0011") {
        Toast(data.msg, "success");
        setDel(true)
      } else {
        Toast(data.msg, "error");
      }
    })
  }

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter, { page: page, rowsPerPage: rowsPerPage });
      setDel(false);
    } else {
      if (del === true) {
        setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
        setDel(false);
      }
    }
  }, [del]);

  const id = []
  useEffect(() => {
    const rowsData = selectedIds.map((value) => { return rows.filter((id) => id.id === value) });
    rowsData.map((door) => id.push(door[0].doorId));
    setDoorId(id);
  }, [selectedIds])

  const reloadlist = (value) => {
    setReload(value);
  };

  const handleClose = (value) => {
    setDeleteDialog(value);
    setDoorDialog(value);
  };

  const loadData = (filter, paging) => {
    dispatch(listDoorsByAccessLevelId(Object.assign(filter, paging))).unwrap().then((data) => {
      if (data?.code === 'DMSI0000') {
        setRows(data?.data?.accessGroupDoors);
        setPage(data?.data?.currentPage);
        setTotalPages(data?.data?.totalPages);
        setPageSize(data?.data?.pageSize);
      } else {
        setRows([]);
      }
    })
      .catch((er) => { });
  }

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };
  
  const handleFilter = (data) => {
    setClear(true);
    setPaging(defaultPaging);
    setRows(data);
    setFilter({
      id: data.id,
      accessGroupid: props?.history?.location?.state?.data?.id,
    });
  };
  const columns = React.useMemo(
    () => [
      {
        headerName: "",
        sortable: false,
        width: 5,
        renderCell: (params) => {
          return <div></div>;
        },
      },
      {
        field: "name",
        headerName: "Door name",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.name,
      },
      {
        field: "deviceProperty",
        headerName: "Door properties",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.deviceProperty === 'checkIn' ? 'Check-In' : 'Check-Out',
      },
      {
        field: "Actions",
        headerName: "Action",
        type: "actions",
        flex: 1,
        minWidth: 190,
        width: 150,
        headerAlign: "center",
        renderCell: (e) => (
          <Grid style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <MoreOption data={defaultMenuData} handleSelect={(option) => handleActions(option, e.row)} />
          </Grid>)
      },
    ], []);

  const handleActions = (action, data) => {
    if (action === 0) {
      setDeleteDialog(true);
      setDeleteData(data)
    } else if (action === 1) {
      setDoorDialog(true);
      setUpdateDoor(data);
    }
  };

  function handleTextOverflow(text) {
    if (text && text.length > 30) {
      return text.substring(0, 30) + '...';
    } else {
      return text;
    }
  }

  return (
    <>
      <>
        <TitleBar title={t("Manage Access Levels")} back_button={true} back={true}
          onClick={() => history.push({ pathname: REACT_ACCESSLEVELS_LIST })}
        />
      </>

      <Box
        display={"flex"}
        width={"100%"}
        justifyContent={'space-between'}
        alignItems={"center"}
      >
        <Box item p={1}>
          <Typography style={{ fontWeight: '410', fontSize: '16px', lineHeight: '25px', color: '#3D4977' }}>
            {'Access Level Name: ' + handleTextOverflow(previousState?.name)}
          </Typography>
        </Box>
        <Box item p={1}>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={'space-between'}
            alignItems={"center"}
          >
            <Stack direction="row" alignItems="center" spacing={1}>

              <Button variant="contained" onClick={() => { history.push({ pathname: REACT_ADD_DEVICE_TO_ACCESSLEVEL, state: previousState }) }} style={{ display: 'flex', justifyContent: 'space-around' }}>
                <AddDoorIcon /> <Typography marginLeft={'10px'}>Add door</Typography>
              </Button>

              <IconButton onClick={() => handleDeleteSelectedDoor({ accGroupId: props?.history?.location?.state?.data?.id })}>
                <DeleteIconHeader />
              </IconButton>

              <IconButton onClick={() => {
                setRows([]);
                setSelectedIds([]); 
               
                loadData(defaultFilter, defaultPaging);
              }} >
                <Refresh />
              </IconButton>

              <Box display={"flex"} flexDirection={"row-reverse"} alignItems={"center"}>
                <Box item>
                  {clear && (
                    <Typography
                      style={{
                        color: "#E3393C",
                        fontSize: "14px",
                        textDecoration: 'underline',
                        cursor: 'pointer'
                      }}
                      onClick={() => resetFilter()}
                    >
                      {"Clear Filter"}
                    </Typography>
                  )}
                </Box>
                <Box></Box>
                <IconButton>
                  <AccessLevelFilter onSubmit={(data) => { handleFilter(data) }} accGroupId={props?.history?.location?.state?.data?.id} />
                </IconButton>
              </Box>

            </Stack>
          </Box>
        </Box>
      </Box>


      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={(rows && rows.length === 0)  ? [] : rows}
          page={page}
          checkboxSelection
          onSelectionModelChange={(row) => setSelectedIds(row)}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {deleteDialog && <DeleteDoor
        open={deleteDialog}
        doorData={deleteData}
        name={"Door"}
        text={"Are you sure you want to delete this "}
        reloadlist={(value) => reloadlist(value)}
        onClose={(value) => handleClose(value)}
      />}
      {doorDialog && <SetCheckInCheckOutDoor
        open={doorDialog}
        onClose={(value) => handleClose(value)}
        updateData={updateDoor}
        reloadlist={(value) => reloadlist(value)} />}
    </>
  )
}

export default ManageAccessLevel;

export const demo = {
  "code": "DMSI0000",
  "msg": "Success.",
  "data": {
    "totalCount": 1,
    "currentPage": 1,
    "totalPages": 1,
    "pageSize": 1,
    "accessGroupDoors": [
      {
        "name": "Door-1",
        "id": "access1",
        "companyId": "8a8194b484a960d00184c7e0df1d08d3",
        "accGroupId": "8a81948984d1af9f0184d233e455002a",
        "doorId": "door1",
        "doorNo": null,
        "checkIn": null,
        "checkOut": null,
        "deviceProperty": "Check-In"
      },
      {
        "name": "Door-2",
        "id": "access2",
        "companyId": "8a8194b484a960d00184c7e0df1d08d3",
        "accGroupId": "8a81948984d1af9f0184d233e455002a",
        "doorId": "door2",
        "doorNo": null,
        "checkIn": null,
        "checkOut": null,
        "deviceProperty": "Check-In"
      },
      {
        "name": "Door-3",
        "id": "access3",
        "companyId": "8a8194b484a960d00184c7e0df1d08d3",
        "accGroupId": "8a81948984d1af9f0184d233e455002a",
        "doorId": "door3",
        "doorNo": null,
        "checkIn": null,
        "checkOut": null,
        "deviceProperty": "Check-In"
      }
    ]
  }
}