import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import HeaderToolbar from "../../components/HeaderToolbar";
// import QRCodeICON from '../../assets/qrcode.svg';
import SendQRCode from "../../assets/QRScan.svg";
import { Grid, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import DialogBox from '../../components/DialogBoxComponent/DialogBox';
import { LinkForQRCode } from '../../slices/Invitations/InvitationsSlice';
import { useDispatch } from "react-redux";
import SelfRegistrationFlow from "../../assets/images/SelfRegistrationImage.svg";
import api from '../../config/http-common';
import { getPermissionsListByroleId } from '../../slices/RoleandPermission/RoleandPermissionSlice';
import html2canvas from 'html2canvas';

export default function SelfRegistrationList() {

  const { t } = useTranslation();
  const [openQR, setOpenQR] = React.useState(false);
  const handleOpenQR = () => setOpenQR(true);
  const handleCloseQR = () => setOpenQR(false);
  const [link, setLink] = useState(0);
  const dispatch = useDispatch();

  const [roles, setRoles] = useState([]);
  const [selfFlowchart, setSelfFlowchart] = useState(true);

  var urlValue = window.location.href;
  var url = new URL(urlValue);
  var code = url.searchParams.get("code");

  const config = {
    headers: { companyId: localStorage.getItem('companyId') },
  };

  useEffect(() => {
    dispatch(LinkForQRCode(code, config))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI0511") {
          setLink(data?.data);
        }
      });
  }, []);

  // download QR code
  const handleQRCode = () => {
    // const qrCodeURL = document.getElementById('qrCodeEl')
    //   .toDataURL("image/png")
    //   .replace("image/png", "image/octet-stream");
    // console.log(qrCodeURL)
    // let aEl = document.createElement("a");
    // aEl.href = qrCodeURL;
    // aEl.download = "QR_Code.png";
    // document.body.appendChild(aEl);
    // aEl.click();
    // document.body.removeChild(aEl);

    html2canvas(document.getElementById('render')).then((canvas) => {
      const pngUrl = canvas.toDataURL('image/png');

      // Create a download link for the PNG image
      const a = document.createElement('a');
      a.href = pngUrl;
      a.download = 'QR_Code.png';
      a.click();
    });
  }

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId))
      .unwrap()
      .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
        });
        setRoles(l);
        setSelfFlowchart(l.includes("CREATE_SELF_REGISTRATION") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("CREATE_SELF_REGISTRATION")) {
        }
      });
  }, []);

  useEffect(() => {
    if (roles.includes("CREATE_SELF_REGISTRATION") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
    }
  }, []);

  return (
    <div>

      {selfFlowchart ? (
        <HeaderToolbar
          src={SendQRCode}
          onClick={handleOpenQR}
          title="Self Registration"
          tooltipTitle={t("SELFREGISTRATIONLBL")}
        />
      ) :

        (<HeaderToolbar
          title="Self Registration"
        />)
      }

      {selfFlowchart ? (
        <Grid>
          <img
            className="header-logo"
            alt="SelfRegister"
            src={SelfRegistrationFlow}
          />
        </Grid>) : (<div style={{ marginTop: '185px', paddingLeft: '300px' }}>
          <Typography variant="h5" style={{ color: '#3D4977', fontSize: '18px', fontWeight: '450' }}>
            {t('CVROLE010')}
          </Typography>
        </div>)
      }

      <DialogBox
        Header="Download QR Code"
        acceptText="Download"
        cancelText="Cancel"
        fullWidth={true}
        onClose={handleCloseQR}
        cancel={handleCloseQR}
        onSubmit={handleQRCode}
        open={openQR}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
        >
          <Grid container style={{ minHeight: "10vh", display: 'flex', justifyContent: "center", alignItems: "center", height: '40vh' }}>
            <div style={{ backgroundColor: '#36C96D', paddingTop: '20px', paddingRight: '20px',  paddingLeft: '20px', borderRadius: '10px', textAlign:'center' }} id='render'>
              <QRCode value={link} size={200} id="qrCodeEl" />
              <Typography style={{color:'#fff', fontSize: '22px'}} >ZKBio Zlink</Typography>
            </div>
          </Grid>
        </Grid>
      </DialogBox>


    </div>
  )
}
