import { Dialog, DialogTitle } from '@material-ui/core'
import { Box, DialogActions, DialogContent, Divider, FormGroup, Grid, IconButton, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import ViewIcon from '../../Invitations/ViewIcon';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { useTranslation } from "react-i18next";
import { countryData, timeZoneData } from './AddressFormData'
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
    textarea: {
        resize: "both",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        background: "#3d4977",
        width: "100%",
        padding: "5px",
        textAlign: "center",
        borderRadius: "5px",
        color: "#FFFFFF",
    },
}));

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: process.env.REACT_APP_BG_SWITCH,
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: "#52d869",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

function SiteViewDialog(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const [siteData, setSetData] = React.useState({});

    const [sitedetails, setSiteDetails] = React.useState(
        {
            'Name': props.data.name,
            'Site Type': props.data.siteTypeName,
            'Parent': props.data.parentName,
            'Description': props.data.description,
            'Address Line 1': props.data.address.addressLine1,
            'Address Line 2': props.data.address.addressLine2,
            'Address Line 3': props.data.address.addressLine3,
            'Address Type': props.data.address.addressTypeName
        }
    );
    const [addressDetails, setAddressDetails] = React.useState(
        {
            'Country': props.data.address.country,
            'State': props.data.address.state,
            'City': props.data.address.city,
            'Post Code': props.data.address.areaCode,
            'Latitude': props.data.address.latitude,
            'Longitude': props.data.address.longitude,
            'Radius': props.data.address.radius,
            'Time Zone': props.data.address.timeZone,
        }
    );

    useEffect(() => {
        setSiteDetails({...sitedetails, 
            'Name': props.data.name,
            'Site Type': props.data.siteTypeName,
            'Parent': props.data.parentName,
            'Description': props.data.description,
            'Address Line 1': props.data.address.addressLine1,
            'Address Line 2': props.data.address.addressLine2,
            'Address Line 3': props.data.address.addressLine3,
            'Address Type': props.data.address.addressTypeName,
        })
    },[props.data]);

    useEffect(() => {

        setAddressDetails({...addressDetails, 
          //  'Country': props.data.address.country,
            'State': props.data.address.state,
            'City': props.data.address.city,
            'Post Code': props.data.address.areaCode,
            'Latitude': props.data.address.latitude,
            'Longitude': props.data.address.longitude,
            'Radius': props.data.address.radius,
          //  'Time Zone': props.data.address.timeZone,
        })
    },[props.data]);

    useEffect(() => {
        let timeZone = ''
        timeZoneData.map((item, index) =>
            (item.id === props.data.address.timeZone ? timeZone = item.name : ''));
        
        countryData.map((item, index) =>
        (item.id === props.data.address.country && setAddressDetails
            ({ ...addressDetails, 'Country': item.name, 'Time Zone': timeZone })

        ))
    }, []);


    const [dayLightSaving, setDayLightSaving] = React.useState(props.data.address.dayLightSaving);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };


    const [state, setState] = React.useState(true)

    const handledaylight = (event) => {

        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.checked,
        });
    };

    return (
        <>
            <div onClick={handleOpen}>
                <ViewIcon />
            </div>
            <Dialog
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                className="custom-modal"
            >
                    <DialogTitle id="form-dialog-title">
                        <Box display="flex" m={1.5}>
                            <Box flexGrow={1}>
                                <Typography variant="h3" sx={{color:'#000'}}>
                                    {'Site Details'}
                                </Typography>
                            </Box>
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    right: 15,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            > <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <Divider />
                <DialogContent>
                    <Grid container md={12} xs={12} style={{ fontSize: 'large' }}>
                        <Grid container md={5.5} xs={12} spacing={2} >
                            {Object.entries(sitedetails).map(([key, value]) => (
                                <Grid container spacing={2} sx={{ marginLeft: '10px', marginTop: '5px' }}>
                                    <Grid item xs={4} ><Typography variant='body1'>{key}</Typography></Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={7}><Typography style={{ wordBreak: 'break-word' }} variant='inherit'>{value}</Typography></Grid>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid md={1}>
                            <Divider orientation="vertical" sx={{ marginLeft: '20px', marginRight: '30px' }} />
                        </Grid>
                        <Grid container md={5.5} xs={12} spacing={2}>
                            {Object.entries(addressDetails).map(([key, value]) => (
                                <Grid container spacing={2}
                                    sx={{ marginLeft: '10px', marginTop: '5px' }}
                                >
                                    <Grid item xs={4} ><Typography variant='body1'>{key}</Typography></Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={7}><Typography variant='inherit'>{value}</Typography></Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup>
                            <Grid xs={12}
                                style={{ display: 'flex', margin: '20px 10px' }}
                                container
                                alignItems="center"
                            >
                                <Grid item><Typography variant='body1'>{t("ADD011")}</Typography></Grid> &nbsp;
                                <Grid item style={{ display: 'flex' }}> &nbsp;
                                    <Typography variant='body1'>{t("DAYLIGHTON")}</Typography>/
                                    <Typography variant='body1'>{t("DAYLIGHTOFF")}</Typography>
                                </Grid>
                                <Grid item >
                                    <IOSSwitch
                                        checked={dayLightSaving}
                                        onChange={handledaylight}
                                        name="dayLightSaving"
                                    />
                                </Grid>
                            </Grid>
                        </FormGroup>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default SiteViewDialog