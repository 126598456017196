import { Avatar, Typography } from "@material-ui/core";
import { Box, Link, Tooltip, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SendWatchlistIcon from "../../assets/USA_Images/WatchlistIcon.svg";
import DataTable from "../../components/DataTable/DataTable";
// import { GridActionsCellItem } from '@mui/x-data-grid-pro';

import "../../App.css";
import { toast } from "react-toastify";
import Search from "../../components/SearchTab/Search";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import { useTranslation } from "react-i18next";
import { getSiteTypes } from "../../slices/Site/SiteSlice";
import WatchlistTemplateForm from "./WatchlistTemplateForm";
import WatchlistFilterForm from "./WatchlistFilterForm";
import {
  listWatchlistTemplates,
  updateWatchlistTemplate,
} from "../../slices/Watchlist/WatchlistSlice";
import WatchlistViewProfile from "../../components/VisitorActionsComponents/WatchlistViewProfile";
import DeleteIcon from "./DeleteIcon";
import DeleteWatchlist from "./DeleteWatchlist";
import EditWatchlistIcon from "./EditWatchlistIcon";
import HeaderToolbar from "../../components/HeaderToolbar";
import WatchlistInfo from "./WatchlistInfo";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import { getAllWatchlistTemplate } from "../../slices/WatchlistGlobal/WatchlistGlobalSlice";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";

export default function WatchlistTemplate(props) {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [clear, setClear] = React.useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteVisitor, setOpenDeleteVisitor] = useState(false);
  const [watchlistId, setWatchlistId] = useState();
  const [openInformation, setOpenInformation] = React.useState(false);
  const zlink = localStorage.getItem("redirectFrom");

  const [roles, setRoles] = useState([]);
  const [createWatchlist, setCreateWatchlist] = useState(true);
  const [listWatchlist, setListWatchlist] = useState(true);
  const [editWatchlist, setEditWatchlist] = useState(true);
  const [deleteWatchlist, setDeleteWatchlist] = useState(true);
  const [viewWatchlist, setViewWatchlist] = useState(true);

  const [selectedObject, setSelectedObject] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    contactEmail: "",
    contactPhoneNumber: "",
    visitorCompanyName: "",
    purposeTypeId: "",
  });

  const [filter, setFilter] = React.useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    contactEmail: "",
    contactPhoneNumber: "",
    visitorCompanyName: "",
    purposeTypeId: "",
    isPagable: true,
  });

  const defaultFilter = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    contactEmail: "",
    contactPhoneNumber: "",
    visitorCompanyName: "",
    purposeTypeId: "",
    isPagable: true,
  };

  const applyFilter = (filterData) => {
    setClear(true);
    const query = {
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      email: filterData.email,
      phoneNumber: filterData.phoneNumber,
      visitorCompanyName: filterData.visitorCompanyName,
      contactEmail: filterData.contactEmail,
      contactPhoneNumber: filterData.contactPhoneNumber,
      purposeTypeId: filterData.purposeTypeId,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setClear(false);
    setFilter({
      ...defaultFilter,
    });
  };

  const handleChange = (newPage, size) => {
    setPage(newPage - 1);
    loadData(newPage == 0 ? 0 : newPage - 1, size);
    setRowsPerPage(size);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadData(0, rowsPerPage);
  }, [filter]);

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setCreateWatchlist(l.includes("CREATE_EDIT_DELETE_WATCHLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setEditWatchlist(l.includes("CREATE_EDIT_DELETE_WATCHLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setDeleteWatchlist(l.includes("CREATE_EDIT_DELETE_WATCHLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setViewWatchlist(l.includes("VIEW_CV_WATCHLIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setListWatchlist(l.includes("WATCHLIST_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("WATCHLIST_LIST")) {
          const payload = {
            filter: filter,
            page: page,
            rowsPerPage: rowsPerPage,
          };
          dispatch(getAllWatchlistTemplate(payload))
            .unwrap()
            .then((data) => {
              if (data?.code === "CVAI1000" && data?.data) {
                setTotalRecords(data?.data?.totalCount);
                setRows(data?.data?.watchLists);
                setPage(data?.data?.currentPage + 1);
                setTotalPages(data?.data?.totalPages);
              } else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("WATCHLIST_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {   
      }
  }, []);

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: filter,
      page: page,
      rowsPerPage: rowsPerPage,
    };

    dispatch(getAllWatchlistTemplate(payload))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI1000" && data?.data) {
          setTotalRecords(data?.data?.totalCount);
          setRows(data?.data?.watchLists);
          setPage(data?.data?.currentPage + 1);
          setTotalPages(data?.data?.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog);
    setSelectedObject(params);
  };

  const handleDeleteVisit = (params) => {
    setOpenDeleteVisitor(!openDeleteVisitor);
    setWatchlistId(params.id);
  };

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(0, rowsPerPage)
      return;
    }

    const payload = {
      filter: filter,
      page: page - 1,
      rowsPerPage: rowsPerPage,
    };
    dispatch(getAllWatchlistTemplate(payload))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI1000" && data?.data) {
          
          const filteredRows = data?.data?.watchLists.filter((test) => {
            return (
              test.firstName.toLowerCase().includes(searchedVal.toLowerCase()) ||
              test.lastName.toLowerCase().includes(searchedVal.toLowerCase()) ||
              test.email.toLowerCase().includes(searchedVal.toLowerCase()) ||
              test.phoneNumber.toLowerCase().includes(searchedVal.toLowerCase()) ||
              test.contactEmail.toLowerCase().includes(searchedVal.toLowerCase()) ||
              test.contactPhoneNumber
                .toLowerCase()
                .includes(searchedVal.toLowerCase()) ||
              `${test.firstName} ${test.lastName}`
                .toLowerCase()
                .includes(searchedVal.toLowerCase())
      
            );
          });
          setRows(filteredRows);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
   
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
      return text.substring(0, 17) + '...';
    } else {
      return text;
    }
  }

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
            cursor: 'pointer',
          }}
          alt={params.row.firstName}
          src={params.row.profilePhotoURL !== undefined ? params.row.profilePhotoURL : null}
        >{params.row.firstName == null ? "" : params.row.firstName.charAt(0)}</Avatar>
      }
      child={<Avatar
        style={{
          width: "300px",
          height: "300px",
          border: "12px solid #FFFF",
          fontSize: "100px",
          borderRadius: "1px",
          cursor: 'pointer',
        }}
        alt={params.row.firstName}
        src={params.row.profilePhotoURL !== undefined ? params.row.profilePhotoURL : null}
      >{params.row.firstName == null ? "" : params.row.firstName.charAt(0)}</Avatar>}
    />
  )

  const columns = React.useMemo(() => [
    {
      field: "profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return (
          ZoomHandaler(params)
        );
      },
    },

    {
      field: "name",
      headerName: t("COMMON014"),
      flex: 1,
      width: 110,
      // valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
      valueGetter: (params) =>
        params.row.lastName !== null ? (params.row.firstName + " " + params.row.lastName) : (params.row.firstName),
      renderCell: (params) => (
        <Tooltip title={` ${params.row.lastName !== null ? (params.row.firstName + " " + params.row.lastName) : (params.row.firstName)}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.lastName !== null ? (params.row.firstName + " " + params.row.lastName) : (params.row.firstName))}</span>
        </Tooltip>
      ),
    },

    {
      field: "email",
      headerName: t("COMMON042"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.email,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.email}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.email)}</span>
        </Tooltip>
      ),
    },

    {
      field: "phoneNumber",
      headerName: t("COMMON063"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.phoneNumber ? (params.row.phoneCountryCode + "" + - params.row.phoneNumber) : "",
    },

    {
      field: "contactEmail",
      headerName: t("WATCHLISTTEMP002"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.contactEmail,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.contactEmail}`} placement="bottom-start">
          <span>{handleTextOverflow(params.row.contactEmail)}</span>
        </Tooltip>
      ),
    },

    {
      field: "contactPhoneNumber",
      headerName: t("WATCHLISTTEMP009"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.contactPhoneNumber ? (params.row.contactPhoneCountryCode + "" + - params.row.contactPhoneNumber) : "",
    },

    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        
        (<>
        {viewWatchlist &&
        <GridActionsCellItem
          icon={<WatchlistViewProfile rowData={params.row} />}
          label="View"
        />}
        </>),

        (<>
        {editWatchlist &&
        <GridActionsCellItem
          icon={<EditWatchlistIcon />}
          label="Edit"
          onClick={() => handleEditVisit(params.row)}
        />}
        </>),

        (<>
        {deleteWatchlist &&
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Delete"
          onClick={() => handleDeleteVisit(params)}
        />}
        </>),

      ],
    },
  ]);

  return (
    <>
      {!zlink ? (
        <>
          <HeaderToolbar
            src={SendWatchlistIcon}
            onClick={handleOpen}
            title={t('WATCHLISTTEMP001')}
            tooltipTitle={t('WATCHLIST005')}
          />
        </>
      ) : (
        <>

        {createWatchlist ?(
          <HeaderToolbar
            src={SendWatchlistIcon}
            onClick={handleOpen}
            title={t('WATCHLISTTEMP001')}
            tooltipTitle={t('WATCHLIST005')}
          />):
          
          (<HeaderToolbar
            title={t('WATCHLISTTEMP001')}
            tooltipTitle={t('WATCHLIST005')}
          />)}

          <Box style={{ marginLeft: '145px', marginTop: '-55px' }}>
            <IconButton onClick={() => setOpenInformation(!openInformation)}>
              <WatchlistInfo />
            </IconButton>
          </Box>
        </>
      )}

   {listWatchlist &&
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >

      <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <WatchlistFilterForm
            rowsPerPage={rowsPerPage}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>

        {zlink ? (
          <>
            <Box item>
              <Tooltip title={"Refresh"}>
                <IconButton onClick={() => loadData(0, rowsPerPage)}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Box>
          </>) : (<></>)}

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(0, rowsPerPage)}
          />
        </Box>

      </Box>}

    {listWatchlist ? (
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
        ):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
                <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                 {t('CVROLE023')}
             </Typography>
           </div>)
    }     

      {open && (
        <WatchlistTemplateForm
          selectedObject={selectedObject}
          open={open}
          handleClose={() => {
            handleClose(loadData(0, rowsPerPage));
          }}
        />
      )}

      {openEditDialog && (
        <WatchlistTemplateForm
          selectedObject={selectedObject}
          open={openEditDialog}
          handleClose={() => {
            setOpenEditDialog(false);
            loadData(0, rowsPerPage);
            setSelectedObject({
              id: "",
              firstName: "",
              lastName: "",
              email: "",
              phoneNumber: "",
              contactEmail: "",
              contactPhoneNumber: "",
              visitorCompanyName: "",
              purposeTypeId: "",
            });
          }}
        />
      )}

      {openDeleteVisitor && (
        <DeleteWatchlist
          open={openDeleteVisitor}
          handleClose={(data) => {
            setOpenDeleteVisitor(data);
            loadData(0, rowsPerPage);
          }}
          DeleteId={watchlistId}
        />
      )}
    </>
  );
}
