
import React, { useState } from "react";
import { Grid, styled, TextField, ThemeProvider } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CalenderIcon from '../../assets/Calender';
import {
  DesktopDateTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { createTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import Toast from "../../components/ToastContainer/CustomToast";


const MuiTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: process.env.REACT_APP_BG_ICON,
    },
    secondary: {
      main: "#f50057",
    },
  },
});


const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  color: process.env.REACT_APP_BG_ICON,
  border: process.env.REACT_APP_BG_BORDER,
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: process.env.REACT_APP_BG_ICON,
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  color: process.env.REACT_APP_BG_ICON,
  border: process.env.REACT_APP_BG_BORDER,
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: process.env.REACT_APP_BG_ICON,
    color: "#FFFF",
  },
}));

const useStyles = makeStyles((theme) => ({
  cssLabel: {
    color: '#0000008a'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#1976d2 !important',
    }
  },
  cssFocused: {
    color: '#1976d2 !important'
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important'
  },
  dialogbox: {
    "& .css-hlj6pa-MuiDialogActions-root": {
      marginLeft: '290px !important',
    },
    "& .css-ypiqx9-MuiDialogContent-root": {
      padding: '0px !important',
    },
  },

}));


export default function DeviceEventDateTimePicker(props) {
  const classes = useStyles();
  const [dateOpen, setDateOpen] = useState(false);
  const [scheduledStartDate, setScheduledStartDate] = React.useState(
    new Date()
  );

  const handleDate = (e) => {
    setScheduledStartDate(e);
    props.SelectedDate(e);
  };

  return (

    <ThemeProvider theme={MuiTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDateTimePicker
          disabled={props.disabled}
          hideTabs={false}
          components={{
            OpenPickerIcon: CalenderIcon,
            LeftArrowIcon: CustomIconLeft,
            RightArrowIcon: CustomIconRight,
          }}
          dateRangeIcon={<CalenderIcon />}
          label={props.label}
          disablePast={props.disablePast}
          disableFuture={props.disableFuture}
          onChange={props.onChange}
          onChangeText={props.onChangeText}
          inputFormat="yyyy-MM-dd HH:mm:ss"
          value={props.value}
          error={props.error}
          minDateTime={props.minDateTime}
          maxDateTime={props.maxDateTime}
          type="datetime-local"
          InputProps={{
            classes:
            {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          renderInput={(params) => (
            <TextField style={{ width: '-webkit-fill-available' }}
              {...params}
              readOnly={true}
              error={props.error}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
            />
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>

  );
}
