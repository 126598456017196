import React, { useEffect, useState } from "react";
import HeaderToolbar from '../../components/HeaderToolbar'
import FlowchartTabs from './FlowchartTabs'
import { getPermissionsListByroleId } from '../../slices/RoleandPermission/RoleandPermissionSlice';
import api from '../../config/http-common';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

function VisitingFlowchart() {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [roles, setRoles] = useState([]);
  const [visitFlowchart, setVisitFlowchart] = useState(true);

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setVisitFlowchart(l.includes("VISITING_FLOWCHART") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("VISITING_FLOWCHART")) {
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("VISITING_FLOWCHART") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);


    return (
        <>
            <HeaderToolbar title=" Visiting Flowchart" />
               
               {visitFlowchart ?(<FlowchartTabs />):
               (<div style={{marginTop: '185px', paddingLeft: '300px'}}>
                    <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                        {t('CVROLE007')}
                    </Typography>
                </div>)
                }
        </>
    )
}

export default VisitingFlowchart