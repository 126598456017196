import React, { useState } from "react";
import Webcam from "react-webcam";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import b64toBlob from "b64-to-blob";

// Webcamera access and take snapshot using third party library react-webcam

const videoConstraints = {
  width: 220,
  height: 200,
  facingMode: "user",
};

// Taking snapshot on capture and dispatch Image source to Redux Store
export const WebcamCapture = (props) => {
  const [image, setImage] = useState(props.profilePhoto);
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    var contentType = 'image/jpeg';
    var b64Data = imageSrc.replace("data:image/jpeg;base64,", "");
    props.base64Image(b64Data);
    var blob = b64toBlob(b64Data, contentType);
    props.onsetProfile(blob);
  });

  return (
    <div className="webcam-container">
      <Grid conatiner display={'flex'} direction='column' justifyContent='center' alignItems='center'>
        <div className="webcam-img">
          {image ?
            <img src={image}  style={{ borderStyle: 'dashed ', borderRadius: '50%', padding: '5px', color: '#D2D2D2', height: '200px', width: '220px' }} onClick={() => setImage("")}/>
            :
            <Webcam
              audio={false}
              height={200}
              style={{ borderStyle: 'dashed ', borderRadius: '50%', padding: '5px', color: '#D2D2D2' }}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={220}
              videoConstraints={videoConstraints}
              alt=""
              onClick={(e) => {
                e.preventDefault();
                capture();
              }}
            />
          }
        </div>
      </Grid>
    </div>
  );
};
