import { Box, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import checklistIcon from "../../assets/checklist.svg";
import DataTable from "../../components/DataTable/DataTable";

import "../../App.css";
import Search from "../../components/SearchTab/Search";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import EditChecklistIcon from "./EditChecklistIcon";
import DeleteChecklist from "./DeleteChecklist";
import HeaderToolbar from "../../components/HeaderToolbar";
import { getChecklistItems, listChecklistTemplates } from "../../slices/CheckListSlice/CheckListSlice";
import { useHistory } from "react-router-dom";
import ChecklistItemAddUpdate from "./ChecklistItemAddUpdate";
import { useLocation } from "react-router-dom";
import ChecklistDelete from "./ChecklistDelete";
import yesIcon from "../Checklist/YesNoSvg/Tick.svg"
import NoIcon from "../Checklist/YesNoSvg/Line.svg"



export default function ChecklistItems(props) {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [clear, setClear] = React.useState(false);
  const [totalRecords, setTotalRecords] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteChecklistItem, setOpenDeletChecklistItem] = useState(false);
  const location = useLocation();
  const [checklitItemIDToDelete, setChecklitItemIDToDelete] = useState();
  const [selectedObject, setSelectedObject] = useState({
    id: "",
    name: "",
    companyId: "",
    code: "",
    description: "",
  });

  const [filter, setFilter] = React.useState({
    id: "",
    name: "",
    companyId: "",
    code: "",
    description: "",
  });

  const defaultFilter = {
    id: "",
    name: "",
    companyId: "",
    code: "",
    description: "",
  };

  const applyFilter = (filterData) => {
    setClear(true);
    const query = {
      id: filterData.id,
      name: filterData.name,
      companyId: filterData.companyId,
      code: filterData.code,
      description: filterData.description,
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setClear(false);
    setFilter({
      ...defaultFilter,
    });
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setRowsPerPage(size);
    loadData(newPage, size);

  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadData(page, rowsPerPage);
  }, [filter]);

  const loadData = (page, rowsPerPage) => {
    const payload = {
      templateId: location.state.data.id,
      pageNumber: 0,
      pageSize: 100,
    };

    setSelectedObject({});
    dispatch(getChecklistItems(payload))
      .unwrap()
      .then((data) => {
        console.info(data)
        if ((data.code = "CVAI0000" && data.data)) {
          setTotalRecords(data.data.totalCount);
          setRows(data.data.questionaries);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });

  };


  const handleEditVisit = (params) => {
    setOpenEditDialog(!openEditDialog);
    setSelectedObject(params);
    // setSelectedObject({ code: location.state.data.id });
  };


  const handleDeleteChecklistItem = (params) => {
    setOpenDeletChecklistItem(!openDeleteChecklistItem);
    setSelectedObject(params);
    setChecklitItemIDToDelete(params.id);
  };


  const globalsearch = (searchedVal) => {
    const payload = {
      templateId: location.state.data.id,
      pageNumber: 0,
      pageSize: 100,
    };

    setSelectedObject({});
    dispatch(getChecklistItems(payload))
      .unwrap()
      .then((data) => {
        console.info(data)
        if ((data.code = "CVAI0000" && data.data)) {
          const filteredRows = data.data.questionaries.filter((test) => {
            return (
              test.item.toLowerCase().includes(searchedVal.toLowerCase())
              || test.options.toLowerCase().includes(searchedVal.toLowerCase())
              || test.mandatory.toLowerCase().includes(searchedVal.toLowerCase())
            );
          });
          setRows(filteredRows);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
    
  };
  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "question",
      headerName: "Item",
      flex: 1,
      width: 180,
      valueGetter: (params) => params.row.question,
    },

    {
      field: "options",
      headerName: "Options",
      flex: 1,
      minWidth: 100,
      valueGetter: (params) => params.row.options,
    },

    {
      field: "mandatory",
      headerName: "Mandatory",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => params.row.mandatory === "Yes" ? <div> <img src={yesIcon} /> </div>
        : params.row.mandatory === "No" ? <div><img src={NoIcon} /> </div> : params.row.mandatory,
    },

    {
      field: "Actions",
      headerName: "Action",
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [

        <GridActionsCellItem
          icon={<EditChecklistIcon />}
          label="Edit"
          onClick={() => handleEditVisit(params.row)}
        />,

        <GridActionsCellItem
          icon={<DeleteChecklist />}
          label="Delete"
          onClick={() => handleDeleteChecklistItem(params)}
        />,
      ],
    },
  ]);

  return (
    <>
      <HeaderToolbar src={checklistIcon} back_button={true}
        onClick={handleOpen}
        title="Checklist Items"
        tooltipTitle="Add Checklist Items" />
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(page, rowsPerPage)}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {open && (
        <ChecklistItemAddUpdate
          selectedObject={selectedObject}
          templateId={location.state.data.id}
          open={open}
          handleClose={() => {
            handleClose(loadData(page, rowsPerPage));
          }}
        />
      )}

      {openEditDialog && (
        <ChecklistItemAddUpdate
          selectedObject={selectedObject}
          open={openEditDialog}
          templateId={location.state.data.id}
          handleClose={() => {
            setOpenEditDialog(false);
            setSelectedObject({
              id: "",
              name: "",
              code: "",
              description: "",
            });
            loadData(page, rowsPerPage);
          }}
        />
      )},

      {openDeleteChecklistItem && (
        <ChecklistDelete
          open={openDeleteChecklistItem}
          handleClose={(data) => {
            setOpenDeletChecklistItem(data);
            handleClose(loadData(page, rowsPerPage));
          }}
          DeleteId={checklitItemIDToDelete}
        />
      )}
    </>
  );
}
