// OAuth-client
export const REACT_LOGIN = '/vis/login';
export const REACT_VERIFY_COMPANY = '/vis/verify-company';
export const REACT_LOGOUT = '/vis/login';

// Rect-Routes
export const REACT_URL_DASHBOARD = '/vis/dashboard';
export const REACT_URL_INVITATIONS = '/vis/v2/invitaions';
export const REACT_URL_REGISTRATIONS = '/vis/v2/registrations';
export const REACT_URL_APPROVAL = '/vis/approvals';
export const REACT_URL_BLOCKED_VISITORS = '/vis/blockedVisitors';
export const REACT_URL_VISITORLOGS = '/vis/logs';
export const REACT_URL_USERPROFILE= '/vis/userProfile';
export const REACT_URL_COMPANYRPROFILE= '/vis/companyProfile';
export const REACT_URL_PERSON= '/vis/person';
export const REACT_URL_ROLE= '/vis/role';
export const REACT_URL_SITE= '/vis/site';
export const REACT_URL_SITETYPE= '/vis/siteType';
export const REACT_URL_ADDRESSTYPE= '/vis/addressType';
export const REACT_URL_CHECKINOUTDEVICES= '/vis/checkInOutDevices';
export const REACT_URL_PURPOSETYPE ='/vis/purposeType'
export const REACT_URL_WATCHLIST = '/vis/watchlist-template';
export const REACT_URL_CHECKLIST = '/vis/checklist-template';
export const REACT_URL_VISITORACCESSLEVEL = '/vis/visitorAccesslevel';
export const REACT_URL_CHECKLIST_ITEM = '/vis/checklist-items';
export const REACT_URL_RESTRICTTYPE ='/vis/visitorRestrictType';
export const REACT_URL_ADDDEVICES = '/vis/addDevice';
export const REACT_URL_ADDCHECKOUT = '/vis/addCheckOut';
export const REACT_URL_URGENTMESSAGES = '/vis/urgentMessages';
export const REACT_URL_FLOWCHART = '/vis/flowChart';
export const REACT_VISITOR_QR_PAGE = '/vis/qr-code';
export const REACT_DEVICE_LIST = '/vis/deviceList';
export const REACT_DEVICE_INFO = '/vis/deviceInfo';
export const REACT_URL_DEVICELIST= '/vis/device-list';
export const REACT_DEVICE_ADD_INSTRUCTION='/vis/device-add-instruction'
export const REACT_ADD_DEVICE='/vis/device-add'
export const REACT_ACCESSLEVELS_LIST = '/vis/accessLevelsList';
export const REACT_TIME_SLOTS='/vis/timeslots'
export const REACT_VIEW_DEVICE='/vis/view-device'
export const REACT_MANAGE_ACCESSLEVELS='/vis/manageAccessLevels'
export const REACT_ADD_TIMESLOTS='/vis/addTimeSlots'
export const REACT_ADD_DEVICE_TO_ACCESSLEVEL='/vis/access-addDevice'
export const REACT_UPDATE_TIMESLOT='/vis/update-timeSlot'
export const REACT_URL_ZONE='/vis/zone'
export const REACT_URL_WALKINREGISTRATION='/vis/v2/walkinRegistartion';
export const REACT_URL_REPORTINVITATION='/vis/v2/Report-Invitation';
export const REACT_URL_REPORTREGISTRATION='/vis/v2/Report-SelfRegistartion';
export const REACT_VISITOR_ACCESS_LEVEL_LIST='/vis/access-level-list'
export const REACT_URL_VISITINVITE = "/vis/invitations";
export const REACT_URL_WALKINVISIT = "/vis/walkInRegistration";
export const REACT_URL_WATCHLISTGLOBAL = "/vis/watchlist";
export const REACT_URL_HEALTHQUESTIONARIES = "/vis/healthQuestionarie";
export const REACT_URL_REPORTLOGS = "/vis/ReportLogs"
export const REACT_URL_ROLEANDPERMISSION = "/vis/RoleAndPermission";
export const REACT_URL_SELFREGISTARTIONS = "/vis/SelfRegistration";
export const REACT_URL_DEVICEREPORTEVENTS = "/vis/events";
export const REACT_URL_UPDATEROLEANDPERMISSION = '/vis/updateRoleAndPermission';
export const REACT_URL_VIEWROLEANDPERMISSION = '/vis/ViewRoleAndPermission';




