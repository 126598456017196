import { createTheme } from "@material-ui/core";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDateTimePicker from "@mui/lab/DesktopDateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
  createFilterOptions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { useDispatch } from "react-redux";
import CalenderIcon from "../../assets/Calender";
import { allHostList, listHost } from "../../slices/HostSlice";
import { createInvitation } from "../../slices/Invitations/InvitationsSlice";
import { getSitesList } from "../../slices/Site/SiteSlice";
import CommonUtil from "../../Util/CommonUtils";
import jwt_decode from "jwt-decode";
import Toast from "../../components/ToastContainer/CustomToast";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
import CancelButtons from "../../components/DialogBoxComponent/CancelButtons";
import { listVisitorTypeZlink } from "../../slices/PerpouseType/PerpouseTypeSlice";
import CommonPhoneInput from "../../components/CustomInputs/CommonPhoneInput";
import moment from "moment";
import CustomDateTimePicker from "../../components/CustomInputs/CustomDateTimePicker";

const MuiTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: process.env.REACT_APP_BG_ICON,
    },
    secondary: {
      main: "#f50057",
    },
  },
});


const useStyles = makeStyles((theme) => ({
  cssLabel: {
    color: '#0000008a'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#1976d2 !important',
    }
  },
  cssFocused: {
    color: '#1976d2 !important'
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important'
  },

}));

const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  color: process.env.REACT_APP_BG_ICON,
  border: process.env.REACT_APP_BG_BORDER,
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: process.env.REACT_APP_BG_ICON,
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  color: process.env.REACT_APP_BG_ICON,
  border: process.env.REACT_APP_BG_BORDER,
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: process.env.REACT_APP_BG_ICON,
    color: "#FFFF",
  },
}));

export default function CreateNewInvitation(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedHost, setSelectHost] = useState({});
  const [data, setData] = useState();
  const [hostList, setHostList] = useState([]);
  const [siteData, setSiteData] = useState([]);
  const [phoneInput, setPhoneInput] = useState("");
  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );

  var today = new Date();

  const zlink = localStorage.getItem("redirectFrom");

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";

  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T" +
    (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
    ":" +
    (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());

  var changeDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T" +
    (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
    ":" +
    (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());

  const [payload, setPayload] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    phoneCountryCode: "",
    purposeTypeId: "",
    visitorTypeId: "",
    hostId: "",
    visitorCompanyName: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    scheduledchangeDate: CommonUtil.formatToUtc(changeDate),
    earlyCheckInTime: "2022-04-11T21:00:00",
    lateCheckout: "2022-04-11T21:00:00",
    visitMode: "INVITATION",
    earlyCheckin: false,
    profilePhoto: "str.jpg",
  });

  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [countryCode, setCountryCode] = React.useState('')

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    emailId: false,
    phoneNumber: false,
    purposeTypeId: false,
    visitorTypeId: false,
    hostId: false,
    visitorCompanyName: false,
    siteId: false,
    profilePhoto: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadVisitType();
    loadHosts();
    loadSites();
  };

  const loadVisitType = () => {
    const query = {
      id: "",
      name: "",
      pageNumber: 0,
      pageSize: 0,
      isPageable: true,
    };
    dispatch(listVisitorTypeZlink(query))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI0000" && data.data) {
          setData(data?.data?.purposeTypes);
        } else {
          setData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadHosts = () => {
    const payload = {
      pageNumber: 1,
      pageSize: 100,
      //email: decoded_jwtToken.email,
    };
    // dispatch(listHost(payload))
    dispatch(allHostList(payload))
      .unwrap()
      .then((data) => {
        if (data?.code === "OMSI0000") {
          // getSelectedItem(data?.data?.employees[0]);
          setHostList(data?.data?.employees);
        } else {
          getSelectedItem();
          setHostList([]);
        }
      });
  };

  const loadSites = () => {
    dispatch(getSitesList(data))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVSI0000") {
          setSiteData(data?.data?.site);
        } else {
          setSiteData([]);
        }
      });
  };

  const handleSumbit = () => {
    if (CommonUtil.isEmptyString(payload.firstName)) {
      Toast("Please Enter First Name", "error");
      setError({ firstName: true });
      return;
    }

    if (!CommonUtil.isValidName(payload.firstName)) {
      Toast("Please Enter Valid First Name", "error");
      setError({ firstName: true });
      return;
    }

   

    if (
      CommonUtil.isEmptyString(payload.emailId) &&
      CommonUtil.isEmptyString(payload.phoneNumber)
    ) {
      Toast("Email or mobile number is mandatory", "error");
      setError({ emailId: true });
      return;
    }

    if (!CommonUtil.isValidEmails(payload.emailId)) {
      setError({ ...error, emailId: true });
      Toast(t("CORRECTEMAIL"), "error");
      return;
    }

    if (
      decoded_jwtToken.email === payload.emailId.trim() ||
      payload.emailId.trim() === selectedHost.email
    ) {
      setError({ emailId: true });
      Toast(
        "Visitor email can not be same as Host or logged user email",
        "error"
      );
      return;
    }

    if (
      payload.phoneNumber !== null &&
      payload.phoneNumber !== undefined &&
      payload.phoneNumber !== ""
    ) {
      if (decoded_jwtToken.phone === payload.phoneNumber) {
        setError({ phoneNumber: false });
        Toast(t("INVITATIONFORM009"), "error");
        return;
      }
    }

    if (CommonUtil.isEmptyString(payload.purposeTypeId)) {
      Toast("Purpose Type is mandatory", "error");
      setError({ purposeTypeId: true });
      return;
    }

    if (CommonUtil.isEmptyString(payload.visitorCompanyName)) {
      zlink ? (Toast("Visitor company name is required", "error"))
        :
        (Toast("Company name is mandatory", "error"))
      setError({ visitorCompanyName: true });
      return;
    }

    if (CommonUtil.isEmpty(selectedHost)) {
      Toast("Host name is mandatory", "error");
      setError({ selectedHost: true });
      return;
    }

    if (CommonUtil.isEmptyString(payload.siteId)) {
      Toast("Site is Mandatory", "error");
      setError({ siteId: true });
      return;
    }

    if (
      payload.scheduledStartDate === null ||
      payload.scheduledStartDate === undefined ||
      payload.scheduledStartDate === ""
    ) {
      setError({ scheduledStartDate: true });
      Toast("Scheduled start date is mandatory", "error");
      return;
    }

    if (
      payload.scheduledEndDate === null ||
      payload.scheduledEndDate === undefined ||
      payload.scheduledEndDate === ""
    ) {
      setError({ scheduledEndDate: true });
      Toast("Scheduled end date is mandatory", "error");
      return;
    }

    if (CommonUtil.isEmptyString(countryCode) && !CommonUtil.isEmptyString(payload.phoneNumber)) {
      Toast(t("COMMON133"), "error");
      return
    }

    if (!CommonUtil.isEmptyString(countryCode) && CommonUtil.isEmptyString(payload.phoneNumber)) {
      Toast(t("COMMON134"), "error");
      return
    }

    if (new Date(payload.scheduledStartDate) > new Date(payload.scheduledEndDate)) {
      setError({ scheduledStartDate: true });
      Toast("Scheduled end date is greater than start time", "error");
      return;
    }

    if (new Date(payload.scheduledStartDate) < new Date(payload.scheduledchangeDate)) {
      setError({ scheduledStartDate: true });
      Toast("You can't schedule for a past time.", "error");
      return;
    }


    if (
      new Date(payload.scheduledStartDate) < new Date() &&
      new Date(payload.scheduledStartDate) < new Date(payload.scheduledEndDate)
    ) {
      var dt = new Date();
      var addMinutes = new Date().setMinutes(dt.getMinutes());
      var currentDate = CommonUtil.formatToUtc(addMinutes);
      const dto = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        purposeTypeId: payload.purposeTypeId.id,
        hostId: payload.hostId,
        phoneCountryCode: !CommonUtil.isEmpty(payload.phoneNumber)
          ? "+" + countryCode
          : "",
        // phoneCountryCode: payload.phoneCountryCode,
        phoneNumber: payload.phoneNumber,
        scheduledStartDate: currentDate,
        scheduledEndDate: CommonUtil.formatToUtc(payload.scheduledEndDate),
        emailId: payload.emailId,
        visitMode: payload.visitMode,
        visitorCompanyName: payload.visitorCompanyName,
        siteId: payload.siteId.id,
        earlyCheckin: payload.earlyCheckin,
        profilePhoto: payload.profilePhoto,
      };
      dispatch(createInvitation(dto))
        .unwrap()
        .then((data) => {
          if (data?.code === "CVAI0067") {
            Toast("Invite Sent Successfully", "success");
            handleClose();
          } else {
            onError(data);
            // Toast(data.message, "error");
          }
        });
    } else if (
      new Date(payload.scheduledStartDate) > new Date() &&
      new Date(payload.scheduledStartDate) < new Date(payload.scheduledEndDate)
    ) {
      const dto = {
        firstName: payload.firstName,
        lastName: payload.lastName,
        emailId: payload.emailId,
        phoneCountryCode: !CommonUtil.isEmpty(payload.phoneNumber) ? "+" + countryCode : "",
        phoneNumber: payload.phoneNumber,
        profilePhoto: payload.profilePhoto,
        purposeTypeId: payload.purposeTypeId.id,
        hostId: payload.hostId,
        visitorCompanyName: payload.visitorCompanyName,
        siteId: payload.siteId.id,
        scheduledStartDate: CommonUtil.formatToUtc(payload.scheduledStartDate),
        scheduledEndDate: CommonUtil.formatToUtc(payload.scheduledEndDate),
        visitMode: payload.visitMode,
      };
      dispatch(createInvitation(dto))
        .unwrap()
        .then((data) => {
          if (data?.code === "CVAI0067" || data?.code === "CVAI1007") {
            Toast("Invite Sent Successfully", "success");
            handleClose();
          } else {
            onError(data);
            // Toast(data.message, "error");
          }
        });
    } else {
      Toast("End time should be greater than Start time", "error");
    }
  };

  const onError = (data) => {
    if (data)
      switch (data.code) {
        case "CVAE1015":
          setError({ emailId: true });
          break;
        case "CVAE1016":
          setError({ emailId: true });
          break;
        case "CVAE1012":
          setError({ firstName: true });
          break;
        case "CVAE1013":
          setError({ firstName: true });
          break;
        case "CVAE1017":
          setError({ phoneNumber: true });
          break;
        case "CVAE1041":
          setError({ purposeTypeId: true });
          break;
        case "CVAE1019":
          setError({ hostId: true });
          break;
        case "CVAE1021":
          setError({ scheduledStartDate: true });
          break;
        case "CVAE1022":
          setError({ scheduledEndDate: true });
          break;
        case "CVAE1023":
          setError({ scheduledEndDate: true });
          break;
        case "CVAE0300":
          setError({ siteId: true });
          break;
        default:
          setError({ ...error });
      }
    Toast(data.message, "error");
  };

  const handleText = (event) => {
    if (CommonUtil.isValidEmails(event.target.value)) {
      console.log(event.target.value);
      const payload = {
        pageNumber: 1,
        pageSize: 0,
        email: event.target.value,
      };
      dispatch(allHostList(payload))
        .unwrap()
        .then((data) => {
          if (data?.code === "OMSI0000") {
            if (!CommonUtil.isEmptyArray(data.data)) {
              // getSelectedItem(data.data.employees[0]);
              setHostList(data?.data?.employees);
            } else {
              setHostList([]);
              getSelectedItem(null);
            }
          }
        });
    }
  };

  const filterOptions = createFilterOptions({
    stringify: ({ firstName, email, lastName }) => `${firstName} ${email} ${lastName}`,
    limit: 1
  });

  const getSelectedItem = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        hostId: newValue.id,
      });
      setSelectHost(newValue);
    } else {
      setPayload({
        ...payload,
        hostId: "",
      });
      setSelectHost({});
    }
  };

  const handlePhoneChange = (value, data, event, formattedValue) => {
    setPhoneInput(value);
    setPayload({
      ...payload,
      phoneCountryCode: "+" + data.dialCode,
      phoneNumber: value.toString().replace(data.dialCode, ""),
    });
    setError({ phoneNumber: false });
  };


  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event,
    });
  };

  const handlePhoneNumberChange = (value) => {
    var code = '+' + countryCode
    var mobile = value
    setError({
      ...error,
      phoneNumber: ''
    })
    if (mobile.length > 25) {
      return false
    }
    else {
      setPayload({
        ...payload,
        phoneCountryCode: code,
        phoneNumber: mobile,
      })
      setPhoneNumber(value)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"md"}
      style={{ borderRadius: '20px !important' }}>
      <DialogTitle variant="h4" onClick={handleClose}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 15,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        New Invite
      </DialogTitle>
      <Divider variant="middle" />
      <DialogContent>
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >
          {!zlink ? (
            <>
              <Grid item lg={6} sm={12}>
                <CustomTextfield
                  label="First Name"
                  error={error.firstName}
                  name="firstName"
                  value={payload.firstName}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.firstName}
                  validation="alpha-numeric"
                  required={true}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item lg={6} sm={12}>
                <CustomTextfield
                  label="Visitor First Name"
                  error={error.firstName}
                  name="firstName"
                  value={payload.firstName}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.firstName}
                  validation="alpha-numeric"
                  required={true}
                />
              </Grid>
            </>
          )}

          {!zlink ? (
            <>
              <Grid item lg={6} sm={12}>
                <CustomTextfield
                  label="Last Name"
                  error={error.lastName}
                  name="lastName"
                  value={payload.lastName}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.lastName}
                  validation="alpha-numeric"
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item lg={6} sm={12}>
                <CustomTextfield
                  label="Visitor Last Name"
                  error={error.lastName}
                  name="lastName"
                  value={payload.lastName}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.lastName}
                  validation="alpha-numeric"
                />
              </Grid>
            </>
          )}

          {!zlink ? (
            <>
              <Grid item lg={6} sm={12}>
                <CustomTextfield
                  label="Email"
                  error={error.emailId}
                  name="emailId"
                  value={payload.emailId}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.emailId}
                  validation="email"
                  required={true}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item lg={6} sm={12}>
                <CustomTextfield
                  label="Visitor Email"
                  error={error.emailId}
                  name="emailId"
                  value={payload.emailId}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.emailId}
                  validation="email"
                  required={true}
                />
              </Grid>
            </>
          )}

          <Grid item lg={6} xs={12} marginTop={-1.5} >
            {/* <CustomPhone
              value={phoneInput}
              specialLabel={false}
              handleChange={(value, data, event, formattedValue) => handlePhoneChange(value, data, event, formattedValue)}
            /> */}

            <CommonPhoneInput
              sx={{ margin: 'none!important' }}
              error={error.phoneNumber}
              handleCountryCode={(data) => {
                setCountryCode(data.dialCode)
                setError({ ...error, phoneNumber: '' })

              }}

              countryCode={countryCode}
              placeholder={t('COMPPROFSCREEN037')}
              phoneNumber={phoneNumber}
              name={'phone'}
              handleChange={(e) => handlePhoneNumberChange(e)}

            />


          </Grid>

          {!zlink ? (
            <>
              <Grid item lg={6} xs={12}>
                <Autocomplete
                  style={{ width: "100%" }}
                  noOptionsText={"No Options found"}
                  name="purposeTypeId"
                  value={payload.purposeTypeId}
                  options={CommonUtil.isEmptyArray(data) ? [] : data}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      // eslint-disable-next-line
                      onselectPurposeType(newValue);
                    } else {
                      onselectPurposeType("");
                    }
                    setError({ purposeTypeId: false });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="purposeTypeId"
                      value={payload.purposeTypeId}
                      error={error.purposeTypeId}
                      variant="outlined"
                      fullWidth
                      label={"Purpose Type *"}
                    />
                  )}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item lg={6} xs={12}>
                <Autocomplete
                  style={{ width: "100%" }}
                  noOptionsText={"No Options found"}
                  name="purposeTypeId"
                  value={payload.purposeTypeId}
                  options={CommonUtil.isEmptyArray(data) ? [] : data}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={(event, newValue) => {
                    if (!CommonUtil.isEmpty(newValue)) {
                      // eslint-disable-next-line
                      onselectPurposeType(newValue);
                    } else {
                      onselectPurposeType("");
                    }
                    setError({ purposeTypeId: false });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="purposeTypeId"
                      value={payload.purposeTypeId}
                      error={error.purposeTypeId}
                      variant="outlined"
                      fullWidth
                      label={"Visitor Purpose *"}
                    />
                  )}
                />
              </Grid>
            </>
          )}

          <Grid item lg={6} sm={12}>
            <Autocomplete
              style={{
                margin: "-15px",
                marginLeft: "1px",
                color: "#242424",
              }}
              id="free-solo-demo"
              freeSolo
              noOptionsText={'No Options found'}
              fullWidth
              //filterOptions={filterOptions}
              options={CommonUtil.isEmptyArray(hostList) ? [] : hostList}
              getOptionLabel={(option) =>
                option.lastName ? option.firstName + " " + option.lastName : option.firstName ? option.firstName : ""}
              value={selectedHost}
              onChange={(event, newValue) => {
                getSelectedItem(newValue);
                setError({ selectedHost: false });
              }}
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label="Host"
                  required={true}
                  placeholder="Host *"
                  name="hostId"
                  key="Confirmation Code"
                  id="hostId"
                  handleChange={(e) => handleText(e)}
                  error={error.selectedHost}
                />
              )}
            />
          </Grid>


          {!zlink ? (
            <>
              <Grid item lg={6} xs={12}>
                <CustomTextfield
                  label="Company Name"
                  error={error.visitorCompanyName}
                  name="visitorCompanyName"
                  value={payload.visitorCompanyName}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.visitorCompanyName}
                  validation="alphabets"
                />
              </Grid>
            </>
          ) : (
            <>

              <Grid item lg={6} xs={12} marginTop={1}>
                <CustomTextfield
                  required
                  label="Visitor Company Name"
                  error={error.visitorCompanyName}
                  name="visitorCompanyName"
                  value={payload.visitorCompanyName}
                  handleChange={(e) => handleChange(e)}
                  helperText={error.visitorCompanyName}
                  validation="alphabets"
                />
              </Grid>
            </>
          )}



          <Grid item lg={6} xs={12} marginTop={2.8}>
            <Autocomplete
              style={{ width: "100%" }}
              noOptionsText={"No Options found"}
              name="siteId"
              value={payload.siteId}
              options={CommonUtil.isEmptyArray(siteData) ? [] : siteData}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                  // eslint-disable-next-line
                  setPayload({ ...payload, siteId: newValue });
                } else {
                  setPayload({ ...payload, siteId: "" });
                }
                setError({ siteId: false });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="siteId"
                  value={payload.siteId}
                  error={error.siteId}
                  variant="outlined"
                  fullWidth
                  label={"Site *"}
                />
              )}
            />

          </Grid>
          <Grid item lg={6} xs={12} marginTop={2}>
            <CustomDateTimePicker
              onChange={(scheduledStartDate) =>
                setPayload({
                  ...payload,
                  scheduledStartDate: scheduledStartDate,
                })
              }
              disablePast
              value={payload.scheduledStartDate}
              label={"Invite Start Date *"}
            />
          </Grid>

          <Grid item lg={6} xs={12} marginTop={2}>
            <CustomDateTimePicker
              onChange={(scheduledEndDate) =>
                setPayload({
                  ...payload,
                  scheduledEndDate: scheduledEndDate,
                })
              }
              disablePast
              value={payload.scheduledEndDate}
              //minDateTime={payload.scheduledStartDate}
              label="Invite End Date *"
            />
          </Grid>

        </Grid>
      </DialogContent>
      <DialogActions style={{
        justifyContent: "center",
      }}>
        <SubmitButtons variant='contained' onClick={handleSumbit}>
          <Typography variant="body1" style={{ textTransform: "none" }}>
            Submit
          </Typography>
        </SubmitButtons>
        <CancelButtons variant='contained' color="secondary" onClick={handleClose}>
          <Typography variant="body1" style={{ textTransform: "none" }}>
            Cancel
          </Typography>
        </CancelButtons>
      </DialogActions>
    </Dialog>
  );
}
