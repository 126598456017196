import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, Grid } from "@mui/material";
import ViewIcon from "../../Invitations/ViewIcon";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
},
'& .MuiDialogActions-root': {
    padding: theme.spacing(1),
},
'& .MuiDialog-paper': {
    overflowY: 'visible',
    borderRadius: '14px',
    minWidth: '900px',
},
}));

const BootstrapDialogTitle = ({ children, onClose, ...props }) => {

  return (
    <DialogTitle variant="h4" sx={{ m: 0, p: 2, fontSize: '20px', color: '#242424' }} {...props}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
  );
};


function SiteTypeViewDetails({ heading, description, children, ...props }) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const handleClickOpen = () => {
      setOpen(true);
  };
  const handleClose = () => {
      setOpen(false);
  };
  useEffect(() => {
  }, []);

  return (
    <div>
      <Grid onClick={handleClickOpen}>
        <ViewIcon />
      </Grid>
      <Grid>
        <BootstrapDialog
          onClose={handleClose}
          open={open}
        >
            <BootstrapDialogTitle onClose={handleClose}>
           {t('STYPE015')}
                    </BootstrapDialogTitle>
                    <Divider variant="middle" />
          <DialogContent >
            <Grid container style={{padding:'10px'}}>
              <Grid container spacing={3} p={1}>
                <Grid item xs={2} ><Typography variant="body">{t("COMMON014")}</Typography></Grid>
                <Grid item xs={1}>:</Grid>
                <Grid item xs={9} >
                  {props.rowData.name}
                </Grid>
              </Grid>
              <Grid container spacing={3} p={1}>
                <Grid item xs={2}><Typography variant="body">{t("COMMON045")}</Typography></Grid>
                <Grid item xs={1}>:</Grid>
                <Grid  item xs={9} >
                  {props.rowData.code}
                </Grid>
              </Grid>
              <Grid container spacing={3} p={1}>
                <Grid item xs={2}> <Typography variant="body">{t('STYPE009')}</Typography></Grid>
                <Grid item xs={1}>:</Grid>
                <Grid item xs={9} style={{overflowWrap:'break-word'}}>
                  {props.rowData.description}
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </BootstrapDialog>
      </Grid>
    </div>
  );
}

export default SiteTypeViewDetails;
