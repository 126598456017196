import { Typography } from "@material-ui/core";
import { Autocomplete, createFilterOptions, Grid, Paper, TextField } from "@mui/material";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import CancelButtons from "../../components/DialogBoxComponent/CancelButtons";
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
import Toast from "../../components/ToastContainer/CustomToast";
import httpCommon from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";
import { withStyles } from "@material-ui/core/styles";
import CustomDateTimePicker from "../../components/CustomInputs/CustomDateTimePicker";
import Loader from "../../components/Loader/Loader";
import CommonPhoneInput from "../../components/CustomInputs/CommonPhoneInput";
import { WebcamCapture } from "../../components/CustomInputs/WebCam";
import { ReactComponent as ZLinkIcon } from "../../assets/zlinklogo.svg";

const styles = {
  input: {
    '&:-webkit-autofill': {
      WebkitTextFillColor: '#000 !important',
    },
  }
};

function SelfRegistration({ classes }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [siteData, setSiteData] = useState([]);
  const [comapanyId, setComapanyId] = useState("");
  const [purposeData, setPurposeData] = useState([]);
  const [profileImage, setProfileImage] = React.useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hostList, setHostList] = useState([]);
  const [selectedHost, setSelectHost] = useState({});
  const [countryCode, setCountryCode] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [code, setCode] = useState("");

  var today = new Date();

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T" +
    (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
    ":" +
    (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());

  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";

  // var dt = new Date();
  // var addMinutes = new Date().setMinutes(dt.getMinutes());
  // var endDate = CommonUtil.formatToUtc(addMinutes);

  var changeDate = 
  today.getFullYear() +
  formatDayAndMonth(today.getMonth() + 1) +
  formatDayAndMonth(today.getDate()) +
  "T" +
  (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
  ":" +
  (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());

  const [payload, setPayload] = useState({
    id: "",
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNumber: "",
    phoneCountryCode: "",
    purposeTypeId: "",
    hostId: "",
    hostEmailId: "",
    visitorCompanyName: "",
    siteId: "",
    visitMode: "SELF_REGISTARTION",
    profilePhoto: "",
    code: "",
    remarks: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    scheduledchangeDate : CommonUtil.formatToUtc(changeDate)
  });

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    emailId: false,
    phoneNumber: false,
    purposeTypeId: false,
    hostId: false,
    siteId: false,
    remarks: false,
    visitorCompanyName: false,
    scheduledStartDate: false,
    scheduledEndDate: false,
    profilePhoto: false,
    selectedHost: false,
    scheduledchangeDate: false,
  });

  useEffect(() => {
    window.history.forward();
    var urlValue = window.location.href;
    var url = new URL(urlValue);
    var code = url.searchParams.get("company");
    setCode(code);
    var lang = url.searchParams.get("lang");
    lang = lang !== null ? lang : "en";
    i18next.changeLanguage(lang);
    decodeAndSetCompanyDetails(code);
  }, []);

  const decodeAndSetCompanyDetails = (code) => {
    var companyDetails = atob(code);
    var details = companyDetails.split(":");
    setPayload({
      ...payload,
      companyName: details[1],
    });
    localStorage.setItem("companyId", details[0])
    setComapanyId(details[0]);
    loadData();
  };

  const loadData = () => {
    loadPurposeType();
    loadSites();
    loadHosts();
  };

  const loadHosts = () => {
    const config = {
      headers: { companyId: localStorage.getItem('companyId') },
    };
    httpCommon.unsecuredAxios().get('/web/selfRegistartion/employees/list?sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&status=ACTIVE&pageNumber=1&pageSize=0&firstName=', config)
      .then((data) => {
        if (data.data.code === "OMSI0000") {
          setHostList(data.data.data.employees);
        } else {
          getSelectedItem();
          setHostList([]);
        }
      });
  };

  const getSelectedItem = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        hostId: newValue.id,
      });
      setSelectHost(newValue);
    } 
    else {
      setPayload({
        ...payload,
        hostId: "",
      });
      setSelectHost({});
    }
  };

  const filterOptions = createFilterOptions({
    stringify: ({ firstName, email,lastName }) => `${firstName} ${email} ${lastName}`,
    limit: 1
  });

  const config = {
    headers: { companyId: localStorage.getItem('companyId') },
  };

  const handleText = (event) => {
    httpCommon.unsecuredAxios().get('/web/selfRegistartion/employees/list?sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&status=ACTIVE&pageNumber=1&pageSize=0&firstName=' + event.target.value, config)
      .then((data) => {
        if (data.data.code === "OMSI0000") {
          // getSelectedItem(data.data.data.employees[0]);
          setHostList(data.data.data.employees);
        } else {
          getSelectedItem();
          setHostList([]);
        }
      });
  };

  const loadSites = () => {
    const config = {
      headers: { companyId: localStorage.getItem('companyId') },
    };
    httpCommon.unsecuredAxios().get("/web/site?pageNumber=1&pageSize=0&sort=createdAt%7Cdesc&caseSensitive=true&operator=AND", config)
      .then((data) => {
        if (data.data.code = "LMSI0000" && data.data.data) {
          setSiteData(data.data.data.site);
        } else {
          setSiteData([]);
        }
      });
  };

  const loadPurposeType = () => {
    const config = {
      headers: { companyId: localStorage.getItem('companyId') },
    };
    httpCommon.unsecuredAxios().get("/web/getPurposeType?isPagable=true&pageNumber=1&pageSize=1000", config)
      .then((data) => {
        if (data.data.code === "CVAI0000" && data.data.data) {
          setPurposeData(data.data.data.purposeTypes);
        } else {
          setPurposeData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleProfile = (file) => {
    setPayload({
      ...payload,
      profilePhoto: payload.firstName,
    });
    setProfileImage(file);
  };

  const setImageFromCam = (image) => {
  }

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };
const handleFirstNameChange = (event) => {
  const name = event.target.name;
  const inputValue = event.target.value;
  const pattern = /^[a-zA-Z0-9\s]*$/;
  if (pattern.test(inputValue)) {
    setPayload({
      ...payload,
      [name] :inputValue,
    });
    setError({
      [name] : false
    })
  }
  else{
    setError({
      ...error,
      [name] : true
    })
  }
}

const handleCompanyName = (event) => {
  const name = event.target.name;
  const inputValue = event.target.value;
//  const pattern =/^[a-zA-Z\s]*$/;
  const pattern = /^[a-zA-Z0-9\s]*$/;
  if (pattern.test(inputValue)) {
    setPayload({
      ...payload,
      [name] :inputValue,
    });
    setError({
      ...error,
      [name] : false
    })
  }
  else{
    setError({
      [name] : true
    })
  }
}

const handlePhoneNumberChange = (value) => {
  if (CommonUtil.isEmptyString(countryCode)) {
    Toast("Please select a country code", "error");
    return;
  }

  var code = '+' + countryCode;
  var mobile = value;

  setError({
    ...error,
    phoneNumber: '',
  });

  if (mobile.length > 25) {
    Toast("Phone number is too long", "error");
    return;
  }

  setPayload({
    ...payload,
    phoneCountryCode: code,
    phoneNumber: mobile,
  });

  setPhoneNumber(value);
};

const onselectPurposeType = (event) => {
  setPayload({
    ...payload,
    purposeTypeId: event,
  });
};

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('url', data.profilePhotoURL);
    bodyFormData.append('file', profileImage);
    httpCommon.unsecuredMultipartAxios().post("/web/employee/profile", bodyFormData)
      .then(function (response) {
        // Toast("Profile Photo Saved", "success");
      })
      .catch(function (response) {

      });
  };

  const handleSubmit = () => {

    if(profileImage === undefined){
      Toast("Profile Photo is mandatory" , "error");
      return;
    }

    if (CommonUtil.isEmptyString(payload.firstName)) {
      Toast("Please Enter First Name", "error");
      setError({ firstName: true });
      return;
    }
    if (!CommonUtil.isValidNames(payload.firstName)) {
      Toast("Please Enter Correct First Name", "error");
      setError({ firstName: true });
      return;
    }

    if (CommonUtil.isEmptyString(payload.lastName)) {
      Toast("Please Enter Last Name", "error");
      setError({ lastName: true });
      return;
    }
    if (!CommonUtil.isValidNames(payload.lastName)) {
      Toast("Please Enter Correct Last Name", "error");
      setError({ lastName: true });
      return;
    }

    if (
      CommonUtil.isEmptyString(payload.emailId) &&
      CommonUtil.isEmptyString(payload.phoneNumber)
    ) {
      Toast("Either Email or Mobile number is mandatory.", "error");
      setError({ emailId: true, phoneNumber: true });
      return;
    }

    if (!CommonUtil.isValidEmails(payload.emailId)) {
      setError({ ...error, emailId: true });
      Toast(t("COMP0007"), "error");
      return;
    }
    if (!CommonUtil.isEmptyString(payload.phoneNumber)) {
      if (payload.phoneNumber.length < 6 || payload.phoneNumber.length > 15) {
        setError({ ...error, phoneNumber: true });
        Toast(t("COMPPROFSCREEN003"), "error");
        return;
      }
    }

    if (CommonUtil.isEmptyString(countryCode) && !CommonUtil.isEmptyString(payload.phoneNumber)) {
      Toast(t("COMMON133"), "error");
      return
    }

    if (!CommonUtil.isEmptyString(countryCode) && CommonUtil.isEmptyString(payload.phoneNumber)) {
      Toast(t("COMMON134"), "error");
      return
    }

    if (CommonUtil.isEmptyString(payload.siteId)) {
      Toast("Please Enter Site", "error");
      setError({ siteId: true });
      return;
    }

    if (CommonUtil.isEmpty(selectedHost)) {
      Toast("Host name is mandatory", "error");
      setError({ selectedHost: true });
      return;
    }

    // if (payload.hostEmailId === payload.emailId.trim() || payload.emailId.trim() === selectedHost.email) {
    if (payload.emailId.trim() === selectedHost.email) { 
      Toast("Visitor email can not be same as Host or logged user email", "error");
      setError({ emailId: true });
      return;
    }

    if (CommonUtil.isEmptyString(payload.purposeTypeId)) {
      Toast("Purpose Type required !!", "error");
      setError({ purposeTypeId: true });
      return;
    }
   
    if (CommonUtil.isEmptyString(payload.visitorCompanyName)) {
      Toast("Please Enter Visitor Company Name", "error");
      setError({ visitorCompanyName: true });
      return;
    }

    if (payload.scheduledStartDate === null || payload.scheduledStartDate === undefined || payload.scheduledStartDate === "") {
      setError({ scheduledStartDate: true });
      Toast("Scheduled start date is mandatory", "error");
      return;
    }
    if (payload.scheduledEndDate === null || payload.scheduledEndDate === undefined || payload.scheduledEndDate === "") {
      setError({ scheduledEndDate: true });
      Toast("Scheduled end date is mandatory", "error");
      return;
    }

    if (new Date(payload.scheduledEndDate) < new Date(payload.scheduledStartDate)) {
      setError({ scheduledEndDate: true });
      Toast("End time should be greater than Start time", "error");
      return;
    }

    if(new Date(payload.scheduledStartDate) < new Date(payload.scheduledchangeDate)){
      setError({ scheduledStartDate: true });
      Toast("You can't schedule for a past time." , "error");
      return;
    }

    if (CommonUtil.isEmptyString(payload.remarks)) {
      Toast("Please enter visitor remarks", "error");
      setError({ remarks: true });
      return;
    }

    setIsLoading(true);
    const dto = {
      id: payload.id,
      firstName: payload.firstName,
      lastName: payload.lastName,
      purposeTypeId: payload.purposeTypeId.id,
      emailId: payload.emailId,
      phoneNumber: payload.phoneNumber,
      phoneCountryCode: !CommonUtil.isEmpty(payload.phoneNumber) ? payload.phoneCountryCode : '',
      visitMode: "SELF_REGISTARTION",
      profilePhoto: payload.firstName,
      visitorCompanyName: payload.visitorCompanyName,
      siteId: payload.siteId.id,
      hostId: payload.hostId,
      scheduledStartDate: payload.scheduledStartDate,
      scheduledEndDate: payload.scheduledEndDate,
      earlyCheckin: payload.earlyCheckin,
      remarks: payload.remarks,
    };
    const config = {
      headers: { companyId: comapanyId },
    };

    httpCommon.unsecuredAxios().post("/web/visit/selfRegisrtaion", dto, config).then((data) => {
      if (data.data.code === "CVAE1025") {
            Toast(t("Already meeting is scheduled."), "error");
            return;
          }

      if (data.data.code === "CVAI0067") {
        if (!CommonUtil.isEmptyString(data.data.data.profilePhotoURL)) {
          uploadProfilePhoto(data.data.data);
        }
        if (data.data.data.checkListItem?.questionaries?.length > 0) {
          const enabledlistItems = data.data.data.checkListItem?.questionaries.filter((question) => {
            return question.disabled === false
          });
          if (enabledlistItems.length > 0) {
            history.push({
              pathname: "/vis/selfRegistration-confirm-page",
              state: {
                success: true,
                listValue: enabledlistItems,
                id: data.data.data.id,
                code: code,
              }
            });
          }
          else {
            handleClose();
          }
        } else {
          handleClose();
        }
      }
      else {
        Toast(data.data.message, "error");
      }
      setIsLoading(false);
    })

  };
  const handleClose = (data) => {
    Toast("Self Registration created successfully", "success");
    history.push({
      pathname: "/vis/visit-success",
      state: { success: true },
    });
  };

  const handleCancel = () => {
    history.push({
      pathname: "/vis/confirms-page/rejects",
      state: { success: true },
    });
  };

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "40px",
          backgroundColor: "#F4F6F8",
        }}
      >
        <Grid container alignItems="center">
          <Grid item className="logo-center">
            <ZLinkIcon />
          </Grid>
        </Grid>
      </Grid>
      <Paper>
        <Typography
          gutterBottom
          variant="h2"
          // style={{ padding: "25px", textAlign: "center" }}
          style={{ padding: "20px", textAlign: "center", color: "#3D4977" }}
        >
          Self Registration
        </Typography>
        <Grid container p={5} spacing={3}>
         <Grid
            container
            justifyContent="center"
            alignItems="center"
            md={3.5}
            sm={12}
          >
           <Grid>
              {/* <ProfileUplod
                onsetProfile={(profile) => handleProfile(profile)}
                profilePhoto={profile} /> */}
                <WebcamCapture onsetProfile={(profile) => handleProfile(profile)} 
                profilePhoto={profileImage} base64Image={(data) => setImageFromCam(data)}/>
            </Grid>
          </Grid>

          <Grid container md={8} sm={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTextfield
                name={"firstName"}
                label={"First Name"}
                value={payload.firstName}
                required={true}
                handleChange={handleFirstNameChange}
                error={error.firstName}
                validation="alpha-numeric"
                autoComplete="on"
                inputProps={{ className: classes.input,maxLength:50}}
                helperText= {error.firstName ? "Only accepts alpha-numeric characters" : ""}
              
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextfield
                name={"lastName"}
                label={"Last Name"}
                value={payload.lastName}
                handleChange={handleFirstNameChange}
                error={error.lastName}
                validation="alpha-numeric"
                autoComplete="on"
                required={true}
                inputProps={{ className: classes.input , maxLength:50}}
                // helperText= {error.lastName ? "Only accepts alphabetic characters" : ""}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ margin: "12px 0px" }}>
              <CustomTextfield
                label={"Email"}
                name={"emailId"}
                error={error.emailId}
                value={payload.emailId}
                validation="email"
                handleChange={(e) => handleChange(e)}
                autoComplete="on"
                required={true}
                inputProps={{ className: classes.input, maxLength: 50}}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CommonPhoneInput
                sx={{ margin: 'none!important' }}
                error={error.phoneNumber}
                handleCountryCode={(data) => {
                setCountryCode(data.dialCode)
                setError({...error, phoneNumber: '' })
                }}
                countryCode={countryCode}
                placeholder={t('COMPPROFSCREEN037')}
                phoneNumber={phoneNumber}
                name={'phone'}
                handleChange={(e) => handlePhoneNumberChange(e)}
            />
            </Grid>

            <Grid item xs={12} sm={6} style={{ margin: "16px 0px" }}>
              <Autocomplete
                style={{ width: '100%' }}
                noOptionsText={'No Options found'}
                name='siteId'
                value={payload.siteId}
                options={CommonUtil.isEmptyArray(siteData) ? [] : siteData}
                getOptionLabel={(option) => option.name ? option.name : ''}
                onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {// eslint-disable-next-line
                    setPayload({ ...payload, siteId: newValue })
                  } else {
                    setPayload({ ...payload, siteId: '' })
                  }
                  setError({ siteId: false });
                }}
                renderInput={(params) => (
                  <TextField {...params} name='siteId' value={payload.siteId} error={error.siteId} variant='outlined' fullWidth label={'Site'} required={true} />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ margin: "16px 0px" }}>
            <Autocomplete
              style={{
                margin: "-15px",
                marginLeft: "1px",
                color: "#242424",
              }}
              id="free-solo-demo"
              freeSolo
              noOptionsText={'No Options found'}
              fullWidth
              // filterOptions={filterOptions}
              options={CommonUtil.isEmptyArray(hostList) ? [] : hostList}
              getOptionLabel={(option) =>
                option.lastName ? option.firstName + " " + option.lastName : option.firstName ? option.firstName :""}
              value={selectedHost}
              onChange={(event, newValue) => {
                getSelectedItem(newValue);
                setError({ selectedHost: false });
              }}
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label="Host"
                  required={true}
                  placeholder="Host *"
                  name="hostId"
                  key="Confirmation Code"
                  id="hostId"
                  handleChange={(e)=>handleText(e)}
                  error={error.selectedHost}
                />
              )}
            />            
            </Grid>

            <Grid item xs={12} sm={6} style={{ margin: "16px 0px" }}>             
                <Autocomplete
                  style={{ width: "100%" }}
                  noOptionsText={"No Options found"}
                  name="purposeTypeId"
                  value={payload.purposeTypeId}
                  options={CommonUtil.isEmptyArray(purposeData) ? [] : purposeData}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  onChange={(event, newValue) => {
                  if (!CommonUtil.isEmpty(newValue)) {
                  // eslint-disable-next-line
                  onselectPurposeType(newValue);
                  } else {
                  onselectPurposeType("");
                  }
                  setError({ purposeTypeId: false });
                  }}
                  renderInput={(params) => (
                  <TextField
                    {...params}
                    name="purposeTypeId"
                    value={payload.purposeTypeId}
                    error={error.purposeTypeId}
                    variant="outlined"
                    fullWidth
                    label={"Visitor Purpose *"}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                label={"Company Name"}
                name={"visitorCompanyName"}
                value={payload.visitorCompanyName}
                handleChange={handleCompanyName}
                required={true}
                error={error.visitorCompanyName}
                autoComplete="on"
                inputProps={{ className: classes.input,maxLength:50}}
                helperText= {error.visitorCompanyName ? "Only accepts alpha-numeric characters" : ""}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ margin: "16px 0px" }}>
            <CustomDateTimePicker
                onChange={(scheduledStartDate) =>
                  setPayload({
                    ...payload,
                    scheduledStartDate: scheduledStartDate,
                  })
                }
                disableFuture
                disablePast
                value={payload.scheduledStartDate}
                error={error.scheduledStartDate}
                label={"Invite Start Date *"}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ margin: "16px 0px" }}>
              <CustomDateTimePicker
                onChange={(scheduledEndDate) =>
                  setPayload({
                    ...payload,
                    scheduledEndDate: scheduledEndDate,
                  })
                }
                disablePast
                disableFuture
                value={payload.scheduledEndDate}
                error={error.scheduledEndDate}
                //minDateTime={payload.scheduledStartDate}
                label="Invite End Date *"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <CustomTextfield
                label={"Remarks"}
                name={"remarks"}
                value={payload.remarks}
                handleChange={handleCompanyName}
                error={error.remarks}
                autoComplete="on"
                required={true}
                inputProps={{ className: classes.input, maxLength: 100 }}
                helperText= {error.remarks ? "Only accepts alphabetic characters" : ""} 
              />
            </Grid>

          </Grid>
        </Grid>

        {isLoading ? <Loader /> : 

        <Grid container lg={12} md={12} xs={11} p={5} pt={0} spacing={3} justifyContent="flex-end">
          <Grid item>
            <SubmitButtons
              onClick={handleSubmit}
              variant="contained"
              disabled={isLoading}
            >
              <Typography>
                {"Submit"}
              </Typography>
            </SubmitButtons>
          </Grid>
          <Grid item>
            <CancelButtons
              color="secondary"
              onClick={handleCancel}
              variant="contained"
            >
              <Typography>
                {"Cancel"}
              </Typography>
            </CancelButtons>
          </Grid>
        </Grid>
    }
      </Paper>
    </>
  );
}
export default withStyles(styles)(SelfRegistration);
