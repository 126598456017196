import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material";
//import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import DataTable from "../../components/DataTable/DataTable";
import { useDispatch } from "react-redux";
import HeaderToolbar from "../../components/HeaderToolbar";
import Search from "../../components/SearchTab/Search";
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import AddIcon from "../../assets/images/VisitingAccessLevels/add.svg";
//import { ReactComponent as Door } from "../../assets/DeviceIcon/Door.svg";
import { deleteVisitorAccessLevels, getVisitorAccessLevels } from "../../slices/VisitorAccessLevels/VisitorAccessLevelSlice";
//import { ReactComponent as DeleteIcon } from "../../assets/DeleteIcon.svg";
import { REACT_MANAGE_ACCESSLEVELS } from "../../actions/EndPoints";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import AddAccessLevelDialog from "./AddAccessLevelDialog";
import DeleteDialog from "../../components/DialogBoxComponent/DeleteDialog";
import Toast from "../../components/ToastContainer/CustomToast";
import EditIcon from "../Person/EditIcon";
import DoorIcon from "./DoorIcon";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import ChecklistFilterForm from "../Checklist/ChecklistFilterForm";
import Loader from "../../components/Loader/Loader";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";

function VisitingAccessLevels() {

    const [rows, setRows] = useState([]);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(1);
    const [clear, setClear] = useState(false);
    const [searchedRows, setSerchedRows] = useState([]);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [selectedObject, setSelectedObject] = useState({});
    const [reload, setReload] = useState(false);

    const [roles, setRoles] = useState([]);
    const [createVisitingAccessLevels, setCreateVisitingAccessLevels] = useState(true);
    const [listVisitingAccessLevels, setListVisitingAccessLevels] = useState(true);
    const [deleteVisitingAccessLevels, setDeleteVisitingAccessLevels] = useState(true);
    const [editVisitingAccessLevels, setEditVisitingAccessLevels] = useState(true);
    const [manageVisitingAccessLevels, setManageVisitingAccessLevels] = useState(true);
    
    const [paging, setPaging] = useState({
        page: 1,
        rowsPerPage: 10,
    });
    const [filter, setFilter] = useState({
        name: "",
        parentId: "",
    });

    const defaultPaging = {
        page: 1,
        rowsPerPage: 10,
    };

    const defaultFilter = {
        name: "",
        parentId: "",
    };

    useEffect(() => {
        loadData(filter, paging);
    }, [filter, paging, clear]);

    const handleChange = (newPage, size) => {
        setRowsPerPage(size);
        setPage(newPage);
        setPaging({
            page: newPage,
            rowsPerPage: size,
        });
    };

    const globalsearch = (searchedVal) => {

        if (!searchedVal) {
            loadData(filter, paging);
            return;
          }

        dispatch(getVisitorAccessLevels(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
                if ((data?.code === "DMSI0000" && data?.data)) {
                    const filteredRows = data?.data?.accessGroups.filter((test) => {
                        return (
                            (test.name
                                ? test.name.toLowerCase().includes(searchedVal.toLowerCase())
                                : "") ||
                            (test.timeSlotName
                                ? test.timeSlotName.toLowerCase().includes(searchedVal.toLowerCase())
                                : "")
                        );
                    });
                    setRows(filteredRows);
                }
                else {
                    setRows([]);
                  }
            })
            .catch((er) => { });
       
    };

    const handleOpen = () => {
        setOpenEditDialog(true);
    };

    const handleClose = () => {
        setOpenEditDialog(false);
    };

    const resetFilter = () => {
        setClear(false);
        setPaging({ ...paging, page: 1 });
        setFilter(defaultFilter);
    };

    const reloadlist = (value) => {
        setReload(true);
      };

      useEffect(() => {
        if (reload === true)
        loadData(filter, paging);
      }, [reload]);


    useEffect(() => {
        dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
        .unwrap()
        .then((res) => {
            var l = [];
            res?.data?.rolePermissions.map((item, index) => {
                l.push(item.code);
          });
            setRoles(l);
            setCreateVisitingAccessLevels(l.includes("CREATE_EDIT_DELETE_VISITING_ACCESS_LEVEL") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
            setEditVisitingAccessLevels(l.includes("CREATE_EDIT_DELETE_VISITING_ACCESS_LEVEL") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
            setDeleteVisitingAccessLevels(l.includes("CREATE_EDIT_DELETE_VISITING_ACCESS_LEVEL") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setManageVisitingAccessLevels(l.includes("MANAGE_CV_VISITING_ACCESS_LEVEL") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setListVisitingAccessLevels(l.includes("VISITING_ACCESS_LEVEL_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
    
        if (l.includes("VISITING_ACCESS_LEVEL_LIST")) {
            dispatch(getVisitorAccessLevels(Object.assign(filter, paging)))
                .unwrap()
                .then((data) => {
                    if ((data?.code === "DMSI0000" && data?.data)) {
                        setTotalRecords(data?.data?.totalCount);
                        setRows(data?.data?.accessGroups);
                        setPage(data?.data?.currentPage);
                        setTotalPages(data?.data?.totalPages);
                    } else {
                    setRows([]);
                  }
                })
                .catch((er) => { });
               }
             });
    }, []);

    useEffect(() => {
        if (roles.includes("VISITING_ACCESS_LEVEL_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
          }
    }, []);
                    
    const loadData = (filter, paging) => {
        dispatch(getVisitorAccessLevels(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
                if ((data?.code === "DMSI0000" && data?.data)) {
                    setTotalRecords(data?.data?.totalCount);
                    setRows(data?.data?.accessGroups);
                    setPage(data?.data?.currentPage);
                    setTotalPages(data?.data?.totalPages);
                    setReload(false);
                }
                else {
                    setRows([]);
                  }
            })
            .catch((er) => { });
    };

    const handleFilter = (data) => {
        setClear(true);
        setPaging({ ...paging, page: 1 });
        setFilter({
          name: data?.name,
        });
    };

    const handleDelete = (data) => {
        let delPayLoad= {
            id:data?.id,
            name:data?.name
        }
        dispatch(deleteVisitorAccessLevels(delPayLoad))
            .unwrap()
            .then((data) => {
                if ((data?.code === "DMSI0000")) {
                    loadData(filter, paging);
                    Toast("Access Level Deleted Successfully", 'success')
                } else {
                    // Toast(data.msg, 'error')
                    Toast("Access level is already mapped with purpose type", "error")
                }
            })
            .catch((er) => { });
    }

    const columns = React.useMemo(() => [
            {
                headerName: "",
                sortable: false,
                width: 5,
                renderCell: (params) => {
                    return <div></div>;
                },
            },
            {
                field: "name",
                headerName: "Name",
                flex: 1.5,
                width: 10,
                valueGetter: (params) => params.row.name,
            },
            {
                field: "timeSlot",
                headerName: "Time Slots",
                flex: 1,
                width: 10,
                valueGetter: (params) => params.row.timeSlotName,
            },
            {
                field: "Actions",
                headerName: "Action",
                type: "actions",
                flex: 1,
                width: 10,
                headerAlign: "center",
                getActions: (params) => [
                    
                    (<>
                    {manageVisitingAccessLevels ? (
                    <GridActionsCellItem
                        icon={(<DoorIcon/>)}
                        onClick={() => { history.push({ pathname: REACT_MANAGE_ACCESSLEVELS, 
                            state: { data: params.row } }) }}
                    /> 
                    ) : ("")
                    }
                    </>),    

                    (<>
                    {editVisitingAccessLevels ? (
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        onClick={() => { setSelectedObject({ id: params.row.id, name: params.row.name, schedulerId: params.row.schedulerId,edit:true }); handleOpen(); }}
                    /> 
                    ) : ("")
                    }
                    </>),

                    (<>
                    {deleteVisitingAccessLevels ? (
                    <GridActionsCellItem
                        icon={
                            <DeleteDialog
                                handleDelete={()=>handleDelete(params.row)}
                                deviceName={params.row.name}
                                name={"Access Level"}
                                text={"Are you sure you want to delete this "}
                                reloadlist={reloadlist}
                            />
                        }
                        label="Delete"
                    /> 
                    ) : ("")
                    }
                    </>),
                    
                ],
            },
        ],
        
    );

    return (
        <>
            <>
            {createVisitingAccessLevels ? (
                <HeaderToolbar
                    src={AddIcon}
                    onClick={handleOpen}
                    title="Visiting Access Level"
                    tooltipTitle="Add Access Levels"
                />
            ):
                (<HeaderToolbar
                    title="Visiting Access Level"
                    tooltipTitle="Add Access Levels"
                />)
            }    
            </>

    {listVisitingAccessLevels &&
        <Box
                display={"flex"}
                width={"100%"}
                flexDirection={"row-reverse"}
                alignItems={"center"}
            >

            <Box item>
                    {clear && (
                        <Link
                            href="#"
                            underline="hover"
                            className="line"
                            style={{
                                opacity: "0.8",
                                color: "#E3393C",
                                fontSize: "14px",
                                underline: "hover",
                                display: "inline",
                            }}
                            onClick={resetFilter}
                        >
                            {"Clear Filter"}
                        </Link>
                    )}
                </Box>

                <Box item>
                    <ChecklistFilterForm
                        rowsPerPage={rowsPerPage}
                        applyFilter={(data) => handleFilter(data)}
                    />
                </Box>
                
                <Box style={{ textAlign: "-webkit-right", padding: "5px", margin: "5px", }}>
                <Tooltip title={"Refresh"}>
                    <IconButton onClick={() => loadData(filter, paging)}>
                        <Refresh />
                    </IconButton>
                </Tooltip>
                </Box>
                <Box item p={1}>
                    <Search
                        onSearch={(e) => globalsearch(e)}
                        clearSearch={() => loadData(filter, paging)}
                    />
                </Box>
               
            </Box>}

            {reload ? (<Loader />) : <div style={{ height: 300, width: "100%" }}>
                {listVisitingAccessLevels ?(
                <DataTable
                    columns={columns}
                    rows={searchedRows.length === 0 ? rows : searchedRows}
                    page={page}
                    getRowId={(row)=>row.id}
                    pageCount={totalPages}
                    pageSize={rowsPerPage}
                    count={totalRecords}
                    handleChange={(newPage, size) => handleChange(newPage, size)}
                />
                ):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
                <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                    {t('CVROLE018')}
                </Typography>
              </div>)}
            </div>}

            {openEditDialog ? <AddAccessLevelDialog open={openEditDialog} handleDialogClose={handleClose} selectedObject={selectedObject} updateSelectedObject={() => { setSelectedObject({}) }} loadData={() => { loadData(filter, paging) }} /> : null}
        </>
    )
}

export default VisitingAccessLevels