import { Box, Divider, InputLabel } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import InputPhone from "../VisitorActionsComponents/PhoneInput";
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-vinay";
import { ReactComponent as DownArrow } from "../../assets/downArrow.svg";
import { ReactComponent as CloseIcon } from "../../assets/Close.svg";
import CommonUtil from "../Util/CommonUtils";


const CommonPhoneInput = (props) => {
  const { t } = useTranslation();

  const useStyles = makeStyles({
    borderClass: {
      "&.react-tel-input .selected-flag .flag": {
        display: CommonUtil.isEmpty(props.countryCode) ? "none" : "block",
      },
      "& .react-tel-input::after": {
        content: DownArrow,
      },

      "&.react-tel-input .flag-dropdown": {
        width: '100% !important',
      },

      "&.react-tel-input .selected-flag": {
        width: '100% !important',
        padding: '14px 0 0 11px !important',
      },

      "&.react-tel-input ::placeholder": {
        color: 'executeReducerBuilderCallback',
        opacity: '0.44',
        font: 'normal normal normal 14px/19px Roboto',
      },

      "&.react-tel-input .country-list": {
        margin: '-8px 0 10px 0px !important',
        maxHeight: '180px !important',
        border: '0.5px solid #c0c0c0 !important',
        width: 'auto !important',
      },

      "&.react-tel-input .form-control": {
        fontSize: '16px',
        background: '#ffffff',
        border: 'none !important',
        borderRadius: '5px',
        width: '100% !important',
        outline: 'none',
        padding: '0px !important',
        margin: '0px 0px',
        textAlign: 'center',
      },

      "&.react-tel-input .special-label": {
        zIndex: '1',
        display: 'block',
        background: 'white',
        fontSize: '13px',
        whiteSpace: 'nowrap',
        color: '#00000099 !important',
        top: '7px !important',
      },

      //   "&.react-tel-input .selected-flag .flag": {
      //     position: 'relative !important',
      //     top: '0px !important',
      //     marginTop: '0px !important',
      //     paddingRight: '10px !important',
      //   },

      "&.react-tel-input .selected-flag .arrow": {
        display: 'none',
      },

      "&.react-tel-input .country-list .search": {
        padding: '10px 9px 6px 6px !important',
      },

      "&.react-tel-input .country-list .country-name ": {
        position: 'relative',
        top: '6px',
      },

      "&.react-tel-input .country-list .country .dial-code": {
        position: 'relative',
        top: '6px',
      },
      
    },
    customInput: {
      "& .MuiOutlinedInput-root ": {
        paddingLeft: "0px",
      },
    },
  });
  const classes = useStyles();

  return (
    <Box variant="standard" style={{ width: "100%" }}>
      {/* <InputLabel shrink htmlFor='bootstrap-input' style={{ marginBottom: '-14px' }}>
                <span style={{ fontSize: '19px' }}>{t('ZLINK0076')}</span> {<span style={{ fontSize: '19px', color: 'red', marginLeft: '-4px' }}>*</span>}
            </InputLabel> */}
      <Box sx={{ margin: "18px 0px", width: "100%" }}>
        <InputPhone
          disabled={props.disabled}
          type={"text"}
          placeholder={props.placeholder}
          autoComplete="off"
          fullWidth
          className={classes.customInput}
          error={props.error}
          name={props.name}
          value={props.phoneNumber}
          variant="outlined"
          size={props.size ? props.size : "small"}
          handleChange={(e) => props.handleChange(e.target.value)}
          helperText={props.error}
          validation="mobile"
          InputProps={{
            startAdornment: (
              <>
                <PhoneInput
                  disabled={props.disabled}
                  autoFormat={true}
                  countryCodeEditable={true}
                  enableSearch={true}
                  searchPlaceholder={"Search"}
                  searchNotFound={"No Options Found"}
                  onChange={(value, data) => {
                    props.handleCountryCode(data);
                  }}
                  // inputProps={{
                  //     disabled: true,
                  // }}
                  value={props.countryCode}
                  containerClass={classes.borderClass}
                  placeholder={t("Country Code")}
                  specialLabel={false}
                  // isValid={CommonUtil.isEmptyString(props.error)}
                  style={{ width: "auto" }}
                  inputStyle={{ marginBottom: "0px" }}
                />
                {/* { <DownArrow style={{paddingRight:'10px',cursor:'default'}}/>} */}
                <CloseIcon 
                  onClick={()=>{
                    props.handleCountryCode('');
                  }}
                  style={{cursor:'pointer', paddingRight: '5px', width: '50px', height: '50px'}}
                />
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ marginRight: "10px" }}
                />
              </>
            ),
          }}
        />
        {/* </Box> */}
      </Box>
    </Box>
  );
};

export default CommonPhoneInput;
