import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import "./phoneInput.css";
import { useDispatch } from "react-redux";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import CustomDropdown from "../../components/CustomInputs/CustomDropdown";
import CommonUtil from "../../Util/CommonUtils";
import { useTranslation } from "react-i18next";
import Toast from "../../components/ToastContainer/CustomToast";
import { getAddressTypes } from "../../slices/Site/AddressTypeSlice";
import { countryData, timeZoneData, gender } from "./AddressFormData";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import CommonPhoneInput from "../../components/CustomInputs/CommonPhoneInput";
import { createEmployee, updateEmployee, viewEmployeeById } from "../../slices/Employee/EmployeeSlice";
import ProfileUplod from "./UploadImg";

const useStyles = makeStyles((theme) => ({
  dialogbox: {
    "& .css-hlj6pa-MuiDialogActions-root": {
      marginLeft: '290px !important',
    },
  },
}));

export default function AddPerson(props) {
  const classes = useStyles();
  const { handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(props.open);
  const [addressTypes, setAddressTypes] = React.useState([]);
  const [countries, setCountries] = React.useState([]);
  const [Gender, setGender] = React.useState([]);
  const [timeZones, setTimeZones] = React.useState([]);
  const [profileImage, setProfileImage] = React.useState();
  const [profile, setProfile] = React.useState();
  const [phoneInput, setPhoneInput] = useState("");
  const [countryCode, setCountryCode] = React.useState('')

  const [employeePayload, setEmployeePayload] = React.useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",                                
    phoneCountryCode: "",
    phone: "",
    code: "",
    gender: "",
    profilePhoto: "",
    dateOfBirth: "",
    departmentIdOrCode: "",
    designationIdOrCode: "",
    siteId: "",
    status: "ACTIVE",
    type: "PERMANENT",
    personType: "",
    joinDate: "19-06-2020",
    createUser:false,
  });

  const [error, setError] = React.useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
    gender: false,
    code: false,
    userId: false,
    profilePhoto: false,
    addressLine1: false,
    addressLine2: false,
    addressLine3: false,
    country: false,
    state: false,
    city: false,
    areaCode: false,
    timeZone: false,
    latitude: false,
    longitude: false,
    radius: false,
    addressTypeId: false,
    dateOfBirth: false,
    joinDate: false,
    countryCode: false,
  });

  const [addressPayload, setAddressPayload] = React.useState({
    addressId:"",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    country: "",
    state: "",
    city: "",
    areaCode: "",
    userId: "",
    latitude: "",
    longitude: "",
    radius: "",
    timeZone: "",
    dayLightSaving: true,
    addressTypeId: "",
    employeeId: "",
    siteId: "",
    deafult: true,
  });

  const query = {
    page: 1,
    rowsPerPage: 10000,
    code: "",
    name: "",
  };

  const uploadProfilePhoto = (data) => {
    var bodyFormData = new FormData();
    bodyFormData.append('url', data.data.profilePhotoURL);
    bodyFormData.append('file', profileImage);
    axios({
      method: 'post',
      url: '/web/employee/profile',
      data: bodyFormData,
      Authorization: localStorage.getItem('VISJwtToken'),
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(function (response) {
        // Toast("Profile Photo Saved", "success");
      })
      .catch(function (response) {
       
      });
  };

  useEffect(() => {
    dispatch(getAddressTypes(query))
      .unwrap()
      .then((data) => {
        if (data.code === "LMSI0000") setAddressTypes(data.data.addressType);
      });
    
    setCountries(countryData);
    setTimeZones(timeZoneData);
    setGender(gender);
  }, []);

  const handleEmployeeChange = (event) => {
    const name = event.target.name;
    setEmployeePayload({
      ...employeePayload,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const handleAddressChange = (event) => {
    const name = event.target.name;
    setAddressPayload({
      ...addressPayload,
      [name]: event.target.value,
    });
    setError({
      [name]: false,
    });
  };

  const handleSubmit = (event) => {

    event.preventDefault();
    if (CommonUtil.isEmptyString(employeePayload.firstName)) {
      Toast(t("Please Enter First Name"), "error");
      setError({ firstName: true });
      return;
    }

    if (!CommonUtil.isValidNames(employeePayload.firstName)) {
      setError({ ...error, firstName: true });
      Toast("Please Enter Correct First Name", "error");
      return;
    }

    if (CommonUtil.isEmptyString(employeePayload.lastName)) {
      Toast(t("Please Enter Last Name"), "error");
      setError({ lastName: true });
      return;
    }

    if (!CommonUtil.isValidNames(employeePayload.lastName)) {
      Toast(t("Please Enter Correct Last Name"), "error");
      setError({ lastName: true });
      return;
    }

    if (CommonUtil.isEmptyString(employeePayload.email)) {
      Toast(t("Please Enter Email"), "error");
      setError({ email: true });
      return false;
    }

    if (!CommonUtil.isValidEmail(employeePayload.email)) {
      setError({ ...error, email: true });
      Toast(t("CORRECTEMAIL"), "error");
      return;
    }

    if (!CommonUtil.isValidEmails(employeePayload.email)) {
      setError({ ...error, email: true });
      Toast("Please Enter Correct Email", "error");
      return false;
    }

    if (CommonUtil.isEmptyString(employeePayload.code)) {
      Toast(t("Please Enter Person ID"), "error");
      setError({ code: true });
      return false;
    }

    if (CommonUtil.isEmptyString(addressPayload.addressLine1)) {
      Toast(t("Please Enter Address Line1"), "error");
      setError({ addressLine1: true });
      return false;
    }

    if (CommonUtil.isEmptyString(addressPayload.addressTypeId)) {
      Toast(t("Please Enter Address Type"), "error");
      setError({ addressTypeId: true });
      return false;
    }

    if (CommonUtil.isEmptyString(addressPayload.country)) {
      Toast(t("Please Enter Country"), "error");
      setError({ country: true });
      return false;
    }

    if (CommonUtil.isEmptyString(addressPayload.timeZone)) {
      Toast(t("Please enter timezone"), "error");
      setError({ timeZone: true });
      return false;
    }

    if (CommonUtil.isEmptyString(addressPayload.longitude)) {
      Toast(t("Please Enter Longitude"), "error");
      setError({ longitude: true });
      return false;
    }

    if (CommonUtil.isEmptyString(addressPayload.latitude)) {
      Toast(t("Please Enter Latitude"), "error");
      setError({ latitude: true });
      return false;
    }

    const newTemplate = {
      employeeDTOV4: {
        ...employeePayload,companyCode: localStorage.getItem("companyCode")
      },
      addressDTOV3: {
        ...addressPayload,
      },
    };
    //  alert(employeePayload.phone)
    if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      dispatch(createEmployee(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI0020") {
            Toast("Person Created Successfully", "success");
            if (!CommonUtil.isEmptyString(data.data.profilePhotoURL)) {
              uploadProfilePhoto(data);
            }
            handleClose();
          } else {
            Toast(data.message, "error")
          }
        });
    }
    
    else {
      dispatch(updateEmployee(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI0021") {
            Toast("Person Updated Successfully", "success");
            if (!CommonUtil.isEmpty(data.data.data.profilePhotoURL)) {
              uploadProfilePhoto(data.data);
            }
            handleClose();
          } else {
            Toast(data.message, "error")
          }
        });
    }
  }

  useEffect(() => {
    if (props.selectedObject.id) {
      dispatch(viewEmployeeById(props.selectedObject.id))
        .unwrap()
        .then((data) => {
          setEmployeePayload({
            ...employeePayload,
            id: props.selectedObject.id,
            firstName: data.data.employeeDTOV4.firstName,
            lastName: data.data.employeeDTOV4.lastName,
            email: data.data.employeeDTOV4.email,
            // phone: data.data.employeeDTOV4.phone,
            phoneCountryCode:data.data.employeeDTOV4.phone.split("-")[0],
            phone:data.data.employeeDTOV4.phone.split("-")[1],
            code: data.data.employeeDTOV4.code,
            gender: data.data.employeeDTOV4.gender,
            departmentIdOrCode: data.data.employeeDTOV4.departmentIdOrCode,
            designationIdOrCode: data.data.employeeDTOV4.designationIdOrCode,
            siteId: data.data.employeeDTOV4.siteId,
            profilePhoto: data.data.employeeDTOV4.profilePhoto,
            personType: data.data.employeeDTOV4.personType,

          })
          // setProfile(data.data.employeeDTOV4.profilePhoto);
          setProfile(data.data.employeeDTOV4.profilePhotoURL ?
            data.data.employeeDTOV4.profilePhotoURL : "data:image/jpeg;base64," + data.data.employeeDTOV4.profilePhotoURL);
          setCountryCode(data.data.employeeDTOV4.phone.split("-")[0])
          setPhoneInput(data.data.employeeDTOV4.phone.split("-")[1]);
          setAddressPayload({
            ...addressPayload,
            addressId:data.data.address.id,
            addressLine1: data.data.address.addressLine1,
            addressLine2: data.data.address.addressLine2,
            addressLine3: data.data.address.addressLine3,
            country: data.data.address.country,
            state: data.data.address.state,
            city: data.data.address.city,
            areaCode: data.data.address.areaCode,
            latitude: data.data.address.latitude,
            longitude: data.data.address.longitude,
            radius: data.data.address.radius,
            timeZone: data.data.address.timeZone,
            addressTypeId: data.data.address.addressTypeId,

          });
        })
    }
  }, []);

  const handleProfile = (file) => {
    setEmployeePayload({
      ...employeePayload,
      profilePhoto: " ",
    });
    setProfile(file.base64);
    setProfileImage(file.file);
  };
  
  // useEffect(() => {
  //   loadData();
  // }, []);

  // const loadData = () => {
  //   loadUserRoles();
  // };

  // const loadUserRoles = () => {
  //   const query = {
  //     page: 1,
  //     rowsPerPage: 1000,
  //     name: "",
  //   };

  //   dispatch(getAllUserRoles(query))
  //     .unwrap()
  //     .then((data) => {
  //       if (data.code === "UASI0000") {
  //         setRoleType(data.data.roles);
  //       } else {
  //         setRoleType([]);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  

  const handlePhoneNumberChange = (value) => {
    // var code = '+' + countryCode
    var codeCountry = countryCode
    var mobile = value
    setError({
      ...error,
      phone: ''
    })
    if (mobile.length > 25) {
      return false
    }
    else {
      setEmployeePayload({
        ...employeePayload,
        phoneCountryCode: codeCountry,
        phone: mobile,
      })
      setPhoneInput(value)
    }
  }

  return (
    <>
      <DialogBox className={classes.dialogbox}
        Header={props.selectedObject.id ? "Update Person" : "Add Person"}
        acceptText={"Submit"}
        cancelText={"Cancel"}
        maxWidth={"md"}
        onSubmit={handleSubmit}
        open={open}
        onClose={handleClose}
        cancel={handleClose}
      >
        <Grid container justifyContent="center" sx={{ marginTop: "20px" }}>
          <Grid xs={12} sm={4}
            container
            justifyContent="center"
            style={{ padding: "16px 32px", width: "100%" }}
            spacing={2}
            direction="column"
            alignItems="center"
          >
             <ProfileUplod
              onsetProfile={(profile) => handleProfile(profile)}
              profilePhoto={profile} />
          </Grid>

          <Grid sm={8} container justifyContent="center" spacing={1}>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={"text"}
                error={error.firstName}
                name="firstName"
                label="First Name"
                value={employeePayload.firstName}
                handleChange={(e) => handleEmployeeChange(e)}
                helperText={error.firstName}
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
             <CustomTextfield
                type={"text"}
                error={error.lastName}
                name="lastName"
                label="Last Name"
                value={employeePayload.lastName}
                handleChange={(e) => handleEmployeeChange(e)}
                helperText={error.lastName}
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={"text"}
                error={error.email}
                name="email"
                label="Email"
                value={employeePayload.email}
                handleChange={(e) => handleEmployeeChange(e)}
                helperText={error.email}
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{marginTop: "-10px"}}>
              <CommonPhoneInput
                sx={{ margin: 'none!important' }}
                error={error.phone}
                handleCountryCode={(data) => {
                setCountryCode(data.dialCode)
                setError({...error, phone: '' })
                }}
                countryCode={countryCode}
                placeholder={t('COMPPROFSCREEN037')}
                phoneNumber={phoneInput}
                name={'phone'}
                handleChange={(e) => handlePhoneNumberChange(e)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                type={"text"}
                error={error.code}
                name="code"
                label="Person ID"
                value={employeePayload.code}
                handleChange={(e) => handleEmployeeChange(e)}
                helperText={error.code}
                validation="numeric"
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ margin: "16px 0px" }}>
              <CustomDropdown
                data={Gender}
                label="Gender"
                name="gender"
                value={employeePayload.gender}
                handleChange={(e) => handleEmployeeChange(e)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                required
                error={error.addressLine1}
                name="addressLine1"
                label={t("ADD001")}
                helperText={error.addressLine1}
                value={addressPayload.addressLine1}
                handleChange={handleAddressChange}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
            <CustomTextfield
                error={error.addressLine2}
                name="addressLine2"
                label={t("ADD002")}
                helperText={error.addressLine2}
                value={addressPayload.addressLine2}
                handleChange={handleAddressChange}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                error={error.addressLine3}
                name="addressLine3"
                label={t("ADD017")}
                helperText={error.addressLine3}
                value={addressPayload.addressLine3}
                handleChange={handleAddressChange}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

             <Grid item xs={12} sm={6} style={{ marginTop: "16px" }}>
                  <CustomDropdown
                    label="Country *"
                    error={error.country}
                    name="country"
                    value={addressPayload.country}
                    handleChange={(e) => handleAddressChange(e)}
                    data={countries}
                  />
             </Grid> 

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                error={error.state}
                name="state"
                label={t("ADD004")}
                helperText={error.state}
                value={addressPayload.state}
                handleChange={handleAddressChange}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                error={error.city}
                name="city"
                label={t("ADD005")}
                helperText={error.city}
                value={addressPayload.city}
                handleChange={handleAddressChange}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                error={error.areaCode}
                name="areaCode"
                label="Post Code"
                helperText={error.areaCode}
                value={addressPayload.areaCode}
                handleChange={handleAddressChange}
                inputProps={{ maxLength: 10 }}
                validation="numeric"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                error={error.radius}
                name="radius"
                label={t("ADD009")}
                helperText={error.radius}
                value={addressPayload.radius}
                handleChange={handleAddressChange}
                inputProps={{ maxLength: 20 }}
                validation="numeric"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                error={error.longitude}
                name="longitude"
                label={t("ADD008")}
                helperText={error.longitude}
                value={addressPayload.longitude}
                handleChange={handleAddressChange}
                inputProps={{ maxLength: 20 }}
                validation="numeric"
                required={true}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomTextfield
                required
                error={error.latitude}
                name="latitude"
                label={t("ADD007")}
                helperText={error.latitude}
                value={addressPayload.latitude}
                handleChange={handleAddressChange}
                inputProps={{ maxLength: 20 }}
                validation="numeric"
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ marginTop: "15px" }}>
              <CustomDropdown
                label={t("ADD010") + " * "}
                error={error.timeZone}
                name="timeZone"
                helperText=""
                value={addressPayload.timeZone}
                handleChange={(e) => handleAddressChange(e)}
                data={timeZones}
              />
            </Grid>

           { process.env.REACT_APP_ENVIRONMENT === 'USA' ?  
            <Grid item xs={12} sm={12} sx={{ marginTop: "15px" }}>
               <CustomDropdown
                  label={t("ATYPE001") + " * "}
                  error={error.addressTypeId}
                  name="addressTypeId"
                  helperText={error.addressTypeId}
                  value={addressPayload.addressTypeId}
                  handleChange={(e) => handleAddressChange(e)}
                  data={addressTypes}
                />
            </Grid>: 
            <Grid item xs={12} sm={6} sx={{ marginTop: "15px" }}>
               <CustomDropdown
                  label={t("ATYPE001") + " * "}
                  error={error.addressTypeId}
                  name="addressTypeId"
                  helperText={error.addressTypeId}
                  value={addressPayload.addressTypeId}
                  handleChange={(e) => handleAddressChange(e)}
                  data={addressTypes}
                />
          </Grid>}
          </Grid>
        </Grid>
      </DialogBox>

    </>
  );
}
