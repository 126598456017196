import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import LoginLayoutV2 from './LoginLayoutV2';
import { useTranslation } from 'react-i18next';
import forgot_pass from '../../assets/USA_Images/login_images/forgotpassword.svg';
import vis_forgot_pass from '../../assets/images/onboard/forgot_pass_img.svg';
import forgot_password from '../../assets/USA_Images/login_images/forgotPass.svg';
import vis_forgot_password from '../../assets/images/onboard/forgot_password.svg';
import CommonUtil from '../../Util/CommonUtils';
import CustomTextfield from '../../components/CustomInputs/CustomTextfield';
import { forgotPassword } from '../../slices/User';
import Toast from '../../components/ToastContainer/CustomToast';
import { ReactComponent as UserIcon } from "../../assets/UserIcon.svg";


const useStyles = makeStyles((theme) => ({
    form: {
        width: '70%',
        marginTop: theme.spacing(3),
    },
    submit: {
        backgroundColor: '#36c96d',
        borderRadius: '0.2rem',
        padding: '10px',
        '&:hover': {
            background: '#119743',
        },
    },
}));

function ForgotPasswordScreen(props) {
    const classes = useStyles();
    const [email, setEmail] = useState();
    const [error, setError] = useState({
        email: false,
    });

    const query = new URLSearchParams(props.location.search);
    const { t } = useTranslation();
    const handleChange = (event) => {
        const name = event.target.name;
        setEmail(event.target.value);
        setError({
            [name]: false,
        });
    };
    const dispatch = useDispatch();
    const history = useHistory();
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(forgotPassword(email))
            .unwrap()
            .then((data) => {
               if (data.code === 'CVAI0001') {
                    history.push('/vis/email-sent'); 
               }
                else {
                Toast((data.message), 'error');
                }
            })
            .catch((er) => { })
    };

    return !CommonUtil.isEmptyString(query.get('code')) ? (
        <></>
    ) : (
        <LoginLayoutV2 lang={true} src={ process.env.REACT_APP_ENVIRONMENT === 'USA' ?  forgot_pass : vis_forgot_pass}>
            <Grid className='center_div'>
                <Grid container alignItems='center'>
                    <Grid item className='logo-center'>
                        <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  forgot_password : vis_forgot_password}
                         alt='logo' style={{ width: '180px', marginBottom:'30px' }} />
                    </Grid>
                </Grid>
                <form className={classes.form} noValidate onSubmit={handleSubmit} autoComplete='off'>
                    <Grid item xs={12}>
                        <Typography variant='h2' align='left' style={{ color: '#464646', fontWeight: '500', marginTop: '30px' }}>
                            {t("FORGOTPASS001")}
                        </Typography>
                        <Typography variant='subtitle2' align='left' style={{ color: '#7a7b97', marginBottom: '20px', marginTop: '6px', fontSize: '12px' }}>
                            {t("FORGOTPASSWORD0003")}
                        </Typography>
                    </Grid>
                    <Grid container alignItems='center'>
                    <Grid item xs style={{ display: 'flex' }}>
                                <Grid className="company_icon">
                                    <UserIcon className='visibleIcons' />
                                </Grid>
                            <CustomTextfield
                                 type={'text'}
                                 error={error.email}
                                 name='email'
                                 label={t("LOGINPAGE002")+"*"}
                                 value={email}
                                 handleChange={(e) => handleChange(e)}
                                 helperText={error.email}
                                 validation='email'
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={5} alignItems='center' style={{ marginTop: '10px' }}>
                        <Grid item xs>
                            <Button type='submit' fullWidth variant='contained' >
                                {t('FORGOTPASSWORD0004')}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
                <Grid container alignItems='center'>
                    <Grid item xs>
                        <Box mt={1} align='center'>
                            <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px', marginTop: '20px' }}>
                                Back to <Link href='/vis/login' className='link_green'> {t('LOGINFORM0002')}</Link>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </LoginLayoutV2>

    );
}

export default withRouter(ForgotPasswordScreen);
