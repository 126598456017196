import { Grid } from "@mui/material";
import React from "react";
import TitleBar from "../../components/v4/TitleBar";

export default function RightViewLayout(props) {

  return (
    <>
      <TitleBar
        back_button={props.back_button}
        title={props.title ? props.title : "Dummy title"}
        actions={props.actions}
      />
      <Grid
        container
        style={{
          width: "85vw",
          minHeight: props.type !== 'table' ? '75vh' :'none',
          border: props.type !== 'table' ? "1px solid #EAEBEB" : 'none',
          borderRadius: "10px",
          backgroundColor: "#FFFFFF",
          overflowY: "auto",
          height: "fit-content",
          marginBottom: props.type !== 'table' ? "60px" : 'none'
        }}
        p={1}
      >
        {props.children}
      </Grid>
    </>
  );
}
