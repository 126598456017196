import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import SendInviteIcon from "../../assets/Group5341.svg";
import CommonUtil from "../../Util/CommonUtils";
import DataTable from "../../components/DataTable/DataTable";
import ResendIcon from "../Invitations/ResendIcon";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import CancelIcon from "../Invitations/CancelIcon";
import BlockVisitorIcon from "../Invitations/BlockVisitorIcon";
import InvitationFilterForm from "../../components/InvitationFilterForm";
import "../../App.css";
import Search from "../../components/SearchTab/Search";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import { useTranslation } from "react-i18next";
import HeaderToolbar from "../../components/HeaderToolbar";
import moment from "moment";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import { Avatar } from "@material-ui/core";
import {
  listInvitations,
  resendInvitation,
} from "../../slices/Invitations/InvitationsSlice";
import CreateNewRegistration from "./CreateNewRegistration";
import RescheduleIcon from "../Invitations/RescheduleIcon";
import AddRegistrationIcon from "../../assets/images/Registrations/AddRegistrationIcon.svg";
import VisitorRegistrationViewPage from "./VisitorRegistrationViewPage";
import Toast from "../../components/ToastContainer/CustomToast";
import RescheduleVisitor from "../../components/ReschduleVisitor/RescheduleVisitor";
import CancelRegistration from "./CancelRegistration";
import BlockVisitorForm from "../../components/DialogBoxComponent/BlockVisitorForm";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";

function RegistrationList() {
  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [openCancelVisitorDailog, setOpenCancelVisitorDailog] = useState(false);
  const [visitorId, setVisitorId] = useState();
  const [visitorFirstName, setVisitorFirstName] = useState("");
  const [openRescheduleDailog, setOpenRescheduleDailog] = useState(false);
  const [id, setId] = useState();
  const [openBlacklistVisitor, setOpenBlacklistVisitor] = useState(false);
  const [blacklistId, setBlacklistId] = useState();
  const zlink = localStorage.getItem("redirectFrom");

  const [roles, setRoles] = useState([]);
  const [viewRegistration, setViewRegistration] = useState(true);
  const [listRegistration, setListRegistration] = useState(true);
  const [createRegistration, setCreateRegistration] = useState(true);
  const [cancelRegistration, setCancelRegistration] = useState(true);
  const [rescheduleRegistration, setRescheduleRegistration] = useState(true);
  const [resendRegistrations, setResendRegistrations] = useState(true);
  const [blockRegistration, setBlockRegistration] = useState(true);

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const status = [
    "SCHEDULED",
    "CONFIRMED",
    "APPROVED",
    "CHECKED_IN",
    "CANCELLED",
    "REJECTED",
    "CHECKED_OUT",
    "MISSED",
  ];

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const [filter, setFilter] = React.useState({
    status: [
      "SCHEDULED",
      "CONFIRMED",
      "APPROVED",
      "CHECKED_IN",
      "CANCELLED",
      "REJECTED",
      "CHECKED_OUT",
    ],
    purposeTypeId: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: ["WALKIN_REGISTARTION", "SELF_REGISTARTION", "WALKIN_REGISTARTION_KIOSK"],
    isPagable: true,
  });

  const defaultFilter = {
    status: [
      "SCHEDULED",
    "CONFIRMED",
    "APPROVED",
    "CHECKED_IN",
    "CANCELLED",
    "REJECTED",
    "CHECKED_OUT",
    ],
    purposeTypeId: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: ["WALKIN_REGISTARTION", "SELF_REGISTARTION", "WALKIN_REGISTARTION_KIOSK"],
    isPagable: true,
  };

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setViewRegistration(l.includes("VIEW_REGISTRATION") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setCreateRegistration(l.includes("CREATE_REGISTRATION") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setCancelRegistration(l.includes("CANCEL_REGISTRATION") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setBlockRegistration(l.includes("BLOCK_REGISTRATION") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setRescheduleRegistration(l.includes("RESCHEDULE_REGISTRATION") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setResendRegistrations(l.includes("RESEND_REGISTRATION") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setListRegistration(l.includes("REGISTRATION_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("REGISTRATION_LIST")) {
          dispatch(listInvitations(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
              if ((data.code = "CVAI0000" && data.data)) {
                setTotalRecords(data.data.totalCount);
                setRows(data.data.visits);
                setPage(data.data.curPage);
                setTotalPages(data.data.totalPages);
              } else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("REGISTRATION_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);

  const loadData = (filter, paging) => {
    dispatch(listInvitations(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "CVAI0000" && data?.data)) {
          setTotalRecords(data?.data?.totalCount);
          setRows(data?.data?.visits);
          setPage(data?.data?.curPage);
          setTotalPages(data?.data?.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => {});
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      siteId: data.siteId,
      purposeTypeId: data.purposeTypeId,
      visitMode: data.visitMode,
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
      status: data.status,
    });
  };

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(filter, paging);
      return;
    }

    dispatch(listInvitations(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "CVAI0000" && data?.data)) {
          if (searchedVal === "Check In") {
            searchedVal = "CHECKED_IN";
          }
          const filteredRows = data?.data?.visits.filter((test) => {
            return (
              (test.visitor.firstName
                ? test.visitor.firstName
                    .toLowerCase()
                    .includes(searchedVal.toLowerCase())
                : "") ||
              (test.visitor.lastName
                ? test.visitor.lastName
                    .toLowerCase()
                    .includes(searchedVal.toLowerCase())
                : "") ||
              (test.visitor.phone
                ? test.visitor.phone.toLowerCase().includes(searchedVal.toLowerCase())
                : "") ||
              (test.visitor.email
                ? test.visitor.email.toLowerCase().includes(searchedVal.toLowerCase())
                : "") ||
              (test.hostName
                ? test.hostName
                    .toLowerCase()
                    .includes(searchedVal.toLowerCase())
                : "") ||
              (test.scheduledStartDate
                ? test.scheduledStartDate
                    .toLowerCase()
                    .includes(searchedVal.toLowerCase())
                : "") ||
              test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
              `${test.visitor.firstName} ${test.visitor.lastName}`
                .toLowerCase()
                .includes(searchedVal.toLowerCase())
            );
          });
          setRows(filteredRows);
        } else {
          setRows([]);
        }
      })
      .catch((er) => {});
    
  };

  const handleCancelVisit = (params) => {
    setOpenCancelVisitorDailog(!openCancelVisitorDailog);
    setVisitorId(params.id);
    setVisitorFirstName(params.row.visitor.firstName);
  };

  const handleReschdueleVisitor = (params) => {
    setOpenRescheduleDailog(!openRescheduleDailog);
    setId(params.id);
  };

  const getStatus = (params) => {
    if (params.row.status === "CHECKED_IN" || params.row.status === "CHECKED_OUT" || params.row.status === "MISSED" ) {
      return true;
    }
  };

  const handleResendEmail = (params) => {
    dispatch(resendInvitation(params.id))
      .unwrap()
      .then((data) => {
        if ((data?.code === "CVAI0070")) {
          Toast("Registration Sent Successfully", "success");
        } else {
        }
      })
      .catch((er) => {});
  };

  const visitExpired = (params) => {
    if (
      params.row.status !== "SCHEDULED" &&
      params.row.status !== "APPROVED"
    ) {
      return true;
    }
  };

  const getCancelStatus = (params) => {
    if (params.row.status === "CHECKED_IN" || params.row.status === "CHECKED_OUT" || params.row.status === "CANCELLED" || params.row.status === "REJECTED" || params.row.status === "MISSED") {
      return true;
    }
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
        return text.substring(0, 17) + '...';
    } else {
    return text;
   }
 }

  const handleBlacklistVisit = (params) => {
    setOpenBlacklistVisitor(!openBlacklistVisitor);
    setBlacklistId(params.row.id);
  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
    button={
      <Avatar
        style={{
        cursor:'pointer',
      }}
        alt={params.row.visitor.firstName}
        src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
      >{params.row.visitor.firstName == null ? "" :params.row.visitor.firstName.charAt(0)}</Avatar>
      }
      child={
        <Avatar
          style={{
            width: "300px",
            height: "300px",
            border: "12px solid #FFFF",
            fontSize: "100px",
            borderRadius: "1px",
            cursor:'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        >{params.row.visitor.firstName == null ? "" :params.row.visitor.firstName.charAt(0)}</Avatar>
      }
    />
  );

  const columns = React.useMemo(() => [
    {
      field: "visitor.profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return ZoomHandaler(params);
      },
    },
    {
      field: "visitor.firstName",
      headerName: t("COMMON014"),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName),
        renderCell: (params) => (
          <Tooltip title={` ${ params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName)}`}>
             <span>{handleTextOverflow( params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName))}</span>
             </Tooltip>
              ),
    },
    {
      field: "visitor.phone",
      headerName: t("COMMON063"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.phone,
      renderCell: (params) => (
        <Tooltip title={` ${ params.row.visitor.phone}`}>
           <span>{handleTextOverflow(params.row.visitor.phone)}</span>
           </Tooltip>
            ),
    },
     {
      field: "visitor.email",
      headerName: t("PERSON005"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
      renderCell: (params) => (
        <Tooltip title={` ${ params.row.visitor.email}`}>
           <span>{handleTextOverflow(params.row.visitor.email)}</span>
           </Tooltip>
            ),
    },
    {
      field: "hostName",
      headerName: t("commonsTextFeildHost"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.hostName,
      renderCell: (params) => (
        <Tooltip title={` ${params.row.hostName}`}>
           <span>{handleTextOverflow(params.row.hostName)}</span>
           </Tooltip>
            ),
    },
    {
      field: "scheduledStartDate",
      headerName: t("INVITEDATELBL"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        moment(CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)).format(
          "DD-MM-YYYY HH:mm"
        ),
    },
    {
      field: "status",
      headerName: t("COMMON001"),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />;
      },
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [

        (<>
        {viewRegistration &&
        <GridActionsCellItem
          icon={<VisitorRegistrationViewPage rowData={params.row} />}
          label="View"
        />}
        </>),

        (<>
        {cancelRegistration &&
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          onClick={() => handleCancelVisit(params)}
          disabled={getCancelStatus(params)}
        />}
        </>),

        (<>
        {rescheduleRegistration &&
        <GridActionsCellItem
          icon={<RescheduleIcon />}
          label="Reschedule"
          onClick={() => handleReschdueleVisitor(params)}
          disabled={getStatus(params)}
        />}
        </>),

        (<>
        {blockRegistration &&
        <GridActionsCellItem
          icon={<BlockVisitorIcon />}
          label="BlockVisitor"
          onClick={() => handleBlacklistVisit(params)}
        />}
        </>),

        (<>
        {resendRegistrations &&
        <GridActionsCellItem
          icon={<ResendIcon />}
          label="Resend"
          onClick={() => handleResendEmail(params)}
          disabled={visitExpired(params)}
        />}
        </>),

      ],
    },
  ]);

  return (
    <>
    
    {createRegistration ?(
      <HeaderToolbar
        src={AddRegistrationIcon}
        onClick={handleOpen}
        title="Registrations"
        tooltipTitle={t("ADDREGISTRATION")}
      />):

      (<HeaderToolbar
        title="Registrations"
        tooltipTitle={t("ADDREGISTRATION")}
      />)
    }

    {listRegistration &&
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >

      <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <InvitationFilterForm
            rowsPerPage={rowsPerPage}
            selectedObject={filter.visitMode}
            status= {status}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

       
        <Box item>
        <Tooltip title={"Refresh"}>
              <IconButton onClick={() => loadData(filter, paging)}>
                <Refresh />
              </IconButton>
            </Tooltip>
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>

      </Box>}

    {listRegistration ? (
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      ):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
      <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
          {t('CVROLE009')}
      </Typography>
    </div>)
  }

      {open && (
        <CreateNewRegistration
          open={open}
          handleClose={() => {
            handleClose(loadData(filter, paging));
          }}
        />
      )}

      {openCancelVisitorDailog && (
        <CancelRegistration
          open={openCancelVisitorDailog}
          handleClose={(data) => {
            setOpenCancelVisitorDailog(data);
            loadData(filter, paging);
          }}
          CancelId={visitorId}
          CancelFirstName={visitorFirstName}
        />
      )}

      {openRescheduleDailog && (
        <RescheduleVisitor
          open={openRescheduleDailog}
          handleClose={(data) => {
            setOpenRescheduleDailog(data);
            loadData(filter, paging);
          }}
          CustomerID={id}
        />
      )}

      {openBlacklistVisitor && (
        <BlockVisitorForm
          open={openBlacklistVisitor}
          handleClose={(data) => {
            setOpenBlacklistVisitor(data);
            loadData(filter, paging);
          }}
          BlacklistVisitorId={blacklistId}
        />
      )}
      
    </>
  );
}

export default RegistrationList;
