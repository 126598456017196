import {
    Tooltip,
    Grid,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { ReactComponent as FilterIcon } from "../../../../src/assets/FilterIcon.svg";
  import { toast } from "react-toastify";
  import { useTranslation } from "react-i18next";
  import DialogBox from "../../../components/DialogBoxComponent/DialogBox";
import Toast from "../../../components/ToastContainer/CustomToast";

 
  export default function FilterSiteType(props) {
    const [open, setOpen] = React.useState(false);
  
    const { t } = useTranslation();
  
    const [payload, setPayload] = useState({
      name: "",
      code: "",
    });
  
    const handleClose = () => {
      setPayload({
        name: "",
        code: "",
      });
      setOpen(false);
    };
  
    const [error, setError] = useState({
        name: false,
        code: false,
    });
  
    const reset = () => {
      if (
        payload.name.trim() === "" &&
        payload.code.trim() === "" 
      ) {
        Toast(t("COMMON027"), "error");
        return;
      }
  
      setPayload({
        name: "",
        code: "",
      });
    };
  
    const handleChange = (event) => {
      const name = event.target.name;
      setPayload({
        ...payload,
        [name]: event.target.value,
      });
  
      setError({
        [name]: false,
      });
    };
  
    const handleSubmit = () => {
  
  
      if (
        payload.name.trim() === "" &&
        payload.code.trim() === "" 
      ) {
        Toast(t("COMMON015"), 'error')
        return;
      }
  
      props.applyFilter(payload);
      setPayload({
        name: "",
        code: "",
      });
      handleClose();
    };
  
    useEffect(() => {}, []);
  
    return (
      <>
        <Tooltip
          title={
            <Typography fontSize={12} fontWeight={500}>
             {t('COMMON006')}
            </Typography>
          }
        >
          <FilterIcon onClick={() => setOpen(true)} style={{cursor:'pointer'}}>
            <FilterSiteType />
          </FilterIcon>
        </Tooltip>
  
        <DialogBox
          Header={t('COMMON006')}
          acceptText={t('COMMON024')}
          cancelText={t('COMMON025')}
          fullWidth={true}
          open={open}
          onClose={handleClose}
          onSubmit={handleSubmit}
          cancel={reset}
        >
          <Grid
            container
            justifyContent="center"
            style={{ padding: "16px 32px", width: "100%" }}
            spacing={2}
          >
            <Grid item xs={12} sm={10.5} style={{ marginTop: "15px" }}>
              <TextField
                variant="outlined"
                label={t("COMMON014")}
                error={error.name}
                name="name"
                value={payload.name}
                onChange={handleChange}
                autoComplete="off"
                inputProps={{ maxLength: 50 }}
                style={{ margin: "1px" }}
                fullWidth
              />
            </Grid>
  
            <Grid item xs={12} sm={10.5} style={{ marginTop: "15px" }}>
              <TextField
                variant="outlined"
                label={t("COMMON045")}
                error={error.code}
                name="code"
                value={payload.code}
                onChange={handleChange}
                autoComplete="off"
                inputProps={{ maxLength: 50 }}
                style={{ margin: "1px" }}
                fullWidth
              />
            </Grid>
  
            
          </Grid>
        </DialogBox>
      </>
    );
  }
  