import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Typography } from '@material-ui/core';
import img1 from '../../assets/USA_Images/selfRegistartion.png';
import img1_visitor from '../../assets/USA_Images/login_images_visitor/img1.png';
import img2 from '../../assets/USA_Images/dashbaord.png';
import img2_visitor from '../../assets/USA_Images/login_images_visitor/img2.png';
import img3 from '../../assets/USA_Images/invitation.svg';
import img3_visitor from '../../assets/USA_Images/login_images_visitor/img3.svg';
import img4 from '../../assets/USA_Images/registration.svg';
import img4_visitor from '../../assets/USA_Images/login_images_visitor/img4.svg';
import img5 from '../../assets/USA_Images/visitor.svg';
import img5_visitor from '../../assets/USA_Images/login_images_visitor/img5.svg';
import img6 from '../../assets/USA_Images/visitorlog.svg';
import img6_visitor from '../../assets/USA_Images/login_images_visitor/img6.svg';

export default function SimpleSlider() {
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true
    };

    return (
        <Grid
            style={{
                width: '100%',
            }}
        >
            <Slider {...settings}>
                <Grid container alignItems='center'>
                    <Grid item xs={12} className="carousel-item">
                        <img 
                        src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  img1 : img1_visitor } alt='logo' style={{ width: '600px'}} className="carousel-item" />
                        <Typography variant='h4' gutterBottom>
                          Self Registration
                        </Typography>
                        <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                        It gives visitors the flexibility of self-registering their information on the cloud platform
                        </Typography>
                    </Grid>
                </Grid>
                    <Grid item xs={12} className="carousel-item">
                        <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  img2 : img2_visitor} alt='logo' style={{ width: '600px'}} className="carousel-item" />
                        <Typography variant='h4' gutterBottom>
                          Dashboard
                        </Typography>
                        <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                        A dashboard is a sort of graphical user interface that shows key application performance data in a quick look
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="carousel-item">
                        <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  img3 : img3_visitor} alt='logo' style={{ width: '600px'}} className="carousel-item" />
                        <Typography variant='h4' gutterBottom>
                          Send Invitation
                        </Typography>
                        <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                        Onboarding a new visitor or scheduling a frequent/regular visitor with a seamless effect
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="carousel-item">
                        <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  img4 : img4_visitor} alt='logo' style={{ width: '600px'}} className="carousel-item" />
                        <Typography variant='h4' gutterBottom>
                          Registration
                        </Typography>
                        <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                        The admin has the privilege to register for unauthorized visitors with certain details on the cloud platform
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="carousel-item">
                        <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  img5 : img5_visitor} alt='logo' style={{ width: '600px'}} className="carousel-item" />
                        <Typography variant='h4' gutterBottom>
                          Block Visitor
                        </Typography>
                        <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                        Cloud security guarantees that your data and services are not easily accessible to unauthorized visitors
                        </Typography>
                    </Grid> 
                    <Grid item xs={12} className="carousel-item">
                        <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  img6 : img6_visitor} alt='logo' style={{ width: '600px'}} className="carousel-item" />
                        <Typography variant='h4' gutterBottom>
                          Visitor Log
                        </Typography>
                        <Typography variant='subtitle2' color='textSecondary' gutterBottom>
                        A log is used to keep track of individuals who enter the secure cloud platform as well as their activity information
                        </Typography>
                    </Grid>
            </Slider>
        </Grid>
    );
}