import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import employeeService from '../../services/Employee/EmployeeService';

const initialState = [];

export const listAllEmployees = createAsyncThunk('listAllEmployees/list', async (payload) => {
    const res = await employeeService.listAllEmployees(payload);
    return res.data;
});

export const createEmployee = createAsyncThunk('createEmployee/create', async (payload) => {
    const res = await employeeService.createEmployee(payload);
    return res.data;
});

export const updateEmployee = createAsyncThunk('updateEmployee/update', async (payload) => {
    const res = await employeeService.updateEmployee(payload);
    return res.data;
});

export const deleteEmployee = createAsyncThunk('deleteEmployee/delete', async (employeeIdOrEmployeeCode) => {
    const res = await employeeService.deleteEmployee(employeeIdOrEmployeeCode);
    return res.data;
});

export const activateEmployeeAccount = createAsyncThunk('activateEmployeeAccount/activate', async (payload) => {
    const res = await employeeService.activateEmployeeAccount(payload);
    return res.data;
});

export const checkEmployeeMemeberIsActive = createAsyncThunk('checkEmployeeMemeberIsActive/isActive', async (payload) => {
    const res = await employeeService.checkEmployeeMemeberIsActive(payload);
    return res.data;
});

export const validateEmployeeActivationCode = createAsyncThunk('validateEmployeeActivationCode/activateCode', async (payload) => {
    const res = await employeeService.validateEmployeeActivationCode(payload);
    return res.data; 
});

export const validateEmployeeCode = createAsyncThunk('validateEmployeeCode/validateCode', async (payload) => {
    const res = await employeeService.validateEmployeeCode(payload);
    return res.data;
});


export const viewEmployeeById = createAsyncThunk('viewEmployee/view', async (employeeIdOrCode) => {
    const res = await employeeService.viewEmployeeById(employeeIdOrCode);
    return res.data;
});

export const getUserById = createAsyncThunk('getUser/getUserId', async (payload) => {
    const res = await employeeService.UserById(payload);
    return res.data;
});

export const deactivateEmployeeAccount = createAsyncThunk('deactivateEmployeeAccount/membershipId', async (id) => {
    const res = await employeeService.deactivateEmployeeAccount(id);
    return res.data;
});

const employeeSlice = createSlice({
    name: 'listAllEmployees',
    initialState,
    extraReducers: {
        [listAllEmployees.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = employeeSlice;
export default reducer;