import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import PersonService from '../../services/Person/PersonService';
const initialState = [];

export const getPersonList = createAsyncThunk('person/getPersonList', async (payload) => {
  const res = await PersonService.getPersonList(payload);
  return res.data;
})

export const createPerson = createAsyncThunk('person/create', async (payload) => {
  const res = await PersonService.createPerson(payload);
  return res.data;
})

export const updatePerson = createAsyncThunk('person/update', async (payload) => {
  const res = await PersonService.updatePerson(payload);
  return res.data;
})


export const getPersonAddress = createAsyncThunk('person/updateAddress', async (payload) => {
  const res = await PersonService.getPersonAddress(payload);
  return res.data;
})


export const deletePerson = createAsyncThunk('person/deletePerson', async (payload) => {
  const res = await PersonService.deletePerson(payload);
  return res.data;
})

export const activateAccount = createAsyncThunk('person/activateAccount', async (payload) => {
  const res = await PersonService.activateAccount(payload);
  return res.data;
})

export const checkIsMemeberActive = createAsyncThunk('person/checkIsMemeberActive', async (payload) => {
  const res = await PersonService.checkIsMemeberActive(payload);
  return res.data;
})

export const validateActivationCode = createAsyncThunk('person/validateActivationCode', async (payload) => {
  const res = await PersonService.validateActivationCode(payload);
  return res.data;
})

export const validateCode = createAsyncThunk('person/validateCode', async (payload) => {
  const res = await PersonService.validateCode(payload);
  return res.data;
})

export const manageUserrole = createAsyncThunk('role/manageUserRole', async (payload) => {
  const res = await PersonService.manageUserrole(payload);
  return res.data;
})

const personSlice = createSlice({
  name: "person",
  ...initialState,
  extraReducers: {
    [getPersonList.fulfilled]: (state, action) => {
      if (action.payload.code === "") {
        return action.payload.data;
      } else {
        return [];
      }
    },
    [createPerson.fulfilled]: (state, action) => {
      if (action.payload.code === "") {
        return action.payload.data;
      } else {
        return [];
      }
    },

  },
});

const { reducer } = personSlice;

export default reducer;