import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import AccessLevelService from '../services/AccessLevelService';

const initialState = [];

export const listcloudAccess_Levels = createAsyncThunk('visitor_type/access_level', async (payload) => {
  const res = await AccessLevelService.listcloudAccess_Levels(payload);
  return res.data;

});

export const listAccessLevelsPurposeType = createAsyncThunk('accesslevel', async (payload) => {
  const res = await AccessLevelService.getAccessLevel(payload);
  return res.data;

});

export const deleteAccessLevel = createAsyncThunk('accesslevel', async (payload) => {
  const res = await AccessLevelService.deleteAccessLevel(payload);
  return res.data;

});


export const addAccessLevel = createAsyncThunk('accesslevel', async (payload) => {
  const res = await AccessLevelService.addAccessLevel(payload);
  return res.data;

});



const accessLevelSlice = createSlice({
  name: 'company',
  initialState,
  extraReducers: {
    [listAccessLevelsPurposeType.fulfilled]: (state, action) => {
      // if (action.payload.code === 'UASI0000' && !CommonUtil.isEmpty(action.payload.data)) {
      //   return action.payload.data.company;
      // } else {
      //   return [];
      // }
    },
  },
});

const { reducer } = accessLevelSlice;
export default reducer;


