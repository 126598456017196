import React from "react";
import { Tooltip, Grid } from "@mui/material";

const ActivateIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  const zlink=localStorage.getItem('redirectFrom');
  return (
    <>
      <Tooltip title={!zlink ?"Activate Account":"Manage user role"}>
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="17.365" height="18.75" viewBox="0 0 17.365 18.75">
              <g transform="translate(-43.625 0.375)">
                <path id="activate-account-hover-state" d="M9,14.192A3.807,3.807,0,1,1,12.808,18,3.808,3.808,0,0,1,9,14.192Zm.692,0a3.115,3.115,0,1,0,3.116-3.115A3.119,3.119,0,0,0,9.692,14.192ZM0,17.564V15.979A7.3,7.3,0,0,1,11,9.694,5.089,5.089,0,0,0,7.3,14.583a5.038,5.038,0,0,0,.967,2.981H0Zm.692-1.586v.894H7.076a5.726,5.726,0,0,1-.472-2.29A5.79,5.79,0,0,1,9.317,9.69,6.609,6.609,0,0,0,.692,15.979Zm11.823-.695a.273.273,0,0,1-.191-.076l-.8-.743a.284.284,0,0,1-.014-.4.28.28,0,0,1,.4-.013l.586.547,1.529-1.747a.279.279,0,1,1,.419.368l-1.718,1.965a.281.281,0,0,1-.2.1ZM3.168,3.808A3.808,3.808,0,1,1,6.976,7.616,3.808,3.808,0,0,1,3.168,3.808Zm.692,0A3.115,3.115,0,1,0,6.976.692,3.119,3.119,0,0,0,3.86,3.808Z" transform="translate(44)" fill="#3d4977" />
                <path id="activate-account-hover-state_-_Outline" data-name="activate-account-hover-state - Outline" d="M12.808,18.375a4.183,4.183,0,1,1,4.182-4.183A4.188,4.188,0,0,1,12.808,18.375Zm0-6.923a2.74,2.74,0,1,0,2.74,2.74A2.743,2.743,0,0,0,12.808,11.452ZM8.264,17.939H-.375V15.979A7.672,7.672,0,0,1,11.2,9.371l.757.448-.847.236a4.714,4.714,0,0,0-3.434,4.528,4.664,4.664,0,0,0,.878,2.737l.619.619ZM1.067,16.5H6.532a6.113,6.113,0,0,1-.3-1.915A6.2,6.2,0,0,1,8.451,9.857a6.234,6.234,0,0,0-7.384,6.121Zm11.471-.838h-.022a.652.652,0,0,1-.449-.179l-.794-.741a.655.655,0,0,1,.894-.959l.3.284,1.274-1.455a.654.654,0,1,1,.981.865l-1.716,1.962a.656.656,0,0,1-.463.223ZM6.976,7.991a4.183,4.183,0,1,1,4.183-4.183A4.188,4.188,0,0,1,6.976,7.991Zm0-6.924a2.74,2.74,0,1,0,2.74,2.741A2.744,2.744,0,0,0,6.976,1.067Z" transform="translate(44)" fill="#3d4977" />
              </g>
            </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="17.365" height="18.75" viewBox="0 0 17.365 18.75">
              <path id="Path_6246" data-name="Path 6246" d="M9,14.192A3.807,3.807,0,1,1,12.808,18,3.808,3.808,0,0,1,9,14.192Zm.692,0a3.115,3.115,0,1,0,3.116-3.115A3.119,3.119,0,0,0,9.692,14.192ZM0,17.564V15.979A7.3,7.3,0,0,1,11,9.694,5.089,5.089,0,0,0,7.3,14.583a5.038,5.038,0,0,0,.967,2.981H0Zm.692-1.586v.894H7.076a5.726,5.726,0,0,1-.472-2.29A5.79,5.79,0,0,1,9.317,9.69,6.609,6.609,0,0,0,.692,15.979Zm11.823-.695a.273.273,0,0,1-.191-.076l-.8-.743a.284.284,0,0,1-.014-.4.28.28,0,0,1,.4-.013l.586.547,1.529-1.747a.279.279,0,1,1,.419.368l-1.718,1.965a.281.281,0,0,1-.2.1ZM3.168,3.808A3.808,3.808,0,1,1,6.976,7.616,3.808,3.808,0,0,1,3.168,3.808Zm.692,0A3.115,3.115,0,1,0,6.976.692,3.119,3.119,0,0,0,3.86,3.808Z" transform="translate(0.375 0.375)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.75" />
            </svg>

          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default ActivateIcon;
