import React, { useEffect, useState } from "react";
import HeaderToolbar from "../../components/HeaderToolbar";
import RestrictTypeAddIcon from "../../../src/assets/RestrictTypeAddIcon.svg"
import { Box, IconButton, Link, Tooltip, Typography } from "@mui/material";
import Search from "../../components/SearchTab/Search";
import ChecklistFilterForm from "../Checklist/ChecklistFilterForm";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DataTable from "../../components/DataTable/DataTable";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ChecklistTemplateDetails from "../Checklist/ChecklistTemplateDetails";
import EditChecklistIcon from "../Checklist/EditChecklistIcon";
import AddRestrictType from "./AddRestrictType";
import { getRestrictType } from "../../slices/RestrictType/RestirctTypeSlice";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";


export default function RestrictType(props) {
    const [open, setOpen] = useState(false);
    const [clear, setClear] = React.useState(false);
    const [rows, setRows] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [del, setDel] = useState(false);
    const [pageSize, setPageSize] = useState(0);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const zlink = localStorage.getItem("redirectFrom");

    const [roles, setRoles] = useState([]);
    const [createRestrictType, setCreateRestrictType] = useState(true);
    const [listRestrictType, setListRestrictType] = useState(true);
    const [viewRestrictType, setViewRestrictType] = useState(true);
    const [editRestrictType, setEditRestrictType] = useState(true);

    const [selectedObject, setSelectedObject] = useState({
        name: '',
        description: '',
    });

    const [paging, setPaging] = useState({
        page: 1,
        rowsPerPage: 10,
    });

    const [filter, setFilter] = React.useState({
        name: "",
    });

    const defaultPaging = {
        page: 1,
        rowsPerPage: 10,
    };

    const defaultFilter = {
        name: "",
    };

    const handleChange = (newPage, size) => {
        setPage(newPage);
        setRowsPerPage(size);
        setPaging({
            page: newPage,
            rowsPerPage: size,
        });
    };

    useEffect(() => {
        loadData(filter, paging);
    }, [filter, paging, clear]);

    const handleOpen = () => {
        setOpen(true);
    };

    const resetFilter = () => {
        setClear(false);
        setPaging({ ...paging, page: 1 });
        setFilter(defaultFilter);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
        .unwrap()
        .then((res) => {
            var l = [];
            res.data.rolePermissions.map((item, index) => {
                l.push(item.code);
          });
            setRoles(l);
            setCreateRestrictType(l.includes("CREATE_EDIT_RESTRICT_TYPE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
            setEditRestrictType(l.includes("CREATE_EDIT_RESTRICT_TYPE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
            setViewRestrictType(l.includes("VIEW_CV_RESTRICT_TYPE") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setListRestrictType(l.includes("RESTRICT_TYPE_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
    
            if (l.includes("RESTRICT_TYPE_LIST")) {
              dispatch(getRestrictType(Object.assign(filter, paging)))
                .unwrap()
                .then((data) => {
                    if ((data?.code === "CVAI0000" && data?.data)) {
                      setTotalRecords(data?.data?.totalCount);
                      setPage(data?.data?.curPage);
                      setTotalPages(data?.data?.totalPages);
                      setRows(data?.data?.purposeTypes);
                      setPageSize(data?.data?.pageSize);
                    } else {
                    setRows([]);
                  }
                })
                .catch((er) => { });
               }
             });
      }, []);

    useEffect(() => {
      if (roles.includes("RESTRICT_TYPE_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
        }
    }, []);

    const loadData = (filter, paging) => {
        dispatch(getRestrictType(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
                if ((data?.code === "CVAI0000" && data?.data)) {
                    setTotalRecords(data?.data?.totalCount);
                    setPage(data?.data?.curPage);
                    setTotalPages(data?.data?.totalPages);
                    setRows(data?.data?.purposeTypes);
                    setPageSize(data?.data?.pageSize);
                } else {
                    setRows([]);
                }
            })
            .catch((er) => { });
    };

    const handleFilter = (data) => {
        setClear(true);
        setPaging({ ...paging, page: 1 });
        setFilter({
            name: data.name,
        });
    };

    const handleEditRestrictType = (params) => {
        setOpenEditDialog(!openEditDialog);
        setSelectedObject(params);
    };

    const globalsearch = (searchedVal) => {
        if (!searchedVal) {
            loadData(filter, paging);
            return;
          }
        dispatch(getRestrictType(Object.assign(filter, paging)))
        .unwrap()
        .then((data) => {
            if ((data?.code === "CVAI0000" && data?.data)) {                
                const filteredRows = data?.data?.purposeTypes.filter((test) => {
                    return (test.name ? test.name.toLowerCase().includes(searchedVal.toLowerCase()) : "")
                        || test.description.toLowerCase().includes(searchedVal.toLowerCase())
                });
                setRows(filteredRows);
            } else {
                setRows([]);
            }
        })
        .catch((er) => { });
        
    };

    useEffect(() => {

        if (del === true && parseInt(pageSize) !== 1) {
            loadData(filter, { page: page, rowsPerPage: rowsPerPage });
            setDel(false);
        } else {
            if (del === true) {
                setPaging({ page: page - 1, rowsPerPage: rowsPerPage })
                setDel(false);
            }
        }
    }, [del]);

    const columns = React.useMemo(() => [
        {
            headerName: "",
            sortable: false,
            width: 5,
            renderCell: (params) => {
                return <div></div>;
            },
        },
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            width: 110,
            valueGetter: (params) => params.row.name,
        },

        {
            field: "description",
            headerName: "Description",
            flex: 1,
            minWidth: 150,
            valueGetter: (params) => params.row.description,
        },

        {
            field: "Actions",
            headerName: t("ACTION"),
            type: "actions",
            flex: 1,
            minWidth: 190,
            width: 150,
            headerAlign: "center",
            getActions: (params) => [

                (<>
                {viewRestrictType &&
                <GridActionsCellItem
                  icon={<ChecklistTemplateDetails
                  title={"Restrict Type Details"}
                  rowData={params.row} />}
                  label="View"
                />}
                </>),

                (<>
                {editRestrictType &&
                <GridActionsCellItem
                  icon={<EditChecklistIcon />}
                  label="Edit"
                  onClick={() => handleEditRestrictType(params.row)}
                />}
                </>),

            ],
        },
    ]);

    return (
        <>

          {createRestrictType?(
            <HeaderToolbar
                src={RestrictTypeAddIcon}
                onClick={handleOpen}
                title=" Restrict Type "
                tooltipTitle="Add Restrict" 
            />):

            (<HeaderToolbar
                title=" Restrict Type "
                tooltipTitle="Add Restrict" 
            />)
           }

         {listRestrictType &&
            <Box
                display={"flex"}
                width={"100%"}
                flexDirection={"row-reverse"}
                alignItems={"center"}
            >
                <Box item>
                    {clear && (
                        <Link
                            href="#"
                            underline="hover"
                            className="line"
                            style={{
                                opacity: "0.8",
                                color: "#E3393C",
                                fontSize: "14px",
                                underline: "hover",
                                display: "inline",
                            }}
                            onClick={resetFilter}
                        >
                            {"Clear Filter"}
                        </Link>
                    )}
                </Box>

                <Box item>
                    <ChecklistFilterForm
                        rowsPerPage={rowsPerPage}
                        applyFilter={(data) => handleFilter(data)}
                    />
                </Box>

                {zlink ? (
                    <>
                        <Box item>
                            <Tooltip title={"Refresh"}>
                                <IconButton onClick={() => loadData(filter, paging)}>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </>) : (<></>)}


                <Box item p={1}>
                    <Search
                        onSearch={(e) => globalsearch(e)}
                        clearSearch={() => loadData(filter, paging)}
                    />
                </Box>

            </Box>}

        {listRestrictType ? (
            <div style={{ height: 300, width: "100%" }}>
                <DataTable
                    columns={columns}
                    rows={rows}
                    page={page}
                    count={totalRecords}
                    rowId={(row) => row.id}
                    pageCount={totalPages}
                    pageSize={rowsPerPage}
                    handleChange={(newPage, size) => handleChange(newPage, size)}
                />
            </div>
                ):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
                      <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                          {t('CVROLE020')}
                      </Typography>
                   </div>)
        }

            {open && (
                <AddRestrictType
                    open={open}
                    selectedObject={selectedObject}
                    handleClose={() => {
                        setOpen(false);
                        loadData(filter, paging);
                    }}
                />
            )}
            {openEditDialog && (
                <AddRestrictType
                    open={openEditDialog}
                    selectedObject={selectedObject}
                    handleClose={() => {
                        setOpenEditDialog(false);
                        loadData(filter, paging);
                        setSelectedObject({
                            id: '',
                            code: '',
                            name: '',
                            description: '',
                        });
                    }}
                />
            )}

        </>
    )
}


