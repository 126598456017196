import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Box, Link, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AddRegistrationIcon from "../../assets/images/Registrations/AddRegistrationIcon.svg";
import {
  listInvitations,
  resendInvitation,
} from "../../slices/Invitations/InvitationsSlice";
import CommonUtil from "../../Util/CommonUtils";
import DataTable from "../../components/DataTable/DataTable";
import CreateNewRegistration from "./CreateNewRegistration";
import RegistrationFilterForm from "../../components/RegistrationFilterForm";
import CancelIcon from "../Invitations/CancelIcon";
import RescheduleIcon from "../Invitations/RescheduleIcon";
import BlockVisitorIcon from "../Invitations/BlockVisitorIcon";
import ResendIcon from "../Invitations/ResendIcon";
import "../../App.css";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import VisitorProfileCard from "../../components/VisitorActionsComponents/VisitorProfileCard";
import RescheduleVisitor from "../../components/ReschduleVisitor/RescheduleVisitor";
import Search from "../../components/SearchTab/Search";
import BlockVisitorForm from "../../components/DialogBoxComponent/BlockVisitorForm";
import CancelRegistration from "./CancelRegistration";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import HeaderToolbar from "../../components/HeaderToolbar";
import Toast from "../../components/ToastContainer/CustomToast";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import VisitorRegistrationViewPage from "./VisitorRegistrationViewPage";


export default function Registrations() {
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [clear, setClear] = React.useState(false);
  const [openRescheduleDailog, setOpenRescheduleDailog] = useState(false);
  const [id, setiD] = useState();
  const [openCancelVisitorDailog, setOpenCancelVisitorDailog] = useState(false);
  const [visitorId, setVisitorId] = useState();
  const [visitorFirstName, setVisitorFirstName] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [openBlockVisitor, setOpenBlockVisitor] = useState(false);
  const [blockId, setBlockId] = useState();
  const [registrationId, setRegistrationId] = useState();
  const { t } = useTranslation();

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [filter, setFilter] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    status: [
      "SCHEDULED",
      "CONFIRMED",
      "APPROVED",
      "CHECKED_IN",
      "CANCELLED",
      "INITIATED_FOR_BLOCKED",
      "BLOCKED",
      "REJECTED",
    ],
    visitorTypeId: "",
    purposeTypeId: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    hostId: "",
    visitMode: ["WALKIN_REGISTARTION"],
    isPagable: true,
  });

  const defaultFilter = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    status: [
      "SCHEDULED",
      "CONFIRMED",
      "APPROVED",
      "CHECKED_IN",
      "CANCELLED",
      "INITIATED_FOR_BLOCKED",
      "BLOCKED",
      "REJECTED",
    ],
    visitorTypeId: "",
    purposeTypeId: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    hostId: "",
    visitMode: ["WALKIN_REGISTARTION"],
    isPagable: true,
  };

  const applyFilter = (filterData) => {
    setClear(true);
    var today = new Date(filterData.scheduledStartDate);
    var startDate =
      today.getFullYear() +
      formatDayAndMonth(today.getMonth() + 1) +
      formatDayAndMonth(today.getDate()) +
      "T" +
      today.getHours() +
      ":" +
      today.getMinutes();
    today = new Date(filterData.scheduledEndDate);
    var endDate =
      today.getFullYear() +
      formatDayAndMonth(today.getMonth() + 1) +
      formatDayAndMonth(today.getDate()) +
      "T" +
      today.getHours() +
      ":" +
      today.getMinutes();

    const query = {
      firstName: filterData.firstName,
      lastName: filterData.lastName,
      phone: filterData.phone,
      email: filterData.email,
      visitorTypeId: filterData.visitorTypeId,
      purposeTypeId: filterData.purposeTypeId,
      siteId: filterData.siteId,
      status: filterData.status,
      hostId: filterData.hostId,
      scheduledStartDate: CommonUtil.formatToUtc(filterData.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(filterData.scheduledEndDate),
      visitMode: [filterData.visitMode],
    };

    setFilter({
      ...filter,
      ...query,
    });
  };

  const resetFilter = () => {
    setClear(false);
    setFilter({
      ...defaultFilter,
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    loadData(newPage, size);
    setRowsPerPage(size);
  };

  const handleReschdueleVisitor = (params) => {
    setOpenRescheduleDailog(!openRescheduleDailog);
    setiD(params.id);
  };
  const handleCancelVisit = (params) => {
    setOpenCancelVisitorDailog(!openCancelVisitorDailog);
    setVisitorId(params.id);
    setVisitorFirstName(params.row.visitor.firstName);
  };

  const handleBlockVisit = (params) => {
    setOpenBlockVisitor(!openBlockVisitor);
    setBlockId(params.row.visitor.id);
    setRegistrationId(params.id);
  };

  useEffect(() => {
    loadData(1, rowsPerPage);
  }, [filter]);

  const loadData = (page, rowsPerPage) => {
    const payload = {
      filter: filter,
      page: page,
      rowsPerPage: rowsPerPage,
    };

    dispatch(listInvitations(payload))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0000" && data.data)) {
          setRows(data.data.visits);
          setPage(data.data.curPage);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };
  const globalsearch = (searchedVal) => {

      
    if (searchedVal === "Checked In") {
      searchedVal = "CHECKED_IN";
    }

    const filteredRows = rows.filter((test) => {
      return (
        (test.visitor.firstName? test.visitor.firstName.toLowerCase().includes(searchedVal.toLowerCase()): "") ||
        (test.visitor.lastName? test.visitor.lastName.toLowerCase().includes(searchedVal.toLowerCase()): "") ||
        (test.visitor.phone? test.visitor.phone.toLowerCase().includes(searchedVal.toLowerCase()): "") ||
        (test.visitor.email? test.visitor.email.toLowerCase().includes(searchedVal.toLowerCase()): "") ||
        (test.visitor.hostName? test.visitor.hostName.toLowerCase().includes(searchedVal.toLowerCase()): "") ||
        (test.visitor.scheduledStartDate? test.visitor.scheduledStartDate.toLowerCase().includes(searchedVal.toLowerCase()): "") ||
        test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
        `${test.visitor.firstName} ${test.visitor.lastName}`.toLowerCase().includes(searchedVal.toLowerCase())
      );
    });
    setRows(filteredRows);
  };

  const handleResendEmail = (params) => {
    dispatch(resendInvitation(params.id))
      .unwrap()
      .then((data) => {
        if ((data.code = "CVAI0070")) {
          Toast("Registration Sent Successfully", "success");
        } else {
        }
      })
      .catch((er) => { });
  };

  const visitExpired = (params) => {
    if (
      new Date(params.row.scheduledEndDate) < new Date() ||
      params.row.status === "CANCELLED" ||
      params.row.status === "CHECKED_IN"
    ) {
      return true;
    }
  }

  const getStatus = (params) => {
    if (params.row.status === 'CHECKED_IN') {
      return true;
    }
  };

  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          alt={params.row.visitor.firstName}
          // src={`data:image/png;base64,${params.row.visitor.profile}`}
          src={params.row.profilePhotoURL}
        ></Avatar>
      }
      child={<Avatar
        style={{
          width: '300px', height: '300px', border: "12px solid #FFFF",
          fontSize: '100px',
          borderRadius: '1px'
        }}
        alt={params.row.visitor.firstName}
        // src={`data:image/png;base64,${params.row.visitor.profile}`}
        src={params.row.profilePhotoURL}
      ></Avatar>}
    />
  )

  const columns = React.useMemo(() => [
    {
      field: "visitor.profile",
      headerName: "",
      sortable: false,
      width: 10,
      renderCell: (params) => {
        return (
          ZoomHandaler(params)
        );
      },
    },
    {
      field: "visitor.firstName",
      headerName: t("COMMON014"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => 
      params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName),
    },
    {
      field: "visitor.phone",
      headerName: t("COMMON063"),
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.phone,
    },
    {
      field: "visitor.email",
      headerName: t("PERSON005"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
    },
    {
      field: "hostName",
      headerName: t("commonsTextFeildHost"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.hostName,
    },
    {
      field: "scheduledStartDate",
      headerName: t("INVITEDATELBL"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
        moment(CommonUtil.getLocalDate(params.row.scheduledStartDate)).format("DD-MM-YYYY kk:mm")
    },
    {
      field: "status",
      headerName: t("COMMON001"),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />;
      }
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisitorRegistrationViewPage rowData={params.row} />}
        />,
        <GridActionsCellItem
          icon={<CancelIcon />}
          label="Cancel"
          onClick={() => handleCancelVisit(params)}
        />,
        <GridActionsCellItem
          icon={<RescheduleIcon />}
          label="Reschedule"
          onClick={() => handleReschdueleVisitor(params)}
          disabled={getStatus(params)}
        />,
        <GridActionsCellItem
          icon={<BlockVisitorIcon />}
          label="BlockVisitor"
          onClick={() => handleBlockVisit(params)}
        />,

        <GridActionsCellItem
          icon={<ResendIcon />}
          onClick={() => handleResendEmail(params)}
          disabled={visitExpired(params)}
        />,
      ],
    },
  ]);

  const status = [
    "SCHEDULED",
    "CONFIRMED",
    "APPROVED",
    "CHECKED_IN",
    "CANCELLED",
    "INITIATED_FOR_BLOCKED",
    "BLOCKED",
    "REJECTED",
  ];

  return (
    <>
      <HeaderToolbar src={AddRegistrationIcon} onClick={handleOpen} title="Registration" tooltipTitle= {t('ADDREGISTRATION')}/>
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "1",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <RegistrationFilterForm
            rowsPerPage={rowsPerPage}
            selectedObject={filter.registrationMode}
            status={status}
            applyFilter={(data) => applyFilter(data)}
          />
        </Box>
        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(page, rowsPerPage)}
          />
        </Box>
      </Box>
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
      {open && (
        <CreateNewRegistration
          open={open}
          handleClose={() => {
            handleClose();
            loadData(page, rowsPerPage);
          }}
        />
      )}
      {openRescheduleDailog && (
        <RescheduleVisitor
          open={openRescheduleDailog}
          handleClose={(data) => {
            setOpenRescheduleDailog(data);
            loadData(page, rowsPerPage);
          }}
          CustomerID={id}
        />
      )}
      {openCancelVisitorDailog && (
        <CancelRegistration
          open={openCancelVisitorDailog}
          handleClose={(data) => {
            setOpenCancelVisitorDailog(data);
            loadData(page, rowsPerPage);
          }}
          CancelId={visitorId}
          CancelFirstName={visitorFirstName}
        />
      )}
      {openBlockVisitor && (
        <BlockVisitorForm
          open={openBlockVisitor}
          handleClose={(data) => {
            setOpenBlockVisitor(data);
            loadData(page, rowsPerPage);
          }}
          BlockVisitorId={blockId}
          BlockRegistrationId={registrationId}
        />
      )}
    </>
  );
}
