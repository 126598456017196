import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import sucess_img1 from '../../assets/USA_Images/login_images/visitSuccess.svg';
import vis_sucess_img1 from '../../assets/visitSuccess.svg';

const useStyles = makeStyles((theme) => ({
    submit: {
        width: '350px',
        backgroundColor: '#36c96d',
        borderRadius: '0.2rem',
        padding: '10px',
        '&:hover': {
            background: '#119743',
        },
    },
    success: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },
    footer: {
        bottom: '0px',
        padding: '40px',
        textAlign: 'center',
        width: '100%',
        position: 'relative',
    }
}));

function VisitSuccess() {
    const { t } = useTranslation();
    const classes = useStyles();
    
    window.history.pushState(null, null, window.location.href); 
    window.onpopstate = function () { window.history.go(1); };

    return (
        <Grid className={classes.success} >
            <Grid container alignItems='center'>
                <Grid item className='logo-center'>
                    <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  sucess_img1 : vis_sucess_img1} alt='logo' style={{ width: '300px' }} />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '25px', marginTop: '10px' }}>
                <Typography variant='h3' align='center' style={{ color: '#464646', fontWeight: '500' }}>
                    Visit Confirmed Successfully
                </Typography>
            </Grid>

            <Grid className={classes.footer}>
                <Typography style={{ fontSize: '10px' }} color='textSecondary'>
                    Copyright © 2022 ZKTECO CO., LTD. All rights reserved
                </Typography>
            </Grid>
        </Grid>
    );
}
export default VisitSuccess;
