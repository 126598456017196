

import api from '../config/http-common';

// const get = (payload) => {
//     return api.securedAxios().get('http://10.10.10.142:9092/api/v2.0/employee/search?page=' + payload.pageNumber + '&Size=' + payload.pageSize + '&email=' + payload.email);
// };

const get = (payload) => {
    return api.securedAxios().post('/web/employees/list?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize + '&email=' + payload.email);
};

const allHostList = (payload) => {
    return api.securedAxios().post('/web/employees/list?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=' + payload.pageNumber + '&pageSize=' + payload.pageSize);
};

const HostService = {
    get,
    allHostList,
};

export default HostService;
