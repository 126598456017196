import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import LoginLayoutV2 from './LoginLayoutV2';
import { useTranslation } from 'react-i18next';
import sucess_img1 from '../../assets/USA_Images/login_images/passwordSuccess.svg';
import vis_sucess_img1 from '../../assets/images/onboard/sucess_img1.svg';
import sucess_img2 from '../../assets/images/onboard/sucess_img2.svg';
import vis_sucess_img2 from '../../assets/images/onboard/sucess_img2.svg';


const useStyles = makeStyles((theme) => ({
    form: {
        width: '70%',
        marginTop: theme.spacing(3),
    },
    submit: {
        backgroundColor: '#36c96d',
        borderRadius: '0.2rem',
        padding: '10px',
        '&:hover': {
            background: '#119743',
        },
    },
}));

function SuccessScreen(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();

    function handleClick(e) {
        e.preventDefault();
        history.push('/vis/login');
      }

    return (
        <>
            <LoginLayoutV2 lang={true} src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  sucess_img2 : vis_sucess_img2}>
                <Grid className='center_div'>
                <Grid container alignItems='center'>
                    <Grid item className='logo-center'>
                        <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ?  sucess_img1 : vis_sucess_img1} alt='logo' style={{ width: '250px' }} />
                    </Grid>
                </Grid>
                    <form className={classes.form} noValidate autoComplete='off'>
                        <Grid item xs={12} style={{ marginBottom: '25px', marginTop: '10px' }}>
                            <Typography variant='h2' align='center'  style={{ color: '#464646', fontWeight: '500' }}>
                               Success!
                            </Typography>
                        </Grid>
                        <Grid>
                            <Typography variant='body1' color='textSecondary' align='center' gutterBottom>
                            Your password has been reset successfully. Click here to sign in button.
                            </Typography>
                        </Grid>
                        <Grid container spacing={5} alignItems='center'>
                            <Grid item xs>
                                <Box mt={3}>
                                    <Button onClick={handleClick}
                                        fullWidth variant='contained'
                                    >
                                        {t('VERIFYCOMP0004')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
            </LoginLayoutV2>
        </>
    );
}
export default withRouter(SuccessScreen);
