import { Avatar, Box, Divider, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import React from 'react'
import EditIcon from '../Person/EditIcon';
import SampleDevice from '../../assets/DeviceIcon/SampleDevice.svg';
import TitleBar from '../../components/v4/TitleBar';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { ReactComponent as DeviceEdit } from '../../assets/DeviceIcon/DeviceEdit.svg';
import { toast } from 'react-toastify';
import { updateDevice } from '../../slices/ZlinkDeviceManagement/DeviceSlice';
import { timeZoneList } from '../../components/v4/TimeZoneList/timeZoneData';
import Toast from '../../components/ToastContainer/CustomToast';

export const ViewDevice = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({});

  const [deviceAlias, setDevicealias] = useState({ deviceAlias: '' });
  const [error, setError] = useState({ deviceAlias: '' });
  useEffect(() => {
    if (location.state!==null) {
      setState({ ...state, ...location.state.state });
      setDevicealias({ deviceAlias: location.state.state.deviceName });
    }
  }, []);
  const handleDeviceName = (e) => {
    const name=e.target.name
    const value = e.target.value;
    setDevicealias({ deviceAlias: value });
    setError({ deviceAlias: '' });
  };
  const handleUpdate = () => {
    if (deviceAlias.deviceAlias==='') {
      setError({
        ...error,
        deviceAlias: "This field is required",
      });
      toast('Please enter device name', {
        variant: 'error',
      });
      return;
    }
    
    const payload = {
      ...deviceAlias,
      id: state.id
      
    };
    dispatch(updateDevice(payload)).unwrap().then((data) => {
      if (data?.code === 'DMSI0000') {
        Toast("Updated successfully", "success");
        setState({
          ...state,
          deviceName: deviceAlias.deviceAlias,
        });
        setOpen(false);
      } else {
        Toast("There is some issue ", "error");
      }
    });
  };
  const getTimezoneName = (value) => {
    var name = '';
    if(value.toString().includes('%')){
      timeZoneList.map((t) => {
        if (value === t.value) {
          name = t.name;
        }
      })
      return name;
    }else{
    let encoded = encodeURIComponent(value);
    timeZoneList.map((t) => {
      if (encoded === t.value) {
        name = t.name;
      }
    })
    return name;
  }
  }


 
  return (
    <>
      <TitleBar title={'View device details'}  back_button={true}/>

      <Paper elevation={0} style={{ minHeight: '200px', backgroundColor: '#ffffff', borderRadius: '8px', paddingTop: '16px', paddingBottom: '16px' }}>
        <Grid container direction='row' justifyContent='space-evenly' alignItems='flex-start'>
          <Grid item lg={2}>
            <IconButton
              variant='contained'
              component='label'
              style={{ font: 'normal normal normal 18px/24px Roboto', letterSpacing: '0px', backgroundColor: '#EFF3F8', width: '100%', height: '100%', flexDirection: 'column' }}
              disabled={true}
            >
              <Avatar alt='Device' 
              src={SampleDevice} 
              style={{ height: '100%', width: '100%' }} sx={[() => ({ '& .MuiAvatar-img': { width: '50%' } })]} />
              {/* <Grid style={{ display: 'none' }}>
                    <FileBase64 multiple={false} onDone={(file) => handleProfile(file)} />
                 </Grid> */}
            </IconButton>
          </Grid>
          <Grid item lg={4}>
            <Box display='flex' alignItems='center' height='52px' paddingBottom={1}>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>Device alias name</Typography>
              </Box>
              <Box item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                {open===true ? (
                   <>
                     <TextField 
                     label="Device name"
                      style={{ width: '80%' }}
                      sx={{ '& .MuiOutlinedInput-input': { paddingTop: '0px !important', paddingBottom: '0px !important', height: '40px' } }}
                      value={deviceAlias.deviceAlias}
                      name='deviceAlias'
                      onChange={handleDeviceName}
                      error={error.deviceAlias} 
                      variant="outlined" />
                    <DeviceEdit style={{ width: '40px', height: '40px', marginLeft: '8px' }}
                     onClick={handleUpdate} 
                     />
                </>
                ) : (
                  <>
                    <Typography variant='fieldValue'>
                      {state.deviceName}
                      </Typography>
                      <IconButton onClick={() => setOpen(!open)}> <EditIcon/></IconButton>
                   {' '}
                  </>
                )}
              </Box>
            </Box>

            <Box display='flex' alignItems='center' height='52px'>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>Site name</Typography>
              </Box>
              <Box item>
                <Typography variant='fieldValue'>
                  {state.siteName}
                  </Typography>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' height='52px'>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>Zone name</Typography>
              </Box>
              <Box item>
                <Typography variant='fieldValue'>
                  {state.zoneName}
                  </Typography>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' height='52px'>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>Site Time Zone</Typography>
              </Box>
              <Box item>
                <Typography variant='fieldValue'>
                  {state.siteTimeZone&&getTimezoneName(state.siteTimeZone)}
                  </Typography>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' height='52px'>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>IP Address</Typography>
              </Box>
              <Box item>
                <Typography variant='fieldValue'>
                  {state.ipAddress}
                  </Typography>
              </Box>
            </Box>
            {/* <Box display='flex' alignItems='center' height='52px'>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>MCU version</Typography>
              </Box>
              <Box item>
                <Typography variant='fieldValue'>{'-'}</Typography>
              </Box>
            </Box> */}
          </Grid>
          <Divider orientation='vertical' flexItem />
          <Grid item lg={5} paddingLeft='32px'>
            <Box display='flex' alignItems='center' height='52px'>
              <Box item paddingRight={1} minWidth='150px'>
                <Typography variant='fieldLabel'>Firmware Version</Typography>
              </Box>
              <Box item>
                <Typography variant='fieldValue'>
                  {state.fwVersion}
                  </Typography>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' height='52px'>
              <Box item paddingRight={1} minWidth='150px'>
                {/* <Typography variant='fieldLabel'>Status</Typography> */}
              </Box>
              <Box item>
                {/* <Typography variant='fieldValue'>
                  <span style={{color:state.status==='0'?'#4caf50':'#f44336'}}><b>{state.status==='0'?'Online':'Offline'}</b></span>
                  </Typography> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
