import { Typography } from "@material-ui/core";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import visLogo from "../../assets/images/onboard/vis-logo.png";
import CancelButtons from "../../components/DialogBoxComponent/CancelButtons";
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
import Toast from "../../components/ToastContainer/CustomToast";
import httpCommon from '../../config/http-common';
import CommonUtil from "../../Util/CommonUtils";

function SelfRegistrationSuccess(props) {
  const location = useLocation();

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [visit, setVisit] = useState({});
  const [visitor, setVisitor] = useState({});
  const [checkListItems, setCheckListItems] = useState([]);
  const [ndaUrl, setNdaUrl] = useState(null);
  const [checkListAnswers, setCheckListAnswers] = useState(
    location.state.listValue
  );
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    setCheckListItems(props.location.state.listValue);
    window.history.forward();
    var checkListQuestions = [];
    props.location.state.listValue.map((data) => {
      checkListQuestions.push({
        Question: data.question,
        Answers: {},
      });
    });
    setCheckListAnswers(checkListQuestions);
    setIsLoading(false);
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);
    var error = false;
    var isAnswersMatched = false;
    checkListItems.map((question) => {
      if (error) return;
      checkListAnswers.map((expertedAnswer) => {
        if (question.question === expertedAnswer.Question) {
          if (
            question.mandatory !== "No" &&
            CommonUtil.isEmpty(expertedAnswer.Answers)
          ) {
            Toast("Please answer mandatory questions", "error");
            error = true;
          }
        }
      });
    });

    if (error) {
      return;
    }

    checkListItems.map((question) => {
          if (isAnswersMatched) return;
            checkListAnswers.map((expertedAnswer) => {
              if(question.mandatory == 'Yes'){
                 if (question.question === expertedAnswer.Question) {
                  if (question.expertedAnswer !== expertedAnswer.Answers) {
                     Toast("Answered questions are not matched with checklist items.", "error");
                     handleCancel();
                     isAnswersMatched = true;
                    }
                   }
                  }});
                });
    if (isAnswersMatched) {
      return;
    }

    const payload = {
      visitId: props.location.state.id,
      answers: checkListAnswers,
    };

    httpCommon.unsecuredAxios()
    .post("/web/questionariesAnswer", payload)
    .then((res) => {
      const data = res.data;
      if (data.code === "CVHQI0000") {
           history.push({
             pathname: "/vis/visit-success",
             state: { message: res.data.message, success: true },
              });
            }
            else {
              Toast(data.message, "error");
            }
          })
      .catch((e) => {
        console.log(e);
    });
  setIsLoading(false);
};

const handleCancel = () => {
  const payload = {
    visitId: props.location.state.id,
  };
  const data = data;
  httpCommon.securedAxios().put('/web/visit/rejectById/' + payload.visitId)
    .then((res) => {
      if (res.data.code === "CVAI0073") {
        history.push({
          pathname: "/vis/confirms-page/rejects",
          state: { message: res.data.message, cancel: true },
        });
      } else {
        Toast(data.message, "error");
      }
    })
    .catch((e) => {
      console.log(e);
    });
  setIsLoading(false);
  return;
}

  const handleChange = (event) => {
    checkListAnswers.map((question) => {
      if (question.Question === event.target.name) {
        question.Answers = event.target.value;
      }
    });
  };

  return (
    <>
      <Grid
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
          backgroundColor: "#F4F6F8",
        }}
      >
        <Grid container alignItems="center">
          <Grid item className="logo-center">
            <img src={visLogo} alt="logo" style={{ width: "180px" }} />
          </Grid>
        </Grid>
      </Grid>
      <Paper>
        <Typography
          gutterBottom
          variant="h3"
          style={{ padding: "25px", paddingLeft: "75px" }}
        >
          Checklist Form
        </Typography>
        <Grid container p={5} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Grid container direction="row">
              {checkListItems.length > 0 &&
                checkListItems.map((checkList, index) => {
                  return (
                    <Grid item xs={12} sm={6}>
                      <Box
                        display="flex"
                        p={2}
                        marginLeft={"20px"}
                        marginRight={"20px"}
                      >
                        {index + 1 + '. '} <FormControl component="fieldset">
                          <FormLabel component='legend'>{checkList.question + (checkList.mandatory !== 'No' ? ' *' : '')}</FormLabel>
                          <RadioGroup row aria-label='gender' name={checkList.question} onChange={handleChange}>
                            {checkList?.options.map((data) => {
                              const splittedData = data.split(',');
                               if (splittedData?.length >= 0) {
                              let options = [];
                              splittedData.map((option) =>
                                options.push(<FormControlLabel value={option} control={<Radio />} label={option} />)
                              );
                              return options;
                            }
                          })}
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
        </Grid>
        {/* {isLoading ? <Loader /> : */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" sx={{ m: 1 }}>
            <CancelButtons
              sx={{ m: 1 }}
              color="secondary"
              onClick={() => { history.goBack(); }}
              variant="contained"
            >
              <Typography>{"Back"}</Typography>
            </CancelButtons>
            <SubmitButtons sx={{ m: 1 }} onClick={handleSubmit} variant="contained">
              <Typography>{"Submit"}</Typography>
            </SubmitButtons>
          </Box>
        </Grid>
        {/* } */}
      </Paper>
    </>
  );
}
export default SelfRegistrationSuccess;
