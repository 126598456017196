import {
  Tooltip,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import CommonUtil from "../../Util/CommonUtils";
import Toast from "../../components/ToastContainer/CustomToast";
import WatchlistFilterForm from "../Watchlist/WatchlistFilterForm";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";


export default function FilterChecklistTemplate(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const [payload, setPayload] = useState({
    name: "",
  });

  const handleClose = () => {
    setPayload({
    name: "",
    });
    setOpen(false);
  };

  const [error, setError] = useState({
    name: false,
  });

  const reset = () => {
    if (
      payload.name.trim() === ""
    ) {
      toast.error(t("Nothing to Reset"), {
        closeButton: false,
        hideProgressBar: true,
      });
      return;
    }

    setPayload({
    name: "",
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (
      payload.name.trim() === ""
    ) {
      Toast(("Please enter filter condition!"), 'error')
      return;
    }

   

    props.applyFilter(payload);
    setPayload({
      name: "",
    });
    handleClose();
  };
  useEffect(() => {}, []);

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            Filter
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{cursor:'pointer'}}>
          <FilterIcon />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        Header="Filter"
        acceptText="Apply"
        cancelText="Reset"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={reset}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={11}>
            <CustomTextfield
              label="Name"
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
            />
          </Grid>

        </Grid>
      </DialogBox>
    </>
  );
}
