

import { Tooltip, Grid, IconButton, Button, Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "../../assets/FilterIcon.svg";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { createTheme } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import CommonUtil from "../../components/Util/CommonUtils";
import { listDoorsByAccessLevelId } from "../../slices/VisitorAccessLevels/VisitorAccessLevelSlice";
import Toast from "../../components/ToastContainer/CustomToast";

const MuiTheme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#36c96d",
        },
        secondary: {
            main: "#f50057",
        },
    },
});

const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
    border: "1px solid green",
    borderRadius: "50%",
    "&:hover": {
        backgroundColor: "green",
        color: "#FFFF",
    },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
    border: "1px solid green",
    borderRadius: "50%",
    "&:hover": {
        backgroundColor: "green",
        color: "#FFFF",
    },
}));

const useStyles = makeStyles((theme) => ({
    textarea: {
        resize: "both",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        background: "#3d4977",
        width: "100%",
        padding: "5px",
        textAlign: "center",
        borderRadius: "5px",
        color: "#FFFFFF",
    },
}));

export default function AccessLevelFilter(props) {

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();
    const [door, setDoor] = useState();
    const { t } = useTranslation();
    const [filterQuery, setFilterQuery] = useState({
        name: '',
        id: '',
    });

    const paging = {
        page: 1,
        rowsPerPage: 10000,
    };

    const defaultFilter = {
        accessGroupid: props.accGroupId,
        name: '',
        id: '',
    }
    useEffect(() => {
        if (open === true)
            dispatch(listDoorsByAccessLevelId(Object.assign(defaultFilter, paging))).unwrap().then((data) => {
                if (data.code === 'DMSI0000') {
                    setDoor(data.data.accessGroupDoors);
                }
            })
    }, [open]);

    const handleClose = () => {
        setFilterQuery(defaultFilter);
        setOpen(false);
    }

    const handleSubmit = () => {
        if (filterQuery.name.trim() === "") {
            Toast(t("COMMON015"), "error");
            return;
        }
        props.onSubmit(filterQuery);
        handleClose();
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const reset = () => {
        if (filterQuery.name.trim() === "") {
            Toast(t("COMMON027"), "error");
            return;
        }

        setFilterQuery(defaultFilter);
    }

    const doorNameField = () => {
        return (
            <Autocomplete
                name="name"
                fullWidth
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => <TextField{...params} label={'Door name'} />}
                getOptionLabel={(option) => option && option.name}
                options={!CommonUtil.isEmpty(door) ? door : []}
                value={filterQuery}
                onChange={(event, newValue) => {
                    if (newValue !== null)
                        setFilterQuery(newValue);
                }}
            />
        )
    }
    return (
        <>
            <Tooltip title={<Typography fontSize={12} fontWeight={500}>{t("COMMON006")}</Typography>}>
                <FilterIcon onClick={handleOpen} style={{ cursor: 'pointer' }}>
                    <FilterIcon />
                </FilterIcon>
            </Tooltip>

            <DialogBox
                Header={t("COMMON006")}
                acceptText={t("COMMON024")}
                cancelText={t("COMMON025")}
                fullWidth={true}
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                cancel={reset}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems={"center"}
                >
                    <Grid item xs={12} sm={9} style={{ marginTop: "30px" }}>
                        {doorNameField()}
                    </Grid>
                </Grid>
            </DialogBox>
        </>
    );
}
