import React, { useEffect } from 'react';
import { useState } from 'react';
import { Box, Grid, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Typography } from '@mui/material';
import { TimePicker } from 'antd';
import localeES from 'antd/es/date-picker/locale/es_ES';
import localeZH from 'antd/es/date-picker/locale/zh_CN';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeRU from 'antd/es/date-picker/locale/ru_RU';
import localeJA from 'antd/es/date-picker/locale/ja_JP';
import localeTH from 'antd/es/date-picker/locale/th_TH';
import localeID from 'antd/es/date-picker/locale/id_ID';
import BrowserLanguage from '../../components/Util/BrowserLanguage';
import CancelButtons from '../../components/DialogBoxComponent/CancelButtons';
import SubmitButtons from '../../components/DialogBoxComponent/SubmitButtons';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
import CommonUtil from '../../components/Util/CommonUtils';
import { useDispatch } from 'react-redux';
import { saveTimeSlot } from '../../slices/TimeSlot/TimeSlotSlice';
import Toast from '../../components/ToastContainer/CustomToast';
import 'antd/dist/antd.min.css';
import TitleBar from '../../components/v4/TitleBar';
import CustomTextfield from '../../components/CustomInputs/CustomTextfield';
import Checkbox from '@material-ui/core/Checkbox';

export default function AddTimeSlots() {
    const { t } = useTranslation();
    const location = useLocation();
    const language = BrowserLanguage.getDefaultLanguage();
    const history = useHistory();
    const dispatch = useDispatch();

    const [copyMonday, setCopyMonday] = useState(false);

    const [monday1, setMonday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [monday2, setMonday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [monday3, setMonday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [tuesday1, setTuesday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [tuesday2, setTuesday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [tuesday3, setTuesday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [wednesday1, setWednesday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [wednesday2, setWednesday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [wednesday3, setWednesday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [thursday1, setThursday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [thursday2, setThursday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [thursday3, setThursday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [friday1, setFriday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [friday2, setFriday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [friday3, setFriday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [saturday1, setSaturday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [saturday2, setSaturday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [saturday3, setSaturday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [sunday1, setSunday1] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [sunday2, setSunday2] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);
    const [sunday3, setSunday3] = useState([moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')]);

    const [error, setError] = useState({
        name: false,
        remark: false,
    });
    const localeMap = {
        en: localeEN,
        es: localeES,
        zh: localeZH,
        ru: localeRU,
        ja: localeJA,
        th: localeTH,
        idn: localeID,
    };
    const [payload, setPayload] = React.useState({
        id: '',
        companyId: '',
        name: '',
        remark: '',
        sundayStart1: '',
        sundayEnd1: '',
        sundayStart2: '',
        sundayEnd2: '',
        sundayStart3: '',
        sundayEnd3: '',
        mondayStart1: '',
        mondayEnd1: '',
        mondayStart2: '',
        mondayEnd2: '',
        mondayStart3: '',
        mondayEnd3: '',
        tuesdayStart1: '',
        tuesdayEnd1: '',
        tuesdayStart2: '',
        tuesdayEnd2: '',
        tuesdayStart3: '',
        tuesdayEnd3: '',
        wednesdayStart1: '',
        wednesdayEnd1: '',
        wednesdayStart2: '',
        wednesdayEnd2: '',
        wednesdayStart3: '',
        wednesdayEnd3: '',
        thursdayStart1: '',
        thursdayEnd1: '',
        thursdayStart2: '',
        thursdayEnd2: '',
        thursdayStart3: '',
        thursdayEnd3: '',
        fridayStart1: '',
        fridayEnd1: '',
        fridayStart2: '',
        fridayEnd2: '',
        fridayStart3: '',
        fridayEnd3: '',
        saturdayStart1: '',
        saturdayEnd1: '',
        saturdayStart2: '',
        saturdayEnd2: '',
        saturdayStart3: '',
        saturdayEnd3: '',
    });


    useEffect(() => {
        if (copyMonday) handleCopyMonday(monday1, monday2, monday3);
    }, [monday1, monday2, monday3]);

    const timeFormat = 'HH:mm';

    const handleChange = (event) => {
        var val = event.target.value;
        if (val.trim() === '') {
            val = val.trim();
        }
        if (event.target.value.length > 0 && val.length == 0) {
            return;
        }
        if (val.length > 2) {
            if (val[val.length - 1] === ' ' && val[val.length - 2] === ' ') {
                return;
            }
            if (val[0] === ' ') {
                return;
            }
        }
        setCopyMonday(event.target.checked);
        var defaultTime = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
        if (event.target.checked) handleCopyMonday(monday1, monday2, monday3);
        else handleCopyMonday(defaultTime, defaultTime, defaultTime);
    };

    const handleSubmit = () => {
        if (CommonUtil.isEmptyString(payload.name)) {
            setError({...error, name: true,});
            toast.error('Name is required');
            return;
        }

        else if (!CommonUtil.validateName(payload.name)) {
            Toast(('Please enter the valid Time slots name'), 'error')
            setError({...error, name: true});
            return;
          }

        const state = {
            id: payload.id,
            name: payload.name,
            remark: payload.remark,
            companyId: payload.companyId,
            mondayStart1: monday1[0].format('HH:mm'),
            mondayStart2: monday2[0].format('HH:mm'),
            mondayStart3: monday3[0].format('HH:mm'),
            mondayEnd1: monday1[1].format('HH:mm'),
            mondayEnd2: monday2[1].format('HH:mm'),
            mondayEnd3: monday3[1].format('HH:mm'),

            tuesdayStart1: tuesday1[0].format('HH:mm'),
            tuesdayStart2: tuesday2[0].format('HH:mm'),
            tuesdayStart3: tuesday3[0].format('HH:mm'),
            tuesdayEnd1: tuesday1[1].format('HH:mm'),
            tuesdayEnd2: tuesday2[1].format('HH:mm'),
            tuesdayEnd3: tuesday3[1].format('HH:mm'),

            wednesdayStart1: wednesday1[0].format('HH:mm'),
            wednesdayStart2: wednesday2[0].format('HH:mm'),
            wednesdayStart3: wednesday3[0].format('HH:mm'),
            wednesdayEnd1: wednesday1[1].format('HH:mm'),
            wednesdayEnd2: wednesday2[1].format('HH:mm'),
            wednesdayEnd3: wednesday3[1].format('HH:mm'),

            thursdayStart1: thursday1[0].format('HH:mm'),
            thursdayStart2: thursday2[0].format('HH:mm'),
            thursdayStart3: thursday3[0].format('HH:mm'),
            thursdayEnd1: thursday1[1].format('HH:mm'),
            thursdayEnd2: thursday2[1].format('HH:mm'),
            thursdayEnd3: thursday3[1].format('HH:mm'),

            fridayStart1: friday1[0].format('HH:mm'),
            fridayStart2: friday2[0].format('HH:mm'),
            fridayStart3: friday3[0].format('HH:mm'),
            fridayEnd1: friday1[1].format('HH:mm'),
            fridayEnd2: friday2[1].format('HH:mm'),
            fridayEnd3: friday3[1].format('HH:mm'),

            saturdayStart1: saturday1[0].format('HH:mm'),
            saturdayStart2: saturday2[0].format('HH:mm'),
            saturdayStart3: saturday3[0].format('HH:mm'),
            saturdayEnd1: saturday1[1].format('HH:mm'),
            saturdayEnd2: saturday2[1].format('HH:mm'),
            saturdayEnd3: saturday3[1].format('HH:mm'),

            sundayStart1: sunday1[0].format('HH:mm'),
            sundayStart2: sunday2[0].format('HH:mm'),
            sundayStart3: sunday3[0].format('HH:mm'),
            sundayEnd1: sunday1[1].format('HH:mm'),
            sundayEnd2: sunday2[1].format('HH:mm'),
            sundayEnd3: sunday3[1].format('HH:mm'),
        };
        handleSave(state);
    };

    const handleSave = (payload) => {
        dispatch(saveTimeSlot(payload)).unwrap().then((res) => {
            if (res?.code === 'DMSI0007') {
                Toast('Time slot created successfully', 'success')
                handleClose();
            } else if (res?.code === 'DMSE00065') {
                Toast('Please select atleast one time interval', 'error')
            }
            else if (res?.code === 'DMSE00607') {
                Toast('Time Slot name already exist', 'error')
            }
            else if (res?.code === 'CASE0101') {
                setError({
                    ...error,
                    name: true,
                });
                Toast(res.msg, 'error')
            } else {
                Toast(res.msg, 'error')
            }
        })

    }

    const handleClose = () => {
        history.push({
            pathname: '/vis/timeslots',
        });
    };

    const handleCopyMonday = (monday1, monday2, monday3) => {
        setThursday1(monday1);
        setWednesday1(monday1);
        setTuesday1(monday1);
        setFriday1(monday1);
        setSunday1(monday1);
        setSaturday1(monday1);

        setSunday2(monday2);
        setTuesday2(monday2);
        setWednesday2(monday2);
        setThursday2(monday2);
        setFriday2(monday2);
        setSaturday2(monday2);

        setTuesday3(monday3);
        setWednesday3(monday3);
        setThursday3(monday3);
        setFriday3(monday3);
        setSaturday3(monday3);
        setSunday3(monday3);
    };

    return (
        <>
            <TitleBar title={t("Add Time Slots")} back_button={true} />

            <Grid style={{ paddingTop: '40px' }}>
                <div className='custom-container' style={{ minHeight: '6vh' }}>
                    <Grid container>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center' m={1} p={1}>
                                <CustomTextfield
                                    label="Name"
                                    error={error.name}
                                    name="name"
                                    value={payload.name}
                                    handleChange={(event) => {
                                        setPayload({ ...payload, name: event.target.value });
                                        setError({ ...error, name: false });
                                    }}
                                    helperText={error.name}
                                    validation="alphabets"
                                    required
                                    inputProps={{ maxLength: 30 }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </div>

                <div className='custom-container' style={{ fontSize: 'larger', marginTop: '25px', marginBottom: '10px' }}>
                    <Grid container style={{ paddingBottom: '15px', paddingTop: '20px' }} >
                        <Grid container>
                            <Grid item xs={3} lg={3}>
                            </Grid>
                            <Grid item xs={3} lg={3}>
                                <Typography variant='subtitle1' >
                                    Interval1
                                </Typography>
                            </Grid>
                            <Grid item xs={3} lg={3}>
                                <Typography variant='subtitle1' >
                                    Interval2
                                </Typography>
                            </Grid>
                            <Grid item xs={3} lg={3}>
                                <Typography variant='subtitle1' >
                                    Interval3
                                </Typography>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Box display='flex' justifyContent='center'>

                                <span style={{ verticalAlign: 'center' }}>{t('Monday')}</span>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3} >
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={monday1}
                                    onChange={(time, timeString) => setMonday1(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={monday2}
                                    onChange={(time, timeString) => setMonday2(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={monday3}
                                    onChange={(time, timeString) => setMonday3(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container style={{ paddingBottom: '15px' }}>
                        <Grid item xs={12} sm={2}>
                            <Box display='flex' justifyContent='center'>
                                <span style={{ verticalAlign: 'center' }}>{t('Tuesday')}</span>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={tuesday1}
                                    onChange={(time, timeString) => setTuesday1(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={tuesday2}
                                    onChange={(time, timeString) => setTuesday2(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={tuesday3}
                                    onChange={(time, timeString) => setTuesday3(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container style={{ paddingBottom: '15px' }}>
                        <Grid item xs={12} sm={2}>
                            <Box display='flex' justifyContent='center'>
                                <span style={{ verticalAlign: 'center' }}>{t('Wednesday')}</span>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={wednesday1}
                                    onChange={(time, timeString) => setWednesday1(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={wednesday2}
                                    onChange={(time, timeString) => setWednesday2(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={wednesday3}
                                    onChange={(time, timeString) => setWednesday3(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container style={{ paddingBottom: '15px' }}>
                        <Grid item xs={12} sm={2}>
                            <Box display='flex' justifyContent='center'>
                                <span style={{ verticalAlign: 'center' }}>{t('Thursday')}</span>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={thursday1}
                                    onChange={(time, timeString) => setThursday1(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={thursday2}
                                    onChange={(time, timeString) => setThursday2(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={thursday3}
                                    onChange={(time, timeString) => setThursday3(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container style={{ paddingBottom: '15px' }}>
                        <Grid item xs={12} sm={2}>
                            <Box display='flex' justifyContent='center'>
                                <span style={{ verticalAlign: 'center' }}>{t('Friday')}</span>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={friday1}
                                    onChange={(time, timeString) => setFriday1(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={friday2}
                                    onChange={(time, timeString) => setFriday2(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={friday3}
                                    onChange={(time, timeString) => setFriday3(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container style={{ paddingBottom: '15px' }}>
                        <Grid item xs={12} sm={2}>
                            <Box display='flex' justifyContent='center'>
                                <span style={{ verticalAlign: 'center' }}>{t('Saturday')}</span>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={saturday1}
                                    onChange={(time, timeString) => setSaturday1(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={saturday2}
                                    onChange={(time, timeString) => setSaturday2(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={saturday3}
                                    onChange={(time, timeString) => setSaturday3(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container style={{ paddingBottom: '15px' }}>
                        <Grid item xs={12} sm={2}>
                            <Box display='flex' justifyContent='center'>
                                <span style={{ verticalAlign: 'center' }}>{t('Sunday')}</span>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={sunday1}
                                    onChange={(time, timeString) => setSunday1(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={sunday2}
                                    onChange={(time, timeString) => setSunday2(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={3} style={{ paddingBottom: '20px' }}>
                            <Box display='flex' justifyContent='center'>
                                <TimePicker.RangePicker
                                    size="large"
                                    allowClear={false}
                                    locale={localeMap[language]}
                                    format={timeFormat}
                                    value={sunday3}
                                    onChange={(time, timeString) => setSunday3(time)}
                                    order={false}
                                    placeholder={[t('tableHeaderStartTime'), t('tableHeaderEndTime')]}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                </div>
                <Grid display='flex' p={1}>
                    <Box p={1} flexGrow={1} >
                        <FormControlLabel
                            control={<Checkbox
                                style={{ color: process.env.REACT_APP_BG_ICON }}
                                name='copyMonday'
                                checked={copyMonday}
                                onChange={handleChange}
                            />}
                            label={<Typography style={{ fontSize: 'large' }}>{t("Copy Monday's settings to other weekdays")}
                            </Typography>}
                        />
                    </Box>
                    <Box display='flex' style={{ flexDirection: 'row-reverse' }}>
                        <Box p={1}>
                            <CancelButtons onClick={handleClose} >
                                {t('COMMON008')}
                            </CancelButtons>
                        </Box>
                        <Box p={1}>
                            <SubmitButtons onClick={handleSubmit} >
                                {t('COMMON007')}
                            </SubmitButtons>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
