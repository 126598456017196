import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
  let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;

  if (!CommonUtil.isEmptyString(filter.startDateTime)) {
    const formattedStartDate = formatDate(filter.startDateTime);
    query = query + "&startDateTime=" + formattedStartDate;
  }
  if (!CommonUtil.isEmptyString(filter.endDateTime)) {
    const formattedEndDate = formatDate(filter.endDateTime);
    query = query + "&endDateTime=" + formattedEndDate;
  }
  if (!CommonUtil.isEmptyString(filter.personnelId)) {
    query = query + "&personnelId=" + filter.personnelId;
  }
  if (!CommonUtil.isEmptyString(filter.deviceAlias)) {
    query = query + "&deviceAlias=" + filter.deviceAlias;
  }
  if (!CommonUtil.isEmptyString(filter.cardNo)) {
    query = query + "&cardNo=" + filter.cardNo;
  }
  if (!CommonUtil.isEmptyString(filter.eventPointName)) {
    query = query + "&eventPointName=" + filter.eventPointName;
  }
  return query;
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

const getAllDeviceEvents = (filter) => {
  return api.securedAxios().get("/web/events" + buildQuery(filter));
};

const DeviceEventReportListService = {
  getAllDeviceEvents
};

export default DeviceEventReportListService;

