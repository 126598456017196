import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
    let query = "";

    if (filter.name !== '') {
        let name = encodeURIComponent(filter.name);
        query += '?name=' + name;
    }

    if ((filter.code !== '') & (filter.name !== '')) {
        let code = encodeURIComponent(filter.code);
        query += '&code=' + code;
    }

    if ((filter.code !== '') & (filter.name === '')) {
        let code = encodeURIComponent(filter.code);
        query += '?code=' + code;
    }
    return query;
};

const getAllUserRoles = (filter) => {
    return api.securedAxios().get('/web/role/lists' + buildQuery(filter));
}

const getPermissionsList = () => {
    return api.securedAxios().get('/web/role/permissions');
}

const createRolesandPermission = (payload) => {
    return api.securedAxios().post('/web/role/createRole', payload);
}

const updateRolesandPermission = (payload) => {
    return api.securedAxios().put('/web/role/update/' + payload.id, payload);
}

const deleteRolesandPermission = (roleId) => {
    return api.securedAxios().delete("/web/role/" + roleId)
}

const getPermissionsListByroleId = (roleId) => {
    return api.securedAxios().get('/web/role/'+roleId+"/permissions");
}

const updatePermission = (permissionId) => {
    return api.securedAxios().put('/web/permissions/' + permissionId);
}

const RoleandPermissionService = {
    getAllUserRoles,
    createRolesandPermission,
    getPermissionsList,
    updateRolesandPermission,
    deleteRolesandPermission,
    getPermissionsListByroleId,
    updatePermission,
}

export default RoleandPermissionService;