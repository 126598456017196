import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RestrictTypeService from '../../services/Restrict Type service/RestrictTypeService';



const initialState = [];

export const getRestrictType = createAsyncThunk('getRestrictType/', async (payload) => {
    const res = await RestrictTypeService.getRestrictType(payload);
    return res.data;
});

export const createRestrictType = createAsyncThunk('createRestrictType/create', async (payload) => {
    const res = await RestrictTypeService.createRestrictType(payload);
    return res.data;
});

export const updateRestrictType = createAsyncThunk('updateRestrictType/update', async (payload) => {
    const res = await RestrictTypeService.updateRestrictType(payload);
    return res.data;
});

export const deleteRestrictType = createAsyncThunk('deleteRestrictType/delete', async (payload) => {
    const res = await RestrictTypeService.deleteRestrictType(payload);
    return res.data;
});

export const getRestrictTypeById = createAsyncThunk('visitType/add', async (id) => {
    const res = await RestrictTypeService.getRestrictTypeById(id);
    return res.data;
});


const RestrictTypeSlice = createSlice({
    name: 'getRestrictType',
    initialState,
    extraReducers: {
        [getRestrictType.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = RestrictTypeSlice;
export default reducer;