import {
  Tooltip,
  Grid,
  Select,
  InputLabel,
  Autocomplete,
  TextField,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import { FormControl, MenuItem, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { listVisitorType } from "../../slices/VisitTypeSlice";
import DialogBox from "./DialogBox";
import { allHostList } from "../../slices/HostSlice";
import Applyi18nTOStatus from "../../components/Util/VisitorStatusutil";
import jwt_decode from "jwt-decode";
import AutocompleteComponent from "../CustomInputs/AutocompleteComponent";
import { getPurposeType } from "../../slices/PurposeTypeGlobalUSA/PurposeTypeGlobalSlice";
import Toast from "../ToastContainer/CustomToast";
import CustomTextfield from "../CustomInputs/CustomTextfield";
import CommonUtil from "../../Util/CommonUtils";


export default function FilterForm(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [hostList, setHostList] = useState([]);
  const [selectedHost, setSelectHost] = useState(null);
  const [selectedPurpose, setSelectedPurpose] = useState(null);
  const [purposeData, setPurposeData] = useState([]);

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );

  const [payload, setPayload] = useState({
    hostId: "",
    purposeTypeId: "",
    status: "",
    visitMode: props.visitMode,
  });

  const handleClose = () => {
    setSelectHost(null)
    setSelectedPurpose(null)
    setPayload({
      hostId: "",
      purposeTypeId: "",
      status: ""/* props.status */,
      visitMode: props.selectedObject,
    });
    setOpen(false);
  };

  const [error, setError] = useState({
    hostId: false,
    purposeTypeId: false,
  });

  const reset = () => {
    if (payload.hostId === "" && selectedPurpose === null && payload.status === "") {
      Toast(t("COMMON027"), "error");
      return;
    }

    if (selectedHost !== "") {
      setSelectHost(null)
    }

    if (selectedPurpose !== null) {
      setSelectedPurpose(null)

    }

    if (payload.status !== "") {
      setPayload({
        status: ''
      })
      return
    }

    setSelectHost("");
    setPayload({
      hostId: "",
      purposeTypeId: "",
      status: "" /* props.status */,
      visitMode: props.selectedObject,
    });
  };

  const handleChange = (event) => {

    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadPurposeType();
    loadHosts();
  };


  const loadPurposeType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };

    dispatch(getPurposeType(query))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI0000") {
          setPurposeData(data?.data?.purposeTypes);

        } else {
          setPurposeData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadHosts = () => {
    const payload = {
      pageNumber: 1,
      pageSize: 20,
      email: decoded_jwtToken.email,
    };
    dispatch(allHostList(payload))
      .unwrap()
      .then((data) => {
        if (data?.code === "OMSI0000") {
          getSelectedItem(data?.data?.employees[0]);

          setHostList(data?.data?.employees);
        } else {
          getSelectedItem();
          setHostList([]);
        }
      });
  };

  const handleSubmit = () => {
    if (
      payload.purposeTypeId.trim() === "" &&
      payload.hostId.trim() === "" &&
      payload.status === props.status
    ) {
      Toast(t("COMMON015"), "error");
      return;
    }

    props.applyFilter(payload);
    setSelectHost("");
    setPayload({
      hostId: "",
      purposeTypeId: "",
      status: ""/* props.status */,
    });
    setSelectedPurpose(null)
    handleClose();
  };

  const onselectPurposeType = (event) => {
    setPayload({
      ...payload,
      purposeTypeId: event.id,
    });
  };

  const getSelectedItem = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        hostId: newValue.id,
      });

    } else {
      setPayload({
        ...payload,
        hostId: ''
      })
      setSelectHost("");
    };
  }

  const selectedPurposeType = (newValue) => {

    if (newValue != null) {
      setPayload({
        purposeTypeId: newValue.id
      })
    }
    else {
      setPayload({
        ...payload,
        purposeTypeId: ''
      })
    }
  }



  const handleText = (event) => {
    if (CommonUtil.isValidEmails(event.target.value)) {
      console.log(event.target.value)
      const payload = {
        pageNumber: 1,
        pageSize: 10,
        email: event.target.value,
      };
      dispatch(allHostList(payload))
        .unwrap()
        .then((data) => {
          if (data?.code === "OMSI0000") {
            if (!CommonUtil.isEmptyArray(data.data)) {
              // getSelectedItem(data.data.employees[0]);
              setHostList(data?.data?.employees);

            } else {
              setHostList([]);
              getSelectedItem(null);
            }
          }
        });
    }
  };

  const filterOptions = createFilterOptions({ stringify: ({ firstName, email }) => `${firstName} ${email}`, });



  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            Filter
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
          <FilterForm />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        Header="Filter"
        acceptText="Apply"
        cancelText="Reset"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={reset}
      >
        <Grid container justifyContent="center" alignItems={"center"}>
          <Grid item xs={12} sm={9} sx={{ mt: 5 }}>
            <Autocomplete
              style={{
                margin: "-15px",
                marginLeft: "1px",
                color: "#242424",
              }}
              noOptionsText={'No Options found'}
              fullWidth
              filterOptions={filterOptions}
              options={hostList}
              getOptionLabel={(option) =>
                option.firstName ? option.firstName + ' ' + option.lastName : ""
              }
              value={selectedHost}
              onChange={(event, newValue) => {
                getSelectedItem(newValue);
                setSelectHost(newValue)
                setError({ selectedHost: false });
              }}
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label="Host"
                  required={false}
                  placeholder="Host"
                  name="hostId"
                  //key="Confirmation Code"
                  //id="hostId"
                  // onChange={handleText}
                  //handleChange={(e) => handleText(e)}
                  error={error.selectedHost}
                />
              )}
            />

          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 5 }}>
            {/* <AutocompleteComponent
              label="Purpose Type"
              name="purposeTypeId"
              error={error.purposeTypeId}
              value={payload.purposeTypeId}
              isOptionEqualToValue={(option, value) => option.name === value}
              onSelect={(e) => onselectPurposeType(e)}
              options={purposeData}
            /> */}

            <Autocomplete
              style={{
                margin: "-15px",
                marginLeft: "1px",
                color: "#242424",
              }}
              noOptionsText={'No Options found'}
              fullWidth
              options={purposeData}
              getOptionLabel={(option) =>
                option.name ? option.name : ''
              }
              value={selectedPurpose}
              onChange={(event, newValue) => {
                setSelectedPurpose(newValue)
                selectedPurposeType(newValue);
                setError({ purposeTypeId: false });
              }}
              renderInput={(params) => (
                <CustomTextfield
                  {...params}
                  label="PurposeType"
                  required={false}
                  placeholder="PurposeType"
                  name="id"
                  error={error.purposeTypeId}
                />
              )}
            />

          </Grid>

          <Grid item xs={12} sm={9} sx={{ mt: 5, mb: 2 }}>
            <FormControl
              variant="outlined"
              size="small"
              style={{ minWidth: "450px", height: "100%" }}
            >
              <InputLabel
                style={{
                  fontSize: "17px",
                  opacity: "1",
                  fontFamily: "sans-serif",
                  marginTop: "3.5px",
                  margin: "1px",
                  padding: "2px",
                  marginLeft: "-1.5px",
                }}
              >
                Status
              </InputLabel>
              <Select
                style={{ height: "58px" }}
                label="Status"
                error={error.status}
                name="status"
                value={payload.status}
                onChange={handleChange}
              >
                <MenuItem value="">Select Status</MenuItem>
                {props.status ? (
                  props.status.map((d, index) => (
                    <MenuItem key={index} value={d}>
                      <Typography variant="inherit" noWrap>
                        {Applyi18nTOStatus(d)}
                      </Typography>
                    </MenuItem>
                  ))
                ) : (
                  <></>
                )}
              </Select>
            </FormControl>
            {/* <AutocompleteComponent
              label='Status'
              value={payload.status}
              error={error.status}
              name="status"
              options={props.status}
              onSelect={(e) => onSelectChange(e)}
              defaultSelect={t('SELECTPURPOSE')} 
            /> */}
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
