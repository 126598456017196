import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";

import { ReactComponent as DeleteVisitIcon } from "../../../../src/assets/DeleteVisit.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import DialogBox from "../../../components/DialogBoxComponent/DialogBox";
import { deleteSiteType } from "../../../slices/Site/SiteTypeSlice";
import Toast from "../../../components/ToastContainer/CustomToast";

export default function DeleteSiteType(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();


  useEffect(() => {}, []);

  const handleSubmit = () => {
    const siteTypeId = props.DeleteSiteTypeId;
  
    dispatch(deleteSiteType(siteTypeId))
      .unwrap()
      .then((data) => {
        handleClose(false);
        if (data.code === "LMSI0007") {
          Toast(t(data.message), "success");
          return;
        }
        else {
        Toast(t(data.data.error[0].message), 'error')
        }
      });
  };

  return (
    <>
      <DialogBox
        Header={t("COMMON004")}
        acceptText={t("CONFIRM")}
        cancelText={t("COMMON008")}
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
          style={{ padding: "20px" }}
        >
          <Grid>
            <DeleteVisitIcon />
          </Grid>
          <Grid>
            <Grid
              style={{
                wordWrap: "break-word",
                opacity: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                fontSize: "17px",
                marginTop: "15px",
              }}
            >
              <Typography
                style={{
                  color: "#242424",
                  marginRight: "4px",
                  fontSize: "17px",
                }}
              >
                {t("DELETEMSG")}
              </Typography>
              <Typography style={{ fontWeight: "bold", marginRight: "4px" }}>
                {props.DeleteSiteTypeName}
              </Typography>
              ?
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
