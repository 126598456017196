import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "../../../src/components/VisitorActionsComponents/profileCard.css";

import {
  Avatar,
  Box,
  DialogContent,
  Divider,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";
import CommonUtil from "../../Util/CommonUtils";
import { Tooltip } from "@material-ui/core";
import ViewIcon from "../../Pages/Invitations/ViewIcon";
import { getPersonAddress } from "../../slices/Person/PersonSlice";
import { Cancel } from "@material-ui/icons";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textarea: {
    resize: "both",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    background: "#3d4977",
    width: "100%",
    padding: "5px",
    textAlign: "center",
    borderRadius: "5px",
    color: "#FFFFFF",
  },
}));

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#36C96D",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function PersonViewPage(props) {
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [payloadPerson, setPayloadPerson] = React.useState({
    // id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    code: "",
  });

  const [payloadAddress, setPayloadAddress] = React.useState({
    addressLine1: "",
    addressLine2: "",
    country: "",
    state: "",
    city: "",
    areaCode: "",
  });


  useEffect(() => {
    if (open === true) {
      dispatch(getPersonAddress(props.rowData.id))
        .unwrap()
        .then((data) => {
          if (data?.code == "LMSI0000" || data?.code === "CVAI0022") {
            setPayloadPerson({
              "First Name": data?.data?.employeeDTOV4?.firstName,
              "Last Name": data?.data?.employeeDTOV4?.lastName,
              Email: data?.data?.employeeDTOV4?.email,
              Phone: data?.data?.employeeDTOV4?.phone,
              Gender: data?.data?.employeeDTOV4?.gender === "M" ? "Male" : data?.data?.employeeDTOV4?.gender === "F" ? "Female" : "",
              "Employee Code": data?.data?.employeeDTOV4?.code,


            });

            setPayloadAddress({
              "Address Line 1": data?.data?.address?.addressLine1,
              "Address Line 2": data?.data?.address?.addressLine2,
              Country: data?.data?.address?.country,
              State: data?.data?.address?.state,
              City: data?.data?.address?.city,
              "Area Code": data?.data?.address?.areaCode,
            });
          }
        });
    }
  }, [open]);



  return (
    <>
      <div onClick={handleClickOpen}>
        <ViewIcon />
      </div>

      <Dialog
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="custom-modal"
      >
        <div
          style={{ marginLeft: "5px", marginRight: "5px", marginTop: "15px" }}
        >
          <DialogTitle id="form-dialog-title">
            <Box display="flex" m={1.5}>
              <Box flexGrow={1}>
                <Typography variant="h4" sx={{ color: '#000' }} fontWeight="500">
                  {"Person Details"}
                </Typography>
              </Box>
              <IconButton
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 15,
                  top: 24,
                  color: (theme) => theme.palette.grey[500],
                }}
              > <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <Divider />
        </div>

        <DialogContent>
          <Grid container md={12} xs={12} style={{ fontSize: "large" }}>
            <Grid container md={5.5} xs={12} spacing={2}>
              {Object.entries(payloadPerson).map(([key, value]) => (
                <Grid
                  container
                  spacing={2}
                  sx={{ marginLeft: "10px", marginTop: "10px" }}
                >
                  <Grid item xs={4}>
                    <Typography variant="body1">{key}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      style={{ wordBreak: "break-word" }}
                      variant="inherit"
                    >
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid md={1}>
              <Divider
                orientation="vertical"
                sx={{ marginLeft: "20px", marginRight: "30px" }}
              />
            </Grid>
            <Grid container md={5.5} xs={12} spacing={2}>
              {Object.entries(payloadAddress).map(([key, value]) => (
                <Grid
                  container
                  spacing={2}
                  sx={{ marginLeft: "10px", marginTop: "5px" }}
                >
                  <Grid item xs={4}>
                    <Typography variant="body1">{key}</Typography>
                  </Grid>
                  <Grid item xs={1}>
                    :
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="inherit">{value}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PersonViewPage;
