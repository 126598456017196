import React from "react";
import { Tooltip, Grid } from "@mui/material";

const AccessLevelIconGlobal = () => {
    const [isShown, setIsShown] = React.useState(false);
    return (
        <>
            <Tooltip title="Access Level">
                <Grid
                    onMouseEnter={() => setIsShown(true)}
                    onMouseLeave={() => setIsShown(false)}
                >
                    {isShown ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.537" height="19.548" viewBox="0 0 19.537 19.548">
                            <g id="Group_7345" data-name="Group 7345" transform="translate(9983.371 -2536.61)">
                                <path id="Subtraction_3" data-name="Subtraction 3" d="M3.336,18.8H.4a.391.391,0,0,1-.391-.391c0-.346,0-.653-.007-.924-.018-1.407-.025-1.932.578-2.549L7.441,8.042a5.864,5.864,0,0,1,9.622-6.295A5.758,5.758,0,0,1,18.725,5a5.941,5.941,0,0,1-.5,3.387,5.677,5.677,0,0,0-.634-.489,5.083,5.083,0,1,0-8.268,1.6c.091.091.186.18.284.263a5.635,5.635,0,0,0-.352.714c-.055-.044-.125-.1-.192-.162L8.52,9.751a5.99,5.99,0,0,1-.689-.961c-1.589,1.59-2.8,2.792-3.773,3.758C.793,15.791.793,15.791.795,16.277v1.739H3.336a.2.2,0,0,0,.2-.2.979.979,0,0,1,.977-.977H4.9a.172.172,0,0,0,.132-.048c.077-.081.071-.252.062-.469a1.377,1.377,0,0,1,.287-1.1l.16-.162c.342-.35.548-.561.971-.561l.309,0,.2,0c.325,0,.426-.028.426-.2,0-.138,0-.258,0-.363-.017-.8-.017-.8,1.215-2l.258-.252a5.7,5.7,0,0,0-.074.914c0,.089,0,.179.006.267-.623.621-.623.629-.619.686,0,0,0,.01,0,.016V14.3a.979.979,0,0,1-.977.977H6.679c-.241,0-.256.009-.3.061-.1.1-.179.174-.244.234-.27.252-.27.252-.259.67,0,.105.006.237.006.406a.979.979,0,0,1-.977.977H4.509a.2.2,0,0,0-.2.2A.979.979,0,0,1,3.336,18.8ZM13.865,6.511A1.566,1.566,0,1,1,15.43,4.945,1.567,1.567,0,0,1,13.865,6.511Zm0-2.348a.783.783,0,1,0,.782.783A.784.784,0,0,0,13.865,4.162Z" transform="translate(-9983 2536.985)" fill="#3d4977" stroke="#3d4977" stroke-width="0.75" />
                                <path id="user" d="M6.377,2a4.377,4.377,0,1,0,4.377,4.377A4.382,4.382,0,0,0,6.377,2ZM9.04,8.9a2.97,2.97,0,0,0-5.323,0A3.63,3.63,0,0,1,2.7,6.377a3.677,3.677,0,0,1,7.354,0A3.631,3.631,0,0,1,9.04,8.9ZM6.377,3.751A1.576,1.576,0,1,0,7.953,5.327,1.576,1.576,0,0,0,6.377,3.751Z" transform="translate(-9974.957 2543.151)" fill="#3d4977" stroke="#3d4977" stroke-width="0.5" />
                            </g>
                        </svg>

                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.537" height="19.548" viewBox="0 0 19.537 19.548">
                            <g id="Group_7345" data-name="Group 7345" transform="translate(9983.371 -2536.61)">
                                <path id="Subtraction_3" data-name="Subtraction 3" d="M3.336,18.8H.4a.391.391,0,0,1-.391-.391c0-.346,0-.653-.007-.924-.018-1.407-.025-1.932.578-2.549L7.441,8.042a5.864,5.864,0,0,1,9.622-6.295A5.758,5.758,0,0,1,18.725,5a5.941,5.941,0,0,1-.5,3.387,5.677,5.677,0,0,0-.634-.489,5.083,5.083,0,1,0-8.268,1.6c.091.091.186.18.284.263a5.635,5.635,0,0,0-.352.714c-.055-.044-.125-.1-.192-.162L8.52,9.751a5.99,5.99,0,0,1-.689-.961c-1.589,1.59-2.8,2.792-3.773,3.758C.793,15.791.793,15.791.795,16.277v1.739H3.336a.2.2,0,0,0,.2-.2.979.979,0,0,1,.977-.977H4.9a.172.172,0,0,0,.132-.048c.077-.081.071-.252.062-.469a1.377,1.377,0,0,1,.287-1.1l.16-.162c.342-.35.548-.561.971-.561l.309,0,.2,0c.325,0,.426-.028.426-.2,0-.138,0-.258,0-.363-.017-.8-.017-.8,1.215-2l.258-.252a5.7,5.7,0,0,0-.074.914c0,.089,0,.179.006.267-.623.621-.623.629-.619.686,0,0,0,.01,0,.016V14.3a.979.979,0,0,1-.977.977H6.679c-.241,0-.256.009-.3.061-.1.1-.179.174-.244.234-.27.252-.27.252-.259.67,0,.105.006.237.006.406a.979.979,0,0,1-.977.977H4.509a.2.2,0,0,0-.2.2A.979.979,0,0,1,3.336,18.8ZM13.865,6.511A1.566,1.566,0,1,1,15.43,4.945,1.567,1.567,0,0,1,13.865,6.511Zm0-2.348a.783.783,0,1,0,.782.783A.784.784,0,0,0,13.865,4.162Z" transform="translate(-9983 2536.985)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.75" />
                                <path id="user" d="M6.377,2a4.377,4.377,0,1,0,4.377,4.377A4.382,4.382,0,0,0,6.377,2ZM9.04,8.9a2.97,2.97,0,0,0-5.323,0A3.63,3.63,0,0,1,2.7,6.377a3.677,3.677,0,0,1,7.354,0A3.631,3.631,0,0,1,9.04,8.9ZM6.377,3.751A1.576,1.576,0,1,0,7.953,5.327,1.576,1.576,0,0,0,6.377,3.751Z" transform="translate(-9974.957 2543.151)" fill="#c0c7cc" stroke="#c0c7cc" stroke-width="0.5" />
                            </g>
                        </svg>

                    )}
                </Grid>
            </Tooltip>
        </>
    );
};
export default AccessLevelIconGlobal;
