import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import back_button from '../../assets/images/onboard/back_button.svg';
import './custom.css';
import HeaderI18n from '../../layout-components/HeaderI18n';
import CustomCarousel from '../../Pages/OnBoardV2/CustomCarousel'
import { useHistory, withRouter } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '90%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  footer: {
    bottom: '10px',
    marginLeft: '-180px',
    textAlign: 'left',
    width: '100%',
    position: 'absolute',
  }
}));

function LoginLayoutV2({ bgColor, color, width, height, fontFamily, lang, login_img, success, backbutton, login_img_vis, ...props }) {
  const classes = useStyles();
  const history = useHistory();

  function handleClick(e) {
    e.preventDefault();
    history.push('/vis/login');
  }

  return (
    <Grid className='signin-container'>
      <Grid component='main' maxWidth='full' >
        <Grid container>
          <Grid item sm={6} xs={12} className='paper_left1'>
            {props.src ? (
              <img
                src={props.src}
                alt='logo' className='left_img' />
            ) : (
              <CustomCarousel />
            )}
          </Grid>

          <Grid item sm={6} xs={12} className='signIn-right'>
            <Grid container direction='row'>
              {backbutton ? (
                <Grid item onClick={handleClick}>
                  <Tooltip title="Back to Sign In" style={{ cursor: 'pointer' }} >
                    <img src={back_button} alt='logo' width={'36px'} />
                  </Tooltip>
                </Grid>
              ) : (
                <></>
              )}
              {lang ? (
                <Grid item style={{ position: 'absolute', right: 12 }}>
                  <HeaderI18n />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
            {props.children}
            <Grid className={classes.footer}>
              <Typography style={{ fontSize: '10px' }} color='textSecondary'>
                Copyright © 2022 ZKTECO CO., LTD. All rights reserved
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
LoginLayoutV2.propTypes = {
  bgColor: PropTypes.string,
  width: PropTypes.string,
};

LoginLayoutV2.defaultProps = {
  width: '150px',
};

export default LoginLayoutV2;
