import React, { useState } from "react";
import { Box, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@mui/material";
import SubmitButtons from "./SubmitButtons";
import CancelButtons from "./CancelButtons";
import { Divider } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import { ReactComponent as CloseSvg } from '../../../src/assets/Close.svg';
import { DialogActions } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    borderRadius: "20px",
  },
}));

export default function DeleteDialogBox(props) {
  const classes = useStyles();

  const { onSubmit, children, onClose, ...other } = props;

  const handleClose = () => {
    props.onClose(false);
  }
  return (
    <>
      <Dialog
        classes={{ paper: classes.dialogPaper }}
        {...props}
      >
        <DialogTitle variant="h4" onClick={handleClose}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {props.Header}
        </DialogTitle>
        <Divider variant="middle"/>
        {props.children}
        <DialogActions
          style={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
            marginBottom: "20px"
          }}
        >
          <SubmitButtons
            variant="contained"
            onClick={onSubmit}
          >
            <Typography variant='body1' style={{ textTransform: "none" }}>
              {props.acceptText}
            </Typography>
          </SubmitButtons>
          <CancelButtons
          variant="contained"
            color="secondary"
            onClick={() => props.cancel()}
          >
            <Typography variant="body1" style={{ textTransform: "none" }}>
              {props.cancelText}
            </Typography>
          </CancelButtons>
        </DialogActions>
      </Dialog>
    </>
  );
}
