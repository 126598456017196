import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
    let query = "?isPagable=true&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    if (!CommonUtil.isEmpty(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    return query;
};

const getRestrictType = (filter) => {
    return api.securedAxios().get('/web/getBlackListType' + buildQuery(filter));
}

const createRestrictType = (payload) => {
    return api.securedAxios().post('/web/createBlackListType', payload);
}

const updateRestrictType = (payload) => {
    return api.securedAxios().put('/web/updateBlackListType/' + payload.id, payload);
}

const deleteRestrictType = (payload) => {
    return api.securedAxios().delete("/web/deleteBlackListType?id=" + payload);
}

const getRestrictTypeById = (id) => {
    return api.securedAxios().get('/api/v2.0/restrict_type/' + id);
}


const RestrictTypeService = {
    getRestrictType,
    createRestrictType,
    updateRestrictType,
    deleteRestrictType,
    getRestrictTypeById
}

export default RestrictTypeService;