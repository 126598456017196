import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './components/Util/i18n';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme/index';
serviceWorker.unregister();

ReactDOM.render(
<ThemeProvider theme={theme}>
    <I18nextProvider i18n={i18n}>
      <App props={i18n} />
    </I18nextProvider>
   </ThemeProvider>,
  document.getElementById('root')
);
