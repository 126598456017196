import { Autocomplete, Grid, List, ListItem, ListItemAvatar, ListItemText, TextField, Typography } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import DialogBox from '../../components/DialogBoxComponent/DialogBox';
import Device from "../../assets/DeviceIcon/pic.png";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { updateDoor } from '../../slices/VisitorAccessLevels/VisitorAccessLevelSlice';
import {ReactComponent as DoorIcon} from "../../assets/DeviceIcon/DoorPic.svg";

export const SetCheckInCheckOutDoor = (props) => {
    const [open,setOpen]=useState(props.open);
    const [payload,setPaload]=useState({parameters:{ doorStatus:''}});
    const dispatch=useDispatch();


    const handleClose = () => {
        props.onClose(!open);
        setOpen(!open);
      };

      const handleChange = (event, newValue) => {
        setPaload({parameters:{doorStatus:newValue.value}});
      };

      const handleUpdate=()=>{
        
        if (payload.parameters.doorStatus === "") {
            toast.error("Please enter door property");
            return;
          }

          const state = {
            payload:payload,
            doorId: props.updateData.doorId,
          };
          
          dispatch(updateDoor(state))
            .unwrap()
            .then((data) => {
              if (data?.code === "DMSI0000") {
                toast.success("Updated successfully");
                props.reloadlist(true);
                handleClose();
              } else {
                toast.error("There is some issue. ");
              }
            });
        };
    
     const value={
        id: "1", value: props.updateData.deviceProperty,
        name:props.updateData.deviceProperty==='checkIn'?"Check-In":"Check-Out"  
     } 

    const doorProperty = [
        { id: "1", value: "checkIn", name: "Check-In" },
        { id: "2", value: "checkOut", name: "Check-Out" },
      ];
    const FieldDeviceProperty = (key) => (
        <Autocomplete
          fullWidth
          disablePortal
          isOptionEqualToValue={(option, value) => option.id === value.id}
          id="door property"
        //   value={value}
          getOptionLabel={(option) => option.name}
          options={doorProperty}
          sx={{ margin: "14px" }}
          renderInput={(params) => (
            <TextField {...params} label="Door Properties" />
          )}
          onChange={(event, newValue) => {
            handleChange(event, newValue);
          }}
        />
      );
    
      return (
        <div style={{  width:'500px !important'}}>
          <DialogBox
            Header="Set Check-In/Check-Out door"
            acceptText="Submit"
            cancelText="Cancel"
            fullWidth={true}
            onClose={handleClose}
            cancel={handleClose}
            onSubmit={handleUpdate}
            open={open}
          >
            <Grid container>
              <Grid item lg={11} style={{ marginLeft: "22px", marginTop: "10px" }}>
                <List
                  sx={{
                    bgcolor: "#EFF5F3",
                    paddingLeft: "30px",
                    borderRadius: "8px",
                  }}
                >
                  <ListItem>
                    <ListItemAvatar sx={{ marginRight: "1rem" }}>
                         <DoorIcon height="75px" width="33px"/>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        props.updateData.name
                          ? props.updateData.name
                          : "Door Name"
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item lg={11}>
                <Typography padding={"20px 30px"} fontSize="14px">
                  Please specify the properties of the device
                </Typography>{" "}
              </Grid>
              <Grid item lg={11}>
                <Typography
                  style={{
                    fontSize: "14px",
                    padding: "0px 30px",
                    color: "#36C96D",
                  }}
                >
                  Device properties determine whether the device acts as an entry or
                  exit Visitor can check in and out accordingly
                </Typography>
              </Grid>
              <Grid item lg={11} style={{ margin: "15px 10px" }}>
                {FieldDeviceProperty()}
              </Grid>
            </Grid>
          </DialogBox>
        </div>
      );
}

