import axios from 'axios';
import { REACT_LOGIN } from '../actions/EndPoints';
import { startLoader, stopLoader } from '../slices/Loader/LoaderSlice';
import store from './configureStore';
import jwt_decode from "jwt-decode";
import CommonUtil from '../components/Util/CommonUtils';

const axiosHelper = (headers) => {
  let req = axios.create({
    headers: headers,
  });
  req.interceptors.request.use(
    (request) => {
      store.dispatch((startLoader()));
      return request;
    },
  );
  req.interceptors.response.use(
    (response) => {
      store.dispatch(stopLoader());
      return response;
    },
    (error) => {
      store.dispatch(stopLoader());
      if (401 === error.response.status && window.location.pathname !== REACT_LOGIN) {
        /* window.location = REACT_LOGIN; */
        window.location = '/vis/error'
      } 
    }
  );
  return req;
};

const securedAxios = () => {
  return axiosHelper({
    'Content-type': 'application/json',
    Authorization:CommonUtil.getCookie('zlink_token'),
    companyId: localStorage.getItem('companyId'),
    'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

const switchCompanyAxios = () => {
  return axiosHelper({
    'Content-type': 'application/json',
    Authorization: localStorage.getItem(process.env.REACT_APP_TEMP_TOKEN),
  });
};

const unsecuredAxios = () => {
  return axiosHelper({
    'Content-type': 'application/json',
    'accept-language': localStorage.getItem("i18nextLng"),
     Application: 'cloudvisitor',
     companyId: localStorage.getItem("companyId"),
    'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

const unsecuredMultipartAxios = () => {
  return axiosHelper({
    'Content-type': 'multipart/form-data',
    'accept-language': localStorage.getItem("i18nextLng"),
    Application: 'cloudvisitor',
    'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
};

const securedAxiosForExport = (url) => {
  let req = axios.create({
    method: 'GET',
    url: url,
    headers: {
      'Content-type': 'application/json',
      Authorization: CommonUtil.getCookie('zlink_token'),
      companyId: localStorage.getItem('companyId'),
      'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    responseType: "blob"
  });
  req.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (401 === error.response.status && window.location.pathname !== REACT_LOGIN) {
        /* window.location = REACT_LOGIN; */
        window.location = '/vis/error'
      }
    }
  );
  return req;
};

const decoded = () => {
  try {
  var decode = jwt_decode(CommonUtil.getCookie('zlink_token'));
  return decode;
  } catch {
    window.location = '/vis/error'
  }
};

export const BasicConfigurationApiForZlinkRedirect = () => {
  return api.unsecuredAxios().get("/web/services_url");
};

const api = {
  axiosHelper,
  securedAxios,
  unsecuredAxios,
  switchCompanyAxios,
  unsecuredMultipartAxios,
  securedAxiosForExport,
  decoded,
};

export default api;
