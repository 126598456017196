import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import DialogBox from "./DialogBox";
import { Autocomplete, Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import Toast from "../ToastContainer/CustomToast";
import CustomTextfield from "../CustomInputs/CustomTextfield";
import { useTranslation } from "react-i18next";
import { getRestrictType } from "../../slices/RestrictType/RestirctTypeSlice";
import { blacklistVisitor } from "../../slices/BlockVisitorsGlobal/BlockVisitorSlice";
import CommonUtil from "../../Util/CommonUtils";

export default function BlockVisitorForm(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [blacklistData, setBlacklistData] = useState([]);

  var decoded_jwtToken = jwt_decode(
    localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
  );

  const [payload, setPayload] = React.useState({
    visitId: props.BlacklistVisitorId,
    restrictedBy: decoded_jwtToken.firstName + " " + decoded_jwtToken.lastName,
    restrictReasonType: "",
    restrictReason: "",
    removeAccess: false,
  });

  const [error, setError] = React.useState({
    restrictReasonType: false,
    restrictReason: false,
    removeAccess: false,
  });

  const [removeAccess, setRemoveAccess] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });
  };

  const handleChange1 = (event) => {
    setRemoveAccess(event.target.checked);
    const name = event.target.name;
    setPayload({
      ...payload,
       [name]: event.target.checked,
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmptyString(payload.restrictReasonType)) {
      Toast(t("Please enter restrict type"), "error");
      setError({ restrictReasonType: true });
      return false;
    }

    if (CommonUtil.isEmptyString(payload.restrictReason)) {
      Toast(t("Please enter restrict reason"), "error");
      setError({ restrictReason: true });
      return false;
    }

    const dto = {
      visitId: payload.visitId,
      restrictedBy: payload.restrictedBy,
      restrictReasonType: payload.restrictReasonType.id,
      restrictReason: payload.restrictReason,
      removeAccess: payload.removeAccess,
    }

    dispatch(blacklistVisitor(dto))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAE1046") {
          Toast(t("Visitor blocked successfully"), "success");
          handleClose();
        }
        else if(data.code === "CVAE1048") {
          Toast(data.message, "success");
          handleClose();
       } 
       else if(data.code === "CVAE1050") {
        Toast(data.message, "success");
        handleClose();
       } 
       else {
        Toast(data?.message, "error");
       }
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadRestrictType();
  };

  const loadRestrictType = () => {
    const query = {
      page: 1,
      rowsPerPage: 1000,
      name: "",
    };

    
    dispatch(getRestrictType(query))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI0000") {
          setBlacklistData(data?.data?.purposeTypes);
        } else {
          setBlacklistData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <DialogBox
        Header={
          process.env.REACT_APP_ENVIRONMENT === "USA"
            ? "Blacklist Visitor"
            : "Block Visitor"
        }
        acceptText="Block"
        cancelText="Cancel"
        fullWidth={true}
        open={open}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
      >
        <Grid
          container
          justifyContent="center"
          alignItems={"center"}
          style={{ marginTop: "35px" }}
        >
          <Grid item xs={8.8} sm={8.8} style={{ marginBottom: "10px" }}>
            <Autocomplete
              style={{ width: "100%" }}
              noOptionsText={"No Options found"}
              name="restrictReasonType"
              value={payload.restrictReasonType}
              options={CommonUtil.isEmptyArray(blacklistData) ? [] : blacklistData}
              getOptionLabel={(option) => (option.name ? option.name : "")}
              onChange={(event, newValue) => {
                if (!CommonUtil.isEmpty(newValue)) {
                  // eslint-disable-next-line
                  setPayload({ ...payload, restrictReasonType: newValue });
                } else {
                  setPayload({ ...payload, restrictReasonType: "" });
                }
                setError({ restrictReasonType: false });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="restrictReasonType"
                  value={payload.restrictReasonType}
                  error={error.restrictReasonType}
                  variant="outlined"
                  fullWidth
                  label={"Restrict Type *"}
                />
              )}
            />
          </Grid>

          <Grid item xs={8.8} sm={8.8}>
          <CustomTextfield
              type={"text"}
              label="Restrict Reason *"
              error={error.restrictReason}
              name="restrictReason"
              value={payload.restrictReason}
              handleChange={(e) => handleChange(e)}
              inputProps={{ maxLength: 120 }}
            />
          </Grid>

          <Grid item xs={10} sm={10}>
            <Box display="flex" justifyContent="flex-end">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: process.env.REACT_APP_BG_ICON }}
                    checked={removeAccess}
                    onChange={handleChange1}
                    name="removeAccess"
                  />
                }
                style={{
                  marginRight: "255px",
                  marginTop: "10px",
                  fontSize: "17px",
                }}
                label="Remove access immediately"
              />
            </Box>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
