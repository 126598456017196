import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import SiteTypeService from "../../services/Site/SiteTypeService";

const initialState = [];

export const getSiteTypeList = createAsyncThunk('siteType/list', async (payload) => {
    const res = await SiteTypeService.getSiteType(payload);
    return res.data;
});

export const addSiteType = createAsyncThunk('siteType/add', async (payload) => {
    const res = await SiteTypeService.createSiteType(payload);
    return res.data;
});

export const updateSiteType = createAsyncThunk('siteType/update', async (payload) => {
  const res = await SiteTypeService.editSiteType(payload);
  return res.data;
});

export const deleteSiteType = createAsyncThunk('siteType/delete', async (idsOrCodes) => {
  const res = await SiteTypeService.removeSiteType(idsOrCodes);
  return res.data;
});

const siteTypeSlice = createSlice({
    name: "siteType",
    ...initialState,
    extraReducers: {
        [getSiteTypeList.fulfilled]: (state, action) => {
          if (action.payload.code === "") {
            return action.payload.data;
          } else {
            return [];
          }
        },

        [addSiteType.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },

          [updateSiteType.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },

          [deleteSiteType.fulfilled]: (state, action) => {
            if (action.payload.code === "") {
              return action.payload.data;
            } else {
              return [];
            }
          },
    },
});

const { reducer } = siteTypeSlice;

export default reducer;