import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import { Grid } from '@material-ui/core'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { useTranslation } from 'react-i18next'
import AllDailyReports from '../VisitorReport/AllDailyReports'
import CheckIn from '../VisitorReport/CheckIn'
import CheckedOut from '../VisitorReport/CheckedOut'
import Cancelled from '../VisitorReport/Cancelled'
import Missed from '../VisitorReport/Missed'
import Rejected from '../VisitorReport/Rejected'

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return <div {...other}>{value === index && <Box >{children}</Box>}</div>;
}

const tabsStyle = {
    borderRight: 'solid #00000029', minWidth: 150,
    textTransform: "none"
}

export default function ReportTabs(props) {
    const { t } = useTranslation();
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }


    return (
        <>
            <Grid container >
                <Grid item style={{ marginTop: '20px' }} xs={12}
                // xl={12}  md={12} sm={12}  xs={12}
                >
                    <Tabs
                        TabIndicatorProps={{ style: { background: process.env.REACT_APP_BG_ICON, height: 3 } }}
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        <Tab label={t('STATUSTABS002')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS003')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS004')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS005')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS006')} style={tabsStyle} />
                        <Tab label={t('STATUSTABS001')} style={{ textTransform: "none" }} />
                    </Tabs>
                </Grid>
               
                <Grid xs={12}>
                    {
                        value === 0 && (
                            <TabPanel value={value} index={value} >
                                <CheckIn/>
                            </TabPanel>
                        )
                    }
                    
                    {
                        value === 1 && (
                            <TabPanel value={value} index={value}>
                                <CheckedOut/>
                            </TabPanel>
                        )
                    }
                    {
                        value === 2 && (
                            <TabPanel value={value} index={value}>
                                <Cancelled/>
                            </TabPanel>
                        )
                    }
                    {
                        value === 3 && (
                            <TabPanel value={value} index={value}>
                                <Missed/>
                            </TabPanel>
                        )
                    }
                    {
                        value === 4 && (
                            <TabPanel value={value} index={value}>
                                <Rejected/>
                            </TabPanel>
                        )
                    }
                    {
                        value === 5 && (
                            <TabPanel value={value} index={value}>
                                <AllDailyReports/>
                            </TabPanel>
                        )
                    }
                </Grid>
            </Grid>
        </>
    )
}

