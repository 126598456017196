import { Grid, Paper, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ReactComponent as ZLinkIcons } from "../../assets/zlinklogo.svg";
import QRCode from "react-qr-code";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { QRCodeForVisitors } from '../../slices/Invitations/InvitationsSlice';

function QRcode() {

    const dispatch = useDispatch();
    const history = useHistory();

    const [showQr, setShowQr] = useState(false);
    const [cardNumber, setCardNumber] = useState(0);
    const [message, setMessage] = useState('');

    var urlValue = window.location.href;
    var url = new URL(urlValue);
    var code = url.searchParams.get("code");

    useEffect(() => {
        if (code != null) {
            dispatch(QRCodeForVisitors(code))
                .unwrap()
                .then((data) => {
                    if (data.code === "CVSI0000") {
                        setCardNumber(data.data.value);
                        setShowQr(true);
                    } else {
                        setMessage(data.message);
                    }
                });
        } else {
            history.push("/vis/result-page");
        }
    }, [])

    return (
        <>
            <Grid
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                    backgroundColor: '#F4F6F8'
                }}
            >
                <Grid container alignItems='center'>
                    <Grid item className='logo-center'>
                        <ZLinkIcons />
                    </Grid>
                </Grid>
            </Grid>
            <Paper>
                <Paper elevation={3} style={{ minWidth: "60%", minHeight: "20vh", borderRadius: "2px", backgroundColor: "white", height: '50vh' }}>
                    {showQr && (
                        <Grid container style={{ minHeight: "20vh", padding: "16px", display: 'flex', justifyContent: "center", alignItems: "center", height: '50vh' }}>
                            <QRCode value={cardNumber} size={200} />
                        </Grid>
                    )}
                    {message !== '' && (
                        <Grid container style={{ minHeight: "20vh", padding: "16px", direction: "row", justify: "center", alignItems: "center" }}>
                            {message}
                        </Grid>
                    )}
                </Paper>
            </Paper>
        </>
    )
}

export default QRcode
