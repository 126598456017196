import api from "../../config/http-common";

const create = (payload) => {
    return api.unsecuredAxios().post('/onboard/user/authorize', payload);
};

const getCompanies = () => {
    return api.switchCompanyAxios().get(`/onboard/company?pageNumber=1&pageSize=1000&applicationId=` + localStorage.getItem('applicationId'));
}

const switchCompanies = () => {
    return api.securedAxios().get(`/onboard/company?pageNumber=1&pageSize=1000&applicationId=` + localStorage.getItem('applicationId'));
}

const switchCompany = (payload) => {
    return api
        .switchCompanyAxios()
        .put("/onboard/company", payload);
};

const selectCompany = (payload) => {
    return api
        .securedAxios()
        .put("/onboard/company", payload);
};


const revoke = () => {
    return api.unsecuredAxios().get(process.env.REACT_APP_AUTH_SERVICE_URI + '/api/v1.0/oauth/revoke');
};

const createUser = (payload) => {
    return api.unsecuredAxios().post('/onboard/user', payload);
};

const refreshToken = () => {
    const payload = {
        refreshToken: localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN),
        companyCode: localStorage.getItem('companyCode'),
    }
    return api.unsecuredAxios().post('/api/v2.0/refresh_token', payload);
};

const LoginService = {
    create,
    revoke,
    getCompanies,
    switchCompany,
    refreshToken,
    createUser,
    switchCompanies,
    selectCompany,
};

export default LoginService;