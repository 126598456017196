import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import LoginLayoutZlink from '../Pages/OnBoardV2/LoginLayoutZlink';
import { Grid } from '@mui/material';
import Cookies from 'js-cookie';
import CommonUtil from '../components/Util/CommonUtils';
import { BasicConfigurationApiForZlinkRedirect } from '../config/http-common';

export default function SessionOut() {
    const redirectKey = CommonUtil.getCookie('zlink_redirect_url')
    return (

        <Grid container>
            <Grid item sm={6} xs={12}>
                <LoginLayoutZlink />
            </Grid>
            <Grid item sm={6} className='signIn-right' xs={12}>
                <Grid component={'main'} container justifyContent='center' alignItems='center'>
                    <Grid sm={8} sx={{ position: 'relative', top: '-4%' }}>
                        <Typography variant='h4' component='div' gutterBottom style={{ color: '#474A4E' }}>
                            Session timeout,
                        </Typography>
                        <Button
                            style={{ width: '100%', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF' }}
                            onClick={() => {
                                BasicConfigurationApiForZlinkRedirect().then((data) => {
                                    if (data?.data?.code === "CVAE0000") {
                                      window.location.href = data?.data?.data?.zlinkRedirectUrl;
                                    }
                                  });
                            }}
                        >
                            Login with zlink
                        </Button>

                    </Grid>


                </Grid>
            </Grid>
        </Grid>

    )
}
