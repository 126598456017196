import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const getAddressTypes = (filter) => {
    return api.securedAxios().get('/api/v2.0/addressType' + buildQuery(filter));
}

const buildQuery = (filter) => {
    let query = "?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=false&operator=AND&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    if (!CommonUtil.isEmptyString(filter.name)) {
        query = query + "&name=" + filter.name;
    }
    if (!CommonUtil.isEmptyString(filter.code)) {
        query = query + "&code=" + filter.code;
    }
    return query;
};


const addressService = {
    getAddressTypes,
}

export default addressService;