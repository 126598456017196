import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DeviceEventReportListService from '../../services/DeviceEventReportList/DeviceEventReportListService';

const initialState = [];

export const getAllDeviceEvents = createAsyncThunk('getAllDeviceEvents/', async (payload) => {
    const res = await DeviceEventReportListService.getAllDeviceEvents(payload);
    return res.data;
});

const DeviceEventReportListSlice = createSlice({
    name: 'getAllDeviceEvents',
    initialState,
    extraReducers: {
        [getAllDeviceEvents.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = DeviceEventReportListSlice;
export default reducer;