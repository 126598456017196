import { Tooltip, Grid, IconButton, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as FilterIcon } from "../../../assets/FilterIcon.svg";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { createTheme } from "@material-ui/core";
import CustomTextfield from "../../../components/CustomInputs/CustomTextfield";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Toast from "../../../components/ToastContainer/CustomToast";
import { useTranslation } from "react-i18next";

const MuiTheme = createTheme({
    palette: {
        type: "light",
        primary: {
            main: "#36c96d",
        },
        secondary: {
            main: "#f50057",
        },
    },
});

const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
    border: "1px solid green",
    borderRadius: "50%",
    "&:hover": {
        backgroundColor: "green",
        color: "#FFFF",
    },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
    border: "1px solid green",
    borderRadius: "50%",
    "&:hover": {
        backgroundColor: "green",
        color: "#FFFF",
    },
}));

const useStyles = makeStyles((theme) => ({
    textarea: {
        resize: "both",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        background: "#3d4977",
        width: "100%",
        padding: "5px",
        textAlign: "center",
        borderRadius: "5px",
        color: "#FFFFFF",
    },
}));

export default function AddressTypeFilter(props) {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [filterQuery, setFilterQuery] = useState({
        name: '',
        code: ''
    });

    const defaultFilter = {
        name: '',
        code: ''
    }

    const handleChange = (event) => {
        const name = event.target.name;
        setFilterQuery({
            ...filterQuery,
            [name]: event.target.value,
        });
    };

    const handleClose = () => {
        setFilterQuery(defaultFilter);
        setOpen(false);
    }

    const handleSubmit = () => {
        props.onSubmit(filterQuery);
        handleClose();
    }

    const handleOpen = () => {
        setOpen(true);
    }

    const reset = () => {
        if (filterQuery.code.trim() === "" && filterQuery.name.trim() === "") {
            Toast(t("COMMON027"), "error")
            return;
        }
        setFilterQuery({
            code: "",
            name: ""
        })
    }

    return (
        <>
            <Tooltip title={<Typography fontSize={12} fontWeight={500}>{t('COMMON006')}</Typography>}>
                <FilterIcon onClick={handleOpen} style={{ cursor: 'pointer' }}>
                    <FilterIcon />
                </FilterIcon>
            </Tooltip>

            <DialogBox
                Header={t('COMMON006')}
                acceptText={t('COMMON024')}
                cancelText={t('COMMON025')}
                fullWidth={true}
                open={open}
                onClose={handleClose}
                onSubmit={handleSubmit}
                cancel={reset}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems={"center"}
                >
                    <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                        <CustomTextfield
                            label={t("COMMON014")}
                            name="name"
                            value={filterQuery.name}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid>

                    <Grid item xs={12} sm={9} sx={{ mt: 2 }}>
                        <CustomTextfield
                            label={t("COMMON045")}
                            name="code"
                            value={filterQuery.code}
                            handleChange={(e) => handleChange(e)}
                        />
                    </Grid>

                </Grid>
            </DialogBox>
        </>
    );
}
