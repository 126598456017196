import React from "react";
import { Tooltip, Grid } from "@mui/material";

const DeactivateIcon = () => {
  const [isShown, setIsShown] = React.useState(false);
  return (
    <>
      <Tooltip title="Deactivate Account">
        <Grid
          onMouseEnter={() => setIsShown(true)}
          onMouseLeave={() => setIsShown(false)}
        >
          {isShown ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="17.465" height="18.85" viewBox="0 0 17.465 18.85">
            <g transform="translate(-49.575 0.25)">
              <path id="Deactivate_Account-Hoverstate" data-name="Deactivate Account-Hoverstate" d="M8601,3232.366a3.982,3.982,0,1,1,3.982,3.984A3.986,3.986,0,0,1,8601,3232.366Zm1.041,0a2.941,2.941,0,1,0,2.941-2.938A2.943,2.943,0,0,0,8602.042,3232.366Zm-1.6,3.548H8592v-1.761a7.47,7.47,0,0,1,11.268-6.434l.354.208-.4.11a4.916,4.916,0,0,0-3.58,4.721,4.861,4.861,0,0,0,.925,2.867l.289.29Zm-7.4-1.761v.719h5.947a5.887,5.887,0,0,1-.384-2.115,6,6,0,0,1,2.478-4.829,6.506,6.506,0,0,0-1.611-.2A6.436,6.436,0,0,0,8593.042,3234.153Zm10.595-1.2a.5.5,0,0,1,0-1h2.846a.5.5,0,0,1,0,1Zm-8.469-10.971a3.983,3.983,0,1,1,3.981,3.984A3.987,3.987,0,0,1,8595.168,3221.983Zm1.041,0a2.94,2.94,0,1,0,2.94-2.94A2.945,2.945,0,0,0,8596.209,3221.983Z" transform="translate(-8542.175 -3218.001)" fill="#3d4977"/>
              <path id="Deactivate_Account-Hoverstate_-_Outline" data-name="Deactivate Account-Hoverstate - Outline" d="M8604.983,3236.6a4.233,4.233,0,1,1,4.231-4.234A4.238,4.238,0,0,1,8604.983,3236.6Zm0-7.966a3.733,3.733,0,1,0,3.731,3.732A3.737,3.737,0,0,0,8604.983,3228.635Zm-3.521,7.53h-9.713v-2.011a7.72,7.72,0,0,1,11.645-6.649l.863.505-.964.268a4.667,4.667,0,0,0-3.4,4.48,4.619,4.619,0,0,0,.866,2.7Zm-9.213-.5h8.044a5.109,5.109,0,0,1-.9-2.906,5.169,5.169,0,0,1,3.592-4.911,7.22,7.22,0,0,0-10.738,6.306Zm12.733-.107a3.189,3.189,0,1,1,3.19-3.19A3.194,3.194,0,0,1,8604.983,3235.557Zm0-5.879a2.689,2.689,0,1,0,2.69,2.688A2.693,2.693,0,0,0,8604.983,3229.678Zm-5.631,5.445h-6.561v-.969a6.686,6.686,0,0,1,6.68-6.678,6.759,6.759,0,0,1,1.673.211l.518.132-.433.313a5.725,5.725,0,0,0-2.374,4.626,5.664,5.664,0,0,0,.367,2.026Zm-6.061-.5h5.346a6.219,6.219,0,0,1,1.873-6.56,6.261,6.261,0,0,0-1.039-.087,6.186,6.186,0,0,0-6.18,6.178Zm13.19-1.419h-2.846a.75.75,0,0,1,0-1.5h2.846a.75.75,0,0,1,0,1.5Zm-2.846-1a.25.25,0,0,0,0,.5h2.846a.25.25,0,0,0,0-.5Zm-4.487-5.987a4.233,4.233,0,1,1,4.234-4.234A4.238,4.238,0,0,1,8599.149,3226.217Zm0-7.966a3.733,3.733,0,1,0,3.734,3.732A3.736,3.736,0,0,0,8599.149,3218.251Zm0,6.923a3.191,3.191,0,1,1,3.19-3.191A3.194,3.194,0,0,1,8599.149,3225.173Zm0-5.881a2.691,2.691,0,1,0,2.69,2.69A2.694,2.694,0,0,0,8599.149,3219.292Z" transform="translate(-8542.175 -3218.001)" fill="#3d4977"/>
            </g>
          </svg>
          ) : (

            <svg xmlns="http://www.w3.org/2000/svg" width="17.465" height="18.85" viewBox="0 0 17.465 18.85">
              <g transform="translate(0.25 0.25)">
                <path id="Deactivate_Account" data-name="Deactivate Account" d="M8601,3232.366a3.982,3.982,0,1,1,3.982,3.984A3.986,3.986,0,0,1,8601,3232.366Zm1.041,0a2.941,2.941,0,1,0,2.941-2.938A2.943,2.943,0,0,0,8602.042,3232.366Zm-1.6,3.548H8592v-1.761a7.47,7.47,0,0,1,11.268-6.434l.354.208-.4.11a4.916,4.916,0,0,0-3.58,4.721,4.861,4.861,0,0,0,.925,2.867l.289.29Zm-7.4-1.761v.719h5.947a5.887,5.887,0,0,1-.384-2.115,6,6,0,0,1,2.478-4.829,6.506,6.506,0,0,0-1.611-.2A6.436,6.436,0,0,0,8593.042,3234.153Zm10.595-1.2a.5.5,0,0,1,0-1h2.846a.5.5,0,0,1,0,1Zm-8.469-10.971a3.983,3.983,0,1,1,3.981,3.984A3.987,3.987,0,0,1,8595.168,3221.983Zm1.041,0a2.94,2.94,0,1,0,2.94-2.94A2.945,2.945,0,0,0,8596.209,3221.983Z" transform="translate(-8592 -3218.001)" fill="#c0c7cc" />
                <path id="Deactivate_Account_-_Outline" data-name="Deactivate Account - Outline" d="M8604.983,3236.6a4.233,4.233,0,1,1,4.231-4.234A4.238,4.238,0,0,1,8604.983,3236.6Zm0-7.966a3.733,3.733,0,1,0,3.731,3.732A3.737,3.737,0,0,0,8604.983,3228.635Zm-3.521,7.53h-9.713v-2.011a7.72,7.72,0,0,1,11.645-6.649l.863.505-.964.268a4.667,4.667,0,0,0-3.4,4.48,4.619,4.619,0,0,0,.866,2.7Zm-9.213-.5h8.044a5.109,5.109,0,0,1-.9-2.906,5.169,5.169,0,0,1,3.592-4.911,7.22,7.22,0,0,0-10.738,6.306Zm12.733-.107a3.189,3.189,0,1,1,3.19-3.19A3.194,3.194,0,0,1,8604.983,3235.557Zm0-5.879a2.689,2.689,0,1,0,2.69,2.688A2.693,2.693,0,0,0,8604.983,3229.678Zm-5.631,5.445h-6.561v-.969a6.686,6.686,0,0,1,6.68-6.678,6.759,6.759,0,0,1,1.673.211l.518.132-.433.313a5.725,5.725,0,0,0-2.374,4.626,5.664,5.664,0,0,0,.367,2.026Zm-6.061-.5h5.346a6.219,6.219,0,0,1,1.873-6.56,6.261,6.261,0,0,0-1.039-.087,6.186,6.186,0,0,0-6.18,6.178Zm13.19-1.419h-2.846a.75.75,0,0,1,0-1.5h2.846a.75.75,0,0,1,0,1.5Zm-2.846-1a.25.25,0,0,0,0,.5h2.846a.25.25,0,0,0,0-.5Zm-4.487-5.987a4.233,4.233,0,1,1,4.234-4.234A4.238,4.238,0,0,1,8599.149,3226.217Zm0-7.966a3.733,3.733,0,1,0,3.734,3.732A3.736,3.736,0,0,0,8599.149,3218.251Zm0,6.923a3.191,3.191,0,1,1,3.19-3.191A3.194,3.194,0,0,1,8599.149,3225.173Zm0-5.881a2.691,2.691,0,1,0,2.69,2.69A2.694,2.694,0,0,0,8599.149,3219.292Z" transform="translate(-8592 -3218.001)" fill="#c0c7cc" />
              </g>
            </svg>
          )}
        </Grid>
      </Tooltip>
    </>
  );
};
export default DeactivateIcon;
