export const JAVA_URL_SEARCH_DEVICES = "/api/v2.0/device/search";
export const JAVA_URL_DEVICES = "/api/v2.0/device";

export const JAVA_URL_VISITOR_REGISTRATION = "/api/v1.0/visitor/data";

export const JAVA_URL_VISITOR_TYPE = "/api/v2.0/visitor_type";

// export const JAVA_URL_VISITOR_LOG = "/api/v2.0/visitor_log";

export const JAVA_URL_VISITOR_LOG = "/api/v2.0/visitor_list";

export const JAVA_URL_VISITOR_TYPE_FILTER = "/api/v2.0/visitor_type/search";

export const JAVA_URL_VISITOR_TYPE_DOCUMENT = "/api/v2.0/visitor_type/document_template";

export const JAVA_URL_VISITOR = "/api/v2.0/visitor";

// export const JAVA_URL_REGISTRATION = "/api/v2.0/registrations";
export const JAVA_URL_REGISTRATION = "/api/v2.0/visitor_list";

export const JAVA_URL_BLOCK_VISITOR = "/api/v2.0/block_visitor";

export const JAVA_URL_VISITOR_INVITATION = "/api/v2.0/invitation";

export const JAVA_URL_CHECKLIST = "/api/v2.0/check_list";

export const JAVA_URL_EMPLOYEE_LIST = "/api/v2.0/organization/employee";

export const JAVA_URL_DASHBOARD_STATICS = '/api/v2.0/visitor/dashboard_statics';

export const JAVA_URL_DASHBOARD_TODAYS_VISITORS = '/api/v2.0/visitor/dashboard/visitors_today';

export const JAVA_URL_EXPORT_ALL_VISITORS_LOG = '/api/v2.0/export/all_visitors';

export const JAVA_URL_EXPORT_CHECKOUT_VISITORS_LOG = '/api/v2.0/export/checkout_visitors';

export const JAVA_URL_EXPORT_CANCELMISSESCHECKIN_VISITORS_LOG = '/api/v2.0/export/visitors/';

export const JAVA_URL_RESTRICT_VISITOR = '/api/v2.0/restrict_visitor';

export const JAVA_URL_EXPORT_RESTRICT_VISITOR = '/api/v2.0/restrict_visitor/export';

//SELF REGISTARTIONS
export const JAVA_URL_CREATE_SELFREGISTRATION = '/visitor_selfRegistration/create';

export const JAVA_URL_APPROVE_SELFREGISTRATION = '/visitor_selfRegistration/approve';




