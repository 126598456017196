// import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import DashBoardService from '../../services/DashBoard/DashBoardService';

// const initialState = [];

// export const getTodaysVisitors = createAsyncThunk('dashboard', async (payload) => {
//     const res = await DashBoardService.getTodaysVisitors(payload);
//     return res.data;
// });

// export const getDashBoardStatics = createAsyncThunk('dashboard/statics', async () => {
//     const res = await DashBoardService.getDashBoardStatics();
//     return res.data;
// });

// const dashBoardSlice = createSlice({
//     name: 'dashboard',
//     ...initialState,
//     extraReducers: {
//         [getTodaysVisitors.fulfilled]: (state, action) => {
//             if (action.payload.code === '') {
//                 return action.payload.data;
//             } else {
//                 return [];
//             }
//         }

//     }
// })

// const { reducer } = dashBoardSlice;
// export default reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DashBoardService from '../../services/DashBoard/DashBoardService';

const initialState = [];

export const getTodaysVisitors = createAsyncThunk('dashboard', async (payload) => {
    const res = await DashBoardService.getTodaysVisitors(payload);
    return res.data;
});

export const getDashBoardStatics = createAsyncThunk('dashboard/statics', async (filter) => {
    const res = await DashBoardService.getDashBoardStatics(filter);
    return res.data;
});

const dashBoardSlice = createSlice({
    name: 'dashboard',
    ...initialState,
    extraReducers: {
        [getTodaysVisitors.fulfilled]: (state, action) => {
            if (action.payload.code === '') {
                return action.payload.data;
            } else {
                return [];
            }
        }

    }
})

const { reducer } = dashBoardSlice;
export default reducer;