import { Box } from '@material-ui/core';
import { Grid, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../App.css';
import { ReactComponent as ApprovedIcon } from '../../assets/DashBoardTableIcons/ApprovedIcon.svg';
import { ReactComponent as CancelledIcon } from '../../assets/DashBoardTableIcons/CancelledIcon.svg';
import { ReactComponent as RegistrationIcon } from '../../assets/DashBoardTableIcons/RegistrationIcon.svg';


const CustomStyleStatus = (props) => {
  const { t } = useTranslation();
  const [icon, setIcon] = useState(props.icon);

  const StatusTab = (props) => {
    return (
      <>
        <Box style={{
           width: '150px',
           display: 'flex',
           flexWrap: 'wrap',
        }}>
           <Grid style={{ flex: '0 0 150px' }}>
              <Typography style={{ padding: '4px' }}> {t(props.status)}</Typography>
          </Grid>
          <Grid>
            {props.Icon}
          </Grid>
        </Box>
      </>
    )
  }

  function SwitchCase(props) {
    switch (props.row.row) {
      case 'SCHEDULED':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#EDBD37', backgroundColor: '#EDBD3726', opacity: 1 }}>
              <StatusTab status={'Scheduled'} /></span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

          </Grid>
        );


      case 'APPROVED':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#36C96D', backgroundColor: '#36C96D26', opacity: 1 }}>
              <StatusTab status={'Approved'} /></span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'SELF_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'SELF_REGISTARTION' ? 'Self Registration' : ""}>
                    <ApprovedIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

          </Grid>
        );


      case 'CONFIRMED':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#A6C212', backgroundColor: '#A6C21226', opacity: 1 }}>
              <StatusTab status={'Confirmed'} />
            </span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'SELF_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'SELF_REGISTARTION' ? 'Self Registration' : ""}>
                    <ApprovedIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

          </Grid>
        );

      case 'CHECKED_OUT':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#03ACBF', backgroundColor: '#03ACBF26', opacity: 1 }}>
              <StatusTab status={'Check Out'} /></span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'SELF_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'SELF_REGISTARTION' ? 'Self Registration' : ""}>
                    <ApprovedIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }


          </Grid>
        );

      case 'CANCELLED':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#E3393C', backgroundColor: '#E3393C26', opacity: 1 }}>
              <StatusTab status={'Cancelled'} />
            </span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'SELF_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'SELF_REGISTARTION' ? 'Self Registration' : ""}>
                    <ApprovedIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

          </Grid>
        );

      case 'Rescheduled':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#EDBD37', backgroundColor: '#EDBD3726', opacity: 1 }}>
              <StatusTab status={'Rescheduled'} />
            </span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'SELF_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'SELF_REGISTARTION' ? 'Self Registration' : ""}>
                    <ApprovedIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

          </Grid>
        );

      case 'CHECKED_IN':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#287ADC', backgroundColor: '#287ADC26', opacity: 1 }}>
              <StatusTab status={'Check In'} />
            </span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'SELF_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'SELF_REGISTARTION' ? 'Self Registration' : ""}>
                    <ApprovedIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }


          </Grid>
        );

      case 'INITIATED_FOR_BLOCKED':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#D53033', backgroundColor: '#D5303326', opacity: 1 }}>
              <StatusTab status={process.env.REACT_APP_ENVIRONMENT === 'USA' ? 'Blocklist Initiated' : 'Block Initiated'} />
            </span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'SELF_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'SELF_REGISTARTION' ? 'Self Registration' : ""}>
                    <ApprovedIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

          </Grid>
        );

      case 'BLOCKED':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#D53033', backgroundColor: '#D5303326', opacity: 1 }}>
              <StatusTab status={process.env.REACT_APP_ENVIRONMENT === 'USA' ? 'Blacklist' : 'Blocked'} />
            </span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'SELF_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'SELF_REGISTARTION' ? 'Self Registration' : ""}>
                    <ApprovedIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

          </Grid>
        );

      case 'REJECTED':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#E3393C', backgroundColor: '#E3393C26', opacity: 1 }}>
              <StatusTab status={'Rejected'} />
            </span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'SELF_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'SELF_REGISTARTION' ? 'Self Registration' : ""}>
                    <ApprovedIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

          </Grid>
        );

      case 'MISSED':
        return (
          <Grid display={'flex'}>
            <span style={{ color: '#ED8637', backgroundColor: '#ED863726', opacity: 1 }}>
              <StatusTab status={'Missed'} />
            </span>

            {props.row.visitMode === 'INVITATION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'INVITATION' ? 'Invitation' : ""}>
                    <CancelledIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'SELF_REGISTARTION' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'SELF_REGISTARTION' ? 'Self Registration' : ""}>
                    <ApprovedIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

            {props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? (
              <span>
                {icon ?
                  <Tooltip title={props.row.visitMode === 'WALKIN_REGISTARTION_KIOSK' ? 'Walk-In Registration' : ""}>
                    <RegistrationIcon />
                  </Tooltip>
                  : null
                }
              </span>
            ) : null
            }

          </Grid>
        );

      default:
        return (
          <Box>
            <div style={{ color: '#141413', borderColor: '#141413', opacity: 1 }}>
              {props.row}
            </div>
          </Box>
        );
    }
  }

  return <div>{<SwitchCase row={props} />}</div>;
};

export default CustomStyleStatus;
