import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import { Hidden, IconButton, AppBar, Box, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import projectLogo from '../../assets/images/ZKBio-cloud-visitor-white-Logo.svg';
import HeaderLogo from '../../layout-components/HeaderLogo';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import HeaderProfileMenu from '../../components/HeaderProfileMenu/HeaderProfileMenu';
import SwitchCompany from '../../components/SwitchCompany';

const Header = (props) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
    console.log("Props", props)
  };
  const { headerShadow, headerFixed, sidebarToggleMobile, setSidebarToggleMobile } = props;

  

  return (
    <Fragment>
      <AppBar color='secondary'
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: process.env.REACT_APP_BG_HEADER_LOGO,
        }}
        className={clsx('app-header', {})} position={headerFixed ? 'fixed' : 'absolute'} elevation={headerShadow ? 11 : 3}>
        {!props.isCollapsedLayout && <HeaderLogo />}
        <span></span>
        <Box
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: process.env.REACT_APP_BG_HEADTOOLBAR,
          }}
        >
          <Hidden lgUp>
            <Link to={REACT_URL_DASHBOARD} >
              <img className='app-logo-img' alt='Cloud Access' src={projectLogo} />
            </Link>
          </Hidden>
          <Hidden mdDown>
            <Box className='d-flex align-items-center'></Box>
          </Hidden>
          <Box className='d-flex align-items-center'>
            <SwitchCompany />
            <HeaderProfileMenu />
            <Box className='toggle-sidebar-btn-mobile'>
                <IconButton color='inherit' onClick={toggleSidebarMobile} size='medium'>
                  {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
                </IconButton>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
