
import { styled, Typography } from "@material-ui/core";
import { Box, Checkbox, FormControlLabel, Grid, IconButton, InputAdornment, Tooltip, tooltipClasses } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import InfoIcon from '@material-ui/icons/Info';
import { useDispatch } from "react-redux";
import CommonUtil from "../../Util/CommonUtils";
import { addChecklistItem, editCheckListItem } from "../../slices/CheckListSlice/CheckListSlice";
import Toast from "../../components/ToastContainer/CustomToast";
import info from  "../../assets/images/onboard/info.svg";

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#36C96D0D',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 320,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #007CE8',
    marginRight: '250px'
  },
}));

export default function ChecklistItemAddUpdate(props) {
  const { open, handleClose } = props;
  const [selectedObject, setSelectedObject] = React.useState(props.selectedObject);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: name === 'isMandatory' ? event.target.checked : event.target.value,
    });
    setError({
      [name]: false,
    });
  };
  const [state, setState] = React.useState({
    question: '',
    options: '',
    expertedAnswer: '',
    isMandatory: true,
  });

  const [error, setError] = React.useState({
    question: false,
    options: false,
    expertedAnswer: false,
    isMandatory: false,
  });

  const handleSubmit = () => {
    if (state.question === '' || state.question === undefined) {
      setError({
        question: 'error',
      });
      Toast(t('CHECKLISTFORM001'), "error");
    }
    else if (state.options === '' || state.options === undefined) {
      setError({
        options: 'error',
      });
      Toast(t('CHECKLISTFORM002'), "error");
    }

    if (state.options!=='Yes,No') {
      Toast(t("Options must be Yes or No"), "error");
      setError({ options: true });
      return false;
    }

    if (CommonUtil.isEmptyString(state.expertedAnswer)) {
      Toast(t("Please enter expected answer"), "error");
      setError({ expertedAnswer: true });
      return false;
    }

    if (state.options.split(',')[0]!==state.expertedAnswer) {
      if (state.options.split(',')[1]!==state.expertedAnswer) {
        setError({ ...error, expertedAnswer: true });
        Toast(t('Expected Answer must be either Yes or No'), "error");
        return false;
      }
    }

    else if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      const newTemplate = {
        question: state.question,
        options: [state.options],
        expertedAnswer: state.expertedAnswer,
        mandatory: state.isMandatory ? 'Yes' : 'No',
        templateId: props.templateId,
      };
      dispatch(addChecklistItem(newTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI0055") {
            Toast((data.message), 'success')
            handleClose();
          } else {
            Toast((data.message), 'error')
            handleClose();
          }
        });

    }

    else {
      const updateTemplate = {
        id: CommonUtil.isEmpty(props.selectedObject) ? null : props.selectedObject.id,
        question: state.question,
        options: [state.options],
        expertedAnswer: state.expertedAnswer,
        mandatory: state.isMandatory ? 'Yes' : 'No',
        templateId: props.templateId,
      };
      dispatch(editCheckListItem(updateTemplate))
        .unwrap()
        .then((data) => {
          if (data.code === "CVAI0056") {
            Toast((data.message), 'success')
            handleClose();
          } else {
            Toast((data.message), 'error')
            handleClose();
          }
        });
    }
  }

  useEffect(() => {
    if (props.selectedObject) {
      setState({
        ...state,
        id: props.selectedObject.id,
        question: props.selectedObject.question,
        options: props.selectedObject.options?props.selectedObject.options[0]:'',
        expertedAnswer: props.selectedObject.expertedAnswer,
        isMandatory: props.selectedObject.mandatory === 'Yes' || props.selectedObject.mandatory === 'yes',
      });
    }

  }, []);

  return (
    <>

      <DialogBox
        Header={state.id ? "Update Checklist Items" : "Add Checklist Items"}
        acceptText="Submit"
        cancelText="Cancel"
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <CustomTextfield
              type={'text'}
              label="Question"
              error={error.question}
              name="question"
              defaultValue={selectedObject.question}
              handleChange={(e) => handleChange(e)}
              helperText={error.question}
              required={true}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTextfield
              label="Options"
              error={error.options}
              name="options"
              defaultValue={selectedObject.options}
              handleChange={(e) => handleChange(e)}
              helperText={error.options}
              validation="alpha-numeric"
              required={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <Typography color='inherit'>{t('CHECKLISTOPTIONSINFO001')}</Typography>
                          <b>{t('CHECKLISTOPTIONSINFO003') + ': '}</b> <em>{t('CHECKLISTOPTIONSINFO002')}</em>
                        </React.Fragment>
                      }
                    >
                      <IconButton>
                      <img src={info} alt='logo' className='left_img' />
                        {/* <InfoIcon style={{fontSize:"40px", color:"#00000029"}}/> */}
                      </IconButton>
                    </HtmlTooltip>
                  </InputAdornment>
                ),
              }}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextfield
              type={'text'}
              label="Expected Answer"
              error={error.expertedAnswer}
              name="expertedAnswer"
              defaultValue={selectedObject.expertedAnswer}
              handleChange={(e) => handleChange(e)}
              helperText={error.expertedAnswer}
              required={true}
            />
          </Grid>
          <Grid item xs={12} >
            <Box>
              <FormControlLabel
                control={<Checkbox checked={state.isMandatory} style={{ color: process.env.REACT_APP_BG_ICON}}
                  onChange={handleChange}
                  name='isMandatory' />}
                label={t('CHECKLISTFORM006')} />
            </Box>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
