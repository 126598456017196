import { Avatar } from "@material-ui/core";
import { Box, Link } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DataTable from "../../components/DataTable/DataTable";
import "../../App.css";
import Search from "../../components/SearchTab/Search";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useTranslation } from "react-i18next";
import HeaderToolbar from "../../components/HeaderToolbar";
import UserRoleFilter from "./UserRoleFilter";
import { deactivateAccount, listRole } from "../../slices/Role/RoleSlice";
import DeactivateIcon from "./DeactivateIcon";
import Toast from "../../components/ToastContainer/CustomToast";
import CommonUtil from "../../components/Util/CommonUtils";

export default function Role() {
  const { t } = useTranslation();
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);
  const [clear, setClear] = useState(false);
  const [searchedRows, setSerchedRows] = useState([]);
  const [paging, setPaging] = useState({
    page: 1,
    size: 10,
  });

  const [query, setQuery] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: ''
  });

  const defaultQuery = {
    firstName: '',
    lastName: '',
    email: '',
    mobile: ''
  }

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      size: size,
    })
  };

  useEffect(() => {
    loadData(query, paging);
  }, [query, paging, clear]);

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 })
    setQuery(defaultQuery);
  };

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setQuery({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile
    });
  }

  
  const handleSearch = (searchedVal) => {
    const filteredRows = rows.filter((test) => {
        return (
          (test.firstName
            ? test.firstName.toLowerCase().includes(searchedVal.toLowerCase())
            : "") ||
          (test.lastName
            ? test.lastName.toLowerCase().includes(searchedVal.toLowerCase())
            : "") ||
          (test.email ? test.email.includes(searchedVal.toLowerCase()) : "") ||
          (test.phone
            ? test.phone.toLowerCase().includes(searchedVal.toLowerCase())
            : "") ||
            (test.roleName ? test.roleName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
          `${test.firstName} ${test.lastName}`
            .toLowerCase()
            .includes(searchedVal.toLowerCase())
  
        );
      });
      setRows(filteredRows);
      
    };
  


  const loadData = (query, paging) => {

    dispatch(listRole(Object.assign(query, paging)))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI0000" && data.data) {
          setTotalRecords(data.data.totalCount);
          setRows(data.data.membership);
          setPage(data.data.currentPage);
          setTotalPages(data.data.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };
  var error = '';

  const handleDeActivateAccount = (rowData) => {

    if (rowData.roleName === 'Owner') {
      Toast("Admin can't be deleted!", "error");
      return;
    }
    
    dispatch(deactivateAccount(rowData.id))
      .unwrap()
      .then((data) => {

        if (data.code !== "UASE0019" && data.data) {
          Toast('Account deactivated successfully', 'success');
          loadData(query, paging);
        } else {
          Toast(data.message, 'error');
        }
      })
      .catch((er) => { });
  }

  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return (
          <div>
            <Avatar
              alt={params.row.firstName}
              src={`data:image/png;base64,${params.row.profile}`}
            ></Avatar>
          </div>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      width: 110,
      valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
    },
    {
      field: "phone",
      headerName: "Mobile",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.phone,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.email,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.roleName,
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeactivateIcon />}
          label="Deactivate Account"
          onClick={() => handleDeActivateAccount(params.row)}
        />,
      ],
    },
  ]);

  return (
    <>
      <HeaderToolbar title="Role" />
      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        {/* <Box item p={1}>
          <UserRoleFilter
            applyFilter={(data) => handleFilter(data)}
          />
        </Box> */}

        <Box item p={1}>
          <Search
            onSearch={(e) => handleSearch(e)}
            clearSearch={() => setSerchedRows([])}
          />
        </Box>
      </Box>

      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={searchedRows.length === 0 ? rows : searchedRows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={paging.size}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

    </>
  );
}
