import api from '../config/http-common';

const getAll = () => {
  return api.securedAxios().get('/user');
};

const get = (id) => {
  return api.securedAxios().get(`/user/${id}`);
};

const create = (data) => {
  return api.securedAxios().post('/user', data);
};

const autorize = (data) => {
  return api.unsecuredAxios().post('/user/authorize', data);
};

const update = (id, data) => {
  return api.securedAxios().put(`/user/${id}`, data);
};

const remove = (id) => {
  return api.securedAxios().delete(`/user/${id}`);
};

const removeAll = () => {
  return api.securedAxios().delete(`/user`);
};

const findUser = (filterObj) => {
  return api.securedAxios().get(`/user?pageNumber=1&pageSize=10&ids=${filterObj.ids}`);
};

const forgotPassword = (userName) => {
   return api.securedAxios().post('/onboard/user/' + userName + '/forgot_password');
};

const resetPassword = (payload) => {
  return api.securedAxios().put('/onboard/user/' + payload.code + '/reset_password', payload);
};

const updateProfile = (payload) => {
  return api.securedAxios().put(`/user/${payload.id}`, payload);
};

const revokeUserToken = () => {
  return api.securedAxios().get('http://10.10.10.142:9095/api/v1.0/oauth/revoke');
};
const UserService = {
  getAll,
  get,
  create,
  autorize,
  update,
  remove,
  removeAll,
  findUser,
  forgotPassword,
  resetPassword,
  updateProfile,
  revokeUserToken,
};

export default UserService;
