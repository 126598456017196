import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import DataTable from '../../components/DataTable/DataTable'
import HeaderToolbar from '../../components/HeaderToolbar'
import Search from '../../components/SearchTab/Search'
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import { GridActionsCellItem } from '@mui/x-data-grid-pro'
import EditWatchlistIcon from '../Watchlist/EditWatchlistIcon'
import DeleteDialog from '../../components/DialogBoxComponent/DeleteDialog'
import { useState } from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { deleteTimeSlot, timeSlotList } from '../../slices/TimeSlot/TimeSlotSlice'
import AddIcon from "../../assets/images/VisitingAccessLevels/add.svg";
import { useHistory } from 'react-router-dom'
import { REACT_ADD_TIMESLOTS, REACT_UPDATE_TIMESLOT } from '../../actions/EndPoints'
import Toast from '../../components/ToastContainer/CustomToast'
import CommonUtil from '../../Util/CommonUtils'
import { Link } from "react-router-dom";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import FilterTimeSlots from './FilterTimeSlots'
import { getPermissionsListByroleId } from '../../slices/RoleandPermission/RoleandPermissionSlice'
import api from '../../config/http-common'
import { useTranslation } from 'react-i18next'

export const TimeSlotList = () => {

  const { t } = useTranslation();
  const [totalRecords, setTotalRecords] = useState(1);
  const [clear, setClear] = React.useState(false);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [delPayLoad, setDelPayload] = useState({});
  const[pageSize,setPageSize]=useState(0);
  const [del,setDel]=useState(false);

  const [roles, setRoles] = useState([]);
  const [createTimeSlots, setCreateTimeSlots] = useState(true);
  const [listTimeSlots, setListTimeSlots] = useState(true);
  const [editTimeSlots, setEditTimeSlots] = useState(true);
  const [deleteTimeSlots, setDeleteTimeSlots] = useState(true);
  
  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10
  });

  const [filter, setFilter] = useState({
    name: "",
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const defaultFilter = {
    operationName: "",
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };
  
  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear])

  const handleOpen = () => {
    setOpenEditDialog(true);
  };

  const handleClose = () => {
    setOpenEditDialog(false);
  };
  
  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(filter, paging);
      return;
    }

    dispatch(timeSlotList(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "DMSI0000" && data?.data)) {
          const filteredRows = data?.data?.timeZone.filter((test) => {
            return (test.operationName ? test. operationName.toLowerCase().includes(searchedVal.toLowerCase()) : "") });
          setRows(filteredRows);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
    
  };
 
  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setCreateTimeSlots(l.includes("CREATE_UPDATE_DELETE_TIMES_LOTS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setEditTimeSlots(l.includes("CREATE_UPDATE_DELETE_TIMES_LOTS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setDeleteTimeSlots(l.includes("CREATE_UPDATE_DELETE_TIMES_LOTS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setListTimeSlots(l.includes("TIMES_LOTS_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("TIMES_LOTS_LIST")) {
          dispatch(timeSlotList(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
              if ((data?.code === "DMSI0000" && data?.data)) {
                setRows(data?.data?.timeZone);
                setPage(data?.data?.currentPage);
                setTotalPages(data?.data?.totalPages);
                setPageSize(data?.data?.pageSize);
              } else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("TIMES_LOTS_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);

  const loadData = (filter, paging) => {
    dispatch(timeSlotList(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "DMSI0000" && data?.data)) {
          setRows(data?.data?.timeZone);
          setPage(data?.data?.currentPage);
          setTotalPages(data?.data?.totalPages);
          setPageSize(data?.data?.pageSize);
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  useEffect(() => {
    if (del === true && parseInt(pageSize) !== 1) {
      loadData({ page: page,rowsPerPage: rowsPerPage}, filter);
      setDel(false);
    } else { 
      if (del === true) {
        setPaging({ page: page-1,rowsPerPage: rowsPerPage});
        setDel(false);
      }
    }
  }, [del]);

  const handleDelete=(data)=>{
    dispatch(deleteTimeSlot({id:data.id,name:data.operationName}))
    .unwrap()
    .then((data) => {
      if (data?.code === "DMSI0009") {
        Toast("Time slots deleted successfully.", "success");
        loadData(filter, paging);
      } else Toast("Time slot assigned to access level.", "error");
    })
    .catch((er) => { });
  }

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      operationName: data.operationName,
    });
  };

  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 110,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    { field: 'operationName', headerName: 'Name', flex: 1, width: 10, valueGetter: (params) => params.row.operationName},
      {
      field: "Actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      renderCell: (params) => [

        (<>
        {editTimeSlots &&
        <GridActionsCellItem
          icon={<EditWatchlistIcon />}
          onClick={() => { history.push({ pathname: REACT_UPDATE_TIMESLOT, state: { data: params.id } }) }} />}
        </>),
        
        (<>
        {deleteTimeSlots &&
        <GridActionsCellItem
          icon={<DeleteDialog text={'Are you sure want to delete the selected record'} name={''} handleDelete={() => handleDelete(params.row)} />}
          label="Delete"
        />}
        </>),

      ],
    },

  ]);

  const getActions = (action, data) => {
    if (action === 0) {
      history.push({ pathname: REACT_UPDATE_TIMESLOT, state: { ...data } });
    } else if (action === 1) {
      // setOpen(!open);
    }
  };
  return (
    <div>

  {createTimeSlots ?(
      <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Grid style={{ display: 'flex', cursor: 'pointer' }}>
          {/* <Box style={{ margin: '16px, 0' }}><Typography variant="h3">Time Slot</Typography></Box> */}
          <HeaderToolbar  title="Time Slots" />
        </Grid>
        <Box style={{
          textAlign: "center",
          padding: '10px',
          backgroundColor: process.env.REACT_APP_BG_ICON,
          borderRadius: "50%",
          cursor: 'pointer',
          width: '45px', height: '45px'
        }}
        >
          <Tooltip title='Time Slot'>
            {/* {createTimeSlots && */}
            <img
              alt="..."
              src={AddIcon}
              onClick={() => {
                history.push({
                  pathname: REACT_ADD_TIMESLOTS,
                  state: {},
                });
              }}
              style={{ width: '25px' }}
            />
            {/* } */}
          </Tooltip>
        </Box>
      </Box>
  ): (<HeaderToolbar title="Time Slots" />)}


  {listTimeSlots &&
    <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "1",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>
                  
          <Box item p={1} >
            <FilterTimeSlots
              rowsPerPage={rowsPerPage}
              applyFilter={(data) => handleFilter(data)}
            />
          </Box>

        <Box item>
        <Tooltip title={"Refresh"}>
              <IconButton onClick={() => loadData(filter, paging)}>
                <Refresh />
              </IconButton>
            </Tooltip>
        </Box>

        <Box item p={1}>
          <Search
          onSearch={(e) => globalsearch(e)}
          clearSearch={() => loadData(filter, paging)}
         />
        </Box>

      </Box>}

    {listTimeSlots ? (
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
        ):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
        <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
            {t('CVROLE015')}
        </Typography>
    </div>)
    }
      
      </div>
  )
}
