import api from '../../config/http-common';


const getAllZone = (payload) => {
    let url=`/api/v2.0/zlink/zones/filter?pageNumber=${payload.page}&pageSize=${payload.rowsPerPage}`;
     if(payload.name!==''){
        let name = encodeURIComponent(payload.name);
        url+='&name='+name;
     }
    return api.securedAxios().get(url);
};


const ZoneService = {
    getAllZone,   
    
};

export default ZoneService;