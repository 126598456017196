import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import HeaderToolbar from '../../components/HeaderToolbar'
import { useDispatch } from "react-redux";
import ReportTabs from './ReportTabs';
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";
import { Typography } from "@mui/material";

function ReportLogs() {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const [listVisitorLogs, setListVisitorLogs] = useState(true);
  const [viewVisitorLogs, setViewVisitorLogs] = useState(true);

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setViewVisitorLogs(l.includes("VIEW_VISITOR_LOG") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setListVisitorLogs(l.includes("VISITOR_LOG_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        
        if (l.includes("VISITOR_LOG_LIST")) {
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("VISITOR_LOG_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);

    return (
        <>
            <HeaderToolbar title= {process.env.REACT_APP_ENVIRONMENT === 'USA' ? t("DAILYREPORTLBL") : t("labelVisitorLogTitle")}/>
            {(listVisitorLogs || viewVisitorLogs)?(<ReportTabs />):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
                    <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                        {t('CVROLE013')}
                    </Typography>
                </div>)
                }
        </>
    )
}
export default ReportLogs