import React, { useEffect, useState } from 'react';
import Search from '../../components/SearchTab/Search';
import { Link } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import HeaderToolbar from '../../components/HeaderToolbar';
import { getAllUserRoles, getPermissionsListByroleId } from '../../slices/RoleandPermission/RoleandPermissionSlice';
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import api from '../../config/http-common';
import { REACT_URL_UPDATEROLEANDPERMISSION, REACT_URL_VIEWROLEANDPERMISSION } from '../../actions/EndPoints';
import DataTables from './DataTables';
import ViewRoleAndPermissionIcon from './ViewRoleAndPermissionIcon';
import EditRoleAndPermissionIcon from './EditRoleAndPermissionIcon';
import FilterRolesAndPermission from './FilterRolesAndPermission';
import CommonUtil from '../../Util/CommonUtils';

export default function RoleAndPermissionsList() {

    const [clear, setClear] = React.useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [totalRecords, setTotalRecords] = useState(1);
    const [rows, setRows] = useState([]);
    const [pageSize,setPageSize] = useState(0);
    const zlink = localStorage.getItem("redirectFrom");

    const [roles, setRoles] = useState([]);
    const [listPermission, setListPermission] = useState(true);
    const [viewPermission, setViewPermission] = useState(true);
    const [editPermission, setEditPermission] = useState(true);
    const [perm, setPerm] = useState([]);
    const [load, setLoad] = useState(true);
    const [reload,setReload] = React.useState(false);
    
      const [paging, setPaging] = useState({
        page: 1,
        rowsPerPage: 10,
      });
    
      const [filter, setFilter] = React.useState({
        name: "",
        code: "",
      });
      
      const defaultPaging = {
        page: 1,
        rowsPerPage: 10,
      };
    
      const defaultFilter = {
        name: "",
        code: "",
      };

      const handleChange = (newPage, size) => {
        setPage(newPage);
        setRowsPerPage(size);
        setPaging({
          page: newPage,
          rowsPerPage: size,
        });
      };
    
      useEffect(() => {
        loadData(filter, paging);
      }, [filter, paging, clear]);

      const resetFilter = () => {
        setClear(false);
        setPaging({ ...paging, page: 1 });
        setFilter(defaultFilter);
      };
     
      const loadData = (filter, paging) => {
          setRoles([]);
            dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
            .unwrap()
            .then((res) => {
            var l = []; 
            res.data.rolePermissions.map((item, index) => {
              l.push(item.code);
            });
            setPerm(l);
            setListPermission(l.includes("CV_VIEW_PERMISSION_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setViewPermission(l.includes("CV_VIEW_PERMISSIONS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            setEditPermission(l.includes("CV_EDIT_PERMISSIONS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN'));
            if(l.includes("CV_VIEW_PERMISSION_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
              dispatch(getAllUserRoles(Object.assign(filter, paging))) 
                .unwrap()
                .then((data) => {
                    if (data?.code === 'UASI0000') {
                        setRows(data?.data?.roles);
                        setTotalRecords(data?.data?.totalCount);
                        setLoad(false);
                        (data?.data?.roles).map((role)=>{ 
                        })  
                    } 
                    else {
                      setRows([]);
                    }
                });
            }
        });
      };

      useEffect(() => {
        if (roles.includes("CV_VIEW_PERMISSION_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
          if (load && !filter) {
            loadData(defaultFilter);
          } 
        }
    }, [load]);

      // useEffect(() => {
      //   if (load && !filter) {
      //     loadData(defaultFilter);
      //   }
      // }, [load]);

      useEffect(() => {
        if (filter && !load && reload) {
          loadData(filter);
          setReload(false)
        }
      }, [filter, reload]);

      const handleFilter = (data) => {
        setClear(true);
        setPaging({ ...paging, page: 1 });
        setFilter({
          name: data.name,
          code: data.code,
        });
      };

      const globalsearch = (searchedVal) => {

        if (!searchedVal) {
          loadData(filter, paging);
          return;
        }

        dispatch(getAllUserRoles(Object.assign(filter, paging))) 
                .unwrap()
                .then((data) => {
                    if (data?.code === 'UASI0000') {                        
                        const filteredRows = data?.data?.roles.filter((test) => {
                          return (test.name ? test.name.toLowerCase().includes(searchedVal.toLowerCase()) : "")
                            || test.code.toLowerCase().includes(searchedVal.toLowerCase())
                        });
                        setRows(filteredRows);
                    } 
                    else {
                      setRows([]);
                    }
                });
        
      };

      const handleTextOverflow = (text) => {
        if (text && text.length > 15) {
            return text.substring(0, 17) + '...';
        } else {
        return text;
       }
     }

    const columns = React.useMemo(() => [
        {
          headerName: "",
          sortable: false,
          width: 5,
          renderCell: (params) => {
            return <div></div>;
          },
        },
        {
          field: "name",
          headerName: "User Role Name",
          flex: 1,
          width: 110,
          valueGetter: (params) => params.row.name,
          // renderCell: (params) => (
          //   <Tooltip title={` ${ params.row.name}`}>
          //      <span>{handleTextOverflow(params.row.name)}</span>
          //      </Tooltip>
          //       ),
        },
    
        {
          field: "code",
          headerName: "User Role Code",
          flex: 1,
          minWidth: 150,
          valueGetter: (params) => params.row.code,
          // renderCell: (params) => (
          //   <Tooltip title={` ${ params.row.code}`}>
          //      <span>{handleTextOverflow(params.row.code)}</span>
          //      </Tooltip>
          //       ),
        },
    
        {
          field: "Actions",
          headerName: "Actions",
          type: "actions",
          flex: 1,
          minWidth: 190,
          width: 150,
          headerAlign: "center",
          getActions: (params) => [

            (<>
            {viewPermission &&
            <GridActionsCellItem
              icon={<ViewRoleAndPermissionIcon />}
              label="View"
              onClick={() => history.push(REACT_URL_VIEWROLEANDPERMISSION, { 
                 state: { data: params.row.id, ...params.row }} ) }
            />
            }
            </>),
            
            (<>
            {editPermission &&
            params.row.code !== 'ORGANIZATION OWNER' && params.row.code !== 'SUPERADMIN' ? (<>
            <GridActionsCellItem
              icon={<EditRoleAndPermissionIcon />}
              label="Edit"
              onClick={() =>
                history.push(REACT_URL_UPDATEROLEANDPERMISSION, {
                  state: { data: params.row.id, ...params.row } }) }
            />
              </>) : (<></>)
            }
           </>),

          ],
        },
      ]);
    

  return (
    <>
    <HeaderToolbar
        title="Role and Permission"
    />

  {listPermission &&
    <Box
      display={"flex"}
      width={"100%"}
      flexDirection={"row-reverse"}
      alignItems={"center"}
    >

      <Box item>
        {clear && (
          <Link
            href="#"
            underline="hover"
            className="line"
            style={{
              opacity: "0.8",
              color: "#E3393C",
              fontSize: "14px",
              underline: "hover",
              display: "inline",
            }}
            onClick={resetFilter}
          >
            {"Clear Filter"}
          </Link>
        )}
      </Box>

      <Box item>
        <FilterRolesAndPermission
          rowsPerPage={rowsPerPage}
          applyFilter={(data) => handleFilter(data)}
        />
      </Box>

      {zlink ? (
          <>
            <Box item>
              <Tooltip title={"Refresh"}>
                <IconButton onClick={() => loadData(filter, paging)}>
                  <Refresh />
                </IconButton>
              </Tooltip>
            </Box>
          </>) : (<></>)}

      <Box item p={1}>
        <Search
          onSearch={(e) => globalsearch(e)}
          clearSearch={() => loadData(filter, paging)}
        />
      </Box>

    </Box>}

  {listPermission ? ( 
    <div style={{ height: 300, width: "100%" }}>
        <DataTables
          page={page - 1}
          totalRecords={totalRecords}
          rowsPerPage={rowsPerPage}
          columns={columns}
          getRowId={(row) => row.id}
          rows={rows}
          hidePagination={'s'}
       // toolbar={filters}
       // checkboxSelection={true}
          onSelection={(e) => console.log()}
          onPageChange={(e) => console.info()}
          onPageSizeChange={(e) =>console.info()}
          noRecordText="No User Role found" />
    </div>
    ): (<div style={{marginTop: '185px', paddingLeft: '300px'}}>
          <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
             {t('CVROLE003')}
          </Typography>
        </div>)}
     
  </>
)
}
