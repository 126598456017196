import {
  Tooltip,
  Grid,
  Select,
  InputLabel,
  Stack,
  TextField,
  ThemeProvider,
  DialogContent,
  Divider,
  DialogTitle,
  Dialog,
  DialogActions,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "./DialogBoxComponent/DialogBox";
import { ReactComponent as FilterIcon } from "../../src/assets/FilterIcon.svg";
import { FormControl, MenuItem, Typography } from "@mui/material";
import { ReactComponent as DropDownIcon } from "../../src/assets/DropDown.svg";
import { ReactComponent as DropUpIcon } from "../../src/assets/DropUp.svg";
import CalenderIcon from "../assets/Calender";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DesktopDateTimePicker from "@mui/lab/DesktopDateTimePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Breadcrumbs } from "@mui/material";
import { Link } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getSitesList } from "../slices/Site/SiteSlice";
import { listVisitorType } from "../slices/VisitTypeSlice";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { createTheme } from "@material-ui/core";
import Toast from "./ToastContainer/CustomToast";
import { makeStyles } from "@material-ui/core";
import SubmitButtons from "./DialogBoxComponent/SubmitButtons";
import CancelButtons from "./DialogBoxComponent/CancelButtons";
import CloseIcon from "@mui/icons-material/Close";
import AutocompleteComponent from "../components/CustomInputs/AutocompleteComponent";
import CustomDropdown from "./CustomInputs/CustomDropdown";


const MuiTheme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: process.env.REACT_APP_BG_ICON,
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  cssLabel: {
    color: '#0000008a'
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: '#1976d2 !important',
    }
  },
  cssFocused: {
    color: '#1976d2 !important'
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4 !important'
  },

}));


const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  color: process.env.REACT_APP_BG_ICON,
  border: process.env.REACT_APP_BG_BORDER,
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: process.env.REACT_APP_BG_ICON,
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  color: process.env.REACT_APP_BG_ICON,
  border: process.env.REACT_APP_BG_BORDER,
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: "green",
    color: "#FFFF",
  },
}));

export default function RegistrationFilterForm(props) {
  const [open, setOpen] = React.useState(false);
  const [purposeIcon, setPurposeIcon] = React.useState(false);
  const [siteIcon, setSiteIcon] = React.useState(false);
  const [data, setData] = useState();
  const [siteData, setSiteData] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const ITEM_HEIGHT = 44;
  const ITEM_PADDING_TOP = 2;

  const MenuProps = {
    PaperProps: {
      style: {
        opacity: "1",
        color: "#242424",
        fontSize: "17px",
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        marginLeft: "9.5px",
        width: 440,
      },
    },
  };

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };
  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [payload, setPayload] = useState({

    visitorTypeCode: "",
    siteId: "",
    status: props.status,
    scheduledStartDate: startDate,
    scheduledEndDate: endDate,
    registrationMode: props.selectedObject,
  });

  const handleClose = () => {
    setPayload({
      visitorTypeCode: "",
      siteId: "",
      status: props.status,
      scheduledStartDate: startDate,
      scheduledEndDate: endDate,
      registrationMode: props.selectedObject,
    });
    setOpen(false);
  };

  const [error, setError] = useState({
    visitorTypeCode: false,
    siteId: false,
  });

  const reset = () => {
    if (
      payload.visitorTypeCode.trim() === "" &&
      payload.siteId.trim() === "" &&
      payload.status === props.status &&
      payload.scheduledStartDate === startDate &&
      payload.scheduledEndDate === endDate
    ) {

      Toast(t("COMMON027"), "error")
      return;
    }

    setPayload({
      visitorTypeCode: "",
      siteId: "",
      status: props.status,
      scheduledStartDate: startDate,
      scheduledEndDate: endDate,
      registrationMode: props.selectedObject,
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSumbit = () => {
    if (
      payload.visitorTypeCode.trim() === "" &&
      payload.siteId.trim() === "" &&
      // payload.status === null &&
      payload.scheduledStartDate === null &&
      payload.scheduledEndDate === null
    ) {
      Toast(t("COMMON015"), "error")
      return;
    }

    if (
      payload.scheduledStartDate === null ||
      payload.scheduledStartDate === undefined ||
      payload.scheduledStartDate === ""
    ) {
      setError({ scheduledStartDate: true });
      Toast(t("INVITESTARTDATEERROR"), "error")
      return;
    }

    if (
      payload.scheduledEndDate === null ||
      payload.scheduledEndDate === undefined ||
      payload.scheduledEndDate === ""
    ) {
      setError({ scheduledEndDate: true });
      Toast(t("INVITEENDDATEERROR"), "error")
      return;
    }

    if (
      new Date(payload.scheduledStartDate).toString() === "Invalid Date" ||
      new Date(payload.scheduledEndDate).toString() === "Invalid Date"
    ) {
      Toast(t("USERPROFSCREEN013"), "error")
      return;
    }

    if (
      new Date(payload.scheduledEndDate) < new Date(payload.scheduledStartDate)
    ) {
      Toast(t("INVITATIONFORM010"), "error")
      return;
    }


    props.applyFilter(payload);
    setPayload({
      visitorTypeCode: "",
      siteId: "",
      status: props.status,
      scheduledStartDate: startDate,
      scheduledEndDate: endDate,
      registrationMode: props.selectedObject,
    });
    handleClose();
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadVisitType();
    loadSites();
  };

  const loadVisitType = () => {
    const query = {
      code: "",
      name: "",
      pageNumber: 0,
      pageSize: 0,
      isPageable: false,
    };

    dispatch(listVisitorType(query))
      .unwrap()
      .then((data) => {
        if (data.code === "CVAI1000") {
          setData(data.data.visitTypes);
        } else {
          setData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const loadSites = () => {
    dispatch(getSitesList(data))
      .unwrap()
      .then((data) => {
        if (data.code === "CVSI0000") {
          setSiteData(data.data.site);
        } else {
          setSiteData([]);
        }
      });
  };

  const onSelectChange = (event) => {
    setPayload({
      ...payload,
      visitorTypeCode: event.id,
      siteId: event.id,
    });
  };

  return (
    <>
      <Tooltip title={<Typography >{t('COMMON006')}</Typography>}>
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <RegistrationFilterForm />
        </FilterIcon>
      </Tooltip>
      <Grid item xs={6}>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle variant='h4' onClick={handleClose}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 15,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            {t('COMMON006')}
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
            //   style={{ marginTop: "40px" }}
            >
              <Grid item xs={12} sm={9} style={{ marginTop: "10px" }}>
                <AutocompleteComponent
                  label="Purpose"
                  name="visitorTypeCode"
                  error={error.visitorTypeCode}
                  value={payload.visitorTypeCode}
                  onSelect={(newValue) => setPayload({
                    ...payload,
                    visitorTypeCode: newValue.id,
                  })}
                  options={data}
                />
              </Grid>

              <Grid item xs={12} sm={9} style={{ marginTop: "40px" }}>
                <AutocompleteComponent
                  label="Site"
                  name="siteId"
                  error={error.siteId}
                  value={payload.siteId}
                  options={siteData}
                  onSelect={(newValue) => setPayload({
                    ...payload,
                    siteId: newValue.id,
                  })}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={9}
                style={{ marginTop: "40px", height: "100%" }}
              >
                <ThemeProvider theme={MuiTheme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDateTimePicker
                        //    disablePast
                        components={{
                          OpenPickerIcon: CalenderIcon,
                          LeftArrowIcon: CustomIconLeft,
                          RightArrowIcon: CustomIconRight,
                        }}
                        dateRangeIcon={<CalenderIcon />}
                        label={t('INVITESTARTDATE') + "*"}
                        inputFormat="dd-MM-yyyy HH:mm"
                        value={payload.scheduledStartDate}
                        // onChange={handleChange}

                        onChange={(scheduledStartDate) =>
                          setPayload({
                            ...payload,
                            scheduledStartDate: scheduledStartDate,
                          })
                        }

                        InputProps={{
                          classes:
                          {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                            {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </ThemeProvider>
              </Grid>

              <Grid
                item
                xs={12}
                sm={9}
                style={{ marginTop: "40px", height: "100%" }}
              >
                <ThemeProvider theme={MuiTheme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDateTimePicker
                        //  disablePast
                        components={{
                          OpenPickerIcon: CalenderIcon,
                          LeftArrowIcon: CustomIconLeft,
                          RightArrowIcon: CustomIconRight,
                        }}
                        dateRangeIcon={<CalenderIcon />}
                        label={t('INVITEENDDATE') + "*"}
                        inputFormat="dd-MM-yyyy HH:mm"
                        type="datetime-local"
                        value={payload.scheduledEndDate}
                        //    onChange={handleChange}

                        onChange={(scheduledEndDate) => (
                          setPayload({
                            ...payload,
                            scheduledEndDate: scheduledEndDate,
                          }),
                          setError({ scheduledEndDate: false })
                        )}
                        minDateTime={payload.scheduledStartDate}

                        InputProps={{
                          classes:
                          {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                        }}
                        renderInput={(params) => (
                          <TextField InputLabelProps={{
                            classes: {
                              root: classes.cssLabel,
                              focused: classes.cssFocused,
                            },
                          }}
                            {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </ThemeProvider>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{
            justifyContent: "center",
          }}>
            <SubmitButtons variant='contained' onClick={handleSumbit}>
              <Typography variant="body1" style={{ textTransform: "none" }}>
                {t('COMMON024')}
              </Typography>
            </SubmitButtons>
            <CancelButtons variant='contained' color="secondary" onClick={reset}>
              <Typography variant="body1" style={{ textTransform: "none" }}>
                {t('COMMON025')}
              </Typography>
            </CancelButtons>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
