import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as DeleteIcon } from "../../../src/assets/DeleteIcon.svg";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Toast from "../../components/ToastContainer/CustomToast";
import { deleteEmployee } from "../../slices/Employee/EmployeeSlice";

export default function DeletePerson(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [payload, setPayload] = React.useState({
    employeeIdOrEmployeeCode: props.DeleteId,
    userId: props.DeleteUserId,
    firstName: props.DeleteFirstName,
  });

  const handleSubmit = (data) => {
  
          dispatch(deleteEmployee(payload.employeeIdOrEmployeeCode))
            .unwrap()
            .then((data) => {
              if (data?.code === "CVAI0023") {
                Toast("Employee Deleted Successfully", 'success');
                handleClose(false);
                props.delete();
              }
              else {
                Toast((data.message), 'error')
                handleClose(false);
              }
            })
        }


  return (
    <>
      <DialogBox
        Header="Delete"
        acceptText="Confirm"
        cancelText="Cancel"
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSubmit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
          style={{ padding: "20px" }}
        >
          <Grid>
            <DeleteIcon width='60px' height={'50px'} />
          </Grid>
          <Grid>
            <Grid
              style={{
                wordWrap: "break-word",
                opacity: 1,
                textAlign: "center",
                display: "flex",
                flexDirection: "row",
                fontSize: "17px",
                marginTop: "15px",
              }}
            >
              <Typography style={{ color: "#242424", marginRight: "4px" }}>
                Are you sure you want to delete this person for {" "}
              </Typography>
              <Typography style={{ fontWeight: "bold", marginRight: "4px" }}>
                {payload.firstName}
              </Typography>
              <Typography style={{ color: "#242424", marginRight: "4px" }}>?</Typography>
            </Grid>
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
