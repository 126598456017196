import api from '../config/http-common';

const get = (payload) => {
    return api.securedAxios().get('/api/v2.0/location/sites');
};

const getSiteById = (payload) => {
    return api.securedAxios().get('/api/v2.0/location/site/' + payload);
};

const SiteService = {
    get,
    getSiteById,
};

export default SiteService;
