import { Switch, styled } from "@mui/material"
import { useLocation } from "react-router-dom";

const CustomSwitch = (props) => {
    const MaterialUISwitch = styled(Switch)(({ theme }) => ({
        width: 54,
        height: 38,
        padding: 7,
        "& .MuiSwitch-switchBase": {
            margin: 11,
            padding: 0,
            transform: "translateX(5px)",
            backgroundColor: "#fff",
            "&.Mui-checked": {
                color: "#fff",
                transform: "translateX(15px)",
                "& .MuiSwitch-thumb:before": {
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                        "#226BAB"
                    )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`
                },
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor: "#36C96D"
                }
            }
        },
        "& .MuiSwitch-thumb": {
            backgroundColor: "#f1f1f1",
            width: 15,
            height: 15,
            "&:before": {
                content: "''",
                position: "absolute",
                width: "100%",
                height: "100%",
                left: 0,
                top: 0,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }
        },

        "& .MuiSwitch-track": {
            opacity: 1,
            backgroundColor: "#fff",
            borderRadius: 30 / 2,
            border: "1px solid #cdcdcd"
        }
    }));


    return (
        <>
            <MaterialUISwitch  checked={props.checked} onChange={(e) => props.handleChange(e)} />

        </>
    )

}

export default CustomSwitch