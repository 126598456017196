import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, IconButton } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Close from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useHistory, useLocation } from 'react-router';

import PerfectScrollbar from 'react-perfect-scrollbar';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import navItemZlink from './navItemZlink';
import { Box, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import api from '../../config/http-common';
import { useEffect } from 'react';
import { useState } from "react";
import { getPermissionsListByroleId } from '../../slices/RoleandPermission/RoleandPermissionSlice';

const SidebarIndex = (props) => {

  let history = useHistory();
  let location = useLocation();
  const isZlink = window.location.toString();
  const zlink = isZlink.includes('/z/');
  const [contextMenu, setContextMenu] = React.useState(false);
  const [Selected, setSelected] = React.useState(0);

  const [selectedMenu, setSelectedMenu] = React.useState('');
  const [selectedSubMenu, setSelectedSubMenu] = React.useState('');

  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
           l.push(item.code);
      });
        setRoles(l);
         });
    }, []);

    const checkPermission = (code) => {
      if (api.decoded() != undefined && (api.decoded()?.roleCode === 'OWNER'||api.decoded()?.roleCode === 'SUPERADMIN'))
        return true;
      return roles.includes(code);
    }

    const navMaps = {
      'Person': 'CV_VIEW_PERSONLISTS',
      'Role and Permission': 'CV_VIEW_PERMISSION_LIST',
      'Site': 'CV_VIEW_SITELIST',
      'Zone': 'CV_VIEW_ZONE_LIST',
      'Device List': 'DEVICE_LISTS',
      'Visiting Flowchart': 'VISITING_FLOWCHART',
      'Invitations': 'INVITATION_LIST',
      'Registrations': 'REGISTRATION_LIST',
      'Self Registrations': 'CREATE_SELF_REGISTRATION',
      'Approvals': 'INVITATION_APPROVALS',
      'Blocked Visitors': 'BLOCKED_VISITORS_LIST',
      'Visitor Log': 'VISITOR_LOG_LIST',
      'Events': 'EVENTS_LIST',
      'Time slots': 'TIMES_LOTS_LIST',
      'Visiting Purpose': 'VISITING_PURPOSE_LIST',
      'Visiting Access Level': 'VISITING_ACCESS_LEVEL_LIST',
      'Restrict Type': 'RESTRICT_TYPE_LIST',
      'Checklist': 'CHECKLIST_LIST',
      'Watchlist': 'WATCHLIST_LIST',
      'Urgent Messages': 'VIEW_URGENT_MESSAGES',
      'Dashboard': 'CV_DASHBOARD',
      'Organization': 'CV_ORGANIZATION',
      'Site Management': 'CV_SITE_MANAGEMENT',
      'Device Management': 'CV_DEVICE_MANAGEMENT',
      'Visitor': 'CV_VISITOR',
      'Reports': 'CV_REPORTS',
      'Settings': 'CV_SETTINGS',
    }

    const checkDashboard = (item) => {
      if (item == 'CV_DASHBOARD') {
        if (checkPermission("VIEW_CV_DASHBOARDS")) {
          return true;
        }
      }
      return false;
    }

    const checkSubModule = (items) => {
      var ret = false;
      if (items instanceof Array) {
        items.map((cItem, cIndex) => {
          if (checkPermission(navMaps[cItem.name])) {
            ret = true;
            return;
          }
        });
      }
      return ret;
    }


  const handleMenu = (menu) => {
    if (!contextMenu || menu.name == 'Dashboard') {
      if (selectedMenu === menu.name) {
        setSelectedMenu('');
      } else {
        setSelectedMenu(menu.name);
        if (menu.link) history.push(menu.link);
      }
    }
    if (contextMenu) toggleSidebar(!contextMenu);
  };

  const handleClose = () => {
    setSelectedMenu('');
  };

  const handleSubMenu = (menu) => {
    if (selectedSubMenu === menu.name) {
      setSelectedSubMenu('');
    } else {
      setSelectedSubMenu(menu.name);
      if (menu.link) history.push(menu.link);
    }
  };

  const toggleSidebar = (toggle) => {
    props.onchange(toggle);
    setContextMenu(toggle);
    localStorage.setItem('toggleSidebar', toggle);
  };

  const CustomSidebarGrid = styled(Grid)((e) => ({
    display: 'flex',
    width: '100%',
    fontSize: '18px',
    color: '#304669',
    alignItems: 'center',
    height: '50px',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontWeight: Selected == e.data ? '500' : '400',
  }));

  const handleSelection = (parms) => {
    setSelected(parms.title);
    alert(parms.title);
    // history.push(parms.path)
  };

  return (
    <Grid
      style={{
        display: 'flex',
        padding: '50px 0px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        position: 'fixed',
        height: '110vh',
      }}
    >
      <Grid style={{ display: 'flex', flexDirection: 'column', width: contextMenu ? '70px' : '270px' }}>
        <PerfectScrollbar>
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }} component='nav' aria-labelledby='nested-list-subheader'>
            {navItemZlink.map((item, index) => (
              <>
              {(checkPermission(navMaps[item.name]) || checkDashboard(navMaps[item.name]) || checkSubModule(item.items)) ? <>
                {' '}
                <ListItemButton onClick={() => handleMenu(item)} style={{ padding: '10px 10px' }}>
                  <ListItemIcon style={{ paddingLeft: item.id === '1' ? '10px' : '14px' }}>
                    <item.Icon />
                  </ListItemIcon>
                  {contextMenu === false && <ListItemText primary={item.name} />}
                  {item.items instanceof Array && item.items.length > 0 && (selectedMenu === item.name ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                <Collapse in={selectedMenu === item.name} timeout='auto' unmountOnExit>
                  {item.items instanceof Array &&
                    item.items.map((cItem, cIndex) => (
                      <>
                      {checkPermission(navMaps[cItem.name]) &&
                      (<List component='div' disablePadding>
                        <Box sx={{ p:'2px'}} onClick={() => handleSubMenu(cItem)}>
                          {/* <ListItemIcon>{cItem.icon}</ListItemIcon> */}
                          {contextMenu === false && (
                            <ListItemText 
                            primary={
                              <ul>
                                <li style={{ cursor: 'pointer', paddingLeft: '25px' }}>{cItem.name}</li>
                              </ul>
                            }
                              primaryTypographyProps={{
                                fontWeight: selectedSubMenu === cItem.name ? 'bold' : 'normal',
                                color: '#304669',
                              }}
                            />
                          )}
                        </Box>
                      </List>)}
                      </>
                    ))}
                </Collapse>
              </>
              : <Grid></Grid>}
              </>
            ))}
          </List>
        </PerfectScrollbar>
        <Box sx={{ display: 'flex', mb: '2.5em' }}>
          <IconButton
            style={{ alignSelf: 'center', backgroundColor: '#fff', position: 'relative', borderRadius: 'inherit' }}
            onClick={() => toggleSidebar(!contextMenu)}
            disableRipple
          >
            {contextMenu ? (
              <Typography style={{ marginLeft: '-8px', fontSize: '10px', fontWeight: 'bold', position: 'relative', top: '9vh' }}>
                {' '}
                V 1.0.0_Beta
                <br />
                <MenuOpenIcon style={{ opacity: '0.8', fill: '#304669', position: 'fixed', left: '35px' }} />
              </Typography>
            ) : (
              <Typography style={{ marginLeft: '4px', position: 'relative', top: '11vh' }}>
                APP version 1.0.0_Beta
                <Close style={{ opacity: '0.8', fill: '#304669', position: 'fixed', marginLeft: '90px' }} onClick={() => handleClose()} />
              </Typography>
            )}
          </IconButton>
        </Box>
      </Grid>
      {/* <Grid style={{ display: contextMenu ? 'none' : 'block', minWidth: '170px', height: '100px' }}>
        {SlidebarICone.map((item, index) => (
          <CustomSidebarGrid key={index} data={item.title} onClick={() => handleSelection(item)}>
            {item.title}
          </CustomSidebarGrid>
        ))}
      </Grid> */}
    </Grid>
  );
}

export default SidebarIndex
