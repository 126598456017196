import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import './custom.css';
import { useHistory, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import LoginLayoutV2 from './LoginLayoutV2';
import { useTranslation } from 'react-i18next';
import select_company from '../../assets/USA_Images/login_images/selectCompanyright.svg';
import visSelectComapny from '../../assets/images/onboard/select_company.svg';
import company from '../../assets/USA_Images/login_images/selectCompany.svg';
import vis_company from '../../assets/images/onboard/company.svg';
import { REACT_URL_DASHBOARD } from '../../actions/EndPoints';
import CustomAutocomplete from '../../components/CustomInputs/CustomAutocomplete';
import CommonUtil from '../../Util/CommonUtils';
import { getCompanies, switchCompany } from '../../slices/onboard/LoginSlice';
import jwt_decode from 'jwt-decode';

const useStyles = makeStyles((theme) => ({
    form: {
        width: '70%',
        marginTop: theme.spacing(3),
    },
    selectcompany: {
        '& .MuiInputBase-input .MuiOutlinedInput-input .MuiInputBase-inputSizeSmall .MuiInputBase-inputAdornedEnd .MuiAutocomplete-input .MuiAutocomplete-inputFocused .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
            paddingLeft: '30px !important'
        },
    },
    submit: {
        backgroundColor: '#36c96d',
        borderRadius: '0.2rem',
        padding: '10px',
        '&:hover': {
            background: '#119743',
        },
    },
}));

function SelectCompany(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setCompany] = useState('');

    useEffect(() => {
        loadCompanies();
    }, []);

    const loadCompanies = () => {
        dispatch(getCompanies())
            .unwrap()
            .then((data) => {
                setCompanies(CommonUtil.isEmpty(data.data) ? [] : data.data.company);
            })
            .catch((er) => { })
    };

    const VerifyCompany = () => {
        if (CommonUtil.isEmptyString(selectedCompany)) {
            toast.error(t('VERIFYCOMP0001'));
            return;
        }
        const payload = {
            companyCode: selectedCompany,
            companyId: '',
        };
        dispatch(switchCompany(payload))
            .unwrap()
            .then((data) => {
                if (data.code === 'UASI0011') {
                    localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
                    localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, 'Bearer ' + data.data.access_token);
                    localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, data.data.refresh_token);
                    const decoded = jwt_decode(data.data.access_token);
                    localStorage.setItem('companyId', decoded.companyId);
                    localStorage.setItem('companyName', decoded.companyName);
                    localStorage.setItem('companyCode', payload.companyCode);
                    history.push(REACT_URL_DASHBOARD);
                }
            })
            .catch((er) => { })
    };

    return (
        <>
            <LoginLayoutV2 lang={true} src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? company : vis_company} backbutton={true}>
                <Grid className='center_div'>
                    <Grid container alignItems='center'>
                        <Grid item className='logo-center'>
                            <img src={process.env.REACT_APP_ENVIRONMENT === 'USA' ? select_company : visSelectComapny} alt='logo' style={{ width: '150px' }} />
                        </Grid>
                    </Grid>
                    <form className={classes.form} noValidate autoComplete='off'>
                        <Grid item xs={12} style={{ marginBottom: '13px', marginTop: '50px' }}>
                            <Typography variant='h2' align='left' style={{ color: '#464646', fontWeight: '500' }}>
                                Select Company
                            </Typography>
                        </Grid>
                        <Grid>
                            <CustomAutocomplete
                                className={classes.selectcompany}
                                label={t('VERIFYCOMP0007')}
                                onSelect={(e) => setCompany(e.code)}
                                options={companies}
                                required={true}
                            />
                        </Grid>
                        <Grid container spacing={5} alignItems='center'>
                            <Grid item xs>
                                <Box mt={3}>
                                    <Button
                                        fullWidth variant='contained'
                                        onClick={() => {
                                            VerifyCompany();
                                        }}
                                    >
                                        {t('VERIFYCOMP0003')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                    <Grid container alignItems='center'>
                        <Grid item xs>
                            <Box mt={4} align='center'>
                                <Typography variant='body2' color='textPrimary' align='center' style={{ fontSize: '12px' }}>
                                    {t('VERIFYCOMP0005')} <Link href='/vis/create-company' className='link_green'> {t('VERIFYCOMP0006')}</Link>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </LoginLayoutV2>
        </>
    );
}
export default withRouter(SelectCompany);
