import { Avatar, Divider, Grid, IconButton, Menu, MenuItem, Radio, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CompanyBackgroundImg from '../assets/CompanyBackgroundImg.svg';
import CompanyBackgroundImg_blue from '../assets/company_blue.svg';
import vis_CompanyBackgroundImg from '../assets/images/onboard/company-white.svg';
import SwitchImage from '../assets/SwitchImage';
import jwt_decode from "jwt-decode";
import Person from '../../src/assets/Person.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CompanyIcon from '../assets/CompanyIcon.svg';
import { useDispatch } from 'react-redux';
import { getCompanies, selectCompany, switchCompanies, switchCompany } from '../slices/onboard/LoginSlice';
import CommonUtil from '../Util/CommonUtils';
import { useTranslation } from 'react-i18next';
import { REACT_URL_DASHBOARD } from '../actions/EndPoints';
import { useHistory } from 'react-router-dom';
import Toast from './ToastContainer/CustomToast';
import { getCompanyInfo, getUserByCompanyIdOrCode } from '../slices/Company/CompanySlice'


function SwitchCompany(props) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const [company, setCompany] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const [companyCode, setCompanyCode] = useState('');
    const [companyList, setCompanyList] = useState([]);
    const [picture, setPicture] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [image, setImage] = useState('');
    const [companyProfile, setcompanyProfile] = useState({})

    const zlink = localStorage.getItem("redirectFrom");

    const onMouseOverHandleChange = (event) => {
        setIsSelected(true);
        setCompanyCode(event.target.value)
    }

    const onMouseLeaveHandleChange = (event) => {
        setIsSelected(false);
        setCompanyCode(event.target.value)
    }

    useEffect(() => {
        loadCompanies();
    }, [company]);



    const loadCompanies = () => {
        dispatch(switchCompanies())
            .unwrap()
            .then((data) => {
                setCompanyList(CommonUtil.isEmpty(data.data) ? [] : data.data.company);
                const com = data.data.company.filter((itm) => {
                    return itm.id === localStorage.getItem('companyId');
                });
                setImage(com[0].companyLogo !== undefined ? 'data:image/jpeg;base64,' + com[0].companyLogo : '');
            })
            .catch((er) => { })
    };

    const VerifyCompany = (code) => {
        if (CommonUtil.isEmptyString(code)) {
            Toast(t('VERIFYCOMP0001'), 'error');
            return;
        }
        const payload = {
            companyCode: code,
            companyId: ''
        };
        dispatch(selectCompany(payload))
            .unwrap()
            .then((data) => {
                if (data.code === 'UASI0011') {
                    localStorage.removeItem(process.env.REACT_APP_TEMP_TOKEN);
                    localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, 'Bearer ' + data.data.access_token);
                    localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, data.data.refresh_token);
                    const decoded = jwt_decode(data.data.access_token);
                    localStorage.setItem('companyId', decoded.companyId);
                    localStorage.setItem('companyName', decoded.companyName);
                    localStorage.setItem('companyCode', payload.companyCode);
                    window.location.reload();
                    setCompanyName(decoded.companyName);
                    dispatch(getCompanyInfo())
                        .unwrap()
                        .then((data) => {
                            if ((data.code = "UASI0000")) {
                                setPicture(data.data.companyLogo ? 'data:image/jpeg;base64,' + data.data.companyLogo : '')
                            }
                        })
                }
            })
            .catch((er) => { })
    };


    const handleChange = (event) => {
        VerifyCompany(event.target.value);
    }

    const handleChangeItem = (item) => {
        VerifyCompany(item);
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    var decoded_jwtToken = jwt_decode(
        localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN)
    );


    useEffect(() => {
        dispatch(getUserByCompanyIdOrCode(decoded_jwtToken.companyId)).unwrap().then((res) => {
            if (res.code === "UASI0026") {
                console.log(res.data)
                setcompanyProfile(res.data)
            }
        })
    }, [])
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title={!zlink ? "Account settings" : ''}>
                    <IconButton
                        onMouseOver={handleClick}
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2, mr: 5 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >

                        {/* {companyProfile !== null ? <Avatar src={companyProfile.imagePreSignedURL} /> :
                            <Avatar src={picture ? picture : process.env.REACT_APP_ENVIRONMENT === 'USA' ? CompanyBackgroundImg_blue : vis_CompanyBackgroundImg} */}
                        <>
                            <Avatar src={companyProfile.imagePreSignedURL}
                                sx={[
                                    picture === '' ?
                                        {
                                            width: 40,
                                            height: 40,
                                            background: process.env.REACT_APP_BG_AVATAR,
                                            padding: '0px'
                                        } : '',
                                    open && {
                                        background: process.env.REACT_APP_BG_AVATAR,
                                        opacity: 0.8
                                    },
                                    (theme) => ({
                                        '&:hover': {
                                            background: process.env.REACT_APP_BG_AVATAR,
                                            opacity: 0.8
                                        },
                                    }),
                                ]}
                            />
                        </>
                        <div style={{ padding: '5px' }}>
                            <Typography variant='h5'>
                                {decoded_jwtToken.companyName.length >= 20 ?
                                    <Tooltip title={decoded_jwtToken.companyName}>
                                        <Typography variant='h5'>
                                            {decoded_jwtToken.companyName.length <= 20 ? decoded_jwtToken.companyName : decoded_jwtToken.companyName.substring(0, 17) + '...'}
                                        </Typography>
                                    </Tooltip>
                                    :
                                    <Typography variant='h5'>
                                        {decoded_jwtToken.companyName.length <= 20 ? decoded_jwtToken.companyName : decoded_jwtToken.companyName.substring(0, 17) + '...'}
                                    </Typography>
                                }
                            </Typography>
                        </div>
                        {!zlink ? (<>
                            <KeyboardArrowDownIcon style={{ paddingTop: '2.5px', color: process.env.REACT_APP_ICON }} />
                        </>) : (<></>)}
                    </IconButton>
                </Tooltip>
                {!zlink ? (<>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        style={{
                            border: '0.5px solid lightgray',
                            borderRadius: '5px',
                        }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                bgcolor: process.env.REACT_APP_BG_HEADER_LOGO,
                                height: '89px',
                                overflow: 'visible',
                                mt: 1.5,
                                width: '270px',
                                boxShadow: 'none !importent',
                                backgroundImage: `url(${CompanyBackgroundImg})`,
                                backgroundPosition: 'bottom right',
                                backgroundRepeat: 'no-repeat',
                                opacity: 0.8,

                                '& .MuiAvatar-root': {
                                    width: 50,
                                    height: 50,
                                    ml: 1,
                                    mr: 1,
                                    background: '#FFFFFF 0% 0% no-repeat padding-box',
                                    // opacity: 0.6,
                                    backgroundColor: "#f1efef7d"
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: process.env.REACT_APP_BG_HEADER_LOGO,
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <Grid style={{ margin: '8px', display: 'flex', direction: 'row' }}>
                            <Avatar ><SwitchImage /></Avatar>
                            <Grid style={{ margin: "2px" }}>
                                {decoded_jwtToken.companyName.length >= 20 ? <Tooltip title={decoded_jwtToken.companyName}>
                                    <Typography style={{ fontSize: '16px', color: '#FFFFFF' }}>{decoded_jwtToken.companyName.length <= 20 ? decoded_jwtToken.companyName : decoded_jwtToken.companyName.substring(0, 17) + '...'}</Typography>
                                </Tooltip>
                                    : <Typography style={{ fontSize: '12px', color: '#FFFFFF' }}>{decoded_jwtToken.companyName.length <= 20 ? decoded_jwtToken.companyName : decoded_jwtToken.companyName.substring(0, 17) + '...'}</Typography>
                                }
                                <Typography style={{ fontSize: '12px', color: '#FFFFFF' }}>{decoded_jwtToken.email}</Typography>
                                <Typography style={{ fontSize: '12px', color: '#FFFFFF' }}>{decoded_jwtToken.roleName}</Typography>
                            </Grid>
                        </Grid>

                        {!zlink ? (<>
                            {process.env.REACT_APP_ENVIRONMENT === 'USA' ? "" : <Divider />}
                        </>) : (<></>)}

                        {!zlink ? (<>
                            {process.env.REACT_APP_ENVIRONMENT === 'USA' ? '' :
                                <div style={{ height: '250px', width: '237', overflowY: 'auto', border: '0.5px solid lightgray', borderRadius: '3px', backgroundColor: '#fff' }}>
                                    <PerfectScrollbar>
                                        <Typography style={{ fontSize: '12px', color: '#637381', paddingLeft: '15px', paddingTop: '11px' }}>Switch Company</Typography>
                                        {companyList.map((item) => (
                                            (item.name.length > 20 ? <Tooltip title={item.name}>
                                                <MenuItem
                                                    onClick={() => { handleChangeItem(item.code); }} value={item.code} onMouseOver={onMouseOverHandleChange} onMouseLeave={onMouseLeaveHandleChange}
                                                    style={{
                                                        padding: "5px",
                                                        margin: "14px",
                                                        borderRadius: "1px",
                                                        fontSize: '14px',
                                                        border: "0.5px solid lightgray",
                                                        // color: '#434547',
                                                        //backgroundColor: decoded_jwtToken.companyCode === item.code ? '#e0ffff' : '#F6F6F6 ',
                                                    }}>
                                                    <Radio checked={decoded_jwtToken.companyCode === item.code} value={item.code} onClick={handleChange} />
                                                    {item.name.substring(0, 16) + '...'}
                                                </MenuItem>
                                            </Tooltip> :
                                                <MenuItem
                                                    onClick={() => { handleChangeItem(item.code); }} value={item.code} onMouseOver={onMouseOverHandleChange} onMouseLeave={onMouseLeaveHandleChange}
                                                    style={{
                                                        padding: "5px",
                                                        margin: "14px",
                                                        border: "0.5px solid lightgray",
                                                        borderRadius: "1px",
                                                        fontSize: '14px',
                                                        // color: '#434547',
                                                        backgroundColor: isSelected && decoded_jwtToken.companyCode == item.code ? '#EEFFF4' : '',
                                                    }}>
                                                    <Radio checked={decoded_jwtToken.companyCode === item.code} value={item.code} onClick={() => { handleChangeItem(item.code); }} />
                                                    {item.name}
                                                </MenuItem>
                                            )
                                        ))}
                                    </PerfectScrollbar>
                                </div>
                            }</>) : (<></>)}
                    </Menu>
                </>) : (<></>)}
            </Box>
        </>
    );
}

export default SwitchCompany;