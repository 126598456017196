import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "../../components/DataTable/DataTable";
import { addAccessLevel } from "../../slices/PerpouseType/PerpouseTypeAccessSlice";
import { ReactComponent as AddDeviceIcon } from '../../../src/assets/images/checkInAndOutDevice/AddDeviceIcon.svg'
import Toast from "../../components/ToastContainer/CustomToast";
import { ReactComponent as AddDeviceIconUSA } from "../../assets/USA_Images/adddeviceusa.svg";
import TitleBar from "../../components/v4/TitleBar";
import { Grid } from "@mui/material";
import { Button } from "antd";
import { getVisitorAccessLevels } from "../../slices/VisitorAccessLevels/VisitorAccessLevelSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CommonUtil from "../../Util/CommonUtils";
import { toast } from "react-toastify";
import { REACT_URL_VISITORACCESSLEVEL } from "../../actions/EndPoints";


export default function VisitorAddAccessLevelDialog(props) {
    const { open, handleClose,id } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(1);
    const [selectedAccessLevels, onSelectionChange] = useState([]);
    const history=useHistory();
    const store=useSelector((state)=>state.accessLevel)
    const [accessId,setAccessId]=useState([]);
  
    let accessLevelID=[];

    const [paging, setPaging] = useState({
        page: 1,
        rowsPerPage: 10,
    });

    const defaultPaging = {
        page: 1,
        rowsPerPage: 10,
    };

    useEffect(() => {
        loadCloudAccess_Levels(paging);
    }, [paging]);
    
    // useEffect(()=>{
    //   if(!CommonUtil.isEmptyArray(store.accessLevelIds))
    //     setAccessId(store.accessLevelIds);
    // },[store])

    const handleChange = (newPage, size) => {
        setPage(newPage);
        setPaging({
            page: newPage,
            rowsPerPage: size,
        });
    };


    const onSelectionRowdata = (ids) => {
        const selectedIDs = new Set(ids);
        const selectedRowData = ids;
        onSelectionChange(selectedRowData);
    };

    const loadCloudAccess_Levels = (paging) => {
        const query = {
            page: paging.page,
            rowsPerPage: paging.rowsPerPage,
        };
        
        dispatch(getVisitorAccessLevels(query))
            .unwrap()
            .then((data) => {
                if ((data?.code === "DMSI0000" && data?.data)) {
                    setPage(data?.data?.currentPage);
                    setRows(data?.data?.accessGroups);
                    setTotalPages(data?.data?.totalPages);

                } else {
                    setRows([]);
                }
            })
            .catch((er) => { });
    };

    const columns = [
        {
            headerName: "",
            sortable: false,
            width: 5,
            renderCell: (params) => {
              return <div></div>;
            },
          },
        {
            field: 'name',
            headerName: t('COMMON044'),
            flex: 1,
        },
        {
            field: 'timeSlotName',
            headerName: t('VISACCLEVDIAL001'),
            flex: 1,
        },

        // {
        //     field: 'doorCount',
        //     headerName: t('VISACCLEVDIAL002')
        // },
        {
            field: "Actions",
            headerName: "Action",
            type: "actions",
            flex: 1,
            minWidth: 1,
            width: 150,
            headerAlign: "center",

            getActions: (params) => [
                <GridActionsCellItem
                    icon={
                        process.env.REACT_APP_ENVIRONMENT === 'USA' ? <AddDeviceIconUSA /> : <AddDeviceIcon />}
                    label="Add"
                   onClick={() => handleAddIconClick([params.row])}
                />,
            ],
        },

    ];
    const handleSubmitAdd = (data) => {
       
        let object={};
        if (data.length < 1) {
            Toast((t('COMMON019')), "warning");
            return;
        }
        if(data.length===1){
            let array=[data[0]];
            object={
                id:history.location.state.state.id,
                ids:{ids:array}
            }
        }else{
           object = {
                 id:history.location.state.state.id,
                ids:{ids:selectedAccessLevels},
            };
        }   
         accessLevelID.push(object.ids.ids)
        dispatch(addAccessLevel(object))
            .unwrap()
            .then((data) => {
                if (data?.code === "CVAI0046") {
                    Toast("Access level added successfully", "success");
                    history.goBack();
                } else { Toast(data.message, "error")}
            })
            .catch((er) => { });

    }

    const handleAddIconClick = (data) => {
        let object = {};
        if (data.length < 1) {
          Toast(t("COMMON019"), "warning");
          return;
        }
        if (data.length === 1) {
          let array = [data[0].id];
          object = {
            id: history.location.state.state.id,
            ids: { ids: array },
          };
        } else {
          object = {
            id: history.location.state.state.id,
            ids: { ids: selectedAccessLevels },
          };
        }
        accessLevelID.push(object.ids.ids);
        dispatch(addAccessLevel(object))
          .unwrap()
          .then((data) => {
            if (data?.code === "CVAI0046") {
                Toast("Access level added successfully", "success");
              history.push({
                pathname: REACT_URL_VISITORACCESSLEVEL,
                state: { data: data.data },
              });
            } else {
              Toast(data.message, "error");
            }
          })
          .catch((er) => {});
      };

    return (
        <>
            <TitleBar title={t("Access Levels")} back_button={true} />  
            <div style={{ padding: '18px 18px 1px 18px' }}>
                <DataTable
                    checkboxSelection
                    columns={columns}
                    rows={rows}
                    page={page}
                    count={totalRecords}
                    pageCount={totalPages}
                    pageSize={rowsPerPage}
                    handleChange={(newPage, size) => handleChange(newPage, size)}
                    onSelectionModelChange={(ids) => onSelectionRowdata(ids)}
                />
            </div>
            <Grid style={{ display: 'flex', justifyContent: 'end', margin: '12px 0px' }}>
                <Button onClick={() => handleSubmitAdd(selectedAccessLevels)} style={{ width: '240px', backgroundColor: 'rgb(54, 201, 109)', color: '#FFFF', height: '50px', marginRight: '24px' }}>
                    Submit
                </Button>
                <Button
                    onClick={() => history.goBack()}
                    onClose={handleClose}
                    cancel={handleClose}
                    variant='outlined'
                    style={{ width: '240px', backgroundColor: 'white', color: 'rgb(54, 201, 109)', borderColor: 'rgb(54, 201, 109)', height: '50px', marginRight: '16px' }}
                >
                    Cancel
                </Button>
            </Grid>
        </>
    );

}