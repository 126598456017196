import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import "../../App.css";
import { Avatar } from "@material-ui/core";
import { Box, IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import CommonUtil from "../../Util/CommonUtils";
import moment from "moment";
import { listInvitations } from "../../slices/Invitations/InvitationsSlice";
import SimpleDialogDemo from "../Approvals/ImageDailogBox";
import CustomStyleStatus from "../../components/statusStyleFormat/statusStyleFormat";
import DataTable from "../../components/DataTable/DataTable";
import InvitationFilterForm from "../../components/InvitationFilterForm";
import Search from "../../components/SearchTab/Search";
import BlockVisitorIcon from "../Invitations/BlockVisitorIcon";
import BlockVisitorForm from "../../components/DialogBoxComponent/BlockVisitorForm";
import CheckOutProfile from "./CheckOutProfile";
import { exportCheckInVistorsLogs } from "../../services/Invitations/ExportService";
import Toast from "../../components/ToastContainer/CustomToast";
import FilterExportDialog from "./FilterExportDialog";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { getPermissionsListByroleId } from "../../slices/RoleandPermission/RoleandPermissionSlice";
import api from "../../config/http-common";

export default function CheckIn(props) {

  const [clear, setClear] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [openBlacklistVisitor, setOpenBlacklistVisitor] = useState(false);
  const [blacklistId, setBlacklistId] = useState();
  const [del,setDel] = useState(false);
  const [pageSize,setPageSize] = useState(0);
  const zlink = localStorage.getItem("redirectFrom");

  const [roles, setRoles] = useState([]);
  const [listVisitorLogs, setListVisitorLogs] = useState(true);
  const [viewVisitorLogs, setViewVisitorLogs] = useState(true);
  const [blockVisitorLogs, setBlockVisitorLogs] = useState(true);

  const  status = ["CHECKED_IN"];

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

  const [paging, setPaging] = useState({
    page: 1,
    rowsPerPage: 10,
  });

  const defaultPaging = {
    page: 1,
    rowsPerPage: 10,
  };

  const [filter, setFilter] = React.useState({
    status: ["CHECKED_IN"],
    purposeTypeId: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: ["INVITATION","WALKIN_REGISTARTION","WALKIN_REGISTARTION_KIOSK","SELF_REGISTARTION",],
    isPagable: true,
  });

  const defaultFilter = {
    status: ["CHECKED_IN"],
    purposeTypeId: "",
    siteId: "",
    scheduledStartDate: CommonUtil.formatToUtc(startDate),
    scheduledEndDate: CommonUtil.formatToUtc(endDate),
    visitMode: ["INVITATION","WALKIN_REGISTARTION","WALKIN_REGISTARTION_KIOSK","SELF_REGISTARTION",],
    isPagable: true,
  };

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setRowsPerPage(size);
    setPaging({
      page: newPage,
      rowsPerPage: size,
    });
  };

  useEffect(() => {
    loadData(filter, paging);
  }, [filter, paging, clear]);

  const handleOpen = () => {
    setOpen(true);
  };

  const resetFilter = () => {
    setClear(false);
    setPaging({ ...paging, page: 1 });
    setFilter(defaultFilter);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
      setRoles(l);
      setViewVisitorLogs(l.includes("VIEW_VISITOR_LOG") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
      setListVisitorLogs(l.includes("VISITOR_LOG_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
      setBlockVisitorLogs(l.includes("BLOCK_VISITOR_LOG") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));

        if (l.includes("VISITOR_LOG_LIST")) {
          dispatch(listInvitations(Object.assign(filter, paging)))
            .unwrap()
            .then((data) => {
              if ((data?.code === "CVAI0000" && data?.data)) {
                setTotalRecords(data?.data?.totalCount);
                setRows(data?.data?.visits);
                setPage(data?.data?.curPage);
                setPageSize(data?.data?.pageSize);
                setTotalPages(data?.data?.totalPages);
              } else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("VISITOR_LOG_LIST") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);

  const loadData = (filter, paging) => {
    dispatch(listInvitations(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if ((data?.code === "CVAI0000" && data?.data)) {
          setTotalRecords(data?.data?.totalCount);
          setRows(data?.data?.visits);
          setPage(data?.data?.curPage);
          setPageSize(data?.data?.pageSize);
          setTotalPages(data?.data?.totalPages);
        } else {
          setRows([]);
        }
      })
      .catch((er) => {});
  };

  useEffect(() => {
    
    if (del === true && parseInt(pageSize) !== 1) {
      loadData(filter,{ page: page,rowsPerPage: rowsPerPage});
      setDel(false);
    } else {
      if (del === true) {
        setPaging({ page: page-1,rowsPerPage: rowsPerPage})
        setDel(false);
      }
    }
  }, [del]);

  const handleFilter = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      siteId: data.siteId,
      purposeTypeId: data.purposeTypeId,
      visitMode: data.visitMode,
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
      status: data.status,
    });
  };

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(filter, paging);
      return;
    }

    dispatch(listInvitations(Object.assign(filter, paging)))
      .unwrap()
      .then((data) => {
        if (data?.code == "CVAI0000" ) {
         
          if (searchedVal === "Check In") {
            searchedVal = "CHECKED_IN";
          }
          const filteredRows = data?.data.visits.filter((test) => {
            return (
              (test.visitor.firstName ? test.visitor.firstName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.visitor.lastName ? test.visitor.lastName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.visitor.phone ? test.visitor.phone.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.visitor.email ? test.visitor.email.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.hostName ? test.hostName.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              (test.scheduledStartDate ? test.scheduledStartDate.toLowerCase().includes(searchedVal.toLowerCase()) : "") ||
              test.status.toLowerCase().includes(searchedVal.toLowerCase()) ||
              test.purposeType.name.toLowerCase().includes(searchedVal.toLowerCase()) ||
              `${test.visitor.firstName} ${test.visitor.lastName}`.toLowerCase().includes(searchedVal.toLowerCase())
            );
          });
          setRows(filteredRows);  
        } else {
          setRows([]);
        }
      })
      .catch((er) => { });
  };

  const handleCheckInExport = (data) => {
    setClear(true);
    setPaging({ ...paging, page: 1 });
    setFilter({
      status: "CHECKED_IN",
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
    });
    
    const stats = {
      page: 1,
      rowsPerPage: 1000,
      scheduledStartDate: CommonUtil.formatToUtc(data.scheduledStartDate),
      scheduledEndDate: CommonUtil.formatToUtc(data.scheduledEndDate),
      status: "CHECKED_IN",
    }
    
    dispatch(exportCheckInVistorsLogs(onSuccess, onFailure, stats))
      .unwrap()
      .then((data) => {
        if ((data?.code === "CVAI0000" && data?.data)) {
          props.handleClose();
          onSuccess();
        } else {
          onFailure();
        }
      })
      .catch((er) => { });
  };

  const onSuccess = () => {
    return Toast(t("EXPORTSUCC001"), 'success');
  }

  const onFailure = () => {
    Toast(t('COMMON000'), 'error');
  };

  const handleBlacklistVisit = (params) => {
    setOpenBlacklistVisitor(!openBlacklistVisitor);
    setBlacklistId(params.row.id);
  };

  const handleTextOverflow = (text) => {
    if (text && text.length > 15) {
        return text.substring(0, 17) + '...';
    } else {
    return text;
   }
 }
  
  const ZoomHandaler = (params) => (
    <SimpleDialogDemo
      button={
        <Avatar
          style={{
          cursor:'pointer',
        }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        >{params.row.visitor.firstName == null ? "" :params.row.visitor.firstName.charAt(0)}</Avatar>
      }
      child={
        <Avatar
          style={{
            width: "300px",
            height: "300px",
            border: "12px solid #FFFF",
            fontSize: "100px",
            borderRadius: "1px",
            cursor:'pointer',
          }}
          alt={params.row.visitor.firstName}
          src={params.row.visitor.profilePhotoURL !== undefined ? params.row.visitor.profilePhotoURL : null}
        >{params.row.visitor.firstName == null ? "" :params.row.visitor.firstName.charAt(0)}</Avatar>
      }
    />
  );

  const columns = React.useMemo(() => [
    {
        field: "visitor.profile",
        headerName: "",
        sortable: false,
        width: 10,
        renderCell: (params) => {
          return ZoomHandaler(params);
        },
    },
    {
      field: "visitor.firstName",
      headerName: t("COMMON014"),
      flex: 1,
      width: 10,
      valueGetter: (params) =>
        params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName),
        renderCell: (params) => (
          <Tooltip title={` ${ params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName)}`} placement="bottom-start">
             <span>{handleTextOverflow( params.row.visitor.lastName !== null ? (params.row.visitor.firstName + " " + params.row.visitor.lastName) : (params.row.visitor.firstName))}</span>
             </Tooltip>
              ),
    },
   
    {
      field: "visitor.email",
      headerName: t("PERSON005"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.email,
      renderCell: (params) => (
        <Tooltip title={` ${ params.row.visitor.email}`} placement="bottom-start">
           <span>{handleTextOverflow(params.row.visitor.email)}</span>
           </Tooltip>
            ),
    },

    {
      field: "purposeType",
      headerName: t("Purpose"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) => params.row.visitor.purposeType !== null ? params.row.purposeType.name : '',
      renderCell: (params) => (
        <Tooltip title={` ${ params.row.visitor.purposeType !== null ? params.row.purposeType.name : ''}`} placement="bottom-start">
           <span>{handleTextOverflow( params.row.visitor.purposeType !== null ? params.row.purposeType.name : '')}</span>
           </Tooltip>
           ),
    },

    {
      field: "scheduledStartDate",
      headerName: t("INVITEDATELBL"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
      params.row.scheduledStartDate ? moment(CommonUtil.getLocalDateTimeInYearFormat(params.row.scheduledStartDate)).format("DD-MM-YYYY HH:mm"): "",
    },

    {
      field: "checkIn",
      headerName: t("Check In"),
      flex: 1,
      minWidth: 150,
      valueGetter: (params) =>
      params.row.checkIn ? moment(CommonUtil.getLocalDateTimeInYearFormat(params.row.checkIn)).format("DD-MM-YYYY HH:mm"): "",
    },

    {
      field: "status",
      headerName: t("COMMON001"),
      flex: 1,
      minWidth: 10,
      renderCell: (params) => {
        return <CustomStyleStatus row={params.row.status} icon={false} />;
      },
    },
    {
      field: "Actions",
      headerName: t("ACTION"),
      type: "actions",
      flex: 1,
      minWidth: 190,
      width: 150,
      headerAlign: "center",
      getActions: (params) => [

        (<>
        {viewVisitorLogs &&
        <GridActionsCellItem
          icon={<CheckOutProfile rowData={params.row} />}
          label="View"
        />}
        </>),

        (<>
        {blockVisitorLogs &&
        <GridActionsCellItem
          icon={<BlockVisitorIcon />}
          label="BlockVisitor"
          onClick={() => handleBlacklistVisit(params)}
        />}
        </>),

        // <GridActionsCellItem
        //   icon={<ForceCheckoutIcon />}
        //   label="Force Checkout"
        //   onClick={() => handleForceCheckOut(params)}
        // />,
      ],
    },
  ]);

  return (
    <>

      <Box
        display={"flex"}
        width={"100%"}
        flexDirection={"row-reverse"}
        alignItems={"center"}
      >
        <Box item>
          {clear && (
            <Link
              href="#"
              underline="hover"
              className="line"
              style={{
                opacity: "0.8",
                color: "#E3393C",
                fontSize: "14px",
                underline: "hover",
                display: "inline",
              }}
              onClick={resetFilter}
            >
              {"Clear Filter"}
            </Link>
          )}
        </Box>

        <Box item>
          <InvitationFilterForm
            rowsPerPage={rowsPerPage}
            selectedObject={filter.visitMode}
            status={status}
            applyFilter={(data) => handleFilter(data)}
          />
        </Box>

        {/* <Box item p={2}>
          <FilterExportDialog
           rowsPerPage={rowsPerPage}
           applyFilter={(data) => handleCheckInExport(data)}
          />
        </Box> */}

       
      <Box item>
        <Tooltip title={"Refresh"}>
              <IconButton onClick={() => loadData(filter, paging)}>
                <Refresh />
              </IconButton>
            </Tooltip>
        </Box>

        <Box item p={1}>
          <Search
            onSearch={(e) => globalsearch(e)}
            clearSearch={() => loadData(filter, paging)}
          />
        </Box>
      </Box>

     <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={rowsPerPage}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>

      {openBlacklistVisitor && (
         <BlockVisitorForm
         open={openBlacklistVisitor}
         handleClose={(data) => {
           setOpenBlacklistVisitor(data);
         }}
         disable={()=>setDel(true)}
         BlacklistVisitorId={blacklistId}
       />
      )}
   
    </>
  )
}
