import {
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { useDispatch } from "react-redux";
import CommonUtil from "../../Util/CommonUtils";
import Toast from "../../components/ToastContainer/CustomToast";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";
import { addChecklistTemplate, updateChecklistTemplate } from "../../slices/CheckListSlice/CheckListSlice";

export default function ChecklistTemplateForm(props) {
  const { open, handleClose } = props;
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    id: "",
    name: "",
    // code: "",
    description: "",
  });

  const [error, setError] = React.useState({
    id: false,
    name: false,
    // code: false,
    description: false,
  });

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (CommonUtil.isEmpty(payload.name)) {
      Toast(("Please Enter Checklist Name"), 'error')
      setError({ name: true });
      return;
    }

    if (!CommonUtil.validateName(payload.name)) {
      Toast(('Please enter the valid Checklist Name'), 'error')
      setError({ name: true });
      return;
    }

    if (CommonUtil.checkCharactersGreaterThan250(payload.description)) {
      Toast(("Please Enter Description"), 'error')
      setError({ description: true });
      return;
    }

    const newTemplate = {
      id: props.selectedObject.id,
      name: payload.name,
      // code: payload.code,
      description: payload.description,
    };

    if (CommonUtil.isEmptyString(props.selectedObject.id)) {
      dispatch(addChecklistTemplate(newTemplate))
        .unwrap()
        .then((data) => {
          if (data?.code === "CVAI0033") {
            Toast("Checklist Template Created Successfully", 'success')
            handleClose();
          } else {
            Toast(data.message,"error")
          }
        });
    } 
    else {
      dispatch(updateChecklistTemplate(newTemplate))
        .unwrap()
        .then((data) => {
          if (data?.code === "CVAI0029") {
            Toast(("Checklist Template Updated Successfully"), 'success')
            handleClose();
          } else {
            Toast(data.message,"error")
          }
        });
    }
  };

  useEffect(() => {
    if (props.selectedObject.id) {
      setPayload({
        ...payload,
        id: props.selectedObject.id,
        name: props.selectedObject.name,
        // code: props.selectedObject.code,
        description: props.selectedObject.description,
      });
    }
  }, []);

  return (
    <>
      <DialogBox
        Header={payload.id ? "Update Checklist Template" : "Add Checklist Template"}
        acceptText="Submit"
        cancelText="Cancel"
        style={{ color: "#3D4977", opacity: 1, minHeight: "" }}
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={handleClose}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <CustomTextfield
              type={'text'}
              label="Name"
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
              required={true}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>

          {/* <Grid item xs={12}>
            <CustomTextfield
              type={'text'}
              label="Code"
              error={error.code}
              name="code"
              value={payload.code}
              handleChange={(e) => handleChange(e)}
              helperText={error.code}
              validation='alphabets'
              required={true}
              inputProps={{ maxLength: 50 }}
            />
          </Grid> */}
          <Grid item xs={12} >
            <CustomTextfield
              type={'text'}
              label="Description"
              error={error.description}
              name="description"
              value={payload.description}
              handleChange={(e) => handleChange(e)}
              helperText={error.description}
              inputProps={{ maxLength: 150 }}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
