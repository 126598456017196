import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {
    let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
    if (!CommonUtil.isEmpty(filter.operationName)) {
        query = query + "&operationName=" + filter.operationName;
    }
    return query;
};

const timeSlotList = (filter) => {
    return api.securedAxios().post('/web/schedules/list' + buildQuery(filter));
}

const timeSlotListPro = (query) => {
    return api.securedAxios().post('/web/schedules/list?pageNumber='+query.pageNumber+ '&pageSize=' +query.pageSize);
};

const saveTimeSlot = (payload) => {
    return api.securedAxios().post('/web/schedule', payload );
};

const updateTimeSLots =(payload) =>{
    return api.securedAxios().put(`/web/schedules/${payload.id}`,payload)
}

const getTimeSlotById=  (payload) =>{
    return api.securedAxios().get(`/web/schedules/${payload}`)
}

const deleteTimeSlot=  (payload) =>{
    return api.securedAxios().delete(`/web/schedules?id=${payload.id}&name=${payload.name}`)
}

const TimeSlotService = {
    timeSlotList,
    saveTimeSlot,
    updateTimeSLots,
    getTimeSlotById,
    deleteTimeSlot,
    timeSlotListPro

};
export default TimeSlotService;