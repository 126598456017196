import { Tooltip, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import { useTranslation } from "react-i18next";
import Toast from "../../components/ToastContainer/CustomToast";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";

export default function FilterRolesAndPermission(props) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const [payload, setPayload] = useState({
    name: "",
    code: "",
  });

  const handleClose = () => {
    setPayload({
      name: "",
      code: "",
    });
    setOpen(false);
  };

  const [error, setError] = useState({
    name: false,
    code: false,
  });

  const reset = () => {
    if ((payload.name.trim() === "") && (payload.code.trim() === "")){
        Toast(t("Nothing to Reset"), "error");
      return;
    }

    setPayload({
      name: "",
      code: "",
    });
  };

  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if ((payload.name.trim() === "") && (payload.code.trim() === "")){
      Toast("Please enter filter condition!", "error");
      return;
    }

    props.applyFilter(payload);
    setPayload({
      name: "",
      code: "",
    });
    handleClose();
  };
  useEffect(() => {}, []);

  return (
    <>
      <Tooltip
        title={
          <Typography fontSize={12} fontWeight={500}>
            Filter
          </Typography>
        }
      >
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>
          <FilterIcon />
        </FilterIcon>
      </Tooltip>

      <DialogBox
        Header="Filter"
        acceptText="Apply"
        cancelText="Reset"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        onSubmit={handleSubmit}
        cancel={reset}
      >
        <Grid
          container
          justifyContent="center"
          style={{ padding: "16px 32px", width: "100%" }}
          spacing={2}
        >
          <Grid item xs={11}>
            <CustomTextfield
              label="User Role Name"
              error={error.name}
              name="name"
              value={payload.name}
              handleChange={(e) => handleChange(e)}
              helperText={error.name}
            />
          </Grid>

          <Grid item xs={11}>
            <CustomTextfield
              label="User Role Code"
              error={error.code}
              name="code"
              value={payload.code}
              handleChange={(e) => handleChange(e)}
              helperText={error.code}
            />
          </Grid>
        </Grid>
      </DialogBox>
    </>
  );
}
