import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
import CancelButtons from "../../components/DialogBoxComponent/CancelButtons";
import ActivateIcon from "./ActivateIcon";
import CommonUtil from "../../Util/CommonUtils";
import { ResetTvRounded } from "@mui/icons-material";
import { manageUserrole } from "../../slices/Person/PersonSlice";
import { toast } from "react-toastify";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(1),
        overflowY: "clip",
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
    "& .MuiDialog-paper": {
        overflowY: "visible",
        borderRadius: "20px",
        minWidth: "520px",
        height: "500px",
        opacity: 1,
    },
    '& .MuiDialogContent-root ': {
        padding: '16px 24px',
        position: ' relative',
        bottom: '80px',
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other} >
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function PersonRole(props) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const [role,setRole]=useState('');
    const[error,setError]=useState({role:""})
    const [payload,setPayload]=useState({
        firstName:"",
         lastName:"",
            email:"",
 phoneCountryCode:"",  //+91
            phone:""
    });

   useEffect(()=>{
    if(open===true){
        setRole('');
      setPayload({
        firstName: !CommonUtil.isEmpty(props.rowData.firstName)?props.rowData.firstName:"",
         lastName: !CommonUtil.isEmpty(props.rowData.lastName)?props.rowData.lastName:"",
            email: !CommonUtil.isEmpty(props.rowData.email)?props.rowData.email:"",
            // phoneCountryCode: !CommonUtil.isEmpty(props.rowData.phone)?props.rowData.phone.split("-")[0]:"",
            // phone: !CommonUtil.isEmpty(props.rowData.firstName)?props.rowData.phone.split("-")[1]:""
      })}
    },[open])

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit=()=>{
        if(role===''){
            return toast.error("User role is required.");
        }
        const state={
            payload:payload,
            role:role
        }
        dispatch(manageUserrole(state))
        .unwrap()
        .then((data) => {
            if (data?.code === "UASI0009"||data?.code ==="UASI0025") {
                toast.success("User role assigned successfully");
                setOpen(false);
              } else {
                if(data?.code==='ZLINKE002')  toast.error("User role already exit");
                else if(data?.code==='ZLINKE001')  toast.error(data.message);
                else if(data?.code==='CVAE0602')  toast.error(data.message);
                else  toast.error(data.message);
              }
         });
    }

    return (
        <div>
            <div onClick={handleClickOpen}>
                <ActivateIcon />
            </div>
            <Grid>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle onClose={handleClose}>
                        <Avatar
                            alt={props.rowData.firstName}
                            src={props.rowData.imagePreSignedURL}
                            sx={{
                                width: 130,
                                height: 130,
                                bottom: "70px",
                                left: "190px",
                                border: "solid #FFFF",
                                borderWidth: "7px",
                            }}
                        />
                        <Box className="CssTextProfileViewPageName" marginTop={'15px'}>
                            {props.rowData.firstName + " " + props.rowData.lastName}
                        </Box>
                        <Box className="CssTextProfileViewPagePhone" margin={'5px 0px'} >
                            {props.rowData.phone ? props.rowData.phone : ' '}
                        </Box>
                        <Box className="CssTextProfileViewPagePhone">
                            {props.rowData.email}
                        </Box>
                    </BootstrapDialogTitle>
                    <DialogContent style={{ marginLeft: "30px", marginRight: "30px" }}>

                        <Box display={'flex'} justifyContent='center' margin={'50px 0px'}>
                            <FormControl fullWidth>
                                <InputLabel>User role *</InputLabel>
                                <Select
                                    required
                                    label="User role"
                                    name='role' 
                                    error={error.role}
                                    helperText={error.role}
                                    onChange={(newValue)=>setRole(newValue.target.value)} 
                                >
                                    <MenuItem value={'owner'}>Owner</MenuItem>
                                    <MenuItem value={'employee'}>Person</MenuItem>    
                                </Select>
                            </FormControl>
                        </Box>
                   
                        <Box display={'flex'} justifyContent='center' marginTop={'90px'}>
                          <Box marginRight={'20px'} ><SubmitButtons onClick={()=>handleSubmit()} >save</SubmitButtons></Box>  
                            <CancelButtons onClick={()=>handleClose()}>cancel</CancelButtons>
                        </Box>
                    </DialogContent>
                </BootstrapDialog>
            </Grid>
        </div>
    );
}

export default PersonRole;
