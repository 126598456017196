import axios from "axios";
import CommonUtil from "../../components/Util/CommonUtils";

const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };

  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T00:00";

export const exportCheckoutVistorsLogs = (onSuccess, onFailure, filter) => async (dispatch) => {

      const buildQueryCheckOut = (filter) => {
        let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage ;
        if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
            query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
        }
        if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
          query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
        }
        if (!CommonUtil.isEmptyString(filter.status)) {
            query = query + "&status=" + filter.status;
        }
        return query;
      };

    axios({
        url: "/web/checked_out/export" + buildQueryCheckOut(filter),
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization:CommonUtil.getCookie('zlink_token'),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'CheckedOutVisitors log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
  };

  export const exportCheckInVistorsLogs = (onSuccess, onFailure, filter) => async (dispatch) => {

      const buildQueryCheckIn = (filter) => {
        let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage ;
        if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
            query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
        }
        if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
          query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
        }
        if (!CommonUtil.isEmptyString(filter.status)) {
            query = query + "&status=" + filter.status;
        }
        return query;
      };

    axios({
        url: "/web/checked_in/export" + buildQueryCheckIn(filter),
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization:CommonUtil.getCookie('zlink_token'),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'CheckInVisitors log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
  };

  export const exportCancelledVistorsLogs = (onSuccess, onFailure, filter) => async (dispatch) => {

      const buildQueryCancelled = (filter) => {
        let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage ;
        if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
            query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
        }
        if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
          query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
        }
        if (!CommonUtil.isEmptyString(filter.status)) {
            query = query + "&status=" + filter.status;
        }
        return query;
      };

    axios({
        url: "/web/cancelled/export" + buildQueryCancelled(filter),
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization:CommonUtil.getCookie('zlink_token'),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'CancelledVisitors log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
  };


  export const exportMissedVistorsLogs = (onSuccess, onFailure, filter) => async (dispatch) => {

      const buildQueryMissed = (filter) => {
        let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage ;
        if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
            query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
        }
        if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
          query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
        }
        if (!CommonUtil.isEmptyString(filter.status)) {
            query = query + "&status=" + filter.status;
        }
        return query;
      };

    axios({
        url: "/web/missed/export" + buildQueryMissed(filter),
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization:CommonUtil.getCookie('zlink_token'),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'MissedVisitors log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
  };

  export const exportRejectedVistorsLogs = (onSuccess, onFailure, filter) => async (dispatch) => {

      const buildQueryReject = (filter) => {
        let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage ;
        if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
            query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
        }
        if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
          query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
        }
        if (!CommonUtil.isEmptyString(filter.status)) {
            query = query + "&status=" + filter.status;
        }
        return query;
      };

    axios({
        url: "/web/rejected/export" + buildQueryReject(filter),
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization:CommonUtil.getCookie('zlink_token'),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
    
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'RejectedVisitors log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
  };

  export const exportAllVistorsLogs = (onSuccess, onFailure, filter) => async (dispatch) => {

      const buildQueryAll = (filter) => {
        let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage ;
        if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
            query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
        }
        if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
          query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
        }
        if (!CommonUtil.isEmptyString(filter.status)) {
            query = query + "&status=" + filter.status;
        }
        return query;
      };

    axios({
        url: "/web/allVisitors/export" + buildQueryAll(filter),
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization:CommonUtil.getCookie('zlink_token'),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'AllVisitors log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
  };


  export const exportBlacklistVisitors = (onSuccess, onFailure, filter) => async (dispatch) => {

    const buildBlacklistVisitors = (filter) => {
        let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage ;
        if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
            query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
        }
        if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
          query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
        }
        if (!CommonUtil.isEmptyString(filter.status)) {
            query = query + "&status=" + filter.status;
        }
        return query;
      };

    axios({
        url: "/web/blacklist/export" + buildBlacklistVisitors(filter),
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization:CommonUtil.getCookie('zlink_token'),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'BlacklistVisitors log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
  };

  export const exportInvitationVisitors = (onSuccess, onFailure, filter) => async (dispatch) => {

    const buildQueryInvitation = (filter) => {
        let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage ;
        if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
            query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
        }
        if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
          query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
        }
        if (!CommonUtil.isEmptyString(filter.status)) {
            query = query + "&status=" + filter.status;
        }
        return query;
      };

    axios({
        url: "/web/invitation/export" + buildQueryInvitation(filter),
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization:CommonUtil.getCookie('zlink_token'),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        params: {
            visitMode: "INVITATION",
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'Invitation log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
  };

  export const exportWalkInRegistrationVisitors = (onSuccess, onFailure, filter) => async (dispatch) => {

    const buildQueryWalkInRegistration = (filter) => {
        let query = "?pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
        if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
            query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
        }
        if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
          query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
        }
        if (!CommonUtil.isEmptyString(filter.status)) {
            query = query + "&status=" + filter.status;
        }
        return query;
      };

    axios({
        url: "/web/walkInRegistration/export" + buildQueryWalkInRegistration(filter),
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization:CommonUtil.getCookie('zlink_token'),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'WalkIn Registartion log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
  };

  export const exportSelfRegistrationVisitors = (onSuccess, onFailure, filter, page, rowsPerPage) => async (dispatch) => {

    const buildQuerySelfRegistration = (filter, page, rowsPerPage) => {
        let query = "?isPagable=true&pageNumber=" + page + "&pageSize=" + rowsPerPage;
        if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
          query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
        }
        if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) {
            query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
        }
        if (!CommonUtil.isEmptyString(filter.status)) {
            query = query + "&status=" + filter.status;
        }
        return query;
      };

    axios({
        url: "/web/selfRegistration/export" + buildQuerySelfRegistration(filter, page, rowsPerPage),
        method: 'get',
        headers: {
            'Content-type': 'application/json',
            Authorization:CommonUtil.getCookie('zlink_token'),
            companyId: localStorage.getItem('companyId'),
            'time-zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        params: {
            visitMode: "SELF_REGISTARTION",
        },
        responseType: "blob"
    })
        .then((response) => {
            try {
                const blob = new Blob(
                    [response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' })
                const aEle = document.createElement('a');
                const href = window.URL.createObjectURL(blob);
                aEle.href = href;
                aEle.download = 'Self Registartion log.xlsx';
                document.body.appendChild(aEle);
                aEle.click();
                document.body.removeChild(aEle);
                window.URL.revokeObjectURL(href);
                onSuccess();
            } catch {
                onFailure();
            }
        })
  };