import React, { useState } from "react";
import DialogBox from "../DialogBoxComponent/DialogBox";
import {
  Grid,
} from "@mui/material";
import { createTheme } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CommonUtil from "../../Util/CommonUtils";
import { useTranslation } from "react-i18next";
import { rescheduleVisitor } from "../../slices/Invitations/InvitationsSlice";
import { useDispatch } from "react-redux";
import Toast from "../../components/ToastContainer/CustomToast";
import { makeStyles } from "@material-ui/core";
import CustomDateTimePicker from "../CustomInputs/CustomDateTimePicker";

const MuiThemeTwo = createTheme({
  palette: {
    type: "light",
    primary: {
      main: process.env.REACT_APP_BG_ICON,
    },
    secondary: {
      main: "#f50057",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  cssLabel: {
    color: "#0000008a",
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: "#1976d2 !important",
    },
  },
  cssFocused: {
    color: "#1976d2 !important",
  },

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "#C4C4C4 !important",
  },
}));
const CustomIconLeft = styled(ChevronLeftIcon)(() => ({
  color: process.env.REACT_APP_BG_ICON,
  border: process.env.REACT_APP_BG_BORDER,
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: process.env.REACT_APP_BG_ICON,
    color: "#FFFF",
  },
}));

const CustomIconRight = styled(ChevronRightIcon)(() => ({
  color: process.env.REACT_APP_BG_ICON,
  border: process.env.REACT_APP_BG_BORDER,
  borderRadius: "50%",
  "&:hover": {
    backgroundColor: process.env.REACT_APP_BG_ICON,
    color: "#FFFF",
  },
}));
export default function RescheduleVisitor(props) {
  const { open, handleClose } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [error, setError] = React.useState({
    scheduledStartDate: false,
    scheduledEndDate: false,
  });

  const formatDayAndMonth = (numValue) => {
    if (numValue <= 0) {
      return "-" + (numValue + 12);
    }
    if (numValue < 10) {
      return "-0" + numValue;
    } else {
      return "-" + numValue;
    }
  };
  var today = new Date();
  var endDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T23:59";
  var startDate =
    today.getFullYear() +
    formatDayAndMonth(today.getMonth() + 1) +
    formatDayAndMonth(today.getDate()) +
    "T" +
    (today.getHours() < 10 ? "0" + today.getHours() : today.getHours()) +
    ":" +
    (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes());

  const [state, setState] = React.useState({
    id: props.CustomerID,
    scheduledStartDate: startDate,
    scheduledEndDate: endDate,
  });

  console.log("start", state.scheduledStartDate);
  console.log("end", state.scheduledEndDate);

  const onFailure = (data) => {
    if (CommonUtil.isEmpty(data)) {
      Toast(t("COMMON000"), "error");
      return;
    }
    switch (data.code) {
      case "CVAE1021":
        setError({ ...error, scheduledStartDate: true });
        break;
      case "CVAE1022":
        setError({ ...error, scheduledEndDate: true });
        break;
      case "CVAE1023":
        setError({ ...error, scheduledEndDate: true });
        break;
    }
    Toast(data.message, "error");
  };

  const handleSumbit = () => {
    if (
      state.scheduledStartDate === null ||
      state.scheduledStartDate === undefined ||
      state.scheduledStartDate === ""
    ) {
      setError({ scheduledStartDate: true });
      Toast(t("INVITATIONFORM011"), "error");
      return;
    }

    if (
      state.scheduledEndDate === null ||
      state.scheduledEndDate === undefined ||
      state.scheduledEndDate === ""
    ) {
      setError({ scheduledEndDate: true });
      Toast(t("INVITATIONFORM012"), "error");
      return;
    }

    if (new Date(state.scheduledStartDate) < new Date()) {
      Toast(t("INVITATIONFORM010"), "error");
      return;
    }

    if (new Date(state.scheduledStartDate) > new Date(state.scheduledEndDate)) {
      setError({ scheduledStartDate: true });
      Toast("Scheduled end date is greater than start time", "error");
      return;
    }

    if (new Date(state.scheduledStartDate) < new Date(state.scheduledEndDate)) {
      const formstate = {};
      formstate.scheduledStartDate = CommonUtil.formatToUtc(
        state.scheduledStartDate
      );
      formstate.scheduledEndDate = CommonUtil.formatToUtc(
        state.scheduledEndDate
      );
      formstate.id = state.id;
      dispatch(rescheduleVisitor(formstate))
        .unwrap()
        .then((data) => {
          if (data?.code === "CVAI0069") {
            handleClose(false);
            Toast(t("RESCHEDULEFORM001"), "success");
          } else {
            onFailure(data);
          }
        });
    }
  };

  return (
    <>
      <DialogBox
        Header="Reschedule Visitor"
        acceptText="Submit"
        cancelText="Cancel"
        fullWidth={true}
        onClose={() => handleClose(false)}
        cancel={handleClose}
        onSubmit={handleSumbit}
        open={open}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems={"center"}
          style={{ padding: "20px" }}
        >
            
          <Grid item marginTop={2} style={{width:'400px'}}>
            <CustomDateTimePicker
              onChange={(scheduledStartDate) =>
                setState({
                  ...state,
                  scheduledStartDate: scheduledStartDate,
                })
              }
              disablePast
              value={state.scheduledStartDate}
              label={"Invite Start Date *"}
            />
            </Grid>

            <Grid item style={{width:'400px'}} marginTop={"35px"}>
              <CustomDateTimePicker
                onChange={(scheduledEndDate) => (
                    setState({
                        ...state,
                        scheduledEndDate: scheduledEndDate,
                    })
                )
                }
                disablePast
                value={state.scheduledEndDate}
                //minDateTime={payload.scheduledStartDate}
                label="Invite End Date *"
              />
            </Grid>
                    
         
        </Grid>
      </DialogBox>
    </>
  );
}
