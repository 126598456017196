import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Avatar from '@mui/material/Avatar';
import '../../../src/components/VisitorActionsComponents/profileCard.css'
import { Divider, Grid } from '@mui/material';
import { getSiteById } from '../../slices/Site/SiteSlice';
import { useDispatch } from "react-redux";
import CommonUtil from '../../Util/CommonUtils';
import ViewIcon from '../../Pages/Invitations/ViewIcon';
import moment from 'moment';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Tooltip } from '@material-ui/core';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        overflowY: 'clip',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        overflowY: 'visible',
        borderRadius: '20px',
        minWidth: '120vh',
        height: '644px',
        opacity: 1,
    },
    '& .MuiDialogContent-root ': {
        padding: '16px 24px',
        position: ' relative',
        bottom: '90px',
    }
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 0, marginBottom: 1 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

function MissedViewProfile(props) {
    const [open, setOpen] = React.useState(false);
    const [siteName, setSiteName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [checkIn, setCheckIn] = useState('');
    const [endDate, setEndDate] = useState('');
    const [duration, setDuration] = useState('');
    const rowDataMode = props.rowData.visitMode
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        loadSiteName(props.rowData.siteId);
        setEndDate(moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate)).format("DD-MM-YYYY HH:mm"));
        setStartDate(moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledStartDate)).format("DD-MM-YYYY HH:mm"));
        setCheckIn(moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.checkIn)).format("DD-MM-YYYY HH:mm"));
        setTotalDuration(props.rowData);
    }, [])

    const loadSiteName = (siteId) => {
        dispatch(getSiteById(siteId))
            .unwrap().then((data) => {
                if (data?.code === 'CVAI0000') {
                    console.log(JSON.stringify(data))
                    setSiteName(data?.data?.name);
                }
                else {
                    setSiteName('');
                }
            })
    };

    const setTotalDuration = (params) => {
        let start = moment(params.checkIn);
        let end = moment(params.checkOut);
        let duration = moment.duration(end.diff(start));
        setDuration(Math.floor(duration.asHours()) + moment.utc(duration.asMilliseconds()+ (60000)).format(":mm"))
    }

    const handleTextOverflow = (text) => {
        if (text && text.length > 20) {
            return text.substring(0, 25) + '...';
        } else {
        return text;
    }
    }

    return (
        <div >
            <div onClick={() => {handleClickOpen()}} >
                <ViewIcon />

            </div>
            <Grid>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle onClose={handleClose} >
                        <Avatar alt={props.rowData.visitor.firstName} src={props.rowData.visitor.profilePhotoURL !== undefined ? props.rowData.visitor.profilePhotoURL : null} sx={{ width: 150, height: 150, bottom: '80px', margin: 'auto', border: 'solid #FFFF', borderWidth: '7px', }} />
                        <div className='CssTextProfileViewPageName'>
                        {props.rowData.visitor.lastName === null ? props.rowData.visitor.firstName: props.rowData.visitor.firstName + ' ' + props.rowData.visitor.lastName}
                        </div>
                        <div className='CssTextProfileViewPagePhone'>
                            {props.rowData.visitor.phone}
                        </div>
                    </BootstrapDialogTitle>
                    <Divider variant="inset" className='MuiDividerCss' />
                    <DialogContent style={{ marginLeft: '70px', marginRight: '70px' }}>
                    <PerfectScrollbar options={{ wheelPropagation: true }}>
                        < Grid container spacing={12.25} className='CssTextProfileViewPageContent' marginTop={0.5}>
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3} >Email</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Tooltip title={props.rowData.visitor.email}>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'> 
                                {handleTextOverflow(props.rowData.visitor.email)}
                                </Grid>
                                </Tooltip>
                            </Grid>
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3}>Host</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Tooltip title={props.rowData.hostName}>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {handleTextOverflow(props.rowData.hostName)}
                                    </Grid>
                                </Tooltip>
                            </Grid>
                            <Grid container spacing={2} p={1.25} >
                                <Grid item xs={3}>Purpose Type</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Tooltip title={props.rowData.purposeType?.name}>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {handleTextOverflow(props.rowData.purposeType?.name)}
                                    </Grid>
                                </Tooltip>
                            </Grid>
                    
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3}>Site</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Tooltip title={siteName}>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {handleTextOverflow(siteName)}
                                    </Grid>
                                </Tooltip>
                            </Grid>
                            <Grid
                                container spacing={2} p={1.25}>
                                <Grid item xs={3}>Company Name</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Tooltip title={props.rowData.visitor.visitorCompanyName}>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                        {handleTextOverflow(props.rowData.visitor.visitorCompanyName)}
                                </Grid>
                                </Tooltip>
                            </Grid>
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3} >Invite Start Date</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {/* {startDate} */}
                                    { (moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledStartDate)).format("DD-MM-YYYY HH:mm"))}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3} >Invite End Date</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {/* {endDate} */}
                                    { (moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.scheduledEndDate)).format("DD-MM-YYYY HH:mm"))}
                                </Grid>
                            </Grid>

                            {(props.rowData.status === "CHECKED_OUT") || (props.rowData.status === "CHECKED_IN") ?
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3} >Check In</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>  
                        {props.rowData.checkIn ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.checkIn)).format("DD-MM-YYYY HH:mm") : ""}           
                                </Grid>
                            </Grid>
                            : ""}

                            {props.rowData.status === "CHECKED_OUT" ?
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3} >Check Out</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>  
                        {props.rowData.checkOut ? moment(CommonUtil.getLocalDateTimeInYearFormat(props.rowData.checkOut)).format("DD-MM-YYYY HH:mm") : ""}           
                                </Grid>
                            </Grid>
                            : ""}

                    {props.rowData.status === "CHECKED_OUT" ?
                       <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3} >Total duration</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                   {duration}
                                </Grid>
                            </Grid> : "" }

                            {process.env.REACT_APP_ENVIRONMENT === 'USA' ?
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3}>Type</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{
                                    props.rowData.visitMode === 'WALKIN_REGISTARTION' ? 'Walk-In Registration' :
                                        props.rowData.visitMode === "INVITATION" ? "Invitation" :
                                            props.rowData.visitMode === "SELF_REGISTARTION" ? "Self Registration" :
                                                props.rowData.visitMode === "WALKIN_REGISTARTION_KIOSK" ? "Walkin Registration Kiosk" :
                                                    props.rowData.visitMode}</Grid>
                            </Grid>
                            :
                            <Grid container spacing={2} p={1.25}>
                            <Grid item xs={3}>Type</Grid>
                            <Grid item xs={3}>:</Grid>
                            <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>{
                                props.rowData.visitMode === 'WALKIN_REGISTARTION' ? 'Registration' :
                                    props.rowData.visitMode === "INVITATION" ? "Invitation" :
                                        props.rowData.visitMode === "SELF_REGISTARTION" ? "Self Registration" :
                                            props.rowData.visitMode === "WALKIN_REGISTARTION_KIOSK" ? "Walkin Registration Kiosk" :
                                                props.rowData.visitMode}</Grid>
                        </Grid>
                       
                         }
                             
                            {props.rowData.status === "CHECKED_IN" ?
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3}>Verify Type In</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {props.rowData.verifyTypeIn}
                                    </Grid>
                            </Grid>
                            : ""}

                            {props.rowData.status === "CHECKED_OUT" ?
                            <Grid container spacing={2} p={1.25}>
                                <Grid item xs={3}>Verify Type Out</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                                    {props.rowData.verifyTypeOut}
                                    </Grid>
                            </Grid>
                            : ""}



                            <Grid
                                container spacing={2} p={1.25}>
                                <Grid item xs={3}>Status</Grid>
                                <Grid item xs={3}>:</Grid>
                                <Grid item marginLeft={10} className='CssTextProfileViewPageContentValue'>
                             
                                <span style={{ color: '#EDBD37', backgroundColor: '#EDBD3726', opacity: 1 }}>
                                 {props.rowData.status === "SCHEDULED" ? "Scheduled": ""}
                                </span>

                                <span style={{ color: '#A6C212', backgroundColor: '#A6C21226', opacity: 1 }}>
                                 {props.rowData.status === "CONFIRMED" ? "Confirmed": ""}
                                </span>

                                <span style={{ color: '#E3393C', backgroundColor: '#E3393C26', opacity: 1 }}>
                                 { props.rowData.status === "CANCELLED" ? "Cancelled": ""}
                                </span>

                                <span style={{ color: '#E3393C', backgroundColor: '#E3393C26', opacity: 1 }}>
                                 {props.rowData.status === "REJECTED" ? "Rejected": ""}
                                </span>

                                <span style={{ color: '#287ADC', backgroundColor: '#287ADC26', opacity: 1 }}>
                                 {props.rowData.status === "CHECKED_IN" ? "Check In": ""}
                                </span>

                                <span style={{ color: '#03ACBF', backgroundColor: '#03ACBF26', opacity: 1 }}>
                                 {props.rowData.status === "CHECKED_OUT" ? "Check Out": ""}
                                </span>

                                <span style={{ color: '#36C96D', backgroundColor: '#36C96D26', opacity: 1 }}>
                                 {props.rowData.status === "APPROVED" ? "Approved": ""}
                                </span>

                                <span style={{ color: '#ED8637', backgroundColor: '#ED863726', opacity: 1 }}>
                                 {props.rowData.status === "MISSED" ? "Missed": ""}
                                </span>

                                <span style={{ color: '#D53033', backgroundColor: '#D5303326', opacity: 1 }}>
                                 {props.rowData.status === "BLOCKED" ? "Blacklist": ""}
                                </span>

                                <span style={{ color: '#D53033', backgroundColor: '#D5303326', opacity: 1 }}>
                                 {props.rowData.status === "INITIATED_FOR_BLOCKED" ? "Blacklist Initiated": ""}
                                </span>
                                                                
                              </Grid>
                            </Grid>

                        </Grid>
                        </PerfectScrollbar>
                    </DialogContent>
                </BootstrapDialog>
            </Grid>
        </div>
    );
}

export default MissedViewProfile