import api from '../../config/http-common';
import CommonUtil from "../../Util/CommonUtils";
const buildQuery = (filter) => {
  let query = "?isPagable=true&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;
  if (!CommonUtil.isEmptyString(filter.name)) {
      query = query + "&name=" + filter.name;
  }
  return query;
};

const listChecklistTemplates = (filter) => {
  return api.securedAxios().get('/web/getCheckListTemplates' + buildQuery(filter));
}

const addChecklistTemplate = (payload) => {
  return api.securedAxios().post("/web/createCheckListTemplate", payload);
};

const updateChecklistTemplate = (payload) => {
  return api.securedAxios().put("/web/updateCheckListTemplate/" + payload.id, payload);
}

const buildQueryItem = (filter) => {
  let query = "&pageNumber=" + filter.pageNumber + "&pageSize=" + filter.pageSize;
  return query;
};

const getChecklistItems = (filter) => {
  return api.securedAxios().get('/web/getHealthQuestionarie?templateId=' +  filter.templateId + buildQueryItem(filter));
}

const addChecklistItem = (payload) => {
  return api.securedAxios().post('/web/createHealthQuestionarie', payload);
};

const editChecklistItem = (payload) => {
  return api.securedAxios().put('/web/updateHealthQuestionarie/' + payload.id, payload);
}

const deleteChecklistItem = (payload) => {
  return api.securedAxios().delete("/web/deleteHealthQuestionarie?id=" + payload);
}

const QRCode = (code) => {
  return api.unsecuredAxios().get('/visitor_workflow/visit_registration/' + code + '/qrcode');
}

const ChecklistTemplateService = {
    listChecklistTemplates,
    addChecklistTemplate,
    updateChecklistTemplate,
    getChecklistItems,
    addChecklistItem,
    editChecklistItem,
    deleteChecklistItem,
    QRCode,
};

export default ChecklistTemplateService;