import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import TimeSlotService from '../../services/TimeSlotService/TimeSlotService';

const initialState = [];

export const timeSlotList = createAsyncThunk('timeSlot/list', async (payload) => {
    const res = await TimeSlotService.timeSlotList(payload);
    return res.data;
});

export const timeSlotListPro = createAsyncThunk('timeSlot/list', async (payload) => {
    const res = await TimeSlotService.timeSlotListPro(payload);
    return res.data;
});


export const deleteTimeSlot = createAsyncThunk('timeSlot/delete', async (payload) => {
    const res = await TimeSlotService.deleteTimeSlot(payload);
    return res.data;
});

export const saveTimeSlot = createAsyncThunk('saveTimeSlot/list', async (payload) => {
    const res = await TimeSlotService.saveTimeSlot(payload);
    return res.data;
});

export const updateTimeSLots=createAsyncThunk('update/Time', async(payload)=>{
    const res= await TimeSlotService.updateTimeSLots(payload);
    return res.data;
})

export const getTimeSlotById=createAsyncThunk('get/timeSlot', async(paylaod)=>{
    const res=await TimeSlotService.getTimeSlotById(paylaod);
    return res.data;
})

const TimeSlotSlice = createSlice({
    name: 'timeSlot',
    initialState,
    extraReducers: {
    
    }
})

const { reducer } = TimeSlotSlice;
export default reducer;