import React, { useState } from "react";
import PropTypes from "prop-types";
import PhoneInput from "react-phone-input-vinay";
import "react-phone-input-2/lib/material.css";
// import "react-phone-input-2/lib/bootstrap.css";
import { Grid } from "@material-ui/core";

export default function CustomPhone({
  isValid,
  value,
  handleChange,
  specialLabel,
  disabled,
  placeholder
}) {
  const zlink = localStorage.getItem("redirectFrom");

  return !zlink ? (
    <>
      <Grid>
        <PhoneInput
          fullWidth
          placeholder={placeholder}
          specialLabel={specialLabel}
          searchPlaceholder=""
          countryCodeEditable={true}
          enableSearch="true"
          disabled={disabled}
          disableCountryCode={false}
          enableAreaCodes={false}
          isValid={isValid}
          country={process.env.REACT_APP_ENVIRONMENT === 'USA'? "us" : ""}
          value={value}
          onChange={handleChange}    
          inputStyle={{
            width: "100%",
            height: "56px",
          }}
        />
      </Grid>
    </>
  ) : (
    <>
      <Grid>
      <PhoneInput
          fullWidth
          placeholder={placeholder}
          specialLabel={specialLabel}
          searchPlaceholder=""
          countryCodeEditable={true}
          enableSearch="true"
          disabled={disabled}
          disableCountryCode={false}
          enableAreaCodes={false}
          isValid={isValid}
          country={"af"}
          value={value}
          onChange={handleChange}    
          inputStyle={{
            width: "100%",
            height: "56px",
          }}
        />
      </Grid>
    </>
  );
}
CustomPhone.propType = {
  value: PropTypes.any,
  isValid: PropTypes.bool,
  handleChange: PropTypes.func,
};
