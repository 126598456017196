import {
  Tooltip,
  Grid,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  IconButton,
  Autocomplete,
  Typography,
} from "@mui/material";
import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import SubmitButtons from "../../components/DialogBoxComponent/SubmitButtons";
import CancelButtons from "../../components/DialogBoxComponent/CancelButtons";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { listVisitorTypeZlink } from "../../slices/PerpouseType/PerpouseTypeSlice";
import Toast from "../../components/ToastContainer/CustomToast";
import CustomTextfield from "../../components/CustomInputs/CustomTextfield";

export default function FilterBlockedVisitors(props) {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [payload, setPayload] = useState({
    purposeTypeId: "",
    status: props.status,
    visitMode: props.selectedObject,
  });

  const [selectedPurpose, setSelectedPurpose] = useState(null);
  
  const handleClose = () => {
    setPayload({
      purposeTypeId: "",
      status: props.status,
      visitMode: props.selectedObject,
    });
    setSelectedPurpose(null)
    setOpen(false);
  };

  const [error, setError] = useState({
    purposeTypeId: false,
  });

  const reset = () => {

    if (
      selectedPurpose === null
    ) {
      Toast(t("COMMON027"), "error")
      return;
    }

    if (selectedPurpose !== null) {
      setSelectedPurpose(null)
    }

    setPayload({
      purposeTypeId: "",
      status: props.status,
      visitMode: props.selectedObject,
    });
  };



  const handleChange = (event) => {
    const name = event.target.name;
    setPayload({
      ...payload,
      [name]: event.target.value,
    });

    setError({
      [name]: false,
    });
  };

  const handleSubmit = () => {
    if (
      payload.purposeTypeId === "" 
    ) {
      Toast(t("COMMON015"), "error")
      return;
    }

    props.applyFilter(payload);
    setPayload({
      purposeTypeId: "",
      visitMode: props.selectedObject,
      status: props.status,
    });
    handleClose();
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    loadVisitType();
  };

  const loadVisitType = () => {
    const query = {
      id: "",
      name: "",
      pageNumber: 1,
      pageSize: 0,
      isPageable: true,
    };

    dispatch(listVisitorTypeZlink(query))
      .unwrap()
      .then((data) => {
        if (data?.code === "CVAI0000") {
          setData(data?.data?.purposeTypes);
        } else {
          setData([]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const selectedPurposeType = (newValue) => {
    if (newValue != null) {
      setPayload({
        ...payload,
        purposeTypeId: newValue.id
      })
    }
    else {
      setPayload({
        ...payload,
        purposeTypeId: ''
      })
    }
  }

  return (
    <>
      <Tooltip title={<Typography>Filter</Typography>}>
        <FilterIcon onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>
          <FilterBlockedVisitors />
        </FilterIcon>
      </Tooltip>
      <Grid item xs={6}>
        <Dialog open={open} onClose={handleClose} fullWidth={true}>
          <DialogTitle variant="h4" onClick={handleClose}>
            <IconButton
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 15,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            Filter
          </DialogTitle>
          <Divider variant="middle" />
          <DialogContent>
            <Grid
              container
              justifyContent="center"
              alignItems={"center"}
            >
              <Grid item xs={12} sm={9} style={{ marginTop: "10px" }}>
                <Autocomplete
                  fullWidth
                  noOptionsText={'No Options found'}
                  options={data}
                  getOptionLabel={(option) =>
                    option.name ? option.name : ""
                  }
                  value={selectedPurpose}
                  onChange={(event, newValue) => {
                    setSelectedPurpose(newValue)
                    selectedPurposeType(newValue)
                    setError({ purposeTypeId: false })
                  }}
                  renderInput={(params) => (
                    <CustomTextfield
                      {...params}
                      label="PurposeType"
                      required={false}
                      palceholder="PurposeType"
                      name="id"
                      error={error.purposeTypeId}
                    />
                  )}
                />
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions style={{
            justifyContent: "center",
          }}>
            <SubmitButtons variant='contained' onClick={handleSubmit}>
              <Typography variant="body1" style={{ textTransform: "none" }}>
                Apply
              </Typography>
            </SubmitButtons>
            <CancelButtons variant='contained' color="secondary" onClick={reset}>
              <Typography variant="body1" style={{ textTransform: "none" }}>
                Reset
              </Typography>
            </CancelButtons>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}
