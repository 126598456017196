import api from "../../config/http-common";
import CommonUtil from "../../Util/CommonUtils";

const buildQuery = (filter) => {

    let query = "?isPagable=true&sort=createdAt%7Cdesc&caseSensitive=true&operator=AND&pageNumber=" + filter.page + "&pageSize=" + filter.rowsPerPage;

    if (!CommonUtil.isEmptyString(filter.firstName)) {
        query = query + "&firstName=" + filter.firstName;
    }
    if (!CommonUtil.isEmptyString(filter.lastName)) {
        query = query + "&lastName=" + filter.lastName;
    }
    if (!CommonUtil.isEmptyString(filter.email)) {
        query = query + "&email=" + filter.email;
    }
    if (!CommonUtil.isEmptyString(filter.phone)) {
        query = query + "&phone=" + filter.phone;
    }
    if (!CommonUtil.isEmptyString(filter.code)) {
        query = query + "&code=" + filter.code;
    }
    return query;

    if (!CommonUtil.isEmptyString(filter.gender)) {
        query = query + "&gender=" + filter.gender;
    }
    if (!CommonUtil.isEmptyString(filter.departmentId)) {
        query = query + "&departmentId=" + filter.departmentId;
    }
    if (!CommonUtil.isEmptyString(filter.designationId)) {
        query = query + "&designationId=" + filter.designationId;
    }

    if (!CommonUtil.isEmptyString(filter.roleIdentifier)) {
        query = query + "&roleIdentifier=" + filter.roleIdentifier;
    }
    if (!CommonUtil.isEmptyString(filter.userId)) {
        query = query + "&userId=" + filter.userId;
    }
    if (!CommonUtil.isEmptyString(filter.status)) {
        query = query + "&status=" + filter.status;
    }
    if (!CommonUtil.isEmpty(filter.createdAt)) {
        query = query + "&createdAt=" + filter.createdAt;
    }
    if (!CommonUtil.isEmpty(filter.updatedAt)) {
        query = query + "&updatedAt=" + filter.updatedAt;
    }
    return query;
};

const getPersonList = (filter) => {
    return api.securedAxios()
        .post(
            "/web/employees/list" +
            buildQuery(filter)
        );
}

const createPerson = (payload) => {
    return api
        .securedAxios()
        .post("/web/employee", payload);
};

const updatePerson = (payload) => {
    return api.securedAxios()
        .put('/web/employee/' + payload.id, payload);
};


const activateAccount = (payload) => {
    return api
        .securedAxios()
        .post("/api/v2.0/user/activate", payload);
};

const checkIsMemeberActive = (payload) => {
    return api
        .securedAxios()
        .get("/api/v2.0/checkMembership/" + payload.userId);
};

const deletePerson = (payload) => {
    return api.securedAxios()
        .delete('/web/employee/' + payload.id);
};

const getPersonAddress = (id) => {
    return api.securedAxios().get('/web/employees/' + id);
};

const validateActivationCode = (payload) => {
    return api.unsecuredAxios().put('/onboard/user/' + payload.code + '/activate', payload);
};

const validateCode = (payload) => {
    return api.unsecuredAxios().get('/onboard/user/' + payload);
};

const manageUserrole = (payload) => {
    return api.securedAxios().post('/web/zlink/role/'+payload.role,payload.payload);
};

const PersonService = {
    getPersonList,
    createPerson,
    updatePerson,
    activateAccount,
    deletePerson,
    getPersonAddress,
    validateActivationCode,
    validateCode,
    checkIsMemeberActive,
    manageUserrole  
}

export default PersonService;