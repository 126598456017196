import React, { useState } from "react";
import PropTypes from "prop-types";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'

import { Grid } from '@material-ui/core';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

export default function CustomPhoneInput({ isValid, value, handleChange, specialLabel }) {
    return (
        <Grid xs={{ display: 'flex' }}>
            {/* <Grid className="phone_icon">
                <PhoneAndroidIcon className='visibleIcons' />
            </Grid> */}
            <PhoneInput
                fullWidth
                specialLabel={specialLabel}
                searchPlaceholder=''
                countryCodeEditable={true}
                enableSearch="true"
                disabled={false}
                disableCountryCode={false}
                enableAreaCodes={false}
                isValid={isValid}
                country={process.env.REACT_APP_ENVIRONMENT === 'USA'? "us" : ""}
                value={value}
                onChange={(e) => {handleChange(e)}}
            />
        </Grid>
    );
}
CustomPhoneInput.propType = {
    value: PropTypes.any,
    isValid: PropTypes.bool,
    handleChange: PropTypes.func,
}
