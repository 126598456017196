import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import LoginService from '../../services/onboard/LoginService';
import jwt_decode from 'jwt-decode';
import { REACT_LOGIN } from '../../actions/EndPoints';

let initialState = {
    tokenRefreshTimer: 600000,
    security: {},
};

export const authorizeUser = createAsyncThunk('user/login', async (payload) => {
    const res = await LoginService.create(payload);
    return res.data;
});

export const revokeUser = createAsyncThunk('user/revoke', async () => {
    const res = await LoginService.revoke();
    return res.data;
});

export const getCompanies = createAsyncThunk('user/companies', async () => {
    const res = await LoginService.getCompanies();
    return res.data;
});

export const switchCompanies = createAsyncThunk('user/companies', async () => {
    const res = await LoginService.switchCompanies();
    return res.data;
});

export const callRefreshToken = createAsyncThunk('user/refreshToken', async (payload) => {
    const res = await LoginService.refreshToken(payload);
    return res.data;
});

export const switchCompany = createAsyncThunk('user/switchCompany', async (payload) => {
    const res = await LoginService.switchCompany(payload);
    return res.data;
});

export const selectCompany = createAsyncThunk('user/switchCompany', async (payload) => {
    const res = await LoginService.selectCompany(payload);
    return res.data;
});

export const createUser = createAsyncThunk('user/create', async (payload) => {
    const res = await LoginService.createUser(payload);
    return res.data;
});

const loginSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: {
        [authorizeUser.fulfilled]: (state, action) => {
            if (action.payload.code === 'CVAI0000') {
                return action.payload.data;
            } else {
                return [];
            }
        },
        [switchCompany.fulfilled]: (state, action) => {
            if (action.payload.code === 'UASI0011') {
                let token = jwt_decode(action.payload.data.access_token);
                state.security = token;
                state.tokenRefreshTimer = 600000;
            } else {
                state.security = {};
            }
        },
        [callRefreshToken.fulfilled]: (state, action) => {
            if (action.payload.code === 'CVAI0117') {
                localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN, action.payload.data.access_token);
                localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN, action.payload.data.refresh_token.split(' ')[1]);
                state.security = jwt_decode(action.payload.data.access_token);
            } else {
                state.security = {};
            }
        }
    },
})

const { reducer } = loginSlice;
export default reducer;