import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { getPermissionsListByroleId } from '../../slices/RoleandPermission/RoleandPermissionSlice';
import { useDispatch } from 'react-redux';
import CustomizedAccordions from './CustomizedAccordion';
import TitleBar from '../../components/v4/TitleBar';
import { defaultPermissionCode } from './DefaultPermissionCode';
import { useTranslation } from "react-i18next";

export default function RoleAndPermissionView(props) {

  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [permissions, setPermissions] = useState(defaultPermissionCode);
  const defaultPermissions = useState(defaultPermissionCode);

  useEffect(() => {
    dispatch(getPermissionsListByroleId(location?.state?.state.id)).unwrap()
    .then((data) => {
      if (data?.code === "CVRP0001") {
        setData(data?.data?.rolePermissions);
      }
    });
  }, []);

  useEffect(() => {
    if (data instanceof Array && data.length > 0) {
      var newPermissions = { ...permissions };
      data.map((data) => {
        newPermissions[data.code] = true;
      });
      setPermissions({
        ...permissions,
        ...newPermissions,
      });
    } else {
      setPermissions({
        ...defaultPermissions,
      });
    }
  }, [data]);

  return (
    <div>
  
    <Grid>
       <TitleBar title={t('View Role and Its Permissions ')} overflowY={"hidden"} back_button={true} />   
          <Box item flexGrow={1} paddingBottom={2} paddingTop={2}>
             <Typography variant="h4" style={{color: '#3D4977', fontSize: '20px', fontWeight:'500'}}>
                  User Role
             </Typography>
          </Box>
       <Paper elevation={0} style={{ backgroundColor: '#ffffff', borderRadius: '8px', overflow: 'hidden', margin: '12px' }}>
          <Box display='flex' padding={2}>
              <Box item paddingRight={1} minWidth='150px'>
                 <Typography variant="h5" style={{color: '#B8B8B8', fontSize: '16px', fontWeight:'420'}}>User Role Name :</Typography>
              </Box>
            <Box item>
              <Typography variant="h5" style={{color: '#3D4977', fontSize: '16px', fontWeight:'480'}}>
                  {location?.state?.state?.name}
              </Typography> 
            </Box>
          </Box>
          <Box display='flex' padding={2} paddingBottom={2}>
               <Box item paddingRight={1} minWidth='150px'>
                   <Typography variant="h5" style={{color: '#B8B8B8', fontSize: '16px', fontWeight:'420'}}>User Role Code :</Typography>
                </Box>
               <Box item>
                   <Typography variant="h5" style={{color: '#3D4977', fontSize: '16px', fontWeight:'480'}}>
                         {location?.state?.state?.code}
                   </Typography>
               </Box>
          </Box>
      </Paper>
          <Box item flexGrow={1} paddingBottom={2} paddingTop={2}>
              <Typography variant="h5" style={{color: '#3D4977', fontWeight:'410'}}>
                     Permission for this User Role in Cloud Visitor
              </Typography>
          </Box>
        <CustomizedAccordions style = {{color: '#B8B8B8'}} permissions={permissions} role={location?.state?.state?.code} setPermissions={(v) => setPermissions(v)} disabled={true} />
    </Grid>

    </div>
  );
}
