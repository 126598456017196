import { Grid} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import CommonUtil from '../../Util/CommonUtils';
import DialogBox from "../../components/DialogBoxComponent/DialogBox";
import jwt_decode from "jwt-decode";
import CustomTextfeild from '../../components/CustomInputs/CustomTextfield'
import Toast from "../../components/ToastContainer/CustomToast";
import { createRestrictType, updateRestrictType } from '../../slices/RestrictType/RestirctTypeSlice';

export default function AddRestrictType(props) {
    const { open, handleClose } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
  
    useEffect(() => {
        if (props.selectedObject.id) {
            setState({
                ...state,
                id: props.selectedObject.id,
                name: props.selectedObject.name,
                description: props.selectedObject.description,

            });
        }
    }, []);

    const [state, setState] = React.useState({
        id: '',
        name: '',
        description: '',
    });

    const [error, setError] = useState({
        name: false,
        description: false,

    });

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
        setError({
            [name]: false,
        });
    };

    const handleSumbit = () => {

        if (state.name === '' || state.name === undefined) {
            setError({
                name: 'error'
            })
            Toast('Please Enter Restrict Name', 'error')
        }
       
        else if (!CommonUtil.validateName(state.name)) {
            Toast(t('nameValidation'), 'error')
        }
        else if (CommonUtil.checkCharactersGreaterThan50(state.name)) {
            Toast(t("NAMELENGTH"), 'error')
        }
        else if ((state.description !== undefined || state.description !== '' ) &&
         CommonUtil.checkCharactersGreaterThan250(state.description)) {
            Toast(t("RESTRICTERROR003"), 'error')
        }
    
      else  if (CommonUtil.isEmptyString(props.selectedObject.id)) {
            dispatch(createRestrictType(state))
                .unwrap().then((data) => {
                    if (data?.code === 'CVAI0059') {
                        Toast('Restrict Type Created Successfully', "success");
                        handleClose();
                    } else {
                        onError(data);
                    }
                });
        } else {
            dispatch(updateRestrictType(state))
                .unwrap().then((data) => {
                    if (data?.code === 'CVAI0060') {
                        Toast('Restrict Type Updated Successfully', 'success');
                        handleClose();
                    } else {
                        onError(data);
                    }
                });
        }
    };

    const onError = (data) => {
        if (data)
            switch (data.code) {
                case "CVAE1015":
                    setError({ emailId: true });
                    break;
                default:
                    setError({ ...error });
            }
        Toast(data.message,"error");
    };

    return (
        <DialogBox Header={state.id ? "Update Restrict Type" : "Add Restrict Type"}
            acceptText={'Submit'} cancelText={'Cancel'}
            style={{ color: '#3D4977', opacity: 1, minHeight: '' }}
            fullWidth={true} maxWidth={"sm"} open={open} onClose={handleClose}
            cancel={handleClose} onSubmit={handleSumbit}>
            <Grid
                container
                justifyContent="center"
                style={{ padding: "16px 32px", width: '100%', }}
                spacing={2}
            >

                <Grid item xs={12} xm={10} >
                    <CustomTextfeild
                        error={error.name}
                        name='name'
                        label='Name *'
                        helperText=''
                        value={state.name}
                        handleChange={(e) => handleChange(e)}
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
                
                <Grid item xs={12} xm={10} >
                    <CustomTextfeild
                        error={error.description}
                        name='description'
                        label={t('RestrictTypeForm005')}
                        helperText=''
                        value={state.description}
                        handleChange={(e) => handleChange(e)}
                        inputProps={{ maxLength: 120 }}
                    />
                </Grid>
            </Grid>
        </DialogBox>
    )
}