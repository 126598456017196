import { Button, Grid, TextareaAutosize, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import DataTable from '../../components/DataTable/DataTable';
import HeaderToolbar from '../../components/HeaderToolbar';
import Toast from '../../components/ToastContainer/CustomToast';
import { createUrgentMessage, listUrgentMessages } from '../../slices/UrgentMessages/UrgentMessagesSlice';
import CommonUtil from '../../Util/CommonUtils';
import sendIconBlack from '../../../src/assets/images/UrgentMessages/sendIconBlack.svg';
import sendIconWhite from '../../../src/assets/images/UrgentMessages/sendIconWhite.svg';
import moment from "moment";
import { getPermissionsListByroleId } from '../../slices/RoleandPermission/RoleandPermissionSlice';
import api from '../../config/http-common';
import { useTranslation } from "react-i18next";

function UrgentMessages() {

  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(1);
  const [changeImage, setChangeImage] = useState(false);

  const [roles, setRoles] = useState([]);
  const [addUrgentMessages, setAddUrgentMessages] = useState(true);
  const [listUrgentMsg, setListUrgentMsg] = useState(true);

  const [paging, setPaging] = useState({
    page: 1,
    size: 10,
  });

  const [message, setMessage] = useState({
    message: ''
  });

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      size: size,
    })
  };

  const handleTextChange = (event) => {
    const name = event.target.name;
    setMessage({
      ...message,
      [name]: event.target.value,
    });
  }

  useEffect(() => {
    loadData(paging);
  }, [paging]);

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setAddUrgentMessages(l.includes("CREATE_URGENT_MESSAGES") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;
        setListUrgentMsg(l.includes("VIEW_URGENT_MESSAGES") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN')) ;

        if (l.includes("VIEW_URGENT_MESSAGES")) {
          const filter = {
            pageNumber: paging.page,
            pageSize: paging.size
          }
          dispatch(listUrgentMessages(filter))
            .unwrap()
            .then((data) => {
              if (data?.code === 'CVAI0000' && data.data) {
                setRows(data?.data?.urgentMessages);
                setPage(data?.data?.curPage);
                setTotalPages(data?.data?.totalPages);
              }else {
                setRows([]);
              }
            })
            .catch((er) => { });
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("VIEW_URGENT_MESSAGES") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);

  const loadData = (paging) => {
    const filter = {
      pageNumber: paging.page,
      pageSize: paging.size
    }
    dispatch(listUrgentMessages(filter))
      .unwrap()
      .then((data) => {
        if (data?.code === 'CVAI0000' && data.data) {
          setRows(data?.data?.urgentMessages);
          setPage(data?.data?.curPage);
          setTotalPages(data?.data?.totalPages);
        }
      })
      .catch((er) => { });
  };

  const createMessage = () => {
    if (CommonUtil.isEmptyString(message.message)) {
      Toast('Please enter urgent message!', 'error');
      return;
    }
    dispatch(createUrgentMessage(message))
      .unwrap()
      .then((data) => {
        if (data?.code === 'CVAI0077' && data?.data) {
          setMessage({ ...message, message: '' });
          Toast((data?.message), 'success');
          loadData(paging);
        }
        else
          Toast(data?.message, 'error');
      })
      .catch((er) => { });
  }

  const columns = React.useMemo(() => [
    {
      headerName: "",
      sortable: false,
      width: 5,
      renderCell: (params) => {
        return <div></div>;
      },
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.message,
    },
    {
      field: "Created By",
      headerName: "Created By",
      flex: 1,
      width: 10,
      valueGetter: (params) => params.row.createdByName,
    },
    {
      field: "Created Time",
      headerName: "Created Time",
      flex: 1,
      width: 10,
      valueGetter: (params) => moment(CommonUtil.getLocalDateTimeInYearFormat(params.row.createdAt)).format("DD-MM-YYYY HH:mm"),
    },
  ], []);


  const handleOnMouseEnter = () => {
    setChangeImage(true);
  }

  const handleOnMouseLeave = () => {
    setChangeImage(false);
  }

  return (
    // <div style={{ padding: "0px 20px" }}>
    <div>

      <HeaderToolbar title="Urgent Messages" />

    {addUrgentMessages &&
      <Grid container style={{ padding: "20px 5px" }}>
        <Grid item sm={12} md={10} lg={11}>
          {message.message === '' ? <div style={{ position: 'absolute', color: '#A7A7A7', fontSize: 'large', left: '20px', top: '10px' }}>
          </div> : <></>}
          <Grid style={{ position: 'absolute', bottom: '10px', right: '130px', color: '#A7A7A7' }}>Maximum 300 Characters</Grid>
          <TextareaAutosize
            minRows={3}
            maxlength="300"
            placeholder="Please Enter Urgent Message"
            name='message'
            value={message.message}
            onChange={handleTextChange}
            style={{
              width: '95%',
              borderRadius: '8px',
              borderColor: '#C6C6C6',
              resize: 'none',
              padding: '10px'
            }}
          />
        </Grid>
        
        <Grid item sm={12} md={2} lg={1} sx={{ alignSelf: 'flex-end', float: 'left', writingMode: 'vertical-rl' }}>
          <Tooltip title="Send Message" arrow>
            <Button onClick={createMessage} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} sx={[
              { backgroundColor: process.env.REACT_APP_BG_ICON },
              (theme) => ({
                '&:hover': {
                  background: '#EDEEF3',
                },
              }),
            ]}>
              {!changeImage ? <img src={sendIconWhite} style={{ padding: '2px' }} /> : <img src={sendIconBlack} style={{ padding: '2px' }} />}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    }
    
    { listUrgentMsg ? (
      <div style={{ height: 300, width: "100%" }}>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.id}
          pageCount={totalPages}
          pageSize={paging.size}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
      </div>
          ):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
              <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                  {t('CVROLE024')}
              </Typography>
            </div>)
    }     

    </div>
  )
}

export default UrgentMessages







