import { DialogContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DeleteDialogBox from '../../components/DialogBoxComponent/DeleteDialogBox'
import { ReactComponent as DeleteVisitIcon } from "../../../src/assets/DeleteVisit.svg";
import { useDispatch } from 'react-redux';
import { deleteDoorsFromAccessLevel } from '../../slices/VisitorAccessLevels/VisitorAccessLevelSlice';
import { toast } from 'react-toastify';
import Toast from '../../components/ToastContainer/CustomToast';

export const DeleteDoor = (props) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(props.open);
    const dispatch=useDispatch();

    const handleSubmit = () => {
        const payload={
         accessGroupId:props.doorData.accGroupId,
         doorId:[props.doorData.doorId]
        }
       dispatch(deleteDoorsFromAccessLevel(payload)).unwrap().then((data) => {
         if (data?.code === "DMSI0011") {
           Toast("Door deleted successfully.", "success");
           props.reloadlist(true);
           handleClose();
         } else {
          Toast(data.msg, "error");
         }
       })
     }

    const handleClose = () => {
        props.onClose(!open);
    }

  return (
    <div>
         <DeleteDialogBox
                Header="Delete"
                acceptText="Confirm"
                cancelText="Cancel"
                fullWidth={true}
                onClose={handleClose}
                cancel={handleClose}
                onSubmit={handleSubmit}
                open={open}
            >
                <DialogContent sx={{ textAlign: 'center' }}>
                    <Grid >
                        <DeleteVisitIcon />
                    </Grid>
                    <Grid p={2} style={{ display: 'flex', justifyContent: 'center', paddingTop: '10px' }}>
                        <Typography gutterBottom>
                            {props.text + props.name}
                        </Typography> &nbsp;
                        <Typography style={{ fontWeight: "bold" }}>
                            {props.doorData.name} ?
                        </Typography>
                    </Grid>
                </DialogContent>
            </DeleteDialogBox>
    </div>
  )
}
