import React from "react";
import { toast } from "react-toastify";

const Toast = (message, type) => {
    switch (type) {
        case "success":
            return toast.success((
                <>
                    <p>{message}</p>
                </>
            ), { closeButton: false, hideProgressBar: true});
        case "error":
            return toast.error((
                <>
                    <p>{message}</p>
                </>
            ), { closeButton: false, hideProgressBar: true});
        case "warning":
            return toast.warning((
                <>
                    <p>{message}</p>
                </>
            ), { closeButton: false, hideProgressBar: true});
    }
};
export default Toast;
