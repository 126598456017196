

import api from '../../config/http-common';
import CommonUtil from '../../Util/CommonUtils';
import { JAVA_URL_DASHBOARD_STATICS, JAVA_URL_DASHBOARD_TODAYS_VISITORS } from '../EndPoints';

const getTodaysVisitors = (payload) => {
  return api.securedAxios().get(JAVA_URL_DASHBOARD_TODAYS_VISITORS + buildQuery(payload.filter, payload.page, payload.rowsPerPage));
};

const getDashBoardStatics = (filter) => {
  return api.securedAxios().get('/web/dashboard/statics?scheduledEndDate=' + filter.scheduledEndDate + '&scheduledStartDate=' + filter.scheduledStartDate);
};

const buildQuery = (filter, page, rowsPerPage) => {
  let query = "?isPagable=true&pageNumber=" + page + "&pageSize=" + rowsPerPage;

  if (!CommonUtil.isEmptyString(filter.purposeTypeId)) {
    query = query + "&purposeTypeId=" + filter.purposeTypeId;
  }
  if (!CommonUtil.isEmptyString(filter.hostId)) {
    query = query + "&hostId=" + filter.hostId;
  }
  if (!CommonUtil.isEmpty(filter.status)) {
    query = query + "&status=" + filter.status;
  }
  if (!CommonUtil.isEmpty(filter.visitMode)) {
    query = query + "&visitMode=" + filter.visitMode;
  }
  if (!CommonUtil.isEmptyString(filter.scheduledEndDate)) { 
    query = query + "&scheduledEndDate=" + filter.scheduledEndDate;
  }
  if (!CommonUtil.isEmptyString(filter.scheduledStartDate)) {
    query = query + "&scheduledStartDate=" + filter.scheduledStartDate;
  }
  
  return query;
};

const DashBoardService = {
  getTodaysVisitors,
  getDashBoardStatics,
};

export default DashBoardService;