import { GridActionsCellItem } from '@mui/x-data-grid-pro';
import React from 'react'
import DataTable from '../../components/DataTable/DataTable';
import TitleBar from '../../components/v4/TitleBar';
import Search from "../../components/SearchTab/Search";
import { Box, IconButton } from '@mui/material';
import { ReactComponent as FilterIcon } from "../../../src/assets/FilterIcon.svg";
import { ReactComponent as Refresh } from "../../assets/DeviceIcon/Refresh.svg";
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { AddDoorToAccessLevel, DoorList } from '../../slices/DoorSlice/DoorSlice';
import { useEffect } from 'react';
import AddDeviceDialog from './AddDeviceDialog';
import Toast from '../../components/ToastContainer/CustomToast';
import { REACT_MANAGE_ACCESSLEVELS } from "../../actions/EndPoints";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CommonUtil from '../../Util/CommonUtils';

function AddDeviceToAccessLevel(props) {
  const [totalRecords, setTotalRecords] = useState(1);
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();
  const [rowData, setRowData] = useState([]);
  const [paging, setPaging] = useState({
    page: 0,
    size: 10,
  });
  const [totalPages, setTotalPages] = useState(0);
  const previousState = props.history.location.state;

  const handleChange = (newPage, size) => {
    setPage(newPage);
    setPaging({
      page: newPage,
      size: size,
    });
  };

  const handleAddDevice = (data) => {
    const payload = {
      accessGroupId: previousState.id,
      doors: [data.doorId]
    }
    dispatch(AddDoorToAccessLevel(payload))
      .unwrap()
      .then((data) => {
        if ((data?.code === "DMSI0010")) {
          Toast("Access Level Door Added Successfully", 'success')
          history.push({ pathname: REACT_MANAGE_ACCESSLEVELS, state: { data: previousState } })
        } else {
          Toast(data.code, 'error')
        }
      })
      .catch((er) => { });
  }

  useEffect(() => {
    loadData(paging);
  }, [paging]);

  const globalsearch = (searchedVal) => {

    if (!searchedVal) {
      loadData(paging);
      return;
    }

    const filteredRows = rows.filter((test) => {
      return (test.doorName !== undefined ? test.doorName.toLowerCase().includes(searchedVal.toLowerCase()) :
        test.doorName ? test.doorName.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || (test.siteName ? test.siteName.toLowerCase().includes(searchedVal.toLowerCase()) : "")
        || (test.zoneName ? test.zoneName.toLowerCase().includes(searchedVal.toLowerCase()) : "")

    });
    setRows(filteredRows);
  };

  const loadData = (paging) => {
    dispatch(DoorList(paging))
      .unwrap()
      .then((data) => {
        // console.info(data.data)
        if ((data?.code === "DMSI0000" && data?.data)) {
          setRows(data?.data?.doors);
          setPage(data?.data?.currentPage);
          setTotalPages(data?.data?.totalPages);
        }
      })
      .catch((er) => { });
  };

  const columns = React.useMemo(
    () => [
      {
        headerName: "",
        sortable: false,
        width: 5,
        renderCell: (params) => {
          return <div></div>;
        },
      },
      {
        field: "doorName",
        headerName: "Door name",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.doorName,
      },
      {
        field: "siteName",
        headerName: "Site name",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.siteName,
      },
      {
        field: "zoneName",
        headerName: "Zone name",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.zoneName,
      },
      {
        field: "deviceproperty",
        headerName: "Properties",
        flex: 1,
        width: 10,
        valueGetter: (params) => params.row.deviceproperty,
      },
      {
        field: "Actions",
        headerName: "Action",
        type: "actions",
        flex: 1,
        minWidth: 190,
        width: 150,
        headerAlign: "center",
        getActions: (params) => [
          <GridActionsCellItem
            icon={
              <AddDeviceDialog
                data={params.row}
                handleDevice={() => {
                  handleAddDevice(params.row);
                }}
              />
            }
          />,
        ],
      },
    ],
    []
  );

  return (
    <div><><TitleBar title={"Add Door"} back_button={true} />
    </>
      <div style={{ width: "100%" }}>

        <Box
          display={"flex"}
          width={"100%"}
          flexDirection={"row-reverse"}
          alignItems={"center"}
        >
          {/* <IconButton>
            <FilterIcon />
          </IconButton> */}

          <Box style={{ textAlign: "-webkit-right", padding: "5px", margin: "5px", }}>
            {/* <Tooltip title={"Refresh Device"}>*/}
            <IconButton onClick={() => loadData(paging)}>
              <Refresh />
            </IconButton>
            {/* </Tooltip> */}
          </Box>

          <Box item p={1}>
            <Search
              onSearch={(e) => globalsearch(e)}
              clearSearch={() => loadData(paging)}
            />
          </Box>
        </Box>
        <DataTable
          columns={columns}
          rows={rows}
          page={page}
          count={totalRecords}
          rowId={(row) => row.doorId}
          pageCount={totalPages}
          pageSize={paging.size}
          handleChange={(newPage, size) => handleChange(newPage, size)}
        />
        {/* <Box style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <CancelButtons style={{ margin: '10px' }}>Cancel</CancelButtons>
          <SubmitButtons style={{ margin: '10px' }}>Submit</SubmitButtons>
        </Box> */}

      </div></div>

  )
}

export default AddDeviceToAccessLevel