import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UserService from '../../services/User/UserService';

let initialState = {
    profileUpdated: false
};

export const getUserInfo = createAsyncThunk('profile/get', async (userId) => {
    const res = await UserService.getUserInfo(userId);
    return res.data;
})

export const updateUserInfo = createAsyncThunk('profile/update', async (user) => {
    const res = await UserService.updateUserInfo(user);
    return res.data;
})


export const getUserById = createAsyncThunk("profile/get", async (idOrEmailOrPhone) => {

    const res = await UserService.getUserById(idOrEmailOrPhone)
    return res.data

})


const UserSlice = createSlice({
    name: 'profile',
    initialState,
    extraReducers: {
        [updateUserInfo.fulfilled]: (state, action) => {
            if (action.payload.code === 'UASI0002') {
                state.profileUpdated = state.profileUpdated ? false : true;
            }
        },
    },
})

const { reducer } = UserSlice;
export default reducer;

