import React, { Fragment } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { REACT_URL_DASHBOARD } from "../../actions/EndPoints";
import projectLogo from "../../assets/images/ZKBio-cloud-visitor-white-Logo.svg";
import ZkBioCloudLogo from "../../assets/CloudVistorIcon.svg";

const HeaderLogo = () => {
  const zlink = localStorage.getItem("redirectFrom");

  return (
    <Fragment>
      <span></span>
      <div className={clsx("app-header-logo", {})}>
        {!zlink ? (
          <>

            <Link to={REACT_URL_DASHBOARD} className="header-logo-wrapper-link">
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box item style={{ width: '40px', height: '40px' }}>
                  <img
                    className="header-logo"
                    alt="Cloud Visitor"
                    src={ZkBioCloudLogo}
                  />
                </Box><Box item>
                  <Typography style={{fontSize:'22px', color:'#fff', paddingLeft:'5px'}}>Cloud Visitor</Typography></Box>
              </Grid>
            </Link>

          </>
        ) : (
          <>
            <Link to={REACT_URL_DASHBOARD} className="header-logo-wrapper-link">
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box item style={{ width: '40px', height: '40px' }}>
                  <img
                    className="header-logo"
                    alt="Cloud Visitor"
                    src={ZkBioCloudLogo}
                  />
                </Box><Box item>
                  <Typography style={{fontSize:'22px', color:'#fff', paddingLeft:'5px'}}>Cloud Visitor</Typography></Box>
              </Grid>
            </Link>

          </>
        )}
      </div>
    </Fragment>
  );
};

export default HeaderLogo;
