import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import { ReactComponent as SearchIcon } from '../../../src/assets/Search.svg';
import { ReactComponent as CancelIcon } from '../../../src/assets/Cancel.svg';
import { Grid } from '@mui/material';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  container: {
    border: '0.2px solid #FFFF',
    maxWidth: '250px',
    display: 'flex',
    padding: '3px',

  },
  search_icon: {
    boxSizing: 'content-box ',
    backgroundColor: process.env.REACT_APP_BG_SEARCHBAR,
    padding: '9px',
    borderRadius: '0px 8px 8px 0px',
    opacity: '60%',
    cursor:'pointer',

    "&:hover": {
      background: process.env.REACT_APP_BG_SEARCHBAR,
      opacity: '1',
    },
  },
}));

export default function Search(props) {

  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('')
  const { t } = useTranslation();

  const handleTextChange = () => {
    props.onSearch(inputValue);
  }

  const handleChange = (event) => {
    const value = event.target.value
    setInputValue(value);
  }

  const handleInput = (event) => {
    const value = event.target.value;
    setInputValue(value);
    if (value === '') {
      props.clearSearch();
    }
  }

  return (
    <Grid className={classes.container} >
      <InputBase
        style={{ backgroundColor: 'white', padding: '6px 0 7px 9px' }}
        placeholder={t("SEARCH02")}
        onChange={handleChange}
        onInput={handleInput} 
        inputProps={{ "aria-label": "search" }}
        value={inputValue}
      />
      {inputValue.length ?
        <CancelIcon
          style={{cursor:'pointer'}}
          onClick={() => {
            setInputValue('')
            props.clearSearch()
            props.onSearch('')
            // props.loadData()
          }
          } /> : <></>}
      <SearchIcon onClick={handleTextChange} className={classes.search_icon} />
    </Grid>
  );
}
