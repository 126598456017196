import BarChartIcon from "@material-ui/icons/BarChart";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import ChatIcon from "@material-ui/icons/ChatOutlined";
import CodeIcon from "@material-ui/icons/Code";
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import FolderIcon from "@material-ui/icons/FolderOutlined";
import GradeTwoTone from "@material-ui/icons/GradeTwoTone";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LockOpenIcon from "@material-ui/icons/LockOpenOutlined";
import MailIcon from "@material-ui/icons/MailOutlined";
import PresentToAllIcon from "@material-ui/icons/PresentToAll";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import PersonIcon from "@material-ui/icons/PersonOutlined";
import ReceiptIcon from "@material-ui/icons/ReceiptOutlined";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import DashboardTwoToneIcon from "@material-ui/icons/DashboardTwoTone";
import BrowserLanguage from "../../components/Util/BrowserLanguage";
import {
  REACT_URL_ADDRESSTYPE,
  REACT_URL_APPROVAL,
  REACT_URL_BLOCKED_VISITORS,
  REACT_URL_CHECKINOUTDEVICES,
  REACT_URL_DASHBOARD,
  REACT_URL_INVITATIONS,
  REACT_URL_PERSON,
  REACT_URL_REGISTRATIONS,
  REACT_URL_ROLE,
  REACT_URL_SITE,
  REACT_URL_SITETYPE,
  REACT_URL_PURPOSETYPE,
  REACT_URL_WATCHLIST,
  REACT_URL_CHECKLIST,
  REACT_URL_RESTRICTTYPE,
  REACT_URL_URGENTMESSAGES,
  REACT_URL_FLOWCHART,
  REACT_DEVICE_LIST,
  REACT_URL_DEVICELIST,
  REACT_ACCESSLEVELS_LIST,
  REACT_TIME_SLOTS,
  REACT_URL_ZONE,
  REACT_URL_VISITINVITE,
  REACT_URL_WALKINVISIT,
  REACT_URL_REPORTLOGS,
  REACT_URL_ROLEANDPERMISSION,
  REACT_URL_SELFREGISTARTIONS,
  REACT_URL_DEVICEREPORTEVENTS,
} from "../../actions/EndPoints";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { ReactComponent as Dashboard_Icon } from "../../assets/images/dashboard-icons/dashboard_icon.svg";
import { ReactComponent as Site_Icon } from "../../assets/images/dashboard-icons/site_icon.svg";
import { ReactComponent as Device_Icon } from "../../assets/images/dashboard-icons/setting.svg";
import { ReactComponent as Access_Icon } from "../../assets/images/dashboard-icons/access_icon.svg";
import { ReactComponent as Report_Icon } from "../../assets/images/dashboard-icons/visitor.svg";
import { ReactComponent as Organization_Icon } from "../../assets/images/dashboard-icons/organization_icon.svg";
import { ReactComponent as AccessControl } from "../../assets/cloudaccess-svg/AccessControl.svg";
import { ReactComponent as Reports } from "../../assets/cloudaccess-svg/Reports.svg";
import { ReactComponent as SiteManagement } from "../../assets/cloudaccess-svg/SiteManagement.svg";
import { ReactComponent as PersonalManagement } from "../../assets/cloudaccess-svg/Personnel Icon.svg";
import { ReactComponent as Icon_device } from "../../assets/images/dashboard-icons/device_icon.svg";
import { ReactComponent as Device_Event_Icon } from "../../assets/images/dashboard-icons/device_event_icon.svg";

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  AccessControlIcon: AccessControl,
  ReportsIcon: Reports,
  SiteManagementIcon: SiteManagement,
  PersonalManagementIcon: PersonalManagement,
  BusinessIcon: DashboardTwoToneIcon,
  EqualizerIcon: EqualizerIcon,
};

let language = BrowserLanguage.getDefaultLanguage();

const zlink = localStorage.getItem("redirectFrom");

const dataEn = [
  {
    id: "0",
    name: "Dashboard",
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },

  {
    id: "1",
    name: "Organization",
    Icon: Organization_Icon,
    items: [
      {
        id: "6",
        name: "Person",
        link: `${REACT_URL_PERSON}`,
      },

      {
        id: "65",
        name: "Role and Permission",
        link: `${REACT_URL_ROLEANDPERMISSION}`,
      },

    ],
  },

  {
    id: "2",
    name: "Site Management",
    Icon: Site_Icon,
    items: [
      {
        id: "12",
        name: "Site",
        link: `${REACT_URL_SITE}`,
      },
      {
        id: "34",
        name: "Zone",
        link: `${REACT_URL_ZONE}`,

      },
    ],
  },


  {
    id: "3",
    name: "Device Management",
    Icon: Icon_device,
    items: [
      {
        id: "14",
        name: "Device List",
        link: `${REACT_URL_DEVICELIST}`,
      },
    ],
  },

  {
    id: "5",
    name: "Visitor",
    Icon: Report_Icon,
    items: [
      {
        id: "19",
        name: "Visiting Flowchart",
        link: `${REACT_URL_FLOWCHART}`,
      },

      {
        id: '20',
        name: 'Invitations',
        link: `${REACT_URL_VISITINVITE}`,
      },
      {
        id: '21',
        name: 'Registrations',
        link: `${REACT_URL_WALKINVISIT}`
      },
      {
        id: '22',
        name: 'Self Registrations',
        link: `${REACT_URL_SELFREGISTARTIONS}`
      },
      {
        id: "23",
        name: "Approvals",
        link: `${REACT_URL_APPROVAL}`,
      },
      {
        id: "24",
        name: "Blocked Visitors",
        link: `${REACT_URL_BLOCKED_VISITORS}`,
      },
      {
        id: '25',
        name: 'Visitor Log',
        link: `${REACT_URL_REPORTLOGS}`  
      },
    ],
  },

  {
    id: "6",
    name: "Reports",
    Icon: Device_Event_Icon,
    items: [
      {
        id: "62",
        name: "Events",
        link: `${REACT_URL_DEVICEREPORTEVENTS}`,
      },
    ],
  },

  {
    id: "4",
    name: "Settings",
    Icon: Device_Icon,
    items: [
      {
        id: "12",
        name: "Time slots",
        link: `${REACT_TIME_SLOTS}`,
      },
      {
        id: "13",
        name: "Visiting Purpose",
        link: `${REACT_URL_PURPOSETYPE}`,
      },
      {
        id: "15",
        name: "Visiting Access Level",
        link: `${REACT_ACCESSLEVELS_LIST}`,
      },
      {
        id: "16",
        name: "Restrict Type",
        link: `${REACT_URL_RESTRICTTYPE}`,
      },
      {
        id: "17",
        name: "Checklist",
        link: `${REACT_URL_CHECKLIST}`,
      },
      {
        id: "18",
        name: "Watchlist",
        link: `${REACT_URL_WATCHLIST}`,
      },
      {
        id: "19",
        name: "Urgent Messages",
        link: `${REACT_URL_URGENTMESSAGES}`,
      },
    ],
  },
];

const dataEs = [
  {
    id: "0",
    name: "Salpicadero",
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },

  {
    id: "1",
    name: "Organización",
    Icon: Organization_Icon,
    items: [
      {
        id: "6",
        name: "Persona",
        link: `${REACT_URL_PERSON}`,
      },
    ],
  },

  {
    id: "2",
    name: "Gestión del sitio",
    Icon: Site_Icon,
    items: [
      {
        id: "12",
        name: "Sitio",
        link: `${REACT_URL_SITE}`,
      },
      {
        id: "34",
        name: "Zona",
        link: `${REACT_URL_ZONE}`,

      },
    ],
  },


  {
    id: "3",
    name: "Administración de dispositivos",
    Icon: Icon_device,
    items: [
      {
        id: "14",
        name: "Device List",
        link: `${REACT_URL_DEVICELIST}`,
      },
    ],
  },

  {
    id: "5",
    name: "Visitante",
    Icon: Report_Icon,
    items: [
      {
        id: "19",
        name: "Diagrama de flujo de visitas",
        link: `${REACT_URL_FLOWCHART}`,
      },
      {
        id: '20',
        name: 'Invitaciones',
        link: `${REACT_URL_VISITINVITE}`,
      },
      {
        id: '21',
        name: 'Registros',
        link: `${REACT_URL_WALKINVISIT}`
      },
      {
        id: "22",
        name: "Aprobaciones",
        link: `${REACT_URL_APPROVAL}`,
      },
      {
        id: "23",
        name: "Visitantes bloqueados",
        link: `${REACT_URL_BLOCKED_VISITORS}`,
      },
      {
        id: "24",
        name: "Registro de visitantes",
        link: `${REACT_URL_REPORTLOGS}`,
      },
    ],
  },


  {
    id: "4",
    name: "Configuración",
    Icon: Device_Icon,
    items: [
      {
        id: "12",
        name: "Franjas horarias",
        link: `${REACT_TIME_SLOTS}`,
      },
      {
        id: "13",
        name: "Propósito de la visita",
        link: `${REACT_URL_PURPOSETYPE}`,
      },
      {
        id: "15",
        name: "Nivel de acceso de visita",
        link: `${REACT_ACCESSLEVELS_LIST}`,
      },
      {
        id: "16",
        name: "Tipo de restricción",
        link: `${REACT_URL_RESTRICTTYPE}`,
      },
      {
        id: "17",
        name: "Lista de verificación",
        link: `${REACT_URL_CHECKLIST}`,
      },
      {
        id: "18",
        name: "Lista de seguimiento",
        link: `${REACT_URL_WATCHLIST}`,
      },
      {
        id: "19",
        name: "Mensajes urgentes",
        link: `${REACT_URL_URGENTMESSAGES}`,
      },
    ],
  },
];

const dataZh = [
  {
    id: "0",
    name: "仪表板",
    link: `${REACT_URL_DASHBOARD}`,
    Icon: Dashboard_Icon,
  },

  {
    id: "1",
    name: "组织",
    Icon: Organization_Icon,
    items: [
      {
        id: "6",
        name: "人",
        link: `${REACT_URL_PERSON}`,
      },
    ],
  },

  {
    id: "2",
    name: "现场管理",
    Icon: Site_Icon,
    items: [
      {
        id: "12",
        name: "网站",
        link: `${REACT_URL_SITE}`,
      },
      {
        id: "34",
        name: "区",
        link: `${REACT_URL_ZONE}`,

      },
    ],
  },


  {
    id: "3",
    name: "设备管理",
    Icon: Icon_device,
    items: [
      {
        id: "14",
        name: "设备列表",
        link: `${REACT_URL_DEVICELIST}`,
      },
    ],
  },

  {
    id: "5",
    name: "游客",
    Icon: Report_Icon,
    items: [
      {
        id: "19",
        name: "参观流程图",
        link: `${REACT_URL_FLOWCHART}`,
      },

      // {
      //   id: "20",
      //   name: "邀请",
      //   link: `${REACT_URL_INVITATIONS}`,
      // },
      // {
      //   id: "21",
      //   name: "Registrations",
      //   link: `${REACT_URL_REGISTRATIONS}`,
      // },
      {
        id: '20',
        name: '邀请',
        link: `${REACT_URL_VISITINVITE}`,
      },
      {
        id: '21',
        name: '注册',
        link: `${REACT_URL_WALKINVISIT}`
      },
      {
        id: "22",
        name: "批准",
        link: `${REACT_URL_APPROVAL}`,
      },
      {
        id: "23",
        name: "被阻止的访客",
        link: `${REACT_URL_BLOCKED_VISITORS}`,
      },
      {
        id: "24",
        name: "访客日志",
        link: `${REACT_URL_REPORTLOGS}`,
      },
    ],
  },


  {
    id: "4",
    name: "设置",
    Icon: Device_Icon,
    items: [
      {
        id: "12",
        name: "时间段",
        link: `${REACT_TIME_SLOTS}`,
      },
      {
        id: "13",
        name: "参观目的",
        link: `${REACT_URL_PURPOSETYPE}`,
      },
      {
        id: "15",
        name: "访问级别",
        link: `${REACT_ACCESSLEVELS_LIST}`,
      },
      {
        id: "16",
        name: "限制类型",
        link: `${REACT_URL_RESTRICTTYPE}`,
      },
      {
        id: "17",
        name: "清单",
        link: `${REACT_URL_CHECKLIST}`,
      },
      {
        id: "18",
        name: "关注列表",
        link: `${REACT_URL_WATCHLIST}`,
      },
      {
        id: "19",
        name: "紧急消息",
        link: `${REACT_URL_URGENTMESSAGES}`,
      },
    ],
  },
];

export default language === 'en' ? dataEn : language === 'es' ? dataEs : dataZh;
