import React, { useEffect, useState } from "react";
import ApprovalTabs from './ApprovalTabs'
import './Approvals.css';
import HeaderToolbar from '../../components/HeaderToolbar';
import { useTranslation } from 'react-i18next';
import { getPermissionsListByroleId } from '../../slices/RoleandPermission/RoleandPermissionSlice';
import api from '../../config/http-common';
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";

function Approvals() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const [invitationApprovals, setInvitationApprovals] = useState(true);
  const [selfApprovals, setSelfApprovals] = useState(true);
  const [blockApprovals, setBlockApprovals] = useState(true);

  useEffect(() => {
    dispatch(getPermissionsListByroleId(api.decoded().roleId)) 
    .unwrap()
    .then((res) => {
        var l = [];
        res.data.rolePermissions.map((item, index) => {
          l.push(item.code);
      });
        setRoles(l);
        setSelfApprovals(l.includes("REGISTRATION_APPROVALS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setBlockApprovals(l.includes("BLOCKED_REQUEST_APPROVALS_CV") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        setInvitationApprovals(l.includes("INVITATION_APPROVALS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded()!=undefined && api.decoded().roleCode === 'SUPERADMIN'));
        
        if (l.includes("INVITATION_APPROVALS")) {
           }
         });
  }, []);

  useEffect(() => {
    if (roles.includes("INVITATION_APPROVALS") || (api.decoded() != undefined && api.decoded().roleCode === 'OWNER') || (api.decoded() != undefined && api.decoded().roleCode === 'SUPERADMIN')) {
      }
  }, []);

    return (
        <>
            <HeaderToolbar title={t("commonsPathApprovals")} />
            {(invitationApprovals || selfApprovals || blockApprovals)?(<ApprovalTabs />):(<div style={{marginTop: '185px', paddingLeft: '300px'}}>
                    <Typography variant="h5" style={{color: '#3D4977', fontSize: '18px', fontWeight:'450'}}>
                        {t('CVROLE011')}
                    </Typography>
                </div>)
                }
        </>
    )   
}

export default Approvals