import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ZoneService from '../../services/Zone/ZoneService';


const initialState = [];

export const getAllZone = createAsyncThunk('zone/list', async (payload) => {
    const res = await ZoneService.getAllZone(payload);
    return res.data;
});

const ZoneSlice = createSlice({
    name: 'zone',
    initialState,
    extraReducers: {
        
    }
})


const { reducer } = ZoneSlice;
export default reducer;